import React, { Component } from 'react';
import axios from 'axios';
import helpers from '../../Helpers';
import $ from 'jquery';
import queryString from 'query-string';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { styled } from '@mui/material/styles';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Button from '@mui/material/Button';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { RemoveShoppingCart } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { maxWidth } from '@mui/material/node_modules/@mui/system';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart';
import { id } from 'date-fns/locale';
const Swal = require('sweetalert2')

export class SuccessWsp extends Component {
    static displayName = SuccessWsp.name;

    constructor(props) {
        super(props);

        this.state = {
            steps: ['Inicio', 'Cliente', 'Pedido', 'Pago', 'Checkout'],
            activeStep: 2,
            resources: [],
            products: [],
            productsSelected: [],
            total: 0,
            status: "",
            installmentsNumber : 0,
            shippingCostMax: 0,
            name: "",
            lastName: "",
            email: "",
            phone: "",
            address: "",
            number: "",
            cityId: 0,
            cityName: "",
            stateId: 0,
            states: [],
            courierId: 0,
            courierName: "",
            deliveryTime: "",
            buyOrder: "",
            user: "",
            waypayId: 0,
            waypays: [],
            flat: "",
            depto: "",
            betweenStreet: "",
            shoppingCarCreationDate: "",
            creditCard: "",
            securityCode: "",
            duesNumber: "",
            countryWaypayId: "",
            countryWaypays: [],
            params: "",
            shoppingCar: null,
            tokenProducts: "",
            countryId: JSON.parse(localStorage.getItem('DataWorkspace')).state.countryId,
            cardNumber: null,
            paymentStateId: null,
            carStateId: null,
            occId: 0,
            orderNumber: '',
            idAgent: '',
            cardApproval: null,
            cardDues: null
        };
    }

    componentDidMount() {

        this.state.params = queryString.parse(window.location.search);
        // console.log(this.state.params);
        let checkoutData = JSON.parse(localStorage.getItem("DataCheckOutWsp"));
        let data = JSON.parse(localStorage.getItem('DataWorkspace'));
        this.setState({
            orderNumber: checkoutData.orderNumber ,
            products: checkoutData?.products,
            paymentStateId: checkoutData?.paymentStateId,
            carStateId: checkoutData?.carStateId,
            productsSelected: checkoutData?.productsSelected,
            total: checkoutData?.total,
            shippingCostMax: checkoutData?.shippingCostMax,
            name: checkoutData?.name,
            lastName: checkoutData?.lastName,
            email: checkoutData?.email,
            phone: checkoutData?.phone,
            buyOrder: checkoutData?.buyOrder,
            waypayId: checkoutData?.waypayId,
            waypays: checkoutData?.waypays,
            flat: checkoutData?.flat,
            depto: checkoutData?.depto,
            address: checkoutData?.address,
            number: checkoutData?.number,
            cityId: checkoutData?.cityId,
            cityName: checkoutData?.cityName,
            stateId: checkoutData?.stateId,
            states: checkoutData?.states,
            betweenStreet: checkoutData?.betweenstreets,
            shoppingCarCreationDate: checkoutData?.shoppingCarCreationDate,
            logStateCarId: checkoutData?.logStateCarId,
            userId: checkoutData?.userId,
            tokenProducts: data.state.tokenProducts,
            agentId: checkoutData?.agentId,
            tokenClientOcc: checkoutData?.tokenClientOcc,
        });
        this.state.orderNumber = checkoutData.orderNumber;
        this.state.carStateId = checkoutData?.carStateId;
        this.state.paymentStateId = checkoutData?.paymentStateId;
        this.state.products = checkoutData?.products;
        this.state.productsSelected = checkoutData?.productsSelected;
        // console.log(this.state.orderNumber)
       //console.log(this.state.productsSelected)
        this.state.tokenProducts = data.state.tokenProducts;

        let dataPayment = JSON.parse(localStorage.getItem("DataPaymentWsp"));
        this.setState({
            courierId: dataPayment.courierId,
            courierName: dataPayment.courierName,
            deliveryTime: dataPayment.deliveryTime,
            countryWaypays: dataPayment.countryWaypays,
            countryWaypayId: dataPayment.countryWaypayId,
            waypayId: dataPayment.waypayId,
           // cardNumber: dataPayment.cardNumber,
        });

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': this.state.tokenProducts
        };
        
        this.getResources();

        
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getdecrypt/' + this.state.params.response ,
        {
            headers: headers
        })
        .then(response => {
            const data = response.data.data;
            // console.log(data)
            localStorage.setItem("InfoPayment",data);
            this.setState({
                securityCode : data.getAuthorizationCode,
                creditCard: data.getCardDetail,
                installmentsNumber: data.getInstallmentsNumber
            });
            if(this.state.installmentsNumber == 0){
                this.state.installmentsNumber= "1";
            }
            // console.log(this.state.installmentsNumber)

            axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getshoppingcardbyid/' + checkoutData.buyOrder,
            {
                headers: headers
            })
            .then(response => {
                const dataShoppingCar = response.data.data;
                 //console.log(dataShoppingCar);
                this.setState({
                    shoppingCar: dataShoppingCar,
                    idAgent: dataShoppingCar.user_operator_id
                });
                dataShoppingCar.card_dues = this.state.installmentsNumber;
                dataShoppingCar.card_number = this.state.creditCard;
                dataShoppingCar.card_approval = this.state.securityCode;

                // this.state.shoppingCar.cardDues = this.state.installmentsNumber;
                // this.state.shoppingCar.cardNumber = this.state.creditCard;
                // this.state.shoppingCar.cardApproval = this.state.securityCode;

                let updateShoppingCarToRest = {
                    id: Number(JSON.parse(localStorage.getItem("carritooccid"))),
                    id_pais: Number(this.state.countryId),
                    tarjeta_numero: this.state.creditCard,  
                    tarjeta_pagos: this.state.installmentsNumber ,
                    tarjeta_aprobacion: this.state.securityCode ,
                    //id_estado_pedido: this.state.carStateId , 
                    //id_estado_pago: this.state.paymentStateId,
                    id_agente: Number(this.state.idAgent),
                }
            
                if(this.state.paymentStateId){
                    if(this.state.paymentStateId == 1){
                        updateShoppingCarToRest.id_estado_pago = 2;
                        this.state.shoppingCar.payment_state_id = 2;
                    }else{
                        updateShoppingCarToRest.id_estado_pago = this.state.paymentStateId;
                    }
                }
            
                if(this.state.carStateId){
                    if(this.state.carStateId == 34){
                        updateShoppingCarToRest.id_estado_pedido = 1;
                        this.state.shoppingCar.car_state_id = 1;
                    }else{
                        updateShoppingCarToRest.id_estado_pedido = this.state.carStateId;
                    }
                }
                   axios.post(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/updateshoppingcarocc', {shoppingcarOccUpdateData: updateShoppingCarToRest, token: this.state.tokenClientOcc,},{
                   headers: headers
                })
                .then((resp) => {
                    helpers.hideLoadingOverlay();
                    if(resp.data){
                        //console.log(resp.data)
                        let idCarrito = resp.data.id;
                        let msg = localStorage.getItem('WspLanguage') == 'language0' ? `Carrito de Compras Actualizado. Id: ${idCarrito}` : `ShoppingCar Updated. Id: ${idCarrito}`;
                            toast.success(msg, {
                                position: "top-right",
                                autoClose: 10000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                    }
            
                    if(resp.data.errors){         
                            if(resp.data.errors[0]){
                                let msg = localStorage.getItem('WspLanguage') == 'language0' ? `Error: ${resp.data.errors[0]}` : `Error: ${resp.data.errors[0]}`;
                                toast.error(msg, {
                                    position: "top-right",
                                    autoClose: 10000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                });
                            }
                        }
                })
                .catch((error) => {
                    helpers.hideLoadingOverlay();
                    console.log(error)
                });     
                axios.post(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/saveshoppingcar/', this.state.shoppingCar,
                {
                    headers: headers
                })
                .then(response => {
                    const dataResponse = response.data.data;
                    //console.log(dataResponse);

                    let logStateCarData = {
                        id: this.state.logStateCarId,
                        car_id: this.state.shoppingCar.id,
                        car_state_id: this.state.shoppingCar.car_state_id,
                        user_id: this.state.userId,
                        comment: "",
                        file_data: ""
                    }
                    const headers = {
                        'Content-Type': 'application/json',
                        'Authorization': this.state.tokenProducts
                    };
                    axios.post(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/savelogstatecar', logStateCarData, {
                        headers: headers
                    })
                    .then((response) => {
                        // console.log(response.data.data);
                        this.setState({
                            logStateCarId: response.data.data
                        });
                    })
                    .catch((error) => {
                        console.log(error)
                    })
                })
                .catch(function (error) {
                    console.log(error);
                    this.setState({ loading: false });
                }) 
            })
            .catch(function (error) {
                console.log(error);
                helpers.hideLoadingOverlay();
              //  this.setState({ loading: false });
            });
        })
        .catch(function (error) {
            console.log(error);
            // this.setState({ loading: false });
        })  
    }

    render() {

        return (
            <div className="container">
            <ToastContainer></ToastContainer>
                 <div className="row text-center" style={{backgroundColor: "#43eb43b0",padding: "3rem",padding: "1rem",color: "#024802", margin: "2rem"}}>
                    <h2 className='pb-2'>
                        {localStorage.getItem('WspLanguage') == 'language0' ? "Congratulations!" : "Felicitaciones!"}
                    </h2>

                    <h5>
                        {localStorage.getItem('WspLanguage') == 'language0' ? "Your purchase has been completed successfully" : "Tu compra se ha concretado exitosamente"}
                    </h5>
                </div>

                <div className="row m-4">
                    <div className="col-md-12">
                    
                        <div style={{border: '1px solid #cbcbcb', backgroundColor: '#80808017'}} className='p-2'>
                            <h5 className="text-center">
                                {localStorage.getItem('WspLanguage') == 'language0' ? "Client Data" : "Datos del Cliente"}
                            </h5>
                        </div>

                        <div className='col-md-12'>
                            <div className='row'>
                                <div className="col-6 p-2" style={{border: '1px solid #cbcbcb'}}>
                                    <span style={{fontSize: '1rem'}}>{this.state.resources.Label_Name}</span>
                                </div>
                                <div className="col-6 p-2" style={{border: '1px solid #cbcbcb'}}>
                                    {this.state.name + ' ' + this.state.lastName}
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-6 p-2" style={{border: '1px solid #cbcbcb'}}>
                                   <span style={{fontSize: '1rem'}}>{this.state.resources.Label_Status}</span>
                                </div>
                                <div className="col-6 p-2" style={{border: '1px solid #cbcbcb'}}>
                                   {localStorage.getItem('WspLanguage') == 'language0' ? "Pending Payment" : "Pendiente Pago"}  
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-6 p-2" style={{border: '1px solid #cbcbcb'}}>
                                   <span style={{fontSize: '1rem'}}>Courier</span>
                                </div>
                                <div className="col-6 p-2" style={{border: '1px solid #cbcbcb'}}>
                                    {this.state.courierName} 
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-6 p-2" style={{border: '1px solid #cbcbcb'}}>
                                   <span style={{fontSize: '1rem'}}>{this.state.resources.Label_DeliveryTime}</span>
                                </div>
                                <div className="col-6 p-2" style={{border: '1px solid #cbcbcb'}}>
                                    {this.state.deliveryTime} hs
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-6 p-2" style={{border: '1px solid #cbcbcb'}}>
                                   <span style={{fontSize: '1rem'}}>{localStorage.getItem('WspLanguage') == 'language0' ? "Payment Status" : "Estado de Pago"}</span>
                                </div>
                                <div className="col-6 p-2" style={{border: '1px solid #cbcbcb'}}>
                                    {localStorage.getItem('WspLanguage') == 'language0' ? "Pending" : "Pendiente"}     
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-6 p-2" style={{border: '1px solid #cbcbcb'}}>
                                   <span style={{fontSize: '1rem'}}>{localStorage.getItem('WspLanguage') == 'language0' ? "Orden Number" : "Número de Pedido"}</span>
                                </div>
                                <div className="col-6 p-2" style={{border: '1px solid #cbcbcb'}}>
                                   {this.state.buyOrder}
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-6 p-2" style={{border: '1px solid #cbcbcb'}}>
                                   <span style={{fontSize: '1rem'}}>{localStorage.getItem('WspLanguage') == 'language0' ? "Operator" : "Operador"}</span>
                                </div>
                                <div className="col-6 p-2" style={{border: '1px solid #cbcbcb'}}>
                                    {this.state.user}   
                                </div>
                            </div>
                            

                            <div className='row'>
                                <div className="col-6 p-2" style={{border: '1px solid #cbcbcb'}}>
                                    <span style={{ fontSize: '1rem' }}>Total</span>
                                </div>
                                <div className="col-6 p-2" style={{border: '1px solid #cbcbcb'}}>
                                    ${this.state.total.toLocaleString('en-US')}
                                </div>
                            </div>
                        </div>
                                
                    </div>
                </div>

                <div className="row m-4">
                    <div className="col-md-12">
                    
                        <div style={{border: '1px solid #cbcbcb', backgroundColor: '#80808017'}} className='p-2'>
                            <h5 className="text-center">
                                {localStorage.getItem('WspLanguage') == 'language0' ? "Delivery Address" : "Dirección de Entrega"}
                            </h5>
                        </div>

                        <div className='col-md-12'>
                            <div className='row'>
                                <div className="col-6 p-2" style={{border: '1px solid #cbcbcb'}}>
                                    <span style={{fontSize: '1rem'}}>{localStorage.getItem('WspLanguage') == 'language0' ? "Street" : "Calle"}</span>
                                </div>
                                <div className="col-6 p-2" style={{border: '1px solid #cbcbcb'}}>
                                    {this.state.address}
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-6 p-2" style={{border: '1px solid #cbcbcb'}}>
                                   <span style={{fontSize: '1rem'}}>{this.state.resources.Label_Number}</span>
                                </div>
                                <div className="col-6 p-2" style={{border: '1px solid #cbcbcb'}}>
                                    {this.state.number}   
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-6 p-2" style={{border: '1px solid #cbcbcb'}}>
                                   <span style={{fontSize: '1rem'}}>{this.state.resources.Label_Floor}</span>
                                </div>
                                <div className="col-6 p-2" style={{border: '1px solid #cbcbcb'}}>
                                    {this.state.flat}   
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-6 p-2" style={{border: '1px solid #cbcbcb'}}>
                                   <span style={{fontSize: '1rem'}}>{this.state.resources.Label_Department}</span>
                                </div>
                                <div className="col-6 p-2" style={{border: '1px solid #cbcbcb'}}>
                                    {this.state.depto}   
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-6 p-2" style={{border: '1px solid #cbcbcb'}}>
                                   <span style={{fontSize: '1rem'}}>{localStorage.getItem('WspLanguage') == 'language0' ? "Between Streets" : "Entre Calles"}</span>
                                </div>
                                <div className="col-6 p-2" style={{border: '1px solid #cbcbcb'}}>
                                    {this.state.betweenStreet}   
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-6 p-2" style={{border: '1px solid #cbcbcb'}}>
                                   <span style={{fontSize: '1rem'}}>{localStorage.getItem('WspLanguage') == 'language0' ? "Locality" : "Localidad"}</span>
                                </div>
                                <div className="col-6 p-2" style={{border: '1px solid #cbcbcb'}}>
                                    {this.state.cityName}   
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-6 p-2" style={{border: '1px solid #cbcbcb'}}>
                                   <span style={{fontSize: '1rem'}}>{localStorage.getItem('WspLanguage') == 'language0' ? "State or Province" : "Estado o Provincia"}</span>
                                </div>
                                <div className="col-6 p-2" style={{border: '1px solid #cbcbcb'}}>
                                    {this.state.states.map(state => { 
                                        if (state.id == this.state.stateId) {
                                            return state.name;
                                        }
                                    })}   
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-6 p-2" style={{border: '1px solid #cbcbcb'}}>
                                    <span style={{ fontSize: '1rem' }}>{this.state.resources.Label_Phone}</span>
                                </div>
                                <div className="col-6 p-2" style={{border: '1px solid #cbcbcb'}}>
                                    {this.state.phone}
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-6 p-2" style={{border: '1px solid #cbcbcb'}}>
                                    <span style={{ fontSize: '1rem' }}>Email</span>
                                </div>
                                <div className="col-6 p-2" style={{border: '1px solid #cbcbcb'}}>
                                    {this.state.email}  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row m-4">
                    <div className="col-md-12">
                        <div style={{border: '1px solid #cbcbcb', backgroundColor: '#80808017'}} className='p-2'>
                            <h5 className="text-center">
                                {localStorage.getItem('WspLanguage') == 'language0' ? "Payment Details" : "Detalles de Pago"}
                            </h5>
                        </div>
                        <div className='col-md-12'>
                            <div className='row'>
                                <div className="col-6 p-2" style={{border: '1px solid #cbcbcb'}}>
                                    <span style={{fontSize: '1rem'}}>{localStorage.getItem('WspLanguage') == 'language0' ? "Transaction Response" : "Respuesta de la Transacción"}</span>
                                </div>
                                <div className="col-6 p-2" style={{border: '1px solid #cbcbcb'}}>
                                    {localStorage.getItem('WspLanguage') == 'language0' ? "Approved" : "Aprobada"}
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-6 p-2" style={{border: '1px solid #cbcbcb'}}>
                                   <span style={{fontSize: '1rem'}}>{localStorage.getItem('WspLanguage') == 'language0' ? "Purchase Order" : "Orden de Compra"}</span>
                                </div>
                                <div className="col-6 p-2" style={{border: '1px solid #cbcbcb'}}>
                                    {this.state.buyOrder}   
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-6 p-2" style={{border: '1px solid #cbcbcb'}}>
                                   <span style={{fontSize: '1rem'}}>{localStorage.getItem('WspLanguage') == 'language0' ? "Authorization Code" : "Código de Autorización"}</span>
                                </div>
                                <div className="col-6 p-2" style={{border: '1px solid #cbcbcb'}}>
                                {this.state.securityCode}   
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-6 p-2" style={{border: '1px solid #cbcbcb'}}>
                                   <span style={{fontSize: '1rem'}}>{localStorage.getItem('WspLanguage') == 'language0' ? "Transaction Date" : "Fecha/Hora Transacción"}</span>
                                </div>
                                <div className="col-6 p-2" style={{border: '1px solid #cbcbcb'}}>
                                    {helpers.getDisplayDateTimeFromUtc(this.state.shoppingCarCreationDate)}  
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-6 p-2" style={{border: '1px solid #cbcbcb'}}>
                                   <span style={{fontSize: '1rem'}}>{localStorage.getItem('WspLanguage') == 'language0' ? "Credit Card" : "Tarjeta de Crédito"}</span>
                                </div>
                                <div className="col-6 p-2" style={{border: '1px solid #cbcbcb'}}>
                                    {this.state.creditCard}   
                                </div>
                            </div>
                            {/* <div className='row'>
                                <div className="col-6 p-2" style={{border: '1px solid #cbcbcb'}}>
                                   <span style={{fontSize: '1rem'}}>{localStorage.getItem('WspLanguage') == 'language0' ? "Payment Type" : "Tipo de Pago"}</span>
                                </div>
                                <div className="col-6 p-2" style={{border: '1px solid #cbcbcb'}}>
                                    {this.state.waypays.map(waypay => {
                                        if (waypay.id == this.state.waypayId)
                                            return waypay.description;
                                    })} 
                                </div>
                            </div> */}
                            <div className='row'>
                                <div className="col-6 p-2" style={{border: '1px solid #cbcbcb'}}>
                                   <span style={{fontSize: '1rem'}}>{localStorage.getItem('WspLanguage') == 'language0' ? "Purchase amount" : "Monto de Compra"}</span>
                                </div>
                                <div className="col-6 p-2" style={{border: '1px solid #cbcbcb'}}>
                                    {this.state.total.toLocaleString('en-US')}   
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-6 p-2" style={{border: '1px solid #cbcbcb'}}>
                                    <span style={{ fontSize: '1rem' }}>{localStorage.getItem('WspLanguage') == 'language0' ? "Dues Number" : "Número de Cuotas"}</span>
                                </div>
                                <div className="col-6 p-2" style={{border: '1px solid #cbcbcb'}}>
                                    {/* {this.state.countryWaypays.map(countryWaypay => {
                                        if (countryWaypay.id == this.state.countryWaypayId)
                                            return countryWaypay.dues_number;
                                    })}    */}
                                    {this.state.installmentsNumber
                                        //(this.state.installmentsNumber===null || this.state.installmentsNumber===0 || this.state.installmentsNumber===1)?1:this.state.installmentsNumber
                                    }   
                                </div>
                            </div>
                        </div>
                        
                        <br></br>
                                
                        
                    </div>
                    <div className='col-md-12'>
                        
                            <div className="card">
                                    <div className="card-header">
                                        <h5>{this.state.resources.CheckOutBuyDetail}</h5>
                                    </div>
                                    <div className="card-body">
                                        <table className='table datatable my-table' aria-labelledby="tabelLabel">
                                            <thead>
                                                <tr>
                                                <th>{this.state.resources.Label_Products}</th>
                                                <th style={{textAlign:'right'}}>{this.state.resources.Label_Quantity}</th>
                                                <th  style={{textAlign:'right'}}>{this.state.resources.Label_Price}</th>
                                                <th  style={{paddingRight:'2rem', textAlign:'right'}}>{this.state.resources.Label_TotalPrice}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.productsSelected.map(prod => (
                                                    <tr>
                                                        <td>{prod.description}</td>
                                                        <td style={{textAlign:'right'}}>{prod.quantity}</td>
                                                        <td style={{textAlign:'right'}}>${Number(prod.price).toLocaleString('en-US')}</td>
                                                        <td style={{paddingRight:'2rem', textAlign:'right'}}>${(prod.price * prod.quantity).toLocaleString('en-US')}</td>
                                                    </tr>
                                                ))}
                                                <tr>
                                                    <td>{localStorage.getItem('WspLanguage') == 'language0' ? 'Single Shipment: ' : 'Envío Único: '}</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td style={{paddingRight:'2rem', textAlign:'right'}}> ${Number(this.state.shippingCostMax).toLocaleString('en-US')}</td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td style={{paddingRight:'2rem', textAlign:'right'}}>
                                                        <div style={{fontSize:'1.2rem'}}>
                                                            <b>Total: $ {(Number(this.state.total)).toLocaleString('en-US')}</b>
                                                        </div>
                                                    </td>
                                                </tr>
                                                
                                            </tbody>
                                        </table>
                                    </div>
                            </div>
                        <br></br>
                        <br></br>
                        </div>
                </div>
            </div>
            
        );
    }

    async getResources() {
        const resourceVars = [];
        resourceVars.push("Label_SelectOption");
        resourceVars.push("Label_Name");
        resourceVars.push("Label_LastName");
        resourceVars.push("Label_PostalCode");
        resourceVars.push("Title_City");
        resourceVars.push("Label_Address");
        resourceVars.push("Label_Country");
        resourceVars.push("Label_StateOrProvince");
        resourceVars.push("Label_DocNumber");
        resourceVars.push("Label_DateOfBirth");
        resourceVars.push("Label_Phone");
        resourceVars.push("Label_MobilePhone");
        resourceVars.push("Label_Email");
        resourceVars.push("Label_Description");
        resourceVars.push("Label_Enabled");
        resourceVars.push("Label_Success");
        resourceVars.push("ButtonLabel_Save");
        resourceVars.push("ButtonLabel_Cancel");
        resourceVars.push("Label_Error");
        resourceVars.push("ErrorMessage_RequiredField");
        resourceVars.push("ErrorMessage_DuplicatedName");
        resourceVars.push("ErrorMessage_UnknownError");
        resourceVars.push("ErrorMessage_AssociatedEntity_SubArea_User");
        resourceVars.push("SuccessMessage_DataSavedSuccessfully");
        resourceVars.push("Label_GoBack");
        resourceVars.push("Title_Clients");
        resourceVars.push("Label_Creation");
        resourceVars.push("Label_DocumentType");
        resourceVars.push("Workspace_Next");
        resourceVars.push("Label_NoSale");
        resourceVars.push("Label_Sex");
        resourceVars.push("Label_Male");
        resourceVars.push("Label_Female");
        resourceVars.push("Label_SearchClient");
        resourceVars.push("Label_HistoryClient");
        resourceVars.push("Label_Info");
        resourceVars.push("Label_NoMail");
        resourceVars.push("Label_Number");
        resourceVars.push("Label_Floor");
        resourceVars.push("Label_Department");
        resourceVars.push("Label_CourierComentaries");
        resourceVars.push("Label_InsideComentaries");
        resourceVars.push("Label_DocsRequired");
        resourceVars.push("ButtonLabel_Select");
        resourceVars.push("Label_NoRows");
        resourceVars.push("Title_Objetions");
        resourceVars.push("Title_ScriptSales");
        resourceVars.push("Label_Price");
        resourceVars.push("Label_AddProduct");
        resourceVars.push("Label_Quantity");
        resourceVars.push("Label_Products");
        resourceVars.push("Label_Presentation");
        resourceVars.push("Label_Actions");
        resourceVars.push("Label_TotalPrice");
        resourceVars.push("Label_Comments");
        resourceVars.push("Label_NoSaleReason");
        resourceVars.push("Label_ContactAgain");
        resourceVars.push("Label_ProcessHasFinalized");
        resourceVars.push("Label_Status");
        resourceVars.push("Title_Courier");
        resourceVars.push("Label_Price");
        resourceVars.push("Label_AddProduct");
        resourceVars.push("Label_Quantity");
        resourceVars.push("Label_Products");
        resourceVars.push("Label_Presentation");
        resourceVars.push("Label_Actions");
        resourceVars.push("Label_TotalPrice");
        resourceVars.push("Label_Comments");
        resourceVars.push("Label_NoSaleReason");
        resourceVars.push("Label_ContactAgain");
        resourceVars.push("Title_WayPay");
        resourceVars.push("Label_DuesNumber");
        resourceVars.push("Label_InterestRate");
        resourceVars.push("Label_Bank");
        resourceVars.push("Label_CardNumber");
        resourceVars.push("Label_SecurityCode");
        resourceVars.push("Label_Expiration");
        resourceVars.push("Label_Holder");
        resourceVars.push("Label_AccountType");
        resourceVars.push("Label_CurrentAccount");
        resourceVars.push("Label_AccountNumber");
        resourceVars.push("Label_VistaAccount");
        resourceVars.push("Label_RutAccount");
        resourceVars.push("Label_Dues");
        resourceVars.push("Label_Due");
        resourceVars.push("Error_ClientWsp");
        resourceVars.push("CheckOutBuyDetail");
        resourceVars.push("CheckOutShipmentInfo");
        resourceVars.push("CheckOutClientInfo");
        resourceVars.push("ButtonLabel_Confirm");
        resourceVars.push("Label_DeliveryDate");
        resourceVars.push("Label_DeliveryTime");
        resourceVars.push("Title_WayPay");
        resourceVars.push("Title_City");
        resourceVars.push("Label_City");
        resourceVars.push("Label_Address");
        resourceVars.push("Label_Country");
        resourceVars.push("Label_StateOrProvince");
        resourceVars.push("Label_DocNumber");
        resourceVars.push("Label_DateOfBirth");
        resourceVars.push("Label_Phone");
        resourceVars.push("Label_MobilePhone");
        resourceVars.push("Label_Email");
        resourceVars.push("Label_Description");
        resourceVars.push("Label_Products");
        resourceVars.push("Label_Quantity");
        resourceVars.push("Label_Price");
        resourceVars.push("Label_TotalPrice");

        const headersLang = {
            'Content-Type': 'application/json'
        };

        let wspLanguage = localStorage.getItem('WspLanguage');
        helpers.showLoadingOverlay();
        axios.post(window.config.REACT_APP_SECURITY_API_URL + '/languageresource/getbyvars/' + wspLanguage, resourceVars, {
            headers: headersLang
        })
        .then((response) => {
            const data = response.data.data;
            this.setState({ resources: data, loading: false });
        })
        .catch((error) => {
            console.log(error);
            this.setState({ loading: false });
        });

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': this.state.tokenProducts
        };
        let agentId = JSON.parse(localStorage.getItem('DataCheckOutWsp')).agentId;
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getuserbypbxidid/' + agentId,
        {
            'headers': headers
        })
        .then(response => {
            this.setState({
                user: response.data.data.first_name + ' ' + response.data.data.last_name,
            });
            helpers.hideLoadingOverlay();
        })
        .catch(error => {
            helpers.hideLoadingOverlay();
            console.log(error);
        });
    }
}