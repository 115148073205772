import React, { Component } from 'react';
import axios from 'axios';
import $ from 'jquery';
import queryString from 'query-string';
import helpers from '../../Helpers';
import DataCreation from '../DataCreation/DataCreation';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { WindowOpener } from '../PbxWorkspace/window-opener';

export class SalesView extends Component {
    static displayName = SalesView.name;

    constructor(props) {
        super(props);

        this.onCancel = this.onCancel.bind(this);
        //this.onSubmit = this.onSubmit.bind(this);

        this.state = {
            resources: [],
            id: 0,
            carStateName:'',  
            name: '',
            lastName:'',
            paymentStateName:'',
            wayPayName:'',
            cardDues: null,
            courierName:'',
            deliveryTime:'',
            userOperatorName:'',
            items: [],
            address:'',
            addressNumber: null,
            dpto:'',
            flat:'',
            beetweenStreets:'',
            cityName: '',
            stateName:'',
            phone:'',
            email:'',
            internalComments:'',
            total: 0,
            envioUnico: 0,
        };
        
    }

    componentDidMount() {
        //console.log(window.location.href)
        // let firstId = window.location.href.slice(32,36);
        // this.state.id = Number(firstId);
        // console.log(this.state.id)
        let urlId= window.location.href.split('/')[4]; 
        console.log(window.location.href)
        console.log(urlId)
        this.state.id = Number(urlId);

        document.querySelector('#btnDateOrder').classList.add('active');
        document.querySelector('#divOrder').style.display = 'block';
        document.querySelector('#divPurchase').style.display = 'none';
        document.querySelector('#divDelivery').style.display = 'none';
        this.getResources();
        this.populateData();
        helpers.setNavActive("#nav-salesview");
        helpers.setClientValidation();


        let url = window.location.href;
        if (url.includes('view')) {
            this.setState({
                viewMode: true
            });
        }

        // let currentUser = helpers.getUserData();
        // this.setState({
        //     currentUserId: currentUser.id
        // });

        // document.querySelector('#form1').style.display = 'none';
        // document.querySelector('#form2').style.display = 'none';
        // document.querySelector('#form3').style.display = 'none';
        // if (this.state.campaingtype_id == 2 || this.state.campaingtype_id == 3 || this.state.campaingtype_id == 5) {
        //     document.querySelector('#form1').style.display = 'block';
        //     document.querySelector('#form2').style.display = 'none';
        //     document.querySelector('#form3').style.display = 'none';
        // } else if (this.state.campaingtype_id == 4) {
        //     document.querySelector('#form2').style.display = 'block';
        //     document.querySelector('#form1').style.display = 'none';
        //     document.querySelector('#form3').style.display = 'none';
        // } else if (this.state.campaingtype_id == 6) {
        //     document.querySelector('#form3').style.display = 'block';
        //     document.querySelector('#form1').style.display = 'none';
        //     document.querySelector('#form2').style.display = 'none';
        // } else {
        //     document.querySelector('#form1').style.display = 'none';
        //     document.querySelector('#form2').style.display = 'none';
        //     document.querySelector('#form3').style.display = 'none';
        // }

        // let sort = JSON.parse(localStorage.getItem('CampaingListSorting'))
        // localStorage.setItem('CopyCampaingListSorting', JSON.stringify(sort));

        // let paging = JSON.parse(localStorage.getItem('CampaingListPagination'))
        // localStorage.setItem('CopyCampaingListPaging', JSON.stringify(paging));

        // let filtering = JSON.parse(localStorage.getItem('CampaingListFiltering'))
        // localStorage.setItem('CopyCampaingListFiltering', JSON.stringify(filtering));


    }

    onClickBtnDateOrder(){
        document.querySelector('#btnDateOrder').classList.add('active');
        document.querySelector('#btnDatePurchase').classList.remove('active');
        document.querySelector('#btnDateDelivery').classList.remove('active');

        document.querySelector('#divOrder').style.display = 'block';
        document.querySelector('#divPurchase').style.display = 'none';
        document.querySelector('#divDelivery').style.display = 'none';
    }

    onClickBtnDatePurchase(){
        document.querySelector('#btnDatePurchase').classList.add('active');
        document.querySelector('#btnDateOrder').classList.remove('active');
        document.querySelector('#btnDateDelivery').classList.remove('active');

        document.querySelector('#divOrder').style.display = 'none';
        document.querySelector('#divPurchase').style.display = 'block';
        document.querySelector('#divDelivery').style.display = 'none';
    }

    onClickBtnDateDelivery(){
        document.querySelector('#btnDateDelivery').classList.add('active');
        document.querySelector('#btnDatePurchase').classList.remove('active');
        document.querySelector('#btnDateOrder').classList.remove('active');

        document.querySelector('#divOrder').style.display = 'none';
        document.querySelector('#divPurchase').style.display = 'none';
        document.querySelector('#divDelivery').style.display = 'block';
    }

    onCancel(e) {
        e.preventDefault();
        //this.props.history.push("/saleshistory");
        this.props.history.go('-1')
    }

    render() {
        return (
            <>
            <div className="mt-2">
                <ol className="breadcrumb my-4">
                    <li className="breadcrumb-item active"> {this.state.language == 'language0' ? 'Date Order' : 'Vista del Pedido'}</li>
                </ol>
                <div className='row m-0'>
                        <div style={{display: 'flex'}}>
                            <button className="p-2 btn-primary" style={{borderRadius: '4px', fontSize: '0.9rem', marginRight: '2px'}} id="btnDateOrder" onClick={this.onClickBtnDateOrder}>
                                    {this.state.resources.Label_OrderData} 
                            </button>
                            <button className="p-2 btn-primary" style={{borderRadius: '4px', fontSize: '0.9rem', marginRight: '2px'}} id="btnDatePurchase" onClick={this.onClickBtnDatePurchase}>
                                    {this.state.resources.Label_PurchaseData} 
                            </button>
                            <button className="p-2 btn-primary" style={{borderRadius: '4px', fontSize: '0.9rem', marginRight: '2px'}} id="btnDateDelivery" onClick={this.onClickBtnDateDelivery}>
                                    {this.state.resources.Label_DeliveryDataa} 
                            </button>
                        </div>
                    </div>

                    {/*FORM 1*/}
                    <div id="divOrder">
                        <div style={{display: 'flex',border: '2px solid rgb(51 99 205)',borderRadius: '5px', height: '450px' }}>
                            <div className="form-group col-md-6" >

                                <label className=" form-label">{this.state.resources.Label_Order}</label>
                                <input className="form-control" type="text" name="name" value={this.state.id} disabled/>

                                <label className=" form-label">{this.state.resources.Label_Name}</label>
                                <input className="form-control" type="text" name="name" value={this.state.name} disabled />

                                <label className=" form-label">{this.state.resources.Label_OrderState}</label>
                                <input className="form-control" type="text" name="name" value={this.state.carStateName} disabled />

                                <label className=" form-label">{this.state.resources.Label_Dues}</label>
                                <input className="form-control" type="text" name="name" value={this.state.cardDues} disabled />

                                <label className=" form-label">{this.state.resources.Label_DeliveryTime}</label>
                                <input className="form-control" type="text" name="name" value={this.state.deliveryTime} disabled />


                            </div>
                            
                            <div className="form-group col-md-6" >
                            
                                <label className=" form-label">{this.state.resources.Label_PaymentState}</label> 
                                <input className="form-control" type="text" name="name" value={this.state.paymentStateName} disabled />

                                <label className=" form-label">{this.state.resources.Label_LastName}</label>
                                <input className="form-control" type="text" name="name" value={this.state.lastName} disabled />

                                <label className=" form-label">{this.state.resources.Label_WayPay}</label>
                                <input className="form-control" type="text" name="name" value={this.state.wayPayName} disabled />

                                <label className=" form-label">{this.state.resources.Label_Courier}</label> 
                                <input className="form-control" type="text" name="name" value={this.state.courierName} disabled />

                                <label className=" form-label">{this.state.resources.Label_Operator}</label>
                                <input className="form-control" type="text" name="name" value={this.state.userOperatorName} disabled />
                                
                            </div>
                        </div>

                    </div>

                    {/*FORM 2*/}
                    <div id="divPurchase">
                        <div style={{display: 'flex',border: '2px solid rgb(51 99 205)',borderRadius: '5px', height: '450px' }}>
                            <div className="form-group col-md-6" style={{margin: '3%' }} >

                            <table className='table datatable my-table' aria-labelledby="tabelLabel">
                            <thead>
                                <tr style={{padding: 20}}>
                                <th>{this.state.resources.Label_Products}</th>
                                <th style={{textAlign: 'right'}}>{this.state.resources.Label_Quantity}</th> 
                                    <th style={{textAlign: 'right'}}>{this.state.resources.Label_Price}</th>
                                    <th style={{textAlign: 'right', paddingRight: 40}}>{this.state.resources.Label_TotalPrice}</th>
                                </tr>
                            </thead>
                            <tbody> 
                                {this.state.items.map(prod => (
                                    <tr>
                                        <td>{prod.description}</td>
                                        <td style={{textAlign: 'right'}}>{prod.quantity}</td>
                                        <td style={{textAlign: 'right'}}>${Number(prod.unitamount).toLocaleString('en-US')}</td>
                                        <td style={{textAlign: 'right', paddingRight: 40}}>${Number(prod.unitamount * prod.quantity).toLocaleString('en-US')}</td>
                                    </tr>
                                    ))}
                                <tr>
                                    <td>{localStorage.getItem('WspLanguage') == 'language0' ? 'Single Shipment: ' : 'Envío Único: '}</td>
                                    <td></td>
                                    <td></td>
                                    <td style={{textAlign: 'right', paddingRight: 40}}>${this.state.envioUnico?.toLocaleString('en-US')}</td>  
                                </tr>
                                <tr>
                                    <td></td>
                                    <td></td> 
                                    <td></td>
                                    <td>
                                        <div style={{fontSize:'1.2rem'}}>
                                            <b>Total: ${Number(this.state.total + + Number(this.state.envioUnico)).toLocaleString('en-US')}</b>
                                        </div>
                                    </td>
                                </tr>
                                
                            </tbody>
                        </table>
                            
                            </div>
                        </div>

                    </div>

                    {/*FORM 3*/}
                    <div id="divDelivery">
                        <div style={{display: 'flex',border: '2px solid rgb(51 99 205)',borderRadius: '5px', height: '450px' }}>
                            <div className="form-group col-md-6" >
                                <label className=" form-label">{this.state.resources.Label_Address}</label>  
                                <input className="form-control" type="text" name="name" value={this.state.address} disabled />

                                <label className=" form-label">{this.state.resources.Label_Department}</label>
                                <input className="form-control" type="text" name="name" value={this.state.dpto} disabled />

                                <label className=" form-label">{this.state.resources.Label_BetweenStreets}</label>
                                <input className="form-control" type="text" name="name" value={this.state.beetweenStreets} disabled />

                                <label className=" form-label">{this.state.resources.Label_StateOrProvince}</label> 
                                <input className="form-control" type="text" name="name" value={this.state.stateName} disabled />

                                <label className=" form-label">{this.state.resources.Label_Email}</label>
                                <input className="form-control" type="text" name="name" value={this.state.email} disabled />


                            </div>
                            
                            <div className="form-group col-md-6" >
                               
                                <label className=" form-label">{this.state.resources.Label_Number}</label>   
                                <input className="form-control" type="text" name="name" value={this.state.addressNumber} disabled />

                                <label className=" form-label">{this.state.resources.Label_Floor}</label>
                                <input className="form-control" type="text" name="name" value={this.state.flat} disabled />

                                <label className=" form-label">{this.state.resources.Title_City}</label>
                                <input className="form-control" type="text" name="name" value={this.state.cityName} disabled />

                                <label className=" form-label">{this.state.resources.Label_Phone}</label> 
                                <input className="form-control" type="text" name="name" value={this.state.phone} disabled />

                                <label className=" form-label">{this.state.resources.Label_InsideComentaries}</label>
                                <textarea className="form-control" rows="3"  maxLength="150" value={this.state.internalComments} disabled></textarea>

                            </div>
                        </div>

                    </div>

                        {/**Buttons Header */}
                        <div className="buttons-head row">
                            {this.state.viewMode == false ?
                            <div className=" buttons-childs">
                                <button className="btn btn-danger" onClick={this.onCancel}>{this.state.resources.Label_GoBack}</button>
                            </div>
                            :
                            <button className="btn btn-danger btn-block" onClick={this.onCancel}>{this.state.resources.Label_GoBack}</button> 
                            }
                        </div>

                    </div>

            </>
        );
    }
 //{this.state.resources.Label_GoBack} 
 //{this.state.resources.ButtonLabel_Cancel}
    async getResources() {
        const resourceVars = [];
        resourceVars.push("Label_Products");
        resourceVars.push("Label_ID");
        resourceVars.push("Label_Name");
        resourceVars.push("Label_LastName");
        resourceVars.push("Title_Campaing");
        resourceVars.push("Label_Actions");
        resourceVars.push("Label_Status");
        resourceVars.push("Label_Enabled");
        resourceVars.push("Label_Disabled");
        resourceVars.push("ButtonLabel_ViewEdit");
        resourceVars.push("ButtonLabel_New");
        resourceVars.push("Label_Country");
        resourceVars.push("Title_Agency");
        resourceVars.push("Title_Business");
        resourceVars.push("Title_Media");
        resourceVars.push("Label_Name");
        resourceVars.push("Label_Country");
        resourceVars.push("Label_SelectOption");
        resourceVars.push("Label_Enabled");
        resourceVars.push("Label_Success");
        resourceVars.push("ButtonLabel_Save");
        resourceVars.push("ButtonLabel_Cancel");
        resourceVars.push("Label_Error");
        resourceVars.push("Title_Formats");
        resourceVars.push("NavItem_Courier");
        resourceVars.push("ErrorMessage_RequiredField");
        resourceVars.push("ErrorMessage_DuplicatedName");
        resourceVars.push("ErrorMessage_UnknownError");
        resourceVars.push("SuccessMessage_DataSavedSuccessfully");
        resourceVars.push("Label_ManualLoading");
        resourceVars.push("Label_Yes");
        resourceVars.push("Label_CampaingRecalculation");
        resourceVars.push("Label_Domain");
        resourceVars.push("Title_PbxDatabase");
        resourceVars.push("Label_Creation");
        resourceVars.push("Label_LastUpdate");
        resourceVars.push("Label_GoBack");
        resourceVars.push("Label_Domains");
        resourceVars.push("Title_Publicity");
        resourceVars.push("ErrorMessage_PublicityError");
        resourceVars.push("Label_Reading");
        resourceVars.push("Label_Order");
        resourceVars.push("Label_Client");
        resourceVars.push("Label_WayPay");
        resourceVars.push("Label_Invoice");
        resourceVars.push("Label_PaymentState");
        resourceVars.push("Label_OrderState");
        resourceVars.push("Label_Mount");
        resourceVars.push("Label_Operator");
        resourceVars.push("Label_Product");
        resourceVars.push("Label_DeliveryTime");
        resourceVars.push("Label_BetweenStreets");
        resourceVars.push("Label_Dues");
        resourceVars.push("Label_Courier");
        resourceVars.push("Title_City"); 
        resourceVars.push("Label_Address");
        resourceVars.push("Label_Country");
        resourceVars.push("Label_StateOrProvince");
        resourceVars.push("Label_DocNumber");
        resourceVars.push("Label_Phone");
        resourceVars.push("Label_Email"); 
        resourceVars.push("Label_Number");
        resourceVars.push("Label_Floor");
        resourceVars.push("Label_Department");
        resourceVars.push("Label_CourierComentaries");  
        resourceVars.push("Label_InsideComentaries");
        resourceVars.push("Label_Quantity");
        resourceVars.push("Label_Price");
        resourceVars.push("Label_TotalPrice");
        resourceVars.push("Label_OrderData");
        resourceVars.push("Label_PurchaseData");
        resourceVars.push("Label_DeliveryDataa");

        const headers = {
            'Content-Type': 'application/json'
        };

        axios.post(window.config.REACT_APP_SECURITY_API_URL + '/languageresource/getbyvars/' + helpers.getUiLanguage(), resourceVars, {
            headers: headers
        })
            .then((response) => {
                const data = response.data.data;
                this.setState({ resources: data});
            })
            .catch((error) => {
                console.log(error);
                this.setState({ loading: false });
            });
    }

    async populateData() {
        helpers.showLoadingOverlay();
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/shoppingcar/getventas/' + this.state.id,
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                 console.log(data)
                 this.setState({
                    name: data.client_name,
                    lastName: data.client_last_name,
                    carStateName: data.car_state_name,
                    paymentStateName:data.payment_state_name,
                    wayPayName: data.waypay_name,
                    cardDues: data.card_dues,
                    courierName: data.courier,
                    deliveryTime: data.delivery_time,
                    userOperatorName: data.user_operator_name,
                    address: data.address,
                    addressNumber: data.address_number,
                    dpto: data.dpto,
                    flat: data.flat,
                    beetweenStreets: data.between_streets,
                    cityName: data.city_name,
                    stateName: data.state_name,
                    phone: data.phone,
                    email: data.email,
                    internalComments: data.internal_comments,
                    envioUnico: data.delivery_cost,
                });
                helpers.hideLoadingOverlay();
            })
            .catch(function (error) {
                console.log(error);
                helpers.hideLoadingOverlay();
            });
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/shoppingcar/getcardetailbycarid/' + this.state.id,
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                 console.log(data)
                 this.setState({items: data});

                 //this.state.envioUnico = this.state.items[0];
                 this.state.items = this.state.items.filter(x=> x.idkey !== 1);
                 
                //console.log(this.state.envioUnico)
                 console.log(this.state.items)

                  let totalPrice = 0;
                  this.state.items.map(prod => {
                    totalPrice = totalPrice + Number(prod.unitamount * prod.quantity);

                     });
                     
                
                       console.log(this.state.envioUnico)
                 this.state.total = totalPrice;
                 console.log(this.state.total)
                //helpers.hideLoadingOverlay();
            })
            .catch(function (error) {
                console.log(error);
                helpers.hideLoadingOverlay();
            });
    }

}

