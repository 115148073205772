import React, { Component } from 'react';
import axios from 'axios';
import $, { event } from 'jquery';
import helpers from '../../Helpers';


export class MapCourierCityCreate extends Component {
    static displayName = MapCourierCityCreate.name;

    constructor(props) {
        super(props);

        this.onChangeCountry = this.onChangeCountry.bind(this);
        this.onChangeCourier = this.onChangeCourier.bind(this);
        this.onChangeState = this.onChangeState.bind(this);
        this.addAllCities = this.addAllCities.bind(this);
        this.removeAllCities = this.removeAllCities.bind(this);
        this.addCityList = this.addCityList.bind(this);
        this.includeCity = this.includeCity.bind(this);
        this.disIncludeCity = this.disIncludeCity.bind(this);
        this.selectCity = this.selectCity.bind(this);
        this.disSelectCity = this.disSelectCity.bind(this);
        this.searchCitiesArray = this.searchCitiesArray.bind(this);
        this.searchCitiesIncluded = this.searchCitiesIncluded.bind(this);
        this.onChangeWayPay = this.onChangeWayPay.bind(this);
        this.onChangeLimit = this.onChangeLimit.bind(this);
        this.onChangeDeliveryTime = this.onChangeDeliveryTime.bind(this);
        this.onChangeRouteType = this.onChangeRouteType.bind(this);
        this.onChangeEnabled = this.onChangeEnabled.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onSubmit = this.onSubmit.bind(this);  

        this.state = {
            id: 0,
            country_id: 0,
            countries: [],
            state_id: 0,
            states: [],
            city_id: 0,
            cities: [],
            citiesArray: [],
            copyCitiesArray: [],
            citiesIncluded: [],
            copyCitiesIncluded: [],
            isSelected: false,
            selecteds: [],
            disSelecteds: [],
            waypay: 0,
            limit: 0,
            courier_id: 0,
            couriers: [],
            paymethods: [],
            delivery_time: '',
            route_type: '',
            enabled: true,
            loading: true,
            resources: [],
            resultArr: [],
            resultIncludedArr: [],
            userId: 0,
            cityBlocked: [],
        };
    }

    componentDidMount() {
        this.getResources();
        this.populateData();
        helpers.setNavActive("#nav-map-courier-city");

        let currentUser = helpers.getUserData();
        this.setState({
            userId: currentUser.id
        });
    }

    async onSubmit(e) {
        e.preventDefault();
        this.setState({ loading: true });

        let citiesIds = [];
        for (let i = 0; i < this.state.citiesIncluded.length; i++){
            citiesIds.push(this.state.citiesIncluded[i].id);
        }

        const data = {
            country_id: this.state.country_id,
            state_id: this.state.state_id,
            waypay_id: this.state.waypay,
            limit: this.state.limit,
            courier_id: this.state.courier_id,
            delivery_time: this.state.delivery_time,
            route_type: this.state.route_type,
            cities: citiesIds.toString(),
            enabled: this.state.enabled,
            creation_user_id: this.state.userId,
        };
        // console.log(data);
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME)
        };

        axios.post(window.config.REACT_APP_PRODUCTS_API_URL + '/mapcouriercity/save', data, {
            headers: headers
        })
            .then((response) => {
                helpers.notifySuccess(this.state.resources.Label_Success, this.state.resources.SuccessMessage_DataSavedSuccessfully);
                this.props.history.push('/map-courier-city');
            })
            .catch((error) => {
                console.log(error);

                let msg = this.state.resources.ErrorMessage_UnknownError;
                if (error.response.data.error_code == window.errorCodes.DuplicatedEntityName)
                    msg = this.state.resources.ErrorMessage_DuplicatedName;

                helpers.notifyError(this.state.resources.Label_Error, msg);
                this.setState({ loading: false });
            });
    }  

    onCancel(e) {
        e.preventDefault();
        this.props.history.push("/map-courier-city");
    }

    onChangeCountry(e) {
        this.setState({
            country_id: e.target.value,
            citiesArray: [],
        });
    }
    
    onChangeCourier(e) {
        e.preventDefault();
        this.setState({
            courier_id: e.target.value
        });
    }

    onChangeState(e) {
        e.preventDefault();
        this.setState({
            state_id: e.target.value,
            citiesArray: [],
            citiesIncluded: [],
            selecteds: []
        });
    }

    addCityList(e) {
        e.preventDefault();
        // console.log(e.target.value);
        let arr = e.target.value.split(';');
        // console.log(arr);
        let myCity = {
            id: arr[0],
            name: arr[1]
        }
        this.state.citiesArray.map(city => {
            if(city.name == myCity.name) {
                // console.log('duplicated');
                // console.log(city);
                for (let i = 0; i < this.state.citiesArray.length; i++){
                    if(this.state.citiesArray[i].name == city.name) {
                        this.state.citiesArray.splice(i, 1);
                    }
                }
            } else {
                
            }
        })
        this.setState({
            citiesArray: this.state.citiesArray.concat(myCity),
            copyCitiesArray: this.state.citiesArray.concat(myCity),
        });
    }

    selectCity(e) {
        e.preventDefault();
        // this.state.selecteds = [];
        this.state.isSelected = !this.state.isSelected;
        // console.log(e.target);
        let element = e.target;
        // console.log(e.target.id);
        if (element.classList.contains('selected')) {
            element.classList.remove('selected');
             let arr = element.id.split(';');
            let myCity = {
                id: arr[0],
                name: arr[1]
            }
            
            for (let i = 0; i < this.state.selecteds.length; i++){
                if (this.state.selecteds[i].name == myCity.name) {
                    // console.log(this.state.selecteds[i]);
                    this.state.selecteds.splice(i, 1);
                }
            }

        } else {
            element.classList.add('selected');
            let arr = element.id.split(';');
            let myCity = {
                id: arr[0],
                name: arr[1]
            }

            this.state.selecteds = this.state.selecteds.concat(myCity);

        }

    }

    disSelectCity(e) {
        e.preventDefault();

        let element = e.target;
        // console.log(e.target.id);
        if (element.classList.contains('unselected')) {
            element.classList.remove('unselected');
             let arr = element.id.split(';');
            let myCity = {
                id: arr[0],
                name: arr[1]
            }

            for (let i = 0; i < this.state.disSelecteds.length; i++){
                if (this.state.disSelecteds[i].name == myCity.name) {
                    // console.log(this.state.disSelecteds[i]);
                    this.state.disSelecteds.splice(i, 1);
                }
            }

        } else {
            element.classList.add('unselected');
            let arr = element.id.split(';');
            let myCity = {
                id: arr[0],
                name: arr[1]
            }

            this.state.disSelecteds = this.state.disSelecteds.concat(myCity);

        }
    }

    includeCity(e) {
        e.preventDefault();
        if (this.state.selecteds !== []) {
            for (let i = 0; i < this.state.selecteds.length; i++){
                for (let c = 0; c < this.state.citiesIncluded.length; c++){
                   
                    for (let x = 0; x < this.state.citiesArray.length; x++){
                        if (this.state.selecteds[i].name == this.state.citiesIncluded[c].name &&
                            this.state.selecteds[i].name == this.state.citiesArray[x].name) {
                            this.state.selecteds.splice(i, 1);
                            this.state.citiesArray.splice(x, 1);
                        }
                    }
                    
                }
            }
            this.setState({
                citiesIncluded: this.state.citiesIncluded.concat(this.state.selecteds),
                copyCitiesIncluded: this.state.citiesIncluded.concat(this.state.selecteds),
            });
            for (let i = 0; i < this.state.selecteds.length; i++){
                for (let c = 0; c < this.state.citiesArray.length; c++){
                    if (this.state.selecteds[i].name == this.state.citiesArray[c].name) {
                        // console.log(this.state.citiesArray[c].name)
                        this.state.citiesArray.splice(c, 1);
                    }
                }
            }
            
            this.state.selecteds = [];
            // console.log(document.getElementsByClassName('selected'));
            let elements = document.getElementsByClassName('selected');
            for (let i = 0; i < elements.length; i++){
                if (elements[i].classList.contains('selected')) {
                    // console.log(elements[i]);
                    // elements[i].style.display = 'none';
                    elements.item(i).setAttribute('display', 'none')
                }
                
            }

            document.querySelector('#searchArr').value = '';
            this.setState({
                resultArr: []
            })
        }
        setTimeout(() => {
                for (let i = 0; i < this.state.citiesIncluded.length; i++) {
                    let arr = new Set(this.state.cityBlocked.concat(Number(this.state.citiesIncluded[i].id)))
                    this.setState({
                        cityBlocked: [...arr]
                    });
                }
                // console.log(this.state.cityBlocked)
            }, 1000)
    }

    disIncludeCity(e) {
        e.preventDefault();
        // console.log(this.state.disSelecteds);
        if (this.state.disSelecteds !== []) {
            this.setState({
                citiesArray: this.state.citiesArray.concat(this.state.disSelecteds),
                copyCitiesArray: this.state.citiesArray.concat(this.state.disSelecteds),
            });
            for (let i = 0; i < this.state.disSelecteds.length; i++){
                for (let c = 0; c < this.state.copyCitiesIncluded.length; c++){
                    if (this.state.disSelecteds[i].name == this.state.copyCitiesIncluded[c].name) {
                        this.state.copyCitiesIncluded.splice(c, 1);
                    }
                }
            }
            this.state.disSelecteds = [];

            // console.log(document.getElementsByClassName('unselected'))
            let elements = document.getElementsByClassName('unselected');
            for (let i = 0; i < elements.length; i++){
                // elements[i].style.display = 'none';
                 elements.item(i).setAttribute('display', 'none')
            }
            document.querySelector('#searchInc').value = '';
            this.setState({
                resultIncludedArr: []
            })
        }
        
        this.state.citiesIncluded = this.state.copyCitiesIncluded;
        // console.log("disInclude: ");
        //  console.log(this.state.copyCitiesIncluded);
        // console.log(this.state.citiesIncluded);
        this.setState({
            citiesIncluded: this.state.copyCitiesIncluded
        })

        setTimeout(() => {
            this.setState({
                cityBlocked: []
            })
            for (let i = 0; i < this.state.citiesIncluded.length; i++) {
                    let arr = new Set(this.state.cityBlocked.concat(Number(this.state.citiesIncluded[i].id)))
                    this.setState({
                        cityBlocked: [...arr]
                    });
                }
                // console.log(this.state.cityBlocked)
            }, 1000)
    }

    addAllCities(e) {
        e.preventDefault();
        // console.log(this.state.citiesArray);
        // console.log('included');
        // console.log(this.state.citiesIncluded);
        for (let i = 0; i < this.state.citiesArray.length; i++) {
            for (let c = 0; c < this.state.citiesIncluded.length; c++) {
                if(this.state.citiesArray.length > 0){
                    if (this.state.citiesArray[i]) {
                        if (this.state.citiesArray[i].name == this.state.citiesIncluded[c].name) {
                            this.state.citiesArray.splice(i, 1);
                        }
                    }
                }
            }
        }
        let myArr = this.state.citiesIncluded.concat(this.state.citiesArray);
       
        for (let i = 0; i < myArr.length; i++) {
            if (myArr[i].name == undefined) {
                myArr.splice(i, 1);   
            }
        }
        //  console.log(myArr);
        this.setState({
            citiesIncluded: myArr,
            copyCitiesIncluded: myArr,
            citiesArray: [],
            copyCitiesArray: [],
        });

        setTimeout(() => {
                for (let i = 0; i < this.state.citiesIncluded.length; i++) {
                    let arr = new Set(this.state.cityBlocked.concat(Number(this.state.citiesIncluded[i].id)))
                    this.setState({
                        cityBlocked: [...arr]
                    });
                }
                // console.log(this.state.cityBlocked)
            }, 1000)
    }

    removeAllCities(e) {
        e.preventDefault();
        this.setState({
            // citiesArray: this.state.citiesIncluded,
            citiesIncluded: [],
            copyCitiesIncluded: [],
            cityBlocked: [],
        });
    }

    searchCitiesArray(e) {
        e.preventDefault();
        // console.log(e.target.value);
        // this.setState({
        //     citiesArray: []
        // })
        // let resultArr = [];
        this.setState({
            resultArr: []
        })
        this.state.resultArr = [];
        for (let i = 0; i < this.state.citiesArray.length; i++){
            if (this.state.citiesArray[i].name.toString().toLowerCase().includes(e.target.value)) {
                // console.log(this.state.citiesArray[i]);
                // resultArr.push(this.state.citiesArray[i]);
                this.setState({
                    resultArr: this.state.resultArr.concat(this.state.citiesArray[i])
                }) 
                
            } else {
                // this.setState({
                //     citiesArray: this.state.copyCitiesArray,
                // })
            }
        }
        // if (resultArr != []) {
        //     this.setState({
        //         citiesArray: resultArr
        //     })
        // } else {
        //     this.state.citiesArray = this.state.copyCitiesArray;
        // }
        if (e.target.value == '' || e.target.value == ' ') {
            this.setState({
                resultArr: []
            })
            // this.state.citiesArray = this.state.copyCitiesArray;
            // this.setState({
            //     citiesArray: this.state.copyCitiesArray,
            // });
        }
        // console.log(e.target.value);
         
        // this.state.citiesArray = this.state.citiesArray;
        // console.log('search citiesArray: ')
        // console.log(this.state.copyCitiesArray);
        // console.log(this.state.citiesArray);
        
    }

    searchCitiesIncluded(e) {
        e.preventDefault();
        
        // let resultArr = [];
        this.setState({
            resultArr: []
        })
        this.state.resultIncludedArr = [];
        for (let i = 0; i < this.state.citiesIncluded.length; i++){
            if (this.state.citiesIncluded[i].name.toString().toLowerCase().includes(e.target.value)) {
                // console.log(this.state.citiesIncluded[i]);
                // resultArr.push(this.state.citiesIncluded[i]);
                this.setState({
                    resultIncludedArr: this.state.resultIncludedArr.concat(this.state.citiesIncluded[i])
                }) 
            } else {
                // this.setState({
                //     citiesIncluded: this.state.copyCitiesIncluded,
                // })
            }
        }
        // if (resultArr != []) {
        //     this.setState({
        //         citiesIncluded: resultArr
        //     })
        // } else {
        //     this.state.citiesIncluded = this.state.copyCitiesIncluded;
        // }
        if (e.target.value == '' || e.target.value == ' ') {
            // this.setState({
            //     citiesIncluded: this.state.copyCitiesIncluded,
            // });
            // this.state.citiesIncluded = this.state.copyCitiesIncluded;
            this.setState({
                resultIncludedArr: []
            })
        }
        
        // this.state.citiesIncluded = this.state.citiesIncluded;
        // console.log("search Include: ");
        //  console.log(this.state.copyCitiesIncluded);
        // console.log(this.state.citiesIncluded);
    }

    onChangeWayPay(e) {
        e.preventDefault();
        this.setState({
            waypay: e.target.value
        });
    }

    onChangeLimit(e) {
        e.preventDefault();
        this.setState({
            limit: e.target.value
        });
    }

    onChangeDeliveryTime(e) {
        e.preventDefault();
        this.setState({
            delivery_time: e.target.value
        });
    }

    onChangeRouteType(e) {
        e.preventDefault();
        this.setState({
            route_type: e.target.value
        });
    }

    onChangeEnabled(e) {
        this.setState({
            enabled: e.target.checked
        });
    } 

    render() {
        let contents = this.state.loading
            ? helpers.showLoadingOverlay()
            :
            <form className="form row g-3 needs-validation" onSubmit={this.onSubmit}>
                <div className="form-group col-md-12">
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" id="enabled" defaultChecked={this.state.enabled} onChange={this.onChangeEnabled} />
                        <label className="form-check-label" htmlFor="enabled">{this.state.resources.Label_Enabled}</label>
                        <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                    </div>
                </div>

                <div className="col-md-3">
                    <label className=" form-label">{this.state.resources.Label_Country}</label>
                    <select className="form-control" data-val="true" name="country_id" defaultValue={this.state.country_id} onChange={this.onChangeCountry} required>
                        <option value="">{this.state.resources.Label_SelectOption}</option>
                        {this.state.countries.map(country =>
                            <option key={country.id} value={country.id}>{country.name}</option>
                        )}
                    </select>
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>

                    <label className=" form-label">{this.state.resources.Title_State}</label>
                    <select disabled={this.state.country_id == 0} className="form-control" data-val="true" name="state_id" defaultValue={this.state.state_id} onChange={this.onChangeState} required>
                        <option value="">{this.state.resources.Label_SelectOption}</option>
                        {this.state.states.map(state =>
                             state.country_id == this.state.country_id &&
                            <option key={state.id} value={state.id}>{state.name}</option>
                        )}
                    </select>
                </div>
                <div className="col-md-6 dis-flex">
                    
                    <div className="card mx-1" style={{width: '45%'}}>
                        <div className="card-header">
                            {this.state.resources.Label_Cities}     
                        </div>
                        <div className="card-body">
                            <select disabled={this.state.state_id == 0} className="form-control" data-val="true" name="city_id" defaultValue={this.state.city_id} onChange={this.addCityList} required>
                                <option>{this.state.resources.Label_SelectOption}</option>
                                {this.state.cities.map(city =>
                                    city.state_id == this.state.state_id &&
                                    <option key={city.id} hidden={this.state.cityBlocked.includes(city.id)} value={city.id + ';' + city.name}>{city.name}</option>
                                )}
                            </select>
                            <input
                                disabled={this.state.state_id == 0}
                                type="text"
                                className="form-control my-1"
                                placeholder="Buscar"
                                onChange={this.searchCitiesArray}
                                id="searchArr"
                            />
                            <div>
                                <ul className="p-0 text-center">
                                    {this.state.resultArr.map(city =>
                                        <>
                                            {/* // <li id={'cityItem'+city.id} className="list-items" onClick={this.selectCity(city.id + ';' + city.name)}>
                                        //     {city.name}
                                        // </li> */}
                                            <button className="btn list-items mx-5" id={city.id + ';' + city.name} onClick={this.selectCity}>
                                                {city.name}
                                            </button>
                                        </>
                                    )}
                                </ul>
                                <hr></hr>
                                <ul className="p-0 text-center">
                                    {this.state.citiesArray.map(city =>
                                        <>
                                            {/* // <li id={'cityItem'+city.id} className="list-items" onClick={this.selectCity(city.id + ';' + city.name)}>
                                        //     {city.name}
                                        // </li> */}
                                            <button className="btn list-items mx-5" id={city.id + ';' + city.name} onClick={this.selectCity}>
                                                {city.name}
                                            </button>
                                        </>
                                    )}
                                </ul>
                            </div>
                        </div>
                        <div className="card-footer text-center">
                            <button className="btn btn-outline-secondary" onClick={this.addAllCities}>{this.state.resources.Label_AddAll}</button>
                        </div>
                    </div>
                    
                    <div className="text-center">
                        <button className="btn btn-primary m-1 p-2" onClick={this.includeCity}> {'>>'} </button>
                        <br></br>
                        <button className="btn btn-primary m-1 p-2" onClick={this.disIncludeCity}> {'<<'} </button>
                    </div>

                    <div className="card mx-1" style={{width: '45%'}}>
                        <div className="card-header">
                        {this.state.resources.Label_CitiesIncluded}
                        </div>
                        <div className="card-body">
                            <input
                                disabled={this.state.state_id == 0}
                                type="text"
                                className="form-control my-1"
                                placeholder="Buscar"
                                id="searchInc"
                                onChange={this.searchCitiesIncluded}
                            />
                            <div>
                                <ul className="p-0 text-center">
                                    {this.state.resultIncludedArr.map(city =>
                                        <>
                                            {/* // <li id={'cityItem'+city.id} className="list-items" onClick={this.selectCity(city.id + ';' + city.name)}>
                                        //     {city.name}
                                        // </li> */}
                                            <button className="btn list-items mx-5" id={city.id + ';' + city.name} onClick={this.disSelectCity}>
                                                {city.name}
                                            </button>
                                        </>
                                    )}
                                </ul>
                                <hr></hr>
                                <ul className="p-0 text-center">
                                    {this.state.citiesIncluded.map(city =>
                                        <>
                                            {/* <li id={'cityItem' + city.id} className="list-items">{city.name}</li> */}
                                            <button className="btn list-items mx-5" id={city.id + ';' + city.name} onClick={this.disSelectCity}>
                                                {city.name}
                                            </button>
                                        </>
                                    )}
                                </ul>
                            </div>
                        </div>
                        <div className="card-footer text-center">
                            <button className="btn btn-outline-secondary" onClick={this.removeAllCities}>{this.state.resources.Label_RemoveAll}</button>
                        </div>
                    </div>

                </div>
                <div className="col-md-3">
                    <h5 className=" form-label">{this.state.resources.Label_Options}</h5>

                    <label className=" form-label">{this.state.resources.Label_PayMethod}</label>
                    <select className="form-control" data-val="true" name="waypay" defaultValue={this.state.waypay} onChange={this.onChangeWayPay}>
                        <option value="">{this.state.resources.Label_SelectOption}</option>
                        {this.state.paymethods.map(paymethod =>
                            <option key={paymethod.id} value={paymethod.id}>{paymethod.method == 'All' ? this.state.resources.Label_All : paymethod.method}</option>
                        )}
                        {/* <option value="all">{this.state.resources.Label_All}</option> */}
                    </select>

                    <label className=" form-label">{this.state.resources.Label_Limit}</label>
                    <input type="number" className="form-control" onChange={this.onChangeLimit}/>

                    <label className=" form-label">{this.state.resources.Title_Courier}</label>
                    <select disabled={this.state.country_id == 0} className="form-control" data-val="true" name="courier_id" defaultValue={this.state.courier_id} onChange={this.onChangeCourier} required>
                        <option value="">{this.state.resources.Label_SelectOption}</option>
                        {this.state.couriers.map(courier =>
                             courier.country_id == this.state.country_id &&
                            <option key={courier.id} value={courier.id}>{courier.name}</option>
                        )}
                    </select>

                    <label className=" form-label">{this.state.resources.Label_DeliveryTime}</label>
                    <input type="text" className="form-control" onChange={this.onChangeDeliveryTime}/>
                    
                    <label className=" form-label">{this.state.resources.Label_RouteType}</label>
                    <select className="form-control" data-val="true" name="route_type" defaultValue={this.state.route_type} onChange={this.onChangeRouteType}>
                        <option value="">{this.state.resources.Label_SelectOption}</option>
                        <option value="principal">Ruta Principal</option>
                        <option value="exception">Ruta Excepción</option>
                        <option value="blocking">Ruta Bloqueo</option>
                    </select>
                </div>

                {/**Buttons Header */}
                 <div className="buttons-head row">
                    <div className=" buttons-childs">
                        <button type="submit" className="btn btn-primary">{this.state.resources.ButtonLabel_Save}</button>
                        <button className="btn btn-danger" onClick={this.onCancel}>{this.state.resources.ButtonLabel_Cancel}</button>
                    </div>
                 </div>
            </form>
            helpers.hideLoadingOverlay();

        return (
            <div className="mt-2">
                <ol className="breadcrumb my-4">
                    <li className="breadcrumb-item active">{this.state.resources.Title_MapCourierCity}</li>
                </ol>
                <div className="card">
                    <div className="card-body">
                        {contents}
                    </div>
                </div>
            </div>
        );
    }

    async getResources() {
        const resourceVars = [];
        resourceVars.push("Title_MapCourierCity");
        resourceVars.push("Label_Name");
        resourceVars.push("Label_Description");
        resourceVars.push("Label_Enabled");
        resourceVars.push("Label_Success");
        resourceVars.push("ButtonLabel_Save");
        resourceVars.push("ButtonLabel_Cancel");
        resourceVars.push("Label_Error");
        resourceVars.push("ErrorMessage_RequiredField");
        resourceVars.push("ErrorMessage_DuplicatedName");
        resourceVars.push("ErrorMessage_UnknownError");
        resourceVars.push("SuccessMessage_DataSavedSuccessfully");
        resourceVars.push("Label_PayMethod");
        resourceVars.push("Label_DeliveryTime");
        resourceVars.push("Label_Limit");
        resourceVars.push("Label_RouteType");
        resourceVars.push("Title_Courier");
        resourceVars.push("Label_Country");
        resourceVars.push("Title_City");
        resourceVars.push("Title_State");
        resourceVars.push("Label_SelectOption");
        resourceVars.push("Label_Options");
        resourceVars.push("Label_Cities");
        resourceVars.push("Label_CitiesIncluded");
        resourceVars.push("Label_AddAll");
        resourceVars.push("Label_RemoveAll");
        resourceVars.push("Label_All");

        const headers = {
            'Content-Type': 'application/json'
        };

        axios.post(window.config.REACT_APP_SECURITY_API_URL + '/languageresource/getbyvars/' + helpers.getUiLanguage(), resourceVars, {
            headers: headers
        })
            .then((response) => {
                const data = response.data.data;
                this.setState({ resources: data, loading: false });
            })
            .catch((error) => {
                console.log(error);
                this.setState({ loading: false });
            });
    }

    async populateData() {
        this.setState({
            loading: false
        });
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/country/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ countries: data, loading: false });
                // order alphabetically
                this.state.countries.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                // console.log(this.state.countries);
            })
            .catch(function (error) {
                console.log(error);
            });
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/state/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ states: data, loading: false });
                // order alphabetically
                this.state.states.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                // console.log(this.state.states);
            })
            .catch(function (error) {
                console.log(error);
            });
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/city/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ cities: data, loading: false });
                // order alphabetically
                this.state.cities.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                // console.log(this.state.cities);
            })
            .catch(function (error) {
                console.log(error);
            });
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/courier/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ couriers: data, loading: false });
                // order alphabetically
                this.state.couriers.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                // console.log(this.state.couriers);
            })
            .catch(function (error) {
                console.log(error);
            });
            axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/paymethod/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ paymethods: data, loading: false });
                // order alphabetically
                this.state.paymethods.sort((a,b) => a.method < b.method ? -1 : +(a.method> b.method))
                // console.log(this.state.paymethods);
            })
            .catch(function (error) {
                console.log(error);
            });
    }
}