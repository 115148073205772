import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';  
import $ from 'jquery';
import DataTable from 'datatables.net';
import helpers from '../../Helpers';
import { render } from 'react-dom';
import DataCreation from '../DataCreation/DataCreation';

export class ApiClientsEdit extends Component {
    
    static displayName = ApiClientsEdit.name;

    constructor(props) {
        super(props);

        this.onChangeName = this.onChangeName.bind(this);
        this.onChangeapiName = this.onChangeapiName.bind(this);
        this.onChangeWorkEmail = this.onChangeWorkEmail.bind(this);
        this.onChangeEnabled = this.onChangeEnabled.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onSubmit = this.onSubmit.bind(this);  

        this.state = {
            id: 0,
            name: '',
            apiName: '',
            workEmail: '',
            enabled: true,
            loading: true,
            resources: [],
            viewMode: false,
            currentUserId: 0,
        };
    }

    componentDidMount() {
        this.getResources();
        this.populateData();
        helpers.setNavActive("#nav-apiclients");

        let url = window.location.href;
        if (url.includes('view')) {
            this.setState({
                viewMode: true
            });
        }
        let currentUser = helpers.getUserData();
        this.setState({
            currentUserId: currentUser.id
        });

        let sort = JSON.parse(localStorage.getItem('ApiClientsListSorting'))
        localStorage.setItem('CopyApiClientsListSorting', JSON.stringify(sort));

        let paging = JSON.parse(localStorage.getItem('ApiClientsListPagination'))
        localStorage.setItem('CopyApiClientsListPaging', JSON.stringify(paging));

        let filtering = JSON.parse(localStorage.getItem('ApiClientsListFiltering'))
        localStorage.setItem('CopyApiClientsListFiltering', JSON.stringify(filtering));
    }

    async onSubmit(e) {
        e.preventDefault();
        this.setState({
            loading: true
        });

        const data = {
            id: parseInt(this.props.match.params.id),
            name: this.state.name,
            apiName: this.state.apiName,
            workEmail: this.state.workEmail,
            enabled: this.state.enabled,
            last_update_user_id: this.state.currentUserId
        };
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME)
        };

        axios.post(window.config.REACT_APP_PRODUCTS_API_URL + '/apiclients/save', data, {
            headers: headers
        })
            .then((response) => {
                helpers.notifySuccess(this.state.resources.Label_Success, this.state.resources.SuccessMessage_DataSavedSuccessfully);
                this.props.history.push('/apiclients');
            })
            .catch((error) => {
                console.log(error);

                let msg = this.state.resources.ErrorMessage_UnknownError;
                if (error.response.data.error_code == window.errorCodes.DuplicatedEntityName)
                    msg = this.state.resources.ErrorMessage_DuplicatedName;
                else if (error.response.data.error_code == window.errorCodes.AssociatedEntity)
                    msg = this.state.resources.ErrorMessage_AssociatedEntity_Area_User;

                helpers.notifyError(this.state.resources.Label_Error, msg);
                this.setState({ loading: false });
            });
    }  

    onCancel(e) {
        e.preventDefault();
        this.props.history.push("/apiclients");
    }

    onChangeName(e) {
        this.setState({
            name: e.target.value
        });
    }  
    onChangeapiName(e) {
        this.setState({
            apiname: e.target.value
        });
    }  

    onChangeWorkEmail(e) {
        this.setState({
            workemail: e.target.value
        });
    }  
    onChangeEnabled(e) {
        this.setState({
            enabled: e.target.checked
        });
    } 

    render() {
        let contents = this.state.loading
            ? helpers.showLoadingOverlay()
            :
            <form className="form row g-3 needs-validation" onSubmit={this.onSubmit}>
                <div className="form-group col-md-12">
                    <input type="hidden" name="areaId" value={this.state.id} />
                </div>
                <div className="form-group col-md-12">
                    <div className="form-check form-switch">
                        <input disabled={this.state.viewMode} className="form-check-input" type="checkbox" id="enabled" checked={this.state.enabled} defaultChecked={this.state.enabled} onChange={this.onChangeEnabled} />
                        <label className="form-check-label" htmlFor="enabled">{this.state.resources.Label_Enabled}</label>
                        <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                    </div>
                </div>
                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_Name}</label>
                    <input disabled={this.state.viewMode} className="form-control" type="text" name="name" onChange={this.onChangeName} value={this.state.name} required />
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                </div>
                <div className=" form-group col-md-6">
                    <label className="form-label">{this.state.resources.Label_apiName}</label>
                    <input disabled={this.state.viewMode} className="form-control" type="text" name="apiname" onChange={this.onChangeapiName} value={this.state.apiname} />
                </div>
                <div className=" form-group col-md-6">
                    <label className="form-label">{this.state.resources.Label_WorkEmail}</label>
                    <input disabled={this.state.viewMode} className="form-control" type="text" name="workemail" onChange={this.onChangeWorkEmail} value={this.state.workemail} />
                </div>

                <div className=" form-group col-md-6">
                     <DataCreation resources={this.state}></DataCreation>
                </div>

                {/**Buttons Header */}
                <div className="buttons-head row">
                    {this.state.viewMode == false ?
                    <div className=" buttons-childs">
                        <button type="submit" className="btn btn-primary">{this.state.resources.ButtonLabel_Save}</button>
                        <button className="btn btn-danger" onClick={this.onCancel}>{this.state.resources.ButtonLabel_Cancel}</button>
                    </div>
                    :
                    <button className="btn btn-danger btn-block" onClick={this.onCancel}>{this.state.resources.Label_GoBack}</button>
                    }
                 </div>
            </form>
            helpers.hideLoadingOverlay();

        return (
            <div className="mt-2">
                <ol className="breadcrumb my-4">
                    <li className="breadcrumb-item active">{this.state.resources.Title_apiClients}</li>
                </ol>
                <div className="card">
                    <div className="card-body">
                        {contents}
                    </div>
                </div>
            </div>
        );
    }

    async getResources() {
        const resourceVars = [];
        resourceVars.push("Title_apiClients");
        resourceVars.push("Label_Name");
        resourceVars.push("Label_apiName");
        resourceVars.push("Label_WorkEmail");
        resourceVars.push("Label_Enabled");
        resourceVars.push("Label_Success");
        resourceVars.push("ButtonLabel_Save");
        resourceVars.push("ButtonLabel_Cancel");
        resourceVars.push("Label_Error");
        resourceVars.push("ErrorMessage_RequiredField");
        resourceVars.push("ErrorMessage_DuplicatedName");
        resourceVars.push("ErrorMessage_UnknownError");
        resourceVars.push("ErrorMessage_AssociatedEntity_Area_User");
        resourceVars.push("SuccessMessage_DataSavedSuccessfully");
        resourceVars.push("Label_GoBack");
        resourceVars.push("Label_Creation");
        resourceVars.push("Label_CreationUser");
        resourceVars.push("Label_LastUpdate");
        resourceVars.push("Label_LastUpdateUser");

        const headers = {
            'Content-Type': 'application/json'
        };

        axios.post(window.config.REACT_APP_SECURITY_API_URL + '/languageresource/getbyvars/' + helpers.getUiLanguage(), resourceVars, {
            headers: headers
        })
            .then((response) => {
                const data = response.data.data;
                this.setState({ resources: data, loading: false });
            })
            .catch((error) => {
                console.log(error);
                this.setState({ loading: false });
            });
    }

    async populateData() {
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/apiclients/getbyid/' + this.props.match.params.id,
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({
                    id: data.id,
                    name: data.name,
                    apiname: data.apiname,
                    workemail: data.workemail,
                    enabled: data.enabled,
                    loading: false,
                    creation: helpers.getDisplayDateTimeFromUtc(data.creation),
                    creationUser: data.creation_user_fullname,
                    lastUpdate: data.last_update == null ? '' : helpers.getDisplayDateTimeFromUtc(data.last_update),
                    lastUpdateUser: data.last_update_user_fullname == null ? '' : data.last_update_user_fullname,
                });
            })
            .catch(function (error) {
                console.log(error);
                this.setState({ loading: false });
            })  
    }
}