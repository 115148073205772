import React, { Component } from 'react';
import axios from 'axios';
import $ from 'jquery';
import helpers from '../../Helpers';

import '../../custom.css';

import DataCreation from '../DataCreation/DataCreation';

export class ConfigurationDetail extends Component {
    static displayName = ConfigurationDetail.name;

    constructor(props) {
        super(props);

        this.onChangeCode = this.onChangeCode.bind(this);
        this.onChangeValue = this.onChangeValue.bind(this);
        this.onChangeDescription = this.onChangeDescription.bind(this);
        this.onChangeCountry = this.onChangeCountry.bind(this);
        this.onChangeEnabled = this.onChangeEnabled.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

        this.state = {
            id: 0,
            code: '',
            value: '',
            description: '',
            countryId: null,
            enabled: true,
            loading: true,
            resources: [],
            countries: [],
            creation: '',
            creationUser: '',
            lastUpdate: '',
            lastUpdateUser: '',
            viewMode: false,
        };
    }

    componentDidMount() {
        this.getResources();
        this.populateData();
        helpers.setNavActive("#nav-configurations");
        helpers.setClientValidation();

        let url = window.location.href;
        if (url.includes('view')) {
            this.setState({
                viewMode: true
            });
        }

        let sort = JSON.parse(localStorage.getItem('ConfigurationsListSorting'))
        localStorage.setItem('CopyConfigurationsListSorting', JSON.stringify(sort));

        let paging = JSON.parse(localStorage.getItem('ConfigurationsListPagination'))
        localStorage.setItem('CopyConfigurationsListPaging', JSON.stringify(paging));

        let filtering = JSON.parse(localStorage.getItem('ConfigurationsListFiltering'))
        localStorage.setItem('CopyConfigurationsListFiltering', JSON.stringify(filtering));
    }

    async onSubmit(e) {
        e.preventDefault();
        this.setState({
            loading: true
        });

        var form = document.querySelectorAll('.needs-validation')[0];

        if (!form.checkValidity())
            return;

        this.setState({ loading: true });

        const data = {
            id: parseInt(this.props.match.params.id),
            code: this.state.code,
            value: this.state.value,
            description: this.state.description,
            country_id: this.state.countryId,
            enabled: this.state.enabled
        };
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_SECURITY_API_NAME)
        };

        axios.post(window.config.REACT_APP_SECURITY_API_URL + '/configuration/save', data, {
            headers: headers
        })
            .then((response) => {
                helpers.notifySuccess(this.state.resources.Label_Success, this.state.resources.SuccessMessage_DataSavedSuccessfully);
                this.props.history.push('/configurations');
            })
            .catch((error) => {
                console.log(error);

                let msg = this.state.resources.ErrorMessage_UnknownError;
                if (error.response.data.error_code == window.errorCodes.DuplicatedEntityName)
                    msg = this.state.resources.ErrorMessage_DuplicatedCode;

                helpers.notifyError(this.state.resources.Label_Error, msg);
                this.setState({ loading: false });
            });
    }  

    onCancel(e) {
        e.preventDefault();
        this.props.history.push("/configurations");
    }

    onChangeCode(e) {
        this.setState({
            code: e.target.value
        });
    }
    onChangeValue(e) {
        this.setState({
            value: e.target.value
        });
    }
    onChangeDescription(e) {
        this.setState({
            description: e.target.value
        });
    }
    onChangeCountry(e) {
        this.setState({
            countryId: e.target.value
        });
    }
    onChangeEnabled(e) {
        this.setState({
            enabled: e.target.checked
        });
    } 

    render() {
        let contents =
            <form className="form row g-3 needs-validation" onSubmit={this.onSubmit} noValidate>
                <div className="form-group col-md-12">
                    <div className="form-check form-switch">
                        <input disabled={this.state.viewMode} className="form-check-input" type="checkbox" id="enabled" checked={this.state.enabled} onChange={this.onChangeEnabled} />
                        <label className="form-check-label" htmlFor="enabled">{this.state.resources.Label_Enabled}</label>
                        <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                    </div>
                </div>
                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_Code}</label>
                    <input disabled={this.state.viewMode} className="form-control" type="text" name="code" onChange={this.onChangeCode} value={this.state.code} required />
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                </div>
                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_Country}</label>
                    <select disabled={this.state.viewMode} className="form-control" data-val="true" name="country" defaultValue={this.state.countryId} onChange={this.onChangeCountry}>
                        <option value="">{this.state.resources.Label_All}</option>
                        {this.state.countries.map(country =>
                            <option key={country.id} value={country.id} selected={this.state.countryId == country.id}>{country.name}</option>
                        )}
                    </select>
                </div>
                <div className="form-group col-md-12">
                    <label className=" form-label">{this.state.resources.Label_Value}</label>
                    <input disabled={this.state.viewMode} className="form-control" type="text" name="value" onChange={this.onChangeValue} value={this.state.value} required />
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                </div>
                <div className="form-group col-md-12">
                    <label className=" form-label">{this.state.resources.Label_Description}</label>
                    <input disabled={this.state.viewMode} className="form-control" type="text" name="description" onChange={this.onChangeDescription} value={this.state.description} required />
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                </div>
                <div className="form-group col-md-6" >
                     <DataCreation resources={this.state}></DataCreation>
                </div>
                
                {/**Buttons Header */}
                <div className="buttons-head row">
                    {this.state.viewMode == false ?
                    <div className=" buttons-childs">
                        <button type="submit" className="btn btn-primary">{this.state.resources.ButtonLabel_Save}</button>
                        <button className="btn btn-danger" onClick={this.onCancel}>{this.state.resources.ButtonLabel_Cancel}</button>
                    </div>
                    : 
                      <button className="btn btn-danger btn-block" onClick={this.onCancel}>{this.state.resources.Label_GoBack}</button>
                    }
                 </div>
            </form>;

        return (
            <div className="mt-2">
                <ol className="breadcrumb my-4">
                    <li className="breadcrumb-item active">{this.state.resources.Title_Configurations}</li>
                </ol>
                <div className="card">
                    <div className="card-body">
                        {this.state.loading ? helpers.showLoadingOverlay() : helpers.hideLoadingOverlay()}
                        {contents}
                    </div>
                </div>
            </div>
        );
    }

    async getResources() {
        const resourceVars = [];
        resourceVars.push("Title_Configurations");
        resourceVars.push("Label_Code");
        resourceVars.push("Label_Value");
        resourceVars.push("Label_Description");
        resourceVars.push("Label_Country");
        resourceVars.push("Label_All");
        resourceVars.push("Label_Enabled");
        resourceVars.push("Label_Success");
        resourceVars.push("Label_Creation");
        resourceVars.push("Label_CreationUser");
        resourceVars.push("Label_LastUpdate");
        resourceVars.push("Label_LastUpdateUser");
        resourceVars.push("ButtonLabel_Save");
        resourceVars.push("ButtonLabel_Cancel");
        resourceVars.push("Label_Error");
        resourceVars.push("ErrorMessage_RequiredField");
        resourceVars.push("ErrorMessage_DuplicatedCode");
        resourceVars.push("ErrorMessage_UnknownError");
        resourceVars.push("SuccessMessage_DataSavedSuccessfully");
        resourceVars.push("Label_GoBack");

        const headers = {
            'Content-Type': 'application/json'
        };

        axios.post(window.config.REACT_APP_SECURITY_API_URL + '/languageresource/getbyvars/' + helpers.getUiLanguage(), resourceVars, {
            headers: headers
        })
            .then((response) => {
                const data = response.data.data;
                this.setState({ resources: data, loading: false });
            })
            .catch((error) => {
                console.log(error);
                this.setState({ loading: false });
            });
    }

    async populateData() {
        this.setState({
            loading: true
        });

        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/country/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ countries: data, loading: false });
                // order alphabetically
                this.state.countries.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                // console.log(this.state.countries);
            })
            .catch(function (error) {
                console.log(error);
            });

        axios.get(window.config.REACT_APP_SECURITY_API_URL + '/configuration/getbyid/' + this.props.match.params.id,
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_SECURITY_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({
                    id: data.id,
                    code: data.code,
                    value: data.value,
                    description: data.description,
                    countryId: data.country_id,
                    enabled: data.enabled,
                    creation: helpers.getDisplayDateTimeFromUtc(data.creation),
                    creationUser: data.creation_user_fullname,
                    lastUpdate: data.last_update == null ? '' : helpers.getDisplayDateTimeFromUtc(data.last_update),
                    lastUpdateUser: data.last_update_user_fullname == null ? '' : data.last_update_user_fullname,
                    loading: false
                });
            })
            .catch(function (error) {
                console.log(error);
                this.setState({ loading: false });
            })  
    }
}