import React, { Component } from 'react';
import axios from 'axios';
import $, { event } from 'jquery';
import helpers from '../../Helpers';
import DataCreation from '../DataCreation/DataCreation';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from '@material-ui/core/TextField';

export class MapCourierCityDetail extends Component {
    static displayName = MapCourierCityDetail.name;

    constructor(props) {
        super(props);

        this.onChangeCountry = this.onChangeCountry.bind(this);
        this.onChangeCourier = this.onChangeCourier.bind(this);
        this.onChangeState = this.onChangeState.bind(this);
        this.addAllCities = this.addAllCities.bind(this);
        this.removeAllCities = this.removeAllCities.bind(this);
        this.addCityList = this.addCityList.bind(this);
        this.includeCity = this.includeCity.bind(this);
        this.disIncludeCity = this.disIncludeCity.bind(this);
        this.selectCity = this.selectCity.bind(this);
        this.disSelectCity = this.disSelectCity.bind(this);
        this.searchCitiesArray = this.searchCitiesArray.bind(this);
        this.searchCitiesIncluded = this.searchCitiesIncluded.bind(this);
        this.onChangeWayPay = this.onChangeWayPay.bind(this);
        this.onChangeLimit = this.onChangeLimit.bind(this);
        this.onChangeDeliveryTime = this.onChangeDeliveryTime.bind(this);
        this.onChangeRouteType = this.onChangeRouteType.bind(this);
        this.onChangeEnabled = this.onChangeEnabled.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.openDeleteModal = this.openDeleteModal.bind(this);

        this.state = {
            id: 0,
            country_id: 0,
            countries: [],
            state_id: 0,
            states: [],
            city_id: 0,
            cities: [],
            citiesArray: [],
            copyCitiesArray: [],
            citiesIncluded: [],
            copyCitiesIncluded: [],
            isSelected: false,
            selecteds: [],
            disSelecteds: [],
            waypay: 0,
            limit: 0,
            courier_id: 0,
            couriers: [],
            delivery_time: '',
            route_type: '',
            enabled: true,
            loading: true,
            resources: [],
            resultArr: [],
            resultIncludedArr: [],
            userId: 0,
            creation: '',
            creationUser: '',
            lastUpdate: '',
            lastUpdateUser: '',
            viewMode: false,
            openModal: false,
            cityBlocked: [],
            paymethods: [],

        };
    }

    componentDidMount() {
        this.getResources();
        this.populateData();
        helpers.setNavActive("#nav-map-courier-city");

        let currentUser = helpers.getUserData();
        this.setState({
            userId: currentUser.id
        });

        let url = window.location.href;
        if (url.includes('view')) {
            this.setState({
                viewMode: true
            });
        }
        let sort = JSON.parse(localStorage.getItem('MapCourierCityListSorting'))
        localStorage.setItem('CopyMapCourierCityListSorting', JSON.stringify(sort));

        let paging = JSON.parse(localStorage.getItem('MapCourierCityListPagination'))
        localStorage.setItem('CopyMapCourierCityListPaging', JSON.stringify(paging));

        let filtering = JSON.parse(localStorage.getItem('MapCourierCityListFiltering'))
        localStorage.setItem('CopyMapCourierCityListFiltering', JSON.stringify(filtering));

    }

    async onSubmit(e) {
        e.preventDefault();
        this.setState({ loading: true });

        let citiesIds = [];
        for (let i = 0; i < this.state.citiesIncluded.length; i++){
            citiesIds.push(this.state.citiesIncluded[i].id);
        }

        const data = {
            id: this.props.match.params.id,
            country_id: this.state.country_id,
            state_id: this.state.state_id,
            waypay_id: this.state.waypay,
            limit: this.state.limit,
            courier_id: this.state.courier_id,
            delivery_time: this.state.delivery_time,
            route_type: this.state.route_type,
            cities: citiesIds.toString(),
            enabled: this.state.enabled,
            last_update_user_id: this.state.userId,
        };
        // console.log(data);
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME)
        };

        axios.post(window.config.REACT_APP_PRODUCTS_API_URL + '/mapcouriercity/save', data, {
            headers: headers
        })
            .then((response) => {
                helpers.notifySuccess(this.state.resources.Label_Success, this.state.resources.SuccessMessage_DataSavedSuccessfully);
                this.props.history.push('/map-courier-city');
            })
            .catch((error) => {
                console.log(error);

                let msg = this.state.resources.ErrorMessage_UnknownError;
                if (error.response.data.error_code == window.errorCodes.DuplicatedEntityName)
                    msg = this.state.resources.ErrorMessage_DuplicatedName;

                helpers.notifyError(this.state.resources.Label_Error, msg);
                this.setState({ loading: false });
            });
    }  

    onCancel(e) {
        e.preventDefault();
        this.props.history.push("/map-courier-city");
    }

    onChangeCountry(e) {
        this.setState({
            country_id: e.target.value,
            citiesArray: [],
        });
    }
    
    onChangeCourier(e) {
        e.preventDefault();
        this.setState({
            courier_id: e.target.value
        });
    }

    onChangeState(e) {
        e.preventDefault();
        this.setState({
            state_id: e.target.value,
            citiesArray: [],
            citiesIncluded: [],
            selecteds: []
        });
    }

    addCityList(e) {
        e.preventDefault();
        // console.log(e.target.value);
        let arr = e.target.value.split(';');
        // console.log(arr);
        let myCity = {
            id: arr[0],
            name: arr[1]
        }
         this.state.citiesArray.map(city => {
            if(city.name == myCity.name) {
                // console.log('duplicated');
                // console.log(city);
                for (let i = 0; i < this.state.citiesArray.length; i++){
                    if(this.state.citiesArray[i].name == city.name) {
                        this.state.citiesArray.splice(i, 1);
                    }
                }
            } else {
                
            }
        })
        this.setState({
            citiesArray: this.state.citiesArray.concat(myCity),
            copyCitiesArray: this.state.citiesArray.concat(myCity),
        });
        // console.log(this.state.citiesArray);
    }

    selectCity(e) {
        e.preventDefault();
        // this.state.selecteds = [];
        this.state.isSelected = !this.state.isSelected;
        // console.log(e.target);
        let element = e.target;
        // console.log(e.target.id);
        if (element.classList.contains('selected')) {
            element.classList.remove('selected');
             let arr = element.id.split(';');
            let myCity = {
                id: arr[0],
                name: arr[1]
            }
            
            for (let i = 0; i < this.state.selecteds.length; i++){
                if (this.state.selecteds[i].name == myCity.name) {
                    // console.log(this.state.selecteds[i]);
                    this.state.selecteds.splice(i, 1);
                }
            }

        } else {
            element.classList.add('selected');
            let arr = element.id.split(';');
            let myCity = {
                id: arr[0],
                name: arr[1]
            }

            this.state.selecteds = this.state.selecteds.concat(myCity);

        }

    }

    disSelectCity(e) {
        e.preventDefault();

        let element = e.target;
        // console.log(e.target.id);
        if (element.classList.contains('unselected')) {
            element.classList.remove('unselected');
             let arr = element.id.split(';');
            let myCity = {
                id: arr[0],
                name: arr[1]
            }

            for (let i = 0; i < this.state.disSelecteds.length; i++){
                if (this.state.disSelecteds[i].name == myCity.name) {
                    this.state.disSelecteds.splice(i, 1);
                }
            }

        } else {
            element.classList.add('unselected');
            let arr = element.id.split(';');
            let myCity = {
                id: arr[0],
                name: arr[1]
            }

            this.state.disSelecteds = this.state.disSelecteds.concat(myCity);

        }
    }

    includeCity(e) {
        e.preventDefault();
        if (this.state.selecteds !== []) {
            for (let i = 0; i < this.state.selecteds.length; i++){
                for (let c = 0; c < this.state.citiesIncluded.length; c++){
                   
                    for (let x = 0; x < this.state.citiesArray.length; x++){
                        if (this.state.selecteds[i].name == this.state.citiesIncluded[c].name &&
                            this.state.selecteds[i].name == this.state.citiesArray[x].name) {
                            this.state.selecteds.splice(i, 1);
                            this.state.citiesArray.splice(x, 1);
                        }
                    }
                    
                }
            }
            this.setState({
                citiesIncluded: this.state.citiesIncluded.concat(this.state.selecteds),
                copyCitiesIncluded: this.state.citiesIncluded.concat(this.state.selecteds),
            });
            for (let i = 0; i < this.state.selecteds.length; i++){
                for (let c = 0; c < this.state.citiesArray.length; c++){
                    if (this.state.selecteds[i].name == this.state.citiesArray[c].name) {
                        this.state.citiesArray.splice(c, 1);
                    }
                }
            }
            
            this.state.selecteds = [];
            let elements = document.getElementsByClassName('selected');
            for (let i = 0; i < elements.length; i++){
                if (elements[i].classList.contains('selected')) {
                    // console.log(elements[i]);
                    // elements[i].style.display = 'none';
                    elements.item(i).setAttribute('display', 'none')
                }
            }

            document.querySelector('#searchArr').value = '';
            this.setState({
                resultArr: []
            })

            setTimeout(() => {
                for (let i = 0; i < this.state.citiesIncluded.length; i++) {
                    let arr = new Set(this.state.cityBlocked.concat(Number(this.state.citiesIncluded[i].id)))
                    this.setState({
                        cityBlocked: [...arr]
                    });
                }
                // console.log(this.state.cityBlocked)
            }, 1000)
        }
    }

    disIncludeCity(e) {
        e.preventDefault();
        // console.log(this.state.disSelecteds);
        if (this.state.disSelecteds !== []) {
            this.setState({
                citiesArray: this.state.citiesArray.concat(this.state.disSelecteds),
                copyCitiesArray: this.state.citiesArray.concat(this.state.disSelecteds),
            });
            for (let i = 0; i < this.state.disSelecteds.length; i++){
                for (let c = 0; c < this.state.copyCitiesIncluded.length; c++){
                    if (this.state.disSelecteds[i].name == this.state.copyCitiesIncluded[c].name) {
                        this.state.copyCitiesIncluded.splice(c, 1);
                    }
                }
            }
            this.state.disSelecteds = [];
            let elements = document.getElementsByClassName('unselected');
            for (let i = 0; i < elements.length; i++){
                // elements[i].style.display = 'none';
                 elements.item(i).setAttribute('display', 'none')
            }
            document.querySelector('#searchInc').value = '';
            this.setState({
                resultIncludedArr: []
            })
        }
        
        this.state.citiesIncluded = this.state.copyCitiesIncluded;
        this.setState({
            citiesIncluded: this.state.copyCitiesIncluded
        })

        setTimeout(() => {
            this.setState({
                cityBlocked: []
            })
            for (let i = 0; i < this.state.citiesIncluded.length; i++) {
                    let arr = new Set(this.state.cityBlocked.concat(Number(this.state.citiesIncluded[i].id)))
                    this.setState({
                        cityBlocked: [...arr]
                    });
                }
                // console.log(this.state.cityBlocked)
            }, 1000)
    }

    addAllCities(e) {
        e.preventDefault();
        for (let i = 0; i < this.state.citiesArray.length; i++) {
            for (let c = 0; c < this.state.citiesIncluded.length; c++) {
                if(this.state.citiesArray.length > 0){
                    if (this.state.citiesArray[i]) {
                        if (this.state.citiesArray[i].name == this.state.citiesIncluded[c].name) {
                            this.state.citiesArray.splice(i, 1);
                        }
                    }
                }
            }
        }
        let myArr = this.state.citiesIncluded.concat(this.state.citiesArray);
       
        for (let i = 0; i < myArr.length; i++) {
            if (myArr[i].name == undefined) {
                myArr.splice(i, 1);   
            }
        }
        //  console.log(myArr);
        this.setState({
            citiesIncluded: myArr,
            copyCitiesIncluded: myArr,
            citiesArray: [],
            copyCitiesArray: [],
        });

        setTimeout(() => {
                for (let i = 0; i < this.state.citiesIncluded.length; i++) {
                    let arr = new Set(this.state.cityBlocked.concat(Number(this.state.citiesIncluded[i].id)))
                    this.setState({
                        cityBlocked: [...arr]
                    });
                }
                // console.log(this.state.cityBlocked)
            }, 1000)
        
    }

    removeAllCities(e) {
        e.preventDefault();
        this.setState({
            citiesIncluded: [],
            copyCitiesIncluded: [],
            cityBlocked: [],
        });
    }

    searchCitiesArray(e) {
        e.preventDefault();
        this.setState({
            resultArr: []
        })
        this.state.resultArr = [];
        for (let i = 0; i < this.state.citiesArray.length; i++){
            if (this.state.citiesArray[i].name.toString().toLowerCase().includes(e.target.value)) {
                this.setState({
                    resultArr: this.state.resultArr.concat(this.state.citiesArray[i])
                }) 
                
            }
        }
        if (e.target.value == '' || e.target.value == ' ') {
            this.setState({
                resultArr: []
            })
        }
        
    }

    searchCitiesIncluded(e) {
        e.preventDefault();
        
        this.setState({
            resultArr: []
        })
        this.state.resultIncludedArr = [];
        for (let i = 0; i < this.state.citiesIncluded.length; i++){
            if (this.state.citiesIncluded[i].name.toString().toLowerCase().includes(e.target.value)) {
                this.setState({
                    resultIncludedArr: this.state.resultIncludedArr.concat(this.state.citiesIncluded[i])
                }) 
            }
        }
        if (e.target.value == '' || e.target.value == ' ') {
            this.setState({
                resultIncludedArr: []
            })
        }
    }

    onChangeWayPay(e) {
        e.preventDefault();
        this.setState({
            waypay: e.target.value
        });
    }

    onChangeLimit(e) {
        e.preventDefault();
        this.setState({
            limit: e.target.value
        });
    }

    onChangeDeliveryTime(e) {
        e.preventDefault();
        this.setState({
            delivery_time: e.target.value
        });
    }

    onChangeRouteType(e) {
        e.preventDefault();
        this.setState({
            route_type: e.target.value
        });
    }

    onChangeEnabled(e) {
        this.setState({
            enabled: e.target.checked
        });
    }
    
    openDeleteModal(e) {
        e.preventDefault();
        
    }

    render() {
        let contents = this.state.loading
            ? helpers.showLoadingOverlay()
            :
            <>
            <form className="form row g-3 needs-validation" onSubmit={this.onSubmit}>
                <div className="form-group col-md-12">
                    <div className="form-check form-switch">
                        <input className="form-check-input" disabled={this.state.viewMode} type="checkbox" id="enabled" defaultChecked={this.state.enabled} onChange={this.onChangeEnabled} />
                        <label className="form-check-label" htmlFor="enabled">{this.state.resources.Label_Enabled}</label>
                        <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                    </div>
                </div>

                <div className="col-md-3">
                    <label className=" form-label">{this.state.resources.Label_Country}</label>
                    <select className="form-control" disabled={this.state.viewMode} data-val="true" name="country_id" defaultValue={this.state.country_id} onChange={this.onChangeCountry} required>
                        <option value="">{this.state.resources.Label_SelectOption}</option>
                        {this.state.countries.map(country =>
                            <option key={country.id} selected={this.state.country_id == country.id} value={country.id}>{country.name}</option>
                        )}
                    </select>
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>

                    <label className=" form-label">{this.state.resources.Title_State}</label>
                    <select disabled={this.state.viewMode} className="form-control" data-val="true" name="state_id" defaultValue={this.state.state_id} onChange={this.onChangeState} required>
                        <option value="">{this.state.resources.Label_SelectOption}</option>
                        {this.state.states.map(state =>
                             state.country_id == this.state.country_id &&
                            <option key={state.id} selected={this.state.state_id == state.id} value={state.id}>{state.name}</option>
                        )}
                    </select>
                </div>
                <div className="col-md-6 dis-flex">
                    
                    <div className="card mx-1" style={{width: '45%'}}>
                        <div className="card-header">
                            {this.state.resources.Label_Cities}     
                        </div>
                        <div className="card-body">
                            <select disabled={this.state.viewMode} className="form-control" data-val="true" name="city_id" defaultValue={this.state.city_id} onChange={this.addCityList} required>
                                <option>{this.state.resources.Label_SelectOption}</option>
                                {this.state.cities.map(city =>
                                    city.state_id == this.state.state_id ?
                                        <option key={city.id} hidden={this.state.cityBlocked.includes(city.id)} value={city.id + ';' + city.name}>{city.name}</option>
                                    :<></> 
                                )} 
                            </select>
                            <input
                                disabled={this.state.state_id == 0}
                                type="text"
                                className="form-control my-1"
                                placeholder="Buscar"
                                onChange={this.searchCitiesArray}
                                id="searchArr"
                            />
                            <div>
                                <ul className="p-0 text-center">
                                    {this.state.resultArr.map(city =>
                                        <>
                                            <button className="btn list-items mx-5" id={city.id + ';' + city.name} onClick={this.selectCity}>
                                                {city.name}
                                            </button>
                                        </>
                                    )}
                                </ul>
                                <hr></hr>
                                <ul className="p-0 text-center">
                                    {this.state.citiesArray.map(city =>
                                        <>
                                            <button className="btn list-items mx-5" id={city.id + ';' + city.name} onClick={this.selectCity}>
                                                {city.name}
                                            </button>
                                        </>
                                    )}
                                </ul>
                            </div>
                        </div>
                        <div className="card-footer text-center">
                            <button className="btn btn-outline-secondary" onClick={this.addAllCities}>{this.state.resources.Label_AddAll}</button>
                        </div>
                    </div>
                    
                    <div className="text-center">
                        <button className="btn btn-primary m-1 p-2" onClick={this.includeCity}> {'>>'} </button>
                        <br></br>
                        <button className="btn btn-primary m-1 p-2" onClick={this.disIncludeCity}> {'<<'} </button>
                    </div>

                    <div className="card mx-1" style={{width: '45%'}}>
                        <div className="card-header">
                        {this.state.resources.Label_CitiesIncluded}
                        </div>
                        <div className="card-body">
                            <input
                                disabled={this.state.state_id == 0}
                                type="text"
                                className="form-control my-1"
                                placeholder="Buscar"
                                id="searchInc"
                                onChange={this.searchCitiesIncluded}
                            />
                            <div>
                                <ul className="p-0 text-center">
                                    {this.state.resultIncludedArr.map(city =>
                                        <>
                                            {/* // <li id={'cityItem'+city.id} className="list-items" onClick={this.selectCity(city.id + ';' + city.name)}>
                                        //     {city.name}
                                        // </li> */}
                                            <button className="btn list-items mx-5" id={city.id + ';' + city.name} onClick={this.disSelectCity}>
                                                {city.name}
                                            </button>
                                        </>
                                    )}
                                </ul>
                                <hr></hr>
                                <ul className="p-0 text-center">
                                    {this.state.citiesIncluded.map(city =>
                                        <>
                                            {/* <li id={'cityItem' + city.id} className="list-items">{city.name}</li> */}
                                            <button className="btn list-items mx-5" id={city.id + ';' + city.name} onClick={this.disSelectCity}>
                                                {city.name}
                                            </button>
                                        </>
                                    )}
                                </ul>
                            </div>
                        </div>
                        <div className="card-footer text-center">
                            <button className="btn btn-outline-secondary" onClick={this.removeAllCities}>{this.state.resources.Label_RemoveAll}</button>
                        </div>
                    </div>

                </div>
                <div className="col-md-3">
                    <h5 className=" form-label">{this.state.resources.Label_Options}</h5>

                    <label className=" form-label">{this.state.resources.Label_PayMethod}</label>
                    <select disabled={this.state.viewMode} className="form-control" data-val="true" name="waypay" defaultValue={this.state.waypay} onChange={this.onChangeWayPay}>
                        <option value="">{this.state.resources.Label_SelectOption}</option>
                        {this.state.paymethods.map(paymethod =>
                            <option key={paymethod.id} selected={this.state.waypay == paymethod.id} value={paymethod.id}>{paymethod.method == 'All' ? this.state.resources.Label_All : paymethod.method}</option>
                        )}
                        {/* <option value="all" selected={this.state.waypay == 'all'}>{this.state.resources.Label_All}</option> */}
                    </select>

                    <label className=" form-label">{this.state.resources.Label_Limit}</label>
                    <input disabled={this.state.viewMode} type="number" value={this.state.limit} className="form-control" onChange={this.onChangeLimit}/>

                    <label className=" form-label">{this.state.resources.Title_Courier}</label>
                    <select disabled={this.state.viewMode} className="form-control" data-val="true" name="courier_id" defaultValue={this.state.courier_id} onChange={this.onChangeCourier} required>
                        <option value="">{this.state.resources.Label_SelectOption}</option>
                        {this.state.couriers.map(courier =>
                             courier.country_id == this.state.country_id &&
                            <option key={courier.id} selected={this.state.courier_id == courier.id} value={courier.id}>{courier.name}</option>
                        )}
                    </select>

                    <label className=" form-label">{this.state.resources.Label_DeliveryTime}</label>
                    <input type="text" disabled={this.state.viewMode} value={this.state.delivery_time} className="form-control" onChange={this.onChangeDeliveryTime}/>
                    
                    <label className=" form-label">{this.state.resources.Label_RouteType}</label>
                    <select disabled={this.state.viewMode} className="form-control" data-val="true" name="route_type" defaultValue={this.state.route_type} onChange={this.onChangeRouteType}>
                        <option value="">{this.state.resources.Label_SelectOption}</option>
                        <option value="principal" selected={this.state.route_type == 'principal'}>Ruta Principal</option>
                        <option value="exception" selected={this.state.route_type == 'exception'}>Ruta Excepción</option>
                        <option value="blocking" selected={this.state.route_type == 'blocking'}>Ruta Bloqueo</option>
                    </select>

                    {/* <button type="button" className="btn btn-danger m-2"><i className="fa fa-trash"></i></button> */}
                    
                    <BasicModal values={this.state}></BasicModal>
                    
                </div>

                {/*LAST UPDATE / LAST UPDATE USER*/}
                <div className="form-group col-md-6" >
                    <DataCreation resources={this.state}></DataCreation>
                </div>

                {/**Buttons Header */}
                <div className="buttons-head row">
                    {this.state.viewMode == false &&
                        <div className=" buttons-childs">
                            <button type="submit" className="btn btn-primary">{this.state.resources.ButtonLabel_Save}</button>
                            <button className="btn btn-danger" onClick={this.onCancel}>{this.state.resources.ButtonLabel_Cancel}</button>
                        </div>
                    }
                    {this.state.viewMode == true &&
                        <div className=" buttons-childs">
                            <button className="btn btn-danger btn-block" onClick={this.onCancel}>{this.state.resources.Label_GoBack}</button>
                        </div>
                    }
                 </div>
            </form>
        </>
            helpers.hideLoadingOverlay();

        return (
            <div className="mt-2">
                <ol className="breadcrumb my-4">
                    <li className="breadcrumb-item active">{this.state.resources.Title_MapCourierCity}</li>
                </ol>
                <div className="card">
                    <div className="card-body">
                        {contents}
                    </div>
                </div>
            </div>
        );
    }

    async getResources() {
        const resourceVars = [];
        resourceVars.push("Title_MapCourierCity");
        resourceVars.push("Label_Name");
        resourceVars.push("Label_Description");
        resourceVars.push("Label_Enabled");
        resourceVars.push("Label_Success");
        resourceVars.push("ButtonLabel_Save");
        resourceVars.push("ButtonLabel_Cancel");
        resourceVars.push("Label_Error");
        resourceVars.push("ErrorMessage_RequiredField");
        resourceVars.push("ErrorMessage_DuplicatedName");
        resourceVars.push("ErrorMessage_UnknownError");
        resourceVars.push("SuccessMessage_DataSavedSuccessfully");
        resourceVars.push("Label_PayMethod");
        resourceVars.push("Label_DeliveryTime");
        resourceVars.push("Label_Limit");
        resourceVars.push("Label_RouteType");
        resourceVars.push("Title_Courier");
        resourceVars.push("Label_Country");
        resourceVars.push("Title_City");
        resourceVars.push("Title_State");
        resourceVars.push("Label_SelectOption");
        resourceVars.push("Label_Options");
        resourceVars.push("Label_Cities");
        resourceVars.push("Label_CitiesIncluded");
        resourceVars.push("Label_AddAll");
        resourceVars.push("Label_RemoveAll");
        resourceVars.push("Label_All");
        resourceVars.push("Label_GoBack");
        resourceVars.push("Label_Creation");
        resourceVars.push("Label_CreationUser");
        resourceVars.push("Label_LastUpdate");
        resourceVars.push("Label_LastUpdateUser");
        resourceVars.push("Label_Yes");
        resourceVars.push("Label_DeleteRoute")

        const headers = {
            'Content-Type': 'application/json'
        };

        axios.post(window.config.REACT_APP_SECURITY_API_URL + '/languageresource/getbyvars/' + helpers.getUiLanguage(), resourceVars, {
            headers: headers
        })
            .then((response) => {
                const data = response.data.data;
                this.setState({ resources: data, loading: false });
            })
            .catch((error) => {
                console.log(error);
                this.setState({ loading: false });
            });
    }

    async populateData() {
        this.setState({
            loading: false
        });
        let authProducts = await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME);
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/mapcouriercity/getbyid/' + this.props.match.params.id,
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({
                    id: data.id,
                    country_id: data.country_id,
                    courier_id: data.courier_id,
                    state_id: data.state_id,
                    delivery_time: data.delivery_time,
                    limit: data.limit,
                    route_type: data.route_type,
                    waypay: data.waypay_id,
                    creation: helpers.getDisplayDateTimeFromUtc(data.creation),
                    creationUser: data.creation_user_fullname,
                    lastUpdate: data.last_update == null ? '' : helpers.getDisplayDateTimeFromUtc(data.last_update),
                    lastUpdateUser: data.last_update_user_fullname == null ? '' : data.last_update_user_fullname,
                    loading: false
                });
                // console.log(data.cities.split(','));
                for (let i = 0; i < data.cities.split(',').length; i++) {
                    axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/city/getbyid/' + data.cities.split(',')[i],
                        {
                            'headers': { 'Authorization': authProducts }
                        })
                        .then(response => {
                            // console.log(response.data.data)
                            let myCity = {
                                id: response.data.data.id,
                                name: response.data.data.name,
                            }
                            this.setState({
                                citiesIncluded: [...this.state.citiesIncluded, myCity]
                            });
                            setTimeout(() => {
                                for (let i = 0; i < this.state.citiesIncluded.length; i++) {
                                    this.setState({
                                        cityBlocked: this.state.cityBlocked.concat(this.state.citiesIncluded[i].id)
                                    });
                                }
                                // console.log(this.state.cityBlocked)
                            }, 1000);
                         })
                        .catch(error => {
                            console.log(error)
                        });
                }
            })
            .catch(function (error) {
                console.log(error);
            });
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/country/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ countries: data, loading: false });
                // order alphabetically
                this.state.countries.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                // console.log(this.state.countries);
            })
            .catch(function (error) {
                console.log(error);
            });
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/state/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ states: data, loading: false });
                // order alphabetically
                this.state.states.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                // console.log(this.state.states);
            })
            .catch(function (error) {
                console.log(error);
            });
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/city/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ cities: data, loading: false });
                // order alphabetically
                this.state.cities.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                // console.log(this.state.cities);
            })
            .catch(function (error) {
                console.log(error);
            });
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/courier/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ couriers: data, loading: false });
                // order alphabetically
                this.state.couriers.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                // console.log(this.state.couriers);
            })
            .catch(function (error) {
                console.log(error);
            });
            axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/paymethod/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ paymethods: data, loading: false });
                // order alphabetically
                this.state.paymethods.sort((a,b) => a.method < b.method ? -1 : +(a.method> b.method))
                // console.log(this.state.paymethods);
            })
            .catch(function (error) {
                console.log(error);
            });
    }
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};



export default function BasicModal(values, props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleSubmit = async() => {
    //   console.log(values);
        const data = {
            id: values.values.id,
            country_id: values.values.country_id,
            state_id: values.values.state_id,
            waypay_id: values.values.waypay,
            limit: values.values.limit,
            courier_id: values.values.courier_id,
            delivery_time: values.values.delivery_time,
            route_type: values.values.route_type,
            cities: '',
            enabled: false,
            last_update_user_id: values.values.userId,
      };
      const headers = {
            'Content-Type': 'application/json',
            'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME)
        };
    //   console.log(data);
        axios.post(window.config.REACT_APP_PRODUCTS_API_URL + '/mapcouriercity/save', data, {
            headers: headers
        })
            .then((response) => {
                helpers.notifySuccess(values.values.resources.Label_Success, values.values.resources.SuccessMessage_DataSavedSuccessfully);
                window.history.back();
            })
            .catch((error) => {
                console.log(error);

                let msg = 'Error';
                if (error.response.data.error_code == window.errorCodes.DuplicatedEntityName)
                    msg = values.values.resources.ErrorMessage_DuplicatedName;

                helpers.notifyError(values.values.resources.Label_Error, msg);
            });
    }
    
    
  return (
    <div>
      <Button onClick={handleOpen} style={{color: 'white', backgroundColor: 'red', margin: '5px'} }><i className="fa fa-trash"></i></Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2" className='text-center'>
            {values.values.resources.Label_DeleteRoute}
          </Typography>
          <hr></hr>
            <div style={{display: 'flex',justifyContent: 'space-evenly'}}>
                <button className="btn btn-primary" onClick={handleSubmit}>{values.values.resources.Label_Yes}</button>
                <button className="btn btn-danger" onClick={handleClose}>{values.values.resources.ButtonLabel_Cancel}</button>
            </div>
        </Box>
      </Modal>
    </div>
  );
}