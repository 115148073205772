import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';  
import $ from 'jquery';
import helpers from '../../Helpers';
import { render } from 'react-dom';

import '../../custom.css';

export default function RutInput(values) {

    // let phoneErrorDiv = document.querySelector('#phoneError');
    // console.log(values);

    let invalidRutMsg;
    let rutError = document.querySelector('#rutError');

    let Fn = {
        // Valida el rut con su cadena completa "XXXXXXXX-X"
        validaRut : function (rutCompleto) {
            if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test( rutCompleto ))
                return false;
            let tmp  = rutCompleto.split('-');
            let digv = tmp[1]; 
            let rut  = tmp[0];
            if ( digv == 'K' ) digv = 'k' ;
            return (Fn.dv(rut) == digv );
        },
        dv : function(T){
            let M=0,S=1;
            for(;T;T=Math.floor(T/10))
                S=(S+T%10*(9-M++%6))%11;
            return S?S-1:'k';
        }
    }
    // console.log(Fn.validaRut(e.target.value) ? 'Valido' : 'inválido');
    // if (Fn.validaRut(values?.documentNumber)) {
    //     invalidRutMsg = false;
    // } else {
    //     invalidRutMsg = true;
    // }

    if(rutError) {
        rutError.style.display = 'none';
    }

    return (
        <> 
            <input className="form-control mx-1" type="text" name="documentNumber" defaultValue={values?.documentNumber}
                onChange={values?.changeDocNumber} disabled={values?.disabledForm} onKeyDown={(evt) => {
                    if (["K", "k", "-", "0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "Backspace","ArrowLeft","ArrowRight"].includes(evt.key)) {
                        
                    }else{
                         evt.preventDefault();
                    }
                }}
                onKeyPress={(evt) => {
                    // console.log(evt.target.value.split(""));
                    if (["-"].includes(evt.key)) {
                        if (evt.target.value.split("").length > 8) { //evt.target.value.split("").length < 8 ||
                            evt.preventDefault();
                        }
                        if (evt.target.value.split("").filter(x => x == "-").length > 0) {
                            evt.preventDefault();
                        }
                    }
                    if (["k", "K"].includes(evt.key)) {
                        if (evt.target.value.split("").length < 7) {
                            evt.preventDefault();
                        }
                    }
                    if (!["-"].includes(evt.key)) {
                        let charArr = evt.target.value.split("");
                        if (charArr?.reverse()[1] == '-') {
                            evt.preventDefault();
                        }
                    }
                    
                 }}
                maxLength={10}/>
            {/* {invalidRutMsg ? <p style={{color: 'red'}}> */}
                {/* <p style={{color: 'red'}} id="rutError">{localStorage.getItem('WspLanguage') == 'language0' ? 'invalid RUT number' : 'RUT no valido, por favor ingresar nuevamente'}</p>  */}
            {/*: <></>*/}
        </>
    )
}