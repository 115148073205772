import React, { Component, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';  
import $ from 'jquery';
import DataTable from 'datatables.net';
import helpers from '../../Helpers';
import '../../custom.css';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/styles';
import { DataGrid, useGridApiRef, esES } from '@mui/x-data-grid';
import { DataGridPro } from '@mui/x-data-grid-pro';
import CircularProgress from '@mui/material/CircularProgress';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import {CSVLink} from 'react-csv'
import {CSVReader } from 'react-papaparse'
import Papa from "papaparse";

LicenseInfo.setLicenseKey(
  '97124f3444ae3cf552c73ef2f3487b08T1JERVI6MzEzNDQsRVhQSVJZPTE2NjY1MzkzOTkwMDAsS0VZVkVSU0lPTj0x',
);

export class MapCourierExportRoutes extends Component {
    static displayName = MapCourierExportRoutes.name;

    constructor(props) {
        super(props);
        this.state = {
            items: [],
            csvItems: [],
            resources: [],
            loading: true,
            permissionsIds: [],
            citiesList: [],
            columns: [],
            paging: null,
            sorting: null,
            filtering: null,
            importItems: [],
            token:"",
            userId: 0,
            importOK: 0,
            resultImport: [],
            mensajeOK: ""

        };

    }

    componentDidMount() {
        let userData = helpers.getUserData();
        this.state.permissionsIds = userData.permission_ids;
        this.state.userId = userData.id;
        this.getResources();
        this.populateData();
        helpers.setNavActive("#nav-map-courier-export");
    }

      render() {
           let contents = this.state.loading
               ? helpers.showLoadingOverlay()
               : <MapCourierCityDataGrid data={this.state} />
              //MapCourierCityList.renderTable(this.state.items, this.state.resources, this.state.permissionsIds, this.state.citiesList);

          return (
              <div className="mt-2">
                  <ol className="breadcrumb my-4">
                      <li className="breadcrumb-item active">{this.state.resources.Title_ExportRoutes}</li>
                  </ol>
                  <div className="card">
                      <div className="card-header">
                      {this.state.permissionsIds.includes(136) ?
                      <CSVLink data={this.state.csvItems} filename="MAPA_DESTINOS.csv"><button className="btn btn-success">{this.state.resources.Label_Export}</button></CSVLink>
                      : <></>}
                      {/* &nbsp; &nbsp; 
                      {this.state.permissionsIds.includes(136) ?
                      <CSVLink data={this.state.csvItems} filename="MAPA_DESTINOS.csv"><button className="btn btn-success">{this.state.resources.Label_Export}</button></CSVLink>
                      : <></>} */}
                     
                        </div>
                      
                      <div className="card-body">
                          <div className="table-responsive">
                              {/* {contents} */}
                          </div>
                      </div>
                  </div>
              </div>
        );
      }

    async populateData() {
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/mapcouriercity/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ items: data, loading: false });
                this.state.columns = [
                    {
                        field: 'actions',
                        headerAlign: "center",
                        headerClassName: "header-table-mui",
                        headerName: this.state.resources.Label_Actions ? this.state.resources.Label_Actions : 'Actions',
                        flex: 1,
                        minWidth: 140,
                        renderCell: (item) => {
                        }
                    },
                ];
                helpers.hideLoadingOverlay();
            })
            .catch(function (error) {
                console.log(error);
                helpers.hideLoadingOverlay();
            });
            axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/mapcouriercity/getallcsv',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {

                const data = response.data.data;
                console.log(data);
                this.setState({ csvItems: data, loading: false });
                helpers.hideLoadingOverlay();
            })
            .catch(function (error) {
                console.log(error);
                helpers.hideLoadingOverlay();
            });
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/city/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ citiesList: data, loading: false });
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    async getResources() {
        const resourceVars = [];
        resourceVars.push("Title_MapCourierCity");
        resourceVars.push("Title_Courier");
        resourceVars.push("Title_City");
        resourceVars.push("Title_State");
        resourceVars.push("Label_ID");
        resourceVars.push("Label_Name");
        resourceVars.push("Label_Description");
        resourceVars.push("Label_Actions");
        resourceVars.push("Label_Status");
        resourceVars.push("Label_Enabled");
        resourceVars.push("Label_Disabled");
        resourceVars.push("ButtonLabel_ViewEdit");
        resourceVars.push("ButtonLabel_New");
        resourceVars.push("Datatables_language_lengthMenu");
        resourceVars.push("Datatables_language_zeroRecords");
        resourceVars.push("Datatables_language_info");
        resourceVars.push("Datatables_language_infoEmpty");
        resourceVars.push("Datatables_language_infoFiltered");
        resourceVars.push("Datatables_language_search");
        resourceVars.push("Datatables_language_paginate_previous");
        resourceVars.push("Datatables_language_paginate_next");
        resourceVars.push("Label_DeliveryTime");
        resourceVars.push("Label_PayMethod");
        resourceVars.push("Label_Limit");
        resourceVars.push("Label_RouteType");
        resourceVars.push("Title_Courier");
        resourceVars.push("Label_Country");
        resourceVars.push("Title_City");
        resourceVars.push("Title_State");
        resourceVars.push("Label_SelectOption");
        resourceVars.push("Label_Options");
        resourceVars.push("Label_Cities");
        resourceVars.push("Label_All");
        resourceVars.push("Label_LookCities");
        resourceVars.push("Label_DeleteRoute");
        resourceVars.push("ButtonLabel_Cancel");
        resourceVars.push("Label_Yes");
        resourceVars.push("SuccessMessage_DataSavedSuccessfully");
        resourceVars.push("Label_Success");
        resourceVars.push("Label_FilterOff");
        resourceVars.push("Label_Filter");
        resourceVars.push("Label_Sort");
        resourceVars.push("Label_Hide");
        resourceVars.push("Label_ShowColumns");
        resourceVars.push("Label_OrderAsc");
        resourceVars.push("Label_OrderDesc");
        resourceVars.push("Label_Unsorting");
        resourceVars.push("Label_Columns");
        resourceVars.push("Label_Delete");
        resourceVars.push("Label_GridValue");
        resourceVars.push("Label_ValueFilter");
        resourceVars.push("Label_And");
        resourceVars.push("Label_Or");
        resourceVars.push("Label_Operator");
        resourceVars.push("Label_NoRows");
        resourceVars.push("Label_SelectColumns");
        resourceVars.push("Label_OperatorAfter");
        resourceVars.push("Label_OperatorBefore");
        resourceVars.push("Label_OperatorContains");
        resourceVars.push("Label_OperatorEnds");
        resourceVars.push("Label_OperatorEquals");
        resourceVars.push("Label_OperatorIs");
        resourceVars.push("Label_OperatorIsEmpty");
        resourceVars.push("Label_OperatorIsNotEmpty");
        resourceVars.push("Label_OperatorIsNot");
        resourceVars.push("Label_OperatorIsOnOrAfter");
        resourceVars.push("Label_OperatorIsOnOrBefore");
        resourceVars.push("Label_OperatorStarts");
        resourceVars.push("Label_ReorderColumns");
        resourceVars.push("Label_HideAll");
        resourceVars.push("Label_ShowAll");
        resourceVars.push("Label_FindColumn");
        resourceVars.push("Label_TitleColumn");
        resourceVars.push("Label_addFilter");
        resourceVars.push("Label_Export");
        resourceVars.push("Title_ExportRoutes");

        const headers = {
            'Content-Type': 'application/json'
        };

        axios.post(window.config.REACT_APP_SECURITY_API_URL + '/languageresource/getbyvars/' + helpers.getUiLanguage(), resourceVars, {
            headers: headers
        })
            .then((response) => {
                const data = response.data.data;
                this.setState({ resources: data});
            })
            .catch((error) => {
                console.log(error);
            });
    }
}

function DeleteModal(values) {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    // console.log(values)
    
    const handleSubmit = async() => {
         const data = {
            id: values.item.id,
            country_id: values.item.country_id,
            state_id: values.item.state_id,
            waypay: values.item.waypay,
            limit: values.item.limit,
            courier_id: values.item.courier_id,
            delivery_time: values.item.delivery_time,
            route_type: values.item.route_type,
            cities: values.item.cities,
            enabled: false,
            last_update_user_id: values.item.userId,
      };
      const headers = {
            'Content-Type': 'application/json',
            'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME)
        };
        axios.post(window.config.REACT_APP_PRODUCTS_API_URL + '/mapcouriercity/save', data, {
            headers: headers
        })
            .then((response) => {
                // console.log(response);
                handleClose();
                helpers.notifySuccess(values.resc.Label_Success, values.resc.SuccessMessage_DataSavedSuccessfully);
                window.history.back();
                setTimeout(() => {
                    window.history.forward();    
                }, 1000)
                
            })
            .catch((error) => {
                console.log(error);

                let msg = 'Error';
                helpers.notifyError(values.resc.Label_Error, msg);
            });
    }

    return (
    <div>
      <Button onClick={handleOpen} className="mt-2" style={{color: 'black'}}><i className="fa fa-trash"></i></Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2" className='text-center'>
            {values.resc.Label_DeleteRoute}
          </Typography>
          <hr></hr>
            <div>
                <div style={{display: 'flex',justifyContent: 'space-evenly'}}> {/** */}
                    <button className="btn btn-primary" onClick={handleSubmit} >{values.resc.Label_Yes}</button>
                    <button className="btn btn-danger"  onClick={handleClose}>{values.resc.ButtonLabel_Cancel}</button>
                </div>
            </div>
        </Box>
      </Modal>
    </div>
  );
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    height: '90%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

function MapCourierCityDataGrid(values) {

    let items = values.data.items,
        resources = values.data.resources,
        permissionsIds = values.data.permissionsIds, columns = values.data.columns,
        sorting = values.data.sorting, paging = values.data.paging ? values.data.paging : 0
        , filter = localStorage.getItem('CopyMapCourierCityListFiltering') ?
            JSON.parse(localStorage.getItem('CopyMapCourierCityListFiltering')) : values.data.filtering
    
    const [page, setPage] = React.useState(paging.page);
    const [pageSize, setPageSize] = React.useState(paging.pageSize);
    
    const [value, setValue] = React.useState([null, null]);
    const [myRows, setRowsValue] = React.useState(values.data.items);
    const [filtering, setFilter] = React.useState(filter);
    const [states, setStates] = React.useState([]);
    const [state_id, onChangeState] = React.useState([null, null]);
    const [countries, setCountries] = React.useState([]);
    const [country_id, onChangeCountry] = React.useState([null, null]);
    const [route_type_id, onChangeRouteType] = React.useState([null, null]);
    const [waypay_id, onChangeWayPay] = React.useState([null, null]);
    const [couriers, setCouriers] = React.useState([]);
    const [courier_id, onChangeCourier] = React.useState([null, null]);
    const [open, setOpen] = React.useState(false);
    const openModal = () => setOpen(true);
    const closeModal = () => setOpen(false);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 1300,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    async function getCountries() {
        // console.log(status)
            helpers.showLoadingOverlay();
            axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/country/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                let data = response.data.data.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                setCountries(data)
                helpers.hideLoadingOverlay();
             })
            .catch(err => console.log(err));
    }
    async function getStates() {
        // console.log(status)
            helpers.showLoadingOverlay();
            axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/state/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
                .then(response => {
                let data = response.data.data.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                setStates(data)
                helpers.hideLoadingOverlay();
             })
            .catch(err => console.log(err));
    }
    async function getCouriers() {
        // console.log(status)
            helpers.showLoadingOverlay();
            axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/courier/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
                .then(response => {
                let data = response.data.data.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                setCouriers(data)
                helpers.hideLoadingOverlay();
             })
            .catch(err => console.log(err));
    }

    function setInitial() {
        if (countries.length < 1) {
            getCountries();
        }
        if (states.length < 1) {
            getStates();
        }
        if (couriers.length < 1) {
            getCouriers();
        }
        setTimeout(() => {
            if (document.getElementsByClassName('MuiTablePagination-selectLabel')[0]){
                document.getElementsByClassName('MuiTablePagination-selectLabel')[0].innerHTML = '';
            }
            // loading = true
            if (myRows.length < 1) {
                if (localStorage.getItem('MapCourierCityFilterItems')) {
                    let myItems = JSON.parse(localStorage.getItem('MapCourierCityFilterItems'));
                    setRowsValue(myItems);
                    setTimeout(() => {
                        localStorage.removeItem('MapCourierCityFilterItems');
                    }, 1000);
                } else {
                    setRowsValue(items)
                }
                
            }
        }, 1000)
       
    }
    setInitial()

    const handlePageChange = (newPage) => {
        setPage(newPage);
    };

    const handlePageSizeChange = (newPage) => {
        setPageSize(newPage);
    };

    const handleFilterChange = (newFilter) => {
        // console.log(newFilter)
        setFilter(newFilter);
    }

    const handleStates = (e) => {
        e.preventDefault();
        onChangeState(e.target.value);
        // console.log(e.target.value)
    }

    const handleCountry = (e) => {
        e.preventDefault();
        onChangeCountry(e.target.value);
        // console.log(e.target.value)
    }

    const handleRouteType = (e) => {
        e.preventDefault();
        onChangeRouteType(e.target.value);
        // console.log(e.target.value)
    }

    const handleWayPay = (e) => {
        e.preventDefault();
        onChangeWayPay(e.target.value);
    }

    const handleCourier = (e) => {
        e.preventDefault();
        onChangeCourier(e.target.value);
    }

    async function onReloadData() {
        let filterData = {
            country_id: country_id,
            state_id: state_id,
            waypay: waypay_id,
            courier_id: courier_id,
            route_type: route_type_id,
        };
        console.log(filterData)
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME)
        };
        // console.log(headers);
        helpers.showLoadingOverlay();
        axios.post(window.config.REACT_APP_PRODUCTS_API_URL + '/mapcouriercity/getbyfilter', filterData,
            {
                headers: headers
            })
            .then(response => {
                let newItems = response.data.data;
                // console.log(response.data.data)
                setRowsValue(newItems);
                localStorage.removeItem('MapCourierCityFilterItems');
                localStorage.setItem('MapCourierCityFilterItems', JSON.stringify(newItems));
                closeModal();
                helpers.hideLoadingOverlay();
             })
            .catch(error => {
                console.log(error)
                helpers.hideLoadingOverlay();
            });
    }

    async function resetFilter() {
        helpers.showLoadingOverlay();
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/mapcouriercity/getall',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                let newItems = response.data.data;
                setRowsValue(newItems);
                localStorage.removeItem('MapCourierCityFilterItems');
                closeModal();
                helpers.hideLoadingOverlay();
             })
            .catch(error => console.log(error));
    }

}