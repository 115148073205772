import React from 'react'
import { Component } from 'react';
/*import authService from '../api-authorization/AuthorizeService';*/
import { LoginActions } from '../api-authorization/AuthorizeService';
import { ApplicationPaths, QueryParameterNames } from '../api-authorization/ApiAuthorizationConstants';
import helpers from '../../Helpers';
import axios from 'axios';

export class PasswordChange extends Component {
    constructor(props) {
        super(props);

        this.onSubmit = this.onSubmit.bind(this);  
        this.onChangeUsername = this.onChangeUsername.bind(this);
        this.onChangePassword = this.onChangePassword.bind(this);
        this.onChangePasswordConfirm = this.onChangePasswordConfirm.bind(this);

        this.state = {
            userId: 0,
            username: '',
            password: '',
            passwordConfirm: '',
            loading: false,
            resources: []
        };
    }

    onChangeUsername(e) {
        this.setState({
            username: e.target.value
        });
    }  

    onChangePassword(e) {
        this.setState({
            password: e.target.value
        });
    }  

    onChangePasswordConfirm(e) {
        this.setState({
            passwordConfirm: e.target.value
        });
    }  

    componentDidMount() {
        this.getResources();
        this.populateData();
        helpers.setClientValidation();
    }

    async onSubmit(e) {
        e.preventDefault();

        var form = document.querySelectorAll('.needs-validation')[0];

        if (!form.checkValidity())
            return;

        if (this.state.password !== this.state.passwordConfirm) {
            helpers.notifyError(this.state.resources.Label_Error, this.state.resources.ErrorMessage_PasswordsDoNotMatch);
            return;
        }

        this.setState({ loading: true });

        const data = {
            password: this.state.password
        };
        const headers = {
            'Content-Type': 'application/json'
        };

        axios.post(window.config.REACT_APP_SECURITY_API_URL + '/user/passwordchange/' + this.state.userId, data, {
            headers: headers
        })
            .then((response) => {

                // logout & redirect
                helpers.clearUserData();
                helpers.removeCookie("ccsession");

                helpers.notifySuccess(this.state.resources.Label_Success, this.state.resources.SuccessMessage_PasswordChangedSuccessfully);

                window.location.replace(window.location.origin);
            })
            .catch((error) => {
                console.log(error);

                let msg = this.state.resources.ErrorMessage_UnknownError;
                if (error.response.data.error_code == window.errorCodes.InvalidNewPassword)
                    msg = error.response.data.message;

                helpers.notifyError(this.state.resources.Label_Error, msg);
                this.setState({ loading: false });
            });
    }

    render() {
        let contents =
            <form className="form row g-3 needs-validation" onSubmit={this.onSubmit} noValidate>
                <div className="form-group col-md-12">
                    <input type="hidden" name="id" value={this.state.id} />
                </div>

                {/*USERNAME*/}
                <div className="form-group col-md-12" >
                    <label className=" form-label">{this.state.resources.Label_Username}</label>
                    <input className="form-control" type="text" name="username" value={this.state.username} disabled />
                </div>

                {/*PASSWORD / CONFIRM PASSWORD*/}
                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_Password}</label>
                    <input className="form-control" type="password" name="password" onChange={this.onChangePassword} value={this.state.password} pattern="^.{6,80}$" required />
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_InvalidPassword}</div>
                </div>
                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_ConfirmPassword}</label>
                    <input className="form-control" type="password" name="passwordConfirm" onChange={this.onChangePasswordConfirm} value={this.state.passwordConfirm} pattern="^.{6,80}$" required />
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_InvalidPassword}</div>
                </div>

                {/*SAVE / CANCEL*/}
                <div className="form-group col-12 text-end">
                    <button type="submit" className="btn btn-primary">{this.state.resources.ButtonLabel_Confirm}</button>
                </div>

            </form>;

        return (
            <div>

                <nav className="sb-topnav navbar navbar-expand navbar-dark bg-dark">
                    <a className="navbar-brand text-center"></a>
                    <ul className="navbar-nav ml-auto">
                        {/*<li className="nav-item dropdown" style={{ display: 'inline-block' }}>*/}
                        {/*    <a className="nav-link dropdown-toggle" id="userDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{this.state.userData.username} <i className="fas fa-user fa-fw"></i></a>*/}
                        {/*    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="userDropdown">*/}
                        {/*        <a className="dropdown-item" href="#">{this.state.resources.NavItem_Profile}</a>*/}
                        {/*        <div className="dropdown-divider"></div>*/}
                        {/*        <a className="dropdown-item" href="#" onClick={this.logout()}>{this.state.resources.NavItem_Logout}</a>*/}
                        {/*    </div>*/}
                        {/*</li>*/}
                    </ul>
                </nav>

                <div id="layoutSidenav">
                    <div id="layoutSidenav_content" style={{ padding: '0' }}>
                        <main>
                            <div className="container-fluid col-md-8">
                                <div className="mt-2">
                                    <h1 className="mt-4 mb-4">{this.state.resources.Title_PasswordChange}</h1>
                                    <div className="card">
                                        <div className="card-body">
                                            {this.state.loading ? helpers.showLoadingOverlay() : helpers.hideLoadingOverlay()}
                                            {contents}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>

            </div>
        );
    }

    async populateData() {
        let userData = helpers.getUserData();

        this.setState({
            userId: userData.id,
            username: userData.username,
            loading: false
        });
    }

    async getResources() {
        const resourceVars = [];
        resourceVars.push("ErrorMessage_UnknownError");
        resourceVars.push("ErrorMessage_InvalidPassword");
        resourceVars.push("ErrorMessage_PasswordsDoNotMatch");
        resourceVars.push("ErrorMessage_NewPasswordMustBeDifferent");
        resourceVars.push("SuccessMessage_PasswordChangedSuccessfully");
        resourceVars.push("Label_Error");
        resourceVars.push("Label_Username");
        resourceVars.push("Label_Password");
        resourceVars.push("Label_Success");
        resourceVars.push("Label_ConfirmPassword");
        resourceVars.push("ButtonLabel_Confirm");
        resourceVars.push("Title_PasswordChange");
        
        const headers = {
            'Content-Type': 'application/json'
        };

        axios.post(window.config.REACT_APP_SECURITY_API_URL + '/languageresource/getbyvars/' + helpers.getUiLanguage(), resourceVars, {
            headers: headers
        })
            .then((response) => {
                const data = response.data.data;
                this.setState({ resources: data, loading: false });
            })
            .catch((error) => {
                console.log(error);
            });
    }


    getReturnUrl(state) {
        const params = new URLSearchParams(window.location.search);
        const fromQuery = params.get(QueryParameterNames.ReturnUrl);
        if (fromQuery && !fromQuery.startsWith(`${window.location.origin}/`)) {
            // This is an extra check to prevent open redirects.
            throw new Error("Invalid return url. The return url needs to have the same origin as the current page.")
        }
        return (state && state.returnUrl) ||
            fromQuery ||
            `${window.location.origin}${ApplicationPaths.Login}`;
    }

    navigateToReturnUrl(returnUrl) {
        // It's important that we do a replace here so that we remove the callback uri with the
        // fragment containing the tokens from the browser history.
        window.location.replace(returnUrl);
    }
}


export default PasswordChange;