import React, { Component } from 'react';
import axios from 'axios';
import $ from 'jquery';
import helpers from '../../Helpers'

export class DomainCreate extends Component {
    static displayName = DomainCreate.name;

    constructor(props) {
        super(props);

        this.onChangeURL = this.onChangeURL.bind(this);
        this.onChangeCommment = this.onChangeCommment.bind(this);
        this.onChangeProduct = this.onChangeProduct.bind(this);
        this.onChangeEnabled = this.onChangeEnabled.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onSubmit = this.onSubmit.bind(this);  

        this.state = {
            id: 0,
            url: '',
            comments: '',
            enabled: true,
            loading: true,
            resources: [],
            products:[],
            productId: 0,
            userId: 0,
        };
    }

    componentDidMount() {
        this.getResources();
        this.populateData();
        helpers.setNavActive("#nav-Domain");

        let currentUser = helpers.getUserData();
        this.setState({
            userId: currentUser.id
        });
    }

    async onSubmit(e) {
        e.preventDefault();
        this.setState({ loading: true });

        const data = {
            url: this.state.url,
            product_id: this.state.productId,
            comment: this.state.comment,
            enabled: this.state.enabled,
            creation_user_id: this.state.userId,
        };
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME)
        };

        axios.post(window.config.REACT_APP_PRODUCTS_API_URL + '/Domain/save', data, {
            headers: headers
        })
            .then((response) => {
                helpers.notifySuccess(this.state.resources.Label_Success, this.state.resources.SuccessMessage_DataSavedSuccessfully);
                this.props.history.push('/Domain');
            })
            .catch((error) => {
                console.log(error);

                let msg = this.state.resources.ErrorMessage_UnknownError;
                if (error.response.data.error_code == window.errorCodes.DuplicatedEntityName)
                    msg = this.state.resources.ErrorMessage_DuplicatedName;

                helpers.notifyError(this.state.resources.Label_Error, msg);
                this.setState({ loading: false });
            });
    }  

    onCancel(e) {
        e.preventDefault();
        this.props.history.push("/Domain");
    }

    onChangeURL(e) {
        this.setState({
            url: e.target.value
        });
    }  
    
    onChangeCommment(e) {
        this.setState({
            comment: e.target.value
        });
    }

    onChangeProduct(e){
        this.setState({
            productId: e.target.value
        });
    } 



    onChangeEnabled(e) {
        this.setState({
            enabled: e.target.checked
        });
    } 

    render() {
        let contents = this.state.loading
            ? helpers.showLoadingOverlay()
            :
            <form className="form row g-3 needs-validation" onSubmit={this.onSubmit}>
                <div className="form-group col-md-12">
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" id="enabled" defaultChecked={this.state.enabled} onChange={this.onChangeEnabled} />
                        <label className="form-check-label" htmlFor="enabled">{this.state.resources.Label_Enabled}</label>
                        <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                    </div>
                </div>
                <div className="form-group col-md-6" >
                <label className=" form-label">{this.state.resources.Label_Products}</label>
                            <select className="form-control" data-val="true" name="productId" defaultValue={this.state.product_id} onChange={this.onChangeProduct}>
                                <option value="">{this.state.resources.Label_SelectOption}</option>
                                {this.state.products.map(product =>
                                    <option key={product.id} value={product.id}>
                                        {product.name}
                                    </option>
                                )}
                            </select>
                </div>
                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_URL}</label>
                    <input className="form-control" type="text" name="url" onChange={this.onChangeURL} value={this.state.url} required />
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                </div>

                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_Comments}</label>
                    <input className="form-control" type="text" name="comment" onChange={this.onChangeCommment} value={this.state.comment} />
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                </div>


                {/**Buttons Header */}
                 <div className="buttons-head row">
                    <div className=" buttons-childs">
                        <button type="submit" className="btn btn-primary">{this.state.resources.ButtonLabel_Save}</button>
                        <button className="btn btn-danger" onClick={this.onCancel}>{this.state.resources.ButtonLabel_Cancel}</button>
                    </div>
                 </div>
                {/* <div className="form-group col-12 text-end">
                    <button type="submit" className="btn btn-primary">{this.state.resources.ButtonLabel_Save}</button>
                    <button className="btn btn-negative" onClick={this.onCancel}>{this.state.resources.ButtonLabel_Cancel}</button>
                </div > */}
            </form>
            helpers.hideLoadingOverlay();

        return (
            <div className="mt-2">
                {/* <h1 className="mt-4">{this.state.resources.Title_Areas}</h1> */}
                <ol className="breadcrumb my-4">
                    <li className="breadcrumb-item active">{this.state.resources.Label_Domain}</li>
                </ol>
                <div className="card">
                    <div className="card-body">
                        {contents}
                    </div>
                </div>
            </div>
        );
    }

    async getResources() {
        const resourceVars = [];
        resourceVars.push("Label_Domain");
        resourceVars.push("Label_Products");
        resourceVars.push("Label_URL");
        resourceVars.push("Label_Comments");
        resourceVars.push("Label_Enabled");
        resourceVars.push("Label_SelectOption");
        resourceVars.push("Label_Success");
        resourceVars.push("ButtonLabel_Save");
        resourceVars.push("ButtonLabel_Cancel");
        resourceVars.push("Label_Error");
        resourceVars.push("ErrorMessage_RequiredField");
        resourceVars.push("ErrorMessage_DuplicatedName");
        resourceVars.push("ErrorMessage_UnknownError");
        resourceVars.push("SuccessMessage_DataSavedSuccessfully");

        const headers = {
            'Content-Type': 'application/json'
        };

        axios.post(window.config.REACT_APP_SECURITY_API_URL + '/languageresource/getbyvars/' + helpers.getUiLanguage(), resourceVars, {
            headers: headers
        })
            .then((response) => {
                const data = response.data.data;
                this.setState({ resources: data, loading: false });
            })
            .catch((error) => {
                console.log(error);
                this.setState({ loading: false });
            });

            axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/product/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ products: data, loading: false });
                // order alphabetically
                this.state.products.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                // console.log(this.state.product);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    async populateData() {
        this.setState({
            loading: false
        });
    }
}