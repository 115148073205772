import React, { Component } from 'react';
import axios from 'axios';
import helpers from '../../Helpers';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import './Company.css';
import DataCreation from '../DataCreation/DataCreation';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import startsWith from 'lodash.startswith';

export class CompanyDetail extends Component {
    static displayName = CompanyDetail.name;
    

    constructor(props) {
        super(props);

        this.onChangeName = this.onChangeName.bind(this);
        this.onChangeFiscalId = this.onChangeFiscalId.bind(this);
        this.onChangeLogoFile = this.onChangeLogoFile.bind(this);
        this.onChangeBusinessArea = this.onChangeBusinessArea.bind(this);
        this.onChangeCountryId = this.onChangeCountryId.bind(this);
        this.onChangeEmail = this.onChangeEmail.bind(this);
        this.onChangeWeb = this.onChangeWeb.bind(this);
        this.onChangePhone1 = this.onChangePhone1.bind(this);
        this.onChangePhone2 = this.onChangePhone2.bind(this);
        this.onChangeFax = this.onChangeFax.bind(this);
        this.onChangeAddress = this.onChangeAddress.bind(this);
        this.onChangeStateOrProvince = this.onChangeStateOrProvince.bind(this);
        this.onChangeTown = this.onChangeTown.bind(this);
        this.onChangeCountry = this.onChangeCountry.bind(this);
        this.onChangePostalCode = this.onChangePostalCode.bind(this);
        this.onChangeNotes = this.onChangeNotes.bind(this);
        this.onChangeEnabled = this.onChangeEnabled.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

        this.state = {
            id: 0,
            name: null,
            fiscalId: null,
            logo: null,
            logoFile: null,
            businessAreaId: null,
            countryId: null,
            email: null,
            web: null,
            phone1: null,
            phone2: null,
            fax: null,
            address: null,
            country: null,
            stateOrProvince: null,
            town: null,
            postalCode: null,
            notes: null,
            enabled: true,
            loading: true,
            resources: [],
            businessAreas: [],
            countries: [],
            viewMode: false,
            currentUserId: 0,
        };
    }

    componentDidMount() {
        this.getResources();
        this.populateData();
        helpers.setNavActive("#nav-companies");
        helpers.setClientValidation();
   
        let url = window.location.href;
        if (url.includes('view')) {
            this.setState({
                viewMode: true
            });
        }
        let currentUser = helpers.getUserData();
        this.setState({
            currentUserId: currentUser.id
        });

        let sort = JSON.parse(localStorage.getItem('CompanyListSorting'))
        localStorage.setItem('CopyCompanyListSorting', JSON.stringify(sort));

        let paging = JSON.parse(localStorage.getItem('CompanyListPagination'))
        localStorage.setItem('CopyCompanyListPaging', JSON.stringify(paging));

        let filtering = JSON.parse(localStorage.getItem('CompanyListFiltering'))
        localStorage.setItem('CopyCompanyListFiltering', JSON.stringify(filtering));
        
        document.querySelector('#phoneError1').style.display = 'none';
        document.querySelector('#phoneError2').style.display = 'none';
    }


    async onSubmit(e) {
        e.preventDefault();

        var form = document.querySelectorAll('.needs-validation')[0];

        if (!form.checkValidity())
            return;

        this.setState({ loading: true });

        // upload logo file
        if (this.state.logoFile !== null) {
            const formData = new FormData();
            formData.append('file', this.state.logoFile);

            const uploadLogoHeaders = {
                'Content-Type': 'multipart/form-data',
                'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME)
            };

            let resp = await axios.post(window.config.REACT_APP_PRODUCTS_API_URL + '/company/uploadlogo', formData, {
                headers: uploadLogoHeaders
            })
                .then((response) => {
                    this.setState({ logo: response.data.data });

                    return response.data;
                })
                .catch((error) => {
                    console.log(error);

                    this.setState({ loading: false });
                });

            if (resp?.success !== true) {
                helpers.notifyError(this.state.resources.Label_Error, this.state.resources.ErrorMessage_ErrorWhileUploadingImage);
                return;
            }
        }

        const data = {
            id: parseInt(this.props.match.params.id),
            name: this.state.name,
            fiscal_id: this.state.fiscalId,
            logo: this.state.logo,
            country_id: this.state.countryId,
            company_businessarea_id: this.state.businessAreaId,
            email: this.state.email,
            web: this.state.web,
            fax: this.state.fax,
            phone_1: this.state.phone1,
            phone_2: this.state.phone2,
            address: this.state.address,
            country: this.state.country,
            state_or_province: this.state.stateOrProvince,
            town: this.state.town,
            postal_code: this.state.postalCode,
            notes: this.state.notes,
            enabled: this.state.enabled,
            last_update_user_id: this.state.currentUserId
        };
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME)
        };

        let errorCount = 0;
        if(document.querySelector('#phoneError1').style.display == 'block' ||
            document.querySelector('#phoneError2').style.display == 'block') {
            errorCount++;
        }

        if (errorCount == 0) {
            axios.post(window.config.REACT_APP_PRODUCTS_API_URL + '/company/save', data, {
                headers: headers
            })
            .then((response) => {
                helpers.notifySuccess(this.state.resources.Label_Success, this.state.resources.SuccessMessage_DataSavedSuccessfully);
                this.props.history.push('/companies');
            })
            .catch((error) => {
                console.log(error);

                let msg = this.state.resources.ErrorMessage_UnknownError;
                if (error.response.data.error_code == window.errorCodes.DuplicatedEntityName)
                    msg = this.state.resources.ErrorMessage_DuplicatedName;
                else if (error.response.data.error_code == window.errorCodes.AssociatedEntity)
                    msg = this.state.resources.ErrorMessage_AssociatedEntity_Company_User;

                helpers.notifyError(this.state.resources.Label_Error, msg);
                this.setState({ loading: false });
            });
        } else {
            let msg = helpers.getUiLanguage() == 'language0' ? 'Phone Data Invalid' : 'Telefono No Valido';

            helpers.notifyError(this.state.resources.Label_Error, msg);
            this.setState({ loading: false });
        }
    }  

    onCancel(e) {
        e.preventDefault();
        this.props.history.push("/companies");
    }

    onChangeName(e) {
        this.setState({
            name: e.target.value
        });
    }
    onChangeFiscalId(e) {
        this.setState({
            fiscalId: e.target.value
        });
    }
    onChangeLogoFile(e) {
        this.setState({
            logoFile: e.target.files[0],
            //show new file name in UI
            logo: e.target.files[0].name
        });
        // console.log(e.target.files[0]);
    }
    onChangeCountryId(e) {
        this.setState({
            countryId: e.target.value
        });
    }
    onChangeBusinessArea(e) {
        this.setState({
            businessAreaId: e.target.value
        });
    }
    onChangeEmail(e) {
        this.setState({
            email: e.target.value
        });
    }
    onChangeWeb(e) {
        this.setState({
            web: e.target.value
        });
    }
    onChangeFax(e) {
        this.setState({
            fax: e.target.value
        });
    }
    onChangePhone1(e) {
        this.setState({
            phone1: e
        });
    }
    onChangePhone2(e) {
        this.setState({
            phone2: e
        });
    }
    onChangeAddress(e) {
        this.setState({
            address: e.target.value
        });
    }
    onChangeCountry(e) {
        this.setState({
            country: e.target.value
        });
    }
    onChangeStateOrProvince(e) {
        this.setState({
            stateOrProvince: e.target.value
        });
    }
    onChangeTown(e) {
        this.setState({
            town: e.target.value
        });
    }
    onChangePostalCode(e) {
        this.setState({
            postalCode: e.target.value
        });
    }
    onChangeNotes(e) {
        this.setState({
            notes: e.target.value
        });
    }
    onChangeEnabled(e) {
        this.setState({
            enabled: e.target.checked
        });
    } 

    render() {

        let contents =
            <form className="form row g-3 needs-validation" onSubmit={this.onSubmit} noValidate>
                <div className="form-group col-md-12">
                    <input type="hidden" name="areaId" value={this.state.id} />
                </div>

                {/*ENABLED*/}
                <div className="form-group col-md-12">
                    <div className="form-check form-switch">
                        <input disabled={this.state.viewMode} className="form-check-input" type="checkbox" id="enabled" checked={this.state.enabled} onChange={this.onChangeEnabled} />
                        <label className="form-check-label" htmlFor="enabled">{this.state.resources.Label_Enabled}</label>
                        <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                    </div>
                </div>

                {/*LOGO FILE / NAME*/}
                <div className=" form-group col-md-6">
                    <div className="pl-3 pt-3">
                        <input disabled={this.state.viewMode}
                            accept="image/*"
                            className="input-upload"
                            id="contained-button-file"
                            multiple
                            type="file"
                            onChange={this.onChangeLogoFile}
                        />
                        <label className="px-4">{this.state.logo}</label>
                        <label htmlFor="contained-button-file">
                            <Button disabled={this.state.viewMode} variant="outlined" color="secondary" component="span">
                                Logo
                            </Button>
                        </label>
                    </div>
                    
                </div>
                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_Name}</label>
                    <input disabled={this.state.viewMode} className="form-control" type="text" name="name" onChange={this.onChangeName} value={this.state.name} required />
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                </div>

                {/*FISCAL ID / BUSINESS AREA*/}
                <div className="form-group col-md-6" >
                    <label className="form-label">{this.state.resources.Label_FiscalId}</label>
                    <input disabled={this.state.viewMode} className="form-control" type="text" name="fiscalid" maxLength="30" onChange={this.onChangeFiscalId} value={this.state.fiscalId} required />
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                </div>
                <div className=" form-group col-md-6">
                    <label className=" form-label">{this.state.resources.Label_BusinessArea}</label>
                    <select disabled={this.state.viewMode} className="form-control" data-val="true" name="businessarea" defaultValue={this.state.businessAreaId} onChange={this.onChangeBusinessArea} required>
                        <option value="">{this.state.resources.Label_SelectOption}</option>
                        {this.state.businessAreas.map(businessarea =>
                            <option key={businessarea.id} value={businessarea.id} selected={this.state.businessAreaId == businessarea.id}>{businessarea.name}</option>
                        )}
                    </select>
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                </div>

                {/*COUNTRY / EMAIL*/}
                <div className=" form-group col-md-6">
                    <label className=" form-label">{this.state.resources.Label_Country}</label>
                    <select disabled={this.state.viewMode} className="form-control" data-val="true" name="countryid" defaultValue={this.state.countryId} onChange={this.onChangeCountryId} required>
                        <option value="">{this.state.resources.Label_SelectOption}</option>
                        {this.state.countries.map(country =>
                            <option key={country.id} value={country.id} selected={this.state.countryId == country.id}>{country.name}</option>
                        )}
                    </select>
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                </div>
                <div className=" form-group col-md-6">
                    <label className="form-label">{this.state.resources.Label_Email}</label>
                    <input disabled={this.state.viewMode} className="form-control" type="email" name="email" onChange={this.onChangeEmail} value={this.state.email} />
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_InvalidEmail}</div>
                </div>

                {/*WEB / FAX*/}
                <div className=" form-group col-md-6">
                    <label className="form-label">{this.state.resources.Label_Web}</label>
                    <input disabled={this.state.viewMode} className="form-control" type="text" name="web" maxLength="50" onChange={this.onChangeWeb} value={this.state.web} />
                </div>
                <div className=" form-group col-md-6">
                    <label className="form-label">{this.state.resources.Label_Fax}</label>
                    <input disabled={this.state.viewMode} className="form-control" type="text" name="fax" maxLength="20" onChange={this.onChangeFax} value={this.state.fax} />
                </div>

                {/*PHONE 1 / PHONE 2*/}
                <div className=" form-group col-md-6">
                    <label className="form-label">{this.state.resources.Label_Phone}</label>
                    {/* <input disabled={this.state.viewMode} className="form-control" type="text" name="phone1" maxLength="20" onChange={this.onChangePhone1} value={this.state.phone1} /> */}
                    <PhoneInput
                        country={'cl'}
                        value={this.state.phone1?.toString()}
                        onChange={this.onChangePhone1}
                        masks={{ cl: '(.) ....-....' }}
                        inputStyle={{ width: '100%' }}
                        onlyCountries={['cl', 'ar', 'mx', 'uk']}
                        isValid={(value, country) => { 
                            // console.log(value);
                            // console.log(country);
                            if (startsWith(value, country.dialCode) || startsWith(country.dialCode, value)) {
                                if (document.querySelector('#phoneError1')) {
                                    document.querySelector('#phoneError1').style.display = 'none';
                                }
                                return true;
                            } else {
                                if (document.querySelector('#phoneError1')) {
                                    document.querySelector('#phoneError1').style.display = 'block';
                                }
                                return false;
                            }
                        }}
                        // disabled={this.state.viewMode}
                    />
                    <div style={{color: 'red'}} class="p-1 pl-2" id="phoneError1">{this.state.resources.ErrorMessage_InvalidPhoneFormat}</div>
                </div>
                <div className=" form-group col-md-6">
                    <label className="form-label">{this.state.resources.Label_AlternativePhone}</label>
                    {/* <input disabled={this.state.viewMode} className="form-control" type="text" name="phone2" maxLength="20" onChange={this.onChangePhone2} value={this.state.phone2} /> */}
                    <PhoneInput
                        country={'cl'}
                        value={this.state.phone2?.toString()}
                        onChange={this.onChangePhone2}
                        masks={{ cl: '(.) ....-....' }}
                        inputStyle={{ width: '100%' }}
                        onlyCountries={['cl', 'ar', 'mx', 'uk']}
                        isValid={(value, country) => { 
                            // console.log(value);
                            // console.log(country);
                            if (startsWith(value, country.dialCode) || startsWith(country.dialCode, value)) {
                                if (document.querySelector('#phoneError2')) {
                                    document.querySelector('#phoneError2').style.display = 'none';
                                }
                                return true;
                            } else {
                                if (document.querySelector('#phoneError2')) {
                                    document.querySelector('#phoneError2').style.display = 'block';
                                }
                                return false;
                            }
                        }}
                        // disabled={this.state.viewMode}
                    />
                    <div style={{color: 'red'}} class="p-1 pl-2" id="phoneError2">{this.state.resources.ErrorMessage_InvalidPhoneFormat}</div>
                </div>

                {/*ADDRESS / COUNTRY*/}
                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_Address}</label>
                    <input disabled={this.state.viewMode} className="form-control" type="text" name="address" onChange={this.onChangeAddress} value={this.state.address} />
                </div>
                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_Country}</label>
                    <input disabled={this.state.viewMode} className="form-control" type="text" name="country" onChange={this.onChangeCountry} value={this.state.country} />
                </div>

                {/*STATE OR PROVINCE / TOWN*/}
                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_StateOrProvince}</label>
                    <input disabled={this.state.viewMode} className="form-control" type="text" name="stateorprovince" onChange={this.onChangeStateOrProvince} value={this.state.stateOrProvince} />
                </div>
                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_Town}</label>
                    <input disabled={this.state.viewMode} className="form-control" type="text" name="town" onChange={this.onChangeTown} value={this.state.town} />
                </div>

                {/*ZIP CODE / */}
                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_PostalCode}</label>
                    <input disabled={this.state.viewMode} className="form-control" type="text" name="postalcode" onChange={this.onChangePostalCode} value={this.state.postalCode} />
                </div>
                <div className="form-group col-md-6" >
                </div>

                <div className="form-group col-md-12">
                    <label className="form-label">{this.state.resources.Label_Notes}</label>
                    <textarea disabled={this.state.viewMode} className="form-control" rows="3" name="notes" maxLength="150" onChange={this.onChangeNotes} defaultValue={this.state.notes}></textarea>
                </div>

                <div className=" form-group col-md-6">
                     <DataCreation resources={this.state}></DataCreation>
                </div>
                
                {/**Buttons Header */}
                <div className="buttons-head row">
                    {this.state.viewMode == false ?
                    <div className=" buttons-childs">
                        <button type="submit" className="btn btn-primary">{this.state.resources.ButtonLabel_Save}</button>
                        <button className="btn btn-danger" onClick={this.onCancel}>{this.state.resources.ButtonLabel_Cancel}</button>
                    </div>
                    :
                    <button className="btn btn-danger btn-block" onClick={this.onCancel}>{this.state.resources.Label_GoBack}</button>
                    }
                 </div>
            </form>;

        return (
            <div className="mt-2">
                <ol className="breadcrumb my-4">
                    <li className="breadcrumb-item active">{this.state.resources.Title_Companies}</li>
                </ol>
                <div className="card">
                    <div className="card-body">
                        {this.state.loading ? helpers.showLoadingOverlay() : helpers.hideLoadingOverlay()}
                        {contents}
                    </div>
                </div>
            </div>
        );
    }

    async getResources() {
        const resourceVars = [];
        resourceVars.push("Title_Companies");
        resourceVars.push("Label_Name");
        resourceVars.push("Label_Country");
        resourceVars.push("Label_FiscalId");
        resourceVars.push("Label_Logo");
        resourceVars.push("Label_BusinessArea");
        resourceVars.push("Label_Web");
        resourceVars.push("Label_Fax");
        resourceVars.push("Label_Phone");
        resourceVars.push("Label_AlternativePhone");
        resourceVars.push("Label_Address");
        resourceVars.push("Label_Email");
        resourceVars.push("Label_StateOrProvince");
        resourceVars.push("Label_Town");
        resourceVars.push("Label_PostalCode");
        resourceVars.push("Label_Notes");
        resourceVars.push("Label_Enabled");
        resourceVars.push("Label_Success");
        resourceVars.push("Label_SelectOption");
        resourceVars.push("ButtonLabel_Save");
        resourceVars.push("ButtonLabel_Cancel");
        resourceVars.push("Label_Error");
        resourceVars.push("ErrorMessage_RequiredField");
        resourceVars.push("ErrorMessage_DuplicatedName");
        resourceVars.push("ErrorMessage_UnknownError");
        resourceVars.push("ErrorMessage_AssociatedEntity_Company_User");
        resourceVars.push("ErrorMessage_ErrorWhileUploadingImage");
        resourceVars.push("ErrorMessage_InvalidEmail");
        resourceVars.push("SuccessMessage_DataSavedSuccessfully");
        resourceVars.push("Label_GoBack");
        resourceVars.push("Label_Creation");
        resourceVars.push("Label_CreationUser");
        resourceVars.push("Label_LastUpdate");
        resourceVars.push("Label_LastUpdateUser");
        resourceVars.push("ErrorMessage_InvalidPhoneFormat");

        const headers = {
            'Content-Type': 'application/json'
        };

        axios.post(window.config.REACT_APP_SECURITY_API_URL + '/languageresource/getbyvars/' + helpers.getUiLanguage(), resourceVars, {
            headers: headers
        })
            .then((response) => {
                const data = response.data.data;
                this.setState({ resources: data, loading: false });
            })
            .catch((error) => {
                console.log(error);
                this.setState({ loading: false });
            });
    }

    async populateData() {
        this.setState({
            loading: true
        });

        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/country/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ countries: data, loading: false });
                // order alphabetically
                this.state.countries.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                // console.log(this.state.countries);
            })
            .catch(function (error) {
                console.log(error);
            });

        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/companybusinessarea/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ businessAreas: data, loading: false });
                // order alphabetically
                this.state.businessAreas.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                // console.log(this.state.businessAreas);
            })
            .catch(function (error) {
                console.log(error);
            });

        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/company/getbyid/' + this.props.match.params.id,
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({
                    id: data.id,
                    name: data.name,
                    fiscalId: data.fiscal_id,
                    logo: data.logo,
                    countryId: data.country_id,
                    businessAreaId: data.company_businessarea_id,
                    email: data.email,
                    web: data.web,
                    fax: data.fax,
                    phone1: data.phone_1,
                    phone2: data.phone_2,
                    address: data.address,
                    country: data.country,
                    stateOrProvince: data.state_or_province,
                    town: data.town,
                    postalCode: data.postal_code,
                    notes: data.notes,
                    loading: false,
                    creation: helpers.getDisplayDateTimeFromUtc(data.creation),
                    creationUser: data.creation_user_fullname,
                    lastUpdate: data.last_update == null ? '' : helpers.getDisplayDateTimeFromUtc(data.last_update),
                    lastUpdateUser: data.last_update_user_fullname == null ? '' : data.last_update_user_fullname,
                });
            })
            .catch(function (error) {
                console.log(error);
            });
    }
}


