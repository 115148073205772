import React, { Component } from 'react';
import axios from 'axios';
import helpers from '../../Helpers';
import '../Companies/Company.css';
import $ from 'jquery';
import Button from '@material-ui/core/Button';
import DataCreation from '../DataCreation/DataCreation';

export class ProductCountryKeyDetail extends Component {
    static displayName = ProductCountryKeyDetail.name;

    constructor(props) {
        super(props);

        this.onChangeKey = this.onChangeKey.bind(this);
        this.onChangeUnits = this.onChangeUnits.bind(this);
        this.onChangePrice = this.onChangePrice.bind(this);
        this.onChangeVisibleFor = this.onChangeVisibleFor.bind(this);
        this.onChangeCrossSell = this.onChangeCrossSell.bind(this);
        this.onChangeProductCountryId = this.onChangeProductCountryId.bind(this);
        this.onChangeDescription = this.onChangeDescription.bind(this);
        this.onChangeIva = this.onChangeIva.bind(this);
        this.onChangeDuration = this.onChangeDuration.bind(this);
        this.onChangeEnabled = this.onChangeEnabled.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onSubmit = this.onSubmit.bind(this);  
        this.onChangeSingleSale = this.onChangeSingleSale.bind(this);
        this.onChangeProductCs = this.onChangeProductCs.bind(this);

        this.state = {
            id: 0,
            key: null,
            resources: [],
            productcountries: [],
            productCountryId: 0,
            productCountryName: null,
            crossSell: null,
            duration: null,
            description: null,
            price: 0,
            units: 0,
            iva: true,
            visibleFor: null,
            visibleForLabel: null,
            enabled: true,
            loading: true,
            viewMode: false,
            currentUserId: 0,
            singleSale: false,
            productCs: null,
        };
    }

    componentDidMount() {
        this.getResources();
        this.populateData();
        helpers.setNavActive("#nav-product-countrykey");
        helpers.setClientValidation();

        let url = window.location.href;
        if (url.includes('view')) {
            this.setState({
                viewMode: true
            });
        }
        let currentUser = helpers.getUserData();
        this.setState({
            currentUserId: currentUser.id
        });
        
        document.querySelector('#divProductCs').style.display = 'none';
        // // Jquery Dependency
        $("input[data-type='currency']").on({
            keyup: function() {
            formatCurrency($(this));
            },
            blur: function() { 
            formatCurrency($(this), "blur");
            }
        });

        function formatNumber(n) {
        // format number 1000000 to 1,234,567
        return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }

        function formatCurrency(input, blur) {
        // appends $ to value, validates decimal side
        // and puts cursor back in right position.
        
        // get input value
        var input_val = input.val();
        
        // don't validate empty input
        if (input_val === "") { return; }
        
        // original length
        var original_len = input_val.length;

        // initial caret position 
        var caret_pos = input.prop("selectionStart");
            
        // check for decimal
        if (input_val.indexOf(".") >= 0) {

            // get position of first decimal
            // this prevents multiple decimals from
            // being entered
            var decimal_pos = input_val.indexOf(".");

            // split number by decimal point
            var left_side = input_val.substring(0, decimal_pos);
            var right_side = input_val.substring(decimal_pos);

            // add commas to left side of number
            left_side = formatNumber(left_side);

            // validate right side
            right_side = formatNumber(right_side);
            
            // On blur make sure 2 numbers after decimal
            if (blur === "blur") {
            right_side += "00";
            }
            
            // Limit decimal to only 2 digits
            right_side = right_side.substring(0, 2);

            // join number by .
            input_val = left_side + "." + right_side; //"$" + 

        } else {
            // no decimal entered
            // add commas to number
            // remove all non-digits
            input_val = formatNumber(input_val);
            // input_val = "$" + input_val;
            
            // final formatting
            if (blur === "blur") {
            input_val += ".00";
            }
        }
        
        // send updated string to input
        input.val(input_val);

        // put caret back in the right position
        var updated_len = input_val.length;
        caret_pos = updated_len - original_len + caret_pos;
        input[0].setSelectionRange(caret_pos, caret_pos);
        }
    }

    async onSubmit(e) {
        e.preventDefault();

        var form = document.querySelectorAll('.needs-validation')[0];

        if (!form.checkValidity())
            return;

        if(this.state.price == '0' || this.state.price == 0 || this.state.units == '0' || this.state.units == 0 )
        return;

        this.setState({ loading: true });


        const data = {
            id: this.state.id,
            product_key: this.state.key,
            cross_sell: this.state.crossSell,
            productcountry_id: this.state.productCountryId,
            description: this.state.description,
            iva: this.state.iva,
            visiblefor: this.state.visibleFor,
            price: this.state.price,
            units: this.state.units,
            enabled: this.state.enabled,
            last_update_user_id: this.state.currentUserId,
            product_cs: this.state.productCs,
            single_sale: this.state.singleSale
        };
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME)
        };

        axios.post(window.config.REACT_APP_PRODUCTS_API_URL + '/productcountrykey/save', data, {
            headers: headers
        })
            .then((response) => {
                helpers.notifySuccess(this.state.resources.Label_Success, this.state.resources.SuccessMessage_DataSavedSuccessfully);
                // this.props.history.push('/product-countrykey');
                this.props.history.go('-1')
            })
            .catch((error) => {
                console.log(error);

                let msg = this.state.resources.ErrorMessage_UnknownError;
                if (error.response.data.error_code == window.errorCodes.DuplicatedEntityName)
                    msg = this.state.resources.ErrorMessage_DuplicatedName;
                else if (error.response.data.error_code == window.errorCodes.AssociatedEntity)
                    msg = this.state.resources.ErrorMessage_AssociatedEntity_Company_User;

                helpers.notifyError(this.state.resources.Label_Error, msg);
                this.setState({ loading: false });
            });
    }  

    onCancel(e) {
        e.preventDefault();
        this.props.history.go('-1')
        // this.props.history.push("/product-countrykey");
    }

    onChangeKey(e) {
        this.setState({
            key: e.target.value
        });
    }  
    onChangeUnits(e) {
         this.setState({
            units: e.target.value
        });
    } 

    onChangeDescription(e) {
        this.setState({
            description: e.target.value
        });
    }

    onChangeIva(e) {
        this.setState({
            iva: e.target.value
        });
    }
    
    onChangeCrossSell(e) {
        this.setState({
            crossSell: e.target.value
        });
        
        if(e.target.value == true || e.target.value == 'true'){
            document.querySelector('#divProductCs').style.display = 'block';
        }else{
            document.querySelector('#divProductCs').style.display = 'none';
            this.setState({
                productCs: null
            });
        }
    }

    onChangeProductCountryId(e) {
        this.setState({
            productCountryId: e.target.value
        });
    }

    onChangePrice(e) {
        this.setState({
            price: e.target.value
        });
    }

    onChangeVisibleFor(e) {
        this.setState({
            visibleFor: e.target.value
        });
    }

    onChangeDuration(e) {
        this.setState({
            duration: e.target.value
        });
    }


    onChangeEnabled(e) {
        this.setState({
            enabled: e.target.checked
        });
    } 

    onChangeSingleSale(e){
        this.setState({
            singleSale: e.target.value
        });
    }

    onChangeProductCs(e){
        this.setState({
            productCs: e.target.value
        });
    }

    render() {
        let contents =
            <form className="form row g-3 needs-validation" onSubmit={this.onSubmit} noValidate>
                {/*ENABLED*/}
                <div className="form-group col-md-12">
                    <div className="form-check form-switch">
                        <input disabled={this.state.viewMode} className="form-check-input" type="checkbox" id="enabled" checked={this.state.enabled} onChange={this.onChangeEnabled} />
                        <label className="form-check-label" htmlFor="enabled">{this.state.resources.Label_Enabled}</label>
                        <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                    </div>
                </div>

                <div className="form-group col-md-4" >

                    <label className=" form-label">{this.state.resources.Label_ProductCountry}</label>
                     <select disabled={this.state.viewMode} className="form-control" data-val="true" name="productcountryid" defaultValue={this.state.productCountryId} onChange={this.onChangeProductCountryId} required>
                        {/* <option value={this.state.productCountryId}>{this.state.productCountryName}</option> */}
                        {this.state.productcountries.map(productcountry =>
                            <option key={productcountry.id} selected={productcountry.id == this.state.productCountryId}
                                value={productcountry.id}>{productcountry.product_name} - {productcountry.country_name}</option>
                        )}
                    </select>
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>

                    <label className=" form-label">{this.state.resources.Label_IndividualSale}</label>
                    <select className="form-control" data-val="true" name="singleSale" value={this.state.singleSale} onChange={this.onChangeSingleSale}>
                        <option value="false">No</option>
                        <option value="true">{this.state.resources.Label_Yes}</option>
                    </select>          
                    
                    <label className=" form-label">{this.state.resources.Label_Units}</label>
                    <input disabled={this.state.viewMode} className="form-control" type="number" name="units" onChange={this.onChangeUnits} value={this.state.units}
                    onKeyDown={(evt) => ["+", "-","e","E"].includes(evt.key) && evt.preventDefault()} min={1} required />
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>

                </div>
               
                <div className="form-group col-md-4" >

                    <label className=" form-label">{this.state.resources.Label_Presentation}</label>
                    <input disabled={this.state.viewMode} className="form-control" type="text" name="description" onChange={this.onChangeDescription} value={this.state.description} required />
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>


                    <label className=" form-label">IVA</label>
                    <select disabled={this.state.viewMode} className="form-control" data-val="true" name="iva" defaultValue={this.state.iva} onChange={this.onChangeIva} required>
                        <option value="false">No</option>
                        <option value="true">{this.state.resources.Label_Yes}</option>
                    </select>
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>

                    <label className=" form-label">{this.state.resources.Label_Price}</label>
                    <input disabled={this.state.viewMode} className="form-control" type="text" name="price" data-type="currency" onChange={this.onChangePrice} value={this.state.price?.toLocaleString('en-US')}
                    onKeyDown={(evt) => ["+", "-","e","E"].includes(evt.key) && evt.preventDefault()} required />
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                    
                </div>
                <div className=" form-group col-md-4">
                    
                    <label className=" form-label">{this.state.resources.Label_Key}</label>
                    <input disabled={this.state.viewMode} className="form-control" type="text" name="key" onChange={this.onChangeKey} value={this.state.key} required />
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>

                    <label className=" form-label">{this.state.resources.Label_VisibleFor}</label>
                    <select disabled={this.state.viewMode} className="form-control" data-val="true" name="visiblefor" defaultValue={this.state.visibleFor} onChange={this.onChangeVisibleFor} required>
                        <option value={this.state.visibleFor}>{this.state.visibleForLabel}</option>
                        <option value="1">{this.state.resources.Label_Level1}</option>
                        <option value="2">{this.state.resources.Label_Level2}</option>
                        <option value="3">{this.state.resources.Label_Level3}</option>
                    </select>

                    <label className=" form-label">Cross Sell</label>
                    <select disabled={this.state.viewMode} className="form-control" data-val="true" name="crosssell" value={this.state.crossSell} onChange={this.onChangeCrossSell}>
                        {/* <option value={this.state.crossSell}>{this.state.crossSell ? this.state.resources.Label_Yes : 'No'}</option> */}
                        <option value="true">{this.state.resources.Label_Yes}</option>
                        <option value="false">No</option>
                    </select>   

                    <div id="divProductCs">
                        <label className=" form-label">{this.state.resources.Label_Product} CS</label>
                        <select className="form-control" data-val="true" name="productCs" onChange={this.onChangeProductCs} required={this.state.crossSell == 'true' ? true : false}>
                            <option value="">{this.state.resources.Label_SelectOption}</option>
                            {this.state.productcountries.map(productcountry =>
                                <option key={productcountry.id} selected={this.state.productCs ==  productcountry.id} 
                                    value={productcountry.id}>{productcountry.product_name} - {productcountry.country_name}</option>
                            )}
                        </select>
                    </div>
                </div>
                <div className=" form-group col-md-6">
                    <DataCreation resources={this.state}></DataCreation>
                </div>


                {/**Buttons Header */}
                <div className="buttons-head row">
                    {this.state.viewMode == false ?
                    <div className=" buttons-childs">
                        <button type="submit" className="btn btn-primary">{this.state.resources.ButtonLabel_Save}</button>
                        <button className="btn btn-danger" onClick={this.onCancel}>{this.state.resources.ButtonLabel_Cancel}</button>
                    </div>
                    :
                    <button className="btn btn-danger btn-block" onClick={this.onCancel}>{this.state.resources.Label_GoBack}</button>
                    }
                 </div>
            </form>;

        return (

           <div>
                
           
            <div className="mt-2">

                <ol className="breadcrumb my-4">
                    <li className="breadcrumb-item active">{this.state.resources.Title_ProductCountryKey}</li>
                </ol>
                <div className="card">
                    <div className="card-body">
                        {/* {this.state.loading ? helpers.showLoadingOverlay() : helpers.hideLoadingOverlay()} */}
                        {contents}
                    </div>
                </div>
                </div>
        </div> 
        );
    }

    async getResources() {
        const resourceVars = [];
        resourceVars.push("Label_Product");
        resourceVars.push("Label_Name");
        resourceVars.push("Label_Description");
        resourceVars.push("Label_BusinessArea");
        resourceVars.push("Label_Enabled");
        resourceVars.push("Label_Success");
        resourceVars.push("Label_SelectOption");
        resourceVars.push("ButtonLabel_Save");
        resourceVars.push("ButtonLabel_Cancel");
        resourceVars.push("Label_Error");
        resourceVars.push("ErrorMessage_RequiredField");
        resourceVars.push("ErrorMessage_DuplicatedName");
        resourceVars.push("ErrorMessage_UnknownError");
        resourceVars.push("ErrorMessage_AssociatedEntity_Company_User");
        resourceVars.push("ErrorMessage_ErrorWhileUploadingImage");
        resourceVars.push("ErrorMessage_InvalidEmail");
        resourceVars.push("SuccessMessage_DataSavedSuccessfully");
        resourceVars.push("Label_ProductCountry");
        resourceVars.push("Title_ProductCountryKey");
        resourceVars.push("Title_Courier");
        resourceVars.push("Title_Countries");
        resourceVars.push("Label_ShippingCost");
        resourceVars.push("Label_ProductionCost");
        resourceVars.push("Label_CourierCost");
        resourceVars.push("Label_Creation");
        resourceVars.push("Label_CreationUser");
        resourceVars.push("Label_LastUpdate");
        resourceVars.push("Label_LastUpdateUser");
        resourceVars.push("Label_Yes");
        resourceVars.push("Label_Key");
        resourceVars.push("Label_Level1");
        resourceVars.push("Label_Level2");
        resourceVars.push("Label_Level3");
        resourceVars.push("Label_VisibleFor");
        resourceVars.push("Label_DurationTreatment");
        resourceVars.push("Label_Price");
        resourceVars.push("Label_Units");
        resourceVars.push("Label_GoBack");
        resourceVars.push("Label_Presentation");
        resourceVars.push("Label_IndividualSale");

        const headers = {
            'Content-Type': 'application/json'
        };

        axios.post(window.config.REACT_APP_SECURITY_API_URL + '/languageresource/getbyvars/' + helpers.getUiLanguage(), resourceVars, {
            headers: headers
        })
            .then((response) => {
                const data = response.data.data;
                this.setState({ resources: data, loading: false });
            })
            .catch((error) => {
                console.log(error);
                this.setState({ loading: false });
            });
    }

    async populateData() {
        this.setState({
            loading: true
        });

        let authTokenProducts = await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME);
        let authTokenSecurity = await helpers.getAuthorizationToken(process.env.REACT_APP_SECURITY_API_NAME);

        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/productcountrykey/getbyid/' + this.props.match.params.id,
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                console.log(data);
                this.setState({
                    id: data.id,
                    key: data.product_key,
                    productCountryId: data.productcountry_id,
                    crossSell: data.cross_sell,
                    duration: data.duration,
                    description: data.description,
                    price: data.price,
                    units: data.units,
                    iva: data.iva,
                    productCountryName: data.productcountry_name,
                    visibleFor: data.visiblefor,
                    enabled: data.enabled,
                    creation: helpers.getDisplayDateTimeFromUtc(data.creation),
                    creationUser: data.creation_user_fullname,
                    lastUpdate: data.last_update == null ? '' : helpers.getDisplayDateTimeFromUtc(data.last_update),
                    lastUpdateUser: data.last_update_user_fullname == null ? '' : data.last_update_user_fullname,
                    productCs: data.product_cs,
                    singleSale: data.single_sale,
                    loading: false
                });
                if (this.state.visibleFor == '1') {
                    this.setState({
                        visibleForLabel: this.state.resources.Label_Level1
                    });
                } else if (this.state.visibleFor == '2') {
                    this.setState({
                        visibleForLabel: this.state.resources.Label_Level2
                    });
                } else if (this.state.visibleFor == '3'){
                    this.setState({
                        visibleForLabel: this.state.resources.Label_Level3
                    });
                }
                
                if( data.cross_sell == true ||  data.cross_sell == 'true'){
                    document.querySelector('#divProductCs').style.display = 'block';
                }
            })
            .catch(function (error) {
                console.log(error);
                // this.setState({ loading: false });
            })
            helpers.showLoadingOverlay();
       axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/productcountry/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ productcountries: data, loading: false });
                // order alphabetically
                this.state.productcountries.sort((a, b) => a.product_name < b.product_name ? -1 : +(a.product_name > b.product_name))
                helpers.hideLoadingOverlay();
            })
            .catch(function (error) {
                console.log(error);
            });

        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/country/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ countries: data, loading: false });
                // order alphabetically
                this.state.countries.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                // console.log(this.state.countries);
            })
            .catch(function (error) {
                console.log(error);
            });

        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/courier/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ couriers: data, loading: false });
                // order alphabetically
                this.state.couriers.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                // console.log(this.state.couriers);
            })
            .catch(function (error) {
                console.log(error);
            });
        
    }
}
