import React, { Component } from 'react';
import axios from 'axios';
import $ from 'jquery';
import helpers from '../../Helpers'

export class AgencyCreate extends Component {
    static displayName = AgencyCreate.name;

    constructor(props) {
        super(props);

        this.onChangeName = this.onChangeName.bind(this);
        this.onChangeEnabled = this.onChangeEnabled.bind(this);
        this.onChangeCountry = this.onChangeCountry.bind(this);
        this.onChangeEmail = this.onChangeEmail.bind(this);
        this.onChangePhone = this.onChangePhone.bind(this);
        this.onChangePhone2 = this.onChangePhone2.bind(this);
        this.onChangeFax = this.onChangeFax.bind(this);
        this.onChangeCity = this.onChangeCity.bind(this);
        this.onChangeState = this.onChangeState.bind(this);
        this.onChangeAddress = this.onChangeAddress.bind(this);
        this.onChangeZipCode = this.onChangeZipCode.bind(this);
        this.onChangeWeb = this.onChangeWeb.bind(this);
        this.onChangeContactCost = this.onChangeContactCost.bind(this);
        this.onChangeAdditionalData = this.onChangeAdditionalData.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onSubmit = this.onSubmit.bind(this);  

        this.state = {
            id: 0,
            name: '',
            countries: [],
            countryId: null,
            documentation: '',
            enabled: true,
            loading: true,
            resources: [],
            email: '',
            phone: '',
            phone2: '',
            fax: '',
            state: '',
            city: '',
            address: '',
            zipcode: '',
            contactcost: '',
            additionalData: '',
            web: '',
            userId: 0,

        };
    }

    componentDidMount() {
        this.getResources();
        this.populateData();
        helpers.setNavActive("#nav-Agency");

        let currentUser = helpers.getUserData();
        this.setState({
            userId: currentUser.id
        });
    }

    async onSubmit(e) {
        e.preventDefault();
        this.setState({ loading: true });

        const data = {
            name: this.state.name,
            country_id: this.state.countryId,
            email: this.state.email,
            phone: this.state.phone,
            alternative_phone: this.state.phone2,
            fax: this.state.fax,
            state: this.state.state,
            city: this.state.city,
            address: this.state.address,
            zipcode: this.state.zipcode,
            contact_cost: this.state.contactcost,
            additional_data: this.state.additionalData,
            web: this.state.web,
            enabled: this.state.enabled,
            creation_user_id: this.state.userId,
        };
        // console.log(data);
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME)
        };

        axios.post(window.config.REACT_APP_PRODUCTS_API_URL + '/Agency/save', data, {
            headers: headers
        })
            .then((response) => {
                helpers.notifySuccess(this.state.resources.Label_Success, this.state.resources.SuccessMessage_DataSavedSuccessfully);
                this.props.history.push('/Agency');
            })
            .catch((error) => {
                console.log(error);

                let msg = this.state.resources.ErrorMessage_UnknownError;
                if (error.response.data.error_code == window.errorCodes.DuplicatedEntityName)
                    msg = this.state.resources.ErrorMessage_DuplicatedName;

                helpers.notifyError(this.state.resources.Label_Error, msg);
                this.setState({ loading: false });
            });
    }  

    onCancel(e) {
        e.preventDefault();
        this.props.history.push("/Agency");
    }

    onChangeName(e) {
        this.setState({
            name: e.target.value
        });
    }
    
    onChangeCountry(e) {
        this.setState({
            countryId: e.target.value
        });
    }

    onChangeEmail(e) {
         this.setState({
            email: e.target.value
        });
    }

    onChangePhone(e) {
        this.setState({
           phone: e.target.value
       });
   }

   onChangePhone2(e) {
        this.setState({
        phone2: e.target.value
        });
    }

    onChangeFax(e) {
        this.setState({
        fax: e.target.value
        });
    }

    onChangeCity(e) {
        this.setState({
        city: e.target.value
        });
    }

    onChangeState(e) {
        this.setState({
        state: e.target.value
        });
    }

    onChangeAddress(e) {
        this.setState({
        address: e.target.value
        });
    }

    onChangeWeb(e) {
        this.setState({
        web: e.target.value
        });
    }

    onChangeAdditionalData(e) {
        this.setState({
        additionalData: e.target.value
        });
    }

    onChangeZipCode(e) {
        this.setState({
        zipcode: e.target.value
        });
    }

    onChangeContactCost(e) {
        this.setState({
        contactcost: e.target.value
        });
    }





    onChangeEnabled(e) {
        this.setState({
            enabled: e.target.checked
        });
    } 

    render() {
        let contents = this.state.loading
            ? helpers.showLoadingOverlay()
            :
            <form className="form row g-3 needs-validation" onSubmit={this.onSubmit}>
                <div className="form-group col-md-12">
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" id="enabled" defaultChecked={this.state.enabled} onChange={this.onChangeEnabled} />
                        <label className="form-check-label" htmlFor="enabled">{this.state.resources.Label_Enabled}</label>
                        <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                    </div>
                </div>
                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_Name}</label>
                    <input className="form-control" type="text" name="name" onChange={this.onChangeName} value={this.state.name} required />
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                </div>
                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_Email}</label>
                    <input className="form-control" type="email" name="email" onChange={this.onChangeEmail} value={this.state.email} />
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                </div>
                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_Web}</label>
                    <input className="form-control" type="text" name="web" onChange={this.onChangeWeb} value={this.state.web} />
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                </div>
                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_Phone}</label>
                    <input className="form-control" type="text" name="phone" onChange={this.onChangePhone} value={this.state.phone} />
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                </div>
                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_AlternativePhone}</label>
                    <input className="form-control" type="text" name="phone2" onChange={this.onChangePhone2} value={this.state.phone2} />
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                </div>
                <div className=" form-group col-md-6">
                    <label className="form-label">{this.state.resources.Label_Fax}</label>
                    <input className="form-control" type="text" name="fax" maxLength="20" onChange={this.onChangeFax} value={this.state.fax} />
                </div>
                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_Country}</label>
                    <input disabled={this.state.viewMode} className="form-control" type="text" name="countryid" maxLength="30" onChange={this.onChangeCountry} defaultValue={this.state.countryId} />
                     <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                </div>
                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_StateOrProvince}</label>
                    <input className="form-control" type="text" name="state" onChange={this.onChangeState} value={this.state.state} />
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                </div>
                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_Town}</label>
                    <input className="form-control" type="text" name="city" onChange={this.onChangeCity} value={this.state.city} />
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                </div>
                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_Address}</label>
                    <input className="form-control" type="text" name="address" onChange={this.onChangeAddress} value={this.state.address} />
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                </div>
                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_PostalCode}</label>
                    <input className="form-control" type="text" name="zipcode" onChange={this.onChangeZipCode} value={this.state.zipcode} />
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                </div>
                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_ContactCost}</label>
                    <input className="form-control" type="text" name="contactcost" onChange={this.onChangeContactCost} value={this.state.contactcost} required />
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                </div>
                
                <div className="form-group col-md-6">
                    <label className="form-label">{this.state.resources.Label_AdditionalData}</label>
                    <textarea className="form-control" rows="3" name="additionaldata" maxLength="150" onChange={this.onChangeAdditionalData} defaultValue={this.state.additionalData}></textarea>
                </div>


                {/**Buttons Header */}
                 <div className="buttons-head row">
                    <div className=" buttons-childs">
                        <button type="submit" className="btn btn-primary">{this.state.resources.ButtonLabel_Save}</button>
                        <button className="btn btn-danger" onClick={this.onCancel}>{this.state.resources.ButtonLabel_Cancel}</button>
                    </div>
                 </div>
                {/* <div className="form-group col-12 text-end">
                    <button type="submit" className="btn btn-primary">{this.state.resources.ButtonLabel_Save}</button>
                    <button className="btn btn-negative" onClick={this.onCancel}>{this.state.resources.ButtonLabel_Cancel}</button>
                </div > */}
            </form>
            helpers.hideLoadingOverlay();

        return (
            <div className="mt-2">
                {/* <h1 className="mt-4">{this.state.resources.Title_Areas}</h1> */}
                <ol className="breadcrumb my-4">
                    <li className="breadcrumb-item active">{this.state.resources.Title_Agency}</li>
                </ol>
                <div className="card">
                    <div className="card-body">
                        {contents}
                    </div>
                </div>
            </div>
        );
    }

    async getResources() {
        const resourceVars = [];
        resourceVars.push("Title_Agency");
        resourceVars.push("Label_Name");
        resourceVars.push("Label_Country");
        resourceVars.push("Label_Email");
        resourceVars.push("Label_Web");
        resourceVars.push("Label_Phone");
        resourceVars.push("Label_AlternativePhone");
        resourceVars.push("Label_Fax");
        resourceVars.push("Label_StateOrProvince");
        resourceVars.push("Label_Town");
        resourceVars.push("Label_Address");
        resourceVars.push("Label_PostalCode");
        resourceVars.push("Label_ContactCost");
        resourceVars.push("Label_AdditionalData");
        resourceVars.push("Label_Description");
        resourceVars.push("Label_Enabled");
        resourceVars.push("Label_Success");
        resourceVars.push("ButtonLabel_Save");
        resourceVars.push("ButtonLabel_Cancel");
        resourceVars.push("Label_Error");
        resourceVars.push("ErrorMessage_RequiredField");
        resourceVars.push("ErrorMessage_DuplicatedName");
        resourceVars.push("ErrorMessage_UnknownError");
        resourceVars.push("SuccessMessage_DataSavedSuccessfully");
        resourceVars.push("Label_SelectOption");
        resourceVars.push("Title_Ticket");
        resourceVars.push("Title_Invoice");

        const headers = {
            'Content-Type': 'application/json'
        };

        axios.post(window.config.REACT_APP_SECURITY_API_URL + '/languageresource/getbyvars/' + helpers.getUiLanguage(), resourceVars, {
            headers: headers
        })
            .then((response) => {
                const data = response.data.data;
                this.setState({ resources: data, loading: false });
            })
            .catch((error) => {
                console.log(error);
                this.setState({ loading: false });
            });

            axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/country/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ countries: data, loading: false });
                // order alphabetically
                this.state.countries.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                // console.log(this.state.countries);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    async populateData() {
        this.setState({
            loading: false
        });
    }
}