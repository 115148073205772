import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';  
import $ from 'jquery';
import DataTable from 'datatables.net';
import helpers from '../../Helpers';
import '../../custom.css';
import { makeStyles } from '@material-ui/core/styles';
import { DataGrid, useGridApiRef, esES } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { DataGridPro } from '@mui/x-data-grid-pro';

import { LicenseInfo } from '@mui/x-data-grid-pro';

LicenseInfo.setLicenseKey(
  '97124f3444ae3cf552c73ef2f3487b08T1JERVI6MzEzNDQsRVhQSVJZPTE2NjY1MzkzOTkwMDAsS0VZVkVSU0lPTj0x',
);
export class UsersList extends Component {
    static displayName = UsersList.name;

    constructor(props) {
        super(props);

        UsersFilter = UsersFilter.bind(this);

        this.state = {
            items: [],
            resources: [],
            loading: true,
            permissionsIds: [],
            columns: [],
            paging: null,
            sorting: null,
            filtering: null,
            areas: [],
            areaId: null,
            enabled: null,
            userName: null,
            lastName: null,
            identificationNr: null,
        };
    }

    componentDidMount() {
        let userData = helpers.getUserData();
        this.state.permissionsIds = userData.permission_ids;
        this.getResources();
        this.populateData();

        helpers.setNavActive("#nav-users");

        this.state.sorting = JSON.parse(localStorage.getItem('CopyUserListSorting'));
        this.state.paging = JSON.parse(localStorage.getItem('CopyUserListPaging'));
        this.state.filtering = JSON.parse(localStorage.getItem('CopyUserListFiltering'));
    }

      static renderTable(items, resources, permissionsIds) {
          return (
                <table className='table datatable my-table' aria-labelledby="tabelLabel"> {/*table class:* table-striped datatable */}
                    <thead>
                        <tr>
                          <th>{resources.Label_ID}</th>
                          <th>{resources.Label_FullName}</th>
                          <th>{resources.Label_Status}</th>
                          <th>{resources.Label_Profile}</th>
                          <th>{resources.Label_Area}</th>
                          <th>{resources.Label_PbxId}</th>
                          <th>{resources.Label_AdmissionDate}</th>
                          <th>{resources.Label_TeamLeader}</th>
                          <th>{resources.Label_Supervisor}</th>

                              <th style={{ width: '90px' }} className="text-center">{resources.Label_Actions}</th>

                        </tr>
                    </thead>
                    <tbody>
                        {
                            items.map(item =>
                                <tr key={item.id}>
                                    <td>{item.id}</td>
                                    <td>{item.last_name + ', ' + item.first_name}</td>
                                    <td>{item.enabled == true ? resources.Label_Enabled : resources.Label_Disabled}</td>
                                    <td>{item.profile}</td>
                                    <td>{item.area_name}</td>
                                    <td>{item.pbx_id == null || item.pbx_id == '' ? '---' : item.pbx_id}</td>
                                    <td>{helpers.getDisplayDate(item.admission_date)}</td>
                                    <td>{item.team_leader_user_id == null ? '---' : item.team_leader_user_fullname}</td>
                                    <td>{item.supervisor_user_id == null ? '---' : item.supervisor_user_fullname}</td>
                                    {permissionsIds.includes(51) ?
                                    <td className="text-center">
                                        <Link to={"/user-changepassword/" + item.id} className="btn btn-default"><i className="fas fa-key"></i></Link>
                                        <Link to={"/user-detail/" + item.id} className="btn btn-default"><i className="fas fa-edit"></i></Link>
                                    </td>
                                    : <td className="text-center">
                                        <Link to={"/user-view/" + item.id} className="btn btn-default"><i class="fas fa-eye"></i></Link>
                                      </td>}
                                </tr>
                            )}
                    </tbody>
                </table>
        );
      }

      render() {
          let contents = this.state.loading
              ? helpers.showLoadingOverlay()
              : <UserDataGrid data={this.state}/>
              //UsersList.renderTable(this.state.items, this.state.resources, this.state.permissionsIds);

          return (
              <div className="mt-2">
                  {/* <h1 className="mt-4">{this.state.resources.Title_Users}</h1> */}
                  <ol className="breadcrumb my-4 ">
                      <li className="breadcrumb-item active">{this.state.resources.Title_Users}</li>
                  </ol>
                  <div className="card">
                      <div className="card-header dis-flex" style={{justifyContent:'space-between'}}>
                          {this.state.permissionsIds.includes(49) ?
                          <Link to={"/user-create"} className="btn btn-success"><i className="fas fa-plus" aria-hidden="true"></i> {this.state.resources.ButtonLabel_New}</Link>
                              : <></>}
                          
                        </div>
                        <UsersFilter />
                      <div className="card-body">
                          <div className="table-responsive">
                              {contents}
                          </div>
                      </div>
                  </div>
              </div>
        );
      }

    async populateData() {
        axios.get(window.config.REACT_APP_SECURITY_API_URL + '/user/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_SECURITY_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                
                // this.setState({ items: data, loading: false });
                this.state.loading = false;
                // $.fn.dataTable.ext.errMode = 'throw';
                // // $(".datatable").dataTable().fnDestroy();
                // $('.datatable').DataTable({
                //     "retrieve": true,
                //     "language": {
                //         "lengthMenu": this.state.resources.Datatables_language_lengthMenu,
                //         "zeroRecords": this.state.resources.Datatables_language_zeroRecords,
                //         "info": this.state.resources.Datatables_language_info,
                //         "infoEmpty": this.state.resources.Datatables_language_infoEmpty,
                //         "infoFiltered": this.state.resources.Datatables_language_infoFiltered,
                //         "search": this.state.resources.Datatables_language_search,
                //         "paginate": {
                //             "previous": this.state.resources.Datatables_language_paginate_previous,
                //             "next": this.state.resources.Datatables_language_paginate_next
                //         },
                //         "autoWidth": false
                //     },
                //     stateSave: true,
                //     stateSaveCallback: function (settings, data) {
                //         localStorage.setItem('DataTables_b', JSON.stringify(data))
                //         },
                //     stateLoadCallback: function(settings) {
                //         return JSON.parse(localStorage.getItem( 'DataTables_b'))
                //         }
                // });
                this.state.columns = [
                    {
                        field: 'actions',
                        headerAlign: "center",
                        headerClassName: "header-table-mui",
                        headerName: this.state.resources.Label_Actions ? this.state.resources.Label_Actions : 'Actions',
                        flex: 1,
                        minWidth: 160,
                        renderCell: (item) => {
                            return (
                                this.state.permissionsIds.includes(51) ?
                                <div className="d-flex justify-content-between align-items-center mx-auto" style={{ cursor: "pointer" }}>
                                    <td className="text-center">
                                            <Link to={"/user-changepassword/" + item.id} className="btn btn-default"><i className="fas fa-key"></i></Link>
                                            <Link to={"/user-detail/" + item.id} className="btn btn-default"><i className="fas fa-edit"></i></Link>
                                    </td>
                                </div>
                                :
                                <div>
                                <td className="text-center">
                                            <Link to={"/user-view/" + item.id} className="btn btn-default"><i class="fas fa-eye"></i></Link>
                                    </td>
                                </div>
                            );
                        }
                    },
                    {
                        field: 'id',
                        headerName: 'ID',
                        type: 'number',
                        width: 100,
                        headerAlign: 'center',
                        align: 'left',
                        headerClassName: "header-table-mui"
                    }, 
                    {
                        field: 'first_name',
                        headerName: this.state.resources.Label_FullName ? this.state.resources.Label_FullName : 'Full Name',
                        flex: 1,
                        minWidth: 230,
                        headerAlign: "left",
                        headerClassName: "header-table-mui",
                        renderCell: (item) => {
                            return (
                                <div>
                                    <td className="text-center">
                                        {item.row.first_name} {item.row.last_name}
                                    </td>
                                    
                                </div>
                            )
                        }
                    },
                    {
                        field: 'enabled',
                        headerName: this.state.resources.Label_Status ? this.state.resources.Label_Status : 'Enabled',
                        flex: 1,
                        minWidth: 140,
                        headerAlign: "left",
                        headerClassName: "header-table-mui",
                        renderCell: (item) => {
                            // console.log(item)
                            return (
                                <div className="text-center">
                                    {item.row.enabled == true ? this.state.resources.Label_Enabled : this.state.resources.Label_Disabled}
                                </div>
                            );
                        }
                    },
                    {
                        field: 'profile',
                        headerName: this.state.resources.Label_Profile ? this.state.resources.Label_Profile : 'Profile',
                        flex: 1,
                        minWidth: 150,
                        headerAlign: "left",
                        headerClassName: "header-table-mui"
                    },
                    {
                        field: 'area_name',
                        headerName: this.state.resources.Label_Area ? this.state.resources.Label_Area : 'Area Name',
                        flex: 1,
                        minWidth: 150,
                        headerAlign: "left",
                        headerClassName: "header-table-mui"
                    },
                    {
                        field: 'pbx_id',
                        headerName: this.state.resources.Label_PbxId ? this.state.resources.Label_PbxId : 'PbxId',
                        flex: 1,
                        minWidth: 120,
                        headerAlign: "left",
                        headerClassName: "header-table-mui"
                    },
                    {
                        field: 'admission_date',
                        headerName: this.state.resources.Label_AdmissionDate ? this.state.resources.Label_AdmissionDate : 'Admission Date',
                        flex: 1,
                        minWidth: 180,
                        headerAlign: "left",
                        headerClassName: "header-table-mui",
                        type: 'date',
                        renderCell: (item) => {
                            // console.log(new Date(item.row.admission_date))
                            // console.log(new Date(item.row.admission_date).getDate()+'/'+new Date(item.row.admission_date).getMonth()+'/'+new Date(item.row.admission_date).getFullYear())
                            return (
                                <div className="text-center">
                                    {new Date(item.row.admission_date).getDate()+'/'+(new Date(item.row.admission_date).getUTCMonth()+1)+'/'+new Date(item.row.admission_date).getFullYear()}
                                </div>
                            );
                        },
                        valueGetter: (item)=>item.row.admission_date
                    },
                    {
                        field: 'team_leader_user_fullname',
                        headerName: this.state.resources.Label_TeamLeader ? this.state.resources.Label_TeamLeader : 'Team Leader',
                        flex: 1,
                        minWidth: 200,
                        headerAlign: "left",
                        headerClassName: "header-table-mui"
                    },
                    {
                        field: 'supervisor_user_fullname',
                        headerName: this.state.resources.Label_Supervisor ? this.state.resources.Label_Supervisor : 'Supervisor',
                        flex: 1,
                        minWidth: 200,
                        headerAlign: "left",
                        headerClassName: "header-table-mui"
                    },
                    {
                        field: 'occ_id',
                        headerName: 'Occ Id',
                        type: 'number',
                        width: 130,
                        headerAlign: 'center',
                        align: 'left',
                        headerClassName: "header-table-mui"
                    }, 
                ];

                helpers.hideLoadingOverlay();
            })
            .catch(function (error) {
                console.log(error);
                helpers.hideLoadingOverlay();
            });

        let authTokenProducts = await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME);
        // get areas
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/area/get',
            {
                'headers': { 'Authorization': authTokenProducts }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ areas: data, loading: false });
                this.state.areas.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                // console.log(this.state.areas);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    

    async getResources() {
        const resourceVars = [];
        resourceVars.push("Title_Users");
        resourceVars.push("Label_ID");
        resourceVars.push("Label_FullName");
        resourceVars.push("Label_Area");
        resourceVars.push("Label_Profile");
        resourceVars.push("Label_PbxId");
        resourceVars.push("Label_TeamLeader");
        resourceVars.push("Label_Supervisor");
        resourceVars.push("Label_AdmissionDate");
        resourceVars.push("Label_CreationUser");
        resourceVars.push("Label_LastUpdate");
        resourceVars.push("Label_LastUpdateUser");
        resourceVars.push("Label_PermissionGroups");
        resourceVars.push("Label_Actions");
        resourceVars.push("Label_Status");
        resourceVars.push("Label_Enabled");
        resourceVars.push("Label_Disabled");
        resourceVars.push("ButtonLabel_ViewEdit");
        resourceVars.push("ButtonLabel_New");
        resourceVars.push("Datatables_language_lengthMenu");
        resourceVars.push("Datatables_language_zeroRecords");
        resourceVars.push("Datatables_language_info");
        resourceVars.push("Datatables_language_infoEmpty");
        resourceVars.push("Datatables_language_infoFiltered");
        resourceVars.push("Datatables_language_search");
        resourceVars.push("Datatables_language_paginate_previous");
        resourceVars.push("Datatables_language_paginate_next");
        resourceVars.push("ButtonLabel_Cancel");
        resourceVars.push("Label_FilterOff");
        resourceVars.push("Label_Filter");       
        resourceVars.push("Label_Sort");
        resourceVars.push("Label_Hide");
        resourceVars.push("Label_ShowColumns");
        resourceVars.push("Label_OrderAsc");
        resourceVars.push("Label_OrderDesc");
        resourceVars.push("Label_Unsorting");
        resourceVars.push("Label_Columns");
        resourceVars.push("Label_Delete");
        resourceVars.push("Label_GridValue");
        resourceVars.push("Label_ValueFilter");
        resourceVars.push("Label_And");
        resourceVars.push("Label_Or");
        resourceVars.push("Label_Operator");
        resourceVars.push("Label_NoRows");
        resourceVars.push("Label_SelectColumns");
        resourceVars.push("Label_OperatorAfter");
        resourceVars.push("Label_OperatorBefore");
        resourceVars.push("Label_OperatorContains");
        resourceVars.push("Label_OperatorEnds");
        resourceVars.push("Label_OperatorEquals");
        resourceVars.push("Label_OperatorIs");
        resourceVars.push("Label_OperatorIsEmpty");
        resourceVars.push("Label_OperatorIsNotEmpty");
        resourceVars.push("Label_OperatorIsNot");
        resourceVars.push("Label_OperatorIsOnOrAfter");
        resourceVars.push("Label_OperatorIsOnOrBefore");
        resourceVars.push("Label_OperatorStarts");
        resourceVars.push("Label_ReorderColumns");
        resourceVars.push("Label_HideAll");
        resourceVars.push("Label_ShowAll");
        resourceVars.push("Label_FindColumn");
        resourceVars.push("Label_TitleColumn");
        resourceVars.push("Label_RowsperPage");
        resourceVars.push("Label_addFilter");
        resourceVars.push("Label_SelectOption");
        resourceVars.push("Label_FirstName");
        resourceVars.push("Label_LastName");
        resourceVars.push("Label_DocNumber");

        const headers = {
            'Content-Type': 'application/json'
        };

        axios.post(window.config.REACT_APP_SECURITY_API_URL + '/languageresource/getbyvars/' + helpers.getUiLanguage(), resourceVars, {
            headers: headers
        })
            .then((response) => {
                const data = response.data.data;
                this.setState({ resources: data, loading: false });

            })
            .catch((error) => {
                console.log(error);
            });
    }
}

export default function UserDataGrid(values) {
    // console.log('items')
    let items = localStorage.getItem('UserFilterItems') ?
            JSON.parse(localStorage.getItem('UserFilterItems')) : values.data.items,
        resources = values.data.resources,
        permissionsIds = values.data.permissionsIds, columns = values.data.columns,
        sorting = localStorage.getItem('CopyUserListSorting') ?
            JSON.parse(localStorage.getItem('CopyUserListSorting')) : [],
        paging = values.data.paging ? values.data.paging : 0
        , filter = localStorage.getItem('CopyUserListFiltering') ?
            JSON.parse(localStorage.getItem('CopyUserListFiltering')) : values.data.filtering
    
    const [page, setPage] = React.useState(paging.page);
    const [pageSize, setPageSize] = React.useState(paging.pageSize);
    
    const [value, setValue] = React.useState([null, null]);
    const [myRows, setRowsValue] = React.useState(values.data.items);
    const [mySortModel, setSortModel] = React.useState([]);
    const [filtering, setFilter] = React.useState(filter);
    const [status, onChangeStatus] = React.useState(true);
    const [open, setOpen] = React.useState(false);
    const openModal = () => setOpen(true);
    const closeModal = () => setOpen(false);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    function setInitial() {
        setTimeout(() => {
            if (document.getElementsByClassName('MuiTablePagination-selectLabel')[0]){
                document.getElementsByClassName('MuiTablePagination-selectLabel')[0].innerHTML = '';
            }   
            setRowsValue(items)
            // console.log(mySortModel)
            // localStorage.removeItem('CopyUserListFiltering');
            // filter = null;
            // if (myRows.length > 0) {
            //     // setTimeout(() => {
            //     //     if (sorting) {
            //     //         if (mySortModel.length < 1) {
            //     //             if (sorting.sortModel) {
            //     //                 setSortModel(sorting.sortModel)
            //     //                 localStorage.removeItem('UserListSorting');
            //     //                 localStorage.removeItem('CopyUserListSorting');
            //     //             }
            //     //         }
            //     //     }
            //     // }, 12000)
            // }
            // console.log(JSON.parse(localStorage.getItem('UserListSorting')));
            if (myRows.length < 1) {
                if (localStorage.getItem('UserFilterItems')) {
                    let myItems = JSON.parse(localStorage.getItem('UserFilterItems'));
                    setRowsValue(myItems);
                    setTimeout(() => {
                        localStorage.removeItem('UserFilterItems');
                    }, 1000);
                } else {
                    setRowsValue(items)
                }
                
            }
        }, 1000)
    }
    setInitial()

    const handlePageChange = (newPage) => {
        setPage(newPage);
    };

    const handlePageSizeChange = (newPage) => {
        setPageSize(newPage);
    };

    const handleFilterChange = (newFilter) => {
        // console.log(newFilter)
        setFilter(newFilter);
    }

    const handleSortingChange = (sort) => {
        setSortModel(sort);
        localStorage.removeItem('UserListSorting');
        localStorage.removeItem('CopyUserListSorting');
        localStorage.setItem('CopyUserListSorting', JSON.stringify(sort));
    }

    const handleStatus = (e) => {
        e.preventDefault();
        onChangeStatus(e.target.value);
    }

    // const onReloadData =
    async function onReloadData() {
        // console.log(status)
        
        if (status === true || status === 'true') {
            helpers.showLoadingOverlay();
            axios.get(window.config.REACT_APP_SECURITY_API_URL + '/user/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_SECURITY_API_NAME) }
            })
            .then(response => {
                // console.log(response.data.data)
                let newItems = response.data.data;
                setRowsValue(newItems);
                localStorage.setItem('UserFilterItems', JSON.stringify(newItems));
                closeModal();
                helpers.hideLoadingOverlay();
             })
            .catch(err => console.log(err));
        } else if (status != true){
            helpers.showLoadingOverlay();
            axios.get(window.config.REACT_APP_SECURITY_API_URL + '/user/getdisabled',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_SECURITY_API_NAME) }
            })
            .then(response => {
                // console.log(response.data.data)
                let newItems = response.data.data;
                setRowsValue(newItems);
                localStorage.setItem('UserFilterItems', JSON.stringify(newItems));
                closeModal();
                helpers.hideLoadingOverlay();
             })
            .catch(err => console.log(err));
        }
    }

    async function resetFilter() {
        helpers.showLoadingOverlay();
        axios.get(window.config.REACT_APP_SECURITY_API_URL + '/user/getall',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_SECURITY_API_NAME) }
            })
            .then(response => {
                let newItems = response.data.data;
                setRowsValue(newItems);
                localStorage.setItem('UserFilterItems', JSON.stringify(newItems));
                closeModal();
                helpers.hideLoadingOverlay();
             })
            .catch(error => console.log(error));
    }

    return (
        <>
        {/* <button className="btn btn-success mb-1" onClick={openModal}><i class="fas fa-filter"></i></button> */}
        <Modal
            open={open}
            onClose={closeModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <div className="dis-flex m-1" style={{justifyContent: 'space-beetween'}}>
                    <div>
                        <label className="label-text">{values.data.resources.Label_Status}</label>
                        <select className="form-control" data-val="true" name="productId" value={status} onChange={handleStatus}>
                            <option value="true">{values.data.resources.Label_Enabled}</option>
                            <option value="false">{values.data.resources.Label_Disabled}</option>
                        </select>
                    </div>
                    <div className="mt-4">
                        <button className="btn btn-primary mx-1 my-auto " onClick={onReloadData}>{values.data.resources.Label_Filter}</button>
                        <button className="btn btn-danger mx-1 my-auto" onClick={resetFilter}>{values.data.resources.Label_FilterOff}</button>
                    </div>
                </div>
            </Box>
        </Modal>
            <div style={{ height: 450, width: '99%' }}>
                <DataGridPro rows={myRows} columns={columns} rowsPerPageOptions={[5, 10, 25, 50, 100]} density='compact'
                page={page}
                pagination 
                // density='compact'
                pageSize={pageSize}
                onStateChange={(state) => {
                    localStorage.setItem('UserListPagination', JSON.stringify(state.pagination))
                    localStorage.setItem('UserListSorting', JSON.stringify(state.sorting))
                    localStorage.setItem('UserListFiltering', JSON.stringify(state.filter))
                }}
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
                sortModel={mySortModel}
                onSortModelChange={handleSortingChange}
                state={
                    filter != null ? {filter: filter} : {}
                    // {sorting: sorting}
                }
                 localeText={{
                    'columnMenuFilter': values.data.resources.Label_Filter,
                    'columnHeaderSortIconLabel': values.data.resources.Label_Sort,
                    'columnMenuHideColumn': values.data.resources.Label_Hide,
                    'columnMenuShowColumns': values.data.resources.Label_ShowColumns,
                    'columnMenuSortAsc': values.data.resources.Label_OrderAsc,
                    'columnMenuSortDesc': values.data.resources.Label_OrderDesc,
                    'columnMenuUnsort': values.data.resources.Label_Unsorting,
                    'filterPanelColumns': values.data.resources.Label_Columns,
                    'filterPanelDeleteIconLabel': values.data.resources.Label_Delete,
                    'filterPanelInputLabel': values.data.resources.Label_GridValue,
                    'filterPanelInputPlaceholder': values.data.resources.Label_ValueFilter,
                    'filterPanelOperatorAnd': values.data.resources.Label_And,
                    'filterPanelOperatorOr': values.data.resources.Label_Or,
                    'filterPanelOperators': values.data.resources.Label_Operator,
                    'noResultsOverlayLabel': values.data.resources.Label_NoRows,
                    'noRowsLabel': values.data.resources.Label_NoRows,
                    'toolbarColumns': values.data.resources.Label_Columns,
                    'toolbarColumnsLabel': values.data.resources.Label_SelectColumns,
                    'errorOverlayDefaultLabel': "Error",
                    'filterOperatorAfter': values.data.resources.Label_OperatorAfter,
                    'filterOperatorBefore': values.data.resources.Label_OperatorBefore,
                    'filterOperatorContains': values.data.resources.Label_OperatorContains,
                    'filterOperatorEndsWith': values.data.resources.Label_OperatorEnds,
                    'filterOperatorEquals': values.data.resources.Label_OperatorEquals,
                    'filterOperatorIs': values.data.resources.Label_OperatorIs,
                    'filterOperatorIsEmpty': values.data.resources.Label_OperatorIsEmpty,
                    'filterOperatorIsNotEmpty': values.data.resources.Label_OperatorIsNotEmpty,
                    'filterOperatorNot': values.data.resources.Label_NoRows,
                    'filterOperatorOnOrAfter': values.data.resources.Label_OperatorIsOnOrAfter,
                    'filterOperatorOnOrBefore': values.data.resources.Label_OperatorIsOnOrBefore,
                    'filterOperatorStartsWith': values.data.resources.Label_OperatorStarts,
                    'columnsPanelDragIconLabel': values.data.resources.Label_ReorderColumns,
                    'columnsPanelHideAllButton': values.data.resources.Label_HideAll,
                    'columnsPanelShowAllButton': values.data.resources.Label_ShowAll,
                    'columnsPanelTextFieldLabel': values.data.resources.Label_FindColumn,
                    'columnsPanelTextFieldPlaceholder': values.data.resources.Label_TitleColumn,
                    'filterPanelAddFilter': values.data.resources.Label_addFilter
                }}     
            />
        </div>
    </>
    );
}

function UsersFilter(){
    const [open, setOpen] = React.useState(false);
    const closeModal = () => setOpen(false);

    const handleOpen = () => {
        setOpen(true);
    };
    
    const handleClose = () => {
        setOpen(false);
        this.setState({
            areaId: null,
            enabled: null,
            userName: null,
            lastName: null,
            identificationNr: null
        });
    };

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '1200px',
        maxWidth:'1200px',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const onChangeUserName = (e)  =>{
        this.setState({
            userName: e.target?.value
        });
    }

    const onChangeLastName = (e)  =>{
        this.setState({
            lastName: e.target?.value
        });
    }

    const onChangeIdentification = (e)  =>{
        this.setState({
            identificationNr: e.target?.value
        });
    }

    const onChangeArea = (e)  =>{
        this.setState({
            areaId: e.target?.value
        });
    }

    const onChangeEnabled = (e)  =>{
        this.setState({
            enabled: e.target?.value
        });
    }

    const onSendFilter = async (e) => {
        e.preventDefault();
        const data = {
           last_name: this.state.lastName ?? "",
           first_name: this.state.userName ?? "",
           document_number: this.state.identificationNr ?? "",
           area_id: this.state.areaId ?? 0,
           enabled: this.state.enabled ?? null
        };

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_SECURITY_API_NAME)
        };

        helpers.showLoadingOverlay();
        axios.post(window.config.REACT_APP_SECURITY_API_URL + '/user/searchbyfilter', data, {
            headers: headers
        })
        .then((response) => {
            helpers.hideLoadingOverlay();
             console.log(response); 
            const resp = response.data.data
             this.setState({
                items: resp
            });
            this.state.items = resp;
            handleClose();
        })
        .catch((error) => {
            helpers.hideLoadingOverlay();
            console.log(error);
            handleClose();
        });
    }

    const onClearFilter = (e) => {
        this.setState({
            areaId: -1,
            enabled: -1,
            userName: "",
            lastName: "",
            identificationNr: ""
        });

        this.state.areaId = -1;
        this.state.enabled = -1;
        this.state.userName = "";
        this.state.lastName = "";
        this.state.identificationNr = "";
    }

    return (
        <>
            <div className="form-group col-md-1 mb-0">
                <button className='btn btn-success mx-2 mt-3' onClick={handleOpen}>
                    <i className="btn btn-success mb-1" class="fas fa-filter" data-tougle="tooltip" data-placement="right"  onClick={handleOpen}></i>
                </button>
                <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <div className="mt-2">
                            <ol className="breadcrumb my-4">
                                <li className="breadcrumb-item active">{this.state.resources.Label_addFilter}</li>
                            </ol>
                        </div>

                        <div className="form-group row">

                            <div className="form-group col-md-3" >
                                <label className=" form-label">{this.state.resources.Label_FirstName}</label>
                                <input className="form-control" type="text" name="userName" onChange={onChangeUserName} value={this.state.userName} />
                            </div>
                            <div className="form-group col-md-3" >
                                <label className=" form-label">{this.state.resources.Label_LastName}</label>
                                <input className="form-control" type="text" name="lastName" onChange={onChangeLastName} value={this.state.lastName}  />
                            </div>

                            <div className="form-group col-md-2" >
                                <label className=" form-label">{this.state.resources.Label_DocNumber}</label>
                                <input className="form-control" type="text" name="identificationNr" onChange={onChangeIdentification} value={this.state.identificationNr} />
                            </div>

                            <div className="form-group col-md-2" >
                                <label className=" form-label">{this.state.resources.Label_Area}</label>
                                <select className="form-control" data-val="true" name="areaId" value={this.state.areaId} onChange={onChangeArea}>
                                    <option value="-1">{helpers.getUiLanguage() == 'language1' ? 'Todas' : 'All'}</option>
                                    {this.state.areas.map(area =>
                                        <option key={area.id} value={area.id}>{area.name}</option>
                                    )}
                                </select>
                            </div>

                            <div className="form-group col-md-2" >
                                <label className=" form-label">{this.state.resources.Label_Status}</label>
                                <select className="form-control" data-val="true" name="enabled" value={this.state.enabled} onChange={onChangeEnabled}>
                                    <option value="-1">{helpers.getUiLanguage() == 'language1' ? 'Todos' : 'All'}</option>
                                    <option value={1}>{this.state.resources.Label_Enabled}</option>
                                    <option value={0}>{this.state.resources.Label_Disabled}</option>
                                </select>
                            </div>
                            <div className="col-md-10 "></div>
                            <div className="col-md-2 " style={{justifyContent: 'space-between' }}>
                                <div className=" buttons-childs">
                                    <button className="btn btn-primary mx-2"onClick={onSendFilter}>{helpers.getUiLanguage() == 'language1' ? 'Enviar' : 'Send'}</button>
                                    <div></div>
                                    <button className="btn btn-danger" onClick={onClearFilter}>{helpers.getUiLanguage() == 'language1' ? 'Limpiar' : 'Clear'}</button>
                                </div>
                                
                            </div>
                        </div>
                        
                    </Box>
                </Modal>
            </div>
        </>
    );
}