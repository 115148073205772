import React, { Component } from 'react';
import axios from 'axios';
import $ from 'jquery';
import helpers from '../../Helpers';
import DataCreation from '../DataCreation/DataCreation';

export class PublicityDetail extends Component {
    static displayName = PublicityDetail.name;

    constructor(props) {
        super(props);

        this.onChangePublicity = this.onChangePublicity.bind(this);
        this.onChangeCountry = this.onChangeCountry.bind(this);
        this.onChangeProductsCountry = this.onChangeProductsCountry.bind(this);
        this.onChangeEnabled = this.onChangeEnabled.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onSubmit = this.onSubmit.bind(this);  

        this.state = {
            id: 0,
            publicity: '',
            countries: [],
            countryId: null,
            productId: null,
            products: [],
            product_country_id: null,
            product_countries: [],
            country_name: '',
            enabled: true,
            loading: true,
            resources: [],
            userId: 0,
            viewMode: false,
            currentUserId: 0,
        };
    }

    componentDidMount() {
        this.getResources();
        this.populateData();
        helpers.setNavActive("#nav-publicity");
        helpers.setClientValidation();

        let url = window.location.href;
        if (url.includes('view')) {
            this.setState({
                viewMode: true
            });
        }
        let currentUser = helpers.getUserData();
        this.setState({
            currentUserId: currentUser.id
        });

        let sort = JSON.parse(localStorage.getItem('PublicityListSorting'))
        localStorage.setItem('CopyPublicityListSorting', JSON.stringify(sort));

        let paging = JSON.parse(localStorage.getItem('PublicityListPagination'))
        localStorage.setItem('CopyPublicityListPaging', JSON.stringify(paging));

        let filtering = JSON.parse(localStorage.getItem('PublicityListFiltering'))
        localStorage.setItem('CopyPublicityListFiltering', JSON.stringify(filtering));
    }

    async onSubmit(e) {
        e.preventDefault();

        var form = document.querySelectorAll('.needs-validation')[0];

        if (!form.checkValidity())
            return;

        this.setState({ loading: true });

        const data = {
            id: parseInt(this.props.match.params.id),
            publicity: this.state.publicity,
            country_id: this.state.countryId,
            product_country_id: this.state.productId,
            enabled: this.state.enabled,
            last_update_user_id: this.state.currentUserId
        };
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME)
        };

        axios.post(window.config.REACT_APP_PRODUCTS_API_URL + '/publicity/save', data, {
            headers: headers
        })
            .then((response) => {
                helpers.notifySuccess(this.state.resources.Label_Success, this.state.resources.SuccessMessage_DataSavedSuccessfully);
                this.props.history.push('/publicity');
            })
            .catch((error) => {
                console.log(error);

                let msg = this.state.resources.ErrorMessage_PublicityError;
                //let msg = localStorage.getItem('ErrorLanguage') == 'language0' ? `Ya existe una publicidad con la misma combinación` : `Publicity is already exist`;
                helpers.notifyError(this.state.resources.Label_Error, msg);
                this.setState({ loading: false });
            });
    }  

    onCancel(e) {
        e.preventDefault();
        this.props.history.push("/publicity");
    }

     onChangePublicity(e) {
        this.setState({
            publicity: e.target.value
        });
    }
    
    async onChangeCountry(e) {
        this.setState({
            countryId: e.target.value
        });
         let authTokenProducts = await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME);
            axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/productcountry/getproductsbycountryid/' + this.state.countryId,
                {
                    'headers': { 'Authorization': authTokenProducts}
                })
                .then(response => {
                    const data = response.data.data;
                    this.state.product_countries = [];
                     console.log(data);
                    for (let i = 0; i < data.length; i++) {
                        this.state.product_countries.push(data[i].product_id);
                    }
                    // order alphabetically
                    this.state.product_countries.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                     console.log(this.state.product_countries);
                    setTimeout(() => {
                        this.setState({
                            product_countries: this.state.product_countries,
                            //country_name: data[0].country_name
                         });
                    }, 1000);
                })
                .catch(function (error) {
                    console.log(error);
                });
    }

    onChangeProductsCountry(e) {
        this.setState({
            productId: e.target.value
        });
        console.log(e.target.value)
    }

    onChangeEnabled(e) {
        this.setState({
            enabled: e.target.checked
        });
    } 

    render() {
        let contents =
            <form className="form row g-3 needs-validation" onSubmit={this.onSubmit} noValidate>
                <div className="form-group col-md-12">
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" id="enabled" checked={this.state.enabled} onChange={this.onChangeEnabled} />
                        <label disabled={this.state.viewMode} className="form-check-label" htmlFor="enabled">{this.state.resources.Label_Enabled}</label>
                        <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                    </div>
                </div>

                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Title_Publicity}</label>
                    <input disabled={this.state.viewMode} className="form-control" type="text" name="publicity" onChange={this.onChangePublicity} value={this.state.publicity} required />
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                </div>

                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_Country}</label>
                    <select disabled={this.state.viewMode} className="form-control" data-val="true" name="countryid" defaultValue={this.state.countryId} onChange={this.onChangeCountry} required>
                        <option value="">{this.state.resources.Label_SelectOption}</option>
                        {this.state.countries.map(country =>
                            <option key={country.id} value={country.id} selected={this.state.countryId == country.id}>{country.name}</option>
                        )}
                    </select>
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                </div>
                
                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_Products}</label>
                    <select disabled={this.state.viewMode} className="form-control" data-val="true" name="productid" defaultValue={this.state.productId} onChange={this.onChangeProductsCountry} required>
                        <option value="">{this.state.resources.Label_SelectOption}</option>
                        {this.state.products.map(product =>
                            this.state.product_countries.includes(product.id) &&
                                <option key={product.id} value={product.id} selected={this.state.productId == product.id} >{product.name}</option>
                        )}
                    </select>
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                </div> 

                <div className=" form-group col-md-6">
                     <DataCreation resources={this.state}></DataCreation>
                </div>

                {/**Buttons Header */}
                <div className="buttons-head row">
                    {this.state.viewMode == false ?
                    <div className=" buttons-childs">
                        <button type="submit" className="btn btn-primary">{this.state.resources.ButtonLabel_Save}</button>
                        <button className="btn btn-danger" onClick={this.onCancel}>{this.state.resources.ButtonLabel_Cancel}</button>
                    </div>
                    :
                    <button className="btn btn-danger btn-block" onClick={this.onCancel}>{this.state.resources.Label_GoBack}</button>
                    }
                 </div>

            </form>;

        return (
            <div className="mt-2">
                <ol className="breadcrumb my-4">
                    <li className="breadcrumb-item active">{this.state.resources.Title_Publicity}</li>
                </ol>
                <div className="card">
                    <div className="card-body">
                        {this.state.loading ? helpers.showLoadingOverlay() : helpers.hideLoadingOverlay()}
                        {contents}
                    </div>
                </div>
            </div>
        );
    }

    async getResources() {
        const resourceVars = [];
        resourceVars.push("Title_CampaingType");
        resourceVars.push("Label_Name");
        resourceVars.push("Label_Country");
        resourceVars.push("Title_Carrier");
        resourceVars.push("Label_SelectOption");
        resourceVars.push("Label_Enabled");
        resourceVars.push("Label_Success");
        resourceVars.push("ButtonLabel_Save");
        resourceVars.push("ButtonLabel_Cancel");
        resourceVars.push("Label_Error");
        resourceVars.push("ErrorMessage_RequiredField");
        resourceVars.push("ErrorMessage_DuplicatedName");
        resourceVars.push("ErrorMessage_UnknownError");
        resourceVars.push("SuccessMessage_DataSavedSuccessfully");
        resourceVars.push("Label_GoBack");
        resourceVars.push("Label_Creation");
        resourceVars.push("Label_CreationUser");
        resourceVars.push("Label_LastUpdate");
        resourceVars.push("Label_LastUpdateUser");
        resourceVars.push("Title_Publicity");
        resourceVars.push("Label_Products");
        resourceVars.push("ErrorMessage_PublicityError");
        
        const headers = {
            'Content-Type': 'application/json'
        };

        axios.post(window.config.REACT_APP_SECURITY_API_URL + '/languageresource/getbyvars/' + helpers.getUiLanguage(), resourceVars, {
            headers: headers
        })
            .then((response) => {
                const data = response.data.data;
                this.setState({ resources: data });
            })
            .catch((error) => {
                console.log(error);
                this.setState({ loading: false });
            });
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/country/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ countries: data });
                // order alphabetically
                this.state.countries.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                // console.log(this.state.countries);
            })
            .catch(function (error) {
                console.log(error);
            });
            axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/product/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ products: data});
                // order alphabetically
                this.state.products.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                // console.log(this.state.brands);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    async populateData() {
        this.setState({
            loading: true
        });
        let authTokenProducts = await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME);
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/publicity/getbyid/' + this.props.match.params.id,
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                 console.log(data);
                this.setState({
                    id: data.id,
                    publicity: data.publicity,
                    countryId: data.country_id,
                    productId: data.product_country_id,
                    enabled: data.enabled,
                    creation: helpers.getDisplayDateTimeFromUtc(data.creation),
                    creationUserId: data.creation_user_fullname,
                    lastUpdate: data.last_update == null ? '' : helpers.getDisplayDateTimeFromUtc(data.last_update),
                    lastUpdateUserId: data.last_update_user_fullname == null ? '' : data.last_update_user_fullname
                });
        
                axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/productcountry/getproductsbycountryid/' + this.state.countryId,
                {
                    'headers': { 'Authorization': authTokenProducts }
                })
                .then(response => {
                    const data = response.data.data;
                    this.state.product_countries = [];
                     console.log(data);
                    for (let i = 0; i < data.length; i++) {
                        this.state.product_countries.push(data[i].product_id);
                    }
                    // order alphabetically
                    this.state.product_countries.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                     console.log(this.state.product_countries);
                    setTimeout(() => {
                        this.setState({
                            product_countries: this.state.product_countries,
                            country_name: data[0].country_name,
                            loading: false
                         });
                    }, 1000);
                })
                .catch(function (error) {
                    console.log(error);
                });
            })
            .catch(function (error) {
                console.log(error);
                this.setState({ loading: false });
            }) 

     }
}