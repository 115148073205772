import React, { Component } from 'react';
import axios from 'axios';
import $ from 'jquery';
import helpers from '../../Helpers'
import DataCreation from '../DataCreation/DataCreation';

export class TreatmentQuestionDetail extends Component {
    static displayName = TreatmentQuestionDetail.name;

    constructor(props) {
        super(props);

        this.onChangeTreatment = this.onChangeTreatment.bind(this);
        this.onChangeQuestion = this.onChangeQuestion.bind(this);
        this.onChangeCountry = this.onChangeCountry.bind(this);
        this.onChangeQuestionType = this.onChangeQuestionType.bind(this);
        this.onChangeAnswerType = this.onChangeAnswerType.bind(this);
        this.onChangeQuestionValue = this.onChangeQuestionValue.bind(this);
        this.onChangeOutputOrder = this.onChangeOutputOrder.bind(this);
        this.onChangeEnabled = this.onChangeEnabled.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onSubmit = this.onSubmit.bind(this);  

        this.state = {
            id: 0,
            question: '',
            question_type: '',
            output_order: 0,
            answer_type: '',
            question_value: '',
            enabled: true,
            loading: true,
            resources: [],
            treatments: [],
            treatmentId: null,
            userId: 0,
            countries: [],
            countryId: null,
        };
    }

    componentDidMount() {
        this.getResources();
        this.populateData();
        helpers.setNavActive("#nav-TreatmentQuestion");

        let currentUser = helpers.getUserData();
        this.setState({
            userId: currentUser.id
        });
    }

    async onSubmit(e) {
        e.preventDefault();
        this.setState({ loading: true });

        const data = {
            id: parseInt(this.props.match.params.id),
            name: this.state.name,
            question: this.state.question,
            treatment_id: this.state.treatmentId,
            question_value: this.state.question_value,
            question_type: this.state.question_type,
            answer_type: this.state.answer_type,
            output_order: this.state.output_order,
            enabled: this.state.enabled,
            creation_user_id: this.state.userId,
            country_id: this.state.countryId,
        };
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME)
        };

        axios.post(window.config.REACT_APP_PRODUCTS_API_URL + '/TreatmentQuestion/save', data, {
            headers: headers
        })
            .then((response) => {
                helpers.notifySuccess(this.state.resources.Label_Success, this.state.resources.SuccessMessage_DataSavedSuccessfully);
                this.props.history.push('/treatmentsQuestion');
            })
            .catch((error) => {
                console.log(error);

                let msg = this.state.resources.ErrorMessage_UnknownError;
                if (error.response.data.error_code == window.errorCodes.DuplicatedEntityName)
                    msg = this.state.resources.ErrorMessage_DuplicatedName;

                helpers.notifyError(this.state.resources.Label_Error, msg);
                this.setState({ loading: false });
            });
    }  

    onCancel(e) {
        e.preventDefault();
        this.props.history.push("/treatmentsQuestion");
    }

    onChangeQuestion(e) {
        this.setState({
            question: e.target.value
        });
    } 
    
    onChangeQuestionType(e) {
        this.setState({
            question_type: e.target.value
        });
    } 
    
    onChangeQuestionValue(e) {
        this.setState({
            question_value: e.target.value
        });
    }

    onChangeCountry(e) {
        this.setState({
            countryId: e.target.value
        });
    }
    
    onChangeAnswerType(e) {
        this.setState({
            answer_type: e.target.value
        });
    } 

    onChangeOutputOrder(e) {
        this.setState({
            output_order: e.target.value
        });
    }

    onChangeEnabled(e) {
        this.setState({
            enabled: e.target.checked
        });
    } 

    onChangeTreatment(e) {
        this.setState({
            treatmentId: e.target.value
        });
    }

    render() {
        let contents = this.state.loading
            ? helpers.showLoadingOverlay()
            :
            <form className="form row g-3 needs-validation" onSubmit={this.onSubmit}>
                <div className="form-group col-md-12">
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" id="enabled" defaultChecked={this.state.enabled} onChange={this.onChangeEnabled} />
                        <label className="form-check-label" htmlFor="enabled">{this.state.resources.Label_Enabled}</label>
                        <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                    </div>
                </div>
                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Title_Treatments}</label>
                    <select className="form-control" data-val="true" name="treatment_id" defaultValue={this.state.treatmentId} onChange={this.onChangeTreatment} required>
                        <option value="">{this.state.resources.Label_SelectOption}</option>
                        {this.state.treatments.map(country =>
                            <option key={country.id} value={country.id} selected={this.state.treatmentId == country.id}>{country.name}</option>
                        )}
                    </select>                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                </div>
                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_Country}</label>
                    <select disabled={this.state.viewMode} className="form-control" data-val="true" name="country_id" defaultValue={this.state.countryId} onChange={this.onChangeCountry} required>
                        <option value="">{this.state.resources.Label_SelectOption}</option>
                        {this.state.countries.map(country =>
                            <option key={country.id} value={country.id} selected={this.state.countryId == country.id}>{country.name}</option>
                        )}
                    </select>
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                </div>
                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_Question}</label>
                    <input className="form-control" type="text" name="question" onChange={this.onChangeQuestion} value={this.state.question} required />
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                </div>
                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_Question_Type}</label>
                    <select className="form-control" data-val="true" name="question_type" defaultValue={this.state.question_type} onChange={this.onChangeQuestionType} required>
                        <option value="">{this.state.resources.Label_SelectOption}</option>
                        <option value="N"  selected={this.state.question_type == "N"}>Numérico</option>
                        <option value="A" selected={this.state.question_type == "A"}>Alfanumérico</option>
                        <option value="F" selected={this.state.question_type == "F"}>Fecha</option>
                        <option value="B" selected={this.state.question_type == "B"}>Booleano</option>
                        <option value="L" selected={this.state.question_type == "L"}>Lista</option>
                    </select>                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                </div>
                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_Question_Value}</label>
                    <input className="form-control" type="text" name="question_value" onChange={this.onChangeQuestionValue} value={this.state.question_value}  />
                </div>
                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_Answer_Type}</label>
                    <select className="form-control" data-val="true" name="answer_type" defaultValue={this.state.answer_type} onChange={this.onChangeAnswerType} required>
                        <option value="">{this.state.resources.Label_SelectOption}</option>
                        <option value="Obligatorio"  selected={this.state.answer_type == "Obligatorio"}>Obligatoria</option>
                        <option value="Opcional"  selected={this.state.answer_type == "Opcional"}>Opcional</option>
                    </select>                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                </div>
                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_Output_Order}</label>
                    <input className="form-control" type="number" name="output_order" onChange={this.onChangeOutputOrder} value={this.state.output_order} required />
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                </div>

                {/*LAST UPDATE / LAST UPDATE USER*/}
                <div className="form-group col-md-6" >
                    <DataCreation resources={this.state}></DataCreation>
                </div>

                {/**Buttons Header */}
                 <div className="buttons-head row">
                    <div className=" buttons-childs">
                        <button type="submit" className="btn btn-primary">{this.state.resources.ButtonLabel_Save}</button>
                        <button className="btn btn-danger" onClick={this.onCancel}>{this.state.resources.ButtonLabel_Cancel}</button>
                    </div>
                 </div>
                {/* <div className="form-group col-12 text-end">
                    <button type="submit" className="btn btn-primary">{this.state.resources.ButtonLabel_Save}</button>
                    <button className="btn btn-negative" onClick={this.onCancel}>{this.state.resources.ButtonLabel_Cancel}</button>
                </div > */}
            </form>
            helpers.hideLoadingOverlay();

        return (
            <div className="mt-2">
                <ol className="breadcrumb my-4">
                    <li className="breadcrumb-item active">{this.state.resources.NavItem_TreatmentQuestion}</li>
                </ol>
                <div className="card">
                    <div className="card-body">
                        {contents}
                    </div>
                </div>
            </div>
        );
    }

    async getResources() {
        const resourceVars = [];
        resourceVars.push("NavItem_TreatmentQuestion");
        resourceVars.push("Title_Treatments");
        resourceVars.push("Label_Question");
        resourceVars.push("Label_Country");
        resourceVars.push("Label_SelectOption");
        resourceVars.push("Label_Question_Type");
        resourceVars.push("Label_Question_Value");
        resourceVars.push("Label_Answer_Type");
        resourceVars.push("Label_Output_Order");
        resourceVars.push("Label_Enabled");
        resourceVars.push("Label_Success");
        resourceVars.push("ButtonLabel_Save");
        resourceVars.push("ButtonLabel_Cancel");
        resourceVars.push("Label_Error");
        resourceVars.push("ErrorMessage_RequiredField");
        resourceVars.push("ErrorMessage_DuplicatedName");
        resourceVars.push("ErrorMessage_UnknownError");
        resourceVars.push("SuccessMessage_DataSavedSuccessfully");
        resourceVars.push("Label_Creation");
        resourceVars.push("Label_LastUpdate");

        const headers = {
            'Content-Type': 'application/json'
        };

        axios.post(window.config.REACT_APP_SECURITY_API_URL + '/languageresource/getbyvars/' + helpers.getUiLanguage(), resourceVars, {
            headers: headers
        })
            .then((response) => {
                const data = response.data.data;
                this.setState({ resources: data, loading: false });
            })
            .catch((error) => {
                console.log(error);
                this.setState({ loading: false });
            });
            
            axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/country/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ countries: data, loading: false });
                // order alphabetically
                this.state.countries.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                // console.log(this.state.countries);
            })
            .catch(function (error) {
                console.log(error);
            });

            axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/treatment/getall',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ treatments: data, loading: false });
                // order alphabetically
                this.state.treatments.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                // console.log(this.state.countries);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    async populateData() {
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/TreatmentQuestion/getbyid/' + this.props.match.params.id,
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({
                    id: data.id,
                    question: data.question,
                    treatmentId: data.treatment_id,
                    question_value: data.question_value,
                    question_type: data.question_type,
                    answer_type: data.answer_type,
                    output_order: data.output_order,
                    enabled: data.enabled,
                    loading: false,
                    countryId: data.country_id,
                    creation: helpers.getDisplayDateTimeFromUtc(data.creation),
                    creationUser: data.creation_user_fullname,
                    lastUpdate: data.last_update == null ? '' : helpers.getDisplayDateTimeFromUtc(data.last_update),
                    lastUpdateUser: data.last_update_user_fullname == null ? '' : data.last_update_user_fullname,
                });
            })
            .catch(function (error) {
                console.log(error);
                this.setState({ loading: false });
            })  
    }
}