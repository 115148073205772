import { data, event } from 'jquery';
import React, { Component, useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import helpers from '../../Helpers';
import '../../custom.css';
import { Link, Router } from 'react-router-dom';
import { useHistory, useLocation, Redirect } from "react-router";
import { debounce } from '@mui/material';
import {CSVLink} from 'react-csv'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CircularProgress from '@mui/material/CircularProgress';


export class ProductCountryInfo extends Component {
    mounted = false;
    constructor(props) {
        super(props);

        this.onCancel = this.onCancel.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onFilterItems = this.onFilterItems.bind(this);
        this.resetFilter = this.resetFilter.bind(this);

        this.state = {
            id: this.props.values.id,
            name: null,
            courier: null,
            courierCost: null,
            shippingCost: null,
            stock: null,
            manufacturingCost: null,
            productCountryId: null,
            keyActive: null,
            productCountryKey: null,
            presentationUnits: null,
            crossSell: null,
            price: null,
            iva: null,
            units: null,
            treatmentDuration: null,
            country_id: null,
            keys: [],
            resources: [],
            csvItems: [],
            loading: false,
            viewMode: false,
            currentProductName: null,
            currentProductId: null,
            royalties: 0,
            filteringProdKeys: "",
            filterActive: false,
            csvOK : false,
            importItems: [],
            token:"",
            userId: 0,
            importOK: 0,
            resultImport: [],
            mensajeOK: ""
        };

        ImportRoute = ImportRoute.bind(this);
    }

    componentDidMount() {
        let userData = helpers.getUserData();
        this.state.userId = userData.id;
        this.getResources();
        this.populateData();
        helpers.setClientValidation();
        // helpers.hideLoadingOverlay();
        if (localStorage.getItem('viewMode') === 'true' || localStorage.getItem('viewMode') === true) {
            this.state.viewMode = true;
        }
        this.mounted = true;
        console.log(this.mounted);
    }
    async onSubmit(e) {
        e.preventDefault();
    }

    onCancel(e) {
        e.preventDefault();
        
        localStorage.removeItem('viewMode');
    
        window.location.href = "/products";
    }

    goNewKey(e) {
        e.preventDefault();
        //localStorage.setItem('productCountryId', this.state.productCountryId);
        window.location.href = "/product-countrykey-create";
    }

    onFilterItems(e){
        this.setState({
            filterActive: true,
            filteringProdKeys: e.target.value,
        });
        this.state.filteringProdKeys = e.target.value;
        this.state.filterActive = true;
    }

    resetFilter(){
        document.querySelector('#btnFilterProcs').value = "";
        this.setState({
            filterActive: false,
            filteringProdKeys: "",
        });
        this.state.filteringProdKeys = "";
        this.state.filterActive = false;
    }

    render() {
        // console.log(this.props);
        let contents =
            <div>
                <div>
                    <h4>{this.state.resources.Label_Detail}</h4>
                    <div className="detail-country-proc">
                        <div className="m-2">
                            <label>{this.state.resources.Label_Name}</label>
                            <ol className="breadcrumb my-3" style={{minHeight: '48px'}}>
                                <li className="breadcrumb-item active">{this.state.name}</li>
                            </ol>
                        </div>
                         
                         <div className="m-2">
                            <label>{this.state.resources.Title_Courier}</label>
                            <ol className="breadcrumb my-3" style={{minHeight: '48px'}}>
                                <li className="breadcrumb-item active">{this.state.courier}</li>
                            </ol>
                        </div>
                         
                         <div className="m-2">
                            <label>Stock</label>
                            <ol className="breadcrumb my-3" style={{minHeight: '48px'}}>
                                <li className="breadcrumb-item active">{this.state.stock}</li>
                            </ol>
                        </div>
                        <div className="m-2">
                            <label>{this.state.resources.Label_Royalties}(USD)</label>
                            <ol className="breadcrumb my-3" style={{minHeight: '48px'}}>
                                <li className="breadcrumb-item active">{this.state.royalties?.toLocaleString('en-US')}</li>
                            </ol>
                        </div>

                        <div className="m-2">
                            <label>{this.state.resources.Label_CourierCost}(USD)</label>
                            <ol className="breadcrumb my-3" style={{minHeight: '48px'}}>
                                <li className="breadcrumb-item active">{this.state.courierCost?.toLocaleString('en-US')}</li>
                            </ol>
                        </div>

                        <div className="m-2" style={{flexBasis: "20%"}}>
                            <label>{this.state.resources.Label_ShippingCost}(Pesos)</label>
                            <ol className="breadcrumb my-3" style={{minHeight: '48px'}}>
                                <li className="breadcrumb-item active">{this.state.shippingCost?.toLocaleString('en-US')}</li>
                            </ol>
                        </div>
                        
                         <div className="m-2">
                            <label>{this.state.resources.Label_ProductCost}(USD)</label>
                            <ol className="breadcrumb my-3" style={{minHeight: '48px'}}>
                                <li className="breadcrumb-item active">{this.state.manufacturingCost?.toLocaleString('en-US')}</li>
                            </ol>
                        </div>
                        <div className="m-2" style={{flexBasis: "10%"}}>
                            { this.state.viewMode == false ? 
                            <Link to={`/product-country-detail/${this.state.productCountryId}`} className="btn btn-success mx-2 mt-5">{this.state.resources.ButtonLabel_ViewEdit}</Link>
                            : 
                            <Link to={`/product-country-view/${this.state.productCountryId}`} className="btn btn-success mx-2 mt-5"><i class="fas fa-eye"></i></Link>
                            }
                        </div>
                        
                    </div>

                    <div >
                        <div className="detail-country-proc-mobile">
                            <div className="m-2">
                                <label>{this.state.resources.Label_Name}</label>
                                <ol className="breadcrumb my-3" style={{minHeight: '48px'}}>
                                    <li className="breadcrumb-item active">{this.state.name}</li>
                                </ol>
                            </div>
                            
                            <div className="m-2">
                                <label>{this.state.resources.Title_Courier}</label>
                                <ol className="breadcrumb my-3" style={{minHeight: '48px'}}>
                                    <li className="breadcrumb-item active">{this.state.courier}</li>
                                </ol>
                            </div>
                            <div className="m-2">
                                <label>Stock</label>
                                <ol className="breadcrumb my-3" style={{minHeight: '48px'}}>
                                    <li className="breadcrumb-item active">{this.state.stock}</li>
                                </ol>
                            </div>
                            <div className="m-2">
                                <label>{this.state.resources.Label_Royalties}(USD)</label>
                                <ol className="breadcrumb my-3" style={{minHeight: '48px'}}>
                                    <li className="breadcrumb-item active">{this.state.royalties?.toLocaleString('en-US')}</li>
                                </ol>
                            </div>
                        </div>
                        <div className="detail-country-proc-mobile">
                            
                            

                            <div className="m-2">
                                <label>{this.state.resources.Label_CourierCost}(USD)</label>
                                <ol className="breadcrumb my-3" style={{minHeight: '48px'}}>
                                    <li className="breadcrumb-item active">{this.state.courierCost?.toLocaleString('en-US')}</li>
                                </ol>
                            </div>

                            <div className="m-2">
                                <label>{this.state.resources.Label_ShippingCost}(Pesos)</label>
                                <ol className="breadcrumb my-3" style={{minHeight: '48px'}}>
                                    <li className="breadcrumb-item active">{this.state.shippingCost?.toLocaleString('en-US')}</li>                           
                                </ol>
                            </div>

                            <div className="m-2">
                                <label>{this.state.resources.Label_ProductCost}(USD)</label>
                                <ol className="breadcrumb my-3" style={{minHeight: '48px'}}>
                                    <li className="breadcrumb-item active">{this.state.manufacturingCost?.toLocaleString('en-US')}</li>
                                </ol>
                            </div>
                            <div className="m-2">
                                { this.state.viewMode == false ? 
                                <Link to={`/product-country-detail/${this.state.productCountryId}`} className="btn btn-success mx-2 mt-5">{this.state.resources.ButtonLabel_ViewEdit}</Link>
                                : 
                                <Link to={`/product-country-view/${this.state.productCountryId}`} className="btn btn-success mx-2 mt-5"><i class="fas fa-eye"></i></Link>
                                }
                            </div>
                         </div>
                        
                    </div>

                    <div className="dis-flex mb-2">
                        <h4>{this.state.resources.Label_Keyslist}</h4>
                        { this.state.viewMode == false ? 
                        <Link to="/product-countrykey-create" className="btn btn-success mx-2">{this.state.resources.Label_NewKey}</Link>
                        : <></>}
                        <div className="dis-flex">
                            <input className="form-control ml-2" style={{maxWidth: '200px'}} data-val="true" id="btnFilterProcs" placeholder={localStorage.getItem('uilanguage') == 'language0' ? 'Search' : 'Buscar'}
                            onChange={this.onFilterItems} defaultValue={this.state.filteringProdKeys} />
                            
                            <button className='btn btn-danger mx-2' style={{minWidth: '70px'}} onClick={this.resetFilter}>X</button>
                            {this.state.csvOK == true ?
                            <CSVLink data={this.state.csvItems} filename="CLAVE_PRODUCTOS.csv"><button className="btn btn-success">{this.state.resources.Label_Export}</button></CSVLink>
                            : <></>}
                            &nbsp; &nbsp; 
                            <ImportRoute data={this.state}/>
                        </div>
                    </div>
                    <div>
                        <table className='table datatable my-table' aria-labelledby="tabelLabel"> {/*table class:* table-striped datatable */}
                    <thead>
                      <tr>
                            <th className="text-center">{this.state.resources.Label_Actions}</th>
                            <th  className="text-center">{this.state.resources.Label_Active}</th>
                            <th  className="text-center">{this.state.resources.Label_Key}</th>
                            <th  className="text-center">{this.state.resources.Label_Presentation}</th>
                            <th  className="text-center">{this.state.resources.Label_Units}</th>
                            <th  className="text-center">{this.state.resources.Label_Price?.toLocaleString('en-US')}</th>
                            {/* <th  className="text-center">IVA</th> */}
                            {/* <th  className="text-center">Kit</th> */}
                            <th  className="text-center">{this.state.resources.Label_IndividualSale}</th>
                            <th  className="text-center">Cross Sell</th>
                            {/* <th  className="text-center">{this.state.resources.Title_Treatments}</th> */}
                        </tr>
                    </thead>
                    <tbody>
                                {this.state.keys.length > 0 &&
                                    this.state.keys.map(key =>
                                        this.state.filterActive ?
                                        <>
                                            {key.description.toLowerCase().includes(this.state.filteringProdKeys.toLowerCase()) ||
                                            key.product_key.toLowerCase().includes(this.state.filteringProdKeys.toLowerCase()) ?
                                            <tr key={key.id}>
                                                <td className="table-icons">
                                                    {this.state.viewMode == false ?
                                                        <Link to={"/product-countrykey-detail/" + key.id} className="btn btn-default"><i className="fas fa-edit"></i></Link>
                                                        : <Link to={"/product-countrykey-view/" + key.id} className="btn btn-default"><i className="fas fa-eye"></i></Link>
                                                    }
                                                </td>
                                                <td>
                                                    <div className="form-group col-md-12">
                                                        <div className="form-check form-switch">
                                                            <input className="form-check-input" type="checkbox" id="enabled" checked={key.enabled} disabled/>
                                                            <label className="form-check-label" htmlFor="enabled"></label>
                                                        
                                                        </div>
                                                    </div>    
                                                </td>
                                                <td  className="text-center">{key.product_key}</td>
                                                <td  className="text-center">{key.description}</td>
                                                <td  className="text-center">{key.units}</td>
                                                <td  className="text-center">{key.price?.toLocaleString('en-US')}</td>
                                                {/* <td  className="text-center">{this.state.iva ? '21%' : 'No'}</td> */}
                                                {/* <td  className="text-center">{key.units}</td> */}
                                                <td  className="text-center">{key.single_sale ? this.state.resources.Label_Yes : 'No'}</td>
                                                <td  className="text-center">{key.cross_sell ? this.state.resources.Label_Yes : 'No'}</td>
                                                {/* <td  className="text-center">{this.state.treatmentName}</td> */}
                                            </tr>:<></>}
                                        </>:
                                        <>
                                            <tr key={key.id}>
                                                <td className="table-icons">
                                                    {this.state.viewMode == false ?
                                                        <Link to={"/product-countrykey-detail/" + key.id} className="btn btn-default"><i className="fas fa-edit"></i></Link>
                                                        : <Link to={"/product-countrykey-view/" + key.id} className="btn btn-default"><i className="fas fa-eye"></i></Link>
                                                    }
                                                </td>
                                                <td>
                                                    <div className="form-group col-md-12">
                                                        <div className="form-check form-switch">
                                                            <input className="form-check-input" type="checkbox" id="enabled" checked={key.enabled} disabled/>
                                                            <label className="form-check-label" htmlFor="enabled"></label>
                                                        
                                                        </div>
                                                    </div>    
                                                </td>
                                                <td  className="text-center">{key.product_key}</td>
                                                <td  className="text-center">{key.description}</td>
                                                <td  className="text-center">{key.units}</td>
                                                <td  className="text-center">{key.price?.toLocaleString('en-US')}</td>
                                                {/* <td  className="text-center">{this.state.iva ? '21%' : 'No'}</td> */}
                                                {/* <td  className="text-center">{key.units}</td> */}
                                                <td  className="text-center">{key.single_sale ? this.state.resources.Label_Yes : 'No'}</td>
                                                <td  className="text-center">{key.cross_sell ? this.state.resources.Label_Yes : 'No'}</td>
                                                {/* <td  className="text-center">{this.state.treatmentName}</td> */}
                                            </tr>
                                        </>
                                    )
                                }
                        
                    </tbody>
                </table>
                    </div>
                </div>
            </div>
        
        return (

            <div>
                <div className="mt-2">
                    {contents}
                </div>
                {/**Buttons Header */}
                 <div className="buttons-head row">
                    <div className=" buttons-childs">
                        {/* <button type="submit" className="btn btn-primary">{this.state.resources.ButtonLabel_Save}</button> */}
                        {/* <a href="javascript:history.go(-2);" className="btn btn-block p-0" style={{ textDecoration: 'none' }, { color: 'inherit' }}></a> */}
                        <Link to={"/products"} className="btn btn-block p-0" style={{ textDecoration: 'none', color: 'inherit' }}>
                            <button className="btn btn-outline-danger btn-block">{this.state.resources.Label_GoBack}</button>
                        </Link>
                        
                    </div>
                 </div>
            </div>
        );
    }


    async getResources() {
        const resourceVars = [];
        resourceVars.push("Label_Products");
        resourceVars.push("Label_Name");
        resourceVars.push("Label_Description");
        resourceVars.push("Label_Detail");
        resourceVars.push("Label_Logo");
        resourceVars.push("Label_FiscalId");
        resourceVars.push("Label_Brand");
        resourceVars.push("Label_Supplier");
        resourceVars.push("Title_Treatments");
        resourceVars.push("Label_Logo");
        resourceVars.push("Label_BusinessArea");
        resourceVars.push("Label_Enabled");
        resourceVars.push("Label_Success");
        resourceVars.push("Label_SelectOption");
        resourceVars.push("ButtonLabel_Save");
        resourceVars.push("ButtonLabel_Cancel");
        resourceVars.push("Label_Error");
        resourceVars.push("ErrorMessage_RequiredField");
        resourceVars.push("ErrorMessage_DuplicatedName");
        resourceVars.push("ErrorMessage_UnknownError");
        resourceVars.push("ErrorMessage_AssociatedEntity_Company_User");
        resourceVars.push("ErrorMessage_ErrorWhileUploadingImage");
        resourceVars.push("ErrorMessage_InvalidEmail");
        resourceVars.push("SuccessMessage_DataSavedSuccessfully");
        resourceVars.push("Label_Price");
        resourceVars.push("Label_Units");
        resourceVars.push("Label_Yes");
        resourceVars.push("Label_Key");
        resourceVars.push("Label_ProductCountry");
        resourceVars.push("Title_ProductCountryKey");
        resourceVars.push("Title_Courier");
        resourceVars.push("Title_Countries");
        resourceVars.push("Label_ShippingCost");
        resourceVars.push("Label_ProductCost");
        resourceVars.push("Label_CourierCost");
        resourceVars.push("Label_Keyslist");
        resourceVars.push("Label_NewKey");
        resourceVars.push("Label_Active");
        resourceVars.push("Label_Presentation");
        resourceVars.push("Label_IndividualSale");
        resourceVars.push("Label_Actions");
        resourceVars.push("Label_GoBack");
        resourceVars.push("ButtonLabel_ViewEdit");
        resourceVars.push("Label_Royalties");
        resourceVars.push("Label_Export");
        resourceVars.push("Label_ImportKey");

        const headers = {
            'Content-Type': 'application/json'
        };

        axios.post(window.config.REACT_APP_SECURITY_API_URL + '/languageresource/getbyvars/' + helpers.getUiLanguage(), resourceVars, {
            headers: headers
        })
            .then((response) => {
                const data = response.data.data;
                // console.log(data);
                this.setState({ resources: data, loading: false });
            })
            .catch((error) => {
                console.log(error);
                this.setState({ loading: false });
            });
    }

    async populateData() {


        this.setState({
            loading: true
        });
        helpers.showLoadingOverlay();
        // console.log(this.props)
        // console.log(document.querySelector('.Mui-selected').textContent);
        // console.log(document.querySelector('.Mui-selected').firstChild.textContent);
        
        // let currentCountry = document.querySelector('.Mui-selected')?.firstChild.textContent;
        let currentCountry = document.querySelector('.Mui-selected')?.textContent;
        // console.log(currentCountry);
        let authTokenProducts = await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME);
        let authTokenSecurity = await helpers.getAuthorizationToken(process.env.REACT_APP_SECURITY_API_NAME);

        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/product/getbyid/' + this.state.id,
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                // console.log(data);
                // console.log(this.props);
                // console.log(this.state)
                this.setState({
                    id: data.id,
                    name: data.name,
                    treatmentName: data.treatment_name,
                    loading: false
                });
                localStorage.removeItem('currentProductId');
                localStorage.setItem('currentProductId', this.state.id);

                localStorage.removeItem('currentProductName');
                localStorage.setItem('currentProductName', this.state.name);
                helpers.hideLoadingOverlay();
            })
            .catch(function (error) {
                console.log(error);
                // this.setState({ loading: false });
            })
        
        if (currentCountry) {
            helpers.showLoadingOverlay();
            axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/country/getbyname/' + currentCountry,
            {
                'headers': { 'Authorization': authTokenProducts }
            })
            .then(response => {
                const data = response.data.data;
                // console.log(data);

                this.setState({
                    country_id: data.id,
                    loading: false
                });
                
                // let dataGet = [ this.state.id, this.state.country_id]
                axios.get(window.config.REACT_APP_PRODUCTS_API_URL + `/productcountry/getbyproductcountry/${this.state.id},${this.state.country_id}`,
                {
                    'headers': { 'Authorization': authTokenProducts }
                })
                .then(response => {
                    const data = response.data.data;
                    helpers.hideLoadingOverlay();
                    this.setState({
                        productCountryId: data.id,
                        courier: data.courier_name,
                        courierCost: data.couriercost,
                        crossSell: data.cross_sell,
                        shippingCost: data.shippingcost,
                        stock: data.stock,
                        manufacturingCost: data.manufacturingcost,
                        currentProductId: data.product_id,
                        currentProductName: data.product_name,
                        royalties: data.royalties,
                        loading: false
                    });
                    localStorage.removeItem('productCountryId');
                    localStorage.setItem('productCountryId', this.state.productCountryId);

                    localStorage.removeItem('currentProductId');
                    localStorage.setItem('currentProductId', this.state.currentProductId);

                    localStorage.removeItem('currentProductName');
                    localStorage.setItem('currentProductName', this.state.currentProductName);
                    axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/productcountrykey/getproductcontrykeys/' + this.state.productCountryId,
                    {
                        'headers': { 'Authorization': authTokenProducts }
                    })
                    .then(response => {
                        const data = response.data.data;
                        // console.log(data);
                        this.setState({
                            keys: data,
                            keyActive: data.enabled,
                            productCountryKey: data.product_key,
                            presentationUnits: data.units,
                            price: data.price,
                            iva: data.iva,
                            units: data.units,
                            treatmentDuration: data.duration,
                            loading: false
                        });
                        
                    })
                    .catch(function (error) {
                        console.log(error);
                    })

                    
                    axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/productcountrykey/getproductcontrycsv/' + this.state.productCountryId,
                    {
                        'headers': { 'Authorization': authTokenProducts }
                    })
                    .then(response => {
                        const data = response.data.data;
                        console.log(data);
                        this.setState({ csvItems: data, loading: false, csvOK: true });
                        helpers.hideLoadingOverlay();
                    })
                    .catch(function (error) {
                        console.log(error);
                        helpers.hideLoadingOverlay();
                    });

                })
                .catch(function (error) {
                    console.log(error);
                    helpers.hideLoadingOverlay();
                })
                
            })
            .catch(function (error) {
                console.log(error);
                helpers.hideLoadingOverlay();
            })
        }
    }

    componentWillUnmount(){
        this.mounted = false;
    }

}


function ImportRoute(values) {
    
    const [open, setOpen] = React.useState(false);
    const [filter, setFilter] = React.useState('');
    const [clients, setClient] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [searchError, setSearchError] = React.useState(false);
    const [noMatches, setNoMatches] = React.useState(false);
    const [error, setError] = React.useState("");
    const [file, setFile] = useState();
    //const [data, setData] = useState([]);
    const [array, setArray] = useState([]);

  const fileReader = new FileReader();

  const items = [];


  /*const headers2 = {
    'Content-Type': 'application/json',
    'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME)
  }*/

  const handleOnChange = (e) => {
      if(e.target.files.length > 0)
      {
        setFile(e.target.files[0]);
        fileReader.onload = async function (event) {
            const text = event.target.result;
            let data = csvFileToArray(text);
            /*array.map((item) => (
                  Object.values(item).map((val) => {
                    let result = val.split(';');
                    const mapCourier = {
                        id_pais: result[1],
                        pais: result[2],
                        id_provincia: result[3],
                        provincia: result[4],
                        id_formaPago: result[5],
                        forma_pago: result[6],
                        monto_limite: result[7],
                        id_courier: result[8],
                        courier: result[9],
                        tiempo_entrega: result[10],
                        tipo_ruta: result[11],
                        id_localidades: result[12]
    
                    };
                    
                  })
              ));*/
          };
    
          fileReader.readAsText(e.target.files[0]);
      }
      else
        {
            helpers.notifyError(values.data.resources.Label_Error, "No se exporto ningun archivo o no tiene items para Importar");
        }
  };

  const csvFileToArray = string => {
    const csvHeader = string.slice(0, string.indexOf("\n")).split(";");
    const csvRows = string.slice(string.indexOf("\n") + 1).split("\n");

    const array = csvRows.map(i => {
      const values = i.split(";");
      const obj = csvHeader.reduce((object, header, index) => {
        object[header] = values[index];

        return object;
      }, {});
      items.push(obj);
      return obj;
    });

    setArray(array);
  };

 

  const handleOnSubmit = async (e) => {
    e.preventDefault();

    console.log(items);
    console.log(array);
    if (array.length > 0)
    {
        this.setState({ loading: true, importOK: 1 });
        for (let i = 0; i < array.length; i++)
              {
                if(array[i].Id_Pais != undefined && array[i].Id_Producto != undefined)
                {
                    const mapCourier = {
                        Id_Pais: array[i].Id_Pais,
                        Id_Producto: array[i].Id_Producto,
                        Clave: array[i].Clave,
                        Presentacion: array[i].Presentacion,
                        Estado: array[i].Estado,
                        Venta_Individual: array[i].Venta_Individual,
                        Unidades: array[i].Unidades,
                        Precio: array[i].Precio,
                        Cross_Sell: array[i].Cross_Sell,
                        Id_Producto_Cross_Sell: array[i].Id_Producto_Cross_Sell,
                        Nivel: array[i].Nivel,
                        Iva: array[i].Iva
                    };
                    items.push(mapCourier);
                }
              }
        const data = 
            {
                userId: values.data.userId,
                couriers: items
            }
            const headers2 = {
                'Content-Type': 'application/json',
                'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME)
            };
            axios.post(window.config.REACT_APP_PRODUCTS_API_URL + '/productcountrykey/insertallcsv', data, {
                headers: headers2
            })
                .then((response) => 
                {
                    console.log(response);
                    const val = response.data.data;
                    this.setState({ loading: false, resultImport: response.data.data });
                    if(val.length == 1)
                    {
                        if(val[0].linea == 0)
                        {
                            this.setState({ importOK: 2, mensajeOK: val[0].detalle, items: [] });
                            /*setTimeout(() => {
                                this.setState({ loading: false, resultImport: [], importOK: 0 });
                                //handleClose();    
                            }, 3000)*/
                        }
                        else if(val[0].linea == -1)
                        {
                            this.setState({  importOK: 3 , mensajeOK: val[0].detalle, items: [] });
                            /*setTimeout(() => {
                                this.setState({ loading: false, resultImport: [], importOK: 0 });
                                //handleClose();    
                            }, 3000)*/
                        }
                        else
                        {
                            this.setState({  importOK: 4 , items: []});
                            /*setTimeout(() => {
                                this.setState({ loading: false, resultImport: [], importOK: 0 });
                                //handleClose();   
                            }, 3000)*/
                        }

                    }
                    else
                    {
                        this.setState({  importOK: 4, items: [] });
                        /*setTimeout(() => {
                            this.setState({ loading: false, resultImport: [], importOK: 0 });
                            //handleClose();   
                        }, 4000)*/
                    }
                    
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({ loading: false, importOK: 0 });
                    let msg = values.data.resources.ErrorMessage_UnknownError;
                    if (error.response.data.error_code == window.errorCodes.DuplicatedEntityName)
                        msg = values.data.resources.ErrorMessage_DuplicatedName;
    
                    helpers.notifyError(values.data.resources.Label_Error, msg);
                    this.setState({ loading: false, resultImport: [], importOK: 0});
                });
    }
    else
    {
        helpers.notifyError(values.data.resources.Label_Error, "No se exporto ningun archivo o no tiene items para Importar");
    }
  };


  const headerKeys = Object.keys(Object.assign({}, ...array));


     const allowedExtensions = ["csv"];
   
     /*const handleOnChange = (e) => {
      // console.log(e.target);
       setFile(e.target.files[0]);
      // console.log(e.target.files[0])
     };*/

     /*const handleOnChange = (e) => {
        const files = e.target.files;
        setFile(e.target.files[0]);

        // Check if user has entered the file
        if (e.target.files.length) {
            const inputFile = e.target.files[0];
             
            // Check the file extensions, if it not
            // included in the allowed extensions
            // we show the error
            const fileExtension = inputFile?.type.split("/")[1];
            if (!allowedExtensions.includes(fileExtension)) {
                setError("Please input a csv file");
                return;
            }
 
            // If input type is correct set the state
            setFile(inputFile);
        }

        /*console.log(file);
        if (file) {
          fileReader.onload = function (event) {
            const text = event.target.result;
           console.log(event);
           console.log(event.target.result);
            csvFileToArray(text);
          };
    
          fileReader.readAsText(file);
        }
        console.log("OK1");*/
        /*if (files) {
          console.log(files[0]);
          Papa.parse(files[0], {
            complete: async function(results) {
                let maps = [];
              console.log("Finished:", results.data[1][0]);
              for (let i = 1; i < results.data.length; i++)
              {
                const mapCourier = {
                    id_pais: results.data[i][1],
                    pais: results.data[i][2],
                    id_provincia: results.data[i][3],
                    provincia: results.data[i][4],
                    id_formaPago: results.data[i][5],
                    forma_pago: results.data[i][6],
                    monto_limite: results.data[i][7],
                    id_courier: results.data[i][8],
                    courier: results.data[i][9],
                    tiempo_entrega: results.data[i][10],
                    tipo_ruta: results.data[i][11],
                    id_localidades: results.data[i][12],
                    estado_mapa: results.data[i][13]

                };
                items.push(mapCourier);
              }
              
            }}
        
        )}
      }*/

     /*const csvFileToArray = string => {
        var array = string.toString().split(" ")
       //  console.log(array); here we are getting the first rows which is our header rows to convert it into keys we are logging it here
         var data = []
         // console.log(data);
         for(const r of array){
           // console.log(r);
             let row = r.toString().split(",")
             data.push(row)
         }
         console.log(data)
         var heading = data[0]
         // console.log(heading); to get the column headers which will act as key
         var ans_array = []
         // console.log(ans_array);
         for(var i=1;i<data.length;i++){
             var row = data[i]
             var obj = {}
             for(var j=0;j<heading.length;j++){
                 if(!row[j]){
                     row[j]="NA";
                 }
                 // console.log(row[j].toString())
                 obj[heading[j].replaceAll(" ","_")] = row[j].toString().replaceAll(" ","_")
             }
             ans_array.push(obj)
         }
         console.log({ans_array})
      };*/

      /*const headers2 = {
        'Content-Type': 'application/json',
        //'Authorization': this.getToken()
    };*/
    
      /*const handleOnSubmit = async(e) => {
        e.preventDefault();

        if (!file) return setError("Enter a valid file");
 
        // Initialize a reader which allows user
        // to read any file or blob.
        const reader = new FileReader();
         
        // Event listener on reader when the file
        // loads, we parse it and set the data.
        reader.onload = async ({ target }) => {
            const csv = Papa.parse(target.result, { header: true });
            const parsedData = csv?.data;
            const columns = Object.keys(parsedData[0]);
            for (let i = 0; i < parsedData.length; i++)
            {
                console.log(parsedData[i].id_pais);
            }
            setData(columns);
            console.log(data);
        };
        reader.readAsText(file);

        console.log(data);

        console.log(values);
        console.log(this.state);
        const data = 
        {
            userId: this.state.userId,
            couriers: items
            
        }
        const headers2 = {
            'Content-Type': 'application/json',
            'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME)
        };
        console.log(data);
        if(items.length > 0)
        {
            
            this.setState({ loading: true, importOK: false });
        axios.post(window.config.REACT_APP_PRODUCTS_API_URL + '/mapcouriercity/insertallcsv', data, {
            headers: headers2
        })
            .then((response) => 
            {
                console.log(response);
                this.setState({ loading: false, resultImport: response.data.data, importOK: true });
            })
            .catch((error) => {
                console.log(error);
                this.setState({ loading: false, importOK: false });
                let msg = values.data.resources.ErrorMessage_UnknownError;
                if (error.response.data.error_code == window.errorCodes.DuplicatedEntityName)
                    msg = values.data.resources.ErrorMessage_DuplicatedName;

                helpers.notifyError(values.data.resources.Label_Error, msg);
                this.setState({ loading: false, resultImport: [], importOK: true});
            });
        }
        else
        {
            helpers.notifyError(values.data.resources.Label_Error, "No se exporto ningun archivo o no tiene items para Importar");
        }
    
        
      };*/

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setClient([]);
        setOpen(false);
    };
  
   const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '50%',
        height: '90%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };
    const headers = {
            'Content-Type': 'application/json',
            'Authorization': this.state.tokenProducts
        };

    // const setClientValues = (client) => {
    //     //console.log(client);
        
            
    //     this.setState({
    //         dataExport: [],
    //         dataResult: []
    //     });
    //     const headers = {
    //         'Content-Type': 'application/json',
    //         'Authorization': this.state.tokenProducts
    //     };
    //     axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getcitiesbystate/'+ client.stateid,
    //     {
    //         'headers': headers
    //     })
    //     .then(response => {
    //         const data = response.data.data;
    //         data.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
    //         this.setState({ cities: data, loading: false });
    //         // order alphabetically
    //         this.state.cities.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name));
            
    //         data.map(city =>{
    //             if(client.stateid == city.state_id && city.id == client.cityid)
    //             {
    //                 this.setState({ 
    //                     postalCode: city.cp
    //                 });
    //             }
    //         })
    //     })
    //     .catch(function (error) {
    //         console.log(error);
    //     });

    //     document.querySelector("#mailError").style.display = 'none';
    //     document.querySelector("#phoneError").style.display = 'none';
    //     document.querySelector("#mobilephoneError").style.display = 'none';
    //     document.querySelector("#nameError").style.display = 'none';
    //     document.querySelector("#lastNameError").style.display = 'none';
    //     document.querySelector("#documentTypeError").style.display = 'none';
    //     document.querySelector("#cityError").style.display = 'none';
    //     document.querySelector("#stateError").style.display = 'none';
    //     document.querySelector("#sexError").style.display = 'none';
    //     document.querySelector("#addressError").style.display = 'none';
    //     document.querySelector("#addressNumberError").style.display = 'none';
    //     document.querySelector("#errorBtnDestination").style.display = 'none';
    //     document.querySelector('#btnInfoDestination').classList.remove('btn-danger');
    //     document.querySelector('#btnInfoDestination').classList.add('btn-primary');
    //     document.querySelector("#errorBtnClient").style.display = 'none';
    //     document.querySelector('#btnInfoClient').classList.remove('btn-danger');
    //     document.querySelector('#btnInfoClient').classList.add('btn-primary');
    //     handleClose();
    // }

    const filterClients = (e) => {
        if(filter !== ''){
            /*setLoading(true);
            axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getclientsfull/' + filter,
            {
                'headers': headers
            })
            .then(response => {
                const data = response.data.data;
                setClient(data);
                setLoading(false);
                if(data.length < 1){
                    setNoMatches(true);
                }else{
                    setNoMatches(false);
                }
            })
            .catch(function (error) {
                console.log(error);
                setLoading(false);
            });*/
        }else{
            setNoMatches(false);
            setSearchError(true);
        }
    }

    const onChangeFilter = (e) => {
        if(e.target.value !== ''){
            setSearchError(false);
            setNoMatches(false);
            setFilter(e.target.value);
        }else{
            setFilter('');
            setNoMatches(false);
        }
    }
    
    const handleEnter = (e) => {
        if(e.target.value !== ''){
            setNoMatches(false);
            setFilter(e.target.value);
            filterClients();
        }else{
            setSearchError(true);
            setNoMatches(false);
        }
    }
    
  return (
    
    <div>
          <button className="btn btn-success" onClick={handleOpen}>
              {this.state.resources.Label_ImportKey}
          </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <Typography id="modal-modal-title" variant="h6" component="h2" className='text-center'>
                <th>{values.data.resources.Label_ImportKey} - {this.state.name}</th>
            </Typography>
            {searchError ? <p style={{color: 'red', fontSize: '0.95rem'}}>{localStorage.getItem('WspLanguage') == 'language0' ? 'Enter a valid value for the search' : 'Ingrese un valor válido para la búsqueda'}</p>: <></>}
            {noMatches && <p style={{color: 'red', fontSize: '0.95rem'}}>{localStorage.getItem('WspLanguage') == 'language0' ? 'No matches found' : 'No se encontraron coincidencias'}</p>}
          </div>
          <hr></hr>
          <div className="dis-flex col-md-12 my-3">
              <div className='col-md-7'>
         <input
             type={"file"}
             className="form-control"
             id={"csvFileInput"}
             accept={".csv"}
             onChange={handleOnChange}
           />
                 </div>
              {this.state.importOK  == 0 ? <div>
              <button className="btn btn-success"
             onClick={(e) => {
               handleOnSubmit(e);
             }}
           >
             Cargar
           </button>
           &nbsp; &nbsp;  
           <button className="btn btn-danger"
             onClick={(e) => {
                this.setState({ loading: false, resultImport: [], importOK: 0 });
                handleClose();
                
             }}
           >  
             Cancelar
           </button>              
           </div> : <></>}
           {this.state.importOK ? <div><button className="btn btn-danger"
             onClick={(e) => {
                this.setState({ loading: false, resultImport: [], importOK: 0 });
                handleClose();
                
             }}
           >  
             Cerrar 
           </button>              
           </div>  : <></>}
          </div>
          {this.state.importOK === 1 ? <div className="text-center" style={{position: 'absolute', top: '50%', left: '50%'}}><CircularProgress size={'3.6rem'}/></div> : <></>}
          {this.state.importOK === 2 || this.state.importOK === 3 ? <div><h4 style={{textAlign: "center"}}>Importacion Realizada con Exito</h4><br/><h4 style={{textAlign: "center"}}>{this.state.mensajeOK}</h4></div> : <></> }
          {this.state.importOK === 4 ? <div><h4 style={{textAlign: "center"}}>Archivo con Errores</h4><br/><h4 style={{textAlign: "center"}}>{this.state.mensajeOK}</h4></div>  : <></> }
            {this.state.importOK === 4 ? 
            <div style={{height: "80%", overflowY: 'auto' }}>
            <>
            
                <table className='table datatable my-table' aria-labelledby="tabelLabel">
                    <thead>
                        <tr>
                        <th>Linea</th>
                        <th>Descripcion</th>
                        </tr>
                    </thead>
                    <tbody>{/**values.data.clients */}
                        {/*this.state.importOK && this.state.resultImport.length == 0 ? <div className="text-center" style={{position: 'absolute', top: '50%', left: '50%'}}><CircularProgress size={'3.6rem'}/></div> :*/ 
                        <>
                        {this.state.resultImport.map(client =>
                            // values.data.documentTypeId == client.document_typeid && values.data.documentNumber == client.document_number ?
                            <tr>
                                <td>{client.linea}</td>
                                <td>{client.detalle}</td>
                                </tr>
                                // : <></>
                        )}</>}
                    </tbody>
                </table>
            </>

        </div>  : <></>}
                  
        </Box>
      </Modal>
    </div>
  );
}
