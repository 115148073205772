import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import DataTable from 'datatables.net';
import helpers from '../../Helpers';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { DataGridPro } from '@mui/x-data-grid-pro';

import { LicenseInfo } from '@mui/x-data-grid-pro';

LicenseInfo.setLicenseKey(
  '97124f3444ae3cf552c73ef2f3487b08T1JERVI6MzEzNDQsRVhQSVJZPTE2NjY1MzkzOTkwMDAsS0VZVkVSU0lPTj0x',
);

export class Logs extends Component {
    static displayName = Logs.name;

    constructor(props) {
        super(props);

        this.onChangeType = this.onChangeType.bind(this);
        this.onChangeFrom = this.onChangeFrom.bind(this);
        this.onChangeTo = this.onChangeTo.bind(this);
        this.onChangeUserId = this.onChangeUserId.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.state = {
            items: [],
            from: null,
            to: null,
            type: 1,
            user_id: -1,
            users: [],
            resources: [],
            loading: true,
            columns: [],
            paging: null,
            sorting: null,
            filtering: null
        };
        
    }

    componentDidMount() {
        this.getResources();
        this.populateData();
        this.setState({from: helpers.formatDate(new Date()), to:helpers.formatDate(new Date()), loading: false});
        helpers.setNavActive("#nav-couriers");
    }

    onChangeType(e) {
        this.setState({
            type: e.target.value
        });
    } 
    onChangeFrom(e) {
        this.setState({
            from: e.target.value
        });
    } 

    onChangeTo(e) {
        this.setState({
            to: e.target.value
        });
    } 
    onChangeUserId(e) {
        this.setState({
            user_id: e.target.value
        });
    } 

    static renderTable(items, resources) {
        return (
            <>
                <table border="0" cellspacing="5" cellpadding="5">

                    <tbody>
                        <div style={{display: 'flex'}}>
                            <tr>
                            <td>Desde:</td>
                            <td><input type="datetime-local" id="min" name="min"/></td>
                            </tr>
                            <tr>
                                <td>Hasta:</td>
                                <td><input type="datetime-local" id="max" name="max"/></td>
                            </tr>
                        </div>
                    </tbody>
                </table>
            <table className='table datatable my-table' aria-labelledby="tabelLabel">
                <thead>
                    <tr>
                        <th>{resources.Label_Date}</th>
                        <th>{resources.Label_User}</th>
                        <th>{resources.Label_Log}</th>
                        <th>{resources.Label_IP}</th>
                    </tr>
                </thead>
                <tbody>
                {
                            items.map(item =>
                                <tr>
                                    <td>{item.date}</td>
                                    <td>{item.user}</td>
                                    <td>{item.log}</td>
                                    <td>{item.ip}</td>
                                </tr>
                            )}
                </tbody>
                </table>
            </>
        );
    }

    render() {
        console.log(this.state.loading);
        let contents = this.state.loading
            ? helpers.showLoadingOverlay()
            : <LogDataGrid data={this.state}/>
            // : Logs.renderTable(this.state.items, this.state.resources);

        return (
            <div className="mt-2">
                <ol className="breadcrumb my-4">
                    <li className="breadcrumb-item active">{this.state.resources.Title_Logs}</li>
                </ol>
                <div className="card">
                    {/* <div className="card-header">
                        <div className="form-group row">
                            <div className="form-group col-md-3" >
                                <label className=" form-label">{this.state.resources.Label_LogType}</label>
                                <select className="form-control" data-val="true" name="type" defaultValue={this.state.type} onChange={this.onChangeType} required>
                                    <option value="1">Todos</option>
                                    <option value="2">Log Inicio de Sesión</option>
                                    <option value="3">Log Cierre de Sesión/Expiración</option>
                                    <option value="4">Log intentos fallidos</option>
                                </select>
                            </div>
                            <div className="form-group col-md-2" >
                                <label className=" form-label">{this.state.resources.Label_From}</label>
                                <input className="form-control" type="date" name="from" onChange={this.onChangeFrom} defaultValue={this.state.from} />
                            </div>
                            <div className="form-group col-md-2" >
                                <label className=" form-label">{this.state.resources.Label_To}</label>
                                <input className="form-control" type="date" name="to" onChange={this.onChangeTo} defaultValue={this.state.to} />
                            </div>
                            <div className="form-group col-md-3">
                                <label className=" form-label">{this.state.resources.Label_User}</label>
                                <select className="form-control" data-val="true" name="user_id" defaultValue={this.state.user_id} onChange={this.onChangeUserId}>
                                    <option value="-1">{this.state.resources.Title_SelectUser}</option>
                                    {this.state.users.map(area =>
                                <option key={area.id} value={area.id}>{area.first_name} {area.last_name}</option>
                            )}
                                </select>
                            </div>
                            <div className="form-group col-md-2" >
                                <br/>
                            <button className="btn btn-primary" onClick={this.onSearch}><i className="fa fa-search"></i></button>
                            </div>
                        </div>
                    </div> */}
                    <div className="card-body">
                        <div className="table-responsive">
                            {contents}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    async onSearch(e) {
        e.preventDefault();
        this.setState({ loading: true });
        const data = {
            tipo: this.state.type,
            user_id: this.state.user_id,
            desde: new Date(this.state.from),
            hasta: new Date(this.state.to)
        };
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_SECURITY_API_NAME)
        };
        console.log(data);
        axios.get(window.config.REACT_APP_SECURITY_API_URL + '/logs/getall', data,
        {
            //headers: headers
            'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_SECURITY_API_NAME) }
        })
        .then(response => {
            const data = response.data.data;
            console.log(data);
            this.setState({ items: data, loading: false });    
            helpers.hideLoadingOverlay();
        })
        .catch(function (error) {
            console.log(error);
            helpers.hideLoadingOverlay();
        });
    }  

    async populateData() {


        axios.get(window.config.REACT_APP_SECURITY_API_URL + '/logs/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_SECURITY_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                const rows = data.map((row) => {
                    // console.log(data.indexOf(row))
                    const { DT_RowId, ...rest } = row;
                    return { id: data.indexOf(row)+1, ...rest };
                });
                // console.log(rows)
                this.setState({ items: rows, loading: false });
                this.state.columns = [
                    {
                        field: 'id',
                        headerName: 'ID',
                        type: 'number',
                        width: 105,
                        headerAling: "center",
                        headerClassName: "header-table-mui"
                    },
                    {
                        field: 'date',
                        headerName: this.state.resources.Label_Date ? this.state.resources.Label_Date : 'Date',
                        flex: 1,
                        minWidth: 160,
                        headerAling: "center",
                        headerClassName: "header-table-mui",
                        type: 'date',
                        renderCell: (item) => {
                            // console.log(item)
                            return (
                                <div className="text-center">
                                    {new Date(item.row.fecha).getDate()+'/'+(new Date(item.row.fecha).getUTCMonth()+1)+'/'+new Date(item.row.fecha).getFullYear() + ' ' + (new Date(item.row.fecha).getHours() - (new Date(item.row.fecha).getTimezoneOffset() / 60))+ ':' + new Date(item.row.fecha).getMinutes()}
                                    {/* {item.formattedValue} */}
                                </div>
                            );
                        },
                        valueGetter: (item)=>item.row.fecha
                    },
                    {
                        field: 'user',
                        headerName: this.state.resources.Label_User ? this.state.resources.Label_User : 'User',
                        flex: 1,
                        minWidth: 180,
                        headerAling: "center",
                        headerClassName: "header-table-mui"
                    },
                    {
                        field: 'log',
                        headerName: this.state.resources.Label_Log ? this.state.resources.Label_Log : 'Log',
                        flex: 1,
                        minWidth: 180,
                        headerAling: "center",
                        headerClassName: "header-table-mui"
                    },
                    {
                        field: 'ip',
                        headerName: 'IP',
                        flex: 1,
                        minWidth: 180,
                        headerAling: "center",
                        headerClassName: "header-table-mui"
                    }
                    
                ];
                
                // $.fn.dataTable.ext.errMode = 'throw';
                // // $(".datatable").dataTable().fnDestroy();
                // let table = $('.datatable').DataTable({
                //     "retrieve": true,
                //     "language": {
                //         "lengthMenu": this.state.resources.Datatables_language_lengthMenu,
                //         "zeroRecords": this.state.resources.Datatables_language_zeroRecords,
                //         "info": this.state.resources.Datatables_language_info,
                //         "infoEmpty": this.state.resources.Datatables_language_infoEmpty,
                //         "infoFiltered": this.state.resources.Datatables_language_infoFiltered,
                //         "search": this.state.resources.Datatables_language_search,
                //         "paginate": {
                //             "previous": this.state.resources.Datatables_language_paginate_previous,
                //             "next": this.state.resources.Datatables_language_paginate_next
                //         },
                //         "autoWidth": false
                //     },
                //     stateSave: true,
                //     stateSaveCallback: function (settings, data) {
                //         localStorage.setItem('DataTables_b', JSON.stringify(data))
                //         },
                //     stateLoadCallback: function(settings) {
                //         return JSON.parse(localStorage.getItem( 'DataTables_b'))
                //     }
                    
                // });
                //  $('.datatable thead tr').clone(true).appendTo( '.datatable thead' );
                // $('.datatable thead tr:eq(1) th').each(function (i) {
                //     var title = $(this).text();
                //     $(this).html('<input type="text" placeholder="Search ' + title + '" />');
            
                //     $('input', this).on('keyup change', function () {
                //         if (table.column(i).search() !== this.value) {
                //             table
                //                 .column(i)
                //                 .search(this.value)
                //                 .draw();
                //         }
                //     });
                // });
                
                // /**/
                // let minDate = $('#min'), maxDate = $('#max');
                
                
                // // Refilter the table
                // $('#min, #max').on('change', function () {
                //     // Custom filtering function which will search data in column four between two values
                //     $.fn.dataTable.ext.search.push(
                //         function( settings, data, dataIndex ) {
                //             let min = minDate.val().slice(0, 10).split('-').reverse().join('/'); //
                //             let max = maxDate.val().slice(0, 10).split('-').reverse().join('/');
                //             let date = data[0].split('-').reverse().join('/').slice(0, 10);
                //             console.log(date);
                //             console.log(min);
                //             console.log(max);
                //             if (
                //                 ( min === null && max === null ) ||
                //                 ( min === null && date <= max ) ||
                //                 ( min <= date   && max === null ) ||
                //                 ( min <= date   && date <= max )
                //             ) {
                //                 return true;
                //             }
                //             return false;
                //         }
                //     );
                //     table.draw();
                // });
                helpers.hideLoadingOverlay();
            })
            .catch(function (error) {
                console.log(error);
                helpers.hideLoadingOverlay();
            });
    }

    async getResources() {
        const resourceVars = [];
        resourceVars.push("Title_Logs");
        resourceVars.push("Label_ID");
        resourceVars.push("Label_Logs");
        resourceVars.push("Label_Description");
        resourceVars.push("Label_Actions");
        resourceVars.push("Label_Status");
        resourceVars.push("Label_Enabled");
        resourceVars.push("Label_Disabled");
        resourceVars.push("ButtonLabel_ViewEdit");
        resourceVars.push("ButtonLabel_New");
        resourceVars.push("Datatables_language_lengthMenu");
        resourceVars.push("Datatables_language_zeroRecords");
        resourceVars.push("Datatables_language_info");
        resourceVars.push("Datatables_language_infoEmpty");
        resourceVars.push("Datatables_language_infoFiltered");
        resourceVars.push("Datatables_language_search");
        resourceVars.push("Datatables_language_paginate_previous");
        resourceVars.push("Datatables_language_paginate_next");
        resourceVars.push("Label_LogType");
        resourceVars.push("Label_From");
        resourceVars.push("Label_To");
        resourceVars.push("Label_Date");
        resourceVars.push("Label_Log");
        resourceVars.push("Label_IP");
        resourceVars.push("Label_User");
        resourceVars.push("Title_SelectUser");
        resourceVars.push("Label_FilterOff");
        resourceVars.push("Label_Filter");
        resourceVars.push("Label_Sort");
        resourceVars.push("Label_Hide");
        resourceVars.push("Label_ShowColumns");
        resourceVars.push("Label_OrderAsc");
        resourceVars.push("Label_OrderDesc");
        resourceVars.push("Label_Unsorting");
        resourceVars.push("Label_Columns");
        resourceVars.push("Label_Delete");
        resourceVars.push("Label_GridValue");
        resourceVars.push("Label_ValueFilter");
        resourceVars.push("Label_And");
        resourceVars.push("Label_Or");
        resourceVars.push("Label_Operator");
        resourceVars.push("Label_NoRows");
        resourceVars.push("Label_SelectColumns");
        resourceVars.push("Label_OperatorAfter");
        resourceVars.push("Label_OperatorBefore");
        resourceVars.push("Label_OperatorContains");
        resourceVars.push("Label_OperatorEnds");
        resourceVars.push("Label_OperatorEquals");
        resourceVars.push("Label_OperatorIs");
        resourceVars.push("Label_OperatorIsEmpty");
        resourceVars.push("Label_OperatorIsNotEmpty");
        resourceVars.push("Label_OperatorIsNot");
        resourceVars.push("Label_OperatorIsOnOrAfter");
        resourceVars.push("Label_OperatorIsOnOrBefore");
        resourceVars.push("Label_OperatorStarts");
        resourceVars.push("Label_ReorderColumns");
        resourceVars.push("Label_HideAll");
        resourceVars.push("Label_ShowAll");
        resourceVars.push("Label_FindColumn");
        resourceVars.push("Label_TitleColumn");
        resourceVars.push("Label_addFilter");

        const headers = {
            'Content-Type': 'application/json'
        };

        axios.post(window.config.REACT_APP_SECURITY_API_URL + '/languageresource/getbyvars/' + helpers.getUiLanguage(), resourceVars, {
            headers: headers
        })
            .then((response) => {
                const data = response.data.data;
                this.setState({ resources: data, loading: false });
                // console.log(this.state);
            })
            .catch((error) => {
                console.log(error);
            });

            let authTokenSecurity = await helpers.getAuthorizationToken(process.env.REACT_APP_SECURITY_API_NAME);
            // get users
            axios.get(window.config.REACT_APP_SECURITY_API_URL + '/user/getalllight',
                {
                    'headers': { 'Authorization': authTokenSecurity }
                })
                .then(response => {
                    console.log(response);
                    const data = response.data.data;
                    this.setState({ users: data, loading: false });
                })
                .catch(function (error) {
                    console.log(error);
                });
    }
}

export default function LogDataGrid(values) {

    let items = values.data.items,
        resources = values.data.resources,
        permissionsIds = values.data.permissionsIds, columns = values.data.columns,
        sorting = localStorage.getItem('CopyLogListSorting') ?
            JSON.parse(localStorage.getItem('CopyLogListSorting')) : values.data.sorting,
        paging = values.data.paging ? values.data.paging : 0
        , filter = localStorage.getItem('CopyLogListFiltering') ?
            JSON.parse(localStorage.getItem('CopyLogListFiltering')) : values.data.filtering
    
    const [page, setPage] = React.useState(paging.page);
    const [pageSize, setPageSize] = React.useState(paging.pageSize);
    
    const [value, setValue] = React.useState([null, null]);
    const [myRows, setRowsValue] = React.useState(values.data.items);
    const [mySortModel, setSortModel] = React.useState([]);
    const [filtering, setFilter] = React.useState(filter);
    const [status, onChangeStatus] = React.useState(true);
    const [open, setOpen] = React.useState(false);
    const openModal = () => setOpen(true);
    const closeModal = () => setOpen(false);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    function setInitial() {
        setTimeout(() => {
            if (document.getElementsByClassName('MuiTablePagination-selectLabel')[0]){
                document.getElementsByClassName('MuiTablePagination-selectLabel')[0].innerHTML = '';
            }
            // loading = true
            if (myRows.length < 1) {
                if (localStorage.getItem('LogFilterItems')) {
                    let myItems = JSON.parse(localStorage.getItem('LogFilterItems'));
                    setRowsValue(myItems);
                    setTimeout(() => {
                        localStorage.removeItem('LogFilterItems');
                    }, 1000);
                } else {
                    setRowsValue(items)
                }   
            }
            // if (sorting) {
            //     if (sorting.sortModel) {
            //         setSortModel(sorting.sortModel)
            //     }
            // }
        }, 1000)
       
    }
    setInitial()

    const handlePageChange = (newPage) => {
        setPage(newPage);
    };

    const handlePageSizeChange = (newPage) => {
        setPageSize(newPage);
    };

    const handleFilterChange = (newFilter) => {
        // console.log(newFilter)
        setFilter(newFilter);
    }

    const handleSortingChange = (sort) => {
        setSortModel(sort);
        localStorage.removeItem('LogListSorting');
        localStorage.removeItem('CopyLogListSorting');
        localStorage.setItem('CopyLogListSorting', JSON.stringify(sort));
    }

    const handleStatus = (e) => {
        e.preventDefault();
        onChangeStatus(e.target.value);
    }

    // const onReloadData =
    async function onReloadData() {
        // console.log(status)
        
        if (status === true || status === 'true') {
            helpers.showLoadingOverlay();
            axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/log/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                // console.log(response.data.data)
                let newItems = response.data.data;
                setRowsValue(newItems);
                localStorage.setItem('LogFilterItems', JSON.stringify(newItems));
                closeModal();
                helpers.hideLoadingOverlay();
             })
            .catch(err => console.log(err));
        } else if (status != true){
            helpers.showLoadingOverlay();
            axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/log/getdisabled',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                // console.log(response.data.data)
                let newItems = response.data.data;
                setRowsValue(newItems);
                localStorage.setItem('LogFilterItems', JSON.stringify(newItems));
                closeModal();
                helpers.hideLoadingOverlay();
             })
            .catch(err => console.log(err));
        }
    }

    async function resetFilter() {
        helpers.showLoadingOverlay();
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/log/getall',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                let newItems = response.data.data;
                setRowsValue(newItems);
                localStorage.removeItem('LogFilterItems');
                closeModal();
                helpers.hideLoadingOverlay();
             })
            .catch(error => console.log(error));
    }

    return (
        <>
        {/* <button className="btn btn-success mb-1" onClick={openModal}><i class="fas fa-filter"></i></button> */}
        <Modal
            open={open}
            onClose={closeModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <div className="dis-flex m-1" style={{justifyContent: 'space-beetween'}}>
                    <div>
                        <label className="label-text">{values.data.resources.Label_Status}</label>
                        <select className="form-control" data-val="true" name="productId" value={status} onChange={handleStatus}>
                            <option value="true">{values.data.resources.Label_Enabled}</option>
                            <option value="false">{values.data.resources.Label_Disabled}</option>
                        </select>
                        </div>
                        <div className="mt-4">
                            <button className="btn btn-primary mx-1 my-auto" onClick={onReloadData}>{values.data.resources.Label_Filter}</button>
                            <button className="btn btn-danger mx-1 my-auto" onClick={resetFilter}>{values.data.resources.Label_FilterOff}</button>
                        </div>
                </div>
            </Box>
        </Modal>
            <div style={{ height: 450, width: '99%' }}>
                <DataGridPro rows={myRows} columns={columns} rowsPerPageOptions={[5, 10, 25, 50, 100]} density='compact'
                pagination
                page={page}
                localeText={{
                    'columnMenuFilter': values.data.resources.Label_Filter,
                    'columnHeaderSortIconLabel': values.data.resources.Label_Sort,
                    'columnMenuHideColumn': values.data.resources.Label_Hide,
                    'columnMenuShowColumns': values.data.resources.Label_ShowColumns,
                    'columnMenuSortAsc': values.data.resources.Label_OrderAsc,
                    'columnMenuSortDesc': values.data.resources.Label_OrderDesc,
                    'columnMenuUnsort': values.data.resources.Label_Unsorting,
                    'filterPanelColumns': values.data.resources.Label_Columns,
                    'filterPanelDeleteIconLabel': values.data.resources.Label_Delete,
                    'filterPanelInputLabel': values.data.resources.Label_GridValue,
                    'filterPanelInputPlaceholder': values.data.resources.Label_ValueFilter,
                    'filterPanelOperatorAnd': values.data.resources.Label_And,
                    'filterPanelOperatorOr': values.data.resources.Label_Or,
                    'filterPanelOperators': values.data.resources.Label_Operator,
                    'noResultsOverlayLabel': values.data.resources.Label_NoRows,
                    // 'noRowsLabel': values.data.resources.Label_NoRows,
                    'noRowsLabel': '',
                    'toolbarColumns': values.data.resources.Label_Columns,
                    'toolbarColumnsLabel': values.data.resources.Label_SelectColumns,
                    'errorOverlayDefaultLabel': "Error",
                    'filterOperatorAfter': values.data.resources.Label_OperatorAfter,
                    'filterOperatorBefore': values.data.resources.Label_OperatorBefore,
                    'filterOperatorContains': values.data.resources.Label_OperatorContains,
                    'filterOperatorEndsWith': values.data.resources.Label_OperatorEnds,
                    'filterOperatorEquals': values.data.resources.Label_OperatorEquals,
                    'filterOperatorIs': values.data.resources.Label_OperatorIs,
                    'filterOperatorIsEmpty': values.data.resources.Label_OperatorIsEmpty,
                    'filterOperatorIsNotEmpty': values.data.resources.Label_OperatorIsNotEmpty,
                    'filterOperatorNot': values.data.resources.Label_NoRows,
                    'filterOperatorOnOrAfter': values.data.resources.Label_OperatorIsOnOrAfter,
                    'filterOperatorOnOrBefore': values.data.resources.Label_OperatorIsOnOrBefore,
                     'filterOperatorStartsWith': values.data.resources.Label_OperatorStarts,
                    'columnsPanelDragIconLabel': values.data.resources.Label_ReorderColumns,
                    'columnsPanelHideAllButton': values.data.resources.Label_HideAll,
                    'columnsPanelShowAllButton': values.data.resources.Label_ShowAll,
                    'columnsPanelTextFieldLabel': values.data.resources.Label_FindColumn,
                    'columnsPanelTextFieldPlaceholder': values.data.resources.Label_TitleColumn,
                    'filterPanelAddFilter': values.data.resources.Label_addFilter,
                }}    
                pageSize={pageSize}
                // density='compact'
                // onStateChange={(state) => {
                //     // console.log(state)
                //     localStorage.setItem('LogListPagination', JSON.stringify(state.pagination))
                //     localStorage.setItem('LogListSorting', JSON.stringify(state.sorting))
                //     localStorage.setItem('LogListFiltering', JSON.stringify(state.filter))
                // }}
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
                sortModel={mySortModel}
                onSortModelChange={handleSortingChange}
                state={
                        filter != null ? {filter: filter} : {}
                        
                }
                      
            />
        </div>
    </>
    );
}
