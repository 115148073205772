import React from 'react'
import { Component } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { ApplicationPaths, QueryParameterNames } from './ApiAuthorizationConstants'
import authService from './AuthorizeService'
import Home from '../Home'
import helpers from '../../Helpers'

export default class PublicRoute extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ready: false,
            authenticated: false
        };
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.authenticationChanged());
        this.populateAuthenticationState();
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    render() {
        const { ready, authenticated } = this.state;
        console.log("OKOK");
        var link = document.createElement("a");
        link.href = this.props.path;
        const returnUrl = `${link.protocol}//${link.host}${link.pathname}${link.search}${link.hash}`;
        const redirectUrl = `${ApplicationPaths.Login}?${QueryParameterNames.ReturnUrl}=${encodeURI(returnUrl)}`
        
        const { component: Component, ...rest } = this.props;
        
            return <Route {...rest}
                render={(props) => {
                    console.log(props)
                    if (authenticated) {

                        let permissionId = this.props.permission;
                        let userData = helpers.getUserData();
                        
                        // if (userData === null) {
                        //     window.location.href =  "/login";
                        // } else if (permissionId && userData.permission_ids.indexOf(parseInt(permissionId)) === -1) {
                        //     let langResources = helpers.getLanguageResources();

                        //     if (langResources)
                        //         helpers.notifyWarning(langResources.Label_Unauthorized, langResources.WarningMessage_Unauthorized);

                        //     return <Home />;
                        // }

                        return <Component {...props} />
                    } else {
                        return <Component {...props} />
                    }
                }} />
    }

    async populateAuthenticationState() {
        console.log("OKOK2");

        const authenticated = await authService.isAuthenticated();
        this.setState({ ready: true, authenticated: authenticated });
    }

    async authenticationChanged() {
        console.log("OKOK3");

        this.setState({ ready: false, authenticated: false });
        await this.populateAuthenticationState();
    }
}
