import React, { Component } from 'react';
import axios from 'axios';
import helpers from '../../Helpers';

import DataCreation from '../DataCreation/DataCreation';

export class ProductPaymentWayPayDetail extends Component {
    static displayName = ProductPaymentWayPayDetail.name;

    constructor(props) {
        super(props);

        this.onChangePayment = this.onChangePayment.bind(this);
        this.onChangeWayPay = this.onChangeWayPay.bind(this);
        this.onChangeEnabled = this.onChangeEnabled.bind(this);
        this.onChangeCarStateId = this.onChangeCarStateId.bind(this);
        this.onChangePaymentCarStateId = this.onChangePaymentCarStateId.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onSubmit = this.onSubmit.bind(this);  

        this.state = {
            id: this.props.match.params.id,
            paymentId: 0,
            payments: [],
            wayPayId: 0,
            waypays: [],
            carStates: [],
            carStateId: 0,
            paymentCarStates: [],
            paymentCarStateId: 0,
            enabled: true,
            loading: true,
            resources: [],
            userId: 0,
            viewMode: false,
            productsPayments: [],
        };
    }

    componentDidMount() {
        this.getResources();
        this.populateData();
        helpers.setNavActive("#nav-subareas");

        let url = window.location.href;
        if (url.includes('view')) {
            this.setState({
                viewMode: true
            });
        }

        let currentUser = helpers.getUserData();
        this.setState({
            userId: currentUser.id
        });

        let sort = JSON.parse(localStorage.getItem('ProductPaymentWayPayListSorting'))
        localStorage.setItem('CopyProductPaymentWayPayListSorting', JSON.stringify(sort));

        let paging = JSON.parse(localStorage.getItem('ProductPaymentWayPayListPagination'))
        localStorage.setItem('CopyProductPaymentWayPayListPaging', JSON.stringify(paging));

        let filtering = JSON.parse(localStorage.getItem('ProductPaymentWayPayListFiltering'))
        localStorage.setItem('CopyProductPaymentWayPayListFiltering', JSON.stringify(filtering));
    }

    async onSubmit(e) {
        e.preventDefault();
        this.setState({ loading: true });

        const data = {
            id: this.state.id,
            waypay_id: this.state.wayPayId,
            productpayment_id: this.state.paymentId,
            initial_shoppingcar_state_id: this.state.carStateId,
            initial_payment_state_id: this.state.paymentCarStateId,
            enabled: this.state.enabled,
            lastupdate_user_id: this.state.userId,
        };
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME)
        };

         let errorCount = 0;
        this.state.productsPayments.map(productPayment => {
           
            if (productPayment.waypay_id == data.waypay_id && productPayment.productpayment_id == data.productpayment_id && productPayment.id != data.id) {
                console.log(productPayment);
                console.log(data.id);
                let msg = this.state.resources.Label_ErrorDuplicated;
                helpers.notifyError(this.state.resources.Label_Error, msg);
                errorCount++;
                this.setState({ loading: false });
                return;
            } 
        });
        if (errorCount == 0) {
            axios.post(window.config.REACT_APP_PRODUCTS_API_URL + '/productpaymentwaypay/save', data, {
                headers: headers
            })
            .then((response) => {
                helpers.notifySuccess(this.state.resources.Label_Success, this.state.resources.SuccessMessage_DataSavedSuccessfully);
                this.props.history.push('/product-payment-waypay');
            })
            .catch((error) => {
                console.log(error);

                let msg = this.state.resources.ErrorMessage_UnknownError;
                if (error.response.data.error_code == window.errorCodes.DuplicatedEntity)
                    msg = this.state.resources.ErrorMessage_DuplicatedName;

                helpers.notifyError(this.state.resources.Label_Error, msg);
                this.setState({ loading: false });
            });
        }

        
    }  

    onCancel(e) {
        e.preventDefault();
        this.props.history.push("/product-payment-waypay");
    }
    onChangePayment(e) {
        this.setState({
            paymentId: e.target.value
        });
    }  
    onChangeWayPay(e) {
        this.setState({
            wayPayId: e.target.value
        });
    }  
    onChangeEnabled(e) {
        this.setState({
            enabled: e.target.checked
        });
    } 

    onChangeCarStateId(e) {
        this.setState({
            carStateId: e.target.value
        });
    }

    onChangePaymentCarStateId(e) {
        this.setState({
            paymentCarStateId: e.target.value
        });
    }

    render() {
        let contents = this.state.loading
            ? helpers.showLoadingOverlay()
            :
            <form className="form row g-3 needs-validation" onSubmit={this.onSubmit}>
                <div className="form-group col-md-12">
                    <div className="form-check form-switch">
                        <input className="form-check-input" disabled={this.state.viewMode} type="checkbox" id="enabled" checked={this.state.enabled} onChange={this.onChangeEnabled} />
                        <label className="form-check-label" htmlFor="enabled">{this.state.resources.Label_Enabled}</label>
                        <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                    </div>
                </div>
                <div className="form-group col-md-6">
                    <label className=" form-label">{this.state.resources.Label_ProductPayment}</label>
                    <select className="form-control" disabled={this.state.viewMode} data-val="true" name="paymentId" value={this.state.paymentId} onChange={this.onChangePayment} required>
                        <option value="">{this.state.resources.Label_SelectOption}</option>
                        {this.state.payments.map(payment =>
                            <option key={payment.id} value={payment.id}>{payment.description}</option>
                        )}
                    </select>  
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                </div>
                
                <div className="form-group col-md-6">
                    <label className=" form-label">{this.state.resources.Title_WayPay}</label>
                    <select className="form-control" disabled={this.state.viewMode} data-val="true" name="waypayId" value={this.state.wayPayId} onChange={this.onChangeWayPay} required>
                        <option value="">{this.state.resources.Label_SelectOption}</option>
                        {this.state.waypays.map(waypay =>
                            <option key={waypay.id} value={waypay.id}>{waypay.waypay}</option>
                        )}
                    </select>  
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                </div>

                <div className="form-group col-md-6">
                    <label className=" form-label">{this.state.resources.Label_InitialShoppingCarState}</label>
                    <select className="form-control" disabled={this.state.viewMode} data-val="true" name="area" value={this.state.carStateId} onChange={this.onChangeCarStateId} required>
                        <option value="">{this.state.resources.Label_SelectOption}</option>
                        {this.state.carStates.map(carState =>
                            <option key={carState.id} value={carState.id}>{carState.carstate}</option>
                        )}
                    </select>  
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                </div>

                <div className="form-group col-md-6">
                    <label className=" form-label">{this.state.resources.Label_InitialPaymentShoppingCarState}</label>
                    <select className="form-control" disabled={this.state.viewMode} data-val="true" name="area" value={this.state.paymentCarStateId} onChange={this.onChangePaymentCarStateId} required>
                        <option value="">{this.state.resources.Label_SelectOption}</option>
                        {this.state.paymentCarStates.map(payment =>
                            <option key={payment.id} value={payment.id}>{payment.paymentstate}</option>
                        )}
                    </select>  
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                </div>

                <div className=" form-group col-md-6">
                     <DataCreation resources={this.state}></DataCreation>
                </div>

                {/**Buttons Header */}
                <div className="buttons-head row">
                    {this.state.viewMode == false ?
                    <div className=" buttons-childs">
                        <button type="submit" className="btn btn-primary">{this.state.resources.ButtonLabel_Save}</button>
                        <button className="btn btn-danger" onClick={this.onCancel}>{this.state.resources.ButtonLabel_Cancel}</button>
                    </div>
                    :
                    <button className="btn btn-danger btn-block" onClick={this.onCancel}>{this.state.resources.Label_GoBack}</button>
                    }
                 </div>
            </form>
            helpers.hideLoadingOverlay();

        return (
            <div className="mt-2">
                <ol className="breadcrumb my-4">
                    <li className="breadcrumb-item active">{this.state.resources.Label_ProductPaymentWayPay}</li>
                </ol>
                <div className="card">
                    <div className="card-body">
                        {contents}
                    </div>
                </div>
            </div>
        );
    }

    async getResources() {
        const resourceVars = [];
        resourceVars.push("Label_ProductPaymentWayPay");
        resourceVars.push("Label_SelectOption");
        resourceVars.push("Label_Area");
        resourceVars.push("Label_Name");
        resourceVars.push("Label_Description");
        resourceVars.push("Label_Enabled");
        resourceVars.push("Label_Success");
        resourceVars.push("ButtonLabel_Save");
        resourceVars.push("ButtonLabel_Cancel");
        resourceVars.push("Label_Error");
        resourceVars.push("ErrorMessage_RequiredField");
        resourceVars.push("ErrorMessage_DuplicatedName");
        resourceVars.push("ErrorMessage_UnknownError");
        resourceVars.push("SuccessMessage_DataSavedSuccessfully");
        resourceVars.push("Label_InitialShoppingCarState");
        resourceVars.push("Label_InitialPaymentShoppingCarState");
        resourceVars.push("Label_ProductPayment");
        resourceVars.push("Title_WayPay");
        resourceVars.push("Label_GoBack");
        resourceVars.push("Label_Creation");
        resourceVars.push("Label_CreationUser");
        resourceVars.push("Label_LastUpdate");
        resourceVars.push("Label_LastUpdateUser");
        resourceVars.push("Label_ErrorDuplicated");

        const headers = {
            'Content-Type': 'application/json'
        };

        axios.post(window.config.REACT_APP_SECURITY_API_URL + '/languageresource/getbyvars/' + helpers.getUiLanguage(), resourceVars, {
            headers: headers
        })
        .then((response) => {
            const data = response.data.data;
            this.setState({ resources: data, loading: false });
        })
        .catch((error) => {
            console.log(error);
            this.setState({ loading: false });
        });

        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/productpaymentwaypay/get/',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ productsPayments: data, loading: false })
            })
            .catch(error => {
                console.log(error);
            });
    }

    async populateData() {
        this.setState({
            loading: true
        });

        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/productpaymentwaypay/getbyid/' + this.state.id,
        {
            'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
        })
        .then(response => {
            // console.log(response.data.data)
            let data = response.data.data;
            this.setState({
                paymentId: data.productpayment_id,
                wayPayId: data.waypay_id,
                carStateId: data.initial_shoppingcar_state_id,
                paymentCarStateId: data.initial_payment_state_id,
                enabled: data.enabled,
                creation: helpers.getDisplayDateTimeFromUtc(data.creation),
                creationUser: data.creation_user_name,
                lastUpdate: data.last_update == null ? '' : helpers.getDisplayDateTimeFromUtc(data.last_update),
                lastUpdateUser: data.last_update_user_name == null ? '' : data.last_update_user_name,
                loading: false
            });
            helpers.hideLoadingOverlay();
        })
        .catch(function (error) {
            console.log(error);
            helpers.hideLoadingOverlay();
        });
        
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/waypay/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ waypays: data, loading: false });
                // order alphabetically
                this.state.waypays.sort((a,b) => a.waypay < b.waypay ? -1 : +(a.waypay > b.waypay))
                // console.log(this.state.waypays);
            })
            .catch(function (error) {
                console.log(error);
            });
        
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/productpayment/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ payments: data, loading: false });
                // order alphabetically
                this.state.payments.sort((a,b) => a.description < b.description ? -1 : +(a.description > b.description))
                // console.log(this.state.payments);
            })
            .catch(function (error) {
                console.log(error);
            });
        
        
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getcarstates',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ carStates: data, loading: false });
                // order alphabetically
                this.state.carStates.sort((a,b) => a.carstate < b.carstate ? -1 : +(a.carstate > b.carstate))
                // console.log(this.state.carStates);
            })
            .catch(function (error) {
                console.log(error);
            });
        
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getpaymentstates',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ paymentCarStates: data, loading: false });
                // order alphabetically
                this.state.paymentCarStates.sort((a,b) => a.paymentstate < b.paymentstate ? -1 : +(a.paymentstate > b.paymentstate))
                // console.log(this.state.paymentCarStates);
            })
            .catch(function (error) {
                console.log(error);
            });
    }
}