import React, { PureComponent } from "react";
import Highcharts from "highcharts";
import axios from 'axios';
import helpers from '../../Helpers';

export default class ChartComponent extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
        instance: null,
        options: null,
        options2: null,
        dateBegin: "",
        dateEnd: ""
    }
  }

  componentDidMount() {
    this.state.options = {
        chart: {
          type: 'column'
        },
        title: {
          text: '' 
        },
        xAxis: {
            title: {
                text: localStorage.getItem('uilanguage') == 'language0' ? 'Hours' : "Horas" 
            },
            min: 0,
            max: 24
        },
        yAxis: {
            title: {
                text: localStorage.getItem('uilanguage') == 'language0' ? 'Sales' : "Ventas" 
            }
    
        },
        series: [
          { 
            name: ' ',
            data: []
          }
        ]
      };

      this.state.options2 = {
        chart: {
          type: 'column'
        },
        title: {
          text: ''
        },
        xAxis: {
            title: {
                text: localStorage.getItem('uilanguage') == 'language0' ? 'Hours' : "Horas"
            },
            min: 0,
            max: 24
        },
        yAxis: {
            title: {
                text: localStorage.getItem('uilanguage') == 'language0' ? 'Sales' : "Ventas" 
            }
    
        },
        series: [
          {
            name: ' ',
            data: [],
            
          },
          {
            name: ' ',
            data: []
          },
          {
            name: ' ',
            data: []
          },
          {
            name: ' ',
            data: []
          },
          {
            name: ' ',
            data: []
          },
          {
            name: ' ',
            data: []
          }
        ]
      };
    if(this.props.chartTo == 'salesHour'){
        this.state.instance = Highcharts.chart("dummy-id", this.state.options);
    }else if(this.props.chartTo == 'salesArea'){
        this.state.instance = Highcharts.chart("dummy-id2", this.state.options2);
    }
    
  }

   onViewDate(items){
    //console.log(items)
    this.state.dateEnd =  items.fechaFinal;
    this.state.dateBegin =  items.fechaInicio;
    //console.log(this.state.dateEnd)
    //console.log(this.state.dateBegin)
    
  }

  onUpdateItems(items){
    let arrayitems = [];
    let arrayitems2 = [];
    let categoriesArr = [];

    if(items.length > 0){
        if(this.props.chartTo == 'salesHour'){
          let inicio = "";
          let final = "";
            inicio = this.state.dateBegin;
            final = this.state.dateEnd
             this.state.options.title.text = 'Ventas por hora entre  '+ inicio + '  y  ' + final ;
             
            items.map(x => {
                let newContent = {
                    y: this.convertNumber(x.total),
                    x: this.parseHour(x.creation_date)
                }
                arrayitems.push(newContent);
            });
            // console.log(arrayitems);
            this.state.options.series[0].data = arrayitems;
            this.state.options.series[0].name = "Ventas por Hora";
            this.state.instance = Highcharts.chart("dummy-id", this.state.options);
        }else if(this.props.chartTo == 'salesArea'){

          let inicio = "";
          let final = "";
            inicio = this.state.dateBegin;
            final = this.state.dateEnd

             this.state.options2.title.text = 'Ventas por subárea por hora entre  '+ inicio + '  y  ' + final ;

            items.map(x => {
                if(!categoriesArr.includes(x.sub_area)){
                  categoriesArr.push(x.sub_area);
                  }
            
                //console.log(x);
                let newContent = {
                    y: this.convertNumber(x.total),
                    x: this.parseHour(x.creation_date),
                    name: x.sub_area
                }
                arrayitems2.push(newContent);

                
            });
            //  console.log(categoriesArr);
            // console.log(arrayitems2);
           
            let arrayAux = [];
            let arrayAux2 = [];
            let arrayAux3 = [];
            let arrayAux4 = [];
            let arrayAux5 = [];
            let arrayAux6 = [];
            arrayitems2.map(info => {
              if(info.name == categoriesArr[0]){
                let newContent = {
                  y: info.y,
                  x: info.x,
                  name: info.name
                }
                  arrayAux.push(newContent);
                }else  if(info.name == categoriesArr[1]){
                  let newContentss = {
                    y: info.y,
                    x: info.x,
                    name: info.name
                }
                  arrayAux2.push(newContentss);
                } else if(info.name == categoriesArr[2]){
                  let newContents = {
                    y: info.y,
                    x: info.x,
                    name: info.name
                }
                  arrayAux3.push(newContents);
                } else if(info.name == categoriesArr[3]){
                  let newContents = {
                    y: info.y,
                    x: info.x,
                    name: info.name
                }
                  arrayAux4.push(newContents);
                } else if(info.name == categoriesArr[4]){
                  let newContents = {
                    y: info.y,
                    x: info.x,
                    name: info.name
                }
                  arrayAux5.push(newContents);

                } else if(info.name == categoriesArr[5]){
                  let newContents = {
                    y: info.y,
                    x: info.x,
                    name: info.name
                }
                  arrayAux6.push(newContents);
                }
            });

            this.state.options2.series[0].data = arrayAux;
            this.state.options2.series[0].name = categoriesArr[0];

           
            if(arrayAux2.length > 0){
            this.state.options2.series[1].data = arrayAux2;
            this.state.options2.series[1].name = categoriesArr[1];
            }else{
              this.state.options2.series[1].name = " ";
            }
            
            if(arrayAux3.length > 0){
              this.state.options2.series[2].data = arrayAux3;
              this.state.options2.series[2].name = categoriesArr[2];
              }else{
                this.state.options2.series[2].name = " ";
              }

            if(arrayAux4.length > 0){
              this.state.options2.series[3].data = arrayAux4;
              this.state.options2.series[3].name = categoriesArr[3];
              }else{
                this.state.options2.series[3].name = " ";
              }
            
              if(arrayAux5.length > 0){
                this.state.options2.series[4].data = arrayAux5;
                this.state.options2.series[4].name = categoriesArr[4];
                }else{
                  this.state.options2.series[4].name = " ";
                }

                if(arrayAux6.length > 0){
                  this.state.options2.series[5].data = arrayAux6;
                  this.state.options2.series[5].name = categoriesArr[5];
                  }else{
                    this.state.options2.series[5].name = " ";
                  }

             //console.log(this.state.options2);
             //console.log(arrayitems2.name);
            this.state.instance = Highcharts.chart("dummy-id2", this.state.options2);
        }
        
    }else{
        if(this.props.chartTo == 'salesHour'){
            this.state.options.title = {
              text: ' '
            }
            this.state.options.series = [
            {
              name: ' ',
              data: []
            }
          ];  
            this.state.instance = Highcharts.chart("dummy-id", this.state.options) 
        }else if(this.props.chartTo == 'salesArea'){
          this.state.options2.title = {
            text: ' '
          }
            this.state.options2.series = [
                {
                  name: ' ',
                  data: []
                },
                {
                  name: ' ',
                  data: []
                },
                {
                  name: ' ',
                  data: []
                },
                {
                  name: ' ',
                  data: []
                },
                {
                  name: ' ',
                  data: []
                },
                {
                  name: ' ',
                  data: []
                }
              ];       
            this.state.instance = Highcharts.chart("dummy-id2", this.state.options2) 
        }
    }
  }

  parseHour(time){
    let hours = time.split('T')[1].split(':')[0];
    return hours;
  }

    convertNumber(num, locale = 'en-US') {
        const { format } = new Intl.NumberFormat(locale);
        const [, decimalSign] = /^0(.)1$/.exec(format(0.1));
        return +num
        .replace(new RegExp(`[^${decimalSign}\\d]`, 'g'), '')
        .replace(decimalSign, '.');
    };

  render() {
    return (
      <div>
        <div id="dummy-id" />
        <br></br>
        <div id="dummy-id2" />
      </div>
    );
  }
}
