import React, { Component } from 'react';
import axios from 'axios';
import helpers from '../../Helpers';
import '../Companies/Company.css';

import Button from '@material-ui/core/Button';

export class ProductsCreate extends Component {
    static displayName = ProductsCreate.name;

    constructor(props) {
        super(props);

        this.onChangeName = this.onChangeName.bind(this);
        this.onChangeDescription = this.onChangeDescription.bind(this);
        this.onChangeTreatmentId = this.onChangeTreatmentId.bind(this);
        this.onChangeBrandId = this.onChangeBrandId.bind(this);
        this.onChangeSupplierId = this.onChangeSupplierId.bind(this);
        this.onChangeImageFile = this.onChangeImageFile.bind(this);
        this.onChangeSku = this.onChangeSku.bind(this);
        this.onChangeDuration = this.onChangeDuration.bind(this);
        this.onChangeEnabled = this.onChangeEnabled.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onSubmit = this.onSubmit.bind(this);  
        // this.onPaymentId = this.onChangePaymentId.bind(this); 
        this.onChangePaymentId = this.onChangePaymentId.bind(this);
        this.onChangeClassificationId = this.onChangeClassificationId.bind(this);

        this.state = {
            id: 0,
            name: null,
            description: null,
            brandId: null,
            supplierId: null,
            treatmentId: null,
            image: null,
            imageFile: null,
            sku: '',
            duration: null,
            enabled: true,
            loading: true,
            resources: [],
            brands: [],
            suppliers: [],
            treatments: [],
            payments:[],
            paymentId: null,
            userId: 0,
            payments: [],
            paymentId: null,
            classifications: [],
            classificationId: null,
        };
    }

    componentDidMount() {
        this.getResources();
        this.populateData();
        helpers.setNavActive("#nav-products");
        helpers.setClientValidation();

        let currentUser = helpers.getUserData();
        this.setState({
            userId: currentUser.id
        });
    }

    async onSubmit(e) {
        e.preventDefault();

        var form = document.querySelectorAll('.needs-validation')[0];

        if (!form.checkValidity())
            return;

        this.setState({ loading: true });

        // upload logo file
        const formData = new FormData();
        formData.append('file', this.state.imageFile);

        const uploadLogoHeaders = {
            'Content-Type': 'multipart/form-data',
            'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME)
        };

        // let resp = await axios.post(window.config.REACT_APP_PRODUCTS_API_URL + '/product/uploadlogo', formData, {
        //     headers: uploadLogoHeaders
        // })
        //     .then((response) => {
        //         this.setState({ image: response.data.data });

        //         return response.data;
        //     })
        //     .catch((error) => {
        //         console.log(error);

        //         this.setState({ loading: false });
        //     });

        // if (resp.success !== true) {
        //     helpers.notifyError(this.state.resources.Label_Error, this.state.resources.ErrorMessage_ErrorWhileUploadingImage);
        //     return;
        // }

        const data = {
            name: this.state.name,
            description: this.state.description,
            image: this.state.imageFile,
            brand_id: this.state.brandId,
            supplier_id: this.state.supplierId,
            treatment_id: this.state.treatmentId,
            payment_id: this.state.paymentId,
            duration: this.state.duration,
            sku_id: this.state.sku,
            enabled: this.state.enabled,
            creation_user_id: this.state.userId,
            payment_id: this.state.paymentId,
            classification_id: this.state.classificationId
        };
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME)
        };

        axios.post(window.config.REACT_APP_PRODUCTS_API_URL + '/product/save', data, {
            headers: headers
        })
            .then((response) => {
                helpers.notifySuccess(this.state.resources.Label_Success, this.state.resources.SuccessMessage_DataSavedSuccessfully);
                this.props.history.push('/products');
            })
            .catch((error) => {
                console.log(error);

                let msg = this.state.resources.ErrorMessage_UnknownError;
                if (error.response.data.error_code == window.errorCodes.DuplicatedEntityName)
                    msg = this.state.resources.ErrorMessage_DuplicatedName;
                else if (error.response.data.error_code == window.errorCodes.AssociatedEntity)
                    msg = this.state.resources.ErrorMessage_AssociatedEntity_Company_User;

                helpers.notifyError(this.state.resources.Label_Error, msg);
                this.setState({ loading: false });
            });
    }  

    onCancel(e) {
        e.preventDefault();
        this.props.history.push("/products");
    }

    onChangeName(e) {
        this.setState({
            name: e.target.value
        });
    }  
    onChangeDescription(e) {
         this.setState({
            description: e.target.value
        });
    } 
    onChangeImageFile(e) {
        this.setState({
            imageFile: e.target.files[0],
            //show new file name in UI
            image: e.target.files[0].name
        });
    }  
    onChangeSku(e) {
        this.setState({
            sku: e.target.value
        });
    }
    
    onChangeBrandId(e) {
        this.setState({
            brandId: e.target.value
        });
    }

    onChangeSupplierId(e) {
        this.setState({
            supplierId: e.target.value
        });
    }

    onChangeTreatmentId(e) {
        this.setState({
            treatmentId: e.target.value
        });
    }
    onChangeEnabled(e) {
        this.setState({
            enabled: e.target.checked
        });
    }
    
    onChangeDuration(e) {
        this.setState({
            duration: e.target.value
        });
    }

    onChangePaymentId(e) {
        this.setState({
            paymentId: e.target.value
        });
    }

    onChangeClassificationId(e){
        this.setState({
            classificationId: e.target.value
        });
    }

    convertBase64(files) {
        Array.from(files).forEach((file) => {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (e) => {
                let filePreview = document.querySelector('#file-preview');
                    //e.target.result contents the base64 data from the image uploaded
                filePreview.src = e.target.result;

                let previewZone = document.getElementById('file-preview-zone');
                previewZone.appendChild(filePreview);
                let arrayAux = [];
                let base64 = reader.result;
                arrayAux = base64.split(',');
                this.setState({
                    imageFile: arrayAux[1],
                    //show new file name in UI
                    image: files[0].name
                });
            }
        })
    }

    render() {
        let contents =
            <form className="form row g-3 needs-validation" onSubmit={this.onSubmit} noValidate>
                {/*ENABLED*/}
                <div className="form-group col-md-12">
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" id="enabled" checked={this.state.enabled} onChange={this.onChangeEnabled} />
                        <label className="form-check-label" htmlFor="enabled">{this.state.resources.Label_Enabled}</label>
                        <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                    </div>
                </div>

                {/*LOGO FILE / NAME*/}
                <div className=" form-group col-md-4">
                    
                    <div className="pl-3 pt-3">
                        <input
                            accept="image/*"
                            className="input-upload"
                            id="contained-button-file"
                            multiple
                            type="file"
                            onChange={(e)=>this.convertBase64(e.target.files)}
                            // onChange={this.onChangeImageFile}
                        />
                        <label className="px-4">{this.state.image}</label>
                        <label htmlFor="contained-button-file">
                            <Button variant="outlined" color="secondary" component="span">
                                Image
                            </Button>
                        </label>
                        <div id="file-preview-zone" style={{ width: '100px' , height: '100px' }}>
                            <img id="file-preview" src={'data:image/png;base64,' + this.state.imageFile} style={{ height: '150px' }}/>
                         </div>
                    </div>
                    {/* <label className=" form-label">{this.state.resources.Label_Logo}</label>
                    <input className="form-control" type="file" name="logofile" id="logofile" defaultValue={this.state.logoFile} onChange={this.onChangeLogoFile} required /> */}
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                </div>
                <div className="form-group col-md-8" >

                    <div className="row col-md-12">
                        <div className="col-md-6">
                            <label className=" form-label">{this.state.resources.Label_Name}</label>
                            <input className="form-control" type="text" name="name" onChange={this.onChangeName} value={this.state.name} required />
                            <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                        </div>

                        <div className="col-md-6">
                            <label className=" form-label">{this.state.resources.Label_Classification}</label>
                            <select className="form-control" data-val="true" name="classificationId" defaultValue={this.state.classificationId} onChange={this.onChangeClassificationId}>
                                <option value="">{this.state.resources.Label_SelectOption}</option>
                                {this.state.classifications.map(classification =>
                                    <option key={classification.id} value={classification.id}>{classification.name}</option>
                                )}
                            </select>
                            <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                        </div>
                    </div>

                    <div className="row col-md-12">
                        <div className="col-md-6">
                            <label className=" form-label">SKU</label>
                            <input className="form-control" type="text" name="sku" onChange={this.onChangeSku} value={this.state.sku} required />
                            <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                        </div>
                        <div className="col-md-6">
                            <label className=" form-label">{this.state.resources.Label_Description}</label>
                            <input className="form-control" type="text" maxlength="150" name="description" onChange={this.onChangeDescription} value={this.state.description} placeholder="Max (150)"/>
                            <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                        </div>
                    </div>
                    
                    <div classname="row col-md-12" style={{display: 'flex'}}>
                        <div className="col-md-6 pr-4">
                            <label className=" form-label">{this.state.resources.Label_Brand}</label>
                            <select className="form-control" data-val="true" name="brandid" defaultValue={this.state.brandId} onChange={this.onChangeBrandId} required>
                                <option value="">{this.state.resources.Label_SelectOption}</option>
                                {this.state.brands.map(brand =>
                                    <option key={brand.id} value={brand.id}>{brand.name}</option>
                                )}
                            </select>
                            <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                        </div>

                        <div className="col-md-6 pl-0" style={{paddingRight: '2rem'}}>
                            <label className=" form-label">{this.state.resources.Label_Supplier}</label>
                            <select className="form-control" data-val="true" name="supplierid" defaultValue={this.state.supplierId} onChange={this.onChangeSupplierId} required>
                                <option value="">{this.state.resources.Label_SelectOption}</option>
                                {this.state.suppliers.map(supplier =>
                                    <option key={supplier.id} value={supplier.id}>{supplier.name}</option>
                                )}
                            </select>
                            <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                        </div>
                    </div>

                    <div className="row col-md-12">
                        <div className="col-md-6">
                            <label className=" form-label">{this.state.resources.Title_Treatments}</label>
                            <select className="form-control" data-val="true" name="treatmentid" defaultValue={this.state.treatmentId} onChange={this.onChangeTreatmentId}>
                                <option value="">{this.state.resources.Label_SelectOption}</option>
                                {this.state.treatments.map(treatment =>
                                    <option key={treatment.id} value={treatment.id}>{treatment.name}</option>
                                )}
                            </select>
                            {/* <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div> */}
                        </div>
                        
                        <div className="col-md-6">
                            <label className=" form-label">{this.state.resources.Label_DurationTreatment}</label>
                            <input className="form-control" type="number" name="duration" onChange={this.onChangeDuration} value={this.state.duration} required={this.state.treatmentId > 0 ? true : false}/>
                        </div>
                    </div>
                    
                    <div className="col-md-6 pr-4">
                        <label className=" form-label">{this.state.resources.Label_ProductPayment}</label>
                        <select className="form-control" data-val="true" name="paymentId" defaultValue={this.state.paymentId} onChange={this.onChangePaymentId} required>
                            <option value="">{this.state.resources.Label_SelectOption}</option>
                            {this.state.payments.map(payment =>
                                <option key={payment.id} value={payment.id}>{payment.description}</option>
                            )}
                        </select>
                    </div>
                </div>
               
                <div className="col-md-6" >

                    {/* <label className=" form-label">{this.state.resources.Workspace_Payment}</label>
                    <select className="form-control" data-val="true" name="paymentid" defaultValue={this.state.paymentId} onChange={this.onChangePaymentId} >
                    <option value="">{this.state.resources.Label_SelectOption}</option>
                        {this.state.payments.map(payment =>
                            <option key={payment.id} value={payment.id}>{payment.method == 'All' ? this.state.resources.Label_All : payment.method}</option>
                        )}
                    </select>
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div> */}

                    
                    {/* <label className=" form-label">{this.state.resources.Label_ProductPayment}</label>
                    <select className="form-control" data-val="true" name="paymentId" defaultValue={this.state.paymentId} onChange={this.onChangePaymentId} required>
                        <option value="">{this.state.resources.Label_SelectOption}</option>
                        {this.state.payments.map(payment =>
                            <option key={payment.id} value={payment.id}>{payment.description}</option>
                        )}
                    </select> */}

                </div>

                {/**/}
                {/* <div className=" form-group col-md-6">
                    <label className=" form-label">{this.state.resources.Label_Brand}</label>
                    <select className="form-control" data-val="true" name="brandid" defaultValue={this.state.brandId} onChange={this.onChangeBrandId} required>
                        <option value="">{this.state.resources.Label_SelectOption}</option>
                        {this.state.brands.map(brand =>
                            <option key={brand.id} value={brand.id}>{brand.name}</option>
                        )}
                    </select>
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                </div> */}
                <div className=" form-group col-md-6">
                    {/* <label className=" form-label">{this.state.resources.Label_Supplier}</label>
                     <select className="form-control" data-val="true" name="supplierid" defaultValue={this.state.supplierId} onChange={this.onChangeSupplierId} required>
                        <option value="">{this.state.resources.Label_SelectOption}</option>
                        {this.state.suppliers.map(supplier =>
                            <option key={supplier.id} value={supplier.id}>{supplier.name}</option>
                        )}
                    </select>
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div> */}
                </div>
                <div className=" form-group col-md-6">
                    {/* <label className=" form-label">{this.state.resources.Title_Treatments}</label>
                    <select className="form-control" data-val="true" name="treatmentid" defaultValue={this.state.treatmentId} onChange={this.onChangeTreatmentId} required>
                        <option value="">{this.state.resources.Label_SelectOption}</option>
                        {this.state.treatments.map(treatment =>
                            <option key={treatment.id} value={treatment.id}>{treatment.name}</option>
                        )}
                    </select>
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div> */}
                </div>


                {/**Buttons Header */}
                 <div className="buttons-head row">
                    <div className=" buttons-childs">
                        <button type="submit" className="btn btn-primary">{this.state.resources.ButtonLabel_Save}</button>
                        <button className="btn btn-danger" onClick={this.onCancel}>{this.state.resources.ButtonLabel_Cancel}</button>
                    </div>
                 </div>
            </form>;

        return (

           <div>
                
           
            <div className="mt-2">
                
                {/* <h1 className="mt-4">{this.state.resources.Title_Companies}</h1> */}
                <ol className="breadcrumb my-4">
                    <li className="breadcrumb-item active">{this.state.resources.Label_Products}</li>
                </ol>
                <div className="card">
                    <div className="card-body">
                        {this.state.loading ? helpers.showLoadingOverlay() : helpers.hideLoadingOverlay()}
                        {contents}
                    </div>
                </div>
                </div>
        </div> 
        );
    }

    async getResources() {
        const resourceVars = [];
        resourceVars.push("Label_Products");
        resourceVars.push("Label_Name");
        resourceVars.push("Label_Description");
        resourceVars.push("Label_Logo");
        resourceVars.push("Label_FiscalId");
        resourceVars.push("Label_Brand");
        resourceVars.push("Label_Supplier");
        resourceVars.push("Title_Treatments");
        resourceVars.push("Label_Logo");
        resourceVars.push("Label_BusinessArea");
        resourceVars.push("Label_Enabled");
        resourceVars.push("Label_Success");
        resourceVars.push("Label_SelectOption");
        resourceVars.push("ButtonLabel_Save");
        resourceVars.push("ButtonLabel_Cancel");
        resourceVars.push("Label_Error");
        resourceVars.push("ErrorMessage_RequiredField");
        resourceVars.push("ErrorMessage_DuplicatedName");
        resourceVars.push("ErrorMessage_UnknownError");
        resourceVars.push("ErrorMessage_AssociatedEntity_Company_User");
        resourceVars.push("ErrorMessage_ErrorWhileUploadingImage");
        resourceVars.push("ErrorMessage_InvalidEmail");
        resourceVars.push("SuccessMessage_DataSavedSuccessfully");
        resourceVars.push("Label_DurationTreatment");
        resourceVars.push("Workspace_Payment");
        resourceVars.push("Label_Classification");
        
        resourceVars.push("Label_ProductPayment");

        const headers = {
            'Content-Type': 'application/json'
        };

        axios.post(window.config.REACT_APP_SECURITY_API_URL + '/languageresource/getbyvars/' + helpers.getUiLanguage(), resourceVars, {
            headers: headers
        })
            .then((response) => {
                const data = response.data.data;
                this.setState({ resources: data, loading: false });
            })
            .catch((error) => {
                console.log(error);
                this.setState({ loading: false });
            });
    }

    async populateData() {
        this.setState({
            loading: true
        });

        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/brand/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ brands: data, loading: false });
                // order alphabetically
                this.state.brands.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                // console.log(this.state.brands);
            })
            .catch(function (error) {
                console.log(error);
            });

        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/supplier/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ suppliers: data, loading: false });
                // order alphabetically
                this.state.suppliers.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                // console.log(this.state.suppliers);
            })
            .catch(function (error) {
                console.log(error);
            });

        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/treatment/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ treatments: data, loading: false });
                // order alphabetically
                this.state.treatments.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                // console.log(this.state.treatments);
            })
            .catch(function (error) {
                console.log(error);
            });

            axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/productpayment/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ payments: data, loading: false });
                // order alphabetically
                this.state.payments.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                // console.log(this.state.payments);
            })
            .catch(function (error) {
                console.log(error);
            });


        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/companybusinessarea/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ businessAreas: data, loading: false });
                // order alphabetically
                this.state.businessAreas.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                // console.log(this.state.businessAreas);
            })
            .catch(function (error) {
                console.log(error);
            });
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/productpayment/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ payments: data, loading: false });
                // order alphabetically
                this.state.payments.sort((a,b) => a.description < b.description ? -1 : +(a.description > b.description))
                // console.log(this.state.payments);
            })
            .catch(function (error) {
                console.log(error);
            });
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/productcategory/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ classifications: data, loading: false });
                // order alphabetically
                this.state.classifications.sort((a,b) => a.description < b.description ? -1 : +(a.description > b.description))
                // console.log(this.state.classifications);
            })
            .catch(function (error) {
                console.log(error);
            });
    }
}
