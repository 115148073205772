import React, { Component } from 'react';
import axios from 'axios';
import helpers from '../../Helpers'

import '../../custom.css';

export class CountryProduct extends Component {
    static displayName = CountryProduct.name;

    constructor(props) {
        super(props);

        this.onChangeProduct = this.onChangeProduct.bind(this);
        this.onChangeCountry = this.onChangeCountry.bind(this);
        this.onChangePrice = this.onChangePrice.bind(this);
        this.onChangeEnabled = this.onChangeEnabled.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onSubmit = this.onSubmit.bind(this);  

        this.state = {
            id: 0,
            products: [],
            countries: [],
            country_id: null,
            country_name:null,
            product_id: null,
            product_country_id: null,
            product_countries: [],
            price: 0,
            enabled: true,
            loading: true,
            resources: []
        };
    }

    componentDidMount() {
        this.getResources();
        this.populateData();
        helpers.setNavActive("#nav-add-country-product");
        
    }

    

    async onSubmit(e) {
        e.preventDefault();
        this.setState({ loading: true });

        const data = {
            country_id: this.state.country_id,
            product_id: this.state.product_id,
            price: this.state.price,
            enabled: this.state.enabled
        };
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME)
        };

        axios.post(window.config.REACT_APP_PRODUCTS_API_URL + '/countryproductinfo/save', data, {
            headers: headers
        })
            .then((response) => {
                helpers.notifySuccess(this.state.resources.Label_Success, this.state.resources.SuccessMessage_DataSavedSuccessfully);
                this.props.history.push("/products-info/" + this.state.product_id);
            })
            .catch((error) => {
                console.log(error);

                let msg = this.state.resources.ErrorMessage_UnknownError;
                if (error.response.data.error_code == window.errorCodes.DuplicatedEntityName)
                    msg = this.state.resources.ErrorMessage_DuplicatedName;
                else if (error.response.data.error_code == window.errorCodes.DuplicatedCountryIsoCode)
                    msg = this.state.resources.ErrorMessage_DuplicatedCountryIsoCode;

                helpers.notifyError(this.state.resources.Label_Error, msg);
                this.setState({ loading: false });
            });
    }  

    onCancel(e) {
        e.preventDefault();
        this.props.history.push("/products");
    }

    async onChangeProduct(e) {
        this.setState({
            product_id: e.target.value
        });
        let dataCountryProduct = [];
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/countryproductinfo/getbyproductid/' + e.target.value,
                {
                    'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
                })
                .then(response => {
                    dataCountryProduct = response.data.data;
                    let data = [];
                    localStorage.setItem('dataCountryProductInfo', JSON.stringify(dataCountryProduct[0]));
                    for (let i = 0; i < dataCountryProduct.length; i++) {
                        data.push(dataCountryProduct[i].country_name);
                    }
                    data.push(this.state.country_name);
                    localStorage.setItem('dataCountriesProductInfo', data);
                })
                .catch(function (error) {
                    console.log(error);
                });
    }

    async onChangeCountry(e) {
        this.setState({
            country_id: e.target.value
        });
        
        let authTokenProducts = await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME);
        let authTokenSecurity = await helpers.getAuthorizationToken(process.env.REACT_APP_SECURITY_API_NAME);
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/productcountry/getproductsbycountryid/' + this.state.country_id,
                {
                    'headers': { 'Authorization': authTokenProducts}
                })
                .then(response => {
                    const data = response.data.data;
                    this.state.product_countries = [];
                    // console.log(data);
                    for (let i = 0; i < data.length; i++) {
                        this.state.product_countries.push(data[i].product_id);
                    }
                    // order alphabetically
                    this.state.product_countries.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                    // console.log(this.state.product_countries);
                    setTimeout(() => {
                        this.setState({
                            product_countries: this.state.product_countries,
                            country_name: data[0].country_name
                         });
                    }, 1000);
                })
                .catch(function (error) {
                    console.log(error);
                });
        
    }
    
    onChangePrice(e) {
        this.setState({
            price: e.target.value
        });
    }
    
    onChangeEnabled(e) {
        this.setState({
            enabled: e.target.checked
        });
    } 

    render() {
        let contents = this.state.loading
            ? helpers.showLoadingOverlay()
            :
            <form className="form row g-3 needs-validation" onSubmit={this.onSubmit}>
                {/* <div className="form-group col-md-12">
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" id="enabled" defaultChecked={this.state.enabled} onChange={this.onChangeEnabled} />
                        <label className="form-check-label" htmlFor="enabled">{this.state.resources.Label_Enabled}</label>
                        <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                    </div>
                </div> */}
                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Title_Countries}</label>
                    <select className="form-control" data-val="true" name="countryid" defaultValue={this.state.countryId} onChange={this.onChangeCountry} required>
                        <option value="">{this.state.resources.Label_SelectOption}</option>
                        {this.state.countries.map(country =>
                            <option key={country.id} value={country.id}>{country.name}</option>
                        )}
                    </select>
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                </div>
                <div className=" form-group col-md-6">
                    <label className="form-label">{this.state.resources.Label_Products}</label>
                    <select className="form-control" data-val="true" name="productid" defaultValue={this.state.productId} onChange={this.onChangeProduct} required>
                        <option value="">{this.state.resources.Label_SelectOption}</option>
                        {this.state.products.map(product =>
                            this.state.product_countries.includes(product.id) &&
                                <option key={product.id} value={product.id}>{product.name}</option>
                                
                        )}
                        

                    </select>
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                </div>
                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_Price}</label>
                    <input className="form-control" type="number" name="price" onChange={this.onChangePrice} value={this.state.price} required />
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                </div>
                

                {/*SAVE / CANCEL*/}
                <div className="buttons-head row">
                    <div className=" buttons-childs">
                        <button type="submit" className="btn btn-primary">{this.state.resources.ButtonLabel_Save}</button>
                        <button className="btn btn-danger" onClick={this.onCancel}>{this.state.resources.ButtonLabel_Cancel}</button>
                    </div>
                 </div>
            </form>
            helpers.hideLoadingOverlay();

        return (
            <div className="mt-2">
                {/* <h1 className="mt-4">{this.state.resources.Title_Countries}</h1> */}
                <ol className="breadcrumb my-4">
                    <li className="breadcrumb-item active">{this.state.resources.Label_AddCountry}</li>
                </ol>
                <div className="card">
                    <div className="card-body">
                        {contents}
                    </div>
                </div>
            </div>
        );
    }

    async getResources() {
        const resourceVars = [];
        resourceVars.push("Title_Countries");
        resourceVars.push("Label_Name");
        resourceVars.push("Label_IsoCode");
        resourceVars.push("Label_PhoneCode");
        resourceVars.push("Label_CurrencySymbol");
        resourceVars.push("Label_Theme");
        resourceVars.push("Label_Notes");
        resourceVars.push("Label_Enabled");
        resourceVars.push("Label_Success");
        resourceVars.push("ButtonLabel_Save");
        resourceVars.push("ButtonLabel_Cancel");
        resourceVars.push("Label_Error");
        resourceVars.push("ErrorMessage_RequiredField");
        resourceVars.push("ErrorMessage_DuplicatedName");
        resourceVars.push("ErrorMessage_UnknownError");
        resourceVars.push("ErrorMessage_DuplicatedCountryIsoCode");
        resourceVars.push("ErrorMessage_AssociatedEntity_Country_Company");
        resourceVars.push("SuccessMessage_DataSavedSuccessfully");
        resourceVars.push("Label_Price");
        resourceVars.push("Label_Products");
        resourceVars.push("Label_SelectOption");
        resourceVars.push("Label_AddCountry");
        const headers = {
            'Content-Type': 'application/json'
        };

        axios.post(window.config.REACT_APP_SECURITY_API_URL + '/languageresource/getbyvars/' + helpers.getUiLanguage(), resourceVars, {
            headers: headers
        })
            .then((response) => {
                const data = response.data.data;
                this.setState({ resources: data, loading: false });
            })
            .catch((error) => {
                console.log(error);
                this.setState({ loading: false });
            });
    }

    async populateData() {
        this.setState({
            loading: false
        });

        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/product/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ products: data, loading: false });
                // order alphabetically
                this.state.products.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                // console.log(this.state.brands);
            })
            .catch(function (error) {
                console.log(error);
            });

       
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/country/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ countries: data, loading: false });
                // order alphabetically
                this.state.countries.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                
            })
            .catch(function (error) {
                console.log(error);
            });
    }
}
