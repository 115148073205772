import React, { Component, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';  
import $ from 'jquery';
import DataTable from 'datatables.net';
import helpers from '../../Helpers';
import '../../custom.css';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/styles';
import { DataGrid, useGridApiRef, esES } from '@mui/x-data-grid';
import { DataGridPro } from '@mui/x-data-grid-pro';
import CircularProgress from '@mui/material/CircularProgress';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import {CSVLink} from 'react-csv'
import {CSVReader } from 'react-papaparse'
import Papa from "papaparse";

LicenseInfo.setLicenseKey(
  '97124f3444ae3cf552c73ef2f3487b08T1JERVI6MzEzNDQsRVhQSVJZPTE2NjY1MzkzOTkwMDAsS0VZVkVSU0lPTj0x',
);

export class MapCourierCityList extends Component {
    static displayName = MapCourierCityList.name;

    constructor(props) {
        super(props);
        this.state = {
            items: [],
           // csvItems: [],
            resources: [],
            loading: true,
            permissionsIds: [],
            citiesList: [],
            columns: [],
            paging: null,
            sorting: null,
            filtering: null,
            importItems: [],
            token:"",
            userId: 0,
            importOK: 0,
            resultImport: [],
            mensajeOK: ""

        };
        ImportRoute = ImportRoute.bind(this);

    }

    componentDidMount() {
        let userData = helpers.getUserData();
        this.state.permissionsIds = userData.permission_ids;
        this.state.userId = userData.id;
        this.getResources();
        this.populateData();
        helpers.setNavActive("#nav-map-courier-city");
        this.state.sorting = JSON.parse(localStorage.getItem('CopyMapCourierCityListSorting'));
        this.state.paging = JSON.parse(localStorage.getItem('CopyMapCourierCityListPaging'));
        this.state.filtering = JSON.parse(localStorage.getItem('CopyMapCourierCityListFiltering'));
    }

      static renderTable(items, resources, permissionsIds, citiesList) {
          return (
                <table className='table datatable my-table' aria-labelledby="tabelLabel"> {/*table class:* table-striped datatable */}
                    <thead>
                        <tr>
                          <th>{resources.Label_ID}</th>
                          <th>{resources.Label_Country}</th>
                          <th>{resources.Title_State}</th>
                          <th>{resources.Label_Cities}</th>
                          <th>{resources.Label_RouteType}</th>
                          <th>{resources.Label_PayMethod}</th>
                          <th>{resources.Label_Limit}</th>
                          <th>{resources.Title_Courier}</th>
                          <th>{resources.Label_DeliveryTime}</th>
                          <th className="text-center">{resources.Label_Actions}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            items.map(item =>
                                <tr key={item.id}>
                                    <td>{item.id}</td>
                                    <td>{item.country_name}</td>
                                    <td>{item.state_name}</td>
                                    {/* <td>{resources.Label_LookCities}</td> */}
                                    <td><BasicModal cities={item.cities} resc={resources} citiesList={citiesList}
                                    ></BasicModal></td>
                                    <td>{item.route_type}</td>
                                    <td>{item.waypay === 'all' && resources.Label_All}</td>
                                    <td>{item.limit != 0 ? '$'+item.limit : '---'}</td>
                                    <td>{item.courier_name}</td>
                                    <td>{item.delivery_time}</td>
                                    {/* <td>{item.enabled == true ? resources.Label_Enabled : resources.Label_Disabled}</td> */}
                                    {permissionsIds.includes(137) ?
                                    <>
                                    <td className="text-center dis-flex">
                                        <Link to={"/map-courier-city-detail/" + item.id} className="btn btn-default"><i className="fas fa-edit"></i></Link>
                                        
                                           <DeleteModal item={item} resc={resources}></DeleteModal>
                                    </td>
                                    </>
                                    : 
                                     <td className="text-center">
                                        <Link to={"/map-courier-city-view/" + item.id} className="btn btn-default"><i class="fas fa-eye"></i></Link>
                                      </td>
                                    }
                                </tr>
                            )}
                    </tbody>
                </table>
        );
      }

      render() {
          let contents = this.state.loading
              ? helpers.showLoadingOverlay()
              : <MapCourierCityDataGrid data={this.state} />
              //MapCourierCityList.renderTable(this.state.items, this.state.resources, this.state.permissionsIds, this.state.citiesList);

          return (
              <div className="mt-2">
                  <ol className="breadcrumb my-4">
                      <li className="breadcrumb-item active">{this.state.resources.Title_MapCourierCity}</li>
                  </ol>
                  <div className="card">
                      <div className="card-header">
                      {/* {this.state.permissionsIds.includes(136) ?
                      <CSVLink data={this.state.csvItems} filename="MAPA_DESTINOS.csv"><button className="btn btn-success">{this.state.resources.Label_Export}</button></CSVLink>
                      : <></>} */}
                      {/* &nbsp; &nbsp; 
                      {this.state.permissionsIds.includes(136) ?
                      <CSVLink data={this.state.csvItems} filename="MAPA_DESTINOS.csv"><button className="btn btn-success">{this.state.resources.Label_Export}</button></CSVLink>
                      : <></>} */}
                      &nbsp; &nbsp; 
                          {this.state.permissionsIds.includes(136) ?
                          <Link to={"/map-courier-city-create"} className="btn btn-success"><i className="fas fa-plus" aria-hidden="true"></i> {this.state.resources.ButtonLabel_New}</Link>
                            : <></>}
                        </div>
                        
                      {
                        /*this.state.permissionsIds.includes(136) ? <ImportRoute data={this.state}/> : <></>*/
                      }
                      
                      <div className="card-body">
                          <div className="table-responsive">
                              {contents}
                          </div>
                      </div>
                  </div>
              </div>
        );
      }

    async populateData() {
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/mapcouriercity/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ items: data, loading: false });
                this.state.columns = [
                    {
                        field: 'actions',
                        headerAlign: "center",
                        headerClassName: "header-table-mui",
                        headerName: this.state.resources.Label_Actions ? this.state.resources.Label_Actions : 'Actions',
                        flex: 1,
                        minWidth: 140,
                        renderCell: (item) => {
                            return (
                                this.state.permissionsIds.includes(137) ?
                                <div className="d-flex justify-content-between align-items-center mx-auto" style={{ cursor: "pointer" }}>

                                    <Link to={"/map-courier-city-detail/" + item.id} className="btn btn-default"><i className="fas fa-edit"></i></Link>
                                    <DeleteModal item={item.row} resc={this.state.resources}></DeleteModal>

                                </div>
                                :
                                <div>
                                <td className="text-center">
                                    <Link to={"/map-courier-city-view/" + item.id} className="btn btn-default"><i class="fas fa-eye"></i></Link>
                                </td>
                                </div>
                            );
                        }
                    },
                    {
                        field: 'id',
                        headerName: 'ID',
                        type: 'number',
                        width: 105,
                        headerAlign: "center",
                        align: "left",
                        headerClassName: "header-table-mui"
                    }, 
                    {
                        field: 'country_name',
                        headerName: this.state.resources.Label_Country ? this.state.resources.Label_Country : 'Country Name',
                        flex: 1,
                        minWidth: 140,
                        headerAling: "center",
                        headerClassName: "header-table-mui"
                    },
                    {
                        field: 'state_name',
                        headerName: this.state.resources.Title_State ? this.state.resources.Title_State : 'State Name',
                        flex: 1,
                        minWidth: 140,
                        headerAling: "center",
                        headerClassName: "header-table-mui"
                    },
                    {
                        field: 'cities',
                        headerName: this.state.resources.Label_Cities ? this.state.resources.Label_Cities : 'Cities',
                        flex: 1,
                        minWidth: 140,
                        headerAling: "center",
                        headerClassName: "header-table-mui",
                        renderCell: (item) => {
                            return (
                                <td><BasicModal cities={item.row.cities} resc={this.state.resources} citiesList={this.state.citiesList}></BasicModal></td>
                            )
                        }
                    },
                    {
                        field: 'route_type',
                        headerName: this.state.resources.Label_RouteType ? this.state.resources.Label_RouteType : 'Route Type',
                        flex: 1,
                        minWidth: 160,
                        headerAling: "center",
                        headerClassName: "header-table-mui"
                    },
                    {
                        field: 'waypay_method',
                        headerName: this.state.resources.Label_PayMethod ? this.state.resources.Label_PayMethod : 'Pay Method',
                        flex: 1,
                        minWidth: 200,
                        headerAling: "center",
                        headerClassName: "header-table-mui",
                        renderCell: (item) => {
                            return (
                                <td>{item.row.waypay_id == 3 ? this.state.resources.Label_All  : item.row.waypay_method}</td>
                            )
                        }
                    },
                    {
                        field: 'limit',
                        headerName: this.state.resources.Label_Limit ? this.state.resources.Label_Limit : 'Limit',
                        flex: 1,
                        minWidth: 130,
                        headerAling: "center",
                        headerClassName: "header-table-mui",
                        renderCell: (item) => {
                            return (
                                <td>{item.row.limit != 0 ? '$'+item.row.limit : '---'}</td>
                            );
                        }
                    },
                    {
                        field: 'courier_name',
                        headerName: this.state.resources.Title_Courier ? this.state.resources.Title_Courier : 'Courier Name',
                        flex: 1,
                        minWidth: 160,
                        headerAling: "center",
                        headerClassName: "header-table-mui"
                    },
                    {
                        field: 'delivery_time',
                        headerName: this.state.resources.Label_DeliveryTime ? this.state.resources.Label_DeliveryTime : 'Delivery Time',
                        flex: 1,
                        minWidth: 170,
                        headerAling: "center",
                        headerClassName: "header-table-mui"
                    }
                ];
                helpers.hideLoadingOverlay();
            })
            .catch(function (error) {
                console.log(error);
                helpers.hideLoadingOverlay();
            });
            // axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/mapcouriercity/getallcsv',
            // {
            //     'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            // })
            // .then(response => {
            //     const data = response.data.data;
            //     console.log(data);
            //     this.setState({ csvItems: data, loading: false });
            //     helpers.hideLoadingOverlay();
            // })
            // .catch(function (error) {
            //     console.log(error);
            //     helpers.hideLoadingOverlay();
            // });
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/city/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ citiesList: data, loading: false });
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    /*async getToken()
    {
        this.setState({ token : await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME)});
    }*/

    async getResources() {
        const resourceVars = [];
        resourceVars.push("Title_MapCourierCity");
        resourceVars.push("Title_Courier");
        resourceVars.push("Title_City");
        resourceVars.push("Title_State");
        resourceVars.push("Label_ID");
        resourceVars.push("Label_Name");
        resourceVars.push("Label_Description");
        resourceVars.push("Label_Actions");
        resourceVars.push("Label_Status");
        resourceVars.push("Label_Enabled");
        resourceVars.push("Label_Disabled");
        resourceVars.push("ButtonLabel_ViewEdit");
        resourceVars.push("ButtonLabel_New");
        resourceVars.push("Datatables_language_lengthMenu");
        resourceVars.push("Datatables_language_zeroRecords");
        resourceVars.push("Datatables_language_info");
        resourceVars.push("Datatables_language_infoEmpty");
        resourceVars.push("Datatables_language_infoFiltered");
        resourceVars.push("Datatables_language_search");
        resourceVars.push("Datatables_language_paginate_previous");
        resourceVars.push("Datatables_language_paginate_next");
        resourceVars.push("Label_DeliveryTime");
        resourceVars.push("Label_PayMethod");
        resourceVars.push("Label_Limit");
        resourceVars.push("Label_RouteType");
        resourceVars.push("Title_Courier");
        resourceVars.push("Label_Country");
        resourceVars.push("Title_City");
        resourceVars.push("Title_State");
        resourceVars.push("Label_SelectOption");
        resourceVars.push("Label_Options");
        resourceVars.push("Label_Cities");
        resourceVars.push("Label_All");
        resourceVars.push("Label_LookCities");
        resourceVars.push("Label_DeleteRoute");
        resourceVars.push("ButtonLabel_Cancel");
        resourceVars.push("Label_Yes");
        resourceVars.push("SuccessMessage_DataSavedSuccessfully");
        resourceVars.push("Label_Success");
        resourceVars.push("Label_FilterOff");
        resourceVars.push("Label_Filter");
        resourceVars.push("Label_Sort");
        resourceVars.push("Label_Hide");
        resourceVars.push("Label_ShowColumns");
        resourceVars.push("Label_OrderAsc");
        resourceVars.push("Label_OrderDesc");
        resourceVars.push("Label_Unsorting");
        resourceVars.push("Label_Columns");
        resourceVars.push("Label_Delete");
        resourceVars.push("Label_GridValue");
        resourceVars.push("Label_ValueFilter");
        resourceVars.push("Label_And");
        resourceVars.push("Label_Or");
        resourceVars.push("Label_Operator");
        resourceVars.push("Label_NoRows");
        resourceVars.push("Label_SelectColumns");
        resourceVars.push("Label_OperatorAfter");
        resourceVars.push("Label_OperatorBefore");
        resourceVars.push("Label_OperatorContains");
        resourceVars.push("Label_OperatorEnds");
        resourceVars.push("Label_OperatorEquals");
        resourceVars.push("Label_OperatorIs");
        resourceVars.push("Label_OperatorIsEmpty");
        resourceVars.push("Label_OperatorIsNotEmpty");
        resourceVars.push("Label_OperatorIsNot");
        resourceVars.push("Label_OperatorIsOnOrAfter");
        resourceVars.push("Label_OperatorIsOnOrBefore");
        resourceVars.push("Label_OperatorStarts");
        resourceVars.push("Label_ReorderColumns");
        resourceVars.push("Label_HideAll");
        resourceVars.push("Label_ShowAll");
        resourceVars.push("Label_FindColumn");
        resourceVars.push("Label_TitleColumn");
        resourceVars.push("Label_addFilter");
        resourceVars.push("Label_Export");
        resourceVars.push("Label_ImportRoute");
        const headers = {
            'Content-Type': 'application/json'
        };

        axios.post(window.config.REACT_APP_SECURITY_API_URL + '/languageresource/getbyvars/' + helpers.getUiLanguage(), resourceVars, {
            headers: headers
        })
            .then((response) => {
                const data = response.data.data;
                this.setState({ resources: data});
            })
            .catch((error) => {
                console.log(error);
            });
    }
}

function ImportRoute(values) {
    
    const [open, setOpen] = React.useState(false);
    const [filter, setFilter] = React.useState('');
    const [clients, setClient] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [searchError, setSearchError] = React.useState(false);
    const [noMatches, setNoMatches] = React.useState(false);
    const [error, setError] = React.useState("");
    const [file, setFile] = useState();
    //const [data, setData] = useState([]);
    const [array, setArray] = useState([]);

  const fileReader = new FileReader();

  const items = [];


  /*const headers2 = {
    'Content-Type': 'application/json',
    'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME)
  }*/

  const handleOnChange = (e) => {
      if(e.target.files.length > 0)
      {
        setFile(e.target.files[0]);
        fileReader.onload = async function (event) {
            const text = event.target.result;
            let data = csvFileToArray(text);
            /*array.map((item) => (
                  Object.values(item).map((val) => {
                    let result = val.split(';');
                    const mapCourier = {
                        id_pais: result[1],
                        pais: result[2],
                        id_provincia: result[3],
                        provincia: result[4],
                        id_formaPago: result[5],
                        forma_pago: result[6],
                        monto_limite: result[7],
                        id_courier: result[8],
                        courier: result[9],
                        tiempo_entrega: result[10],
                        tipo_ruta: result[11],
                        id_localidades: result[12]
    
                    };
                    
                  })
              ));*/
          };
    
          fileReader.readAsText(e.target.files[0]);
      }
      else
        {
            helpers.notifyError(values.data.resources.Label_Error, "No se exporto ningun archivo o no tiene items para Importar");
        }
  };

  const csvFileToArray = string => {
    const csvHeader = string.slice(0, string.indexOf("\n")).split(";");
    const csvRows = string.slice(string.indexOf("\n") + 1).split("\n");

    const array = csvRows.map(i => {
      const values = i.split(";");
      const obj = csvHeader.reduce((object, header, index) => {
        object[header] = values[index];

        return object;
      }, {});
      items.push(obj);
      return obj;
    });

    setArray(array);
  };

 

  const handleOnSubmit = async (e) => {
    e.preventDefault();

    console.log(items);
    console.log(array);
    if (array.length > 0)
    {
        this.setState({ loading: true, importOK: 1 });
        for (let i = 0; i < array.length; i++)
              {
                if(array[i].id_pais != undefined && array[i].id_provincia != undefined && array[i].id_courier != undefined && array[i].id_formaPago != undefined)
                {
                    const mapCourier = {
                        id_pais: array[i].id_pais,
                        pais: array[i].pais,
                        id_provincia: array[i].id_provincia,
                        provincia: array[i].provincia,
                        id_formaPago: array[i].id_formaPago,
                        forma_pago: array[i].forma_pago,
                        monto_limite: array[i].monto_limite,
                        id_courier: array[i].id_courier,
                        courier: array[i].courier,
                        tiempo_entrega: array[i].tiempo_entrega,
                        tipo_ruta: array[i].tipo_ruta,
                        id_localidades: array[i].id_localidades
                    };
                    items.push(mapCourier);
                }
              }
        const data = 
            {
                userId: values.data.userId,
                couriers: items
            }
            const headers2 = {
                'Content-Type': 'application/json',
                'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME)
            };
            axios.post(window.config.REACT_APP_PRODUCTS_API_URL + '/mapcouriercity/insertallcsv', data, {
                headers: headers2
            })
                .then((response) => 
                {
                    console.log(response);
                    const val = response.data.data;
                    this.setState({ loading: false, resultImport: response.data.data });
                    if(val.length == 1)
                    {
                        if(val[0].linea == 0)
                        {
                            this.setState({ importOK: 2, mensajeOK: val[0].detalle });
                            setTimeout(() => {
                                this.setState({ loading: false, resultImport: [], importOK: 0 });
                                handleClose();    
                            }, 3000)
                        }
                        else if(val[0].linea == -1)
                        {
                            this.setState({  importOK: 3 , mensajeOK: val[0].detalle });
                            setTimeout(() => {
                                this.setState({ loading: false, resultImport: [], importOK: 0 });
                                handleClose();    
                            }, 3000)
                        }
                        else
                        {
                            this.setState({  importOK: 4 });
                            setTimeout(() => {
                                this.setState({ loading: false, resultImport: [], importOK: 0 });
                                handleClose();   
                            }, 3000)
                        }

                    }
                    else
                    {
                        this.setState({  importOK: 4 });
                        setTimeout(() => {
                            this.setState({ loading: false, resultImport: [], importOK: 0 });
                            handleClose();   
                        }, 4000)
                    }
                    
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({ loading: false, importOK: 0 });
                    let msg = values.data.resources.ErrorMessage_UnknownError;
                    if (error.response.data.error_code == window.errorCodes.DuplicatedEntityName)
                        msg = values.data.resources.ErrorMessage_DuplicatedName;
    
                    helpers.notifyError(values.data.resources.Label_Error, msg);
                    this.setState({ loading: false, resultImport: [], importOK: 0});
                });
    }
    else
    {
        helpers.notifyError(values.data.resources.Label_Error, "No se exporto ningun archivo o no tiene items para Importar");
    }
  };


  const headerKeys = Object.keys(Object.assign({}, ...array));


     const allowedExtensions = ["csv"];
   
     /*const handleOnChange = (e) => {
      // console.log(e.target);
       setFile(e.target.files[0]);
      // console.log(e.target.files[0])
     };*/

     /*const handleOnChange = (e) => {
        const files = e.target.files;
        setFile(e.target.files[0]);

        // Check if user has entered the file
        if (e.target.files.length) {
            const inputFile = e.target.files[0];
             
            // Check the file extensions, if it not
            // included in the allowed extensions
            // we show the error
            const fileExtension = inputFile?.type.split("/")[1];
            if (!allowedExtensions.includes(fileExtension)) {
                setError("Please input a csv file");
                return;
            }
 
            // If input type is correct set the state
            setFile(inputFile);
        }

        /*console.log(file);
        if (file) {
          fileReader.onload = function (event) {
            const text = event.target.result;
           console.log(event);
           console.log(event.target.result);
            csvFileToArray(text);
          };
    
          fileReader.readAsText(file);
        }
        console.log("OK1");*/
        /*if (files) {
          console.log(files[0]);
          Papa.parse(files[0], {
            complete: async function(results) {
                let maps = [];
              console.log("Finished:", results.data[1][0]);
              for (let i = 1; i < results.data.length; i++)
              {
                const mapCourier = {
                    id_pais: results.data[i][1],
                    pais: results.data[i][2],
                    id_provincia: results.data[i][3],
                    provincia: results.data[i][4],
                    id_formaPago: results.data[i][5],
                    forma_pago: results.data[i][6],
                    monto_limite: results.data[i][7],
                    id_courier: results.data[i][8],
                    courier: results.data[i][9],
                    tiempo_entrega: results.data[i][10],
                    tipo_ruta: results.data[i][11],
                    id_localidades: results.data[i][12],
                    estado_mapa: results.data[i][13]

                };
                items.push(mapCourier);
              }
              
            }}
        
        )}
      }*/

     /*const csvFileToArray = string => {
        var array = string.toString().split(" ")
       //  console.log(array); here we are getting the first rows which is our header rows to convert it into keys we are logging it here
         var data = []
         // console.log(data);
         for(const r of array){
           // console.log(r);
             let row = r.toString().split(",")
             data.push(row)
         }
         console.log(data)
         var heading = data[0]
         // console.log(heading); to get the column headers which will act as key
         var ans_array = []
         // console.log(ans_array);
         for(var i=1;i<data.length;i++){
             var row = data[i]
             var obj = {}
             for(var j=0;j<heading.length;j++){
                 if(!row[j]){
                     row[j]="NA";
                 }
                 // console.log(row[j].toString())
                 obj[heading[j].replaceAll(" ","_")] = row[j].toString().replaceAll(" ","_")
             }
             ans_array.push(obj)
         }
         console.log({ans_array})
      };*/

      /*const headers2 = {
        'Content-Type': 'application/json',
        //'Authorization': this.getToken()
    };*/
    
      /*const handleOnSubmit = async(e) => {
        e.preventDefault();

        if (!file) return setError("Enter a valid file");
 
        // Initialize a reader which allows user
        // to read any file or blob.
        const reader = new FileReader();
         
        // Event listener on reader when the file
        // loads, we parse it and set the data.
        reader.onload = async ({ target }) => {
            const csv = Papa.parse(target.result, { header: true });
            const parsedData = csv?.data;
            const columns = Object.keys(parsedData[0]);
            for (let i = 0; i < parsedData.length; i++)
            {
                console.log(parsedData[i].id_pais);
            }
            setData(columns);
            console.log(data);
        };
        reader.readAsText(file);

        console.log(data);

        console.log(values);
        console.log(this.state);
        const data = 
        {
            userId: this.state.userId,
            couriers: items
            
        }
        const headers2 = {
            'Content-Type': 'application/json',
            'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME)
        };
        console.log(data);
        if(items.length > 0)
        {
            
            this.setState({ loading: true, importOK: false });
        axios.post(window.config.REACT_APP_PRODUCTS_API_URL + '/mapcouriercity/insertallcsv', data, {
            headers: headers2
        })
            .then((response) => 
            {
                console.log(response);
                this.setState({ loading: false, resultImport: response.data.data, importOK: true });
            })
            .catch((error) => {
                console.log(error);
                this.setState({ loading: false, importOK: false });
                let msg = values.data.resources.ErrorMessage_UnknownError;
                if (error.response.data.error_code == window.errorCodes.DuplicatedEntityName)
                    msg = values.data.resources.ErrorMessage_DuplicatedName;

                helpers.notifyError(values.data.resources.Label_Error, msg);
                this.setState({ loading: false, resultImport: [], importOK: true});
            });
        }
        else
        {
            helpers.notifyError(values.data.resources.Label_Error, "No se exporto ningun archivo o no tiene items para Importar");
        }
    
        
      };*/

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setClient([]);
        setOpen(false);
    };
  
   const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '50%',
        height: '90%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };
    const headers = {
            'Content-Type': 'application/json',
            'Authorization': this.state.tokenProducts
        };

    // const setClientValues = (client) => {
    //     //console.log(client);
        
            
    //     this.setState({
    //         dataExport: [],
    //         dataResult: []
    //     });
    //     const headers = {
    //         'Content-Type': 'application/json',
    //         'Authorization': this.state.tokenProducts
    //     };
    //     axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getcitiesbystate/'+ client.stateid,
    //     {
    //         'headers': headers
    //     })
    //     .then(response => {
    //         const data = response.data.data;
    //         data.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
    //         this.setState({ cities: data, loading: false });
    //         // order alphabetically
    //         this.state.cities.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name));
            
    //         data.map(city =>{
    //             if(client.stateid == city.state_id && city.id == client.cityid)
    //             {
    //                 this.setState({ 
    //                     postalCode: city.cp
    //                 });
    //             }
    //         })
    //     })
    //     .catch(function (error) {
    //         console.log(error);
    //     });

    //     document.querySelector("#mailError").style.display = 'none';
    //     document.querySelector("#phoneError").style.display = 'none';
    //     document.querySelector("#mobilephoneError").style.display = 'none';
    //     document.querySelector("#nameError").style.display = 'none';
    //     document.querySelector("#lastNameError").style.display = 'none';
    //     document.querySelector("#documentTypeError").style.display = 'none';
    //     document.querySelector("#cityError").style.display = 'none';
    //     document.querySelector("#stateError").style.display = 'none';
    //     document.querySelector("#sexError").style.display = 'none';
    //     document.querySelector("#addressError").style.display = 'none';
    //     document.querySelector("#addressNumberError").style.display = 'none';
    //     document.querySelector("#errorBtnDestination").style.display = 'none';
    //     document.querySelector('#btnInfoDestination').classList.remove('btn-danger');
    //     document.querySelector('#btnInfoDestination').classList.add('btn-primary');
    //     document.querySelector("#errorBtnClient").style.display = 'none';
    //     document.querySelector('#btnInfoClient').classList.remove('btn-danger');
    //     document.querySelector('#btnInfoClient').classList.add('btn-primary');
    //     handleClose();
    // }

    const filterClients = (e) => {
        if(filter !== ''){
            /*setLoading(true);
            axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getclientsfull/' + filter,
            {
                'headers': headers
            })
            .then(response => {
                const data = response.data.data;
                setClient(data);
                setLoading(false);
                if(data.length < 1){
                    setNoMatches(true);
                }else{
                    setNoMatches(false);
                }
            })
            .catch(function (error) {
                console.log(error);
                setLoading(false);
            });*/
        }else{
            setNoMatches(false);
            setSearchError(true);
        }
    }

    const onChangeFilter = (e) => {
        if(e.target.value !== ''){
            setSearchError(false);
            setNoMatches(false);
            setFilter(e.target.value);
        }else{
            setFilter('');
            setNoMatches(false);
        }
    }
    
    const handleEnter = (e) => {
        if(e.target.value !== ''){
            setNoMatches(false);
            setFilter(e.target.value);
            filterClients();
        }else{
            setSearchError(true);
            setNoMatches(false);
        }
    }
    
  return (
    
    <div className="card-header">
          <button className="btn btn-success" onClick={handleOpen}>
              {this.state.resources.Label_ImportRoute}
          </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <Typography id="modal-modal-title" variant="h6" component="h2" className='text-center'>
                <th>{values.data.resources.Label_ImportRoute}</th>
            </Typography>
            {searchError ? <p style={{color: 'red', fontSize: '0.95rem'}}>{localStorage.getItem('WspLanguage') == 'language0' ? 'Enter a valid value for the search' : 'Ingrese un valor válido para la búsqueda'}</p>: <></>}
            {noMatches && <p style={{color: 'red', fontSize: '0.95rem'}}>{localStorage.getItem('WspLanguage') == 'language0' ? 'No matches found' : 'No se encontraron coincidencias'}</p>}
          </div>
          <hr></hr>
          <div className="dis-flex col-md-12 my-3">
              <div className='col-md-7'>
         <input
             type={"file"}
             className="form-control"
             id={"csvFileInput"}
             accept={".csv"}
             onChange={handleOnChange}
           />
                 </div>
                 {this.state.importOK  == 0 ? <div>
              <button className="btn btn-success"
             onClick={(e) => {
               handleOnSubmit(e);
             }}
           >
             Cargar
           </button>
           &nbsp; &nbsp;  
           <button className="btn btn-danger"
             onClick={(e) => {
                this.setState({ loading: false, resultImport: [], importOK: 0 });
                handleClose();
                
             }}
           >  
             Cancelar
           </button>              
           </div> : <></>}
           {this.state.importOK ? <div><button className="btn btn-danger"
             onClick={(e) => {
                this.setState({ loading: false, resultImport: [], importOK: 0 });
                handleClose();
                
             }}
           >  
             Cerrar 
           </button>              
           </div>  : <></>}
          </div>
          {this.state.importOK === 1 ? <div className="text-center" style={{position: 'absolute', top: '50%', left: '50%'}}><CircularProgress size={'3.6rem'}/></div> : <></>}
          {this.state.importOK === 2 || this.state.importOK === 3 ? <div><h4 style={{textAlign: "center"}}>Importacion Realizada con Exito</h4><br/><h4 style={{textAlign: "center"}}>{this.state.mensajeOK}</h4></div> : <></> }
          {this.state.importOK === 4 ? <div><h4 style={{textAlign: "center"}}>Archivo con Errores</h4><br/><h4 style={{textAlign: "center"}}>{this.state.mensajeOK}</h4></div>  : <></> }
            {this.state.importOK === 4 ? 
            <div style={{height: "80%", overflowY: 'auto' }}>
            <>
            
                <table className='table datatable my-table' aria-labelledby="tabelLabel">
                    <thead>
                        <tr>
                        <th>Linea</th>
                        <th>Descripcion</th>
                        </tr>
                    </thead>
                    <tbody>{/**values.data.clients */}
                        {/*this.state.importOK && this.state.resultImport.length == 0 ? <div className="text-center" style={{position: 'absolute', top: '50%', left: '50%'}}><CircularProgress size={'3.6rem'}/></div> :*/ 
                        <>
                        {this.state.resultImport.map(client =>
                            // values.data.documentTypeId == client.document_typeid && values.data.documentNumber == client.document_number ?
                            <tr>
                                <td>{client.linea}</td>
                                <td>{client.detalle}</td>
                                </tr>
                                // : <></>
                        )}</>}
                    </tbody>
                </table>
            </>

        </div>  : <></>}
                  
        </Box>
      </Modal>
    </div>
  );
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function BasicModal(values) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleSearch = (e) => {
    //   console.log(e.target.value);
      resutSearch = [];
      for (let i = 0; i < citiesModal.length; i++){
          if (e.target.value === '' || e.target.value === ' ') {
                resutSearch = []
            }
            if (citiesModal[i].toString().toLowerCase().includes(e.target.value) && e.target.value !== '' && e.target.value !== ' ') {
                
                resutSearch.push(citiesModal[i])
                
            }
      }
    //   console.log(resutSearch);
      document.getElementById("myResult").textContent = resutSearch[0];
  }
  
    let resutSearch = [];
    // console.log(values);

    let citiesIds = values.cities.split(',');
    let citiesModal = [];
    for (let i = 0; i < citiesIds.length; i++) {
        for (let c = 0; c < values.citiesList.length; c++) {
            if(citiesIds[i] == values.citiesList[c].id) {
                citiesModal.push(values.citiesList[c].name);
            }
        }
    }


  return (
    <div>
      <Button onClick={handleOpen} style={{color: 'black'}}>{values.resc.Label_LookCities}</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2" className='text-center'>
            {values.resc.Label_Cities}
          </Typography>
          <hr></hr>
          <div>
                <TextField id="standard-basic" style={{ padding: '10px' }} label="Buscar" onChange={handleSearch}/>
          </div>
            <div>
                <div className="text-center p-3" >
                          <p id="myResult" className="selected" style={{fontSize: '1.2rem'}}></p>
                </div>
            </div>
                  
            <div style={{padding: '10px'}}>
                {citiesModal.map(city => (
                    <li style={{fontSize: '1.2rem'}}>{city}</li>)
                )}
            </div>
        </Box>
      </Modal>
    </div>
  );
}

function DeleteModal(values) {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    // console.log(values)
    
    const handleSubmit = async() => {
         const data = {
            id: values.item.id,
            country_id: values.item.country_id,
            state_id: values.item.state_id,
            waypay: values.item.waypay,
            limit: values.item.limit,
            courier_id: values.item.courier_id,
            delivery_time: values.item.delivery_time,
            route_type: values.item.route_type,
            cities: values.item.cities,
            enabled: false,
            last_update_user_id: values.item.userId,
      };
      const headers = {
            'Content-Type': 'application/json',
            'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME)
        };
        axios.post(window.config.REACT_APP_PRODUCTS_API_URL + '/mapcouriercity/save', data, {
            headers: headers
        })
            .then((response) => {
                // console.log(response);
                handleClose();
                helpers.notifySuccess(values.resc.Label_Success, values.resc.SuccessMessage_DataSavedSuccessfully);
                window.history.back();
                setTimeout(() => {
                    window.history.forward();    
                }, 1000)
                
            })
            .catch((error) => {
                console.log(error);

                let msg = 'Error';
                helpers.notifyError(values.resc.Label_Error, msg);
            });
    }

    return (
    <div>
      <Button onClick={handleOpen} className="mt-2" style={{color: 'black'}}><i className="fa fa-trash"></i></Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2" className='text-center'>
            {values.resc.Label_DeleteRoute}
          </Typography>
          <hr></hr>
            <div>
                <div style={{display: 'flex',justifyContent: 'space-evenly'}}> {/** */}
                    <button className="btn btn-primary" onClick={handleSubmit} >{values.resc.Label_Yes}</button>
                    <button className="btn btn-danger"  onClick={handleClose}>{values.resc.ButtonLabel_Cancel}</button>
                </div>
            </div>
        </Box>
      </Modal>
    </div>
  );
}

function MapCourierCityDataGrid(values) {

    let items = values.data.items,
        resources = values.data.resources,
        permissionsIds = values.data.permissionsIds, columns = values.data.columns,
        sorting = values.data.sorting, paging = values.data.paging ? values.data.paging : 0
        , filter = localStorage.getItem('CopyMapCourierCityListFiltering') ?
            JSON.parse(localStorage.getItem('CopyMapCourierCityListFiltering')) : values.data.filtering
    
    const [page, setPage] = React.useState(paging.page);
    const [pageSize, setPageSize] = React.useState(paging.pageSize);
    
    const [value, setValue] = React.useState([null, null]);
    const [myRows, setRowsValue] = React.useState(values.data.items);
    const [filtering, setFilter] = React.useState(filter);
    const [states, setStates] = React.useState([]);
    const [state_id, onChangeState] = React.useState([null, null]);
    const [countries, setCountries] = React.useState([]);
    const [country_id, onChangeCountry] = React.useState([null, null]);
    const [route_type_id, onChangeRouteType] = React.useState([null, null]);
    const [waypay_id, onChangeWayPay] = React.useState([null, null]);
    const [couriers, setCouriers] = React.useState([]);
    const [courier_id, onChangeCourier] = React.useState([null, null]);
    const [open, setOpen] = React.useState(false);
    const openModal = () => setOpen(true);
    const closeModal = () => setOpen(false);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 1300,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    async function getCountries() {
        // console.log(status)
            helpers.showLoadingOverlay();
            axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/country/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                let data = response.data.data.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                setCountries(data)
                helpers.hideLoadingOverlay();
             })
            .catch(err => console.log(err));
    }
    async function getStates() {
        // console.log(status)
            helpers.showLoadingOverlay();
            axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/state/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
                .then(response => {
                let data = response.data.data.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                setStates(data)
                helpers.hideLoadingOverlay();
             })
            .catch(err => console.log(err));
    }
    async function getCouriers() {
        // console.log(status)
            helpers.showLoadingOverlay();
            axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/courier/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
                .then(response => {
                let data = response.data.data.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                setCouriers(data)
                helpers.hideLoadingOverlay();
             })
            .catch(err => console.log(err));
    }

    function setInitial() {
        if (countries.length < 1) {
            getCountries();
        }
        if (states.length < 1) {
            getStates();
        }
        if (couriers.length < 1) {
            getCouriers();
        }
        setTimeout(() => {
            if (document.getElementsByClassName('MuiTablePagination-selectLabel')[0]){
                document.getElementsByClassName('MuiTablePagination-selectLabel')[0].innerHTML = '';
            }
            // loading = true
            if (myRows.length < 1) {
                if (localStorage.getItem('MapCourierCityFilterItems')) {
                    let myItems = JSON.parse(localStorage.getItem('MapCourierCityFilterItems'));
                    setRowsValue(myItems);
                    setTimeout(() => {
                        localStorage.removeItem('MapCourierCityFilterItems');
                    }, 1000);
                } else {
                    setRowsValue(items)
                }
                
            }
        }, 1000)
       
    }
    setInitial()

    const handlePageChange = (newPage) => {
        setPage(newPage);
    };

    const handlePageSizeChange = (newPage) => {
        setPageSize(newPage);
    };

    const handleFilterChange = (newFilter) => {
        // console.log(newFilter)
        setFilter(newFilter);
    }

    const handleStates = (e) => {
        e.preventDefault();
        onChangeState(e.target.value);
        // console.log(e.target.value)
    }

    const handleCountry = (e) => {
        e.preventDefault();
        onChangeCountry(e.target.value);
        // console.log(e.target.value)
    }

    const handleRouteType = (e) => {
        e.preventDefault();
        onChangeRouteType(e.target.value);
        // console.log(e.target.value)
    }

    const handleWayPay = (e) => {
        e.preventDefault();
        onChangeWayPay(e.target.value);
    }

    const handleCourier = (e) => {
        e.preventDefault();
        onChangeCourier(e.target.value);
    }

    async function onReloadData() {
        let filterData = {
            country_id: country_id,
            state_id: state_id,
            waypay: waypay_id,
            courier_id: courier_id,
            route_type: route_type_id,
        };
        console.log(filterData)
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME)
        };
        // console.log(headers);
        helpers.showLoadingOverlay();
        axios.post(window.config.REACT_APP_PRODUCTS_API_URL + '/mapcouriercity/getbyfilter', filterData,
            {
                headers: headers
            })
            .then(response => {
                let newItems = response.data.data;
                // console.log(response.data.data)
                setRowsValue(newItems);
                localStorage.removeItem('MapCourierCityFilterItems');
                localStorage.setItem('MapCourierCityFilterItems', JSON.stringify(newItems));
                closeModal();
                helpers.hideLoadingOverlay();
             })
            .catch(error => {
                console.log(error)
                helpers.hideLoadingOverlay();
            });
    }

    async function resetFilter() {
        helpers.showLoadingOverlay();
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/mapcouriercity/getall',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                let newItems = response.data.data;
                setRowsValue(newItems);
                localStorage.removeItem('MapCourierCityFilterItems');
                closeModal();
                helpers.hideLoadingOverlay();
             })
            .catch(error => console.log(error));
    }

    return (
        <>
        <button className="btn btn-success mb-1" onClick={openModal}><i class="fas fa-filter"></i></button>
        <Modal
            open={open}
            onClose={closeModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <div className="dis-flex m-1" style={{ justifyContent: 'space-beetween' }}>
                    {countries.length > 0 && 
                    <div className="px-1">
                        <label className="label-text">{values.data.resources.Label_Country}</label>
                        <select className="form-control" data-val="true" name="countryId" value={country_id} onChange={handleCountry}>
                            <option value="">{values.data.resources.Label_SelectOption}</option>
                            {countries.map(country =>
                                <option key={country.id} value={country.id}>{country.name}</option>
                            )}
                        </select>
                    </div>}
                    <div className="px-1">
                        <label className="label-text">{values.data.resources.Label_Status}</label>
                        <select className="form-control" data-val="true" name="productId" value={state_id} onChange={handleStates}>
                            <option value="">{values.data.resources.Label_SelectOption}</option>
                            {states.map(state =>
                                <option key={state.id} value={state.id}>{state.name}</option>
                            )}
                        </select>
                    </div>
                    <div className="px-1">
                        <label className=" form-label">{values.data.resources.Label_RouteType}</label>
                        <select className="form-control" data-val="true" name="route_type" value={route_type_id} onChange={handleRouteType}>
                            <option value="">{values.data.resources.Label_SelectOption}</option>
                            <option value="principal">Ruta Principal</option>
                            <option value="exception">Ruta Excepción</option>
                            <option value="blocking">Ruta Bloqueo</option>
                        </select>
                    </div>
                    <div className="px-1">
                        <label className=" form-label">{values.data.resources.Label_PayMethod}</label>
                        <select className="form-control" data-val="true" name="waypay" value={waypay_id} onChange={handleWayPay}>
                            <option value="">{values.data.resources.Label_SelectOption}</option>
                            <option value="all">{values.data.resources.Label_All}</option>
                        </select>
                    </div>    
                    <div className="px-1">
                        <label className=" form-label">{values.data.resources.Title_Courier}</label>
                        <select disabled={country_id < 1} className="form-control" data-val="true" name="courier_id" value={courier_id} onChange={handleCourier}>
                            <option value="">{values.data.resources.Label_SelectOption}</option>
                            {couriers.map(courier =>
                                courier.country_id == country_id &&
                                <option key={courier.id} value={courier.id}>{courier.name}</option>
                            )}
                        </select>
                    </div>
                        
                        <br></br>
                        <div className="mt-4">
                            <button className="btn btn-primary mx-1 my-auto" onClick={onReloadData}>{values.data.resources.Label_Filter}</button>
                            <button className="btn btn-danger mx-1 my-auto" onClick={resetFilter}>{values.data.resources.Label_FilterOff}</button>
                        </div>
                </div>
            </Box>
        </Modal>
            <div style={{ height: 450, width: '99%' }}>
                <DataGridPro rows={myRows} columns={columns} rowsPerPageOptions={[5, 10, 25, 50, 100]} density='compact'
                page={page}
                pagination
                localeText={{
                    'columnMenuFilter': values.data.resources.Label_Filter,
                    'columnHeaderSortIconLabel': values.data.resources.Label_Sort,
                    'columnMenuHideColumn': values.data.resources.Label_Hide,
                    'columnMenuShowColumns': values.data.resources.Label_ShowColumns,
                    'columnMenuSortAsc': values.data.resources.Label_OrderAsc,
                    'columnMenuSortDesc': values.data.resources.Label_OrderDesc,
                    'columnMenuUnsort': values.data.resources.Label_Unsorting,
                    'filterPanelColumns': values.data.resources.Label_Columns,
                    'filterPanelDeleteIconLabel': values.data.resources.Label_Delete,
                    'filterPanelInputLabel': values.data.resources.Label_GridValue,
                    'filterPanelInputPlaceholder': values.data.resources.Label_ValueFilter,
                    'filterPanelOperatorAnd': values.data.resources.Label_And,
                    'filterPanelOperatorOr': values.data.resources.Label_Or,
                    'filterPanelOperators': values.data.resources.Label_Operator,
                    'noResultsOverlayLabel': values.data.resources.Label_NoRows,
                    'noRowsLabel': values.data.resources.Label_NoRows,
                    'toolbarColumns': values.data.resources.Label_Columns,
                    'toolbarColumnsLabel': values.data.resources.Label_SelectColumns,
                    'errorOverlayDefaultLabel': "Error",
                    'filterOperatorAfter': values.data.resources.Label_OperatorAfter,
                    'filterOperatorBefore': values.data.resources.Label_OperatorBefore,
                    'filterOperatorContains': values.data.resources.Label_OperatorContains,
                    'filterOperatorEndsWith': values.data.resources.Label_OperatorEnds,
                    'filterOperatorEquals': values.data.resources.Label_OperatorEquals,
                    'filterOperatorIs': values.data.resources.Label_OperatorIs,
                    'filterOperatorIsEmpty': values.data.resources.Label_OperatorIsEmpty,
                    'filterOperatorIsNotEmpty': values.data.resources.Label_OperatorIsNotEmpty,
                    'filterOperatorNot': values.data.resources.Label_NoRows,
                    'filterOperatorOnOrAfter': values.data.resources.Label_OperatorIsOnOrAfter,
                    'filterOperatorOnOrBefore': values.data.resources.Label_OperatorIsOnOrBefore,
                     'filterOperatorStartsWith': values.data.resources.Label_OperatorStarts,
                    'columnsPanelDragIconLabel': values.data.resources.Label_ReorderColumns,
                    'columnsPanelHideAllButton': values.data.resources.Label_HideAll,
                    'columnsPanelShowAllButton': values.data.resources.Label_ShowAll,
                    'columnsPanelTextFieldLabel': values.data.resources.Label_FindColumn,
                    'columnsPanelTextFieldPlaceholder': values.data.resources.Label_TitleColumn,
                    'filterPanelAddFilter': values.data.resources.Label_addFilter
                }}    
                pageSize={pageSize}
                onStateChange={(state) => {
                    localStorage.setItem('MapCourierCityListPagination', JSON.stringify(state.pagination))
                    localStorage.setItem('MapCourierCityListSorting', JSON.stringify(state.sorting))
                    localStorage.setItem('MapCourierCityListFiltering', JSON.stringify(state.filter))
                }}
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
                state={
                        filter != null ? {filter: filter} : {}
                        
                }
                      
            />
        </div>
    </>
    );
}
