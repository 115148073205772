import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';  
import $ from 'jquery';
import DataTable from 'datatables.net';
import helpers from '../../Helpers';
import '../../custom.css';
import { DataGrid, useGridApiRef, esES } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { DataGridPro } from '@mui/x-data-grid-pro';

import { LicenseInfo } from '@mui/x-data-grid-pro';

LicenseInfo.setLicenseKey(
  '97124f3444ae3cf552c73ef2f3487b08T1JERVI6MzEzNDQsRVhQSVJZPTE2NjY1MzkzOTkwMDAsS0VZVkVSU0lPTj0x',
);
export class CampaingList extends Component {
    static displayName = CampaingList.name;

    constructor(props) {
        super(props);

        CampaingFilter = CampaingFilter.bind(this);

        this.state = {
            items: [],
            resources: [],
            loading: true,
            permissionsIds: [],
            columns: [],
            paging: null,
            sorting: null,
            filtering: null,
            countries: [],
            countryId: null,
            enabled: null,
            productcountries: [],
            productCountryId: null,
            agencies: [],
            agencyId: null,
            medias: [],
            mediaId: null
        };
    }

    componentDidMount() {
        let userData = helpers.getUserData();
        this.state.permissionsIds = userData.permission_ids;
        this.getResources();
        this.populateData();

        helpers.setNavActive("#nav-campaing");

        this.state.sorting = JSON.parse(localStorage.getItem('CopyCampaingListSorting'));
        this.state.paging = JSON.parse(localStorage.getItem('CopyCampaingListPaging'));
        this.state.filtering = JSON.parse(localStorage.getItem('CopyCampaingListFiltering'));
    }

    static renderTable(items, resources, permissionsIds) {
          return (
                <table className='table datatable my-table' aria-labelledby="tabelLabel"> {/*table class:* table-striped datatable */}
                    <thead>
                        <tr>
                          <th>{resources.Label_ID}</th>
                          <th>{resources.Label_Name}</th>
                          <th>{resources.Label_Country}</th>
                          <th>{resources.Title_Agency}</th>
                          <th>{resources.Title_Media}</th>
                          <th>DID</th>
                          <th>Landing</th>
                          <th>{resources.Label_Status}</th>
                          <th className="text-center">{resources.Label_Actions}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            items.map(item =>
                                <tr key={item.id}>
                                    <td>{item.id}</td>
                                    <td>{item.name}</td>
                                    <td>{item.country_name}</td>
                                    <td>{item.agency_name}</td>
                                    <td>{item.media_name}</td>
                                    <td>{item.did_name}</td>
                                    <td>{item.url_landing}</td>
                                    <td>{item.enabled == true ? resources.Label_Enabled : resources.Label_Disabled}</td>
                                    <td className="text-center">
                                        {permissionsIds.includes(131) ?
                                        <Link to={"/campaing-detail/" + item.id} className="btn btn-default"><i className="fas fa-edit"></i></Link>
                                        : 
                                         <td className="text-center">
                                            <Link to={"/campaing-view/" + item.id} className="btn btn-default"><i class="fas fa-eye"></i></Link>
                                        </td>
                                        }
                                    </td>
                                </tr>
                            )}
                    </tbody>
                </table>
        );
      }

    //   render() {
    //       let contents = this.state.loading
    //           ? helpers.showLoadingOverlay()
    //           : MediaList.renderTable(this.state.items, this.state.resources, this.state.permissionsIds);

    //       return (
    //           <div className="mt-2">
    //               {/* <h1 className="mt-4">{this.state.resources.Title_Areas}</h1> */}
    //               <ol className="breadcrumb my-4">
    //                   <li className="breadcrumb-item active">{this.state.resources.Title_Campaing}</li>
    //               </ol>
    //               <div className="card">
    //                   <div className="card-header">
    //                       {this.state.permissionsIds.includes(130) ?
    //                       <Link to={"/campaing-create"} className="btn btn-success"><i className="fas fa-plus" aria-hidden="true"></i> {this.state.resources.ButtonLabel_New}</Link>
    //                         : <></>}
    //                     </div>
    //                     <CampaingFilter />
    //                   <div className="card-body">
    //                       <div className="table-responsive">
    //                           {contents}
    //                       </div>
    //                   </div>
    //               </div>
    //           </div>
    //     );
    //   }

      render() {
          let contents = this.state.loading
              ? helpers.showLoadingOverlay()
              : <CampaingDataGrid data={this.state}/>
            //   : CampaingList.renderTable(this.state.items, this.state.resources, this.state.permissionsIds, this.props);

          return (
              <div className="mt-2">
                  <ol className="breadcrumb my-4">
                      <li className="breadcrumb-item active">{this.state.resources.Title_Campaing}</li>
                  </ol>
                  <div className="card">
                      {this.state.permissionsIds.includes(130) ?
                      <div className="card-header dis-flex" style={{ display: 'flex' , justifyContent: 'space-between'}}>
                          <Link to={"/campaing-create"} className="btn btn-success"><i className="fas fa-plus" aria-hidden="true"></i> {this.state.resources.ButtonLabel_New}</Link>
                         
                      </div>
                        :<></>}
                        <CampaingFilter />
                      <div className="card-body">
                          <div className="table-responsive">
                              {contents}
                          </div>
                      </div>
                  </div>
              </div>
        );
    }
    


    async populateData() {
        helpers.showLoadingOverlay();
        // axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/campaing/getall',
        //     {
        //         'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
        //     })
        //     .then(response => {
        //         const data = response.data.data;
        //         // console.log(data);
        //         //this.setState({ items: data, loading: false });
        //         this.state.loading = false;
                this.state.columns = [
                    {
                        field: 'actions',
                        headerAlign: "center",
                        headerClassName: "header-table-mui",
                        headerName: this.state.resources.Label_Actions ? this.state.resources.Label_Actions : 'Actions',
                        flex: 1,
                        minWidth: 110,
                        renderCell: (item) => {
                            return (
                                this.state.permissionsIds.includes(131) ?
                                <div className="d-flex justify-content-between align-items-center mx-auto" style={{ cursor: "pointer" }}>
                                    <td className="text-center">
                                        <Link to={"/campaing-detail/" + item.id} className="btn btn-default"><i className="fas fa-edit"></i></Link>
                                    </td>
                                </div>
                                :
                                <div>
                                <td className="text-center">
                                    <Link to={"/campaing-view/" + item.id} className="btn btn-default"><i class="fas fa-eye"></i></Link>
                                </td>
                                </div>
                            );
                        }
                    },
                    {
                        field: 'id',
                        headerName: 'ID',
                        type: 'number',
                        width: 100,
                        headerAlign: "center",
                        align: "left",
                        headerClassName: "header-table-mui"
                    },
                    {
                        field: 'name',
                        headerName: this.state.resources.Label_Name ? this.state.resources.Label_Name : 'Name',
                        flex: 1,
                        minWidth: 150,
                        headerAling: "center",
                        headerClassName: "header-table-mui"
                    }, 
                    {
                        field: 'country_name',
                        headerName: this.state.resources.Label_Country ? this.state.resources.Label_Country : 'Country Name',
                        flex: 1,
                        minWidth: 140,
                        headerAling: "center",
                        headerClassName: "header-table-mui"
                    }, 
                    {
                        field: 'agency_name',
                        headerName: this.state.resources.Title_Agency ? this.state.resources.Title_Agency : 'Agency Name',
                        flex: 1,
                        minWidth: 150,
                        headerAling: "center",
                        headerClassName: "header-table-mui"
                    }, 
                    {
                        field: 'media_name',
                        headerName: this.state.resources.Title_Media ? this.state.resources.Title_Media : 'Media Name',
                        flex: 1,
                        minWidth: 140,
                        headerAling: "center",
                        headerClassName: "header-table-mui"
                    }, 
                    {
                        field: 'did_name',
                        headerName: 'DID',
                        flex: 1,
                        minWidth: 150,
                        headerAling: "center",
                        headerClassName: "header-table-mui"
                    }, 
                    {
                        field: 'url_landing',
                        headerName: 'Landing',
                        flex: 1,
                        minWidth: 220,
                        headerAling: "center",
                        headerClassName: "header-table-mui"
                    }, 
                    {
                        field: 'enabled',
                        headerName: this.state.resources.Label_Status ? this.state.resources.Label_Status : 'Enabled',
                        flex: 1,
                        minWidth: 110,
                        headerAling: "center",
                        headerClassName: "header-table-mui",
                        renderCell: (item) => {
                            // console.log(item)
                            return (
                                <div className="text-center">
                                    {item.row.enabled == true ? this.state.resources.Label_Enabled : this.state.resources.Label_Disabled}
                                </div>
                            );
                        }
                    }
                ];

                helpers.hideLoadingOverlay();
            // })
            // .catch(function (error) {
            //     console.log(error);
            //     helpers.hideLoadingOverlay();
            // });

            axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/country/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ countries: data, loading: false });
                // order alphabetically
                this.state.countries.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                // console.log(this.state.countries);
            })
            .catch(function (error) {
                console.log(error);
            });

            axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/productcountry/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ productcountries: data, loading: false });
                // order alphabetically
                this.state.productcountries.sort((a, b) => a.product_name < b.product_name ? -1 : +(a.product_name > b.product_name))
               
                
                helpers.hideLoadingOverlay();

            })
            .catch(function (error) {
                console.log(error);
            });

            axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/agency/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ agencies: data});
                // order alphabetically
                this.state.agencies.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                // console.log(this.state.agencies);
            })
            .catch(function (error) {
                console.log(error);
            });

            axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/media/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ medias: data});
                // order alphabetically
                this.state.medias.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                // console.log(this.state.medias);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    async getResources() {
        const resourceVars = [];
        resourceVars.push("Label_Products");
        resourceVars.push("Label_ID");
        resourceVars.push("Label_Name");
        resourceVars.push("Title_Campaing");
        resourceVars.push("Label_FiscalId");
        resourceVars.push("Label_Brand");
        resourceVars.push("Label_Supplier");
        resourceVars.push("Title_Treatments");
        resourceVars.push("Label_Actions");
        resourceVars.push("Label_Status");
        resourceVars.push("Label_Enabled");
        resourceVars.push("Label_Disabled");
        resourceVars.push("ButtonLabel_ViewEdit");
        resourceVars.push("ButtonLabel_New");
        resourceVars.push("Datatables_language_lengthMenu");
        resourceVars.push("Datatables_language_zeroRecords");
        resourceVars.push("Datatables_language_info");
        resourceVars.push("Datatables_language_infoEmpty");
        resourceVars.push("Datatables_language_infoFiltered");
        resourceVars.push("Datatables_language_search");
        resourceVars.push("Datatables_language_paginate_previous");
        resourceVars.push("Datatables_language_paginate_next");
        resourceVars.push("Label_AddCountry");
        resourceVars.push("Label_DetailinCountry");
        resourceVars.push("Label_Country");
        resourceVars.push("Title_Agency");
        resourceVars.push("Title_Media");
        resourceVars.push("Label_FilterOff");
        resourceVars.push("Label_Filter");
        resourceVars.push("Label_Sort");
        resourceVars.push("Label_Hide");
        resourceVars.push("Label_ShowColumns");
        resourceVars.push("Label_OrderAsc");
        resourceVars.push("Label_OrderDesc");
        resourceVars.push("Label_Unsorting");
        resourceVars.push("Label_Columns");
        resourceVars.push("Label_Delete");
        resourceVars.push("Label_GridValue");
        resourceVars.push("Label_ValueFilter");
        resourceVars.push("Label_And");
        resourceVars.push("Label_Or");
        resourceVars.push("Label_Operator");
        resourceVars.push("Label_NoRows");
        resourceVars.push("Label_SelectColumns");
        resourceVars.push("Label_OperatorAfter");
        resourceVars.push("Label_OperatorBefore");
        resourceVars.push("Label_OperatorContains");
        resourceVars.push("Label_OperatorEnds");
        resourceVars.push("Label_OperatorEquals");
        resourceVars.push("Label_OperatorIs");
        resourceVars.push("Label_OperatorIsEmpty");
        resourceVars.push("Label_OperatorIsNotEmpty");
        resourceVars.push("Label_OperatorIsNot");
        resourceVars.push("Label_OperatorIsOnOrAfter");
        resourceVars.push("Label_OperatorIsOnOrBefore");
        resourceVars.push("Label_OperatorStarts");
        resourceVars.push("Label_ReorderColumns");
        resourceVars.push("Label_HideAll");
        resourceVars.push("Label_ShowAll");
        resourceVars.push("Label_FindColumn");
        resourceVars.push("Label_TitleColumn");
        resourceVars.push("Label_addFilter");

        const headers = {
            'Content-Type': 'application/json'
        };

        axios.post(window.config.REACT_APP_SECURITY_API_URL + '/languageresource/getbyvars/' + helpers.getUiLanguage(), resourceVars, {
            headers: headers
        })
            .then((response) => {
                const data = response.data.data;
                this.setState({ resources: data});
            })
            .catch((error) => {
                console.log(error);
            });
    }
}

export default function CampaingDataGrid(values) {
    //console.log()
    let items = localStorage.getItem('CampaingFilterItems') ?
            JSON.parse(localStorage.getItem('CampaingFilterItems')) : values.data.items,
        resources = values.data.resources,
        permissionsIds = values.data.permissionsIds, columns = values.data.columns,
        sorting = localStorage.getItem('CopyCampaingListSorting') ?
        JSON.parse(localStorage.getItem('CopyCampaingListSorting')) : [],
    paging = values.data.paging ? values.data.paging : 0
    , filter = localStorage.getItem('CopyCampaingListFiltering') ?
        JSON.parse(localStorage.getItem('CopyCampaingListFiltering')) : values.data.filtering
    
    const [page, setPage] = React.useState(paging.page);
    const [pageSize, setPageSize] = React.useState(paging.pageSize);

    const [value, setValue] = React.useState([null, null]);
    const [myRows, setRowsValue] = React.useState(values.data.items);
    const [mySortModel, setSortModel] = React.useState([]);
    const [filtering, setFilter] = React.useState(filter);
    const [status, onChangeStatus] = React.useState(true);
    const [open, setOpen] = React.useState(false);
    const openModal = () => setOpen(true);
    const closeModal = () => setOpen(false);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    function setInitial() {
        setTimeout(() => {
            if (document.getElementsByClassName('MuiTablePagination-selectLabel')[0]){
                document.getElementsByClassName('MuiTablePagination-selectLabel')[0].innerHTML = '';
            }
            setRowsValue(items)
            // loading = true
            if (myRows.length < 1) {
                if (localStorage.getItem('CampaingFilterItems')) {
                    let myItems = JSON.parse(localStorage.getItem('CampaingFilterItems'));
                    setRowsValue(myItems);
                    setTimeout(() => {
                        localStorage.removeItem('CampaingFilterItems');
                    }, 1000);
                } else {
                    setRowsValue(items)
                } 
            }
        }, 1000)
       
    }
    setInitial()

    const handlePageChange = (newPage) => {
        setPage(newPage);
    };

    const handlePageSizeChange = (newPage) => {
        setPageSize(newPage);
    };

    const handleFilterChange = (newFilter) => {
        // console.log(newFilter)
        setFilter(newFilter);
    }

    const handleStatus = (e) => {
        e.preventDefault();
        onChangeStatus(e.target.value);
    }

    const handleSortingChange = (sort) => {
        setSortModel(sort);
        localStorage.removeItem('CampaingListSorting');
        localStorage.removeItem('CopyCampaingListSorting');
        localStorage.setItem('CopyCampaingListSorting', JSON.stringify(sort));
    }

    // const onReloadData =
    async function onReloadData() {
        // console.log(status)
        
        if (status === true || status === 'true') {
            helpers.showLoadingOverlay();
            axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/campaing/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                // console.log(response.data.data)
                let newItems = response.data.data;
                setRowsValue(newItems);
                localStorage.setItem('CampaingFilterItems', JSON.stringify(newItems));
                closeModal();
                helpers.hideLoadingOverlay();
             })
            .catch(err => console.log(err));
        } else if (status != true){
            helpers.showLoadingOverlay();
            axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/campaing/getdisabled',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                // console.log(response.data.data)
                let newItems = response.data.data;
                setRowsValue(newItems);
                localStorage.setItem('CampaingFilterItems', JSON.stringify(newItems));
                closeModal();
                helpers.hideLoadingOverlay();
             })
            .catch(err => console.log(err));
        }
    }

    async function resetFilter() {
        helpers.showLoadingOverlay();
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/campaing/getall',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                let newItems = response.data.data;
                setRowsValue(newItems);
                localStorage.removeItem('CampaingFilterItems');
                closeModal();
                helpers.hideLoadingOverlay();
             })
            .catch(error => console.log(error));
    }

    return (
        <>
        {/* <button className="btn btn-success mb-1" onClick={openModal}><i class="fas fa-filter"></i></button> */}
        <Modal
            open={open}
            onClose={closeModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <div className="dis-flex m-1" style={{justifyContent: 'space-beetween'}}>
                    <div>
                        <label className="label-text">{values.data.resources.Label_Status}</label>
                        <select className="form-control" data-val="true" name="productId" value={status} onChange={handleStatus}>
                            <option value="true">{values.data.resources.Label_Enabled}</option>
                            <option value="false">{values.data.resources.Label_Disabled}</option>
                        </select>
                        </div>
                        <div className="mt-4">
                            <button className="btn btn-primary mx-1 my-auto" onClick={onReloadData}>{values.data.resources.Label_Filter}</button>
                            <button className="btn btn-danger mx-1 my-auto" onClick={resetFilter}>{values.data.resources.Label_FilterOff}</button>
                        </div>
                </div>
            </Box>
        </Modal>
            <div style={{ height: 450, width: '99%' }}>
                <DataGridPro rows={myRows} columns={columns} rowsPerPageOptions={[5, 10, 25, 50, 100]} density='compact'
                pagination
                page={page}
                pageSize={pageSize}
                onStateChange={(state) => {
                    localStorage.setItem('CampaingListPagination', JSON.stringify(state.pagination))
                    localStorage.setItem('CampaingListSorting', JSON.stringify(state.sorting))
                    localStorage.setItem('CampaingListFiltering', JSON.stringify(state.filter))
                }}
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
                sortModel={mySortModel}
                onSortModelChange={handleSortingChange}
                state={
                        filter != null ? {filter: filter} : {}
                        
                }
                localeText={{
                    'columnMenuFilter': values.data.resources.Label_Filter,
                    'columnHeaderSortIconLabel': values.data.resources.Label_Sort,
                    'columnMenuHideColumn': values.data.resources.Label_Hide,
                    'columnMenuShowColumns': values.data.resources.Label_ShowColumns,
                    'columnMenuSortAsc': values.data.resources.Label_OrderAsc,
                    'columnMenuSortDesc': values.data.resources.Label_OrderDesc,
                    'columnMenuUnsort': values.data.resources.Label_Unsorting,
                    'filterPanelColumns': values.data.resources.Label_Columns,
                    'filterPanelDeleteIconLabel': values.data.resources.Label_Delete,
                    'filterPanelInputLabel': values.data.resources.Label_GridValue,
                    'filterPanelInputPlaceholder': values.data.resources.Label_ValueFilter,
                    'filterPanelOperatorAnd': values.data.resources.Label_And,
                    'filterPanelOperatorOr': values.data.resources.Label_Or,
                    'filterPanelOperators': values.data.resources.Label_Operator,
                    'noResultsOverlayLabel': values.data.resources.Label_NoRows,
                    'noRowsLabel': values.data.resources.Label_NoRows,
                    'toolbarColumns': values.data.resources.Label_Columns,
                    'toolbarColumnsLabel': values.data.resources.Label_SelectColumns,
                    'errorOverlayDefaultLabel': "Error",
                    'filterOperatorAfter': values.data.resources.Label_OperatorAfter,
                    'filterOperatorBefore': values.data.resources.Label_OperatorBefore,
                    'filterOperatorContains': values.data.resources.Label_OperatorContains,
                    'filterOperatorEndsWith': values.data.resources.Label_OperatorEnds,
                    'filterOperatorEquals': values.data.resources.Label_OperatorEquals,
                    'filterOperatorIs': values.data.resources.Label_OperatorIs,
                    'filterOperatorIsEmpty': values.data.resources.Label_OperatorIsEmpty,
                    'filterOperatorIsNotEmpty': values.data.resources.Label_OperatorIsNotEmpty,
                    'filterOperatorNot': values.data.resources.Label_NoRows,
                    'filterOperatorOnOrAfter': values.data.resources.Label_OperatorIsOnOrAfter,
                    'filterOperatorOnOrBefore': values.data.resources.Label_OperatorIsOnOrBefore,
                     'filterOperatorStartsWith': values.data.resources.Label_OperatorStarts,
                    'columnsPanelDragIconLabel': values.data.resources.Label_ReorderColumns,
                    'columnsPanelHideAllButton': values.data.resources.Label_HideAll,
                    'columnsPanelShowAllButton': values.data.resources.Label_ShowAll,
                    'columnsPanelTextFieldLabel': values.data.resources.Label_FindColumn,
                    'columnsPanelTextFieldPlaceholder': values.data.resources.Label_TitleColumn,
                    'filterPanelAddFilter': values.data.resources.Label_addFilter
                }}    
                
                      
            />
        </div>
    </>
    );
}

function CampaingFilter(){
    const [open, setOpen] = React.useState(false);
    const closeModal = () => setOpen(false);

    const handleOpen = () => {
        setOpen(true);
    };
    
    const handleClose = () => {
        setOpen(false);
        this.setState({
            agencyId: null,
            enabled: null,
            countryId: null,
            productCountryId: null,
            mediaId: null
        });
    };

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '1200px',
        maxWidth:'1200px',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const onChangeCountry = (e)  =>{
        this.setState({
            countryId: e.target?.value
        });
        console.log(e.target?.value)
    }

    const onChangeProductsCountry = (e)  =>{
        this.setState({
            productCountryId: e.target?.value
        });
        console.log(e.target?.value)
    }

    const onChangeAgency = (e)  =>{
        this.setState({
            agencyId: e.target?.value
        });
        console.log(e.target?.value)
    }

    const onChangeMedia = (e)  =>{
        this.setState({
            mediaId: e.target?.value
        });
        console.log(e.target?.value)
    }

    const onChangeEnabled = (e)  =>{
        this.setState({
            enabled: e.target?.value
        });
        console.log(e.target?.value)
    }

    const onSendFilter = async (e) => {
        e.preventDefault();
        const data = {
           country_id: Number(this.state.countryId) ?? 0,
           product_country_id: this.state.productCountryId ?? 0,
           media_id: this.state.mediaId ?? 0,
           agency_id: this.state.agencyId ?? 0,
           enabled: this.state.enabled ?? null
        };

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME)
        };
        if(this.state.countryId?.length > 0){
            helpers.showLoadingOverlay();
        axios.post(window.config.REACT_APP_PRODUCTS_API_URL + '/campaing/searchbyfilter', data, {
            headers: headers
        })
        .then((response) => {
            helpers.hideLoadingOverlay();
            // console.log(response); test
            const resp = response.data.data
             this.setState({
                items: resp
            });
            this.state.items = resp;
            handleClose();
        })
        .catch((error) => {
            helpers.hideLoadingOverlay();
            console.log(error);
            handleClose();
        });
        }else{
            helpers.notifyWarning('Error','País Requerido');
        }
        
    }

    const onClearFilter = (e) => {
        this.setState({
            countryId: -1,
            enabled: -1,
            mediaId: -1,
            productCountryId: -1,
            agencyId: -1
        });

        this.state.countryId = -1;
        this.state.enabled = -1;
        this.state.mediaId = -1;
        this.state.productCountryId = -1;
        this.state.agencyId = -1;
    }

    return (
        <>
            <div className="form-group col-md-1 mb-0">
                <button className='btn btn-success mx-2 mt-3' onClick={handleOpen}>
                    <i className="btn btn-success mb-1" class="fas fa-filter" data-tougle="tooltip" data-placement="right"  onClick={handleOpen}></i>
                </button>
                <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <div className="mt-2">
                            <ol className="breadcrumb my-4">
                                <li className="breadcrumb-item active">{this.state.resources.Label_addFilter}</li>
                            </ol>
                        </div>

                        <div className="form-group row">

                            <div className="form-group col-md-3" >
                                <label className=" form-label">{this.state.resources.Label_Country}</label>
                                <select className="form-control" data-val="true" name="countryid" value={this.state.countryId} onChange={onChangeCountry}>
                                    <option value="-1">{helpers.getUiLanguage() == 'language1' ? 'Todas' : 'All'}</option>
                                    {this.state.countries.map(country =>
                                        <option key={country.id} value={country.id}>{country.name}</option>
                                    )}
                                </select>
                            </div>
                            <div className="form-group col-md-3" >
                                <label className=" form-label">{this.state.resources.Label_Products}</label>
                                <select className="form-control" data-val="true" name="productcountryid" value={this.state.productCountryId} onChange={onChangeProductsCountry}>
                                    <option value="-1">{helpers.getUiLanguage() == 'language1' ? 'Todas' : 'All'}</option>
                                    {this.state.productcountries.map(products =>
                                        <option key={products.id} value={products.id}>{products.name}</option>
                                    )}
                                </select>
                            </div>

                            <div className="form-group col-md-2" >
                                <label className=" form-label">{this.state.resources.Title_Agency}</label>
                                <select className="form-control" data-val="true" name="agencyid" value={this.state.agencyId} onChange={onChangeAgency}>
                                    <option value="-1">{helpers.getUiLanguage() == 'language1' ? 'Todas' : 'All'}</option>
                                    {this.state.agencies.map(agency =>
                                        <option key={agency.id} value={agency.id}>{agency.name}</option>
                                    )}
                                </select>
                            </div>

                            <div className="form-group col-md-2" >
                                <label className=" form-label">{this.state.resources.Title_Media}</label>
                                <select className="form-control" data-val="true" name="mediaid" value={this.state.mediaId} onChange={onChangeMedia}>
                                    <option value="-1">{helpers.getUiLanguage() == 'language1' ? 'Todas' : 'All'}</option>
                                    {this.state.medias.map(medi =>
                                        <option key={medi.id} value={medi.id}>{medi.name}</option>
                                    )}
                                </select>
                            </div>

                            <div className="form-group col-md-2" >
                                <label className=" form-label">{this.state.resources.Label_Status}</label>
                                <select className="form-control" data-val="true" name="enabled" value={this.state.enabled} onChange={onChangeEnabled}>
                                    <option value="-1">{helpers.getUiLanguage() == 'language1' ? 'Todos' : 'All'}</option>
                                    <option value={1}>{this.state.resources.Label_Enabled}</option>
                                    <option value={0}>{this.state.resources.Label_Disabled}</option>
                                </select>
                            </div>
                            <div className="col-md-10 "></div>
                            <div className="col-md-2 " style={{justifyContent: 'space-between' }}>
                                <div className=" buttons-childs">
                                    <button className="btn btn-primary mx-2"onClick={onSendFilter}>{helpers.getUiLanguage() == 'language1' ? 'Enviar' : 'Send'}</button>
                                    <div></div>
                                    <button className="btn btn-danger" onClick={onClearFilter}>{helpers.getUiLanguage() == 'language1' ? 'Limpiar' : 'Clear'}</button>
                                </div>
                                
                            </div>
                        </div>
                        
                    </Box>
                </Modal>
            </div>
        </>
    );
}
