import helpers from '../../Helpers'
import SessionConfig from '../config/SessionConfig'
import Cookies from 'universal-cookie';
import authService from '../api-authorization/AuthorizeService';

const SessionHelper = {
    setExpiry: function () {
        helpers.setCookie(SessionConfig.cookieName, SessionConfig.uuid, { path: '/', maxAge: SessionConfig.timeoutInMinutes * 60 });
    },

    resetExpiry: function () {
        helpers.removeCookie(SessionConfig.cookieName);
        helpers.setCookie(SessionConfig.cookieName, SessionConfig.uuid, { path: '/', maxAge: SessionConfig.timeoutInMinutes * 60 });
    },

    isExpired: function () {
        return helpers.getCookieValue(SessionConfig.cookieName) === undefined;
        
        // const cookies = new Cookies();
        // let options = {path: '/login'};
        // cookies.set(undefined, undefined, options);
    },

    doLogout: function () {
        helpers.removeCookie(SessionConfig.cookieName);
        // let username = helpers.getUserData()
        // console.log(username);
        // authService.logOut(username.username);
        // authService.getUser().then(user => {
        //     console.log(user);
        //     authService.logOut(user);
        // }).catch(err => {
        //      console.log(err)
        //  });
        window.location.href = '/login';
    }

}

export default SessionHelper;