import React, { Component } from 'react';
import axios from 'axios';
import helpers from '../../Helpers';
import $ from 'jquery';
import queryString from 'query-string';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { styled } from '@mui/material/styles';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Button from '@mui/material/Button';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { RemoveShoppingCart } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { maxWidth } from '@mui/material/node_modules/@mui/system';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart';
const Swal = require('sweetalert2')

export class FailureWsp extends Component {
    static displayName = FailureWsp.name;

    constructor(props) {
        super(props);

        this.state = {
            steps: ['Inicio', 'Cliente', 'Pedido', 'Pago', 'Checkout'],
            activeStep: 2,
            resources: [],
            tokenProducts: "",
        };
    }

    componentDidMount() {
        this.getResources();
        let checkoutData = JSON.parse(localStorage.getItem("DataCheckOutWsp"));
        let data = JSON.parse(localStorage.getItem('DataWorkspace'));
        this.setState({
            buyOrder: checkoutData.buyOrder,
            tokenProducts: data.state.tokenProducts
        });
        this.state.tokenProducts = data.state.tokenProducts;

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': this.state.tokenProducts
        };
        
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getshoppingcardbyid/' + checkoutData.buyOrder,
            {
                headers: headers
            })
            .then(response => {
                const data = response.data.data;
                //console.log(data);
                this.setState({
                    shoppingCar: data
                });
                this.state.shoppingCar.car_state_id = 3;
                this.state.shoppingCar.payment_state_id = 5;
                // console.log(this.state.shoppingCar);
                axios.post(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/saveshoppingcar/', this.state.shoppingCar,
                {
                    headers: headers
                })
                .then(response => {
                    const data = response.data.data;
                    //console.log(data);
                })
                .catch(function (error) {
                    console.log(error);
                    this.setState({ loading: false });
                }) 
            })
            .catch(function (error) {
                console.log(error);
                this.setState({ loading: false });
            });

    }

    render() {

        return (
            <div className="container">
                 <div className="row text-center" style={{backgroundColor: "rgb(237 160 160 / 69%)",padding: "1rem",color: "rgb(135 0 12)", margin: "2rem"}}>
                    <h2 className='pb-2'>
                        {localStorage.getItem('WspLanguage') == 'language0' ? "Unsuccessful Transaction" : "Transacción No Exitosa"}
                    </h2>
                    <p style={{fontSize: '1.08rem', fontWeight: '600'}}>
                        {localStorage.getItem('WspLanguage') == 'language0' ?
                            <>
                                <>The possible causes of this rejection are: </>
                            </>
                            :
                            <>
                                <>Las posibles causas de este rechazo son: </>
                            </>}
                    </p>
                    <p style={{fontSize: '1rem'}}>
                        {localStorage.getItem('WspLanguage') == 'language0' ?
                            <>
                               
                                - Error in entering your Credit or Debit card data (date and/or security code).<br></br>
                                - Your Credit or Debit card does not have a sufficient balance.<br></br>
                                - Card not yet enabled in the financial system.
                            </> :
                            <>
                               - Error en el ingreso de los datos de su tarjeta de Crédito o Débito (fecha y/o código de seguridad).<br></br>
                                - Su tarjeta de Crédito o Débito no cuenta con saldo suficiente.<br></br>
                                - Tarjeta aun no habilitada en el sistema financiero.
                            </>}
                    </p>
                </div>
            </div>
        );
    }

    async getResources() {
        const resourceVars = [];
        resourceVars.push("Label_SelectOption");
        resourceVars.push("Label_Name");
        resourceVars.push("Label_LastName");
        resourceVars.push("Label_PostalCode");
        resourceVars.push("Title_City");
        resourceVars.push("Label_Address");
        resourceVars.push("Label_Country");
        resourceVars.push("Label_StateOrProvince");
        resourceVars.push("Label_DocNumber");
        resourceVars.push("Label_DateOfBirth");
        resourceVars.push("Label_Phone");
        resourceVars.push("Label_MobilePhone");
        resourceVars.push("Label_Email");
        resourceVars.push("Label_Description");
        resourceVars.push("Label_Enabled");
        resourceVars.push("Label_Success");
        resourceVars.push("ButtonLabel_Save");
        resourceVars.push("ButtonLabel_Cancel");
        resourceVars.push("Label_Error");
        resourceVars.push("ErrorMessage_RequiredField");
        resourceVars.push("ErrorMessage_DuplicatedName");
        resourceVars.push("ErrorMessage_UnknownError");
        resourceVars.push("ErrorMessage_AssociatedEntity_SubArea_User");
        resourceVars.push("SuccessMessage_DataSavedSuccessfully");
        resourceVars.push("Label_GoBack");
        resourceVars.push("Title_Clients");
        resourceVars.push("Label_Creation");
        resourceVars.push("Label_DocumentType");
        resourceVars.push("Workspace_Next");
        resourceVars.push("Label_NoSale");
        resourceVars.push("Label_Sex");
        resourceVars.push("Label_Male");
        resourceVars.push("Label_Female");
        resourceVars.push("Label_SearchClient");
        resourceVars.push("Label_HistoryClient");
        resourceVars.push("Label_Info");
        resourceVars.push("Label_NoMail");
        resourceVars.push("Label_Number");
        resourceVars.push("Label_Floor");
        resourceVars.push("Label_Department");
        resourceVars.push("Label_CourierComentaries");
        resourceVars.push("Label_InsideComentaries");
        resourceVars.push("Label_DocsRequired");
        resourceVars.push("ButtonLabel_Select");
        resourceVars.push("Label_NoRows");
        resourceVars.push("Title_Objetions");
        resourceVars.push("Title_ScriptSales");
        resourceVars.push("Label_Price");
        resourceVars.push("Label_AddProduct");
        resourceVars.push("Label_Quantity");
        resourceVars.push("Label_Products");
        resourceVars.push("Label_Presentation");
        resourceVars.push("Label_Actions");
        resourceVars.push("Label_TotalPrice");
        resourceVars.push("Label_Comments");
        resourceVars.push("Label_NoSaleReason");
        resourceVars.push("Label_ContactAgain");
        resourceVars.push("Label_ProcessHasFinalized");

        const headers = {
            'Content-Type': 'application/json'
        };

        let wspLanguage = localStorage.getItem('WspLanguage');

        axios.post(window.config.REACT_APP_SECURITY_API_URL + '/languageresource/getbyvars/' + wspLanguage, resourceVars, {
            headers: headers
        })
        .then((response) => {
            const data = response.data.data;
            this.setState({ resources: data, loading: false });
        })
        .catch((error) => {
            console.log(error);
            this.setState({ loading: false });
        });
    }
}