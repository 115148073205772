import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';  
import $ from 'jquery';
import DataTable from 'datatables.net';
import helpers from '../../Helpers';
import '../../custom.css';
import { DataGrid, useGridApiRef, esES } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { DataGridPro } from '@mui/x-data-grid-pro';

import { LicenseInfo } from '@mui/x-data-grid-pro';
import ChartComponent from '../ChartComponent/ChartComponent';

LicenseInfo.setLicenseKey(
  '97124f3444ae3cf552c73ef2f3487b08T1JERVI6MzEzNDQsRVhQSVJZPTE2NjY1MzkzOTkwMDAsS0VZVkVSU0lPTj0x',
);

export class SaleResume extends Component {
    static displayName = SaleResume.name;

    constructor(props) {
        super(props);
        this.state = {
            items: [],
            resources: [],
            loading: true,
            permissionsIds: [],
            columns: [],
            paging: null,
            sorting: null,
            filtering: null,
            child: null,
            child2: null,
        };
    }

    componentDidMount() {
        let userData = helpers.getUserData();
        this.state.permissionsIds = userData.permission_ids;
        this.getResources();
        this.populateData();
        helpers.setNavActive("#nav-subareas");

        this.state.sorting = JSON.parse(localStorage.getItem('CopySubAreaListSorting'));
        this.state.paging = JSON.parse(localStorage.getItem('CopySubAreaListPaging'));
        this.state.filtering = JSON.parse(localStorage.getItem('CopySubAreaListFiltering'));
    }

      static renderTable(items, resources, permissionsIds) {
       
      }

      render() {
          let contents = this.state.loading
              ? helpers.showLoadingOverlay()
              : <>
                    <SubAreaDataGrid data={this.state}/>
                    <ChartComponent chartTo={'salesHour'} items={this.state.items} ref={child => this.state.child = child}/>
                    <ChartComponent chartTo={'salesArea'} items={this.state.items} ref={child => this.state.child2 = child}/>
                </>
            //   SubAreasList.renderTable(this.state.items, this.state.resources, this.state.permissionsIds);

          return (
              <div className="mt-2">
                  <ol className="breadcrumb my-4">
                      <li className="breadcrumb-item active">{this.state.resources.Title_SaleResume}</li>
                  </ol>
                  <div className="card">
                      <div className="card-header">
                        </div>
                      <div className="card-body">
                          <div className="table-responsive">
                              {contents}
                          </div>
                      </div>
                  </div>
              </div>
        );
      }

    async populateData() {
        localStorage.setItem('SubAreaFilterItems', JSON.stringify([]));
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/shoppingcar/getresumenventasclear',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ items: data, loading: false });
                localStorage.setItem('SubAreaFilterItems', JSON.stringify(data));
                this.state.columns = [
                    {
                        field: 'sub_area',
                        headerName: this.state.resources.Label_SubArea ? this.state.resources.Label_SubArea : 'Sub Area',
                        flex: 1,
                        minWidth: 180,
                        headerAling: "center",
                        headerClassName: "header-table-mui"
                    },
                    {
                        field: 'monto',
                        headerName: 'Monto ($)',
                        type: 'number',
                        width: 180,
                        headerAlign: "right",
                        align: "right",
                        headerClassName: "header-table-mui",
                        renderCell: (item) => {
                            return (
                                <div style={{textAlign: 'right'}}>
                                    ${Number(item.row.monto).toLocaleString('es-ES')}
                                </div>
                            );
                        },
                        valueGetter: (item)=>item.row.monto
                    }, 
                    {
                        field: 'ventas',
                        headerName: 'Ventas (u)',
                        type: 'number',
                        width: 180,
                        headerAlign: "right",
                        align: "right",
                        headerClassName: "header-table-mui"
                    }, 
                    {
                        field: 'monto_pre',
                        headerName: 'PRE ($)',
                        type: 'number',
                        width: 180,
                        headerAlign: "right",
                        align: "right",
                        headerClassName: "header-table-mui"
                        ,
                        renderCell: (item) => {
                            return (
                                <div style={{textAlign: 'right'}}>
                                    ${Number(item.row.monto_pre).toLocaleString('es-ES')}
                                </div>
                            );
                        },
                        valueGetter: (item)=>item.row.monto_pre
                    },
                    {
                        field: 'monto_cod',
                        headerName: 'COD ($)',
                        type: 'number',
                        width: 180,
                        headerAlign: "right",
                        align: "right",
                        headerClassName: "header-table-mui"
                        ,
                        renderCell: (item) => {
                            return (
                                <div style={{textAlign: 'right'}}>
                                    ${Number(item.row.monto_cod).toLocaleString('es-ES')}
                                </div>
                            );
                        },
                        valueGetter: (item)=>item.row.monto_cod
                    },
                    {
                        field: 'ventas_pre',
                        headerName: 'PRE (u)',
                        type: 'number',
                        width: 180,
                        headerAlign: "right",
                        align: "right",
                        headerClassName: "header-table-mui"
                    },
                    {
                        field: 'ventas_cod',
                        headerName: 'COD (u)',
                        type: 'number',
                        width: 180,
                        headerAlign: "right",
                        align: "right",
                        headerClassName: "header-table-mui"
                    },
                ];
                helpers.hideLoadingOverlay();
            })
            .catch(function (error) {
                console.log(error);
                helpers.hideLoadingOverlay();
            });
    }

    async getResources() {
        const resourceVars = [];
        resourceVars.push("Title_SaleResume");
        resourceVars.push("Label_ID");
        resourceVars.push("Label_Name");
        resourceVars.push("Label_Area");
        resourceVars.push("Label_SubArea");
        resourceVars.push("Label_Description");
        resourceVars.push("Label_Actions");
        resourceVars.push("Label_From");
        resourceVars.push("Label_To");
        resourceVars.push("Label_Enabled");
        resourceVars.push("Label_Disabled");
        resourceVars.push("ButtonLabel_ViewEdit");
        resourceVars.push("ButtonLabel_New");
        resourceVars.push("Datatables_language_lengthMenu");
        resourceVars.push("Datatables_language_zeroRecords");
        resourceVars.push("Datatables_language_info");
        resourceVars.push("Datatables_language_infoEmpty");
        resourceVars.push("Datatables_language_infoFiltered");
        resourceVars.push("Datatables_language_search");
        resourceVars.push("Datatables_language_paginate_previous");
        resourceVars.push("Datatables_language_paginate_next");
        resourceVars.push("Label_FilterOff");
        resourceVars.push("Datatables_language_search");
        resourceVars.push("Label_Sort");
        resourceVars.push("Label_Hide");
        resourceVars.push("Label_ShowColumns");
        resourceVars.push("Label_OrderAsc");
        resourceVars.push("Label_OrderDesc");
        resourceVars.push("Label_Unsorting");
        resourceVars.push("Label_Columns");
        resourceVars.push("Label_Delete");
        resourceVars.push("Label_GridValue");
        resourceVars.push("Label_ValueFilter");
        resourceVars.push("Label_And");
        resourceVars.push("Label_Or");
        resourceVars.push("Label_Operator");
        resourceVars.push("Label_NoRows");
        resourceVars.push("Label_SelectColumns");
        resourceVars.push("Label_OperatorAfter");
        resourceVars.push("Label_OperatorBefore");
        resourceVars.push("Label_OperatorContains");
        resourceVars.push("Label_OperatorEnds");
        resourceVars.push("Label_OperatorEquals");
        resourceVars.push("Label_OperatorIs");
        resourceVars.push("Label_OperatorIsEmpty");
        resourceVars.push("Label_OperatorIsNotEmpty");
        resourceVars.push("Label_OperatorIsNot");
        resourceVars.push("Label_OperatorIsOnOrAfter");
        resourceVars.push("Label_OperatorIsOnOrBefore");
        resourceVars.push("Label_OperatorStarts");
        resourceVars.push("Label_ReorderColumns");
        resourceVars.push("Label_HideAll");
        resourceVars.push("Label_ShowAll");
        resourceVars.push("Label_FindColumn");
        resourceVars.push("Label_TitleColumn");
        resourceVars.push("Label_addFilter");
        resourceVars.push("Label_GenerateSale");
        resourceVars.push("Label_Yes");

        const headers = {
            'Content-Type': 'application/json'
        };

        axios.post(window.config.REACT_APP_SECURITY_API_URL + '/languageresource/getbyvars/' + helpers.getUiLanguage(), resourceVars, {
            headers: headers
        })
            .then((response) => {
                const data = response.data.data;
                this.setState({ resources: data, loading: false });
                this.state.columns = [
                    {
                        field: 'sub_area',
                        headerName: this.state.resources.Label_SubArea ? this.state.resources.Label_SubArea : 'Sub Area',
                        flex: 1,
                        minWidth: 180,
                        headerAling: "center",
                        headerClassName: "header-table-mui"
                    },
                    {
                        field: 'monto',
                        headerName: 'Monto ($)',
                        type: 'number',
                        width: 180,
                        headerAlign: "right",
                        align: "right",
                        headerClassName: "header-table-mui",
                        renderCell: (item) => {
                            return (
                                <div style={{textAlign: 'right'}}>
                                    ${Number(item.row.monto).toLocaleString('es-ES')}
                                </div>
                            );
                        },
                        valueGetter: (item)=>item.row.monto
                    }, 
                    {
                        field: 'ventas',
                        headerName: 'Ventas (u)',
                        type: 'number',
                        width: 180,
                        headerAlign: "right",
                        align: "right",
                        headerClassName: "header-table-mui"
                    }, 
                    {
                        field: 'monto_pre',
                        headerName: 'PRE ($)',
                        type: 'number',
                        width: 180,
                        headerAlign: "right",
                        align: "right",
                        headerClassName: "header-table-mui"
                        ,
                        renderCell: (item) => {
                            return (
                                <div style={{textAlign: 'right'}}>
                                    ${Number(item.row.monto_pre).toLocaleString('es-ES')}
                                </div>
                            );
                        },
                        valueGetter: (item)=>item.row.monto_pre
                    },
                    {
                        field: 'monto_cod',
                        headerName: 'COD ($)',
                        type: 'number',
                        width: 180,
                        headerAlign: "right",
                        align: "right",
                        headerClassName: "header-table-mui"
                        ,
                        renderCell: (item) => {
                            return (
                                <div style={{textAlign: 'right'}}>
                                    ${Number(item.row.monto_cod).toLocaleString('es-ES')}
                                </div>
                            );
                        },
                        valueGetter: (item)=>item.row.monto_cod
                    },
                    {
                        field: 'ventas_pre',
                        headerName: 'PRE (u)',
                        type: 'number',
                        width: 180,
                        headerAlign: "right",
                        align: "right",
                        headerClassName: "header-table-mui"
                    },
                    {
                        field: 'ventas_cod',
                        headerName: 'COD (u)',
                        type: 'number',
                        width: 180,
                        headerAlign: "right",
                        align: "right",
                        headerClassName: "header-table-mui"
                    },
                ];
            })
            .catch((error) => {
                console.log(error);
            });
    }
}

export default function SubAreaDataGrid(values) {

    let items = values.data.items,
        resources = values.data.resources,
        permissionsIds = values.data.permissionsIds, columns = values.data.columns,
        sorting = localStorage.getItem('CopySubAreaListSorting') ?
            JSON.parse(localStorage.getItem('CopySubAreaListSorting')) : values.data.sorting,
        paging = values.data.paging ? values.data.paging : 0
        , filter = localStorage.getItem('CopySubAreaListFiltering') ?
            JSON.parse(localStorage.getItem('CopySubAreaListFiltering')) : values.data.filtering
    
    const [page, setPage] = React.useState(paging.page);
    const [pageSize, setPageSize] = React.useState(paging.pageSize);
    const [mySortModel, setSortModel] = React.useState([]);
    const [value, setValue] = React.useState([null, null]);
    const [myRows, setRowsValue] = React.useState(values.data.items);
    const [filtering, setFilter] = React.useState(filter);
    const [status, onChangeStatus] = React.useState(true);
    const [dateBegin, setDateBegin] = React.useState(new Date())
    const [dateBeginPost, setDateBeginPost] = React.useState()
    const [dateEnd, setDateEnd] = React.useState(new Date())
    const [dateEndPost, setDateEndPost] = React.useState()
    const [datee, setDateEndx] = React.useState(new Date())
    const [open, setOpen] = React.useState(false);
    const openModal = () => setOpen(true);
    const closeModal = () => setOpen(false);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 700,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    function setInitial() {
        
        setTimeout(() => {
            if (document.getElementsByClassName('MuiTablePagination-selectLabel')[0]){
                document.getElementsByClassName('MuiTablePagination-selectLabel')[0].innerHTML = '';
            }
            // loading = true
            if (myRows.length < 1) {
                if (localStorage.getItem('SubAreaFilterItems')) {
                    let myItems = JSON.parse(localStorage.getItem('SubAreaFilterItems'));
                    setRowsValue(myItems);
                    setTimeout(() => {
                        localStorage.removeItem('SubAreaFilterItems');
                    }, 1000);
                } else {
                    setRowsValue(items)
                }
                
            }
            // if (sorting) {
            //     if (sorting.sortModel) {
            //         setSortModel(sorting.sortModel)
            //     }
            // }
        }, 1000)
       
    }
    setInitial()

    const handlePageChange = (newPage) => {
        setPage(newPage);
    };

    const handlePageSizeChange = (newPage) => {
        setPageSize(newPage);
    };

    const handleFilterChange = (newFilter) => {
        // console.log(newFilter)
        setFilter(newFilter);
    }

    const handleSortingChange = (sort) => {
        setSortModel(sort);
        localStorage.removeItem('SubAreaListSorting');
        localStorage.removeItem('CopySubAreaListSorting');
        localStorage.setItem('CopySubAreaListSorting', JSON.stringify(sort));
    }

    const handleStatus = (e) => {
        e.preventDefault();
        onChangeStatus(e.target.value);
    }


    const handleBegin = (e) => {
        e.preventDefault();
        setDateBegin(new Date(e.target.value));
        setDateBeginPost(e.target.value);
    }


    const handleEnd= (e) => {
        e.preventDefault();
        setDateEnd(new Date(e.target.value));
        setDateEndPost(e.target.value);
    }

    async function onReloadData() {
        // console.log(status)
        /*console.log(dateBegin.addDays(1));
        console.log(dateEnd.addDays(1));
        console.log(helpers.getDisplayDateF(dateBegin.addDays(1)));
        console.log(helpers.getDisplayDateF(dateEnd.addDays(1)));*/
        if (dateBegin.getTime() === datee.getTime() || dateEnd.getTime() === datee.getTime()) 
        {
            helpers.notifyWarning('Error','Fecha invalida');
        }
        else if (dateBegin.getTime() === datee.getTime()) 
        {
            helpers.notifyWarning('Error','Fecha invalida');
        }
        else if (dateEnd.getTime() === datee.getTime()) 
        {
            helpers.notifyWarning('Error','Fecha invalida');
        }   
        else
        {
            helpers.showLoadingOverlay();
            axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/shoppingcar/getresumenventas/' + helpers.getDisplayDateF(dateBegin) + '/' + helpers.getDisplayDateF(dateEnd) + '',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                // console.log(response.data.data)
                let newItems = response.data.data;
                setRowsValue(newItems);
                localStorage.setItem('SubAreaFilterItems', JSON.stringify(newItems));
                // values.data.child.onUpdateItems(newItems);
                // values.data.child2.onUpdateItems(newItems);
                closeModal();
                helpers.hideLoadingOverlay();
             })
            .catch(err => console.log(err));

            // helpers.showLoadingOverlay();
            // axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/shoppingcar/getventasparagraficos/' + helpers.getDisplayDateF(dateBegin) + '/' + helpers.getDisplayDateF(dateEnd) + '',
            // {
            //     'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            // })
            // .then(response => {
            //      console.log(response.data.data)
            //      //let newItems = response.data.data;
            //     // setRowsValue(newItems);
            //     // localStorage.setItem('SubAreaFilterItems', JSON.stringify(newItems));
            //      //values.data.child.onUpdateItems(newItems);
            //      //values.data.child2.onUpdateItems(newItems);
            //     closeModal();
            //     helpers.hideLoadingOverlay();
            //  })
            // .catch(err => console.log(err));
            axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/shoppingcar/getventasparasegundografico/' + helpers.getDisplayDateF(dateBegin) + '/' + helpers.getDisplayDateF(dateEnd) + '',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                 //console.log(response.data.data)
                  let newItems = response.data.data;
                  let newContent = {
                        fechaInicio: dateBeginPost,
                        fechaFinal:  dateEndPost
                  }
                  //console.log(newContent)
                  //newItems.push(newContent);
                // // setRowsValue(newItems);
                // // localStorage.setItem('SubAreaFilterItems', JSON.stringify(newItems));
                  values.data.child.onViewDate(newContent);
                  values.data.child.onUpdateItems(newItems);
                  values.data.child2.onViewDate(newContent);
                  values.data.child2.onUpdateItems(newItems);
                closeModal();
                helpers.hideLoadingOverlay();
             })
            .catch(err => console.log(err));
        }
    }

    async function resetFilter() {
        helpers.showLoadingOverlay();
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/subarea/getall',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                let newItems = response.data.data;
                setRowsValue(newItems);
                localStorage.removeItem('SubAreaFilterItems');
                closeModal();
                helpers.hideLoadingOverlay();
             })
            .catch(error => console.log(error));
    }

    return (
        <>
        <button className="btn btn-success mb-1" onClick={openModal}><i class="fas fa-filter"></i></button>
        <Modal
            open={open}
            onClose={closeModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <div className="dis-flex m-1" style={{justifyContent: 'space-beetween'}}>
                    <div>
                        <label className="label-text">{values.data.resources.Label_From}</label>
                        <input type="date"  className="form-control col-md-12" name="From" defaultValue={dateBegin} onChange={handleBegin} required/>
                        </div>
                        <div>
                        <label className="label-text">{values.data.resources.Label_To}</label>
                        <input type="date"  className="form-control col-md-12" name="To" defaultValue={dateEnd} onChange={handleEnd} required/>
                        </div>
                        <div className="mt-4">
                            <button className="btn btn-primary mx-1 my-auto" onClick={onReloadData}>{values.data.resources.Datatables_language_search}</button>
                            {/* <button className="btn btn-danger mx-1 my-auto" onClick={resetFilter}>{values.data.resources.Label_FilterOff}</button> */}
                        </div>
                </div>
            </Box>
        </Modal>
            <div style={{ height: 500, width: '99%' }}>
                <DataGridPro rows={myRows} columns={columns} rowsPerPageOptions={[5, 10, 25, 50, 100]} density="compact"
                pagination
                page={page}
                localeText={{
                    'columnMenuFilter': values.data.resources.Label_Filter,
                    'columnHeaderSortIconLabel': values.data.resources.Label_Sort,
                    'columnMenuHideColumn': values.data.resources.Label_Hide,
                    'columnMenuShowColumns': values.data.resources.Label_ShowColumns,
                    'columnMenuSortAsc': values.data.resources.Label_OrderAsc,
                    'columnMenuSortDesc': values.data.resources.Label_OrderDesc,
                    'columnMenuUnsort': values.data.resources.Label_Unsorting,
                    'filterPanelColumns': values.data.resources.Label_Columns,
                    'filterPanelDeleteIconLabel': values.data.resources.Label_Delete,
                    'filterPanelInputLabel': values.data.resources.Label_GridValue,
                    'filterPanelInputPlaceholder': values.data.resources.Label_ValueFilter,
                    'filterPanelOperatorAnd': values.data.resources.Label_And,
                    'filterPanelOperatorOr': values.data.resources.Label_Or,
                    'filterPanelOperators': values.data.resources.Label_Operator,
                    'noResultsOverlayLabel': values.data.resources.Label_NoRows,
                    'noRowsLabel': "No hay datos para mostrar. Pruebe con otra fecha",
                    'toolbarColumns': values.data.resources.Label_Columns,
                    'toolbarColumnsLabel': values.data.resources.Label_SelectColumns,
                    'errorOverlayDefaultLabel': "Error",
                    'filterOperatorAfter': values.data.resources.Label_OperatorAfter,
                    'filterOperatorBefore': values.data.resources.Label_OperatorBefore,
                    'filterOperatorContains': values.data.resources.Label_OperatorContains,
                    'filterOperatorEndsWith': values.data.resources.Label_OperatorEnds,
                    'filterOperatorEquals': values.data.resources.Label_OperatorEquals,
                    'filterOperatorIs': values.data.resources.Label_OperatorIs,
                    'filterOperatorIsEmpty': values.data.resources.Label_OperatorIsEmpty,
                    'filterOperatorIsNotEmpty': values.data.resources.Label_OperatorIsNotEmpty,
                    'filterOperatorNot': values.data.resources.Label_NoRows,
                    'filterOperatorOnOrAfter': values.data.resources.Label_OperatorIsOnOrAfter,
                    'filterOperatorOnOrBefore': values.data.resources.Label_OperatorIsOnOrBefore,
                     'filterOperatorStartsWith': values.data.resources.Label_OperatorStarts,
                    'columnsPanelDragIconLabel': values.data.resources.Label_ReorderColumns,
                    'columnsPanelHideAllButton': values.data.resources.Label_HideAll,
                    'columnsPanelShowAllButton': values.data.resources.Label_ShowAll,
                    'columnsPanelTextFieldLabel': values.data.resources.Label_FindColumn,
                    'columnsPanelTextFieldPlaceholder': values.data.resources.Label_TitleColumn,
                    'filterPanelAddFilter': values.data.resources.Label_addFilter
                }}    
                pageSize={pageSize}
                onStateChange={(state) => {
                    // console.log(state)
                    localStorage.setItem('SubAreaListPagination', JSON.stringify(state.pagination))
                    localStorage.setItem('SubAreaListSorting', JSON.stringify(state.sorting))
                    localStorage.setItem('SubAreaListFiltering', JSON.stringify(state.filter))
                
                    }}
                sortModel={mySortModel}
                onSortModelChange={handleSortingChange}
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
                state={
                        filter != null ? {filter: filter} : {} 
                }
                      
            />
        </div>
    </>
    );
}