import React, { Component } from 'react';
import axios from 'axios';
import helpers from '../../Helpers';
import '../Companies/Company.css';

import Button from '@material-ui/core/Button';

export class KitProductCreate extends Component {
    static displayName = KitProductCreate.name;

    constructor(props) {
        super(props);

        this.onChangeName = this.onChangeName.bind(this);
        this.onChangeKitKey = this.onChangeKitKey.bind(this);
        this.onChangeQuantity = this.onChangeQuantity.bind(this);
        this.onChangeCountryId = this.onChangeCountryId.bind(this);
        this.onChangeProductId = this.onChangeProductId.bind(this);
        this.onChangeDescription = this.onChangeDescription.bind(this);
        this.onChangeProductCountryKey = this.onChangeProductCountryKey.bind(this);
        this.onChangeEnabled = this.onChangeEnabled.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onSubmit = this.onSubmit.bind(this);  
        this.addKitDetails = this.addKitDetails.bind(this);
        this.removeKitItem = this.removeKitItem.bind(this);

        this.state = {
            id: 0,
            name: ' ',
            resources: [],
            products: [],
            productId: 0,
            countryId: 0,
            countries: [],
            shippingCost: 0,
            quantity: 0,
            courierCost: 0,
            stock: 0,
            kitPrice: 0,
            kitKey: "",
            enabled: true,
            loading: true,
            userId: 0,
            description: "",
            productCountries: [],
            productCountryId: 0,
            productCountryKeys: [],
            productCountryKeyId: 0,
            kitDetails: [],
        };
    }

    componentDidMount() {
        this.getResources();
        this.populateData();
        helpers.setNavActive("#nav-kit-product");
        helpers.setClientValidation();

        let currentUser = helpers.getUserData();
        this.setState({
            userId: currentUser.id
        });
    }

    async onSubmit(e) {
        e.preventDefault();

        var form = document.querySelectorAll('.needs-validation')[0];

        if (!form.checkValidity())
            return;
        
        if (this.state.kitDetails.length < 1) {
            let msg = this.state.resources.Label_ErrorAddProductKit;
            helpers.notifyError(this.state.resources.Label_Error, msg);

        } else {
            this.setState({ loading: true });

            let authTokenProducts = await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME);
            const data = {
                description: this.state.description,
                country_id: this.state.countryId,
                price_kit: this.state.kitPrice.toLocaleString('en-US'),
                kitkey: this.state.kitKey,
                enabled: this.state.enabled,
                creation_user_id: this.state.userId,
            };
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME)
            };
            
            axios.post(window.config.REACT_APP_PRODUCTS_API_URL + '/kitproducts/save', data, {
                headers: headers
            })
                .then((response) => {
                    this.state.kitDetails.map(kitItem => {
                        const kitItemData = {
                            kit_id: response.data.data,
                            productcountrykey_id: kitItem.keyId,
                            quantity: kitItem.quantity,
                            price: kitItem.price,
                            total: kitItem.price * kitItem.quantity,
                            enabled: this.state.enabled,
                            creation_user_id: this.state.userId,
                        }
                        axios.post(window.config.REACT_APP_PRODUCTS_API_URL + '/kitproducts/savekitproductdetail', kitItemData,
                        {
                            'headers': headers
                        })
                        .then(response => {
                            
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                    });
                    helpers.notifySuccess(this.state.resources.Label_Success, this.state.resources.SuccessMessage_DataSavedSuccessfully);
                    this.props.history.push('/kit-product');
                })
                .catch((error) => {
                    console.log(error);

                    let msg = this.state.resources.ErrorMessage_UnknownError;
                    if (error.response.data.error_code == window.errorCodes.DuplicatedEntityName)
                        msg = this.state.resources.ErrorMessage_DuplicatedName;
                    else if (error.response.data.error_code == window.errorCodes.AssociatedEntity)
                        msg = this.state.resources.ErrorMessage_AssociatedEntity_Company_User;

                    helpers.notifyError(this.state.resources.Label_Error, msg);
                    this.setState({ loading: false });
                });
        }

        
    }  

    onCancel(e) {
        e.preventDefault();
        // this.props.history.push("/product-country");
        this.props.history.go('-1')
    }

    onChangeName(e) {
        this.setState({
            name: e.target.value
        });
    }  
    onChangeKitKey(e) {
         this.setState({
            kitKey: e.target.value
        });
    } 

    onChangeDescription(e) {
        this.setState({
            description: e.target.value
        });
    }

    onChangeProductCountryKey(e) {
        this.setState({
            productCountryKeyId: e.target.value
        });
    }
    
    onChangeCountryId(e) {
        this.setState({
            countryId: e.target.value
        });
    }

    onChangeProductId(e) {
        // e.preventDefault();
        // let productId = e.target.value.split(';')[0];
        // let productCountryId = e.target.value.split(';')[1];
        let productId = e.target.value;
        let productCountryId = 0;
        this.state.productCountries.map(productCountry => {
            if (this.state.countryId == productCountry.country_id && productCountry.product_id == productId) {
                productCountryId = productCountry.id;
            }
        });
        this.setState({
            productId: productId,
            productCountryId: productCountryId
        });
    }

    onChangeQuantity(e) {
        this.setState({
            quantity: e.target.value
        });
    }

    onChangeEnabled(e) {
        this.setState({
            enabled: e.target.checked
        });
    } 

    addKitDetails(e) {
        this.state.productCountryKeys.map(prodKey => {
            if (prodKey.id == this.state.productCountryKeyId) {
                
                let item = {
                    key: prodKey.product_key,
                    keyId: prodKey.id,
                    presentation: prodKey.description,
                    quantity: this.state.quantity,
                    price: prodKey.price,
                }
                // this.state.kitDetails.push(item);
                this.setState({
                    kitDetails: [...this.state.kitDetails, item]
                });
                let details = [...this.state.kitDetails, item];
                details.map(kitItem => { 
                    this.setState({
                        kitPrice: this.state.kitPrice + (kitItem.price * kitItem.quantity)
                    })
                });
            }
        });
    }

    removeKitItem(e) {
        e.preventDefault();
        // console.log(JSON.parse(e.target.value));
        const item = JSON.parse(e.target.value);
        let newArray = [];
        this.state.kitDetails.map(kitItem => { 
           
            if (kitItem.key == item.key && kitItem.presentation == item.presentation && kitItem.quantity == item.quantity && kitItem.price == item.price) {
                if (Number(this.state.kitPrice) - (Number(kitItem.price) * Number(kitItem.quantity)) >= 0) {
                    this.setState({
                        kitPrice: Number(this.state.kitPrice) - (Number(kitItem.price) * Number(kitItem.quantity)),
                    });
                } else {
                    this.setState({
                        kitPrice: 0,
                    });
                }
            } else {
                newArray.push(kitItem);
            }
        });

        this.setState({
            kitDetails: newArray,
        }); 
        // newArray.map(kitItem => { 
        //     this.setState({
        //         kitPrice: this.state.kitPrice + (kitItem.price * kitItem.quantity)
        //     })
        // });
    }

    render() {
        let contents =
            <form className="form row g-3 needs-validation" onSubmit={this.onSubmit} noValidate>
                {/*ENABLED*/}
                <div className="form-group col-md-6">
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" id="enabled" checked={this.state.enabled} onChange={this.onChangeEnabled} />
                        <label className="form-check-label" htmlFor="enabled">{this.state.resources.Label_Enabled}</label>
                        <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                    </div>
                </div>

                <div className="form-group col-md-6" >
                    {/* <label className=" form-label" htmlFor="kitPrice">{this.state.resources.Label_KitPrice}</label>
                    <input type="text" className="form-control" name="kitPrice" disabled="true" value={'$' + this.state.kitPrice.toLocaleString('en-US')}/> */}
                </div>

                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_KitKey}</label>
                    <input className="form-control" type="text" name="kitKey" maxLength={12} onChange={this.onChangeKitKey} defaultValue={this.state.kitKey} required/>
                </div>

                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_Description}</label>
                    <input className="form-control" type="text" name="description" maxLength={150} onChange={this.onChangeDescription} defaultValue={this.state.description} required/>
                </div>

                <div className="form-group col-md-6" >
                 <label className=" form-label">{this.state.resources.Title_Countries}</label>
                    <select className="form-control" data-val="true" name="countryid" defaultValue={this.state.countryId} onChange={this.onChangeCountryId} required>
                        <option value="">{this.state.resources.Label_SelectOption}</option>
                        {this.state.countries.map(country =>
                            <option key={country.id} value={country.id}>{country.name}</option>
                        )}
                    </select>
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                </div>

                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_Product}</label>
                    <select className="form-control" data-val="true" name="productId" disabled={this.state.countryId < 1}
                        defaultValue={this.state.productId} onChange={this.onChangeProductId} required>
                        <option value="">{this.state.resources.Label_SelectOption}</option>
                        {this.state.products.map(product =>
                            <option key={product.id} value={product.id}>{product.name}</option>
                        )}
                    </select>
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                </div>

                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Title_ProductCountryKey}</label>
                    <select className="form-control" data-val="true" name="productCountryKeyId" disabled={this.state.productId < 1}
                        defaultValue={this.state.productCountryKeyId} onChange={this.onChangeProductCountryKey} required>
                        <option value="">{this.state.resources.Label_SelectOption}</option>
                        {this.state.productCountryKeys.map(prodKey =>
                            {
                                if (this.state.productCountryId == prodKey.productcountry_id) {
                                    return (
                                        <option key={prodKey.id} value={prodKey.id}>{prodKey.description}</option>
                                    );
                                }
                            })}
                        
                    </select>
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                </div>
            
                <div className="form-group col-md-6" >

                    <label className=" form-label">{this.state.resources.Label_Quantity}</label>
                    <select className="form-control" data-val="true" name="quantity" defaultValue={this.state.quantity} onChange={this.onChangeQuantity} required>
                        <option value="">{this.state.resources.Label_SelectOption}</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                    </select>
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                    
                </div>
                
                <div className="text-center col-md-12">
                    <button className="btn btn-primary" type='button' disabled={this.state.productCountryKeyId < 1 || this.state.quantity < 1} onClick={this.addKitDetails}>{this.state.resources.Label_ButtonAdd}</button>
                </div>

                <div className="col-md-12">
                    <div className="table-responsive">
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th>{this.state.resources.Label_KitProductKey}</th>
                                    <th>{this.state.resources.Label_Presentation}</th>
                                    <th  style={{textAlign: 'right'}}>{this.state.resources.Label_Quantity}</th>
                                    <th  style={{textAlign: 'right'}}>{this.state.resources.Label_UnitPrice}</th>
                                    <th  style={{textAlign: 'right'}}>Total</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.kitDetails.map(kitDetail =>
                                    <tr>
                                        <td>{kitDetail.key}</td>
                                        <td>{kitDetail.presentation}</td>
                                        <td style={{textAlign: 'right'}}>{kitDetail.quantity}</td>
                                        <td style={{textAlign: 'right'}}>${kitDetail.price.toLocaleString('en-US')}</td>
                                        <td style={{textAlign: 'right'}}>${(kitDetail.quantity * kitDetail.price).toLocaleString('en-US')}</td>
                                        <td className="text-center">
                                            <button className="btn btn-danger" value={JSON.stringify(kitDetail)} onClick={this.removeKitItem}><i className="fa fa-trash" style={{ pointerEvents: 'none' }}></i></button>
                                        </td>
                                    </tr>
                                )}
                                <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td style={{textAlign: 'right'}}>${this.state.kitPrice.toLocaleString('en-US')}</td>
                                        <td></td>
                                    </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                {/**Buttons Header */}
                 <div className="buttons-head row">
                    <div className=" buttons-childs">
                        <button type="submit" className="btn btn-primary">{this.state.resources.ButtonLabel_Save}</button>
                        <button className="btn btn-danger" onClick={this.onCancel}>{this.state.resources.ButtonLabel_Cancel}</button>
                    </div>
                 </div>
            </form>;

        return (

           <div>
                
           
            <div className="mt-2">

                <ol className="breadcrumb my-4">
                    <li className="breadcrumb-item active">{this.state.resources.Label_KitProducts}</li>
                </ol>
                <div className="card">
                    <div className="card-body">
                        {this.state.loading ? helpers.showLoadingOverlay() : helpers.hideLoadingOverlay()}
                        {contents}
                    </div>
                </div>
                </div>
        </div> 
        );
    }

    async getResources() {
        const resourceVars = [];
        resourceVars.push("Label_Product");
        resourceVars.push("Label_Name");
        resourceVars.push("Label_Description");
        resourceVars.push("Label_BusinessArea");
        resourceVars.push("Label_Enabled");
        resourceVars.push("Label_Success");
        resourceVars.push("Label_SelectOption");
        resourceVars.push("ButtonLabel_Save");
        resourceVars.push("ButtonLabel_Cancel");
        resourceVars.push("Label_Error");
        resourceVars.push("ErrorMessage_RequiredField");
        resourceVars.push("ErrorMessage_DuplicatedName");
        resourceVars.push("ErrorMessage_UnknownError");
        resourceVars.push("ErrorMessage_AssociatedEntity_Company_User");
        resourceVars.push("ErrorMessage_ErrorWhileUploadingImage");
        resourceVars.push("ErrorMessage_InvalidEmail");
        resourceVars.push("SuccessMessage_DataSavedSuccessfully");
        resourceVars.push("Label_ProductCountry");
        resourceVars.push("Title_Courier");
        resourceVars.push("Title_Countries");
        resourceVars.push("Label_ShippingCost");
        resourceVars.push("Label_ProductionCost");
        resourceVars.push("Label_CourierCost");
        resourceVars.push("Label_KitProducts");
        resourceVars.push("Label_KitPrice");
        resourceVars.push("Label_KitKey");
        resourceVars.push("Title_ProductCountryKey");
        resourceVars.push("Label_Quantity");
        resourceVars.push("Label_ButtonAdd");
        resourceVars.push("Label_UnitPrice");
        resourceVars.push("Label_KitProductKey");
        resourceVars.push("Label_Presentation");
        resourceVars.push("Label_ErrorAddProductKit");

        const headers = {
            'Content-Type': 'application/json'
        };

        axios.post(window.config.REACT_APP_SECURITY_API_URL + '/languageresource/getbyvars/' + helpers.getUiLanguage(), resourceVars, {
            headers: headers
        })
            .then((response) => {
                const data = response.data.data;
                this.setState({ resources: data});
            })
            .catch((error) => {
                console.log(error);
                this.setState({ loading: false });
            });
    }

    async populateData() {
        this.setState({
            loading: true
        });

        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/country/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                // console.log(data);
                this.setState({ countries: data});
                
                // order alphabetically
                this.state.countries.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                // console.log(this.state.countries);
            })
            .catch(function (error) {
                console.log(error);
            });
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/product/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ products: data});
                // order alphabetically
                this.state.products.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                // console.log(this.state.brands);
            })
            .catch(function (error) {
                console.log(error);
            });
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/productcountry/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ productCountries: data, loading: false });
                // order alphabetically
                this.state.productCountries.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                // console.log(this.state.brands);
            })
            .catch(function (error) {
                console.log(error);
            });
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/productcountrykey/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ productCountryKeys: data});
                // order alphabetically
                this.state.productCountryKeys.sort((a,b) => a.description < b.description ? -1 : +(a.description > b.description))
                // console.log(this.state.brands);
            })
            .catch(function (error) {
                console.log(error);
            });
    }
}
