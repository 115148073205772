import React, { Component } from 'react';
import axios from 'axios';
import $ from 'jquery';
import helpers from '../../Helpers'

export class MediaCreate extends Component {
    static displayName = MediaCreate.name;

    constructor(props) {
        super(props);

        this.onChangeName = this.onChangeName.bind(this);
        this.onChangeEnabled = this.onChangeEnabled.bind(this);
        this.onChangeCountry = this.onChangeCountry.bind(this);
        this.onChangeAgency = this.onChangeAgency.bind(this);
        this.onChangeMediaGroup = this.onChangeMediaGroup.bind(this);
        this.onChangeMediaType = this.onChangeMediaType.bind(this);
        this.onChangeProductCountryId = this.onChangeProductCountryId.bind(this);
        this.onCountSpan = this.onCountSpan.bind(this);
        this.removeSalesAreaItem = this.removeSalesAreaItem.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onSubmit = this.onSubmit.bind(this);  

        this.state = {
            id: 0,
            name: '',
            countries: [],
            countryId: null,
            groups: [],
            groupsAll: [],
            mediagroupId: null,
            types: [],
            mediatypeId: null,
            agenciesAll: [],
            agencies: [],
            agencyId: null,
            documentation: '',
            enabled: true,
            loading: true,
            resources: [],
            userId: 0,
            product_id: null,
            productcountries: [],
            productCountryId: null,
            productsArray:[],
            span:[],
            mediaRestricted:null,
            mediaRestrictedId:null,
        };
    }

    componentDidMount() {
        this.getResources();
        this.populateData();
        helpers.setNavActive("#nav-Media");

        let currentUser = helpers.getUserData();
        this.setState({
            userId: currentUser.id
        });
    }

    async onSubmit(e) {
        e.preventDefault();
        if (this.state.productsArray.length == 0) {        
            document.querySelector("#productsError").style.display = 'block';
           this.state.loading = false;
           helpers.hideLoadingOverlay();
           return;
        }
        this.setState({ loading: true });

        let array = [];
        this.state.productsArray.map(item => {
        array.push(item.id);
                    });
        const data = {
            name: this.state.name,
            country_id: this.state.countryId,
            agency_id: this.state.agencyId,
            mediatype_id: this.state.mediatypeId,
            mediagroup_id: this.state.mediagroupId,
            enabled: this.state.enabled,
            creation_user_id: this.state.userId,
        };
        // console.log(data);
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME)
        };

        axios.post(window.config.REACT_APP_PRODUCTS_API_URL + '/Media/save', data, {
            headers: headers
        })
            .then((response) => {
              
                console.log(response)
                console.log(response.data)
                console.log(response.data.data)
                const dataproduct = {
                    //id:  parseInt(this.props.match.params.id),
                    media_id: response.data.data,
                    product_countries_id:  array.toString(),
                    creation_user_id:  this.state.userId,
                    last_update_user_id:  this.state.userId,
                };
                axios.post(window.config.REACT_APP_PRODUCTS_API_URL + '/mediarestrictedproducts/save', dataproduct, {
                    headers: headers
                })
                    .then((response) => {
                        helpers.notifySuccess(this.state.resources.Label_Success, this.state.resources.SuccessMessage_DataSavedSuccessfully);
                        this.props.history.push('/Media');
                    })
                    .catch((error) => {
                        console.log(error);
        
                        let msg = this.state.resources.ErrorMessage_UnknownError;
                        if (error.response.data.error_code == window.errorCodes.DuplicatedEntityName)
                        msg = this.state.resources.ErrorMessage_DuplicatedName;
                    else if (error.response.data.error_code == window.errorCodes.AssociatedEntity)
                        msg = this.state.resources.ErrorMessage_AssociatedEntity_Company_User;
                        helpers.notifyError(this.state.resources.Label_Error, msg);
                        this.setState({ loading: false });
                    });
                // helpers.notifySuccess(this.state.resources.Label_Success, this.state.resources.SuccessMessage_DataSavedSuccessfully);
                // this.props.history.push('/Media');
            })
            .catch((error) => {
                console.log(error);

                let msg = this.state.resources.ErrorMessage_UnknownError;
                if (error.response.data.error_code == window.errorCodes.DuplicatedEntityName)
                    msg = this.state.resources.ErrorMessage_DuplicatedName;

                helpers.notifyError(this.state.resources.Label_Error, msg);
                this.setState({ loading: false });
            });
    }  

    onCancel(e) {
        e.preventDefault();
        this.props.history.push("/Media");
    }

    onChangeName(e) {
        this.setState({
            name: e.target.value
        });
    }
    
    onChangeCountry(e) {
        this.setState({
            countryId: e.target.value,
        });
        this.populateDataCountry(e.target.value);
    }

    onChangeProductCountryId(e) {
        // this.setState({
        //     productCountryId: e.target.value
        // });
        if (e.target.value) {
            let item = JSON.parse(e.target.value);
            if (this.state.productsArray.length > 0) {
                let newArray = this.state.productsArray;
                let count = 0;
                for (let i = 0; i < newArray.length; i++) {
                    if (newArray[i].id == item.id) {
                        count++;
                    }
                }           
                if (count == 0) {
                    newArray.push(item);
                }
                 console.log(newArray);
                this.state.productsArray = newArray;
                 console.log(this.state.productsArray);
                this.setState({
                    productsArray: newArray
                });
            } else {
                this.setState({
                    productsArray: this.state.productsArray.concat(item)
                });
                this.state.productsArray = this.state.productsArray.concat(item);
            }
        }
        if(this.state.productsArray.length > 0){
            document.querySelector("#productsError").style.display = 'none';
        }
        //console.log(this.state.productCountryId)
        console.log(this.state.productsArray)
    }

    onCountSpan(e) {
        this.setState({
            span : e.target.value
        });
        // console.log(this.state.span)
        // if(!this.state.span == 0)
       // document.querySelector('#productsError').style.display = 'block';
    }

    removeSalesAreaItem(e) {
        //document.querySelector('#productsError').style.display = 'none';
        // e.preventDefault();
        if (e.target?.value) {
            let item = JSON.parse(e.target.value);
            // console.log(item);    
            let newArray = this.state.productsArray;
            for (let i = 0; i < newArray.length; i++) {
                if (newArray[i].id == item.id) {
                    newArray.splice(i, 1);
                }
            }
            // console.log(newArray);
            this.setState({
                productsArray: newArray
            });
        }
        
    }


    onChangeAgency(e) {
        this.setState({
            agencyId: e.target.value
        });
    }

    onChangeMediaGroup(e) {
         this.setState({
            mediagroupId: e.target.value
        });
    }

    onChangeMediaType(e) {
        this.setState({
           mediatypeId: e.target.value
       });
   }

    onChangeEnabled(e) {
        this.setState({
            enabled: e.target.checked
        });
    } 

    render() {
        let contents = this.state.loading
            ? helpers.showLoadingOverlay()
            :
            <form className="form row g-3 needs-validation" onSubmit={this.onSubmit}>
                <div className="form-group col-md-12">
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" id="enabled" defaultChecked={this.state.enabled} onChange={this.onChangeEnabled} />
                        <label className="form-check-label" htmlFor="enabled">{this.state.resources.Label_Enabled}</label>
                        <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                    </div>
                </div>
                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_Name}</label>
                    <input className="form-control" type="text" name="name" onChange={this.onChangeName} value={this.state.name} required />
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                </div>
                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_Country}</label>
                    <select className="form-control" data-val="true" name="countryid" defaultValue={this.state.countryId} onChange={this.onChangeCountry} required>
                        <option value="">{this.state.resources.Label_SelectOption}</option>
                        {this.state.countries.map(country =>
                            <option key={country.id} value={country.id}>{country.name}</option>
                        )}
                    </select>                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                </div>
                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Title_Agency}</label>
                    <select className="form-control" data-val="true" name="agencyid" defaultValue={this.state.agencyId} onChange={this.onChangeAgency} required>
                        <option value="">{this.state.resources.Label_SelectOption}</option>
                        {this.state.agenciesAll.map(country =>
                            <option key={country.id} value={country.id}>{country.name}</option>
                        )}
                    </select>                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                </div>
                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Title_MediaGroup}</label>
                    <select className="form-control" data-val="true" name="mediagroupId" defaultValue={this.state.mediagroupId} onChange={this.onChangeMediaGroup} required>
                        <option value="">{this.state.resources.Label_SelectOption}</option>
                        {this.state.groupsAll.map(country =>
                            <option key={country.id} value={country.id}>{country.name}</option>
                        )}
                    </select>                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                </div>
                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Title_MediaType}</label>
                    <select className="form-control" data-val="true" name="mediatypeId" defaultValue={this.state.mediatypeId} onChange={this.onChangeMediaType} required>
                        <option value="">{this.state.resources.Label_SelectOption}</option>
                        {this.state.types.map(country =>
                            <option key={country.id} value={country.id}>{country.name}</option>
                        )}
                    </select>                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                </div>
                
                <div className="form-group col-md-6" >
                <label className=" form-label">{this.state.resources.Label_ProductCountry}</label>
                    <select className="form-control" data-val="true" name="productcountryid" onChange={this.onChangeProductCountryId} required>
                        <option value="">{this.state.resources.Label_SelectOption}</option>
                        {this.state.productcountries.map(productcountry =>
                            <option key={productcountry.id} value={JSON.stringify(productcountry)}
                            hidden={productcountry.country_id == this.state.countryId ? false : true }
                             >{productcountry.name}</option>
                        )}{/*selected={this.state.product_id ==  productcountry.id} */}
                    </select>
                    <div id="productsError" style={{color: 'red',display:'none'}}>{helpers.getUiLanguage() == 'language1' ? 'Debe ingresar al menos un producto' : 'You must add at least one product'}</div> 
                </div>
                    <div className="form-group col-md-4 p-3 m-2" style={{borderRadius: '10px', border: '1px solid #80808054', display: 'flex', flexFlow: 'wrap'}} required>
                    {this.state.productsArray.map(products => (
                        <div className="p-2 m-2" style={{ backgroundColor: 'rgb(255 13 36 / 58%)', borderColor: '#dc3545', display: 'flex', maxWidth: '190px', maxHeight: '50px', justifyContent: 'space-between'}}>
                            <span className="pt-2" onChange={this.onCountSpan} >{products.name}</span>
                            <button className='btn' type='button' value={JSON.stringify(products)} onClick={this.removeSalesAreaItem}>X</button>
                        </div>
                    ))}
                </div>


                {/**Buttons Header */}
                 <div className="buttons-head row">
                    <div className=" buttons-childs">
                        <button type="submit" className="btn btn-primary">{this.state.resources.ButtonLabel_Save}</button>
                        <button className="btn btn-danger" onClick={this.onCancel}>{this.state.resources.ButtonLabel_Cancel}</button>
                    </div>
                 </div>
                {/* <div className="form-group col-12 text-end">
                    <button type="submit" className="btn btn-primary">{this.state.resources.ButtonLabel_Save}</button>
                    <button className="btn btn-negative" onClick={this.onCancel}>{this.state.resources.ButtonLabel_Cancel}</button>
                </div > */}
            </form>
            helpers.hideLoadingOverlay();

        return (
            <div className="mt-2">
                {/* <h1 className="mt-4">{this.state.resources.Title_Areas}</h1> */}
                <ol className="breadcrumb my-4">
                    <li className="breadcrumb-item active">{this.state.resources.Title_Media}</li>
                </ol>
                <div className="card">
                    <div className="card-body">
                        {contents}
                    </div>
                </div>
            </div>
        );
    }

    async getResources() {
        const resourceVars = [];
        resourceVars.push("Title_Media");
        resourceVars.push("Label_Name");
        resourceVars.push("Label_Country");
        resourceVars.push("Title_MediaGroup");
        resourceVars.push("Title_MediaType");
        resourceVars.push("Title_Agency");
        resourceVars.push("Title_Documentation");
        resourceVars.push("Label_Description");
        resourceVars.push("Label_Enabled");
        resourceVars.push("Label_Success");
        resourceVars.push("ButtonLabel_Save");
        resourceVars.push("ButtonLabel_Cancel");
        resourceVars.push("Label_Error");
        resourceVars.push("ErrorMessage_RequiredField");
        resourceVars.push("ErrorMessage_DuplicatedName");
        resourceVars.push("ErrorMessage_UnknownError");
        resourceVars.push("SuccessMessage_DataSavedSuccessfully");
        resourceVars.push("Label_SelectOption");
        resourceVars.push("Title_Ticket");
        resourceVars.push("Title_Invoice");
        resourceVars.push("Label_ProductCountry");

        const headers = {
            'Content-Type': 'application/json'
        };

        axios.post(window.config.REACT_APP_SECURITY_API_URL + '/languageresource/getbyvars/' + helpers.getUiLanguage(), resourceVars, {
            headers: headers
        })
            .then((response) => {
                const data = response.data.data;
                this.setState({ resources: data, loading: false });
            })
            .catch((error) => {
                console.log(error);
                this.setState({ loading: false });
            });

            axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/country/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ countries: data, loading: false });
                // order alphabetically
                this.state.countries.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                // console.log(this.state.countries);
            })
            .catch(function (error) {
                console.log(error);
            });

            axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/agency/getAll',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ agenciesAll: data, loading: false });
                // order alphabetically
                this.state.agenciesAll.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                // console.log(this.state.countries);
            })
            .catch(function (error) {
                console.log(error);
            });

            axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/MediaType/getall',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ types: data, loading: false });
                // order alphabetically
                this.state.types.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                // console.log(this.state.countries);
            })
            .catch(function (error) {
                console.log(error);
            });

            axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/MediaGroup/getall',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ groupsAll: data, loading: false });
                // order alphabetically
                this.state.groupsAll.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                // console.log(this.state.countries);
            })
            .catch(function (error) {
                console.log(error);
            });
            
            axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/productcountry/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ productcountries: data, loading: false });
                // order alphabetically
                this.state.productcountries.sort((a, b) => a.product_name < b.product_name ? -1 : +(a.product_name > b.product_name))
               
                
                helpers.hideLoadingOverlay();

            })
            .catch(function (error) {
                console.log(error);
            });

    }

    populateDataCountry(countryId)
    {
        this.setState({
            agencies: this.state.agenciesAll.filter((a) => a.country_id == countryId),
            groups: this.state.groupsAll.filter((a) => a.country_id == countryId),
            loading: false
        })
    }

    async populateData() {
        this.setState({
            loading: false
        });
    }
}