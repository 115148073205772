import React, { Component } from 'react';
import axios from 'axios';
import helpers from '../../Helpers'
import $ from 'jquery';

import '../../custom.css';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import startsWith from 'lodash.startswith';

export class UserCreate extends Component {
    static displayName = UserCreate.name;

    constructor(props) {
        super(props);

        this.onChangeLastName = this.onChangeLastName.bind(this);
        this.onChangeFirstName = this.onChangeFirstName.bind(this);
        this.onChangeDocNumber = this.onChangeDocNumber.bind(this);
        this.onChangeDateOfBirth = this.onChangeDateOfBirth.bind(this);
        this.onChangePhone = this.onChangePhone.bind(this);
        this.onChangeMobilePhone = this.onChangeMobilePhone.bind(this);
        this.onChangeWorkEmail = this.onChangeWorkEmail.bind(this);
        this.onChangePersonalEmail = this.onChangePersonalEmail.bind(this);
        this.onChangeAddress = this.onChangeAddress.bind(this);
        this.onChangeCountry = this.onChangeCountry.bind(this);
        this.onChangeStateOrProvince = this.onChangeStateOrProvince.bind(this);
        this.onChangeTown = this.onChangeTown.bind(this);
        this.onChangePostalCode = this.onChangePostalCode.bind(this);
        this.onChangeUserLdap = this.onChangeUserLdap.bind(this);
        this.onChangePasswordLdap = this.onChangePasswordLdap.bind(this);
        this.onChangePermissionGroup = this.onChangePermissionGroup.bind(this);
        this.onChangeWorkSchedule = this.onChangeWorkSchedule.bind(this);
        this.onChangeWorkShift = this.onChangeWorkShift.bind(this);
        this.onChangeArea = this.onChangeArea.bind(this);
        this.onChangeSubArea = this.onChangeSubArea.bind(this);
        this.onChangePbxId = this.onChangePbxId.bind(this);
        this.onChangeLanguageResource = this.onChangeLanguageResource.bind(this);
        this.onChangeCompanyIds = this.onChangeCompanyIds.bind(this);
        this.onChangeEmployerCompany = this.onChangeEmployerCompany.bind(this);
        this.onChangeAdmissionDate = this.onChangeAdmissionDate.bind(this);
        this.onChangeAdditionalData = this.onChangeAdditionalData.bind(this);

        this.onChangeEnabled = this.onChangeEnabled.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onSubmit = this.onSubmit.bind(this);  

        this.state = {
            id: 0,
            lastName: '',
            firstName: '',
            docNumber: null,
            dateOfBirth: null,
            phone: null,
            mobilePhone: null,
            workEmail: null,
            personalEmail: null,
            address: null,
            country: null,
            stateOrProvince: null,
            town: null,
            postalCode: null,
            userLdap: null,
            passwordLdap: null,
            permissionGroupId: null,
            workScheduleId: null,
            workShiftId: null,
            areaId: null,
            subAreaId: null,
            pbxId: null,
            languageResource: 'language1',
            companyIds: [],
            employerCompanyId: null,
            admissionDate: null,
            enabled: true,
            teamLeaderUserId: null,
            teamLeaderUserFullName: '',
            supervisorUserId: null,
            supervisorUserFullName: '',
            additionalData: '',
            loading: true,
            permissionGroups: [],
            workSchedules: [],
            workShifts: [],
            areas: [],
            subAreas: [],
            companies: [],
            sellingcompanies: [],
            users: [],
            resources: [],
            userId: 0,
        };
    }

    componentDidMount() {
        this.getResources();
        this.populateData();
        helpers.setNavActive("#nav-users");
        helpers.setClientValidation();

        let currentUser = helpers.getUserData();
        this.setState({
            userId: currentUser.id
        });
        document.querySelector('#phoneError').style.display = 'none';
        document.querySelector('#mobilePhoneError').style.display = 'none';
    }

    async onSubmit(e) {
        e.preventDefault();

        var form = document.querySelectorAll('.needs-validation')[0];

        if (!form.checkValidity())
            return;

        if (this.state.companyIds.length == 0) {
            helpers.notifyError(this.state.resources.Label_Error, this.state.resources.ErrorMessage_User_MustHaveOneCompany);
            return;
        }

        for (let i = 0; i < this.state.users.length; i++) {
            if (this.state.userLdap == this.state.users[i].user_ldap.toString().toLocaleLowerCase()) {
                // console.log(this.state.userLdap)
                // console.log(this.state.users[i].user_ldap.toString().toLocaleLowerCase())
                helpers.notifyWarning(this.state.resources.Label_Error, this.state.resources.ErrorMessage_UserLdapAlreadyExist);
                return;
            }
        }

        this.setState({ loading: true });

        const data = {
            enabled: this.state.enabled,
            last_name: this.state.lastName,
            first_name: this.state.firstName,
            doc_number: this.state.docNumber,
            date_of_birth: this.state.dateOfBirth,
            phone: this.state.phone,
            mobile_phone: this.state.mobilePhone,
            work_email: this.state.workEmail,
            personal_email: this.state.personalEmail,
            address: this.state.address,
            country: this.state.country,
            state_or_province: this.state.stateOrProvince,
            town: this.state.town,
            postal_code: this.state.postalCode,
            user_ldap: this.state.userLdap,
            password_ldap: this.state.passwordLdap,
            permission_group_id: this.state.permissionGroupId,
            work_schedule_id: this.state.workScheduleId,
            work_shift_id: this.state.workShiftId,
            area_id: this.state.areaId,
            pbx_id: this.state.pbxId,
            language_resource: this.state.languageResource,
            employer_company_id: this.state.employerCompanyId,
            admission_date: this.state.admissionDate,
            team_leader_user_id: this.state.teamLeaderUserId,
            supervisor_user_id: this.state.supervisorUserId,
            additional_data: this.state.additionalData,
            company_ids: this.state.companyIds,
            creation_user_id: this.state.userId,
            last_update_user_id: this.state.userId
        };

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_SECURITY_API_NAME)
        };

        let errorCount = 0;
        if(document.querySelector('#phoneError').style.display == 'block' ||
            document.querySelector('#mobilePhoneError').style.display == 'block') {
            errorCount++;
        }

        if (errorCount < 1) {
            axios.post(window.config.REACT_APP_SECURITY_API_URL + '/user/save', data, {
                headers: headers
            })
            .then((response) => {
                helpers.notifySuccess(this.state.resources.Label_Success, this.state.resources.SuccessMessage_DataSavedSuccessfully);
                this.props.history.push('/users');
            })
            .catch((error) => {
                console.log(error);

                let msg = this.state.resources.ErrorMessage_UnknownError;
                if (error.response.data.error_code == window.errorCodes.DuplicatedPbxId
                    || error.response.data.error_code == window.errorCodes.DataInconsistency)
                    msg = error.response.data.message;

                helpers.notifyError(this.state.resources.Label_Error, msg);
                this.setState({ loading: false });
            });
        }else{
            let msg = helpers.getUiLanguage() == 'language0' ? 'Phone Data Invalid' : 'Telefono No Valido';

            helpers.notifyError(this.state.resources.Label_Error, msg);
            this.setState({ loading: false });
        }
        
    }  

    onCancel(e) {
        e.preventDefault();
        this.props.history.push("/users");
    }

    onChangeEnabled(e) {
        this.setState({
            enabled: e.target.checked
        });
    } 
    onChangeLastName(e) {
        this.setState({
            lastName: e.target.value,
            userLdap: (this.state.firstName.charAt(0) + this.state.lastName).toLowerCase()
        });
        // for (let i = 0; i < this.state.users.length; i++) {
        //     if ((this.state.firstName.charAt(0) + e.target.value).toString().toLocaleLowerCase() == this.state.users[i].user_ldap.toString().toLocaleLowerCase()) {
        //         helpers.notifyWarning(this.state.resources.Label_Error, this.state.resources.ErrorMessage_UserLdapAlreadyExist);
        //     }
        // }

    }  
    onChangeFirstName(e) {
        this.setState({
            firstName: e.target.value,
            userLdap: (e.target.value.charAt(0) + this.state.lastName).toLowerCase()
        });
        // for (let i = 0; i < this.state.users.length; i++) {
        //     if ((e.target.value.charAt(0) + this.state.lastName).toString().toLocaleLowerCase() == this.state.users[i].user_ldap.toString().toLocaleLowerCase()) {
        //         helpers.notifyWarning(this.state.resources.Label_Error, this.state.resources.ErrorMessage_UserLdapAlreadyExist);              
        //     }
        // }
    }  
    onChangeDocNumber(e) {
        this.setState({
            docNumber: e.target.value
        });
    }  
    onChangeDateOfBirth(e) {
        this.setState({
            dateOfBirth: e.target.value
        });
    }  
    onChangePhone(e) {
        this.setState({
            phone: e
        });
    }  
    onChangeMobilePhone(e) {
        this.setState({
            mobilePhone: e
        });
    }  
    onChangeWorkEmail(e) {
        this.setState({
            workEmail: e.target.value
        });
    }  
    onChangePersonalEmail(e) {
        this.setState({
            personalEmail: e.target.value
        });
    }
    onChangeAddress(e) {
        this.setState({
            address: e.target.value
        });
    }
    onChangeCountry(e) {
        this.setState({
            country: e.target.value
        });
    }
    onChangeStateOrProvince(e) {
        this.setState({
            stateOrProvince: e.target.value
        });
    }
    onChangeTown(e) {
        this.setState({
            town: e.target.value
        });
    }
    onChangePostalCode(e) {
        this.setState({
            postalCode: e.target.value
        });
    }
    onChangeUserLdap(e) {
        this.setState({
            userLdap: e.target.value
        });
    }
    onChangePasswordLdap(e) {
        this.setState({
            passwordLdap: e.target.value
        });
    }
    onChangePermissionGroup(e) {
        this.setState({
            permissionGroupId: e.target.value
        });
    }
    onChangeWorkSchedule(e) {
        this.setState({
            workScheduleId: e.target.value
        });
    }
    onChangeWorkShift(e) {
        this.setState({
            workShiftId: e.target.value
        });
    }
    async onChangeArea(e) {
        let areaId = e.target.value === "" ? null : parseInt(e.target.value);

        this.setState({
            areaId: areaId,
            subAreaId: 0,
            subAreas: []
        });

        // get sub areas
        if (areaId > 0) {
            axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/subarea/getbyarea/' + areaId,
                {
                    'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
                })
                .then(response => {
                    const data = response.data.data;
                    this.setState({ subAreas: data, loading: false });
                })
                .catch(function (error) {
                    console.log(error);
                    this.setState({ loading: false });
                });
        }
    }
    onChangeSubArea(e) {
        this.setState({
            subAreaId: e.target.value
        });
    }
    onChangePbxId(e) {
        this.setState({
            pbxId: e.target.value
        });
    }
    onChangeLanguageResource(e) {
        this.setState({
            languageResource: e.target.value
        });
    }
    onChangeCompanyIds(e) {
        let id = parseInt(e.target.value);
        let _ids = this.state.companyIds;
        const index = _ids.indexOf(id);

        if (e.target.checked == true) {
            _ids.push(id);
        } else {
            if (index > -1)
                _ids.splice(index, 1);
        }

        this.setState({
            companyIds: _ids
        });
    } 
    onChangeEmployerCompany(e) {
        this.setState({
            employerCompanyId: e.target.value
        });
    }
    onChangeAdmissionDate(e) {
        this.setState({
            admissionDate: e.target.value
        });
    }
    setTeamLeaderUserId = (id, fullName) => () => {
        this.setState({
            teamLeaderUserId: id,
            teamLeaderUserFullName: fullName
        });
    }
    removeTeamLeaderUserId = () => () => {
        this.setState({
            teamLeaderUserId: null,
            teamLeaderUserFullName: ''
        });
    }
    setSupervisorUserId = (id, fullName) => () => {
        this.setState({
            supervisorUserId: id,
            supervisorUserFullName: fullName
        });
    }
    removeSupervisorUserId = () => () => {
        this.setState({
            supervisorUserId: null,
            supervisorUserFullName: ''
        });
    }
    onChangeAdditionalData(e) {
        this.setState({
            additionalData: e.target.value
        });
    }

    render() {
        let contents = 
            <form className="form g-3 needs-validation" onSubmit={this.onSubmit} noValidate> {/**class: row */}
                {/*ENABLED*/}
                <div className="form-group col-md-12">
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" id="enabled" defaultChecked={this.state.enabled} onChange={this.onChangeEnabled} />
                        <label className="form-check-label" htmlFor="enabled">{this.state.resources.Label_Enabled}</label>
                        <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                    </div>
                </div>

                <div className="form-group row">
                    <div className="form-group col-md-12">
                        <label className="data-labels">{this.state.resources.Label_PersonalData}</label>
                    </div>
                    {/*LAST NAME / FIRST NAME*/}
                    <div className="form-group col-md-3" >
                        <label className=" form-label">{this.state.resources.Label_LastName}</label>
                        <input className="form-control" type="text" name="lastname" onChange={this.onChangeLastName} value={this.state.lastName} required />
                        <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                    </div>
                    <div className="form-group col-md-3" >
                        <label className=" form-label">{this.state.resources.Label_FirstName}</label>
                        <input className="form-control" type="text" name="firstname" onChange={this.onChangeFirstName} value={this.state.firstName} required />
                        <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                    </div>
                    {/*DOC NUMBER / DATE OF BIRTH*/}
                    <div className="form-group col-md-3" >
                        <label className=" form-label">{this.state.resources.Label_DocNumber}</label>
                        <input className="form-control" type="text" name="docnumber" onChange={this.onChangeDocNumber} value={this.state.docNumber} />
                    </div>
                    <div className="form-group col-md-3" >
                        <label className=" form-label">{this.state.resources.Label_DateOfBirth}</label>
                        <input className="form-control" type="date" name="dateofbirth" onChange={this.onChangeDateOfBirth} defaultValue={this.state.dateOfBirth} />
                    </div>
                    {/*PHONE / MOBILE PHONE*/}
                    <div className="form-group col-md-3" >
                        <label className=" form-label">{this.state.resources.Label_Phone}</label>
                        {/* <input className="form-control" type="text" name="phone" onChange={this.onChangePhone} value={this.state.mobilePhone} /> */}
                        <PhoneInput
                        country={'cl'}
                        value={this.state.phone}
                        onChange={this.onChangePhone}
                        masks={{ cl: '(.) ....-....' }}
                        inputStyle={{ width: '100%' }}
                        onlyCountries={['cl', 'ar', 'mx', 'uk']}
                        isValid={(value, country) => { 
                            if (startsWith(value, country.dialCode) || startsWith(country.dialCode, value)) {
                                if (document.querySelector('#phoneError')) {
                                    document.querySelector('#phoneError').style.display = 'none';
                                }
                                return true;
                            } else {
                                if (document.querySelector('#phoneError')) {
                                    document.querySelector('#phoneError').style.display = 'block';
                                }
                                return false;
                            }
                        }}
                    />
                    <div style={{color: 'red'}} class="p-1 pl-2" id="phoneError">{this.state.resources.ErrorMessage_InvalidPhoneFormat}</div>  
                    </div>
                    <div className="form-group col-md-3" >
                        <label className=" form-label">{this.state.resources.Label_MobilePhone}</label>
                        {/* <input className="form-control" type="text" name="mobilephone" onChange={this.onChangeMobilePhone} value={this.state.mobilePhone} /> */}
                        <PhoneInput
                        country={'cl'}
                        value={this.state.mobilePhone}
                        onChange={this.onChangeMobilePhone}
                        masks={{ cl: '(.) ....-....' }}
                        inputStyle={{ width: '100%' }}
                        onlyCountries={['cl', 'ar', 'mx', 'uk']}
                        isValid={(value, country) => { 
                            // console.log(value);
                            // console.log(country);
                            if (startsWith(value, country.dialCode) || startsWith(country.dialCode, value)) {
                                if (document.querySelector('#mobilePhoneError')) {
                                    document.querySelector('#mobilePhoneError').style.display = 'none';
                                }
                                return true;
                            } else {
                                if (document.querySelector('#mobilePhoneError')) {
                                    document.querySelector('#mobilePhoneError').style.display = 'block';
                                }
                                return false;
                            }
                        }}
                    />
                    <div style={{color: 'red'}} class="p-1 pl-2" id="mobilePhoneError">{this.state.resources.ErrorMessage_InvalidPhoneFormat}</div>
                    </div>

                    {/*WORK EMAIL / PERSONAL EMAIL*/}
                    <div className="form-group col-md-3" >
                        <label className=" form-label">{this.state.resources.Label_WorkEmail}</label>
                        <input className="form-control" type="email" name="workemail" onChange={this.onChangeWorkEmail} value={this.state.workEmail} />
                        <div className="invalid-feedback">{this.state.resources.ErrorMessage_InvalidEmail}</div>
                    </div>
                    <div className="form-group col-md-3" >
                        <label className=" form-label">{this.state.resources.Label_PersonalEmail}</label>
                        <input className="form-control" type="email" name="personalemail" onChange={this.onChangePersonalEmail} value={this.state.personalEmail} />
                        <div className="invalid-feedback">{this.state.resources.ErrorMessage_InvalidEmail}</div>
                    </div>

                    {/*ADDRESS / COUNTRY*/}
                    <div className="form-group col-md-4" >
                        <label className=" form-label">{this.state.resources.Label_Address}</label>
                        <input className="form-control" type="text" name="address" onChange={this.onChangeAddress} value={this.state.address} />
                    </div>
                    <div className="form-group col-md-4" >
                        <label className=" form-label">{this.state.resources.Label_Country}</label>
                        <input className="form-control" type="text" name="country" onChange={this.onChangeCountry} value={this.state.country} />
                    </div>

                    {/*STATE OR PROVINCE / TOWN*/}
                    <div className="form-group col-md-4" >
                        <label className=" form-label">{this.state.resources.Label_StateOrProvince}</label>
                        <input className="form-control" type="text" name="stateorprovince" onChange={this.onChangeStateOrProvince} value={this.state.stateOrProvince} />
                    </div>
                    <div className="form-group col-md-4" >
                        <label className=" form-label">{this.state.resources.Label_Town}</label>
                        <input className="form-control" type="text" name="town" onChange={this.onChangeTown} value={this.state.town} />
                    </div>

                    {/*ZIP CODE / */}
                    <div className="form-group col-md-4" >
                        <label className=" form-label">{this.state.resources.Label_PostalCode}</label>
                        <input className="form-control" type="text" name="postalcode" onChange={this.onChangePostalCode} value={this.state.postalCode} />
                    </div>
                    <div className="form-group col-md-12" >
                    </div>
                    <div className="form-group col-md-12">
                    
                        <label className="data-labels">{this.state.resources.Label_AccessData}</label>
                    
                    </div>
                    {/*USER LDAP / PASSWORD LDAP*/}
                    <div className="form-group col-md-4" >
                        <label className=" form-label">{this.state.resources.Label_UserLdap}</label>
                        <input className="form-control" type="text" name="userldap" onChange={this.onChangeUserLdap} value={this.state.userLdap} required />
                        <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                    </div>
                    <div className="form-group col-md-4" >
                        <label className=" form-label">{this.state.resources.Label_PasswordLdap}</label>
                        <input className="form-control" type="password" name="passwordldap" onChange={this.onChangePasswordLdap} value={this.state.passwordLdap} required />
                        <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                    </div>

                    {/*PERMISSION GROUP / WORK SCHEDULE*/}
                    <div className="form-group col-md-4" >
                        <label className=" form-label">{this.state.resources.Label_PermissionGroup}</label>
                        <select className="form-control" data-val="true" name="permissiongroup" defaultValue={this.state.permissionGroupId} onChange={this.onChangePermissionGroup} required>
                            <option value="">{this.state.resources.Label_SelectOption}</option>
                            {this.state.permissionGroups.map(permissionGroup =>
                                <option key={permissionGroup.id} value={permissionGroup.id}>{permissionGroup.name}</option>
                            )}
                        </select>
                        <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                    </div>
                    <div className="form-group col-md-12 my-3">
                        <label className="data-labels">{this.state.resources.Label_LaborData}</label>
                    </div>

                    {/*EMPLOYER COMPANY / ADMISSION DATE*/}
                    <div className="form-group col-md-3" >
                        <label className=" form-label">{this.state.resources.Label_EmployerCompany}</label>
                        <select className="form-control" data-val="true" name="employercompany" defaultValue={this.state.employerCompanyId} onChange={this.onChangeEmployerCompany} required>
                            <option value="">{this.state.resources.Label_SelectOption}</option>
                            {this.state.companies.map(company =>
                                <option key={company.id} value={company.id}>{company.name}</option>
                            )}
                        </select>
                        <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                    </div>
                    <div className="form-group col-md-3" >
                        <label className=" form-label">{this.state.resources.Label_AdmissionDate}</label>
                        <input className="form-control" type="date" name="admissiondate" onChange={this.onChangeAdmissionDate} defaultValue={this.state.admissionDate} required />
                        <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                    </div>

                    {/*TEAM LEADER USER / SUPERVISOR USER*/}
                    <div className="form-group col-md-3" >
                        <label className=" form-label">{this.state.resources.Label_TeamLeader}</label>
                        <div className="input-group" data-bs-toggle="tooltip" data-bs-placement="top" title={this.state.teamLeaderUserFullName}>
                            <button data-bs-toggle="modal" data-bs-target="#selectTeamLeaderUserModal" className="btn btn-outline-secondary" type="button" id="button-selectteamleaderuser">{this.state.resources.ButtonLabel_Select}</button>
                            <input type="text" className="form-control" value={this.state.teamLeaderUserFullName} placeholder={this.state.resources.Label_SelectUser} aria-label={this.state.resources.Label_SelectUser} aria-describedby="button-selectteamleaderuser" readOnly />
                            <button onClick={this.removeTeamLeaderUserId()} className="btn btn-outline-secondary" type="button" id="button-removeteamleaderuser"><i className="fa fa-times"></i></button>
                        </div>
                    </div>
                    <div className="form-group col-md-3" >
                        <label className=" form-label">{this.state.resources.Label_Supervisor}</label>
                        <div className="input-group" data-bs-toggle="tooltip" data-bs-placement="top" title={this.state.supervisorUserFullName}>
                            <button data-bs-toggle="modal" data-bs-target="#selectSupervisorUserModal" className="btn btn-outline-secondary" type="button" id="button-selectsupervisoruser">{this.state.resources.ButtonLabel_Select}</button>
                            <input type="text" className="form-control" value={this.state.supervisorUserFullName} placeholder={this.state.resources.Label_SelectUser} aria-label={this.state.resources.Label_SelectUser} aria-describedby="button-selectsupervisoruser" readOnly />
                            <button onClick={this.removeSupervisorUserId()} className="btn btn-outline-secondary" type="button" id="button-removesupervisoruser"><i className="fa fa-times"></i></button>
                        </div>
                    </div>
                    <div className="form-group col-md-3" >
                        <label className=" form-label">{this.state.resources.Label_WorkSchedule}</label>
                        <select className="form-control" data-val="true" name="workschedule" defaultValue={this.state.workScheduleId} onChange={this.onChangeWorkSchedule} required>
                            <option value="">{this.state.resources.Label_SelectOption}</option>
                            {this.state.workSchedules.map(workSchedule =>
                                <option key={workSchedule.id} value={workSchedule.id}>{workSchedule.name}</option>
                            )}
                        </select>
                        <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                    </div>

                    {/*WORK SHIFT / AREA*/}
                    <div className="form-group col-md-3" >
                        <label className=" form-label">{this.state.resources.Label_WorkShift}</label>
                        <select className="form-control" data-val="true" name="workshift" defaultValue={this.state.workShiftId} onChange={this.onChangeWorkShift} required>
                            <option value="">{this.state.resources.Label_SelectOption}</option>
                            {this.state.workShifts.map(workShift =>
                                <option key={workShift.id} value={workShift.id}>{workShift.from + ' - ' + workShift.to}</option>
                            )}
                        </select>
                        <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                    </div>
                    <div className="form-group col-md-3" >
                        <label className=" form-label">{this.state.resources.Label_Area}</label>
                        <select className="form-control" data-val="true" name="area" defaultValue={this.state.areaId} onChange={this.onChangeArea} required>
                            <option value="">{this.state.resources.Label_SelectOption}</option>
                            {this.state.areas.map(area =>
                                <option key={area.id} value={area.id}>{area.name}</option>
                            )}
                        </select>
                        <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                    </div>

                    {/*SUB AREA / PBX ID*/}
                    <div className="form-group col-md-3" >
                        <label className=" form-label">{this.state.resources.Label_SubArea}</label>
                        <select className="form-control" data-val="true" name="subarea" defaultValue={this.state.subAreaId} onChange={this.onChangeSubArea}>
                            <option value="">{this.state.resources.Label_SelectOption}</option>
                            {this.state.subAreas.map(subArea =>
                                <option key={subArea.id} value={subArea.id}>{subArea.name}</option>
                            )}
                        </select>
                    </div>
                    <div className="form-group col-md-3" >
                        <label className=" form-label">{this.state.resources.Label_PbxId}</label>
                        <input className="form-control" type="number" name="pbxid" onChange={this.onChangePbxId} value={this.state.pbxId} />
                    </div>

                    {/*LANGUAGE / */}
                    <div className="form-group col-md-3" >
                        <label className=" form-label">{this.state.resources.Label_Language}</label>
                        <select className="form-control" data-val="true" name="language" defaultValue={this.state.language} onChange={this.onChangeLanguageResource} required>
                            <option value="">{this.state.resources.Label_SelectOption}</option>
                            <option value="language0">{this.state.resources.Label_Language0}</option>
                            <option value="language1" selected>{this.state.resources.Label_Language1}</option>
                            <option value="language2">{this.state.resources.Label_Language2}</option>
                            <option value="language3">{this.state.resources.Label_Language3}</option>
                            <option value="language4">{this.state.resources.Label_Language4}</option>
                            <option value="language5">{this.state.resources.Label_Language5}</option>
                            <option value="language6">{this.state.resources.Label_Language6}</option>
                            <option value="language7">{this.state.resources.Label_Language7}</option>
                            <option value="language8">{this.state.resources.Label_Language8}</option>
                            <option value="language9">{this.state.resources.Label_Language9}</option>
                        </select>
                        <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                    </div>                
                    
                    {/*COMPANIES*/}
                    <div className="form-group col-md-12" >
                        <label className=" form-label">{this.state.resources.Label_SellingCompanies}</label>
                        <div className="col-md-12">
                            {this.state.sellingcompanies.map(company =>
                                <div key={company.id} className="form-check form-check-inline col-md-3">
                                    <input className="form-check-input" type="checkbox" onChange={this.onChangeCompanyIds} id={'company' + company.id} defaultValue={company.id} />
                                    <label className="form-check-label" htmlFor={'company' + company.id}>{company.name}</label>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                {/* --LAST NAME / FIRST NAME--
                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_LastName}</label>
                    <input className="form-control" type="text" name="lastname" onChange={this.onChangeLastName} value={this.state.lastName} required />
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                </div>
                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_FirstName}</label>
                    <input className="form-control" type="text" name="firstname" onChange={this.onChangeFirstName} value={this.state.firstName} required />
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                </div>

                {----DOC NUMBER / DATE OF BIRTH--
                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_DocNumber}</label>
                    <input className="form-control" type="text" name="docnumber" onChange={this.onChangeDocNumber} value={this.state.docNumber} />
                </div>
                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_DateOfBirth}</label>
                    <input className="form-control" type="date" name="dateofbirth" onChange={this.onChangeDateOfBirth} defaultValue={this.state.dateOfBirth} />
                </div>

                {--PHONE / MOBILE PHONE--
                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_Phone}</label>
                    <input className="form-control" type="text" name="phone" onChange={this.onChangePhone} value={this.state.mobilePhone} />
                </div>
                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_MobilePhone}</label>
                    <input className="form-control" type="text" name="mobilephone" onChange={this.onChangeMobilePhone} value={this.state.mobilePhone} />
                </div>

                {/*WORK EMAIL / PERSONAL EMAIL--
                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_WorkEmail}</label>
                    <input className="form-control" type="email" name="workemail" onChange={this.onChangeWorkEmail} value={this.state.workEmail} />
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_InvalidEmail}</div>
                </div>
                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_PersonalEmail}</label>
                    <input className="form-control" type="email" name="personalemail" onChange={this.onChangePersonalEmail} value={this.state.personalEmail} />
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_InvalidEmail}</div>
                </div>

                {/*ADDRESS / COUNTRY--
                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_Address}</label>
                    <input className="form-control" type="text" name="address" onChange={this.onChangeAddress} value={this.state.address} />
                </div>
                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_Country}</label>
                    <input className="form-control" type="text" name="country" onChange={this.onChangeCountry} value={this.state.country} />
                </div>

                {/*STATE OR PROVINCE / TOWN---
                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_StateOrProvince}</label>
                    <input className="form-control" type="text" name="stateorprovince" onChange={this.onChangeStateOrProvince} value={this.state.stateOrProvince} />
                </div>
                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_Town}</label>
                    <input className="form-control" type="text" name="town" onChange={this.onChangeTown} value={this.state.town} />
                </div>

                {/*ZIP CODE / ---
                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_PostalCode}</label>
                    <input className="form-control" type="text" name="postalcode" onChange={this.onChangePostalCode} value={this.state.postalCode} />
                </div>
                <div className="form-group col-md-6" >
                </div>

                --/*USER LDAP / PASSWORD LDAP--
                <div className="form-group col-md-6" >
                    <label className=" form-label">--this.state.resources.Label_UserLdap}</label>
                    <input className="form-control" type="text" name="userldap" onChange={this.onChangeUserLdap} value={this.state.userLdap} required />
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                </div>
                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_PasswordLdap}</label>
                    <input className="form-control" type="password" name="passwordldap" onChange={this.onChangePasswordLdap} value={this.state.passwordLdap} required />
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                </div>

                --/*PERMISSION GROUP / WORK SCHEDULE--
                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_PermissionGroup}</label>
                    <select className="form-control" data-val="true" name="permissiongroup" defaultValue={this.state.permissionGroupId} onChange={this.onChangePermissionGroup} required>
                        <option value="">{this.state.resources.Label_SelectOption}</option>
                        {this.state.permissionGroups.map(permissionGroup =>
                            <option key={permissionGroup.id} value={permissionGroup.id}>{permissionGroup.name}</option>
                        )}
                    </select>
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                </div>
                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_WorkSchedule}</label>
                    <select className="form-control" data-val="true" name="workschedule" defaultValue={this.state.workScheduleId} onChange={this.onChangeWorkSchedule} required>
                        <option value="">{this.state.resources.Label_SelectOption}</option>
                        {this.state.workSchedules.map(workSchedule =>
                            <option key={workSchedule.id} value={workSchedule.id}>{workSchedule.name}</option>
                        )}
                    </select>
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                </div>

                --/*WORK SHIFT / AREA--
                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_WorkShift}</label>
                    <select className="form-control" data-val="true" name="workshift" defaultValue={this.state.workShiftId} onChange={this.onChangeWorkShift} required>
                        <option value="">{this.state.resources.Label_SelectOption}</option>
                        {this.state.workShifts.map(workShift =>
                            <option key={workShift.id} value={workShift.id}>{workShift.from + ' - ' + workShift.to}</option>
                        )}
                    </select>
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                </div>
                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_Area}</label>
                    <select className="form-control" data-val="true" name="area" defaultValue={this.state.areaId} onChange={this.onChangeArea} required>
                        <option value="">{this.state.resources.Label_SelectOption}</option>
                        {this.state.areas.map(area =>
                            <option key={area.id} value={area.id}>{area.name}</option>
                        )}
                    </select>
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                </div>

                --/*SUB AREA / PBX ID---
                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_SubArea}</label>
                    <select className="form-control" data-val="true" name="subarea" defaultValue={this.state.subAreaId} onChange={this.onChangeSubArea}>
                        <option value="">{this.state.resources.Label_SelectOption}</option>
                        {this.state.subAreas.map(subArea =>
                            <option key={subArea.id} value={subArea.id}>{subArea.name}</option>
                        )}
                    </select>
                </div>
                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_PbxId}</label>
                    <input className="form-control" type="number" name="pbxid" onChange={this.onChangePbxId} value={this.state.pbxId} />
                </div>

                --/*LANGUAGE /--
                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_Language}</label>
                    <select className="form-control" data-val="true" name="language" defaultValue={this.state.language} onChange={this.onChangeLanguageResource} required>
                        <option value="">{this.state.resources.Label_SelectOption}</option>
                        <option value="language0">{this.state.resources.Label_Language0}</option>
                        <option value="language1">{this.state.resources.Label_Language1}</option>
                        <option value="language2">{this.state.resources.Label_Language2}</option>
                        <option value="language3">{this.state.resources.Label_Language3}</option>
                        <option value="language4">{this.state.resources.Label_Language4}</option>
                        <option value="language5">{this.state.resources.Label_Language5}</option>
                        <option value="language6">{this.state.resources.Label_Language6}</option>
                        <option value="language7">{this.state.resources.Label_Language7}</option>
                        <option value="language8">{this.state.resources.Label_Language8}</option>
                        <option value="language9">{this.state.resources.Label_Language9}</option>
                    </select>
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                </div>
                <div className="form-group col-md-6" >
                </div>

                --/*COMPANIES--
                <div className="form-group col-md-12" >
                    <label className=" form-label">{this.state.resources.Label_Companies}</label>
                    <div className="col-md-12">
                        {this.state.companies.map(company =>
                            <div key={company.id} className="form-check form-check-inline col-md-3">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangeCompanyIds} id={'company' + company.id} defaultValue={company.id} />
                                <label className="form-check-label" htmlFor={'company' + company.id}>{company.name}</label>
                            </div>
                        )}
                    </div>
                </div>

                --/*EMPLOYER COMPANY / ADMISSION DATE--
                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_EmployerCompany}</label>
                    <select className="form-control" data-val="true" name="employercompany" defaultValue={this.state.employerCompanyId} onChange={this.onChangeEmployerCompany} required>
                        <option value="">{this.state.resources.Label_SelectOption}</option>
                        {this.state.companies.map(company =>
                            <option key={company.id} value={company.id}>{company.name}</option>
                        )}
                    </select>
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                </div>
                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_AdmissionDate}</label>
                    <input className="form-control" type="date" name="admissiondate" onChange={this.onChangeAdmissionDate} defaultValue={this.state.admissionDate} required />
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                </div>

                --/*TEAM LEADER USER / SUPERVISOR USER--
                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_TeamLeader}</label>
                    <div className="input-group">
                        <button data-bs-toggle="modal" data-bs-target="#selectTeamLeaderUserModal" className="btn btn-outline-secondary" type="button" id="button-selectteamleaderuser">{this.state.resources.ButtonLabel_Select}</button>
                        <input type="text" className="form-control" value={this.state.teamLeaderUserFullName} placeholder={this.state.resources.Label_SelectUser} aria-label={this.state.resources.Label_SelectUser} aria-describedby="button-selectteamleaderuser" readOnly />
                        <button onClick={this.removeTeamLeaderUserId()} className="btn btn-outline-secondary" type="button" id="button-removeteamleaderuser"><i className="fa fa-times"></i></button>
                    </div>
                </div>
                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_Supervisor}</label>
                    <div className="input-group">
                        <button data-bs-toggle="modal" data-bs-target="#selectSupervisorUserModal" className="btn btn-outline-secondary" type="button" id="button-selectsupervisoruser">{this.state.resources.ButtonLabel_Select}</button>
                        <input type="text" className="form-control" value={this.state.supervisorUserFullName} placeholder={this.state.resources.Label_SelectUser} aria-label={this.state.resources.Label_SelectUser} aria-describedby="button-selectsupervisoruser" readOnly />
                        <button onClick={this.removeSupervisorUserId()} className="btn btn-outline-secondary" type="button" id="button-removesupervisoruser"><i className="fa fa-times"></i></button>
                    </div>
                </div>

                --/*ADDITIONAL DATA--
                <div className="form-group col-md-12">
                    <label className="form-label">{this.state.resources.Label_AdditionalData}</label>
                    <textarea className="form-control" rows="3" name="additionaldata" maxLength="150" onChange={this.onChangeAdditionalData} defaultValue={this.state.additionalData}></textarea>
                </div> */}
                
                {/*SAVE / CANCEL*/}
                <div className="buttons-head row">
                    <div className=" buttons-childs">
                        <button type="submit" className="btn btn-primary">{this.state.resources.ButtonLabel_Save}</button>
                        <button className="btn btn-danger" onClick={this.onCancel}>{this.state.resources.ButtonLabel_Cancel}</button>
                    </div>
                 </div>
                {/* <div className="form-group col-12 text-end">
                    <button type="submit" className="btn btn-primary">{this.state.resources.ButtonLabel_Save}</button>
                    <button className="btn btn-negative" onClick={this.onCancel}>{this.state.resources.ButtonLabel_Cancel}</button>
                </div> */}

                {/*TEAM LEADER USER MODAL*/}
                <div id="selectTeamLeaderUserModal" className="modal" tabIndex="-1">
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{this.state.resources.Title_SelectTeamLeader}</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label={this.state.resources.ButtonLabel_Close}></button>
                            </div>
                            <div className="modal-body">
                                <table className='table table-striped datatable' aria-labelledby="tabelLabel">
                                    <thead>
                                        <tr>
                                            <th>{this.state.resources.Label_ID}</th>
                                            <th>{this.state.resources.Label_FirstName}</th>
                                            <th>{this.state.resources.Label_LastName}</th>
                                            <th className="text-center">{this.state.resources.Label_Actions}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.users.map(user =>
                                                <tr key={user.id}>
                                                    <td>{user.id}</td>
                                                    <td>{user.first_name}</td>
                                                    <td>{user.last_name}</td>
                                                    <td className="text-center">
                                                        <button type="button" className="btn btn-default" data-bs-dismiss="modal" onClick={this.setTeamLeaderUserId(user.id, user.last_name + ', ' + user.first_name)}><i className="fa fa-plus-circle"></i></button>
                                                    </td>
                                                </tr>
                                            )}
                                    </tbody>
                                </table>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">{this.state.resources.ButtonLabel_Close}</button>
                            </div>
                        </div>
                    </div>
                </div>

                {/*SUPERVISOR USER MODAL*/}
                <div id="selectSupervisorUserModal" className="modal" tabIndex="-1">
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{this.state.resources.Title_SelectSupervisor}</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label={this.state.resources.ButtonLabel_Close}></button>
                            </div>
                            <div className="modal-body">
                                <table className='table table-striped datatable' aria-labelledby="tabelLabel">
                                    <thead>
                                        <tr>
                                            <th>{this.state.resources.Label_ID}</th>
                                            <th>{this.state.resources.Label_FirstName}</th>
                                            <th>{this.state.resources.Label_LastName}</th>
                                            <th className="text-center">{this.state.resources.Label_Actions}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.users.map(user =>
                                                <tr key={user.id}>
                                                    <td>{user.id}</td>
                                                    <td>{user.first_name}</td>
                                                    <td>{user.last_name}</td>
                                                    <td className="text-center">
                                                        <button type="button" className="btn btn-default" data-bs-dismiss="modal" onClick={this.setSupervisorUserId(user.id, user.last_name + ', ' + user.first_name)}><i className="fa fa-plus-circle"></i></button>
                                                    </td>
                                                </tr>
                                            )}
                                    </tbody>
                                </table>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">{this.state.resources.ButtonLabel_Close}</button>
                            </div>
                        </div>
                    </div>
                </div>

            </form>;

        return (
            <div className="mt-2">
                {/* <h1 className="mt-4">{this.state.resources.Title_Users}</h1> */}
                <ol className="breadcrumb my-4">
                    <li className="breadcrumb-item active">{this.state.resources.Title_Users}</li>
                </ol>
                <div className="card">
                    <div className="card-body">
                        {this.state.loading ? helpers.showLoadingOverlay() : helpers.hideLoadingOverlay() }
                        {contents}
                    </div>
                </div>
            </div>
        );
    }

    async getResources() {
        const resourceVars = [];
        resourceVars.push("Title_Users");
        resourceVars.push("Title_SelectTeamLeader");
        resourceVars.push("Title_SelectSupervisor");
        resourceVars.push("Title_SelectUser");
        resourceVars.push("Label_SelectOption");
        resourceVars.push("Label_ID");
        resourceVars.push("Label_FirstName");
        resourceVars.push("Label_LastName");
        resourceVars.push("Label_DocNumber");
        resourceVars.push("Label_DateOfBirth");
        resourceVars.push("Label_Phone");
        resourceVars.push("Label_MobilePhone");
        resourceVars.push("Label_Area");
        resourceVars.push("Label_SubArea");
        resourceVars.push("Label_PbxId");
        resourceVars.push("Label_WorkEmail");
        resourceVars.push("Label_PersonalEmail");
        resourceVars.push("Label_Address");
        resourceVars.push("Label_Country");
        resourceVars.push("Label_StateOrProvince");
        resourceVars.push("Label_Town");
        resourceVars.push("Label_PostalCode");
        resourceVars.push("Label_UserLdap");
        resourceVars.push("Label_PasswordLdap");
        resourceVars.push("Label_PermissionGroup");
        resourceVars.push("Label_WorkSchedule");
        resourceVars.push("Label_WorkShift");
        resourceVars.push("Label_Language");
        resourceVars.push("Label_Companies");
        resourceVars.push("Label_EmployerCompany");
        resourceVars.push("Label_AdmissionDate");
        resourceVars.push("Label_TeamLeader");
        resourceVars.push("Label_Supervisor");
        resourceVars.push("Label_AdditionalData");
        resourceVars.push("Label_Actions");
        resourceVars.push("Label_Enabled");
        resourceVars.push("Label_Success");
        resourceVars.push("Label_SelectUser");
        resourceVars.push("Label_Error");
        resourceVars.push("Label_Language0");
        resourceVars.push("Label_Language1");
        resourceVars.push("Label_Language2");
        resourceVars.push("Label_Language3");
        resourceVars.push("Label_Language4");
        resourceVars.push("Label_Language5");
        resourceVars.push("Label_Language6");
        resourceVars.push("Label_Language7");
        resourceVars.push("Label_Language8");
        resourceVars.push("Label_Language9");
        resourceVars.push("ButtonLabel_Save");
        resourceVars.push("ButtonLabel_Cancel");
        resourceVars.push("ButtonLabel_Select");
        resourceVars.push("ButtonLabel_Close");
        resourceVars.push("ErrorMessage_RequiredField");
        resourceVars.push("ErrorMessage_DuplicatedEntity_AuthorizationToken");
        resourceVars.push("ErrorMessage_UnknownError");
        resourceVars.push("ErrorMessage_User_MustHaveOneCompany");
        resourceVars.push("ErrorMessage_InvalidEmail");
        resourceVars.push("SuccessMessage_DataSavedSuccessfully");
        resourceVars.push("ErrorMessage_UserLdapAlreadyExist");
        resourceVars.push("Label_SellingCompanies");
        resourceVars.push("Label_PersonalData");
        resourceVars.push("Label_AccessData");
        resourceVars.push("Label_LaborData");
        resourceVars.push("ErrorMessage_InvalidPhoneFormat");

        const headers = {
            'Content-Type': 'application/json'
        };

        axios.post(window.config.REACT_APP_SECURITY_API_URL + '/languageresource/getbyvars/' + helpers.getUiLanguage(), resourceVars, {
            headers: headers
        })
            .then((response) => {
                const data = response.data.data;
                this.setState({ resources: data, loading: false });
            })
            .catch((error) => {
                console.log(error);
                this.setState({ loading: false });
            });
    }

    async populateData() {
        this.setState({
            loading: false
        });

        let authTokenProducts = await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME);
        let authTokenSecurity = await helpers.getAuthorizationToken(process.env.REACT_APP_SECURITY_API_NAME);

        // get users
        axios.get(window.config.REACT_APP_SECURITY_API_URL + '/user/get',
            {
                'headers': { 'Authorization': authTokenSecurity }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ users: data, loading: false });
                // $('.datatable').DataTable({
                //     destroy: true,
                //     "language": {
                //         "lengthMenu": this.state.resources.Datatables_language_lengthMenu,
                //         "zeroRecords": this.state.resources.Datatables_language_zeroRecords,
                //         "info": this.state.resources.Datatables_language_info,
                //         "infoEmpty": this.state.resources.Datatables_language_infoEmpty,
                //         "infoFiltered": this.state.resources.Datatables_language_infoFiltered,
                //         "search": this.state.resources.Datatables_language_search,
                //         "paginate": {
                //             "previous": this.state.resources.Datatables_language_paginate_previous,
                //             "next": this.state.resources.Datatables_language_paginate_next
                //         },
                //         "autoWidth": false
                //     }
                // });
            })
            .catch(function (error) {
                console.log(error);
            });

        // get areas
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/area/get',
            {
                'headers': { 'Authorization': authTokenProducts }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ areas: data, loading: false });
                this.state.areas.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                // console.log(this.state.areas);
            })
            .catch(function (error) {
                console.log(error);
            });

        // get selling companies
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/seller/get',
            {
                'headers': { 'Authorization': authTokenProducts }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ sellingcompanies: data, loading: false });
                this.state.sellingcompanies.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                // console.log(this.state.companies);
            })
            .catch(function (error) {
                console.log(error);
            });

        // get permission groups
        axios.get(window.config.REACT_APP_SECURITY_API_URL + '/permissiongroup/get',
            {
                'headers': { 'Authorization': authTokenSecurity }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ permissionGroups: data, loading: false });
                // order alphabetically
                this.state.permissionGroups.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                // console.log(this.state.permissionGroups);
            })
            .catch(function (error) {
                console.log(error);
            });

        // get work schedules
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/workschedule/get',
            {
                'headers': { 'Authorization': authTokenProducts }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ workSchedules: data, loading: false });
            })
            .catch(function (error) {
                console.log(error);
            });

        // get work shifts
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/workshift/get',
            {
                'headers': { 'Authorization': authTokenProducts }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ workShifts: data, loading: false });
                
                
            })
            .catch(function (error) {
                console.log(error);
            });
        
        // get companies
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/company/get',
            {
                'headers': { 'Authorization': authTokenProducts }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ companies: data, loading: false });
                this.state.companies.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                
            })
            .catch(function (error) {
                console.log(error);
            });
        
    }
}
