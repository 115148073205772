import React, { Component, useState } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { Link } from 'react-router-dom';
import authService from '../components/api-authorization/AuthorizeService';
import Login from '../components/api-authorization/Login';
import SessionHelper from '../components/session/SessionHelper';
import $, { timers } from 'jquery';
import logo from '../logoCC.png';
import helpers from '../Helpers'
import axios from 'axios';
import { QueryParameterNames, LogoutActions, LoginActions, ApplicationPaths } from '../components/api-authorization/ApiAuthorizationConstants';
import PasswordChange from './Account/PasswordChange';

import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { Redirect } from 'react-router-dom';
import './Layout.css';

export class Layout extends Component {
    static displayName = Layout.name;

    constructor(props) {
        super(props);
        this.onChangeSearch = this.onChangeSearch.bind(this);

        this.state = {
            languages: [],
            resources: [],
            configurations: [],
            uiLanguage: '',
            isAuthenticated: false,
            forcePasswordChange: false,
            adminPermissionGroupId: 0,
            userData: null,
            username: '',
            searchUser: '',
            ocultarInput: false
        };

    }




    componentDidMount() {
        this.getResources();
        // this.getConfigurations();
        
        let _languages = [];

        _languages.push({
            key: 'language0',
            name: 'English'
        });
        _languages.push({
            key: 'language1',
            name: 'Spanish'
        });

        let uiLanguage = _languages.filter(function (el) {
            return el.key === helpers.getUiLanguage();
        });

        this.populateState(_languages, uiLanguage[0].name);

        authService.setInterceptors();
    }

    async populateState(languages, uiLanguage) {
        const [isAuthenticated, forcePasswordChange] = await Promise.all([authService.isAuthenticated(), authService.forcePasswordChange()])

        this.setState({
            languages: languages,
            uiLanguage: uiLanguage,
            isAuthenticated: isAuthenticated,
            forcePasswordChange: forcePasswordChange,
            userData: helpers.getUserData(),
        });

    }

   

    onChangeSearch(e) {
        localStorage.setItem('UserIndexSearch', e.target.value);
    } 

    async refreshPage(){
        window.location.reload();
    }

    async filterUsers() 
    {   
        let language = localStorage.getItem('UserIndexSearch');
        if (language == null && language !== "")
        {
            helpers.notifyWarning('Error','Ingrese datos para buscar');
        }
        else
        {            
            helpers.showLoadingOverlay();
            document.getElementById("btnRedirect").click();
        }
        this.setState({
            ocultarInput: true
        });
        document.querySelector('#buttonFilter').style.display = 'none';
        document.querySelector('#buttonRefresh').style.display = 'block';
    }

    setUiLanguage = (key, name) => () => {
        helpers.setUiLanguage(key);

        this.setState({
            uiLanguage: name
        });
    }

    toggleSidebar = (key, name) => () => {
        helpers.toggleSidebar();
    }

    toggleSidebarTop = (key, name) => () => {
        $("body").toggleClass("sb-sidenav-toggled");
    }


    render() {
        const { isAuthenticated, forcePasswordChange } = this.state;


        if (!isAuthenticated)
            return <Login />;

        if (forcePasswordChange)
            return <PasswordChange />;

        return (
            <div>

                <nav className="sb-topnav navbar navbar-expand navbar-dark bg-dark">
                    <a className="navbar-brand text-center"></a>
                    <button className="btn btn-link btn-sm order-1 order-lg-0" id="sidebarToggle" onClick={this.toggleSidebarTop()}><i className="fas fa-bars"></i></button>
                    <div className="dis-flex col-md-6 my-3">
              <div className='col-md-9'>
                  <input className='form-control' onChange={this.onChangeSearch} disabled={this.state.ocultarInput}  onKeyPress={(e) => e.key == 'Enter' && this.filterUsers(e)}/>
              </div>
              <div>
                  <button className='btn btn-primary' id="buttonFilter" onClick={this.filterUsers}><i class="fas fa-search"></i></button>
                  <Link to="/searchResult" className="btn btn-default" id="btnRedirect" style={{display: 'none'}}><i class="fas fa-eye"></i></Link>
                  <button className='btn btn-primary' id="buttonRefresh"  style={{display: 'none', fontSize:'0.7rem', padding:5, paddingRight:10, paddingLeft:10, paddingTop:10}} onClick={this.refreshPage}> <i class="fas fa-redo fa-2x fa-lg ml-1 mr-1"></i></button>
              </div>
          </div>
                    <ul className="navbar-nav ml-auto">
                        <li className="nav-item dropdown" style={{ display: 'inline-block' }}>
                            <a className="nav-link dropdown-toggle" id="languageDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{this.state.uiLanguage} <i className="fas fa-globe fa-fw"></i></a>
                            <div className="dropdown-menu dropdown-menu-right" aria-labelledby="languageDropdown">
                                {this.state.languages.map(lang =>
                                    <a key={lang.key} className="dropdown-item" href="#" onClick={this.setUiLanguage(lang.key, lang.name)}>{lang.name}</a>
                                )}
                            </div>
                        </li>
                        <li className="nav-item dropdown" style={{ display: 'inline-block' }}>
                            <a className="nav-link dropdown-toggle" id="userDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{this.state.userData.username} <i className="fas fa-user fa-fw"></i></a>
                            <div className="dropdown-menu dropdown-menu-right" aria-labelledby="userDropdown">
                                <NavItem tag={Link} className="dropdown-item" to="/profile">{this.state.resources.NavItem_Profile}</NavItem>
                                <div className="dropdown-divider"></div>
                                <a className="dropdown-item"  onClick={this.logout()}>{this.state.resources.NavItem_Logout}</a>
                            </div>
                        </li>
                    </ul>
                </nav>

                <div id="layoutSidenav">
                    <div id="layoutSidenav_nav">
                        <nav className="sb-sidenav accordion sb-sidenav-dark" id="sidenavAccordion">
                            <a className="navbar-brand text-center"><img src={logo} style={{ width: '100%' }} /></a>
                            <div className="sb-sidenav-menu">    


                                <div className="nav">
                                    <div className="sb-sidenav-menu-heading">{this.state.resources.Title_Menu}</div>
                                    <div>
                                        {this.state.userData?.permission_ids?.includes(138) &&
                                            <SimpleMenu values={this.state}></SimpleMenu>
                                        }
                                        <div>
                                            <i class="fas fa-home ml-2" style={{color: 'rgba(255, 255, 255, 0.8)'}}></i>
                                            <Button  href="/" tag={Link} id="nav-home"  style={{color: 'rgba(255, 255, 255, 0.801)'}} to="/">
                                                 {this.state.resources.NavItem_Home}
                                            </Button>
                                        </div>
                                        {this.state.userData?.permission_ids?.includes(25) &&
                                        <LogisticMenu values={this.state}></LogisticMenu>
                                        }
                                        {this.state.userData?.permission_ids?.includes(24) &&
                                        <CampaignMenu values={this.state}></CampaignMenu>
                                        }
                                        {this.state.userData?.permission_ids?.includes(23) &&
                                            <RHMenu values={this.state}></RHMenu>
                                        }
                                        {this.state.userData?.permission_ids?.includes(27) &&
                                            <SecurityMenu values={this.state}></SecurityMenu>
                                        }
                                        {this.state.userData?.permission_ids?.includes(26) &&
                                        <StockMenu values={this.state}></StockMenu>
                                        }
                                        {this.state.userData?.permission_ids?.includes(1164) &&
                                        <SalesMenu values={this.state}></SalesMenu>
                                        }
                                                                                                            
                                    </div>

                                </div>
                            </div>
                        </nav>
                    </div>
                    <div id="layoutSidenav_content">
                        <main>
                            <div className="container-fluid">
                                {this.props.children}
                            </div>
                        </main>
                    </div>
                </div>

            </div>
        );
    }

    logout = () => () => {
        const { isAuthenticated } = this.state;
        this.state.username = helpers.getUserData().username
        authService.logOut(this.state.username);
        setTimeout(() => {
            helpers.showLoadingOverlay();
            helpers.clearUserData();
            SessionHelper.doLogout();
            
            return window.location.replace(this.getReturnUrl());  
        }, 1500);
    }

    

    getReturnUrl(state) {
        const params = new URLSearchParams(window.location.search);
        const fromQuery = params.get(QueryParameterNames.ReturnUrl);
        if (fromQuery && !fromQuery.startsWith(`${window.location.origin}/`)) {
            // This is an extra check to prevent open redirects.
            throw new Error("Invalid return url. The return url needs to have the same origin as the current page.")
        }
        return (state && state.returnUrl) ||
            fromQuery ||
            `${window.location.origin}${ApplicationPaths.Login}`;
    }

    getSearchUser()
    {
        return this.state.searchUser;
    }

    async getResources() {
        const resourceVars = [];
        resourceVars.push("Label_Language0");
        resourceVars.push("Label_Language1");
        resourceVars.push("NavItem_Home");
        resourceVars.push("NavItem_Areas");
        resourceVars.push("NavItem_SubAreas");
        resourceVars.push("NavItem_BusinessAreas");
        resourceVars.push("NavItem_Brands");
        resourceVars.push("NavItem_Countries");
        resourceVars.push("NavItem_Companies");
        resourceVars.push("NavItem_Suppliers");
        resourceVars.push("NavItem_EDKeys");
        resourceVars.push("NavItem_Configurations");
        resourceVars.push("NavItem_Permissions");
        resourceVars.push("NavItem_PermissionGroups");
        resourceVars.push("NavItem_Users");
        resourceVars.push("NavItem_AuthorizationTokens");
        resourceVars.push("NavItem_LanguageResources");
        resourceVars.push("NavItem_Profile");
        resourceVars.push("NavItem_Logout");
        resourceVars.push("Title_Menu");
        resourceVars.push("Title_apiClients");
        resourceVars.push("NavItem_ApiClients");
        resourceVars.push("NavItem_Treatments");
        resourceVars.push("NavItem_Courier");
        resourceVars.push("NavItem_Sellers");
        resourceVars.push("Title_Logistic");
        resourceVars.push("Title_Documentation");
        resourceVars.push("Title_Logs");
        resourceVars.push("Title_Objetions");
        resourceVars.push("Title_Messenger");
        resourceVars.push("Title_ScriptSales");
        resourceVars.push("Label_Products");
        resourceVars.push("Title_Campaign");
        resourceVars.push("Title_Formats");
        resourceVars.push("Title_MediaType");
        resourceVars.push("Title_MediaGroup"); 
        resourceVars.push("Title_Pbx"); 
        resourceVars.push("Title_Business");
        resourceVars.push("Label_ProductCountry");
        resourceVars.push("Title_ProductCountryKey");
        resourceVars.push("Title_Agency");
        resourceVars.push("Title_Media");
        resourceVars.push("NavItem_Security");
        resourceVars.push("Title_PbxDatabase");
        resourceVars.push("Title_CampaingType");
        resourceVars.push("Title_Carrier");
        resourceVars.push("Title_State");
        resourceVars.push("Title_City");
        resourceVars.push("Title_Campaing");
        resourceVars.push("Title_ProductCategory");
        resourceVars.push("Title_MapCourierCity");
        resourceVars.push("Label_Domains");
        resourceVars.push("Label_ValidateCities");
        resourceVars.push("Title_WayPay");
        resourceVars.push("Titile_ClosingReasons");
        resourceVars.push("Label_Sales");
        resourceVars.push("Title_Clients");
        resourceVars.push("NavItem_TreatmentQuestion");
        resourceVars.push("Label_ProductInformation");
        resourceVars.push("Title_CountryWayPay");
        resourceVars.push("Label_Bank");
        resourceVars.push("Label_ProductPayment");
        resourceVars.push("Label_ProductPaymentWayPay");
        resourceVars.push("Label_KitProducts");
        resourceVars.push("Title_Publicity");
        resourceVars.push("Title_SalesHistory");
        resourceVars.push("Title_MySales");
        resourceVars.push("NavItem_ProductKeyList");
        resourceVars.push("NavItem_ProductKeySuggest");
        resourceVars.push("Label_Export");
        resourceVars.push("Title_ExportRoutes");
        resourceVars.push("Title_ImportRoutes");
        resourceVars.push("Title_ShippingCostUpdate");
        resourceVars.push("Title_MySalesPendingPayment");
        resourceVars.push("Title_SaleResume");
        resourceVars.push("Tittle_LogMapCourierCity");
        resourceVars.push("Title_KeysSKU");
        const headers = {
            'Content-Type': 'application/json'
        };

        axios.post(window.config.REACT_APP_SECURITY_API_URL + '/languageresource/getbyvars/' + helpers.getUiLanguage(), resourceVars, {
            headers: headers
        })
            .then((response) => {
                const data = response.data.data;
                this.setState({ resources: data, loading: false });
                // console.log(data);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    async getConfigurations() {
        const configurationCodes = [];

        const headers = {
            'Content-Type': 'application/json'
        };

        axios.post(window.config.REACT_APP_SECURITY_API_URL + '/configuration/getbycodes', configurationCodes, {
            headers: headers
        })
            .then((response) => {
                const data = response.data.data;
                this.setState({ configurations: data, loading: false });
                // console.log(this.state.configurations);
                
            })
            .catch((error) => {
                console.log(error);
            });
    }
}



export default function SimpleMenu(values) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const [anchorSubEl, setSubAnchorEl] = React.useState(null);
    

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
        document.querySelector('#config-menu').style.backgroundColor = '#a72835e7';
    };

    const handleSubClick = event => {
        setSubAnchorEl(event.currentTarget);
        document.querySelector('#mens-menu').style.backgroundColor = '#a72835e7';
    }

    const handleClose = () => {
        setAnchorEl(null);
        toggleSidebar();
        document.querySelector('#config-menu').style.backgroundColor = '#DC3444';
    };

    const handleSubClose = () => {
        setSubAnchorEl(null);
        handleClose();
        toggleSubSidebar();
        document.querySelector('#mens-menu').style.backgroundColor = '#DC3444';
    }

    const toggleSidebar = (key, name) => {
        helpers.toggleSidebar();
    }

    const toggleSubSidebar = (key, name) => {
        helpers.toggleSidebar();
    }

    const { isAuthenticated, forcePasswordChange } = values.values;


        if (!isAuthenticated)
            return <Login />;

        if (forcePasswordChange)
            return <PasswordChange />;
 
  return (
      <div>
          <i class="fas fa-cogs ml-2" style={{color: 'rgba(255, 255, 255, 0.8)'}}></i>
      <Button 
            aria-controls="simple-menu"
            aria-haspopup="true"
            id="config-menu"
            onClick={handleClick}
            style={{padding: '3px'}}
        >
            {values.values.resources.NavItem_Configurations}
        </Button>
        <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >

              {values.values.userData.permission_ids.includes(7) &&
                  <NavLink tag={Link} id="nav-businessareas" className="nav-link" to="/businessareas" style={{margin: '0px', padding:'2px',paddingLeft:'20px'}}>
                      <MenuItem onClick={handleClose} style={{margin: '0px', padding:'2px'}}>
                          <div className="sb-nav-link-icon mr-2"><i className=""></i></div> <span>{values.values.resources.NavItem_BusinessAreas}</span>
                      </MenuItem>
                  </NavLink>
              } 
              {values.values.userData.permission_ids.includes(2159) &&
                            <NavLink tag={Link} id="nav-bank" className="nav-link" to="/bank" style={{margin: '0px', padding:'2px',paddingLeft:'20px'}}>
                                <MenuItem onClick={handleSubClose} style={{margin: '0px', padding:'2px'}}>
                                    <div className="sb-nav-link-icon mr-2"></div>
                                    <span>{values.values.resources.Label_Bank}</span>

                                </MenuItem>
                            </NavLink>
              }
              {values.values.userData.permission_ids.includes(115) &&
                  <NavLink tag={Link} id="nav-carrier" className="nav-link" to="/carrier" style={{margin: '0px', padding:'2px',paddingLeft:'20px'}}>
                      <MenuItem onClick={handleClose} style={{margin: '0px', padding:'2px'}}>
                          <div className="sb-nav-link-icon mr-2"></div><span>{values.values.resources.Title_Carrier}</span>
                      </MenuItem>
                  </NavLink>
              }
              {values.values.userData.permission_ids.includes(2166) && 
                <NavLink tag={Link} id="nav-productpayment" className="nav-link" to="/product-payment" style={{margin: '0px', padding:'2px',paddingLeft:'20px'}}>
                    <MenuItem onClick={handleClose} style={{margin: '0px', padding:'2px'}}>
                      <div  className="sb-nav-link-icon mr-2"><i className=""></i></div> <span>{values.values.resources.Label_ProductPayment}</span>
                    </MenuItem>
                </NavLink>
              }
              {values.values.userData.permission_ids.includes(5) && 
                <NavLink tag={Link} id="nav-companies" className="nav-link" to="/companies" style={{margin: '0px', padding:'2px',paddingLeft:'20px'}}>
                    <MenuItem onClick={handleClose} style={{margin: '0px', padding:'2px'}}>
                      <div  className="sb-nav-link-icon mr-2"><i className=""></i></div> <span>{values.values.resources.NavItem_Companies}</span>
                    </MenuItem>
                </NavLink>
              }
              {values.values.userData.permission_ids.includes(17) &&
                <NavLink tag={Link} id="nav-configurations" className="nav-link" to="/configurations"  style={{margin: '0px', padding:'2px',paddingLeft:'20px'}}>
                        <MenuItem onClick={handleClose} style={{margin: '0px', padding:'2px'}}>
                            <div className="sb-nav-link-icon mr-2"><i className=""></i></div><span>{values.values.resources.NavItem_Configurations} </span>
                        </MenuItem>
                </NavLink>
                //   <ConfigMenu values={values}></ConfigMenu>
              }
              {values.values.userData.permission_ids.includes(144) &&
                            <NavLink tag={Link} id="nav-waypays" className="nav-link" to="/waypays" style={{margin: '0px', padding:'2px',paddingLeft:'20px'}}>
                                <MenuItem onClick={handleSubClose} style={{margin: '0px', padding:'2px'}}>
                                    <div className="sb-nav-link-icon mr-2"></div>
                                    <span>{values.values.resources.Title_WayPay}</span>

                                </MenuItem>
                            </NavLink>
              }

                {values.values.userData.permission_ids.includes(2169) && 
                <NavLink tag={Link} id="nav-product-payment-waypay" className="nav-link" to="/product-payment-waypay" style={{margin: '0px', padding:'2px',paddingLeft:'20px'}}>
                    <MenuItem onClick={handleClose} style={{margin: '0px', padding:'2px'}}>
                      <div  className="sb-nav-link-icon mr-2"><i className=""></i></div> <span>{values.values.resources.Label_ProductPaymentWayPay}</span>
                    </MenuItem>
                </NavLink>
              }
              {values.values.userData.permission_ids.includes(2154) &&
                            <NavLink tag={Link} id="nav-country-waypay" className="nav-link" to="/country-waypay" style={{margin: '0px', padding:'2px',paddingLeft:'20px'}}>
                                <MenuItem onClick={handleSubClose} style={{margin: '0px', padding:'2px'}}>
                                    <div className="sb-nav-link-icon mr-2"></div>
                                    <span>{values.values.resources.Title_CountryWayPay}</span>

                                </MenuItem>
                            </NavLink>
              }
              {values.values.userData.permission_ids.includes(11) &&
                  <NavLink tag={Link} id="nav-countries" className="nav-link" to="/countries" style={{margin: '0px', padding:'2px',paddingLeft:'20px'}}>
                      <MenuItem onClick={handleClose} style={{margin: '0px', padding:'2px'}}>
                          <div className="sb-nav-link-icon mr-2"><i className=""></i></div> <span>{values.values.resources.NavItem_Countries}</span>
                      </MenuItem>
                  </NavLink>
              }
              {values.values.userData.permission_ids.includes(152) &&
                  <NavLink tag={Link} id="nav-treatments" className="nav-link" to="/treatmentsQuestion" style={{margin: '0px', padding:'2px',paddingLeft:'20px'}}>
                      <MenuItem onClick={handleClose} style={{margin: '0px', padding:'2px'}}>
                          <div className="sb-nav-link-icon mr-2"></div> <span>{values.values.resources.NavItem_TreatmentQuestion}</span>
                      </MenuItem>
                  </NavLink>
              }
              {values.values.userData.permission_ids.includes(16) &&
                  <NavLink tag={Link} id="nav-langresources" className="nav-link" to="/langresources" style={{margin: '0px', padding:'2px',paddingLeft:'20px'}}>
                      <MenuItem onClick={handleClose} style={{margin: '0px', padding:'2px'}}>
                          <div className="sb-nav-link-icon mr-2"><i className=""></i></div> <span>{values.values.resources.NavItem_LanguageResources}</span>
                      </MenuItem>
                  </NavLink>
              }
              {values.values.userData.permission_ids.includes(73) &&
              <div id="mens-menu">
          
                    <Button 
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    id="ui-menu"
                    onClick={handleSubClick}
                    style={{padding: '3px', paddingLeft: '15px'}}>
                        <i class=""></i>
                        {values.values.resources.Title_Messenger}
                </Button>
                <Menu
                    id="simple-menu-child"
                    className="config-menu"
                    anchorEl={anchorSubEl}
                    keepMounted
                    open={Boolean(anchorSubEl)}
                    onClose={handleSubClose}
                    >
                        {values.values.userData.permission_ids.includes(147) &&
                            <NavLink tag={Link} id="nav-closing-reasons" className="nav-link" to="/closing-reasons" style={{margin: '0px', padding:'2px',paddingLeft:'20px'}}>
                                <MenuItem onClick={handleSubClose} style={{margin: '0px', padding:'2px'}}>
                                    <div className="sb-nav-link-icon mr-2"></div>
                                    <span>{values.values.resources.Titile_ClosingReasons}</span>

                                </MenuItem>
                            </NavLink>
                       }
                        {values.values.userData.permission_ids.includes(73) &&
                            <NavLink tag={Link} id="nav-objetions" className="nav-link" to="/objetions" style={{margin: '0px', padding:'2px',paddingLeft:'20px'}}>
                                <MenuItem onClick={handleSubClose} style={{margin: '0px', padding:'2px'}}>
                                    <div className="sb-nav-link-icon mr-2"></div>
                                    <span>{values.values.resources.Title_Objetions}</span>

                                </MenuItem>
                            </NavLink>
                        }

                        {values.values.userData.permission_ids.includes(76) &&
                            <NavLink tag={Link} id="nav-scriptsales" className="nav-link" to="/scriptsales" style={{margin: '0px', padding:'2px',paddingLeft:'20px'}}>
                                <MenuItem onClick={handleSubClose} style={{margin: '0px', padding:'2px'}}>
                                    <div className="sb-nav-link-icon mr-2"></div>
                                    <span>{values.values.resources.Title_ScriptSales}</span>

                                </MenuItem>
                            </NavLink>
                          }
                </Menu>

                </div>
            }
            {values.values.userData.permission_ids.includes(19) &&
                  <NavLink tag={Link} id="nav-treatments" className="nav-link" to="/treatments" style={{margin: '0px', padding:'2px',paddingLeft:'20px'}}>
                      <MenuItem onClick={handleClose} style={{margin: '0px', padding:'2px'}}>
                          <div className="sb-nav-link-icon mr-2"><i class=""></i></div> <span>{values.values.resources.NavItem_Treatments}</span>
                      </MenuItem>
                  </NavLink>
              }
              {values.values.userData.permission_ids.includes(70) &&
                  <NavLink tag={Link} id="nav-sellers" className="nav-link" to="/sellers" style={{margin: '0px', padding:'2px',paddingLeft:'20px'}}>
                      <MenuItem onClick={handleClose} style={{margin: '0px', padding:'2px'}}>
                          <div className="sb-nav-link-icon mr-2"><i className=""></i></div><span>{values.values.resources.NavItem_Sellers}</span>
                      </MenuItem>
                  </NavLink>
              }
              {/* <MenssegerMenu values={values}></MenssegerMenu> */}

            </Menu>

    </div>
      
  );
}


function RHMenu(values) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
        document.querySelector('#rh-menu').style.backgroundColor = '#a72835e7';
    };

    const handleClose = () => {
        setAnchorEl(null);
        toggleSidebar();
        document.querySelector('#rh-menu').style.backgroundColor = '#DC3444';
    };

    const toggleSidebar = (key, name) => {
        helpers.toggleSidebar();
    }
 
    return (
        <div>
          <i class="fas fa-users ml-2" style={{color: 'rgba(255, 255, 255, 0.8)'}}></i>
            <Button 
            aria-controls="simple-menu"
            aria-haspopup="true"
            id="rh-menu"
            onClick={handleClick}
            style={{padding: '3px'}}
        >
            RRHH
        </Button>
        <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            >
                {values.values.userData.permission_ids.includes(8) &&
                  <NavLink tag={Link} id="nav-areas" className="nav-link" to="/areas" style={{margin: '0px', padding:'2px',paddingLeft:'20px'}}>
                      <MenuItem onClick={handleClose} style={{margin: '0px', padding:'2px'}}>
                          <div className="sb-nav-link-icon mr-2"><i className=""></i></div> <span>{values.values.resources.NavItem_Areas}</span>
                      </MenuItem>
                  </NavLink>
              }

              {values.values.userData.permission_ids.includes(9) &&
                  <NavLink tag={Link} id="nav-subareas" className="nav-link" to="/subareas" style={{margin: '0px', padding:'2px',paddingLeft:'20px'}}>
                      <MenuItem onClick={handleClose} style={{margin: '0px', padding:'2px'}}>
                          <div className="sb-nav-link-icon mr-2"><i className=""></i></div> <span>{values.values.resources.NavItem_SubAreas}</span>
                      </MenuItem>
                  </NavLink>
              }
              {values.values.userData.permission_ids.includes(14) &&
                    <NavLink tag={Link} id="nav-users" className="nav-link" to="/users" style={{margin: '0px', padding:'2px',paddingLeft:'20px'}}>
                        <MenuItem onClick={handleClose} style={{margin: '0px', padding:'2px'}}>
                            <div className="sb-nav-link-icon mr-2"><i className=""></i></div> <span>{values.values.resources.NavItem_Users}</span>
                        </MenuItem>
                    </NavLink>
                }
        </Menu>

        </div>
      
    );
}



/**SUB MENU CONFIGURATIONS */
function ConfigMenu(values) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    
    const state = values;

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
        document.querySelector('#conf-menu').style.backgroundColor = '#a72835e7';
    };

    const handleClose = () => {
        setAnchorEl(null);
        toggleSidebar();
        document.querySelector('#conf-menu').style.backgroundColor = '#DC3444';

    };

    const toggleSidebar = (key, name) => {
        helpers.toggleSidebar();
    }


    return (
        <div id="conf-menu">
          
        <Button 
            aria-controls="simple-menu"
            aria-haspopup="true"
            id="ui-menu"
            onClick={handleClick}
            >
            <i className="fas fa-cog ml-4 mr-1"></i>
            {state.values.values.resources.NavItem_Configurations}
        </Button>
        <Menu
            id="simple-menu"
            className="config-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            >
                
                <NavLink tag={Link} id="nav-configurations" className="nav-link" to="/configurations">
                        <MenuItem onClick={handleClose}>
                            <div className="sb-nav-link-icon mr-2"></div>{state.values.values.resources.NavItem_Configurations} 
                        </MenuItem>
                </NavLink>
                <NavLink tag={Link} id="nav-apiclients" className="nav-link" to="/apiclients">
                        <MenuItem onClick={handleClose}>
                            <div className="sb-nav-link-icon mr-2"></div>{state.values.values.resources.NavItem_ApiClients}
                        </MenuItem>
                </NavLink>

                <NavLink tag={Link} id="nav-logs" className="nav-link" to="/logs">
                        <MenuItem onClick={handleClose}>
                        <div className="sb-nav-link-icon mr-2"></div>
                        {state.values.values.resources.Title_Logs} 
                        </MenuItem>
                </NavLink>
                {state.values.values.userData.permission_ids.indexOf(parseInt(state.values.values.configurations.P_AuthTokens)) !== -1 &&
                  <NavLink tag={Link} id="nav-authtokens" className="nav-link" to="/authtokens">
                      <MenuItem onClick={handleClose}>
                          <div className="sb-nav-link-icon mr-2"><i className="fas fa-key"></i></div> {state.values.values.resources.NavItem_AuthorizationTokens}
                      </MenuItem>
                  </NavLink>
                }
                
                
        </Menu>

        </div>
      
    );
}

/**SUB MENU LOGISTIC */
function LogisticMenu(values) {
    
    const [anchorEl, setAnchorEl] = React.useState(null);
    const state = values;
    // console.log(state.values.userData.permission_ids)
    const handleClick = event => {
        setAnchorEl(event.currentTarget);
        document.querySelector('#log-menu').style.backgroundColor = '#a72835e7';
    };

    const handleClose = () => {
        setAnchorEl(null);
        toggleSidebar();
        document.querySelector('#log-menu').style.backgroundColor = '#DC3444';
    };

    const toggleSidebar = (key, name) => {
        helpers.toggleSidebar();
    }
 
    return (
        <div id="log-menu">
            <i class="fas fa-truck ml-2" style={{color: 'rgba(255, 255, 255, 0.8)'}}></i>
            <Button 
            aria-controls="simple-menu"
            aria-haspopup="true"
            id="ui-menu"
            onClick={handleClick}
            style={{padding: '3px'}}
        >
            {state.values.resources.Title_Logistic}
        </Button>
        <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            >
            {state.values.userData.permission_ids.includes(125) && 
           <NavLink tag={Link} id="nav-city" className="nav-link" to="/city" style={{margin: '0px', padding:'2px',paddingLeft:'20px'}}>
                        <MenuItem onClick={handleClose} style={{margin: '0px', padding:'2px'}}>
                        <div className="sb-nav-link-icon mr-2"></div>
                        <span>{state.values.resources.Title_City}</span>

                        </MenuItem>
            </NavLink>
            }
            {state.values.userData.permission_ids.includes(67) && 
           <NavLink tag={Link} id="nav-products" className="nav-link" to="/couriers" style={{margin: '0px', padding:'2px',paddingLeft:'20px'}}>
                        <MenuItem onClick={handleClose} style={{margin: '0px', padding:'2px'}}>
                        <div className="sb-nav-link-icon mr-2"></div>
                        <span>{state.values.resources.NavItem_Courier}</span>

                        </MenuItem>
            </NavLink>
            }
            {state.values.userData.permission_ids.includes(122) && 
           <NavLink tag={Link} id="nav-state" className="nav-link" to="/state" style={{margin: '0px', padding:'2px',paddingLeft:'20px'}}>
                        <MenuItem onClick={handleClose} style={{margin: '0px', padding:'2px'}}>
                        <div className="sb-nav-link-icon mr-2"></div>
                        <span>{state.values.resources.Title_State}</span>

                        </MenuItem>
            </NavLink>
            }
                
            {state.values.userData.permission_ids.includes(135) && 
           <NavLink tag={Link} id="nav-map-courier-city" className="nav-link" to="/map-courier-city" style={{margin: '0px', padding:'2px',paddingLeft:'20px'}}>
                        <MenuItem onClick={handleClose} style={{margin: '0px', padding:'2px'}}>
                        <div className="sb-nav-link-icon mr-2"></div>
                        <span>{state.values.resources.Title_MapCourierCity}</span>

                        </MenuItem>
            </NavLink>
            }
            {state.values.userData.permission_ids.includes(135) && 
           <NavLink tag={Link} id="validate-cities" className="nav-link" to="/validate-cities" style={{margin: '0px', padding:'2px',paddingLeft:'20px'}}>
                        <MenuItem onClick={handleClose} style={{margin: '0px', padding:'2px'}}>
                        <div className="sb-nav-link-icon mr-2"></div>
                        <span>{state.values.resources.Label_ValidateCities}</span>

                        </MenuItem>
            </NavLink>
            }

            {state.values.userData.permission_ids.includes(2184) && 
           <NavLink tag={Link} id="nav-map-courier-export" className="nav-link" to="/map-courier-city-export" style={{margin: '0px', padding:'2px',paddingLeft:'20px'}}>
                        <MenuItem onClick={handleClose} style={{margin: '0px', padding:'2px'}}>
                        <div className="sb-nav-link-icon mr-2"></div>
                        <span>{state.values.resources.Title_ExportRoutes}</span>

                        </MenuItem>
            </NavLink>
            }
            {state.values.userData.permission_ids.includes(2185) && 
           <NavLink tag={Link} id="nav-map-courier-import" className="nav-link" to="/map-courier-city-import" style={{margin: '0px', padding:'2px',paddingLeft:'20px'}}>
                        <MenuItem onClick={handleClose} style={{margin: '0px', padding:'2px'}}>
                        <div className="sb-nav-link-icon mr-2"></div>
                        <span>{state.values.resources.Title_ImportRoutes}</span>
                        </MenuItem>
            </NavLink>
            }
            {state.values.userData.permission_ids.includes(2194) && 
           <NavLink tag={Link} id="nav-map-courier-import" className="nav-link" to="/update-shippingcost" style={{margin: '0px', padding:'2px',paddingLeft:'20px'}}>
                        <MenuItem onClick={handleClose} style={{margin: '0px', padding:'2px'}}>
                        <div className="sb-nav-link-icon mr-2"></div>
                        <span>{state.values.resources.Title_ShippingCostUpdate}</span>
                        </MenuItem>
            </NavLink>
            }
            {state.values.userData.permission_ids.includes(2200) && 
           <NavLink tag={Link} id="nav-log-map-courier-city-list" className="nav-link" to="/log-map-courier-city-list" style={{margin: '0px', padding:'2px',paddingLeft:'20px'}}>
                        <MenuItem onClick={handleClose} style={{margin: '0px', padding:'2px'}}>
                        <div className="sb-nav-link-icon mr-2"></div>
                        <span>{state.values.resources.Tittle_LogMapCourierCity}</span>
                        </MenuItem>
            </NavLink>
            }
        </Menu>

        </div>
      
    );
}

function CampaignMenu(values) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const state = values;
    const handleClick = event => {
        setAnchorEl(event.currentTarget);
        document.querySelector('#campaing-menu').style.backgroundColor = '#a72835e7';
    };

    const handleClose = () => {
        setAnchorEl(null);
        toggleSidebar();
        document.querySelector('#campaing-menu').style.backgroundColor = '#DC3444';
    };

    const toggleSidebar = (key, name) => {
        helpers.toggleSidebar();
    }
 
    return (
        <div id="campaing-menu">
          <i class="far fa-newspaper ml-2" style={{color: 'rgba(255, 255, 255, 0.8)'}}></i>
            <Button 
            aria-controls="simple-menu"
            aria-haspopup="true"
            id="ui-menu"
            onClick={handleClick}
            style={{padding: '3px'}}
        >
            {state.values.resources.Title_Campaign}
        </Button>
        <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            >
            {state.values.userData.permission_ids.includes(94) &&
                    <NavLink tag={Link} id="nav-products" className="nav-link" to="/agency" style={{margin: '0px', padding:'2px',paddingLeft:'20px'}}>
                        <MenuItem onClick={handleClose} style={{margin: '0px', padding:'2px'}}>
                            <div className="sb-nav-link-icon mr-2"></div>
                            <span>{state.values.resources.Title_Agency}</span>

                        </MenuItem>
                    </NavLink>
            }
            {values.values.userData.permission_ids.includes(129) &&
                  <NavLink tag={Link} id="nav-campaing" className="nav-link" to="/campaing" style={{margin: '0px', padding:'2px',paddingLeft:'20px'}}>
                      <MenuItem onClick={handleClose} style={{margin: '0px', padding:'2px'}}>
                          <div className="sb-nav-link-icon mr-2"><i class=""></i></div><span>{values.values.resources.Title_Campaing}</span>
                      </MenuItem>
                  </NavLink>
            }
            {state.values.userData.permission_ids.includes(119) &&
                    <NavLink tag={Link} id="nav-did" className="nav-link" to="/did" style={{margin: '0px', padding:'2px',paddingLeft:'20px'}}>
                        <MenuItem onClick={handleClose} style={{margin: '0px', padding:'2px'}}>
                            <div className="sb-nav-link-icon mr-2"></div>
                            <span>DID</span>

                        </MenuItem>
                    </NavLink>
            }
            {state.values.userData.permission_ids.includes(139) &&
                    <NavLink tag={Link} id="nav-products" className="nav-link" to="/domain" style={{margin: '0px', padding:'2px',paddingLeft:'20px'}}>
                        <MenuItem onClick={handleClose} style={{margin: '0px', padding:'2px'}}>
                            <div className="sb-nav-link-icon mr-2"><i class=""></i></div>
                            <span>{state.values.resources.Label_Domains}</span>

                        </MenuItem>
                    </NavLink>
            }
            {state.values.userData.permission_ids.includes(20) && 
           <NavLink tag={Link} id="nav-products" className="nav-link" to="/formats" style={{margin: '0px', padding:'2px',paddingLeft:'20px'}}>
                        <MenuItem onClick={handleClose} style={{margin: '0px', padding:'2px'}}>
                        <div className="sb-nav-link-icon mr-2"></div>
                        <span>{state.values.resources.Title_Formats}</span>

                        </MenuItem>
            </NavLink>
            }
            {state.values.userData.permission_ids.includes(85) &&
                    <NavLink tag={Link} id="nav-products" className="nav-link" to="/mediagroups" style={{margin: '0px', padding:'2px',paddingLeft:'20px'}}>
                        <MenuItem onClick={handleClose} style={{margin: '0px', padding:'2px'}}>
                            <div className="sb-nav-link-icon mr-2"></div>
                            <span>{state.values.resources.Title_MediaGroup}</span>

                        </MenuItem>
                    </NavLink>
            }
            {state.values.userData.permission_ids.includes(97) &&
                    <NavLink tag={Link} id="nav-products" className="nav-link" to="/media" style={{margin: '0px', padding:'2px',paddingLeft:'20px'}}>
                        <MenuItem onClick={handleClose} style={{margin: '0px', padding:'2px'}}>
                            <div className="sb-nav-link-icon mr-2"></div>
                            <span>{state.values.resources.Title_Media}</span>

                        </MenuItem>
                    </NavLink>
            }
            {state.values.userData.permission_ids.includes(104) &&
                    <NavLink tag={Link} id="nav-products" className="nav-link" to="/business" style={{margin: '0px', padding:'2px',paddingLeft:'20px'}}>
                        <MenuItem onClick={handleClose} style={{margin: '0px', padding:'2px'}}>
                            <div className="sb-nav-link-icon mr-2"></div>
                            <span>{state.values.resources.Title_Business}</span>

                        </MenuItem>
                    </NavLink>
            }
            {state.values.userData.permission_ids.includes(100) &&
                    <NavLink tag={Link} id="nav-products" className="nav-link" to="/pbxs" style={{margin: '0px', padding:'2px',paddingLeft:'20px'}}>
                        <MenuItem onClick={handleClose} style={{margin: '0px', padding:'2px'}}>
                            <div className="sb-nav-link-icon mr-2"></div>
                            <span>{state.values.resources.Title_Pbx}</span>

                        </MenuItem>
                    </NavLink>
            }
            {state.values.userData.permission_ids.includes(107) &&
                    <NavLink tag={Link} id="nav-pbxdatabase" className="nav-link" to="/pbxdatabase" style={{margin: '0px', padding:'2px',paddingLeft:'20px'}}>
                        <MenuItem onClick={handleClose} style={{margin: '0px', padding:'2px'}}>
                            <div className="sb-nav-link-icon mr-2"></div>
                            <span>{state.values.resources.Title_PbxDatabase}</span>

                        </MenuItem>
                    </NavLink>
            }
            {state.values.userData.permission_ids.includes(2178) &&
                    <NavLink tag={Link} id="nav-publicity" className="nav-link" to="/publicity" style={{margin: '0px', padding:'2px',paddingLeft:'20px'}}>
                        <MenuItem onClick={handleClose} style={{margin: '0px', padding:'2px'}}>
                            <div className="sb-nav-link-icon mr-2"></div>
                            <span>{state.values.resources.Title_Publicity}</span>
                        </MenuItem>
                    </NavLink>
            }
            {values.values.userData.permission_ids.includes(110) &&
                    <NavLink tag={Link} id="nav-campaingtype" className="nav-link" to="/campaing-type" style={{margin: '0px', padding:'2px',paddingLeft:'20px'}}>
                        <MenuItem onClick={handleClose} style={{margin: '0px', padding:'2px'}}>
                            <div className="sb-nav-link-icon mr-2"><i class=""></i></div><span>{values.values.resources.Title_CampaingType}</span>
                        </MenuItem>
                    </NavLink>
            }     
            {state.values.userData.permission_ids.includes(82) &&
                    <NavLink tag={Link} id="nav-products" className="nav-link" to="/mediatypes" style={{margin: '0px', padding:'2px',paddingLeft:'20px'}}>
                        <MenuItem onClick={handleClose} style={{margin: '0px', padding:'2px'}}>
                            <div className="sb-nav-link-icon mr-2"></div>
                            <span>{state.values.resources.Title_MediaType}</span>

                        </MenuItem>
                    </NavLink>
            }
        </Menu>

        </div>
      
    );
}

/**SUB MENU MENSSEGER CC*/
function MenssegerMenu(values) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const state = values;

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
        document.querySelector('#mens-menu').style.backgroundColor = '#a72835e7';
        // console.log(values);
    };

    const handleClose = () => {
        setAnchorEl(null);
        toggleSidebar();
        document.querySelector('#mens-menu').style.backgroundColor = '#DC3444';
    };

    const toggleSidebar = (key, name) => {
        helpers.toggleSidebar();
    }
 
    return (
        <div id="mens-menu">
          
            <Button 
            aria-controls="simple-menu"
            aria-haspopup="true"
            id="ui-menu"
            onClick={handleClick}
            style={{padding: '3px'}}
            >
                <i class="fas fa-clipboard-list ml-4 mr-1"></i>
                {state.values.values.resources.Title_Messenger}
        </Button>
        <Menu
            id="simple-menu"
            className="config-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            >
                {state.values.values.userData.permission_ids.includes(73) &&
                    <NavLink tag={Link} id="nav-objetions" className="nav-link" to="/objetions" style={{margin: '0px', padding:'2px',paddingLeft:'20px'}}>
                        <MenuItem onClick={handleClose} style={{margin: '0px', padding:'2px'}}>
                            <div className="sb-nav-link-icon mr-2"></div>
                            <span>{state.values.values.resources.Title_Objetions}</span>

                        </MenuItem>
                    </NavLink>
                }

                {state.values.values.userData.permission_ids.includes(76) &&
                    <NavLink tag={Link} id="nav-scriptsales" className="nav-link" to="/scriptsales" style={{margin: '0px', padding:'2px',paddingLeft:'20px'}}>
                        <MenuItem onClick={handleClose} style={{margin: '0px', padding:'2px'}}>
                            <div className="sb-nav-link-icon mr-2"></div>
                            {state.values.values.resources.Title_ScriptSales}

                        </MenuItem>
                    </NavLink>
                }
        </Menu>

        </div>
      
    );
}

function StockMenu(values) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const state = values;
    const handleClick = event => {
        setAnchorEl(event.currentTarget);
        document.querySelector('#stock-menu').style.backgroundColor = '#a72835e7';
    };

    const handleClose = () => {
        setAnchorEl(null);
        toggleSidebar();
        document.querySelector('#stock-menu').style.backgroundColor = '#DC3444';
    };

    const toggleSidebar = (key, name) => {
        helpers.toggleSidebar();
    }
 
    return (
        <div id="stock-menu">
          <i class="fas fa-box-open ml-2"></i>
            <Button 
            aria-controls="simple-menu"
            aria-haspopup="true"
            id="ui-menu"
            onClick={handleClick}
            style={{padding: '3px'}}
        >
            STOCK
        </Button>
        <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            >
                {state.values.userData.permission_ids.includes(132) &&
                  <NavLink tag={Link} id="nav-brands" className="nav-link" to="/product-category" style={{margin: '0px', padding:'2px',paddingLeft:'20px'}}>
                      <MenuItem onClick={handleClose} style={{margin: '0px', padding:'2px'}}>
                          <div className="sb-nav-link-icon mr-2"></div> <span>{values.values.resources.Title_ProductCategory}</span>
                      </MenuItem>
                  </NavLink>
                }
                {state.values.userData.permission_ids.includes(2151) &&
                  <NavLink tag={Link} id="nav-info-product" className="nav-link" to="/info-product" style={{margin: '0px', padding:'2px',paddingLeft:'20px'}}>
                      <MenuItem onClick={handleClose} style={{margin: '0px', padding:'2px'}}>
                          <div className="sb-nav-link-icon mr-2"></div> <span>{values.values.resources.Label_ProductInformation}</span>
                      </MenuItem>
                  </NavLink>
                }
                {state.values.userData.permission_ids.includes(2172) &&
                  <NavLink tag={Link} id="nav-info-product" className="nav-link" to="/kit-product" style={{margin: '0px', padding:'2px',paddingLeft:'20px'}}>
                      <MenuItem onClick={handleClose} style={{margin: '0px', padding:'2px'}}>
                          <div className="sb-nav-link-icon mr-2"></div> <span>{values.values.resources.Label_KitProducts}</span>
                      </MenuItem>
                  </NavLink>
                }

            {state.values.userData.permission_ids.includes(10) &&
                  <NavLink tag={Link} id="nav-brands" className="nav-link" to="/brands" style={{margin: '0px', padding:'2px',paddingLeft:'20px'}}>
                      <MenuItem onClick={handleClose} style={{margin: '0px', padding:'2px'}}>
                          <div className="sb-nav-link-icon mr-2"><i className=""></i></div> <span>{values.values.resources.NavItem_Brands}</span>
                      </MenuItem>
                  </NavLink>
            }
                
            {state.values.userData.permission_ids.includes(79) &&
                    <NavLink tag={Link} id="nav-products" className="nav-link" to="/products" style={{margin: '0px', padding:'2px',paddingLeft:'20px'}}>
                        <MenuItem onClick={handleClose} style={{margin: '0px', padding:'2px'}}>
                            <div className="sb-nav-link-icon mr-2"></div>
                            <span>{state.values.resources.Label_Products}</span>

                        </MenuItem>
                    </NavLink>
            }
            {state.values.userData.permission_ids.includes(6) &&
                  <NavLink tag={Link} id="nav-suppliers" className="nav-link" to="/suppliers" style={{margin: '0px', padding:'2px',paddingLeft:'20px'}}>
                      <MenuItem onClick={handleClose} style={{margin: '0px', padding:'2px'}}>
                          <div className="sb-nav-link-icon mr-2"><i className=""></i></div> <span>{values.values.resources.NavItem_Suppliers}</span>
                      </MenuItem>
                  </NavLink>
              }
            {state.values.userData.permission_ids.includes(2188) &&
                  <NavLink tag={Link} id="nav-products-all" className="nav-link" to="/product-countrykey-all" style={{margin: '0px', padding:'2px',paddingLeft:'20px'}}>
                      <MenuItem onClick={handleClose} style={{margin: '0px', padding:'2px'}}>
                          <div className="sb-nav-link-icon mr-2"><i className=""></i></div> <span>{values.values.resources.NavItem_EDKeys}</span>
                      </MenuItem>
                  </NavLink>
              }
              {state.values.userData.permission_ids.includes(2193) &&
                  <NavLink tag={Link} id="nav-products-all" className="nav-link" to="/product-key-list" style={{margin: '0px', padding:'2px',paddingLeft:'20px'}}>
                      <MenuItem onClick={handleClose} style={{margin: '0px', padding:'2px'}}>
                          <div className="sb-nav-link-icon mr-2"><i className=""></i></div> <span>{values.values.resources.NavItem_ProductKeyList}</span>
                      </MenuItem>
                  </NavLink>
              }
              {state.values.userData.permission_ids.includes(2195) &&
                  <NavLink tag={Link} id="nav-products-all" className="nav-link" to="/product-key-suggest" style={{margin: '0px', padding:'2px',paddingLeft:'20px'}}>
                      <MenuItem onClick={handleClose} style={{margin: '0px', padding:'2px'}}>
                          <div className="sb-nav-link-icon mr-2"><i className=""></i></div> <span>{values.values.resources.NavItem_ProductKeySuggest}</span>
                      </MenuItem>
                  </NavLink>
              }
              {state.values.userData.permission_ids.includes(2201) &&
                  <NavLink tag={Link} id="nav-products-all" className="nav-link" to="/claves-sku" style={{margin: '0px', padding:'2px',paddingLeft:'20px'}}>
                      <MenuItem onClick={handleClose} style={{margin: '0px', padding:'2px'}}>
                          <div className="sb-nav-link-icon mr-2"><i className=""></i></div> <span>{values.values.resources.Title_KeysSKU}</span>
                      </MenuItem>
                  </NavLink>
              }
              
        </Menu>

        </div>
      
    );
}

/*MENU SECURITY*/
function SecurityMenu(values) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const state = values;
    const handleClick = event => {
        setAnchorEl(event.currentTarget);
        document.querySelector('#security-menu').style.backgroundColor = '#a72835e7';
    };

    const handleClose = () => {
        setAnchorEl(null);
        toggleSidebar();
        document.querySelector('#security-menu').style.backgroundColor = '#DC3444';
    };

    const toggleSidebar = (key, name) => {
        helpers.toggleSidebar();
    }
 
    return (
        <div id="security-menu">
          <i className="fas fa-key ml-2"></i>
            <Button 
            aria-controls="simple-menu"
            aria-haspopup="true"
            id="ui-menu"
            onClick={handleClick}
            style={{padding: '3px'}}
        >
            {state.values.resources.NavItem_Security}
        </Button>
        <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            >
                {state.values.userData.permission_ids.includes(15) &&
                  <NavLink tag={Link} id="nav-authtokens" className="nav-link" to="/authtokens" style={{margin: '0px', padding:'2px',paddingLeft:'20px'}}>
                      <MenuItem onClick={handleClose} style={{margin: '0px', padding:'2px'}}>
                          <div className="sb-nav-link-icon mr-2"></div> <span>{state.values.resources.NavItem_AuthorizationTokens}</span>
                      </MenuItem>
                  </NavLink>
                }
                {state.values.userData.permission_ids.includes(18) &&
                    <NavLink tag={Link} id="nav-apiclients" className="nav-link" to="/apiclients" style={{margin: '0px', padding:'2px',paddingLeft:'20px'}}>
                        <MenuItem onClick={handleClose} style={{margin: '0px', padding:'2px'}}>
                            <div className="sb-nav-link-icon mr-2"></div><span>{state.values.resources.NavItem_ApiClients}</span>
                        </MenuItem>
                    </NavLink>
                }
                {state.values.userData.permission_ids.includes(66) &&
                    <NavLink tag={Link} id="nav-logs" className="nav-link" to="/logs" style={{margin: '0px', padding:'2px',paddingLeft:'20px'}}>
                        <MenuItem onClick={handleClose} style={{margin: '0px', padding:'2px'}}>
                            <div className="sb-nav-link-icon mr-2"></div>
                            <span>{state.values.resources.Title_Logs}</span>
                        </MenuItem>
                    </NavLink>
                }

              {state.values.userData.permission_ids.includes(13) &&
                  <NavLink tag={Link} id="nav-permissiongroups" className="nav-link" to="/permissiongroups" style={{margin: '0px', padding:'2px',paddingLeft:'20px'}}>
                      <MenuItem onClick={handleClose} style={{margin: '0px', padding:'2px'}}>
                          <div className="sb-nav-link-icon mr-2"><i className=""></i></div> <span>{state.values.resources.NavItem_PermissionGroups}</span>
                      </MenuItem>
                  </NavLink>
              }
              {state.values.userData.permission_ids.includes(12) &&
                  <NavLink tag={Link} id="nav-permissions" className="nav-link" to="/permissions" style={{margin: '0px', padding:'2px',paddingLeft:'20px'}}>
                      <MenuItem onClick={handleClose} style={{margin: '0px', padding:'2px'}}>
                          <div className="sb-nav-link-icon mr-2"><i className=""></i></div> <span>{state.values.resources.NavItem_Permissions}</span>
                      </MenuItem>
                  </NavLink>
              }
        </Menu>

        </div>
      
    );
}

function SalesMenu(values) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const state = values;
    const handleClick = event => {
        setAnchorEl(event.currentTarget);
        document.querySelector('#sales-menu').style.backgroundColor = '#a72835e7';
    };

    const handleClose = () => {
        setAnchorEl(null);
        toggleSidebar();
        document.querySelector('#sales-menu').style.backgroundColor = '#DC3444';
    };

    const toggleSidebar = (key, name) => {
        helpers.toggleSidebar();
    }
 
    return (
        <div id="sales-menu">
          <i class="fas fa-cart-arrow-down ml-2"></i>
            <Button 
            aria-controls="simple-menu"
            aria-haspopup="true"
            id="ui-menu"
            onClick={handleClick}
            style={{padding: '5px'}}
        >
            {state.values.resources.Label_Sales}
        </Button>
        <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            >
            {state.values.userData.permission_ids.includes(1150) &&
                <NavLink tag={Link} id="nav-clients" className="nav-link" to="/clients" style={{margin: '0px', padding:'4px',paddingLeft:'20px'}}>
                    <MenuItem onClick={handleClose} style={{margin: '0px', padding:'4px'}}>
                        <div className="sb-nav-link-icon mr-2"></div> <span>{state.values.resources.Title_Clients}</span>
                    </MenuItem>
                </NavLink>
            } 
             {state.values.userData.permission_ids.includes(2187) &&
                <NavLink tag={Link} id="nav-saleshistory" className="nav-link" to="/saleshistory" style={{margin: '0px', padding:'4px',paddingLeft:'20px'}}>
                    <MenuItem onClick={handleClose} style={{margin: '0px', padding:'4px'}}>
                        <div className="sb-nav-link-icon mr-2"></div> <span>{state.values.resources.Title_SalesHistory}</span>
                    </MenuItem>
                </NavLink>
            } 
            {state.values.userData.permission_ids.includes(2189) &&
                <NavLink tag={Link} id="nav-mysales" className="nav-link" to="/mysales" style={{margin: '0px', padding:'4px',paddingLeft:'20px'}}>
                    <MenuItem onClick={handleClose} style={{margin: '0px', padding:'4px'}}>
                        <div className="sb-nav-link-icon mr-2"></div> <span>{state.values.resources.Title_MySales}</span>
                    </MenuItem>
                </NavLink>
            } 
            {state.values.userData.permission_ids.includes(2191) &&
                <NavLink tag={Link} id="nav-mysalespending" className="nav-link" to="/mysalespending" style={{margin: '0px', padding:'4px',paddingLeft:'20px'}}>
                    <MenuItem onClick={handleClose} style={{margin: '0px', padding:'4px'}}>
                        <div className="sb-nav-link-icon mr-2"></div> <span>{state.values.resources.Title_MySalesPendingPayment}</span>
                    </MenuItem>
                </NavLink>
            } 
            {state.values.userData.permission_ids.includes(2198) &&
                <NavLink tag={Link} id="nav-mysalespending" className="nav-link" to="/saleresume" style={{margin: '0px', padding:'4px',paddingLeft:'20px'}}>
                    <MenuItem onClick={handleClose} style={{margin: '0px', padding:'4px'}}>
                        <div className="sb-nav-link-icon mr-2"></div> <span>{state.values.resources.Title_SaleResume}</span>
                    </MenuItem>
                </NavLink>
            } 
        </Menu>

        </div>
      
    );
}


