import React, { Component } from 'react';
import axios from 'axios';
import $ from 'jquery';
import helpers from '../../Helpers'

export class ValidateCities extends Component {
    static displayName = ValidateCities.name;

    constructor(props) {
        super(props);

        this.onChangeCountry = this.onChangeCountry.bind(this);
        this.onChangeState = this.onChangeState.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onSubmit = this.onSubmit.bind(this);  

        this.state = {
            id: 0,
            countries: [],
            countryId: null,
            states: [],
            stateId: null,
            cities: [],
            citiesIds: [],
            citiesNames: [],
            loading: true,
            resources: [],
            currentCountry: '',
            currentState: '',
            arrayCitiesAll: [],
        };
    }

    componentDidMount() {
        this.getResources();
        this.populateData();
        helpers.setNavActive("#nav-Agency");
    }


    onChangeCountry(e) { 
        e.preventDefault();
        this.setState({
            countryId: e.target.value
        })
        this.setState({
            citiesIds: [],
            citiesNames: [],
            arrayCitiesAll: []
        })
        this.state.citiesIds = [];
        this.state.citiesNames = [];
    }

    onChangeState(e) {
        e.preventDefault();
        this.setState({
            stateId: e.target.value
        })
        this.state.states.map(state => {
            if (state.id == this.state.stateId) {
                this.setState({
                    currentState: state.name
                });
            }
        })
        this.setState({
            citiesIds: [],
            citiesNames: []
        })
         this.state.citiesIds = [];
            this.state.citiesNames = [];
    }

    onCancel(e) {
        e.preventDefault();
        this.props.history.push('/map-courier-city');
    }
    
    async onSubmit(e) {
        e.preventDefault();
        let authToken = await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME)
        helpers.showLoadingOverlay();
        if (this.state.countryId == -1) {
            
            axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/mapcouriercity/get',
                {
                    'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
                })
                .then(response => {
                    const data = response.data.data;

                    for (let i = 0; i < data.length; i++) {
                        let cities = data[i].cities.split(',');
                        for (let x = 0; x < cities.length; x++){
                            this.setState({
                                citiesIds: [...this.state.citiesIds, cities[x]]
                            })
                        }
                        
                    }
                    const dataArr = new Set(this.state.citiesIds);

                    this.setState({
                        citiesIds: [...dataArr]
                    })
                    console.log(this.state.citiesIds);

                    axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/city/get',
                        {
                            'headers': { 'Authorization':  authToken}
                        })
                        .then(response => {
                            const data = response.data.data;
                            // console.log(data);
                            for (let i = 0; i < data.length; i++) {
                                if (!this.state.citiesIds.includes(data[i].id.toString())) {
                                    let currentCity = data[i];
                                    // console.log(this.state.countries);
                                    for (let c = 0; c < this.state.countries.length; c++) {
                                        for (let s = 0; s < this.state.states.length; s++) {
                                            if(currentCity.state_id == this.state.states[s].id) {
                                                currentCity.country_name = this.state.states[s].country_name;
                                            }
                                        }
                                    }
                                    this.setState({
                                        arrayCitiesAll: [...this.state.arrayCitiesAll.concat(currentCity)]
                                    })
                                }
                            }
                            // console.log(this.state.arrayCitiesAll);
                            this.setState({
                              arrayCitiesAll:  this.state.arrayCitiesAll.sort((a,b) => a.country_name < b.country_name ? -1 : +(a.country_name > b.country_name))
                            });
                         })
                        .catch(error => {
                            console.log(error);
                        });
                    
                    helpers.hideLoadingOverlay();
                })
                .catch( (error) => {
                    console.log(error);
                });

        } else {
            
            this.state.countries.map(country => {
                if (country.id == this.state.countryId) {
                    this.setState({
                        currentCountry: country.name
                    });
                }
            })
            this.state.states.map(state => {
                if (state.id == this.state.stateId) {
                    this.setState({
                        currentState: state.name
                    });
                }
            })

            axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/mapcouriercity/get',
                {
                    'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
                })
                .then(response => {
                    const data = response.data.data;
                    // console.log(data)
                    let myArr = data.filter(x => x.state_id == this.state.stateId);
                    // console.log(myArr)
                    for (let i = 0; i < myArr.length; i++) {
                        myArr[i].cities = myArr[i].cities.split(',')
                        // console.log(myArr[i])
                        for (let c = 0; c < myArr[i].cities.length; c++) {
                            // console.log(myArr[i].cities[c])
                            this.setState({
                                citiesIds: [...this.state.citiesIds.concat(myArr[i].cities[c])]
                            })
                            
                        }
                    }
                    
                    const dataArr = new Set(this.state.citiesIds);

                    this.setState({
                        citiesIds: [...dataArr]
                    })
                    // console.log(this.state.citiesIds)
                    this.state.cities.map(city => {
                        if (this.state.citiesIds.includes((city.id).toString())) {
                            // console.log('incluye: ')
                            // console.log(city)
                        } else {
                            // console.log('No incluye: ')
                            // console.log(city)
                            if (city.state_id == this.state.stateId) {
                                this.setState({
                                    citiesNames: [...this.state.citiesNames.concat(city.name)]
                                })
                            }
                            
                        }
                    })
                    helpers.hideLoadingOverlay();
                })
                .catch( (error) => {
                    console.log(error);
                });
        }
    }

    render() {
        return (
            <>
                <div>
                    <ol className="breadcrumb my-4">
                        <li className="breadcrumb-item active">{this.state.resources.Label_ValidateCities}</li>
                    </ol>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-5 card m-1 p-3">
                                <div>
                                    <label className=" form-label">{this.state.resources.Label_Country}</label>
                                    <select className="form-control" data-val="true" name="country_id" onChange={this.onChangeCountry}>
                                        <option value="">{this.state.resources.Label_SelectOption}</option>
                                        <option value="-1">{this.state.resources.Label_All}</option>
                                        {this.state.countries.map(country =>
                                            <option key={country.id} value={country.id}>{country.name}</option>
                                        )}
                                    </select>
                                </div>
                                
                                <div>
                                    <label className=" form-label">{this.state.resources.Title_State}</label>
                                    <select disabled={this.state.countryId == 0 || this.state.countryId == -1} className="form-control" data-val="true" name="state_id" defaultValue={this.state.stateId} onChange={this.onChangeState}>
                                        <option value="">{this.state.resources.Label_SelectOption}</option>
                                        {this.state.states.map(state =>
                                            state.country_id == this.state.countryId && 
                                            <option key={state.id} value={state.id}>{state.name}</option>
                                        )}
                                    </select>
                                </div> 

                                <div className="m-1">
                                    <button onClick={this.onSubmit} className="btn btn-primary m-1">{this.state.resources.Datatables_language_search}</button>
                                    <button onClick={this.onCancel} className="btn btn-danger m-1">{this.state.resources.ButtonLabel_Cancel}</button>
                                </div>
                            </div>
                            <div className="col-md-5 card m-1 p-3">
                                <label className=" form-label">{this.state.resources.Label_Cities}</label>
                                <hr></hr>
                                {this.state.countryId != -1 ?
                                <div>
                                    {this.state.citiesNames.map(city =>
                                        <div key={city}><b>{this.state.currentCountry} | {this.state.currentState} | {city}</b></div>
                                    )}
                                </div>
                                :
                                <div>
                                    {this.state.arrayCitiesAll.map(city =>
                                        <div><b>{city.country_name} | {city.state_name} | {city.name}</b></div>
                                    )}
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    async getResources() {
         const resourceVars = [];
        resourceVars.push("Label_ValidateCities");
        resourceVars.push("Label_Country");
        resourceVars.push("Label_Name");
        resourceVars.push("Label_Description");
        resourceVars.push("Label_Actions");
        resourceVars.push("Label_Status");
        resourceVars.push("Label_Enabled");
        resourceVars.push("Label_Disabled");
        resourceVars.push("ButtonLabel_Save");
        resourceVars.push("ButtonLabel_Cancel");
        resourceVars.push("ButtonLabel_New");
        resourceVars.push("Datatables_language_lengthMenu");
        resourceVars.push("Datatables_language_zeroRecords");
        resourceVars.push("Datatables_language_info");
        resourceVars.push("Datatables_language_infoEmpty");
        resourceVars.push("Datatables_language_infoFiltered");
        resourceVars.push("Datatables_language_search");
        resourceVars.push("Datatables_language_paginate_previous");
        resourceVars.push("Datatables_language_paginate_next");
        resourceVars.push("Label_SelectOption");
        resourceVars.push("Title_State");
        resourceVars.push("Label_Cities");
        resourceVars.push("Label_All");
        resourceVars.push("Datatables_language_search");

        const headers = {
            'Content-Type': 'application/json'
        };
        helpers.showLoadingOverlay();
        axios.post(window.config.REACT_APP_SECURITY_API_URL + '/languageresource/getbyvars/' + helpers.getUiLanguage(), resourceVars, {
            headers: headers
        })
            .then((response) => {
                const data = response.data.data;
                this.setState({
                    resources: data
                })
                helpers.hideLoadingOverlay();
            })
            .catch((error) => {
                console.log(error);
                helpers.hideLoadingOverlay();
            }); 

            axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/country/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ countries: data, loading: false });
                // order alphabetically
                this.state.countries.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                // console.log(this.state.countries);
            })
            .catch(function (error) {
                console.log(error);
            });
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/state/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ states: data, loading: false });
                // order alphabetically
                this.state.states.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                // console.log(this.state.states);
            })
            .catch(function (error) {
                console.log(error);
            });
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/city/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ cities: data, loading: false });
                // order alphabetically
                this.state.cities.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                // console.log(this.state.cities);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    async populateData() {
        
    }
}