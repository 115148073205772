import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { ProductsDetail } from './ProductsDetail';
import '../../custom.css';
import { ProductCountryInfo } from './ProductCountryInfo';
import axios from 'axios';
import helpers from '../../Helpers';
import { data } from 'jquery';
import { Redirect } from 'react-router-dom';
import { Link } from 'react-router-dom';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {

  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

//  dataCountryProductInfo = [];

async function populateData(id) {
  let authTokenProducts = await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME);
  let dataCountryProduct = [];
  let countriesArr = [];
     axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/countryproductinfo/getbyproductid/' + id,
                {
                    'headers': { 'Authorization': authTokenProducts}
                })
                .then(response => {
                    dataCountryProduct = response.data.data;
                    // console.log(dataCountryProduct);
                  //  this.dataCountryProductInfo.push(data.country_name);
                  localStorage.setItem('dataCountryProductInfo', JSON.stringify(dataCountryProduct[0]));
                })
                .catch(function (error) {
                    console.log(error);
                });
   axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/productcountry/getproductbyid/' + id,
                {
                    'headers': { 'Authorization': authTokenProducts}
                })
                .then(response => {
                  // console.log(response.data.data)
                  let data = response.data.data;
                  
                  for (let i = 0; i < data.length; i++) {
                    countriesArr.push(data[i].country_name);
                  }
                  
                })
                .catch(function (error) {
                    console.log(error);
                });
      
    return countriesArr;
}
  
function goBack() {
  helpers.notifyWarning('No Countries Match', 'You will be redirec to Detail');
  setTimeout(() => {
    window.location.href =  "/products/";
  }, 2500);
}

export default function ProductInfo(values) {

  const [value, setValue] = React.useState(0);
  const data = {
    id: values.match.params.id,
    countryId: 0,
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  let country = '';
  let idProduct = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);

  populateData(idProduct).then((res) => {});

  //Validate data from localStorage if is an array, a object or a string
  let myDataCountries = localStorage.getItem('dataCountriesProductInfo').includes(',') ?
                        localStorage.getItem('dataCountriesProductInfo').split(',') :
                        localStorage.getItem('dataCountriesProductInfo');
                        
  if (localStorage.getItem('dataCountriesProductInfo').includes('{')) {
    myDataCountries = JSON.parse(localStorage.getItem('dataCountriesProductInfo'));
  }
  let countries = [];
  if (localStorage.getItem('dataCountriesProductInfo').includes(',')) {
    for (let i = 0; i < myDataCountries.length; i++){
      if (!myDataCountries[i].country) {
        countries.push(myDataCountries[i]);
      } else {
        countries.push(myDataCountries[i].country);
      }
    }
  } else {
    if (!myDataCountries.country) {
        countries.push(myDataCountries);
      } else {
        countries.push(myDataCountries.country);
      }
  }
  console.log(myDataCountries);
  if (!myDataCountries == '[]') { 
     //Sort by Country Name
      myDataCountries = myDataCountries.sort((a, b) => a.country < b.country ? -1 : +(a.country > b.country));
      //Sort by State Enabled
      myDataCountries = myDataCountries.sort((a, b) => a.enabled > b.enabled ? -1 : +(a.enabled < b.enabled));
      
  }
 
  return (
    <div>
      <AppBar position="static" id="tabs-country">
        <Tabs
          value={value} 
          onChange={handleChange}
          aria-label="simple tabs example"
        >
          {myDataCountries !== '[]' && myDataCountries.map(country => 
            <Tab label={country.country} icon={country.enabled == false ?
              <CancelPresentationIcon/> : <></>} {...a11yProps(0)} />
          )}

          {myDataCountries == '[]' &&
            <Link to={"/product-country-create"} className="btn btn-success m-2 my-4"><i className="fas fa-plus" aria-hidden="true"></i> </Link>
          }
          
          
          {/* {countries.includes('Argentina') &&
            
            <Tab label="Argentina" icon={myDataCountries.map(x => (
              x.country == 'Argentina' && x.enabled == false ? <CancelPresentationIcon /> : <></>))} {...a11yProps(0)} />
          }
          {countries.includes('Brasil') &&
            
            <Tab label="Brasil" icon={myDataCountries.map(x => (
              x.country == 'Brasil' && x.enabled == false ? <CancelPresentationIcon /> : <></>))} {...a11yProps(1)} />
          }
          {countries.includes('Chile') &&
            
            <Tab label="Chile" icon={myDataCountries.map(x => (
              x.country == 'Chile' && x.enabled == false ? <CancelPresentationIcon /> : <></>))} {...a11yProps(2)} />
          }
          {countries.includes('Colombia') &&
            
            <Tab label="Colombia" icon={myDataCountries.map(x => (
              x.country == 'Colombia' && x.enabled == false ? <CancelPresentationIcon /> : <></>))} {...a11yProps(3)} />
          }
          {countries.includes('Mexico') &&
            
            <Tab label="Mexico" icon={myDataCountries.map(x => (
              x.country == 'Mexico' && x.enabled == false ? <CancelPresentationIcon /> : <></>))} {...a11yProps(4)} />
          }
          {countries.includes('Uruguay') &&
            
            <Tab label="Uruguay" icon={myDataCountries.map(x => (
              x.country == 'Uruguay' && x.enabled == false ? <CancelPresentationIcon /> : <></>))} {...a11yProps(5)} />
          }
          {countries.includes('UK') &&
            
            <Tab label="UK" icon={myDataCountries.map(x => (
              x.country == 'UK' && x.enabled == false ? <CancelPresentationIcon /> : <></>))} {...a11yProps(6)} />
          }
          {/* {countries.includes('') &&
            goBack()
          } 
          {localStorage.getItem('viewMode') != 'true' &&
            <Link to={"/product-country-create"} className="btn btn-success m-2 my-4"><i className="fas fa-plus" aria-hidden="true"></i> </Link>
          } */}
        </Tabs>
        
      </AppBar>
      <TabPanel value={value} index={0} className="tab">
        <ProductCountryInfo values={data}/>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ProductCountryInfo values={data}/>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <ProductCountryInfo values={data}/>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <ProductCountryInfo values={data}/>
      </TabPanel>
      <TabPanel value={value} index={4}>
        <ProductCountryInfo values={data}/>
      </TabPanel>
      <TabPanel value={value} index={5}>
        <ProductCountryInfo values={data}/>
      </TabPanel>
      <TabPanel value={value} index={6}>
        <ProductCountryInfo values={data}/>
      </TabPanel>
    </div>
  );

  
}
