import React, { Component } from 'react';
import axios from 'axios';
import { Route, Redirect } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { AreasList } from './components/Areas/AreasList';
import { AreaCreate } from './components/Areas/AreaCreate';
import { AreaDetail } from './components/Areas/AreaDetail';
import './custom.css';
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import { SubAreasList } from './components/SubAreas/SubAreasList';
import { SubAreaCreate } from './components/SubAreas/SubAreaCreate';
import { SubAreaDetail } from './components/SubAreas/SubAreaDetail';
import { BusinessAreasList } from './components/BusinessAreas/BusinessAreasList';
import { BusinessAreaCreate } from './components/BusinessAreas/BusinessAreaCreate';
import { BusinessAreaDetail } from './components/BusinessAreas/BusinessAreaDetail';
import { CountriesList } from './components/Countries/CountriesList';
import { CountryCreate } from './components/Countries/CountryCreate';
import { CountryDetail } from './components/Countries/CountryDetail';
import { PermissionsList } from './components/Permissions/PermissionsList';
import { PermissionCreate } from './components/Permissions/PermissionCreate';
import { PermissionDetail } from './components/Permissions/PermissionDetail';
import { AuthorizationTokensList } from './components/AuthorizationTokens/AuthorizationTokensList';
import { AuthorizationTokenCreate } from './components/AuthorizationTokens/AuthorizationTokenCreate';
import { AuthorizationTokenDetail } from './components/AuthorizationTokens/AuthorizationTokenDetail';
import { UsersList } from './components/Users/UsersList';
import { UserSearchList } from './components/Users/UserSearchList';
import { UserCreate } from './components/Users/UserCreate';
import { UserDetail } from './components/Users/UserDetail';
import { UserSearchDetail } from './components/Users/UserSearchDetail';
import { LanguageResourcesList } from './components/LanguageResources/LanguageResourcesList';
import { LanguageResourceDetail } from './components/LanguageResources/LanguageResourceDetail';
import { ChangePassword } from './components/Users/ChangePassword';
import { PermissionGroupsList } from './components/PermissionGroups/PermissionGroupsList';
import { PermissionGroupCreate } from './components/PermissionGroups/PermissionGroupCreate';
import { PermissionGroupDetail } from './components/PermissionGroups/PermissionGroupDetail';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import PublicRoute from './components/api-authorization/PublicRoute';
import { Login } from './components/api-authorization/Login';
import { PasswordChange } from './components/Account/PasswordChange';
import { ConfigurationsList } from './components/Configurations/ConfigurationsList';
import { ConfigurationCreate } from './components/Configurations/ConfigurationCreate';
import { ConfigurationDetail } from './components/Configurations/ConfigurationDetail';
import { CompaniesList } from './components/Companies/CompaniesList';
import { CompanyCreate } from './components/Companies/CompanyCreate';
import { CompanyDetail } from './components/Companies/CompanyDetail';
import { Profile } from './components/Account/Profile';
import { ProfilePasswordChange } from './components/Account/ProfilePasswordChange';
import { BrandsList } from './components/Brands/BrandsList';
import { BrandCreate } from './components/Brands/BrandCreate';
import { BrandDetail } from './components/Brands/BrandDetail';
import { SuppliersList } from './components/Suppliers/SuppliersList';
import { SupplierCreate } from './components/Suppliers/SupplierCreate';
import { SupplierDetail } from './components/Suppliers/SupplierDetail';
import { ApiClients, ApiClientsList } from './components/ApiClients/ApiClientsList';
import { ApiClientsCreate } from './components/ApiClients/ApiClientsCreate';
import { ApiClientsEdit } from './components/ApiClients/ApiClientsEdit';
import { TreatmentList } from './components/Treatment/TreatmentList';
import { TreatmentCreate } from './components/Treatment/TreatmentCreate';
import { TreatmentDetail } from './components/Treatment/TreatmentDetail';
import { CourierList } from './components/Couriers/CourierList';
import { CourierDetail } from './components/Couriers/CourierDetail';
import { CourierCreate } from './components/Couriers/CourierCreate';
import { SellersList } from './components/Sellers/SellersList';
import { SellersDetail } from './components/Sellers/SellersDetail';
import { SellersCreate } from './components/Sellers/SellersCreate';
import { ObjetionsList } from './components/Objetions/ObjetionsList';
import { ObjetionsCreate } from './components/Objetions/ObjetionsCreate';
import { ObjetionsDetail } from './components/Objetions/ObjetionsDetail';
import { SalesHistoryList } from './components/SalesHistory/SalesHistoryList';
import { MySalesList } from './components/MySales/MySalesList';
import { MySalesPending } from './components/MySales/MySalesPending';
import { SaleResume } from './components/SaleResume/SaleResume';
import { SalesView } from './components/SalesHistory/SalesView';
import { ScriptSalesList } from './components/ScriptSales/ScriptSalesList';
import { ScriptSalesCreate } from './components/ScriptSales/ScriptSalesCreate';
import { ScriptSalesDetail } from './components/ScriptSales/ScriptSalesDetail';
import helpers from './Helpers';
import SessionHelper from './components/session/SessionHelper';
import { Logs } from './components/Logs/Logs';
import { ProductsList } from './components/Products/ProductsList';
import { ProductsCreate } from './components/Products/ProductsCreate';
import { ProductsDetail } from './components/Products/ProductsDetail';
import ProductInfo from './components/Products/ProductInfo';
import { ProductCountryCreate } from './components/ProductCountry/ProductCountryCreate';
import { ProductCountryDetail } from './components/ProductCountry/ProductCountryDetail';
import { ProductCountryList } from './components/ProductCountry/ProductCountryList'; 
import { ProductKeyList } from './components/ProductKey/ProductKeyList'; 
import { ProductKeySuggest } from './components/ProductKey/ProductKeySuggest'; 
import { FormatList } from './components/Format/FormatList';
import { FormatCreate } from './components/Format/FormatCreate';
import { FormatDetail } from './components/Format/FormatDetail';
import { MediaTypeList } from './components/MediaType/MediaTypeList';
import { MediaTypeCreate } from './components/MediaType/MediaTypeCreate';
import { MediaTypeDetail } from './components/MediaType/MediaTypeDetail';
import { MediaGroupList } from './components/MediaGroup/MediaGroupList';
import { MediaGroupCreate } from './components/MediaGroup/MediaGroupCreate';
import { MediaGroupDetail } from './components/MediaGroup/MediaGroupDetail';
import { ProductCountryKeyList } from './components/ProductCountryKey/ProductCountryKeyList';
import { ProductCountryUpdateCost } from './components/ProductCountry/ProductCountryUpdateCost';
import { ClavesSku } from './components/Claves/ClavesSku';
import { ProductCountryKeyAll } from './components/ProductCountryKey/ProductCountryKeyAll';
import { ProductCountryKeyCreate } from './components/ProductCountryKey/ProductCountryKeyCreate';
import { ProductCountryKeyDetail } from './components/ProductCountryKey/ProductCountryKeyDetail';
import { CountryProduct } from './components/Products/CountryProduct';
import { AgencyList } from './components/Agency/AgencyList';
import { AgencyCreate } from './components/Agency/AgencyCreate';
import { AgencyEdit } from './components/Agency/AgencyEdit';
import { MediaList } from './components/Media/MediaList';
import { MediaCreate } from './components/Media/MediaCreate';
import { MediaEdit } from './components/Media/MediaEdit';
import { PbxList } from './components/Pbx/PbxList';
import { PbxCreate } from './components/Pbx/PbxCreate';
import { PbxEdit } from './components/Pbx/PbxEdit';
import { PublicityList } from './components/Publicity/PublicityList';
import { PublicityCreate } from './components/Publicity/PublicityCreate';
import { PublicityDetail } from './components/Publicity/PublicityDetail';
import { BusinessList } from './components/Business/BusinessList';
import { BusinessCreate } from './components/Business/BusinessCreate';
import { BusinessEdit } from './components/Business/BusinessEdit';
import { PbxDatabaseList } from './components/PbxDatabase/PbxDatabaseList';
import { PbxWorkspaceList } from './components/PbxWorkspace/PbxWorkspaceList';
import { PbxDatabaseCreate } from './components/PbxDatabase/PbxDatabaseCreate';
import { PbxDatabaseDetail } from './components/PbxDatabase/PbxDatabaseDetail';
import { CampaingTypeList } from './components/CampaingType/CampaingTypeList';
import { CampaingTypeCreate } from './components/CampaingType/CampaingTypeCreate';
import { CampaingTypeDetail } from './components/CampaingType/CampaingTypeDetail';
import { CarrierList } from './components/Carrier/CarrierList';
import { CarrierCreate } from './components/Carrier/CarrierCreate';
import { CarrierDetail } from './components/Carrier/CarrierDetail';
import { DidList } from './components/Did/DidList';
import { DidCreate } from './components/Did/DidCreate';
import { DidDetail } from './components/Did/DidDetail';
import { StateList } from './components/State/StateList';
import { StateCreate } from './components/State/StateCreate';
import { StateDetail } from './components/State/StateDetail';
import { CityList } from './components/City/CityList';
import { CityCreate } from './components/City/CityCreate';
import { CityDetail } from './components/City/CityDetail';
import { CampaingList } from './components/Campaing/CampaingList';
import { CampaingCreate } from './components/Campaing/CampaingCreate';
import { CampaingDetail } from './components/Campaing/CampaingDetail';
import { ProductCategoryList } from './components/ProductCategory/ProductCategoryList';
import { ProductCategoryCreate } from './components/ProductCategory/ProductCategoryCreate';
import { ProductCategoryDetail } from './components/ProductCategory/ProductCategoryDetail';
import { MapCourierCityCreate } from './components/MapCourierCity/MapCourierCityCreate';
import { MapCourierCityList } from './components/MapCourierCity/MapCourierCityList';
import { MapCourierExportRoutes } from './components/MapCourierCity/MapCourierExportRoutes';
import { MapCourierImportRoutes } from './components/MapCourierCity/MapCourierImportRoutes';
import { LogMapCourierCityList } from './components/LogMapCourierCity/LogMapCourierCityList';
import { DomainList } from './components/Domain/DomainList';
import { DomainCreate } from './components/Domain/DomainCreate';
import { DomainEdit } from './components/Domain/DomainEdit';
import { MapCourierCityDetail } from './components/MapCourierCity/MapCourierCityDetail';
import { ValidateCities } from './components/ValidateCities/ValidateCities';
import { WayPaysList } from './components/WayPays/WayPaysList';
import { WayPaysCreate } from './components/WayPays/WayPaysCreate';
import { WayPaysDetail } from './components/WayPays/WayPaysDetail';
import { ClosingReasonList } from './components/ClosingReasons/ClosingReasonList';
import { ClosingReasonCreate } from './components/ClosingReasons/ClosingReasonCreate';
import { ClosingReasonDetail } from './components/ClosingReasons/ClosingReasonDetail';
import { TreatmentQuestionList } from './components/TreatmentQuestion/TreatmentQuestionList';
import { TreatmentQuestionCreate } from './components/TreatmentQuestion/TreatmentQuestionCreate';
import { TreatmentQuestionDetail } from './components/TreatmentQuestion/TreatmentQuestionDetail';
import { ClientWsp } from './components/PbxWorkspace/ClientWsp';
import { ClientsList } from './components/Clients/ClientsList';
import { ClientsCreate } from './components/Clients/ClientsCreate';
import { ClientsDetail } from './components/Clients/ClientsDetail';
import { OrderWsp } from './components/PbxWorkspace/OrderWsp';
import { InfoProductList } from './components/InfoProduct/InfoProductList';
import { InfoProductCreate } from './components/InfoProduct/InfoProductCreate';
import { InfoProductDetail } from './components/InfoProduct/InfoProductDetail';
import { NoSaleComponent } from './components/PbxWorkspace/NoSaleComponent';
import { CountryWayPayCreate } from './components/CountryWayPay/CountryWayPayCreate';
import { CountryWayPayDetail } from './components/CountryWayPay/CountryWayPayDetail';
import {CountryWayPayList} from './components/CountryWayPay/CountryWayPayList';
import { PaymentWsp } from './components/PbxWorkspace/PaymentWsp';
import { BankCreate } from './components/Bank/BankCreate';
import { BankDetail } from './components/Bank/BankDetail';
import {BankList} from './components/Bank/BankList';
import { CheckOutWsp } from './components/PbxWorkspace/CheckoutWsp';
import { SuccessWsp } from './components/PbxWorkspace/SuccesWsp';
import { FailureWsp } from './components/PbxWorkspace/FailureWsp';
import { AnnulmentWsp } from './components/PbxWorkspace/AnnulmentWsp';
import { ProductPaymentList } from './components/ProductPayment/ProductPaymentList';
import { ProductPaymentCreate } from './components/ProductPayment/ProductPaymentCreate';
import { ProductPaymentDetail } from './components/ProductPayment/ProductPaymentDetail';
import { ProductPaymentWayPayCreate } from './components/ProductPaymentWayPay/ProductPaymentWayPayCreate';
import { ProductPaymentWayPayList } from './components/ProductPaymentWayPay/ProductPaymentWayPayList';
import { ProductPaymentWayPayDetail } from './components/ProductPaymentWayPay/ProductPaymentWayPayDetail';
import { KitProductList } from './components/KitProducts/KitProductsList';
import { KitProductCreate } from './components/KitProducts/KitProductsCreate';
import { KitProductDetail } from './components/KitProducts/KitProductsDetail';

export default class App extends Component {
    static displayName = App.name;

    constructor(props) {
        super(props);

        this.state = {
            configurations: [],
            permissionsIds: [],
        };

    }
    
    componentDidMount() {
        // this.getConfigurations();
        let userData = helpers.getUserData();
        if (userData !== null) {
            this.state.permissionsIds = userData.permission_ids;
        }
        
    }

    render() {
          return (
              <div>
                  {
                      window.location.href.includes('workspace') ?
                        <>
                          <Route path='/workspace' component={PbxWorkspaceList} /> 
                          <Route exact path='/no-sale-workspace' component={NoSaleComponent} /> 
                          <Route exact path='/client-workspace' component={ClientWsp} />
                          <Route exact path='/order-workspace' component={OrderWsp} />
                          <Route exact path='/payment-workspace' component={PaymentWsp} />
                          <Route exact path='/checkout-workspace' component={CheckOutWsp} />
                          <Route exact path='/success-workspaces' component={SuccessWsp} />
                          <Route exact path='/annulment-workspaces' component={AnnulmentWsp} />
                          <Route exact path='/failure-workspaces' component={FailureWsp} />
                        </>
                    // <PublicRoute path='/workspace/:id_campania/:id_agente/:id_cliente/:calltype/:id_datos/:id_llamada/:id_crm/:id_db/:id_campania_occ/:id_pais' component={PbxWorkspaceList} />
                    :<>
                  <ReactNotification />
                  <Layout>
                      <Route path='/login' component={Login} />
                      <Route path='/searchResult' component={UserSearchList} />
                      <Route path='/user-detail-s/:id' component={UserSearchDetail} />
                      <AuthorizeRoute exact path='/' component={Home} />
                      <AuthorizeRoute exact path='/passwordchange' component={PasswordChange} />
                      <AuthorizeRoute exact path='/profile-passwordchange' component={ProfilePasswordChange} />
                      <AuthorizeRoute exact path='/profile' component={Profile} />  
                      <AuthorizeRoute path='/areas' component={AreasList} />
                        
                      <AuthorizeRoute path='/area-create' component={AreaCreate} />
                          
                      <AuthorizeRoute path='/area-detail/:id' component={AreaDetail} />
                      <AuthorizeRoute path='/area-view/:id' component={AreaDetail} />
                      <AuthorizeRoute path='/subareas' component={SubAreasList} />
                        
                      <AuthorizeRoute path='/subarea-create' component={SubAreaCreate} />
                      <AuthorizeRoute path='/subarea-detail/:id' component={SubAreaDetail} />  
                      <AuthorizeRoute path='/subarea-view/:id' component={SubAreaDetail} />
                      <AuthorizeRoute path='/businessareas' component={BusinessAreasList}  />
                      <AuthorizeRoute path='/businessarea-create' component={BusinessAreaCreate}  />
                      <AuthorizeRoute path='/businessarea-detail/:id' component={BusinessAreaDetail} />
                      <AuthorizeRoute path='/businessarea-view/:id' component={BusinessAreaDetail}  />
                      <AuthorizeRoute path='/countries' component={CountriesList} />
                      <AuthorizeRoute path='/country-create' component={CountryCreate} />
                      <AuthorizeRoute path='/country-detail/:id' component={CountryDetail} />
                      <AuthorizeRoute path='/country-view/:id' component={CountryDetail} />
                      <AuthorizeRoute path='/permissions' component={PermissionsList} />
                      <AuthorizeRoute path='/permission-create' component={PermissionCreate} />
                      <AuthorizeRoute path='/permission-detail/:id' component={PermissionDetail} />
                      <AuthorizeRoute path='/permission-view/:id' component={PermissionDetail} />
                      <AuthorizeRoute path='/permissiongroups' component={PermissionGroupsList} />
                      <AuthorizeRoute path='/permissiongroup-create' component={PermissionGroupCreate} />
                      <AuthorizeRoute path='/permissiongroup-detail/:id' component={PermissionGroupDetail} />
                      <AuthorizeRoute path='/permissiongroup-view/:id' component={PermissionGroupDetail} />
                      <AuthorizeRoute path='/authtokens' component={AuthorizationTokensList} />
                      <AuthorizeRoute path='/authtoken-create' component={AuthorizationTokenCreate} />
                      <AuthorizeRoute path='/authtoken-detail/:id' component={AuthorizationTokenDetail} />
                      <AuthorizeRoute path='/authtoken-view/:id' component={AuthorizationTokenDetail} />
                      <AuthorizeRoute path='/users' component={UsersList} />
                      <AuthorizeRoute path='/user-create' component={UserCreate} />
                      <AuthorizeRoute path='/user-detail/:id' component={UserDetail} />
                      <AuthorizeRoute path='/user-view/:id' component={UserDetail} />
                      <AuthorizeRoute path='/user-changepassword/:id' component={ChangePassword} />
                      <AuthorizeRoute path='/langresources' component={LanguageResourcesList} />
                      <AuthorizeRoute path='/langresource-detail/:id' component={LanguageResourceDetail} />
                      <AuthorizeRoute path='/langresource-view/:id' component={LanguageResourceDetail} />
                      <AuthorizeRoute path='/configurations' component={ConfigurationsList} />
                      <AuthorizeRoute path='/configuration-create' component={ConfigurationCreate} />
                      <AuthorizeRoute path='/configuration-detail/:id' component={ConfigurationDetail} />
                      <AuthorizeRoute path='/configuration-view/:id' component={ConfigurationDetail} />
                      <AuthorizeRoute path='/companies' component={CompaniesList} />
                         {/* { this.state.permissionsIds.includes(29) ?
                      
                          : <Redirect to="/" />} */}
                      <AuthorizeRoute path='/company-create' component={CompanyCreate} />
                      <AuthorizeRoute path='/company-detail/:id' component={CompanyDetail} />
                      <AuthorizeRoute path='/company-view/:id' component={CompanyDetail} />
                        { this.state.permissionsIds.includes(32) ?
                        <AuthorizeRoute path='/company' component={CompanyCreate} />
                         : <Redirect to="/" />}    
                      <AuthorizeRoute path='/brands' component={BrandsList} />
                      <AuthorizeRoute path='/brand-create' component={BrandCreate} />
                      <AuthorizeRoute path='/brand-detail/:id' component={BrandDetail} />
                      <AuthorizeRoute path='/brand-view/:id' component={BrandDetail} />
                      <AuthorizeRoute path='/suppliers' component={SuppliersList} />
                      <AuthorizeRoute path='/supplier-create' component={SupplierCreate} />
                      <AuthorizeRoute path='/supplier-detail/:id' component={SupplierDetail} />
                      <AuthorizeRoute path='/supplier-view/:id' component={SupplierDetail} />
                      <AuthorizeRoute path='/apiclients' component={ApiClientsList} />
                      <AuthorizeRoute path='/apiclients-create' component={ApiClientsCreate}  />
                      <AuthorizeRoute path='/apiclients-edit/:id' component={ApiClientsEdit} />
                      <AuthorizeRoute path='/apiclients-view/:id' component={ApiClientsEdit}  />
                      <AuthorizeRoute path='/treatments' component={TreatmentList}  />
                      <AuthorizeRoute path='/treatment-create' component={TreatmentCreate}  />
                      <AuthorizeRoute path='/treatment-detail/:id' component={TreatmentDetail} />
                      <AuthorizeRoute path='/treatment-view/:id' component={TreatmentDetail} />
                      <AuthorizeRoute path='/treatmentsQuestion' component={TreatmentQuestionList}  />
                      <AuthorizeRoute path='/treatmentQuestion-create' component={TreatmentQuestionCreate}  />
                      <AuthorizeRoute path='/treatmentQuestion-detail/:id' component={TreatmentQuestionDetail} />
                      <AuthorizeRoute path='/treatmentQuestion-view/:id' component={TreatmentQuestionDetail} />
                      <AuthorizeRoute path='/couriers' component={CourierList} />
                      <AuthorizeRoute path='/courier-detail/:id' component={CourierDetail} />
                      <AuthorizeRoute path='/courier-view/:id' component={CourierDetail} />
                      <AuthorizeRoute path='/courier-create' component={CourierCreate} />
                      <AuthorizeRoute path='/sellers' component={SellersList} />
                      <AuthorizeRoute path='/sellers-detail/:id' component={SellersDetail} />
                      <AuthorizeRoute path='/sellers-view/:id' component={SellersDetail} />
                      <AuthorizeRoute path='/sellers-create' component={SellersCreate} />
                      <AuthorizeRoute path='/objetions' component={ObjetionsList} />
                      <AuthorizeRoute path='/objetions-detail/:id' component={ObjetionsDetail} />
                      <AuthorizeRoute path='/objetions-view/:id' component={ObjetionsDetail} />
                      <AuthorizeRoute path='/objetions-create' component={ObjetionsCreate} />
                      <AuthorizeRoute path='/logs' component={Logs} />
                      <AuthorizeRoute path='/saleshistory' component={SalesHistoryList} />
                      <AuthorizeRoute path='/mysales' component={MySalesList} />
                      <AuthorizeRoute path='/mysalespending' component={MySalesPending} />
                      <AuthorizeRoute path='/saleresume' component={SaleResume} />
                      <AuthorizeRoute path='/salesview' component={SalesView} />
                      <AuthorizeRoute path='/scriptsales' component={ScriptSalesList} />
                      <AuthorizeRoute path='/scriptsales-detail/:id' component={ScriptSalesDetail} />
                      <AuthorizeRoute path='/scriptsales-view/:id' component={ScriptSalesDetail} />
                      <AuthorizeRoute path='/scriptsales-create' component={ScriptSalesCreate} />
                      <AuthorizeRoute path='/products' component={ProductsList} />
                      <AuthorizeRoute path='/products-create' component={ProductsCreate} />
                      <AuthorizeRoute path='/products-detail/:id' component={ProductsDetail} />
                      <AuthorizeRoute path='/products-view/:id' component={ProductsDetail} />
                      <AuthorizeRoute path='/products-info/:id' component={ProductInfo} />
                      <AuthorizeRoute path='/product-country' component={ProductCountryList} />
                      <AuthorizeRoute path='/product-key-list' component={ProductKeyList} />
                      <AuthorizeRoute path='/product-key-suggest' component={ProductKeySuggest} />
                      <AuthorizeRoute path='/product-country-create' component={ProductCountryCreate} />
                      <AuthorizeRoute path='/product-country-detail/:id' component={ProductCountryDetail} />
                      <AuthorizeRoute path='/product-country-view/:id' component={ProductCountryDetail} />
                      <AuthorizeRoute path='/formats' component={FormatList}  />
                      <AuthorizeRoute path='/format-create' component={FormatCreate}  />
                      <AuthorizeRoute path='/format-detail/:id' component={FormatDetail} />
                      <AuthorizeRoute path='/format-view/:id' component={FormatDetail} />
                      <AuthorizeRoute path='/mediatypes' component={MediaTypeList}  />
                      <AuthorizeRoute path='/mediatype-create' component={MediaTypeCreate}  />
                      <AuthorizeRoute path='/mediatype-detail/:id' component={MediaTypeDetail} />
                      <AuthorizeRoute path='/mediatype-view/:id' component={MediaTypeDetail} />
                      <AuthorizeRoute path='/mediagroups' component={MediaGroupList}  />
                      <AuthorizeRoute path='/mediagroup-create' component={MediaGroupCreate}  />
                      <AuthorizeRoute path='/mediagroup-detail/:id' component={MediaGroupDetail} />
                      <AuthorizeRoute path='/mediagroup-view/:id' component={MediaGroupDetail} />
                      <AuthorizeRoute path='/product-countrykey' component={ProductCountryKeyList} />
                      <AuthorizeRoute path='/claves-sku' component={ClavesSku} />
                      <AuthorizeRoute path='/update-shippingcost' component={ProductCountryUpdateCost} />
                      <AuthorizeRoute path='/product-countrykey-all' component={ProductCountryKeyAll} />
                      <AuthorizeRoute path='/product-countrykey-create' component={ProductCountryKeyCreate} />
                      <AuthorizeRoute path='/product-countrykey-detail/:id' component={ProductCountryKeyDetail} />
                      <AuthorizeRoute path='/product-countrykey-view/:id' component={ProductCountryKeyDetail} />
                      <AuthorizeRoute path='/add-country-product' component={CountryProduct} />
                      <AuthorizeRoute path='/agency-create' component={AgencyCreate} />
                      <AuthorizeRoute path='/agency-edit/:id' component={AgencyEdit} />
                      <AuthorizeRoute path='/agency-view/:id' component={AgencyEdit} />
                      <AuthorizeRoute path='/agency' component={AgencyList} />
                      <AuthorizeRoute path='/media-create' component={MediaCreate} />
                      <AuthorizeRoute path='/media-edit/:id' component={MediaEdit} />
                      <AuthorizeRoute path='/media-view/:id' component={MediaEdit} />
                      <AuthorizeRoute path='/media' component={MediaList} />
                      <AuthorizeRoute path='/pbxs' component={PbxList}  />
                      <AuthorizeRoute path='/pbx-create' component={PbxCreate}  />
                      <AuthorizeRoute path='/pbx-edit/:id' component={PbxEdit} />
                      <AuthorizeRoute path='/pbx-view/:id' component={PbxEdit} />
                      <AuthorizeRoute path='/publicity' component={PublicityList}  />
                      <AuthorizeRoute path='/publicity-create' component={PublicityCreate}  />
                      <AuthorizeRoute path='/publicity-detail/:id' component={PublicityDetail} />
                      <AuthorizeRoute path='/publicity-view/:id' component={PublicityDetail} />
                      <AuthorizeRoute path='/business' component={BusinessList}  />
                      <AuthorizeRoute path='/business-create' component={BusinessCreate}  />
                      <AuthorizeRoute path='/business-edit/:id' component={BusinessEdit} />
                      <AuthorizeRoute path='/business-view/:id' component={BusinessEdit} />
                      <AuthorizeRoute path='/pbxdatabase' component={PbxDatabaseList} />
                      <AuthorizeRoute path='/pbxdatabase-create' component={PbxDatabaseCreate} />
                      <AuthorizeRoute path='/pbxdatabase-detail/:id' component={PbxDatabaseDetail} />
                      <AuthorizeRoute path='/pbxdatabase-view/:id' component={PbxDatabaseDetail} />
                      <AuthorizeRoute path='/campaing-type' component={CampaingTypeList} />
                      <AuthorizeRoute path='/campaing-type-create' component={CampaingTypeCreate} />
                      <AuthorizeRoute path='/campaing-type-detail/:id' component={CampaingTypeDetail} />
                      <AuthorizeRoute path='/campaing-type-view/:id' component={CampaingTypeDetail} />
                      <AuthorizeRoute path='/carrier' component={CarrierList} />
                      <AuthorizeRoute path='/carrier-create' component={CarrierCreate} />
                      <AuthorizeRoute path='/carrier-detail/:id' component={CarrierDetail} />
                      <AuthorizeRoute path='/carrier-view/:id' component={CarrierDetail} />
                      <AuthorizeRoute path='/did' component={DidList} />
                      <AuthorizeRoute path='/did-create' component={DidCreate} />
                      <AuthorizeRoute path='/did-detail/:id' component={DidDetail} />
                      <AuthorizeRoute path='/did-view/:id' component={DidDetail} />
                      <AuthorizeRoute path='/state' component={StateList} />
                      <AuthorizeRoute path='/state-create' component={StateCreate} />
                      <AuthorizeRoute path='/state-detail/:id' component={StateDetail} />
                      <AuthorizeRoute path='/state-view/:id' component={StateDetail} />
                      <AuthorizeRoute path='/city' component={CityList} />
                      <AuthorizeRoute path='/city-create' component={CityCreate} />
                      <AuthorizeRoute path='/city-detail/:id' component={CityDetail} />
                      <AuthorizeRoute path='/city-view/:id' component={CityDetail} />
                      <AuthorizeRoute path='/campaing' component={CampaingList} />
                      <AuthorizeRoute path='/campaing-create' component={CampaingCreate} />
                      <AuthorizeRoute path='/campaing-detail/:id' component={CampaingDetail} />
                      <AuthorizeRoute path='/campaing-view/:id' component={CampaingDetail} />
                      <AuthorizeRoute path='/product-category' component={ProductCategoryList} />
                      <AuthorizeRoute path='/product-category-create' component={ProductCategoryCreate} />
                      <AuthorizeRoute path='/product-category-detail/:id' component={ProductCategoryDetail} />
                      <AuthorizeRoute path='/product-category-view/:id' component={ProductCategoryDetail} />
                      <AuthorizeRoute path='/map-courier-city' component={MapCourierCityList} />
                      <AuthorizeRoute path='/map-courier-city-export' component={MapCourierExportRoutes} />
                      <AuthorizeRoute path='/map-courier-city-import' component={MapCourierImportRoutes} />
                      <AuthorizeRoute path='/map-courier-city-create' component={MapCourierCityCreate} />
                      <AuthorizeRoute path='/map-courier-city-detail/:id' component={MapCourierCityDetail} />
                      <AuthorizeRoute path='/map-courier-city-view/:id' component={MapCourierCityDetail} />
                      <AuthorizeRoute path='/log-map-courier-city-list' component={LogMapCourierCityList}  />
                      <AuthorizeRoute path='/domain' component={DomainList} />
                      <AuthorizeRoute path='/domain-create' component={DomainCreate} />
                      <AuthorizeRoute path='/domain-edit/:id' component={DomainEdit} />
                      <AuthorizeRoute path='/domain-view/:id' component={DomainEdit} />
                      <AuthorizeRoute path='/validate-cities' component={ValidateCities} />
                      <AuthorizeRoute path='/waypays' component={WayPaysList} />
                      <AuthorizeRoute path='/waypays-create' component={WayPaysCreate} />
                      <AuthorizeRoute path='/waypays-detail/:id' component={WayPaysDetail} />
                      <AuthorizeRoute path='/waypays-view/:id' component={WayPaysDetail} />
                      <AuthorizeRoute path='/closing-reasons' component={ClosingReasonList} />
                      <AuthorizeRoute path='/closing-reasons-create' component={ClosingReasonCreate} />
                      <AuthorizeRoute path='/closing-reasons-detail/:id' component={ClosingReasonDetail} />
                      <AuthorizeRoute path='/closing-reasons-view/:id' component={ClosingReasonDetail} />
                      <AuthorizeRoute path='/validate-cities' component={ValidateCities} /> 
                      <AuthorizeRoute path='/clients' component={ClientsList} />
                      <AuthorizeRoute path='/clients-create' component={ClientsCreate} />
                      <AuthorizeRoute path='/clients-detail/:id' component={ClientsDetail} />
                      <AuthorizeRoute path='/clients-view/:id' component={ClientsDetail} />
                      <AuthorizeRoute path='/info-product' component={InfoProductList} />
                      <AuthorizeRoute path='/info-product-create' component={InfoProductCreate} />
                      <AuthorizeRoute path='/info-product-detail/:id' component={InfoProductDetail} />
                      <AuthorizeRoute path='/info-product-view/:id' component={InfoProductDetail} />
                      <AuthorizeRoute path='/country-waypay' component={CountryWayPayList} />
                      <AuthorizeRoute path='/country-waypay-create' component={CountryWayPayCreate} />
                      <AuthorizeRoute path='/country-waypay-detail/:id' component={CountryWayPayDetail} />
                      <AuthorizeRoute path='/country-waypay-view/:id' component={CountryWayPayDetail} />
                      <AuthorizeRoute path='/bank' component={BankList} />
                      <AuthorizeRoute path='/bank-create' component={BankCreate} />
                      <AuthorizeRoute path='/bank-detail/:id' component={BankDetail} />
                      <AuthorizeRoute path='/bank-view/:id' component={BankDetail} />
                      <AuthorizeRoute path='/product-payment' component={ProductPaymentList} />
                      <AuthorizeRoute path='/product-payment-create' component={ProductPaymentCreate} />
                      <AuthorizeRoute path='/product-payment-detail/:id' component={ProductPaymentDetail} />
                      <AuthorizeRoute path='/product-payment-view/:id' component={ProductPaymentDetail} />
                      <AuthorizeRoute path='/product-payment-waypay' component={ProductPaymentWayPayList} />
                      <AuthorizeRoute path='/product-payment-waypay-create' component={ProductPaymentWayPayCreate} />
                      <AuthorizeRoute path='/product-payment-waypay-detail/:id' component={ProductPaymentWayPayDetail} />
                      <AuthorizeRoute path='/product-payment-waypay-view/:id' component={ProductPaymentWayPayDetail} />
                      <AuthorizeRoute path='/kit-product' component={KitProductList} />
                      <AuthorizeRoute path='/kit-product-create' component={KitProductCreate} />
                      <AuthorizeRoute path='/kit-product-detail/:id' component={KitProductDetail} />
                      <AuthorizeRoute path='/kit-product-view/:id' component={KitProductDetail} />
                  </Layout> </>}
            </div>
        );
    }

}
