import React, { Component } from 'react';
import axios from 'axios';
import $ from 'jquery';
import helpers from '../../Helpers';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import startsWith from 'lodash.startswith';
import RutInput from '../RutInput/RutInput';

export class ClientsCreate extends Component {
    static displayName = ClientsCreate.name;

    constructor(props) {
        super(props);

        this.onChangeName = this.onChangeName.bind(this);
        this.onChangeLastName = this.onChangeLastName.bind(this);
        this.onChangeDocType = this.onChangeDocType.bind(this);
        this.onChangeDocNumber = this.onChangeDocNumber.bind(this);
        this.onChangeSex = this.onChangeSex.bind(this);
        this.onChangeDateOfBirth = this.onChangeDateOfBirth.bind(this);
        this.onChangePhone = this.onChangePhone.bind(this);
        this.onChangeWorkPhone = this.onChangeWorkPhone.bind(this);
        this.onChangeEmail = this.onChangeEmail.bind(this);      
        this.onChangeHaventEmail = this.onChangeHaventEmail.bind(this);
        this.onChangeAddress = this.onChangeAddress.bind(this);
        this.onChangeNumber = this.onChangeNumber.bind(this);
        this.onChangeFloor = this.onChangeFloor.bind(this);
        this.onChangeDepartment = this.onChangeDepartment.bind(this);
        this.onChangeBetweenStreets = this.onChangeBetweenStreets.bind(this);
        this.onChangePostalCode = this.onChangePostalCode.bind(this);
        this.onChangeCountry = this.onChangeCountry.bind(this);
        this.onChangeState = this.onChangeState.bind(this);
        this.onChangeCity = this.onChangeCity.bind(this);
        this.onChangeEnabled = this.onChangeEnabled.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onSubmit = this.onSubmit.bind(this);  

        this.state = {
            id: 0,
            name: '',
            lastName: '',
            countries: [],
            countryId: null,
            document_types: [],
            documentTypeId: null,
            documentNumber: '',
            sex: '',
            dateOfBirth: '',
            workphone: '',
            phone: '',
            email: '',
            haventMail: false,
            address: '',
            number: null,
            floor: null,
            department: null,
            betweenStreets: ' ',
            postalCode: ' ',
            states: [],
            stateId: null,
            cities: [],
            cityId: 0,
            enabled: true,
            loading: true,
            resources: [],
            userId: 0,
            invalidRutMsg: false,
            clients: [],
            disabledForm: true,
            countrySelected: null
        };
    }

    componentDidMount() {
        this.getResources();
        this.populateData();
        helpers.setNavActive("#nav-did");
        helpers.setClientValidation();

        let currentUser = helpers.getUserData();
        this.setState({
            userId: currentUser.id
        });
        document.querySelector("#mailError").style.display = 'none';
        document.querySelector('#phoneError').style.display = 'none';
        document.querySelector('#workphoneError').style.display = 'none';
        document.querySelector("#inputRut").style.display = 'none';

    }

    async onSubmit(e) {
        e.preventDefault();

        var form = document.querySelectorAll('.needs-validation')[0];

        if (!form.checkValidity())
            return;
        
        if (this.state.invalidRutMsg)
            return;

        this.setState({ loading: true });

        const data = {
            name: this.state.name,
            lastName: this.state.lastName,
            countriesid: this.state.countryId,
            document_typeid: this.state.documentTypeId,
            document_number: this.state.documentNumber,
            sex: this.state.sex,
            date_birth: this.state.dateOfBirth,
            work_phone: this.state.workphone,
            personal_phone: this.state.phone,
            email: this.state.email,
            //email_no: this.state.haventMail,
            direction: this.state.address,
            number: this.state.number,
            flat: this.state.floor,
            depto: this.state.department,
            between_street: this.state.betweenStreets,
            postal_code: this.state.postalCode,
            stateid: this.state.stateId,
            cityid: this.state.cityId,
            enabled: this.state.enabled,
            creation_user_id: this.state.userId,
        };
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME)
        };
 
        let emailRegex = /^(([^<>()\[\]\\.,;:\s@”]+(\.[^<>()\[\]\\.,;:\s@”]+)*)|(“.+”))@((\[[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}])|(([a-zA-Z\-0–9]+\.)+[a-zA-Z]{2,}))$/i;
        if (this.state.name && this.state.name.charAt(0) && this.state.lastName && this.state.lastName.charAt(0) && this.state.documentTypeId > 0 && this.state.documentNumber &&
            this.state.documentNumber.charAt(0) && this.state.phone > 0 && this.state.address.charAt(0) && this.state.number > 0 && this.state.countryId > 0 && this.state.stateId > 0 && this.state.cityId > 0
            ) {
            if (!this.state.email.charAt(0)) {

            }
            else if (this.state.email.charAt(0)) {
                if(emailRegex.test(this.state.email)) {
                }
            }
            else {
                document.querySelector("#mailError").style.display = 'block';

            }  
        }  else {
            document.querySelector("#mailError").style.display = 'block';

        } 
          

        let errorCount = 0;
        this.state.clients.map(client => {
            if(errorCount === 0){
                if (client.document_number == data.document_number && client.document_typeid == data.document_typeid) {
                    let msg = this.state.resources.Label_ErrorClientDuplicated;
                    helpers.notifyError(this.state.resources.Label_Error, msg);
                    errorCount++;
                    this.setState({ loading: false });
                    return;
                } 
            }
        });

        if(document.querySelector('#phoneError').style.display == 'block' ||
            document.querySelector('#workphoneError').style.display == 'block') {
            errorCount++;
        }

        if (this.state.email.charAt(0)) {
            if (emailRegex.test(this.state.email)) {
                if (errorCount == 0) {
                    axios.post(window.config.REACT_APP_PRODUCTS_API_URL + '/client/save', data, {
                        headers: headers
                    })
                    .then((response) => {
                        helpers.notifySuccess(this.state.resources.Label_Success, this.state.resources.SuccessMessage_DataSavedSuccessfully);
                        this.props.history.push('/clients');
                    })
                    .catch((error) => {
                        console.log(error);

                        let msg = this.state.resources.ErrorMessage_UnknownError;

                        helpers.notifyError(this.state.resources.Label_Error, msg);
                        this.setState({ loading: false });
                    });
                }else{
                    let msg = helpers.getUiLanguage() == 'language0' ? 'Phone Data Invalid' : 'Telefono No Valido';

                    helpers.notifyError(this.state.resources.Label_Error, msg);
                    this.setState({ loading: false });
                }
            } else {
                let msg = this.state.resources.ErrorMessage_InvalidEmail;

                helpers.notifyError(this.state.resources.Label_Error, msg);
                this.setState({ loading: false });
            }
        } else {
            if (errorCount == 0) {
                axios.post(window.config.REACT_APP_PRODUCTS_API_URL + '/client/save', data, {
                    headers: headers
                })
                .then((response) => {
                    helpers.notifySuccess(this.state.resources.Label_Success, this.state.resources.SuccessMessage_DataSavedSuccessfully);
                    this.props.history.push('/clients');
                })
                .catch((error) => {
                    console.log(error);

                    let msg = this.state.resources.ErrorMessage_UnknownError;

                    helpers.notifyError(this.state.resources.Label_Error, msg);
                    this.setState({ loading: false });
                });
            } else {
                let msg = helpers.getUiLanguage() == 'language0' ? 'Phone Data Invalid' : 'Telefono No Valido';

                    helpers.notifyError(this.state.resources.Label_Error, msg);
                    this.setState({ loading: false });
            }
        }

        
        
    }  
 
    onCancel(e) {
        e.preventDefault();
        this.props.history.push("/clients");
    }

    onChangeName(e) {
        this.setState({
            name: e.target.value
        });
    }

    onChangeLastName(e) {
        this.setState({
            lastName: e.target.value
        });
    }

    onChangeDocType(e) {
        this.setState({
            documentTypeId: e.target.value
        });
        if (e.target.value != 1) {
            this.state.invalidRutMsg = false;
            document.querySelector("#inputRut").style.display = 'none';
            document.querySelector("#inputDocNumber").style.display = 'block';
        } else {
            document.querySelector("#inputRut").style.display = 'block';
            document.querySelector("#inputDocNumber").style.display = 'none';
            var Fn = {
                // Valida el rut con su cadena completa "XXXXXXXX-X"
                validaRut : function (rutCompleto) {
                    if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test( rutCompleto ))
                        return false;
                    var tmp 	= rutCompleto.split('-');
                    var digv	= tmp[1]; 
                    var rut 	= tmp[0];
                    if ( digv == 'K' ) digv = 'k' ;
                    return (Fn.dv(rut) == digv );
                },
                dv : function(T){
                    var M=0,S=1;
                    for(;T;T=Math.floor(T/10))
                        S=(S+T%10*(9-M++%6))%11;
                    return S?S-1:'k';
                }
            }
            if (Fn.validaRut(this.state.documentNumber)) {
                this.state.invalidRutMsg = false;
            } else {
                this.state.invalidRutMsg = true;
            }
        }
    }

    onChangeDocNumber(e) {
        this.setState({
            documentNumber: e.target.value
        });
        if (this.state.documentTypeId == 1) {
            var Fn = {
                // Valida el rut con su cadena completa "XXXXXXXX-X"
                validaRut : function (rutCompleto) {
                    if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test( rutCompleto ))
                        return false;
                    var tmp 	= rutCompleto.split('-');
                    var digv	= tmp[1]; 
                    var rut 	= tmp[0];
                    if ( digv == 'K' ) digv = 'k' ;
                    return (Fn.dv(rut) == digv );
                },
                dv : function(T){
                    var M=0,S=1;
                    for(;T;T=Math.floor(T/10))
                        S=(S+T%10*(9-M++%6))%11;
                    return S?S-1:'k';
                }
            }
            if (Fn.validaRut(e.target.value)) {
                this.state.invalidRutMsg = false;
            } else {
                this.state.invalidRutMsg = true;
            }
        } else {
            this.state.invalidRutMsg = false;
        }
    }

    onChangeSex(e) {
        this.setState({
            sex: e.target.value
        });
    }

    onChangeDateOfBirth(e) {
        this.setState({
            dateOfBirth: e.target.value
        });
    }

    onChangePhone(e) {
        this.setState({
            phone: e
        });
        // console.log(e);
    }

    onChangeWorkPhone(e) {
        this.setState({
            workphone: e
        });
        // console.log(e);
    }

    onChangeEmail(e) {
        this.setState({
            email: e.target.value
        })
        let mailError = document.querySelector("#mailError");
        if (e.target.value.split("").length < 1) {
            mailError.style.display = 'none';
        }

        // console.log(e.target.value)
        let emailRegex = /^(([^<>()\[\]\\.,;:\s@”]+(\.[^<>()\[\]\\.,;:\s@”]+)*)|(“.+”))@((\[[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}])|(([a-zA-Z\-0–9]+\.)+[a-zA-Z]{2,}))$/i;
        if (!e.target.value.includes('@') || !e.target.value.includes('.')  || emailRegex.test(e.target.value) == false) {
            if(mailError.style.display == 'none') {
                mailError.style.display = 'block';
            }
        } else {
            if(mailError.style.display == 'block') {
                mailError.style.display = 'none';
            }
        }
        if (e.target.value.split("").length < 1) {
            mailError.style.display = 'none';
        }
    }

    onChangeHaventEmail(e) {
        this.setState({
            haventMail: e.target.checked
        })
    }

    onChangeAddress(e){
        this.setState({
            address: e.target.value
        });
    }
    onChangeNumber(e){
        this.setState({
            number: e.target.value
        });
    }
    onChangeFloor(e){
        this.setState({
            floor: e.target.value
        });
    }
    onChangeDepartment(e){
        this.setState({
            department: e.target.value
        });
    }

    onChangeBetweenStreets(e) {
        this.setState({
            betweenStreets: e.target.value
        })
    }

    onChangePostalCode(e) {
        /*{this.state.cities.map(city => )
            if(city.id == e.target.value){
                this.setState({
                    city_cp
                })
            }
        }
        this.setState({
            postalCode: e.target.value
        });*/
    
    }
    
    onChangeCountry(e) {
        this.setState({
            countryId: e.target.value
        });
        this.state.countries.map(country => {
            if (country.id == e.target.value) {
                this.setState({
                    countrySelected: country
                })
            }
        });
    }

    onChangeCity(e) {
        {this.state.cities.map(city =>{
            if(this.state.stateId == city.state_id && city.id == e.target.value)
            {  this.setState({ 
                postalCode: city.cp
                });
            }
        })
             
        }
        this.setState({
            cityId: e.target.value
        });
    }

    onChangeState(e) {
        this.setState({
            stateId: e.target.value
        });
    }

    onChangeEnabled(e) {
        this.setState({
            enabled: e.target.checked
        });
    } 

    render() {
        let contents =
            <form className="form row g-3 needs-validation" onSubmit={this.onSubmit} noValidate>
                <div className="form-group col-md-12">
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" id="enabled" defaultChecked={this.state.enabled} onChange={this.onChangeEnabled} />
                        <label className="form-check-label" htmlFor="enabled">{this.state.resources.Label_Enabled}</label>
                        <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                    </div>
                </div>

                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_Name}</label>
                    <input className="form-control" type="text" name="name" onChange={this.onChangeName} value={this.state.name} required />
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                </div>
                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_LastName}</label>
                    <input className="form-control" type="text" name="lastName" onChange={this.onChangeLastName} value={this.state.lastName} required />
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                </div>

                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_DocumentType}</label>
                    <select className="form-control" data-val="true" name="documentTypeId" defaultValue={this.state.documentTypeId} onChange={this.onChangeDocType} required>
                        <option value="">{this.state.resources.Label_SelectOption}</option>
                        {this.state.document_types.map(doc =>
                            <option key={doc.id} value={doc.id}>{doc.type}</option>
                        )}
                    </select>
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                </div>

                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_DocNumber}</label>
                    <input id="inputDocNumber"  className="form-control" type="text" name="name" onKeyDown={(evt) => ["e", "E", "+"].includes(evt.key) && evt.preventDefault()}
                        onChange={this.onChangeDocNumber} value={this.state.documentNumber} required />
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>

                    <div id="inputRut"><RutInput documentNumber={this.state.documentNumber} changeDocNumber={this.onChangeDocNumber} /> </div>
                    
                    {this.state.invalidRutMsg ? <p style={{color: 'red'}}>
                            {localStorage.getItem('uilanguage') == 'language0' ? 'invalid RUT number' : 'RUT no valido, por favor ingresar nuevamente'}</p> 
                        : <></>}
                </div>

                <div className="form-group col-md-6">
                    <label>{this.state.resources.Label_Sex}</label>
                    <select className="form-control mr-1" data-val="true" name="sex" defaultValue={this.state.sex} onChange={this.onChangeSex}>
                        <option value="">{this.state.resources.Label_SelectOption}</option>
                        <option value="M" selected={this.state.sex == 'M'}>{this.state.resources.Label_Male}</option>
                        <option value="F" selected={this.state.sex == 'F'}>{this.state.resources.Label_Female}</option>
                    </select>
                </div>
                <div className="form-group col-md-6">
                    <label>{this.state.resources.Label_DateOfBirth}</label>
                    <input type="date"  className="form-control col-md-12" name="dateOfBirth" defaultValue={this.state.dateOfBirth} onChange={this.onChangeDateOfBirth}/>
                </div>

                <div className="form-group col-md-6">
                        <label>{this.state.resources.Label_Phone}</label>
                    <PhoneInput
                        country={'cl'}
                        value={this.state.phone}
                        onChange={this.onChangePhone}
                        masks={{ cl: '(.) ....-....' }}
                        inputStyle={{ width: '100%' }}
                        onlyCountries={['cl', 'ar', 'mx', 'uk']}
                        isValid={(value, country) => { 
                            if (startsWith(value, country.dialCode) || startsWith(country.dialCode, value)) {
                                if (document.querySelector('#phoneError')) {
                                    document.querySelector('#phoneError').style.display = 'none';
                                }
                                return true;
                            } else {
                                if (document.querySelector('#phoneError')) {
                                    document.querySelector('#phoneError').style.display = 'block';
                                }
                                return false;
                            }
                        }}
                    />
                    <div style={{color: 'red'}} class="p-1 pl-2" id="phoneError">{this.state.resources.ErrorMessage_InvalidPhoneFormat}</div>    
                    {/* <input className="form-control mx-1" type="number" name="phone" defaultValue={this.state.phone}
                            onKeyDown={(evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()} onChange={this.onChangePhone}/> */}
                </div>
                
                <div className="form-group col-md-6">
                    <label>{this.state.resources.Label_WorksPhone}</label>
                    <PhoneInput
                        country={'cl'}
                        value={this.state.workphone}
                        onChange={this.onChangeWorkPhone}
                        masks={{ cl: '(.) ....-....' }}
                        inputStyle={{ width: '100%' }}
                        onlyCountries={['cl', 'ar', 'mx', 'uk']}
                        isValid={(value, country) => { 
                            // console.log(value);
                            // console.log(country);
                            if (startsWith(value, country.dialCode) || startsWith(country.dialCode, value)) {
                                if (document.querySelector('#workphoneError')) {
                                    document.querySelector('#workphoneError').style.display = 'none';
                                }
                                return true;
                            } else {
                                if (document.querySelector('#workphoneError')) {
                                    document.querySelector('#workphoneError').style.display = 'block';
                                }
                                return false;
                            }
                        }}
                    />
                    <div style={{color: 'red'}} class="p-1 pl-2" id="workphoneError">{this.state.resources.ErrorMessage_InvalidPhoneFormat}</div>
                    {/* <PhoneInput classes="form-control mx-1" phoneNumber={this.state.workphone} country={this.state.countrySelected} resources={this.state.resources} changeInput={this.onChangeWorkPhone} required="true" /> */}
                    {/* <input className="form-control mx-1" type="number" name="workphone" defaultValue={this.state.workphone}
                        onKeyDown={(evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()} onChange={this.onChangeWorkPhone}/> */}
                </div>

                <div className="col-md-6">
                    <label>{this.state.resources.Label_Email}</label>
                    <input className="form-control mr-1" type="mail" maxLength="50" name="mail" defaultValue={this.state.email} onChange={this.onChangeEmail} onBlurCapture={this.onChangeEmail}/>
                    <div style={{color: 'red'}} class="p-1 pl-2" id="mailError">{this.state.resources.ErrorMessage_InvalidEmail}</div> 
                </div>

                <div className="form-group col-md-6"> 
                    <label>{this.state.resources.Label_Address}</label>
                    <input className="form-control" type="text" maxLength="100" name="address" defaultValue={this.state.address} onChange={this.onChangeAddress} required/>
                </div>
                <div className="form-group col-md-6">
                    <label>{this.state.resources.Label_Number}</label>
                    <input className="form-control mx-1" type="number" name="number" defaultValue={this.state.number} value={this.state.number}
                        onKeyDown={(evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()} onChange={this.onChangeNumber} required/>
                </div>
                <div className="form-group col-md-3">
                    <label>{this.state.resources.Label_Floor}</label>
                    <input className="form-control mx-1" type="number" name="floorNumber" defaultValue={this.state.floor}
                        onKeyDown={(evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()} onChange={this.onChangeFloor}/>
                </div>
                <div className="form-group col-md-3">
                    <label>{this.state.resources.Label_Department}</label>
                    <input className="form-control mx-1" type="text" name="departmentNumber" defaultValue={this.state.department}
                        onKeyDown={(evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()} onChange={this.onChangeDepartment}/>
                </div>
                <div className="form-group col-md-6">
                    <label>{this.state.resources.Label_BetweenStreets}</label>
                    <input className="form-control" type="text" maxLength="100" name="betweenStreets" defaultValue={this.state.betweenStreets} onChange={this.onChangeBetweenStreets}/>
                </div>

                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_Country}</label>
                    <select className="form-control" data-val="true" name="countryid" defaultValue={this.state.countryId} onChange={this.onChangeCountry} required>
                        <option value="">{this.state.resources.Label_SelectOption}</option>
                        {this.state.countries.map(country =>
                            <option key={country.id} value={country.id}>{country.name}</option>
                        )}
                    </select>
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                </div>

                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_StateOrProvince}</label>
                    <select className="form-control" data-val="true" name="stateid" defaultValue={this.state.stateId} onChange={this.onChangeState} required>
                        <option value="">{this.state.resources.Label_SelectOption}</option>
                        {this.state.states.map(state =>
                            this.state.countryId == state.country_id &&
                            <option key={state.id} value={state.id}>{state.name}</option>
                        )}
                    </select>
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                </div>

                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Title_City}</label>
                    <select className="form-control" data-val="true" name="cityId" defaultValue={this.state.cityId} onChange={this.onChangeCity} required>
                        <option value="">{this.state.resources.Label_SelectOption}</option>
                        {this.state.cities.map(city =>
                            this.state.stateId == city.state_id &&
                            <option key={city.id} value={city.id}>{city.name}</option>
                        )}
                    </select>
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                </div>

                <div className="form-group col-md-6">
                        <label>{this.state.resources.Label_PostalCode}</label>
                        <input className="form-control" type="text" maxLength="15" name="postalCode" value={this.state.postalCode} disabled={this.state.disabledForm} onChange={this.onChangePostalCode}/>
                    </div>

                {/**Buttons Header */}
                 <div className="buttons-head row">
                    <div className=" buttons-childs">
                        <button type="submit" className="btn btn-primary">{this.state.resources.ButtonLabel_Save}</button>
                        <button className="btn btn-danger" onClick={this.onCancel}>{this.state.resources.ButtonLabel_Cancel}</button>
                    </div>
                 </div>

            </form>;

        return (
            <div className="mt-2">
                <ol className="breadcrumb my-4">
                    <li className="breadcrumb-item active">{this.state.resources.Title_Clients}</li>
                </ol>
                <div className="card">
                    <div className="card-body">
                        {this.state.loading ? helpers.showLoadingOverlay() : helpers.hideLoadingOverlay()}
                        {contents}
                    </div>
                </div>
            </div>
        );
    }

    async getResources() {
        const resourceVars = [];
        resourceVars.push("Title_Clients");
        resourceVars.push("Label_ID");
        resourceVars.push("Label_Name");
        resourceVars.push("Label_Description");
        resourceVars.push("Label_Actions");
        resourceVars.push("Label_Status");
        resourceVars.push("Label_LastName");
        resourceVars.push("Label_PostalCode");
        resourceVars.push("Title_City");
        resourceVars.push("Label_Address");
        resourceVars.push("Label_Country");
        resourceVars.push("Label_StateOrProvince");
        resourceVars.push("Label_DocNumber");
        resourceVars.push("Label_DocumentType");
        resourceVars.push("Label_DateOfBirth");
        resourceVars.push("Label_Phone");
        resourceVars.push("Label_MobilePhone");
        resourceVars.push("Label_WorksPhone");
        resourceVars.push("Label_Sex");
        resourceVars.push("Label_Male");
        resourceVars.push("Label_Female");
        resourceVars.push("Label_Email");
        resourceVars.push("ErrorMessage_InvalidEmail");
        resourceVars.push("Label_NoMail");
        resourceVars.push("Label_Number");
        resourceVars.push("Label_Floor");
        resourceVars.push("Label_Department");
        resourceVars.push("Label_BetweenStreets");
        resourceVars.push("Label_Enabled");
        resourceVars.push("Label_Disabled");
        resourceVars.push("Label_SelectOption");
        resourceVars.push("Label_Enabled");
        resourceVars.push("Label_Success");
        resourceVars.push("ButtonLabel_Save");
        resourceVars.push("ButtonLabel_Cancel");
        resourceVars.push("Label_Error");
        resourceVars.push("ErrorMessage_RequiredField");
        resourceVars.push("ErrorMessage_DuplicatedName");
        resourceVars.push("ErrorMessage_UnknownError");
        resourceVars.push("SuccessMessage_DataSavedSuccessfully");
        resourceVars.push("Label_ErrorClientDuplicated");
        resourceVars.push("ErrorMessage_InvalidPhoneFormat");

        const headers = {
            'Content-Type': 'application/json'
        };

        axios.post(window.config.REACT_APP_SECURITY_API_URL + '/languageresource/getbyvars/' + helpers.getUiLanguage(), resourceVars, {
            headers: headers
        })
            .then((response) => {
                const data = response.data.data;
                this.setState({ resources: data, loading: false });
            })
            .catch((error) => {
                console.log(error);
                this.setState({ loading: false });
            });
        
         axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/client/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ clients: data, loading: false });
                // order alphabetically
                this.state.clients.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                // console.log(this.state.clients);
            })
            .catch(function (error) {
                console.log(error);
            });
        
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/country/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ countries: data, loading: false });
                // order alphabetically
                this.state.countries.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                // console.log(this.state.countries);
            })
            .catch(function (error) {
                console.log(error);
            });
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/documenttype/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
        .then(response => {
                const data = response.data.data;
                this.setState({ document_types: data, loading: false });
                // order alphabetically
                this.state.document_types.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                // console.log(this.state.document_types);
            })
            .catch(function (error) {
                console.log(error);
            });
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/documenttype/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
        .then(response => {
                const data = response.data.data;
                this.setState({ document_types: data, loading: false });
                // order alphabetically
                this.state.document_types.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                // console.log(this.state.document_types);
            })
            .catch(function (error) {
                console.log(error);
            });
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/state/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
        .then(response => {
                const data = response.data.data;
                this.setState({ states: data, loading: false });
                // order alphabetically
                this.state.states.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                // console.log(this.state.states);
            })
            .catch(function (error) {
                console.log(error);
            });
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/city/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
        .then(response => {
                const data = response.data.data;
                this.setState({ cities: data, loading: false });
                // order alphabetically
                this.state.cities.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                // console.log(this.state.cities);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    async populateData() { }
}