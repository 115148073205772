import React from 'react'
import { Component } from 'react';
import authService from './AuthorizeService';
import { AuthenticationResultStatus } from './AuthorizeService';
import { LoginActions, QueryParameterNames, ApplicationPaths } from './ApiAuthorizationConstants';
import helpers from '../../Helpers';
import axios from 'axios';
import SessionHelper from '../session/SessionHelper'

// The main responsibility of this component is to handle the user's login process.
// This is the starting point for the login process. Any component that needs to authenticate
// a user can simply perform a redirect to this component with a returnUrl query parameter and
// let the component perform the login and return back to the return url.
export class Login extends Component {
    constructor(props) {
        super(props);

        this.onSubmit = this.onSubmit.bind(this);  
        this.onChangeUsername = this.onChangeUsername.bind(this);
        this.onChangePassword = this.onChangePassword.bind(this);

        this.state = {
            message: undefined,
            username: '',
            password: '',
            loading: false,
            resources: [],
            
        };
    }

    onChangeUsername(e) {
        this.setState({
            username: e.target.value
        });
    }  

    onChangePassword(e) {
        this.setState({
            password: e.target.value
        });
    }  

    componentDidMount() {
        this.setState({ loading: true });
        this.getResources();
    }

    async onSubmit(e) {
        e.preventDefault();
        this.setState({ loading: true });

        this.login(this.getReturnUrl());
    }

    render() {
        return (
            <div id="layoutAuthentication">
                {this.state.loading ? helpers.showLoadingOverlay() : helpers.hideLoadingOverlay()}
                <div id="layoutAuthentication_content">
                    <main>
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-5">
                                    <div className="card shadow-lg border-0 rounded-lg mt-7">
                                        <div className="card-header"><h3 className="text-center font-weight-light my-4">{this.state.resources.Title_Login}</h3></div>
                                        <div className="card-body">
                                            <form className="needs-validation" onSubmit={this.onSubmit}>
                                                <div className="form-group">
                                                    <label className="small mb-1" htmlFor="inputUsername">{this.state.resources.Label_Username}</label>
                                                    <input className="form-control py-4" id="inputUsername" type="text" onChange={this.onChangeUsername} placeholder={this.state.resources.Label_EnterUsername} required />
                                                </div>
                                                <div className="form-group">
                                                    <label className="small mb-1" htmlFor="inputPassword">{this.state.resources.Label_Password}</label>
                                                    <input className="form-control py-4" id="inputPassword" type="password" onChange={this.onChangePassword} placeholder={this.state.resources.Label_EnterPassword} required />
                                                </div>
                                                <div className="form-group text-end align-items-center justify-content-between mt-4 mb-0">
                                                    <button type="submit" className="btn btn-primary">{this.state.resources.ButtonLabel_Login}</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        );
    }

    async login(returnUrl) {
        console.log("OKOK");
        const state = { returnUrl };
        const result = await authService.signIn(this.state.username, this.state.password);

        if (result.success === true) {
            helpers.saveUserData(result.data);
            localStorage.setItem('uilanguage', result.data.language);
            helpers.loadLanguageResources();

            SessionHelper.setExpiry();

            await this.navigateToReturnUrl(returnUrl);
        } else {
            let msg = this.state.resources.ErrorMessage_UnknownError;

            if (result.error_code == window.errorCodes.InvalidLogin) {
                msg = this.state.resources.ErrorMessage_Login_Invalid;
            } else if (result.error_code == window.errorCodes.LockedOutUser) {
                msg = this.state.resources.ErrorMessage_Login_LockedUser;
            }

            helpers.notifyError(this.state.resources.Label_Error, msg);
        }

        this.setState({ loading: false });
    }

    async getResources() {
        const resourceVars = [];
        resourceVars.push("ErrorMessage_UnknownError");
        resourceVars.push("ErrorMessage_Login_Invalid");
        resourceVars.push("ErrorMessage_Login_LockedUser");
        resourceVars.push("Label_Error");
        resourceVars.push("Label_Username");
        resourceVars.push("Label_EnterUsername");
        resourceVars.push("Label_Password");
        resourceVars.push("Label_EnterPassword");
        resourceVars.push("ButtonLabel_Login");
        resourceVars.push("Title_Login");
        
        const headers = {
            'Content-Type': 'application/json'
        };

        axios.post(window.config.REACT_APP_SECURITY_API_URL + '/languageresource/getbyvars/' + helpers.getUiLanguage(), resourceVars, {
            headers: headers
        })
            .then((response) => {
                const data = response.data.data;
                this.setState({ resources: data, loading: false });
            })
            .catch((error) => {
                console.log(error);
            });
    }

    async processLoginCallback() {
        const url = window.location.href;
        const result = await authService.completeSignIn(url);
        switch (result.status) {
            case AuthenticationResultStatus.Redirect:
                // There should not be any redirects as the only time completeSignIn finishes
                // is when we are doing a redirect sign in flow.
                throw new Error('Should not redirect.');
            case AuthenticationResultStatus.Success:
                await this.navigateToReturnUrl(this.getReturnUrl(result.state));
                break;
            case AuthenticationResultStatus.Fail:
                this.setState({ message: result.message });
                break;
            default:
                throw new Error(`Invalid authentication result status '${result.status}'.`);
        }
    }

    getReturnUrl(state) {
        const params = new URLSearchParams(window.location.search);
        const fromQuery = params.get(QueryParameterNames.ReturnUrl);
        if (fromQuery && !fromQuery.startsWith(`${window.location.origin}/`)) {
            // This is an extra check to prevent open redirects.
            throw new Error("Invalid return url. The return url needs to have the same origin as the current page.")
        }
        return (state && state.returnUrl) || fromQuery || `${window.location.origin}/`;
    }

    redirectToRegister() {
        this.redirectToApiAuthorizationPath(`${ApplicationPaths.IdentityRegisterPath}?${QueryParameterNames.ReturnUrl}=${encodeURI(ApplicationPaths.Login)}`);
    }

    redirectToProfile() {
        this.redirectToApiAuthorizationPath(ApplicationPaths.IdentityManagePath);
    }

    redirectToApiAuthorizationPath(apiAuthorizationPath) {
        const redirectUrl = `${window.location.origin}${apiAuthorizationPath}`;
        // It's important that we do a replace here so that when the user hits the back arrow on the
        // browser he gets sent back to where it was on the app instead of to an endpoint on this
        // component.
        window.location.replace(redirectUrl);
    }

    navigateToReturnUrl(returnUrl) {
        // It's important that we do a replace here so that we remove the callback uri with the
        // fragment containing the tokens from the browser history.
        window.location.replace(returnUrl);
    }
}


export default Login;