import React, { Component } from 'react';
import axios from 'axios';
import helpers from '../../Helpers';
import '../Companies/Company.css';

import Button from '@material-ui/core/Button';

export class ProductCountryKeyCreate extends Component {
    static displayName = ProductCountryKeyCreate.name;

    constructor(props) {
        super(props);

        this.onChangeKey = this.onChangeKey.bind(this);
        this.onChangeUnits = this.onChangeUnits.bind(this);
        this.onChangePrice = this.onChangePrice.bind(this);
        this.onChangeVisibleFor = this.onChangeVisibleFor.bind(this);
        this.onChangeCrossSell = this.onChangeCrossSell.bind(this);
        this.onChangeProductCountryId = this.onChangeProductCountryId.bind(this);
        this.onChangeDescription = this.onChangeDescription.bind(this);
        this.onChangeIva = this.onChangeIva.bind(this);
        this.onChangeDuration = this.onChangeDuration.bind(this);
        this.onChangeEnabled = this.onChangeEnabled.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onSubmit = this.onSubmit.bind(this);  
        this.onChangeSingleSale = this.onChangeSingleSale.bind(this);
        this.onChangeProductCs = this.onChangeProductCs.bind(this);

        this.state = {
            id: 0,
            key: null,
            resources: [],
            productcountries: [],
            productCountryId: localStorage.getItem('productCountryId'),
            productCountryDefaultId: null,
            productCountryDefault: null,
            crossSell: false,
            duration: null,
            description: null,
            price: 0,
            units: 0,
            iva: true,
            visibleFor: null,
            enabled: true,
            loading: true,
            userId: 0,
            disabledProduct: false,
            singleSale: false,
            productCs: null,
        };
    }

    componentDidMount() {
        this.getResources();
        this.populateData();
        helpers.setNavActive("#nav-product-countrykey");
        helpers.setClientValidation();

        let currentUser = helpers.getUserData();
        this.setState({
            userId: currentUser.id
        });

        document.querySelector('#divProductCs').style.display = 'none';
    }

    async onSubmit(e) {
        e.preventDefault();

        var form = document.querySelectorAll('.needs-validation')[0];

        if (!form.checkValidity())
            return;
        
        if(this.state.price == '0' || this.state.price == 0 || this.state.units == '0' || this.state.units == 0 )
            return;
        
        this.setState({ loading: true });
        if (localStorage.getItem("productCountryId")) {
            localStorage.removeItem("productCountryId");
        }

        const data = {
            product_key: this.state.key,
            cross_sell: this.state.crossSell,
            productcountry_id: this.state.productCountryId,
            description: this.state.description,
            iva: this.state.iva,
            visiblefor: this.state.visibleFor,
            price: this.state.price,
            units: this.state.units,
            enabled: this.state.enabled,
            creation_user_id: this.state.userId,
            product_cs: this.state.productCs,
            single_sale: this.state.singleSale
        };
        if (this.state.productCountryDefaultId !== null) {
            data.productcountry_id = this.state.productCountryDefaultId;
        }
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME)
        };

        axios.post(window.config.REACT_APP_PRODUCTS_API_URL + '/productcountrykey/save', data, {
            headers: headers
        })
            .then((response) => {
                helpers.notifySuccess(this.state.resources.Label_Success, this.state.resources.SuccessMessage_DataSavedSuccessfully);
                // this.props.history.push('/product-countrykey');
                this.props.history.go('-1')
            })
            .catch((error) => {
                console.log(error);

                let msg = this.state.resources.ErrorMessage_UnknownError;
                if (error.response.data.error_code == window.errorCodes.DuplicatedEntityName)
                    msg = this.state.resources.ErrorMessage_DuplicatedName;
                else if (error.response.data.error_code == window.errorCodes.AssociatedEntity)
                    msg = this.state.resources.ErrorMessage_AssociatedEntity_Company_User;

                helpers.notifyError(this.state.resources.Label_Error, msg);
                this.setState({ loading: false });
            });
    }  

    onCancel(e) {
        e.preventDefault();
        this.props.history.go('-1')
        // this.props.history.push("/product-countrykey");
    }

    onChangeKey(e) {
        this.setState({
            key: e.target.value
        });
    }  
    onChangeUnits(e) {
         this.setState({
            units: e.target.value
        });
    } 

    onChangeDescription(e) {
        this.setState({
            description: e.target.value
        });
    }

    onChangeIva(e) {
        this.setState({
            iva: e.target.value
        });
    }
    
    onChangeCrossSell(e) {
        this.setState({
            crossSell: e.target.value
        });
        // console.log(e.target.value);
        if(e.target.value == true || e.target.value == 'true'){
            document.querySelector('#divProductCs').style.display = 'block';
        }else{
            document.querySelector('#divProductCs').style.display = 'none';
        }
    }

    onChangeProductCountryId(e) {
        this.setState({
            productCountryId: e.target.value
        });
    }

    onChangePrice(e) {
        this.setState({
            price: e.target.value
        });
    }

    onChangeVisibleFor(e) {
        this.setState({
            visibleFor: e.target.value
        });
    }

    onChangeDuration(e) {
        this.setState({
            duration: e.target.value
        });
    }

    onChangeEnabled(e) {
        this.setState({
            enabled: e.target.checked
        });
    } 

    onChangeSingleSale(e){
        this.setState({
            singleSale: e.target.value
        });
    }

    onChangeProductCs(e){
        this.setState({
            productCs: e.target.value
        });
    }

    render() {
        let contents =
            <form className="form row g-3 needs-validation" onSubmit={this.onSubmit} noValidate>
                {/*ENABLED*/}
                <div className="form-group col-md-12">
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" id="enabled" checked={this.state.enabled} onChange={this.onChangeEnabled} />
                        <label className="form-check-label" htmlFor="enabled">{this.state.resources.Label_Enabled}</label>
                        <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                    </div>
                </div>

                <div className="form-group col-md-4" >

                    <label className=" form-label">{this.state.resources.Label_ProductCountry}</label>
                    <select className="form-control" data-val="true" name="productcountryid" onChange={this.onChangeProductCountryId} required
                        disabled={this.state.disabledProduct}>
                        <option value="">{this.state.resources.Label_SelectOption}</option>
                        {this.state.productcountries.map(productcountry =>
                            <option key={productcountry.id} selected={this.state.productCountryId ==  productcountry.id} 
                                value={productcountry.id}>{productcountry.product_name} - {productcountry.country_name}</option>
                        )}
                    </select>
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>

                    <label className=" form-label">{this.state.resources.Label_IndividualSale}</label>
                    <select className="form-control" data-val="true" name="singleSale" defaultValue={this.state.singleSale} onChange={this.onChangeSingleSale}>
                        <option value="false">No</option>
                        <option value="true">{this.state.resources.Label_Yes}</option>
                    </select>


                    <label className=" form-label">{this.state.resources.Label_Units}</label>
                    <input className="form-control" type="number" name="units" min={1} onChange={this.onChangeUnits} value={this.state.units}
                     onKeyDown={(evt) => ["+", "-","e","E"].includes(evt.key) && evt.preventDefault()} required />
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>

                </div>
               
                <div className="form-group col-md-4" >

                    <label className=" form-label">{this.state.resources.Label_Presentation}</label>
                    <input className="form-control" type="text" name="description" onChange={this.onChangeDescription} value={this.state.description} required />
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>


                    <label className=" form-label">IVA</label>
                    <select className="form-control" data-val="true" name="iva" defaultValue={this.state.iva} onChange={this.onChangeIva} required>
                        <option value="false">No</option>
                        <option value="true">{this.state.resources.Label_Yes}</option>
                    </select>
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>

                    <label className=" form-label">{this.state.resources.Label_Price}</label>
                    <input className="form-control" type="number" min={1} name="price" onChange={this.onChangePrice} value={this.state.price}
                    onKeyDown={(evt) => ["+", "-","e","E"].includes(evt.key) && evt.preventDefault()} required />
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                    
                </div>
                <div className=" form-group col-md-4">
                    
                    <label className=" form-label">{this.state.resources.Label_Key}</label>
                    <input className="form-control" type="text" name="key" onChange={this.onChangeKey} value={this.state.key} required />
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                    
                    <label className=" form-label">{this.state.resources.Label_VisibleFor}</label>
                    <select className="form-control" data-val="true" name="visiblefor" defaultValue={this.state.visibleFor} onChange={this.onChangeVisibleFor} required>
                        <option value="">{this.state.resources.Label_SelectOption}</option>
                        <option value="1">{this.state.resources.Label_Level1}</option>
                        <option value="2">{this.state.resources.Label_Level2}</option>
                        <option value="3">{this.state.resources.Label_Level3}</option>
                    </select>

                    <label className=" form-label">Cross Sell</label>
                    <select className="form-control" data-val="true" name="crosssell" defaultValue={this.state.crossSell} onChange={this.onChangeCrossSell}>
                        <option value={false}>No</option>
                        <option value={true}>{this.state.resources.Label_Yes}</option>
                    </select>

                    <div id="divProductCs">
                        <label className=" form-label">{this.state.resources.Label_Product} CS</label>
                        <select className="form-control" data-val="true" name="productCs" onChange={this.onChangeProductCs} required={this.state.crossSell == 'true' ? true : false}>
                            <option value="">{this.state.resources.Label_SelectOption}</option>
                            {this.state.productcountries.map(productcountry =>
                                <option key={productcountry.id} selected={this.state.productCs ==  productcountry.id} 
                                    value={productcountry.id}>{productcountry.product_name} - {productcountry.country_name}</option>
                            )}
                        </select>
                    </div>
                </div>
                <div className=" form-group col-md-6">
                </div>


                {/**Buttons Header */}
                 <div className="buttons-head row">
                    <div className=" buttons-childs">
                        <button type="submit" className="btn btn-primary">{this.state.resources.ButtonLabel_Save}</button>
                        <button className="btn btn-danger" onClick={this.onCancel}>{this.state.resources.ButtonLabel_Cancel}</button>
                    </div>
                 </div>
            </form>;

        return (

           <div>
                
           
            <div className="mt-2">

                <ol className="breadcrumb my-4">
                    <li className="breadcrumb-item active">{this.state.resources.Title_ProductCountryKey}</li>
                </ol>
                <div className="card">
                    <div className="card-body">
                        {/* {this.state.loading ? helpers.showLoadingOverlay() : helpers.hideLoadingOverlay()} */}
                        {contents}
                    </div>
                </div>
                </div>
        </div> 
        );
    }

    async getResources() {
        const resourceVars = [];
        resourceVars.push("Label_Product");
        resourceVars.push("Label_Name");
        resourceVars.push("Label_Description");
        resourceVars.push("Label_BusinessArea");
        resourceVars.push("Label_Enabled");
        resourceVars.push("Label_Success");
        resourceVars.push("Label_SelectOption");
        resourceVars.push("ButtonLabel_Save");
        resourceVars.push("ButtonLabel_Cancel");
        resourceVars.push("Label_Error");
        resourceVars.push("ErrorMessage_RequiredField");
        resourceVars.push("ErrorMessage_DuplicatedName");
        resourceVars.push("ErrorMessage_UnknownError");
        resourceVars.push("ErrorMessage_AssociatedEntity_Company_User");
        resourceVars.push("ErrorMessage_ErrorWhileUploadingImage");
        resourceVars.push("ErrorMessage_InvalidEmail");
        resourceVars.push("SuccessMessage_DataSavedSuccessfully");
        resourceVars.push("Label_ProductCountry");
        resourceVars.push("Title_Courier");
        resourceVars.push("Title_Countries");
        resourceVars.push("Label_ShippingCost");
        resourceVars.push("Label_ProductionCost");
        resourceVars.push("Label_CourierCost");
        resourceVars.push("Title_ProductCountryKey");
        resourceVars.push("Label_Yes");
        resourceVars.push("Label_Key");
        resourceVars.push("Label_Level1");
        resourceVars.push("Label_Level2");
        resourceVars.push("Label_Level3");
        resourceVars.push("Label_VisibleFor");
        resourceVars.push("Label_DurationTreatment");
        resourceVars.push("Label_Price");
        resourceVars.push("Label_Units");
        resourceVars.push("Label_Presentation");
        resourceVars.push("Label_IndividualSale");

        const headers = {
            'Content-Type': 'application/json'
        };

        axios.post(window.config.REACT_APP_SECURITY_API_URL + '/languageresource/getbyvars/' + helpers.getUiLanguage(), resourceVars, {
            headers: headers
        })
            .then((response) => {
                const data = response.data.data;
                this.setState({ resources: data, loading: false });
            })
            .catch((error) => {
                console.log(error);
                this.setState({ loading: false });
            });
    }

    async populateData() {
        this.setState({
            loading: true
        });
        helpers.showLoadingOverlay();
        let authProducts = await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) 
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/productcountry/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ productcountries: data, loading: false });
                // order alphabetically
                this.state.productcountries.sort((a, b) => a.product_name < b.product_name ? -1 : +(a.product_name > b.product_name))
                helpers.hideLoadingOverlay();
                if (localStorage.getItem('productCountryId') != '') {
                   
                    this.state.productCountryDefaultId = localStorage.getItem('productCountryId');
                    helpers.showLoadingOverlay();
                    axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/productcountry/getbyid/' + this.state.productCountryDefaultId,
                        {
                            'headers': { 'Authorization': authProducts}
                        })
                        .then(response => {
                            // console.log(response);
                            helpers.hideLoadingOverlay();
                            this.setState({
                                productCountryDefault: response.data.data.name,
                                loading: false,
                                disabledProduct: true
                            });
                            this.state.disabledProduct = true;
                            // console.log(this.state.productCountryDefault)
                        })
                        .catch(error => {
                            console.log(error);
                        });
                }

            })
            .catch(function (error) {
                console.log(error);
            });

        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/country/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ countries: data, loading: false });
                // order alphabetically
                this.state.countries.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                // console.log(this.state.countries);
            })
            .catch(function (error) {
                console.log(error);
            });

        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/courier/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ couriers: data, loading: false });
                // order alphabetically
                this.state.couriers.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                // console.log(this.state.couriers);
            })
            .catch(function (error) {
                console.log(error);
            });
        
    }
}
