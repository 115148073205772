import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';  
import $ from 'jquery';
import DataTable from 'datatables.net';
import helpers from '../../Helpers';
import '../../custom.css';

export class ProductCountryKeyList extends Component {
    static displayName = ProductCountryKeyList.name;

    constructor(props) {
        super(props);
        this.state = { items: [], resources: [], loading: true, permissionsIds: [] };
    }

    componentDidMount() {
        let userData = helpers.getUserData();
        this.state.permissionsIds = userData.permission_ids;
        this.getResources();
        this.populateData();
        helpers.setNavActive("#nav-product-countrykey");
        localStorage.removeItem('productCountryId');
    }

      static renderTable(items, resources, permissionsIds) {
          return (
                <table className='table datatable my-table' aria-labelledby="tabelLabel"> {/*table class:* table-striped datatable */}
                    <thead>
                      <tr>
                            <th>{resources.Label_ID}</th>
                            <th>{resources.Label_Key}</th>
                            <th>{resources.Label_Presentation}</th>
                            <th>{resources.Label_Price}</th>
                            <th>{resources.Label_Units}</th>
                            <th>{resources.Label_Status}</th>
                            {permissionsIds.includes(93) ?
                              <th className="text-center">{resources.Label_Actions}</th>
                              : <></>}
                        </tr>
                    </thead>
                    <tbody>
                        {
                            items.map(item =>
                                <tr key={item.id}>
                                    <td>{item.id}</td>
                                    <td>{item.product_key}</td>
                                    <td>{item.description}</td>
                                    <td>{item.price}</td>
                                    <td>{item.units}</td>
                                    <td>{item.enabled == true ? resources.Label_Enabled : resources.Label_Disabled}</td>
                                    {permissionsIds.includes(93) ?
                                    <td className="table-icons">
                                        {/* <Link to={"/products-info/" + item.id} className="btn btn-default"><i className="fas fa-edit"></i></Link> */}
                                        <Link to={"/product-countrykey-detail/" + item.id} className="btn btn-default"><i className="fas fa-edit"></i></Link>
                                    </td>
                                    : <></>}
                                </tr>
                            )}
                    </tbody>
                </table>
        );
      }

      render() {
          let contents = this.state.loading
              ? helpers.showLoadingOverlay()
              : ProductCountryKeyList.renderTable(this.state.items, this.state.resources, this.state.permissionsIds);

          return (
              <div className="mt-2">
                  <ol className="breadcrumb my-4">
                      <li className="breadcrumb-item active">{this.state.resources.Title_ProductCountryKey}</li>
                  </ol>
                  <div className="card">
                      <div className="card-header">
                          {this.state.permissionsIds.includes(92) ?
                          <Link to={"/product-countrykey-create"} className="btn btn-success"><i className="fas fa-plus" aria-hidden="true"></i> {this.state.resources.ButtonLabel_New}</Link>
                            : <></>}
                        </div>
                      <div className="card-body">
                          <div className="table-responsive">
                              {contents}
                          </div>
                      </div>
                  </div>
              </div>
        );
      }

    async populateData() {
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/productcountrykey/getall',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                console.log(data);
                this.setState({ items: data, loading: false });
                $('.datatable').DataTable({
                    "language": {
                        "lengthMenu": this.state.resources.Datatables_language_lengthMenu,
                        "zeroRecords": this.state.resources.Datatables_language_zeroRecords,
                        "info": this.state.resources.Datatables_language_info,
                        "infoEmpty": this.state.resources.Datatables_language_infoEmpty,
                        "infoFiltered": this.state.resources.Datatables_language_infoFiltered,
                        "search": this.state.resources.Datatables_language_search,
                        "paginate": {
                            "previous": this.state.resources.Datatables_language_paginate_previous,
                            "next": this.state.resources.Datatables_language_paginate_next
                        },
                        "autoWidth": false
                    },
                    stateSave: true,
                    stateSaveCallback: function (settings, data) {
                        localStorage.setItem('DataTables_b', JSON.stringify(data))
                        },
                    stateLoadCallback: function(settings) {
                        return JSON.parse(localStorage.getItem( 'DataTables_b'))
                        }
                });

                helpers.hideLoadingOverlay();
            })
            .catch(function (error) {
                console.log(error);
                helpers.hideLoadingOverlay();
            });
        
    }

    async getResources() {
        const resourceVars = [];
        resourceVars.push("Label_Products");
        resourceVars.push("Label_ID");
        resourceVars.push("Label_Name");
        resourceVars.push("Label_Logo");
        resourceVars.push("Label_FiscalId");
        resourceVars.push("Label_Brand");
        resourceVars.push("Label_Supplier");
        resourceVars.push("Title_Treatments");
        resourceVars.push("Label_Actions");
        resourceVars.push("Label_Status");
        resourceVars.push("Label_Enabled");
        resourceVars.push("Label_Disabled");
        resourceVars.push("ButtonLabel_ViewEdit");
        resourceVars.push("ButtonLabel_New");
        resourceVars.push("Datatables_language_lengthMenu");
        resourceVars.push("Datatables_language_zeroRecords");
        resourceVars.push("Datatables_language_info");
        resourceVars.push("Datatables_language_infoEmpty");
        resourceVars.push("Datatables_language_infoFiltered");
        resourceVars.push("Datatables_language_search");
        resourceVars.push("Datatables_language_paginate_previous");
        resourceVars.push("Datatables_language_paginate_next");
        resourceVars.push("Label_Country");
        resourceVars.push("Label_Product");
        resourceVars.push("Label_ProductCost");
        resourceVars.push("Label_ProductCountry");
        resourceVars.push("Title_ProductCountryKey");
        resourceVars.push("Label_Key");
        resourceVars.push("Label_Description");
        resourceVars.push("Label_Price");
        resourceVars.push("Label_Units");
        resourceVars.push("Label_Presentation");

        const headers = {
            'Content-Type': 'application/json'
        };

        axios.post(window.config.REACT_APP_SECURITY_API_URL + '/languageresource/getbyvars/' + helpers.getUiLanguage(), resourceVars, {
            headers: headers
        })
            .then((response) => {
                const data = response.data.data;
                this.setState({ resources: data, loading: false });
                // console.log(data);
            })
            .catch((error) => {
                console.log(error);
            });
    }
}