import React, { Component } from 'react';
import axios from 'axios';
import helpers from '../../Helpers';
import $ from 'jquery';
import queryString from 'query-string';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { styled } from '@mui/material/styles';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Button from '@mui/material/Button';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { RemoveShoppingCart } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { maxWidth } from '@mui/material/node_modules/@mui/system';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart';
import { WindowOpener } from "./window-opener";
import { th } from 'date-fns/locale';

const Swal = require('sweetalert2')

export class CheckOutWsp extends Component {
    static displayName = CheckOutWsp.name;

    constructor(props) {
        super(props);
        this.onConfirm = this.onConfirm.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onChangeClosingReasonId = this.onChangeClosingReasonId.bind(this);
        this.onContactAgain = this.onContactAgain.bind(this);
        this.onChangeCommnets = this.onChangeCommnets.bind(this);
        this.SaveNoSale = this.SaveNoSale.bind(this);
        this.sonResponse = this.sonResponse.bind(this);
        this.updateShoppingCarData = this.updateShoppingCarData.bind(this);
        this.setDataOrderDiv = this.setDataOrderDiv.bind(this);
        this.setDataDeliveryDiv = this.setDataDeliveryDiv.bind(this);
        this.setDataPurchaseDiv = this.setDataPurchaseDiv.bind(this);

        NoSale = NoSale.bind(this);
        OpenPaymentWebPay = OpenPaymentWebPay.bind(this);

        this.state = {
            steps: ['Inicio', 'Cliente', 'Pedido', 'Pago', 'Checkout'], //['Start', 'Client', 'Order', 'Payment', 'Checkout'],
            activeStep: 4,
            resources: [],
            products: [],
            countryId: JSON.parse(localStorage.getItem('DataWorkspace')).state.countryId,
            countryName: '',
            documentType: [],
            documentTypeId: 0,
            states: [],
            stateId: 0,
            cities: [],
            cityId: 0,
            clients: [],
            clientId: 0,
            productId: null,
            objetions: [],
            scriptSales: [],
            total: 0,
            shippingCostMax: 0,
            mapCourierCities: [],
            language: '',
            waypays: [],
            waypayId: 0,
            banks: [],
            bankId: 0,
            cardNumber: '',
            securityCode: null,
            expiration: null,
            holder: '',
            accountNumber: null,
            accountType: '',
            cod: false,
            showCod: false,
            countryWaypays: [],
            countryWaypayId: 0,
            closingReasons: [],
            closingReasonId: 0,
            contactAgain: null,
            comments: '',
            expirationMonth: '',
            expirationYear: '',
            productsSelected: [],
            productInfo: '',
            total: 0,
            shippingCostMax: 0,
            name: '',
            lastName: '',
            phone: '',
            stateId: null,
            address: '',
            courierId: 0,
            courierName: '',
            actualDate: null,
            deliveryTime: '',
            cityName: '',
            cityId: 0,
            courierComments: '',
            internalComments: '',
            userId: 0,
            userOccId: 0,
            campaingId: 0,
            agentId: 0,
            productCountryKeyId: 0,
            clientDocumentTypeId: null,
            clientDocumentNumber: null,
            clientDateBirth: null,
            sex: null,
            mobilePhone: null,
            email: null,
            haventEmail: null,
            number: null,
            flat: null,
            depto: null,
            betweenStreet: " ",
            postalCode: null,
            message: "",
            urlWebPay: "",
            buyOrder: 0,
            commerceCode: 0,
            betweenstreets: "",
            ppalArticleId: 0,
            successUrl: "",
            failureUrl: "",
            nulledUrl: "",
            shoppingCarCreationDate: null,
            sessionId: null,
            carStateId: null,
            paymentStateId: null,
            productCountryKeyId: '',
            interesRate: 0,
            duesLabel: '',
            orderNumber: '',
            paymentState: '',
            paymentStateId: 0,
            orderState: '',
            orderStateId: 0,
            agentName: '',
            showDate: null,
            authorizationCode: '',
            totalDaysTreatment: 0,
            finishTreatmentDate: '',
            appEnviroment: 'QA',
            tokenClientOcc: '',
            tokenApiUrl:'https://snlapi.auth.us-west-2.amazoncognito.com/oauth2/token',
            tokenProducts: "",
            productRefresh: '',
            productbugRefresh:'',
            Evaluacion:'',
           // bugOccid: '',
           productRefreshIdKey:'',
           productRefreshOccId:'',
           productRefreshCampaingOccId:'',
           paymethodId: 0,
           campaingName:'',
           pruebaOccid: 0,
           cantidadCarDetail: 0,
           precioUnitario: 0,
           idCarritoOcc: 0,
           newArray:[]
        };
    }

    sonResponse (err, res) {
        if (err) {
            this.setState({ message: res })
        }
        this.setState({ message: res })
    }


    componentDidMount() {
        var f = new Date();
        this.setState({
            sessionId: f.getMilliseconds() + "" + f.getMinutes() + "" + f.getHours() + "" + f.getDate() + "" + (f.getMonth() + 1) + "" + f.getFullYear()
        });
        
        if(window.location.origin.includes('qa') || window.location.origin.includes('http://qa.occ2.swissnaturelabs.cl')){
            this.setState({
                appEnviroment: 'QA'
            });
            this.state.appEnviroment = 'QA';
        }

        document.querySelector('#dataOrderDiv').style.display = 'block';
        document.querySelector('#dataDeliveryDiv').style.display = 'none';
        document.querySelector('#dataPurchaseDiv').style.display = 'none';
        //document.querySelector('#authCodeDiv').style.display = 'none';
        document.querySelector('#cardNumberDiv').style.display = 'none';

        document.querySelector("#btnOrderData").classList.add('active');

        document.querySelector('#btnPaymentWebPay').style.display = 'none';
        
        let data = JSON.parse(localStorage.getItem('DataWorkspace'));
        // console.log(data);
        this.setState({
            countryId: data.state.countryId,
           // productId: data.state.productId,
            products: data.state.products,
            closingReasons: data.state.closingReasons,
            agentId: data.state.userId,
            campaingId: data.state.campaingId,
            productCountriesKey: data.state.productCountriesKey,
            productCountries: data.state.productCountries,
            subareaId: data.state.subareaId,
            tokenProducts: data.state.tokenProducts,
            mapCourierCities: data.state.mapCourierCities,
            //deliveryTime: data.state.deliveryTime,
        });
        this.state.mapCourierCities= data.state.mapCourierCities;
        this.state.tokenProducts = data.state.tokenProducts;

        this.getResources();
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': data.state.tokenProducts
        };

        let dataOrder = JSON.parse(localStorage.getItem('DataOrderWsp'));
        // this.state.productsSelected.map(product => {
        //     data.state.productCountriesKey.map(prodKey => {
        //         if (prodKey.description == product.description) {
        //             dataOrder.productCountries.map(prodCountry => {
        //                 if (prodCountry.id == prodKey.productcountry_id) {
        //                     productRefresh += this.state.ppalArticleId && this.state.paymentStateId;
        //                 }
        //             });
        //         }
        //     });
        // });
        //console.log(dataOrder)
        let filterArr = [];
                dataOrder.productsSelected.map(product => {
                    let prod = {
                        description: product.description,
                        total: Number(product.quantity) * Number(product.price)
                    }
                    filterArr.push(prod);
                });
            let prodMayor = filterArr.sort((a, b) => (a.total < b.total) ? 1 : -1)[0];
            console.log(prodMayor)
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getrefreshperformance/'+ prodMayor.description , {
            headers: headers
        })
        
        .then((response) => {
            const data = response.data.data;
            console.log(data)
            this.state.productbugRefresh = data;
            this.state.productRefresh = data.productcountry_id;
            this.state.productRefreshIdKey = data.occ_id;
            //data.productcountry_id
            console.log(this.state.productRefresh)
        })
        .catch((error) => {
            console.log(error);
        });

        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getrefreshperformance/Envío Unico', {
            headers: headers
        })
        .then((response) => {
            // console.log(response.data.data);
            this.setState({
                shippingCar: response.data.data
            });
        })
        .catch((error) => {
            console.log(error);
        });

        //paymetod para idfpago en datapayment
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getcampaingbyoccid/'+ data.state.campaingId, {
            headers: headers
        })
        .then((response) => {
            const data = response.data.data;
            this.state.productRefreshCampaingOccId= data.occ_id;
        })
        .catch((error) => {
            console.log(error);
        });
        //////////////////////
        ////
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getproductbyoccid/'+ data.state.productId, {
            headers: headers
        })
        .then((response) => {
            const data = response.data.data;
            this.state.productRefreshOccId= data.occ_id;
        })
        .catch((error) => {
            console.log(error);
        });

        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getuserbypbxidid/' + data.state.userId,
        {
            headers: headers
        })
        .then(response => {
            const data = response.data.data;
            //console.log(data);
            this.setState({
                agentName: data.first_name + ' ' + data.last_name,
                userOccId: data.occ_id
            });
            //console.log(this.state.userOccId)
         })
        .catch((error) => {
              console.log(error);
          }); 
        data.state.productCountries.map(productCountry => {
            if (productCountry.product_id == data.productId) {
                data.state.productCountriesKey.map((item) => {
                    if (item.productcountry_id == productCountry.id) {
                        this.setState({
                            productCountryKeyId: item.id
                        });
                    }
                });
            }
        });

        let dataClient = JSON.parse(localStorage.getItem('DataClientWsp'));
        //console.log(dataClient)
        this.setState({            
            clientId: Number(dataClient.clientId),
            name: dataClient.name,
            phone: dataClient.phone,
            lastName: dataClient.lastName,
            address: dataClient.adress,
            number: dataClient.number,
            stateId: dataClient.stateId,
            states: dataClient.states,
            cityId: dataClient.cityId,
            cities: dataClient.cities,
            courierComments: dataClient.courierComentaries,
            internalComments: dataClient.insideComentaries,
            clientDocumentTypeId: dataClient.documentTypeId,
            clientDocumentNumber: dataClient.documentNumber,
            clientDateBirth: dataClient.dateOfBirth,
            sex: dataClient.sex,
            mobilePhone: dataClient.mobilePhone,
            phone: dataClient.phone,
            email: dataClient.email,
            haventEmail: dataClient.haventMail,
            number: dataClient.number,
            flat: dataClient.floor,
            depto: dataClient.department,
            betweenStreet: dataClient.betweenStreet,
            postalCode: dataClient.postalCode,
            countryId: dataClient.countryId,
            clients: dataClient.clients,
            betweenstreets: dataClient.betweenstreets,
            documentType: dataClient.documentType
        });
        this.state.cities = dataClient.cities;
        this.state.cityId = dataClient.cityId;

        for (let i = 0; i < dataClient.cities.length; i++) {
            if (dataClient.cities[i].id == dataClient.cityId) {
                this.setState({
                    cityName: dataClient.cities[i].name
                });
                this.state.cityName = dataClient.cities[i].name;
            }
        }


         dataOrder = JSON.parse(localStorage.getItem('DataOrderWsp'));
         //console.log(dataOrder)
        this.setState({
            productsSelected: dataOrder.productsSelected,
            total: (dataOrder.total + Number(dataOrder.shippingCostMax)),
            shippingCostMax: dataOrder.shippingCostMax,
            productCountryKeyId: dataOrder.productCountryKeyId,
            productId: dataOrder.productId
        });
        //console.log(dataOrder.productId)

        let dataPayment = JSON.parse(localStorage.getItem('DataPaymentWsp'));
        //console.log(dataPayment)
        this.setState({
            waypayId: dataPayment.waypayId,
            waypays: dataPayment.waypays,
            cod: dataPayment.cod,
            showCod: dataPayment.showCod,
            expirationMonth: dataPayment.expirationMonth,
            expirationYear: dataPayment.expirationYear,
            holder: dataPayment.holder,
            bankId: dataPayment.bankId,
            banks: dataPayment.banks,
            cardNumber: dataPayment.cardNumber,
            securityCode: dataPayment.securityCode,
            accountType: dataPayment.accountType,
            accountNumber: dataPayment.accountNumber,
            courierId: dataPayment.courierId,
            courierName: dataPayment.courierName,
            deliveryTime: dataPayment.deliveryTime,
            interesRate: dataPayment.interesRate,
            duesLabel: dataPayment.duesLabel,
            paymethodId: dataPayment.paymethodId,
        });
        //console.log(dataPayment.courierId);
        //console.log(dataPayment.cardNumber)
        if(!this.state.cardNumber){
            this.state.cardNumber = " ";
        }else{
            this.state.cardNumber = dataPayment.cardNumber;
        }
        this.state.bankId = dataPayment.bankId;
        this.state.waypays = dataPayment.waypays;
         //console.log(dataPayment.waypays)
         //console.log(dataPayment.waypayId)
        this.state.waypayId = dataPayment.waypayId;
        if(dataPayment.waypayId == 7){
            document.querySelector('#authCodeDiv').style.display = 'none';
            document.querySelector('#cardNumberDiv').style.display = 'block';
        }
        //ACA BUG
        // dataPayment.waypays.map(waypay => {
        //     if (waypay.id == dataPayment.waypayId) {
        //         if (waypay.paymethod_name == 'COD') {
        //              document.querySelector('#btn-no-sale').style.display = 'none';
        //              document.querySelector('#btnGoBack').style.display = 'none';
        //             document.querySelector('#btnPaymentWebPay').style.display = 'none';
        //              document.querySelector('#btnConfirm').style.display = 'none';
        //             helpers.showLoadingOverlay();
        //              setTimeout(() => {
        //                  this.onConfirm();
        //              }, 1500);
        //         }   
        //     }
        // });
        if (!dataPayment.validateDues) {
            document.querySelector("#duesNumberDiv").style.display = 'none';
        }

        let date = new Date()
        let day = `${(date.getDate())}`.padStart(2, '0');
        // console.log((Number(day) + (Number(dataPayment.deliveryTime) / 24)).toString().padStart(2, '0'))  T00:00:00.0000000
        let month = `${(date.getMonth() + 1)}`.padStart(2, '0');
        let year = date.getFullYear();
        //console.log(dataPayment.deliveryTime.split(""));
        let dataDeliveryDate = `${dataPayment.deliveryTime.split("")[0]}${dataPayment.deliveryTime.split("")[1]}`;
         //console.log(dataDeliveryDate);
        this.state.actualDate = `${year}-${month}-${(Number(day) + (Number(dataDeliveryDate) / 24)).toString().padStart(2, '0')} 00:00:00.0000000`;
        this.state.showDate = `${year}-${month}-${(Number(day) + (Number(dataDeliveryDate) / 24)).toString().padStart(2, '0')}`;
        this.state.finishTreatmentDate = `${year}-${month}-${(Number(day) + this.state.totalDaysTreatment).toString().padStart(2, '0')} 00:00:00.0000000`;

            
        // }
        // const headers = {
        //     'Content-Type': 'application/json',
        //     'Authorization': this.state.tokenProducts
        // };

        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getuserbypbxidid/' + data.state.userId,
            {
                headers: headers
            })
            .then(response => {
                const data = response.data.data;
                //console.log(data);
                this.setState({
                    userId: data.id,
                    userOccId: data.occ_id
                });
                //console.log(this.state.userOccId)
            })
            .catch(error => {
                console.log(error);
            });

        
        
        

        //AmountUSD
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getchangetypes', //id 1: USD
        {
            'headers': headers
        })
        .then(response => {
            const resp = response.data.data;
            this.setState({
                changeTypes: resp
            });

            resp.map(type => {
                if (type.country_id == this.state.countryId && type.origin_currency == "USD") {
                    
                    this.setState({ 
                        changeRate: type.change_rate
                    });
                    this.state.changeRate = type.change_rate;
                    let filterAmountArr = 0;
                    dataOrder.productsSelected.map(product => {
                        data.state.productCountriesKey.map(prodKey => {
                            if (prodKey.description == product.description) {
                                
                                if (prodKey.iva) {
                                    filterAmountArr += ((product.quantity * product.price) / (1.19));
                                } else {
                                    filterAmountArr += (product.quantity * product.price);
                                }
                            }
                        });
                    });
                    // console.log(filterAmountArr / type.change_rate);
                    this.setState({
                        amountUsd: (filterAmountArr / type.change_rate).toFixed(3)
                    });
                    this.state.amountUsd = (filterAmountArr / type.change_rate).toFixed(3);
                }
            })
        })
        .catch(function (error) {
            console.log(error);
        });
        
        //logisticCost
        let totalCostLogistic = 0;
        dataOrder.productsSelected.map(product => {
            data.state.productCountriesKey.map(prodKey => {
                if (prodKey.description == product.description) {
                    dataOrder.productCountries.map(prodCountry => {
                        if (prodCountry.id == prodKey.productcountry_id) {
                            // console.log(prodCountry.couriercost * product.quantity);
                            totalCostLogistic += (prodCountry.couriercost * product.quantity);
                        }
                    });
                }
            });
        });
        // console.log(totalCostLogistic);
        this.setState({
            logisticCost: totalCostLogistic
        });
        this.state.logisticCost = totalCostLogistic;

        let total = 0;
        dataOrder.productsSelected.map(prod => {
            // console.log(prod);
            // console.log(dataPayment.interesRate);
            // console.log(Number((Number(prod.price * prod.quantity) + ((Number(prod.price * prod.quantity) * dataPayment.interesRate) / 100)).toFixed(0)))
            total = total + Number((Number(prod.price * prod.quantity) + ((Number(prod.price * prod.quantity) * dataPayment.interesRate) / 100)).toFixed(0));
        });

        this.state.total = total + Number((Number(dataOrder.shippingCostMax) + ((Number(dataOrder.shippingCostMax) * dataPayment.interesRate) / 100)).toFixed(0));
        this.setState({
            total: total + Number((Number(dataOrder.shippingCostMax) + ((Number(dataOrder.shippingCostMax) * dataPayment.interesRate) / 100)).toFixed(0))
        });

        dataOrder.productsSelected.map(prod => {
             //console.log(prod);
            let newItem = {
                id_clave: 0 ,
                cantidad: 0 ,
                precio_unitario: 0,
                porcentaje_iva: 0 
            }

             axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getrefreshperformance/'+ prod.description , {
                headers: headers
            })
            .then((response) => {
                const data = response.data.data;
                newItem.id_clave = data.occ_id;
                newItem.cantidad = prod.quantity;
                newItem.precio_unitario = Number(prod.price);
                this.state.newArray.push(newItem);
               
               // console.log(this.state.newArray)
            })
            .catch((error) => {
                console.log(error);
            });
        });

        //Agrego Envio Unico al Json a enviar a OCC
        let newItemE = {
            id_clave: 0 ,
            cantidad: 0 ,
            precio_unitario: 0,
            porcentaje_iva: 0 
        }
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getrefreshperformance/Envío Unico', {
            headers: headers
        })
        .then((response) => {
            // console.log(response.data.data);
                const data = response.data.data;
                newItemE.id_clave = data.occ_id;
                newItemE.cantidad = 1;
                newItemE.precio_unitario = Number(dataOrder.shippingCostMax);
                this.state.newArray.push(newItemE);
        })
        .catch((error) => {
            console.log(error);
        });
    }

    onCancel(e) {
        e.preventDefault();
        // console.log(this.state);
        this.state.products = [];
        this.state.productCountries = [];
        this.state.cities = [];
        this.state.states = [];
        localStorage.setItem('DataCheckOutWsp',JSON.stringify(this.state));
        this.props.history.goBack();
    }

    onConfirm() {

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': this.state.tokenProducts
        };
        
        let clientData = {
            name: this.state.name,
            lastname: this.state.lastName,
            document_typeid: this.state.clientDocumentTypeId,
            document_number: this.state.clientDocumentNumber,
            date_birth: this.state.clientDateBirth,
            sex: this.state.sex,
            personal_phone: Number(this.state.mobilePhone),
            work_phone: Number(this.state.phone),
            email: this.state.email ?? '',
            //email_no: this.state.haventEmail,
            direction: this.state.address,
            number: this.state.number,
            flat: this.state.flat,
            depto: this.state.depto,
            between_street: this.state.betweenstreets ?? "",
            postal_code: this.state.postalCode,
            countriesid: this.state.countryId,
            cityid: this.state.cityId,
            stateid: this.state.stateId,
            creation_user_id: this.state.userId ?? 1,
            // last_update_user_id:
            enabled: true,
           // occ_id: this.state.bugOccid
        }

        let localclientid = JSON.parse(localStorage.getItem('DataClientWsp'));
        //this.state.clients.map((client) => { 
           // console.log(localclientid)
            if (localclientid.clientId) { 
                clientData.id = localclientid.clientId;
                clientData.last_update_user_id = this.state.userId;
                if(localclientid.clientOccId){
                    clientData.occ_id = localclientid.clientOccId;
                }
            } else {
                clientData.creation_user_id = this.state.userId;
            }
             
        //});

        let dataBirth = ((this.state.clientDateBirth?.replace('-', '/'))?.replace('-', '/'))?.split('/')
        // console.log(dataBirth);
        let formatedDateBirth = dataBirth ? `${dataBirth[2]}/${dataBirth[1]}/${dataBirth[0]}` : null;
        // console.log(formatedDateBirth);

        /** ACCION GUARDAR CLIENTE EN OCC **/
        let docType = "";
        this.state.documentType.map(x => {
            if(x.id == this.state.clientDocumentTypeId){
                docType = x.type;
            }
        });

        let cityName = " ";
        let cityOccId = 0;
        this.state.cities.map(x => {
            if(x.id == this.state.cityId){
                cityName = x.name;
                cityOccId = x.occ_id;
                // console.log(x);
            }
        });
        // console.log(cityOccId);

        let stateName = "";
        this.state.states.map(x => {
            if(x.id == this.state.stateId){
                stateName = x.name;
            }
        });

        for (let i = 0; i < this.state.productsSelected.length; i++) { 
    
            this.state.cantidadCarDetail = this.state.productsSelected[i].quantity;
            this.state.precioUnitario = (Number(this.state.productsSelected[i].price) + ((Number(this.state.productsSelected[i].price) * this.state.interesRate) / 100));
            //console.log(this.state.cantidadCarDetail)
            //console.log( this.state.precioUnitario)
        }

      let shoppingcarToRest = {
            //id_cliente: Number(this.state.clientId),
            direccion: this.state.address,
            numero: this.state.number?.toString(),
            // entrecalles: this.state.betweenstreets ?? " ", 
            cp: this.state.postalCode,
            localidad: cityName,// this.state.cityName ?? 
            provincia: stateName,
            //telefono: this.state.mobilePhone,
            id_fpago: Number(this.state.waypayId) ,
            comentarios: this.state.courierComments,
            comentarios_internos: this.state.internalComments,
            id_operador: this.state.userOccId,
            tarjeta_numero: Number(this.state.cardNumber),
            tarjeta_vencimiento: this.state.cardExpiration,
            tarjeta_codigo: this.state.cardCode,
            tarjeta_titular: this.state.cardHolder,
            tarjeta_pagos: this.state.cardDues,
            tarjeta_aprobacion: this.state.cardApproval,
            campania: " ",
            id_campania: this.state.productRefreshCampaingOccId,
            nota_credito: 0,
            //estado_logistico: this.state.statusTango,
            id_articulo_ppal:this.state.productRefreshOccId, //this.state.productRefresh,
            // email: this.state.email ?? "",
            id_pais_negocio: Number(this.state.countryId),// buscar desde memoria el countryid
            unidades: 0,
            multiplica: 1,
            multiplicafactorcobranza: 1,
            monto_donacion: 0,
            id_estado_pedido: this.state.carStateId ,
            id_estado_pago: this.state.paymentStateId,
            items:this.state.newArray,
        }
        //console.log(this.state.productRefreshCampaingOccId)
        let clientToRest = {
            id: 0,
            nombre: this.state.name,
            apellido: this.state.lastName,
            // telefono_laboral: this.state.phone ?? " ",
            // email: this.state.email ?? "",
            tipo_doc: docType,
            num_doc: this.state.clientDocumentNumber.toString(),
            // fecha_nac: formatedDateBirth ?? "23/09/1960",
            direccion: this.state.address,
            numero: this.state.number?.toString(),
            // piso: this.state.flat?.toString() ?? " ",
            // dpto: this.state.depto?.toString() ?? " ",
            // entrecalles: this.state.betweenstreets ?? " ",
            localidad: cityName,// this.state.cityName ?? 
            provincia: stateName,
            // sexo: this.state.sex ?? " ",
            id_pais: Number(this.state.countryId),
            longitud: 0,
            latitud: 0,
            id_localidad: cityOccId,//this.state.cityId,
            // rut_autogenerado: " ",
            // profesion: " ",
            id_fidelidad: 0
        }

            if(!this.state.cardNumber){
                shoppingcarToRest.tarjeta_numero = " ";
            }else{
                shoppingcarToRest.tarjeta_numero = Number(this.state.cardNumber);
            }
        
        if(this.state.mobilePhone){
            if(this.state.mobilePhone?.toString()?.split("")?.length > 0){
                clientToRest.telefono_particular = this.state.mobilePhone
            }
        }

        if(this.state.carStateId){
            if(this.state.carStateId == 14){
                shoppingcarToRest.id_estado_pedido = 1;
            }
        }

        if(this.state.phone){
            if(this.state.phone.toString().split("")?.length > 0){
                clientToRest.telefono_laboral = this.state.phone.toString();
                shoppingcarToRest.telefono = this.state.phone.toString();
            }
        }

        if(this.state.email){
            if(this.state.email?.split("")?.length > 0){
                clientToRest.email = this.state.email;
                shoppingcarToRest.email = this.state.email;
            }
        }
        
        if(formatedDateBirth){
            if(!formatedDateBirth?.includes('undefined') && !formatedDateBirth?.includes('null')){
                clientToRest.fecha_nac = formatedDateBirth
            }
        }

        if(this.state.flat){
            if(this.state.flat?.split("")?.length > 0){
                clientToRest.piso = this.state.flat
            }
        }

        if(this.state.depto){
            if(this.state.depto?.split("")?.length > 0){
                clientToRest.dpto = this.state.depto
            }
        }

        if(this.state.betweenstreets){
            if(this.state.betweenstreets?.split("")?.length > 0){
                clientToRest.entrecalles = this.state.betweenstreets
                //shoppingcarToRest.entrecalles = this.state.betweenstreets
            }
        }

        if(this.state.betweenstreets?.split("")?.length == 0){
            shoppingcarToRest.entrecalles = ".";
            //console.log(this.state.betweenstreets)
        }else{
            shoppingcarToRest.entrecalles = this.state.betweenstreets
        }

        if(this.state.sex){
            if(this.state.sex?.split("")?.length > 0){
                clientToRest.sexo = this.state.sex
            }
        }
        helpers.showLoadingOverlay();
        axios.post(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/gettokenwsp',{},{
            headers: headers
        })
        .then((resp) => {
            // console.log(resp.data)
            this.state.tokenClientOcc = resp.data.access_token;
            let localclient = JSON.parse(localStorage.getItem('DataClientWsp'));
            if(localclient?.clientOccId){
                axios.post(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/updateclientocc', {clientOccUpdateData: clientToRest, token: this.state.tokenClientOcc, occId: localclient.clientOccId},{
                    headers: headers
                })
                .then((resp) => {
                    helpers.hideLoadingOverlay();
                    if(resp.data.id){
                        let id = resp.data.id;
                        let msg = localStorage.getItem('WspLanguage') == 'language0' ? `Cliente Actualizado. Id: ${id}` : `Client Updated. Id: ${id}`;
                            toast.success(msg, {
                                position: "top-right",
                                autoClose: 10000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                            this.saveClientAndShoppingCar(clientData, id, shoppingcarToRest);
                    }
    
                    if(resp.data.errors){
                        if(resp.data.errors[0].client_id){
                            let msg = localStorage.getItem('WspLanguage') == 'language0' ? `El Cliente ya existe. Id: ${resp.data.errors[0].client_id}` : `Client Already exist. Id: ${resp.data.errors[0].client_id}`;
                            toast.error(msg, {
                                position: "top-right",
                                autoClose: 10000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                            this.saveClientAndShoppingCar(clientData, resp.data.errors[0].client_id, shoppingcarToRest);
                        }
                    }
                })
                .catch((error) => {
                    helpers.hideLoadingOverlay();
                    console.log(error)
                }); 
            }
            else{
                axios.post(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/saveclientocc', {clientOccData: clientToRest, token: this.state.tokenClientOcc},{
                    headers: headers
                })
                .then((resp) => {
                    helpers.hideLoadingOverlay();
                    if(resp.data.id){
                       // console.log(resp.data)
                        let id = resp.data.id;
                        let msg = localStorage.getItem('WspLanguage') == 'language0' ? `Cliente Creado. Id: ${id}` : `Client Created. Id: ${id}`;
                            toast.success(msg, {
                                position: "top-right",
                                autoClose: 10000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                            //usar resp.data.id enviandolo como client.occid PARA EL BUG
                      //  this.state.bugOccid = resp.data.id;
                      this.saveClientAndShoppingCar(clientData, id, shoppingcarToRest);
                    }
    
                    if(resp.data.errors){
                        if(resp.data.errors[0].client_id){
                            let msg = localStorage.getItem('WspLanguage') == 'language0' ? `El Cliente ya existe. Id: ${resp.data.errors[0].client_id}` : `Client Already exist. Id: ${resp.data.errors[0].client_id}`;
                            toast.error(msg, {
                                position: "top-right",
                                autoClose: 10000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                            this.saveClientAndShoppingCar(clientData, resp.data.errors[0].client_id, shoppingcarToRest);
                        }
                    }
                })
                .catch((error) => {
                    helpers.hideLoadingOverlay();
                    console.log(error)
                });
            }
            
        })
        .catch((error) => {
            console.log(error)
        });
        
    }

    saveClientAndShoppingCar(clientData, clientOccId, shoppingcarToRest,){
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': this.state.tokenProducts
        };

        if(clientOccId){
            clientData.occ_id = clientOccId;
        }
        
        let date = new Date()
        let day = `${(date.getDate())}`.padStart(2, '0');
        let month = `${(date.getMonth() + 1)}`.padStart(2, '0');
        let year = date.getFullYear();
        this.state.finishTreatmentDate = `${year}-${month}-${(Number(day) + this.state.totalDaysTreatment).toString().padStart(2, '0')} 00:00:00.0000000`;

        helpers.showLoadingOverlay();
        axios.post(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/saveclient', clientData, {
            headers: headers
        })
        .then((response) => {
            const shoppingCar = {
                //id:,
                // creation_date: ,
                unix_creation_date: Date.parse(new Date(Date.now())),
                client_id: response.data.data,
                car_state_id: this.state.carStateId ?? 1,
                // update_car_state_date: ,
                courier_id: this.state.courierId,
                waypay_id: this.state.waypayId,
                courier_comments: this.state.courierComments,
                internal_comments: this.state.internalComments,
                user_operator_id: this.state.userId,
                campaing_id: Number(this.state.campaingId),
                total: this.state.total,//Math.round((Number(this.state.total) + ((Number(this.state.total) * this.state.interesRate) / 100))),
                invoice_number: null,
                billing_date: null, 
                lot_id: null,
                lot_state_id: null,
                //delivery_time: this.state.deliveryTime,
                credit_note: false,
                credit_note_number: null,
                annulment_date: null,
                dolar_quote: this.state.changeRate,
                usd_total: (this.state.total / this.state.changeRate).toFixed(3),
                delivery_date: this.state.actualDate,
                delivered: null,
                delivery_cost: this.state.shippingCostMax,
                address: this.state.address,
                address_number: this.state.number,
                flat: this.state.flat,
                depto: this.state.depto,
                between_streets: this.state.betweenstreets,
                postal_code: this.state.postalCode ?? null, 
                state_id: this.state.stateId,
                city_id: this.state.cityId,
                phone: this.state.phone,
                email: this.state.email,
                alternative_phone: this.state.mobilePhone,
                country_business_id: this.state.countryId,
                card_number: Number(this.state.cardNumber) ,
                card_expiration: this.state.cardExpiration ?? null,
                card_code: this.state.cardCode ?? null,
                card_holder: this.state.cardHolder ?? null,
                card_dues: this.state.cardDues ?? null,
                card_approval: this.state.cardApproval ?? null,
                ppal_article_id: this.state.productRefresh,
                payment_state_id: 1,
                status_tango: this.state.statusTango ?? null,
                approval_date_bo: this.state.approvalDateBo ?? null,
                time_zone: this.state.timeZone ?? null,
                sms_sent: false,
                epack: this.state.epack ?? null,
                subarea_id: this.state.subareaId,
                amount_usd: this.state.amountUsd,
                export_hermes: false,
                hermes: null,
                export_bi: false,
                mail_ap_bo: false,
                index_hermes: this.state.indexHermes ?? null,
                wp_notific: false,
                logistic_cost: this.state.logisticCost,
                repurchase_company: false,
                finish_treatment_date: this.state.finishTreatmentDate,
                // occ_id: this.state.idCarritoOcc,
            }
           // console.log(this.state.campaingId)
            // if(!this.state.cardNumber){
            //     this.state.cardNumber = " ";
            // }else{
            //     this.state.cardNumber = Number(shoppingCar.cardNumber);
            // }
            shoppingcarToRest.id_cliente = Number(clientOccId);

            axios.post(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/saveshoppingcar', shoppingCar, {
                headers: headers
            })
            .then((resp) => { 
                //shoppingCar.occ_id = this.state.idCarritoOcc;
               // console.log(this.state.productsSelected.length);
               //console.log(resp)
                let idCarrito = resp.data.data;
                   //localStorage.setItem("carritooccid",idCarrito);
                   shoppingCar.id = idCarrito;
                   //console.log(shoppingCar.idCarrito);
                   //console.log(shoppingCar.id)
                  // this.saveClientAndShoppingCar(idCarrito)
                   //let idCarrito = myText.split("{")[1].split('":')[1].split(`\"`)[1];
                   //console.log(myText.split("{")[1].split('":')[1].split(`\"`)[1])
                   let msg = localStorage.getItem('WspLanguage') == 'language0' ? `Carro de Compras Creado. Id: ${idCarrito}` : `ShoppingCar Created. Id: ${idCarrito}`;
                       toast.success(msg, {
                           position: "top-right",
                           autoClose: 10000,
                           hideProgressBar: false,
                           closeOnClick: true,
                           pauseOnHover: true,
                           draggable: true,
                           progress: undefined,
                       });
                for (let i = 0; i < this.state.productsSelected.length; i++) { 
                    this.setState({
                        buyOrder: resp.data.data,
                        shoppingCarCreationDate: new Date()
                    });
                    let keyId = 0;
                    this.state.productCountriesKey.map(prodKey => {
                        if (prodKey.description == this.state.productsSelected[i].description) {
                            keyId = prodKey.id
                        }
                    });
                    this.state.states = [];
                    this.state.clients = [];
                    this.state.banks = [];
                    this.state.mapCourierCities = [];
                    this.state.products = [];
                    this.state.cities = [];
                    // this.state.resources = [];
                    this.state.objetions = [];
                    this.state.scriptSales = [];
                    localStorage.setItem('DataCheckOutWsp',JSON.stringify(this.state));
                    //keyId= 2;
                    axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getrefreshperformance/'+ this.state.productsSelected[i].description , {
                        headers: headers
                    })
                    .then((ree) => {
                        const datax = ree.data.data;
                        const carDetail = {
                            idcar: resp.data.data,
                            idkey: datax.id,
                            quantity: this.state.productsSelected[i].quantity,
                            unitamount: (Number(this.state.productsSelected[i].price) + ((Number(this.state.productsSelected[i].price) * this.state.interesRate) / 100)),
                            total: ((Number(this.state.productsSelected[i].quantity) * Number(this.state.productsSelected[i].price)) + ((Number(this.state.productsSelected[i].quantity) * Number(this.state.productsSelected[i].price) * this.state.interesRate) / 100)),
                            enabled: true,
                            idshippingkey: this.state.productCountryKeyId
                        }
                        if(this.state.waypayId == 1||this.state.waypayId == 7 ||this.state.waypayId == 13 )
                             {
                                        document.querySelector('#btn-no-sale').style.display = 'none';
                                        document.querySelector('#btnGoBack').style.display = 'none';
                                        document.querySelector('#btnPaymentWebPay').style.display = 'none';
                                        document.querySelector('#btnConfirm').style.display = 'none';
                             } 
                        // console.log(carDetail);
                        axios.post(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/savecardetail', carDetail, {
                            headers: headers
                        })
                        .then((rp) => { 
                            if( this.state.waypayId == 14)
                            {
                                document.querySelector('#btnPaymentWebPay').style.display = 'block';
                                document.querySelector('#btnConfirm').style.display = 'none';
                                document.querySelector('#btnGoBack').style.display = 'none';
    
                            } else {
                                this.state.waypays.map(waypay => {
                                    if (waypay.id == this.state.waypayId) {
                                        
                                        document.querySelector('#btn-no-sale').style.display = 'none';
                                        document.querySelector('#btnGoBack').style.display = 'none';
                                        document.querySelector('#btnPaymentWebPay').style.display = 'none';
                                        document.querySelector('#btnConfirm').style.display = 'none';
                                    }
                                })
                            }
                            helpers.hideLoadingOverlay();
    
                        })
                        .catch((error) => {
                            helpers.hideLoadingOverlay();
                            console.log(error);
                        });
                    })
                    .catch((error) => {
                        console.log(error);
                    });
                    
                }
                                            //if (i == (this.state.productsSelected.length - 1)) {
                       // this.state.productCountriesKey.map(prodKey => {
                           // if (prodKey.id == this.state.productCountryKeyId) {
                            const shipping  = {
                                idcar: resp.data.data,
                                idkey: this.state.shippingCar.id,
                                quantity: 1,
                                unitamount: (Number(this.state.shippingCar.price) + (Number(this.state.shippingCar.price) * this.state.interesRate) / 100),
                                total: (Number(this.state.shippingCar.price) + (Number(this.state.shippingCar.price) * this.state.interesRate) / 100),
                                enabled: true,
                                idshippingkey: this.state.productCountryKeyId
                            }
                            // console.log(carDetail);
                            axios.post(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/savecardetail', shipping, {
                                headers: headers
                            })
                            .then((rp) => { 
                                if( this.state.waypayId == 14)
                                {
                                    document.querySelector('#btnPaymentWebPay').style.display = 'block';
                                    document.querySelector('#btnConfirm').style.display = 'none';
                                    document.querySelector('#btnGoBack').style.display = 'none';

                                }
                                helpers.hideLoadingOverlay();
                                
                                helpers.hideLoadingOverlay();
                            })
                            .catch((error) => {
                                helpers.hideLoadingOverlay();
                                console.log(error);
                            });
                           // }
                        //});
                        
                    //}
                this.setState({
                    orderNumber: resp.data.data,
                    paymentState: localStorage.getItem('WspLanguage') == 'language0' ? 'Pending' : 'Pendiente',
                    orderState: localStorage.getItem('WspLanguage') == 'language0' ? 'Payment Pending' : 'Pendiente de Pago',
                });
                
            // 
                let logStateCarData = {
                    car_id: resp.data.data,
                    car_state_id: this.state.carStateId ?? 1,
                    user_id: this.state.userId,
                    comment: "",
                    file_data: ""
                }

                axios.post(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/savelogstatecar', logStateCarData, {
                    headers: headers
                })
                .then((response) => {
                    // console.log(response.data.data);
                    this.setState({
                        logStateCarId: response.data.data
                    });
                    this.state.states = [];
                    this.state.clients = [];
                    this.state.banks = [];
                    this.state.mapCourierCities = [];
                    this.state.products = [];
                    this.state.cities = [];
                    // this.state.resources = [];
                    this.state.objetions = [];
                    this.state.scriptSales = [];
                    localStorage.setItem('DataCheckOutWsp',JSON.stringify(this.state));
                })
                .catch((error) => {
                    console.log(error)
                })

                axios.post(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/saveshoppingcarocc', {shoppingcarOccData: shoppingcarToRest, token: this.state.tokenClientOcc},{
                    headers: headers
                })
                .then((resp) => {
                     //console.log(resp.data)
                    if (resp.data) {
                        //let myText = resp.data;
                        //console.log(myText)
                        //console.log(myText.split("{")[1].split('":')[1].split(`\"`)[1])
                        //let idCarrito = myText.split("{")[1].split('":')[1].split(`\"`)[1];

                        let idCarrito = resp.data.id_carrito;
                        //console.log(idCarrito)
                        localStorage.setItem("carritooccid", idCarrito);
                        shoppingCar.occ_id = idCarrito;
                        //shoppingCar.occ_id = Number(idCarrito);
                       // console.log(shoppingCar.idCarrito);

                        axios.post(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/saveshoppingcar', shoppingCar, {
                            headers: headers
                        })
                        .then((resp) => { 
                            //console.log(resp)
                        })
                        .catch((error) => {
                            console.log(error);
                            helpers.hideLoadingOverlay();
                        });
                    }
                    /*if(resp.data.errors){
                        if(resp.data.errors[0].client_id){
                            let msg = localStorage.getItem('WspLanguage') == 'language0' ? `El Carro de Compras ya existe. Id: ${resp.data.errors[0].client_id}` : `ShoppingCar Already exist. Id: ${resp.data.errors[0].client_id}`;
                            toast.error(msg, {
                                position: "top-right",
                                autoClose: 10000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                        }
                    }*/
                })
                .catch((error) => {
                    console.log(error)
                });
            })
            .catch((error) => {
                console.log(error);
                helpers.hideLoadingOverlay();
            });

          
          })
        .catch((error) => {
            console.log(error);
            helpers.hideLoadingOverlay();
        });
    }

    onChangeClosingReasonId(e) {
        this.setState({
            closingReasonId: e.target.value
        });
    }

    onContactAgain(e) {
        this.setState({
            contactAgain: e.target.value
        });
    }

    onChangeCommnets(e) {
        this.setState({
            comments: e.target.value
        });
        let countChararter= e.target.value.length;
        //console.log(countChararter)
        let resta = 100 - countChararter;
        //console.log(resta)
        this.state.remainComentaries = resta;
    }

    updateShoppingCarData() {

        helpers.showLoadingOverlay();
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getshoppingcardbyid/' + this.state.orderNumber,
            {
                //headers: headers
            })
            .then(response => {
                helpers.hideLoadingOverlay();
                const data = response.data.data;
                //console.log(data)
                // data.payment_state_id,
                //     orderState: data.car_state_id
                let paymentState = '';
                if (data.payment_state_id == 1) {
                    paymentState = localStorage.getItem('WspLanguage') == 'language0' ? 'Pending' : 'Pendiente';
                }else if (data.payment_state_id == 2) {
                    paymentState = localStorage.getItem('WspLanguage') == 'language0' ? 'Payed' : 'Pagado';
                }if (data.payment_state_id == 5) {
                    paymentState = localStorage.getItem('WspLanguage') == 'language0' ? 'Rejected' : 'Rechazado';
                }

                let orderState = '';
                if (data.car_state_id == 1) {
                    orderState = localStorage.getItem('WspLanguage') == 'language0' ? 'New' : 'Nuevo';
                    document.querySelector('#btn-no-sale').style.display = 'none';
                    document.querySelector('#btnGoBack').style.display = 'none';
                    document.querySelector('#btnPaymentWebPay').style.display = 'none';
                }else if (data.car_state_id == 11) {
                    orderState = localStorage.getItem('WspLanguage') == 'language0' ? 'Canceled' : 'Anulado';
                }if (data.car_state_id == 3) {
                    orderState = localStorage.getItem('WspLanguage') == 'language0' ? 'Rejected' : 'Rechazado';
                }
                
                this.setState({
                    // orderNumber: data.id, 
                    paymentState: paymentState,
                    orderState: orderState,
                    authorizationCode: data.card_approval                   
                });
            })
            .catch(error => {
                helpers.hideLoadingOverlay();
                console.log(error);
            });
    }

    setDataOrderDiv(){
        document.querySelector('#dataOrderDiv').style.display = 'block';
        document.querySelector('#dataDeliveryDiv').style.display = 'none';
        document.querySelector('#dataPurchaseDiv').style.display = 'none';

        document.querySelector("#btnOrderData").classList.add('active');
        document.querySelector("#btnDeliveryData").classList.remove('active');
        document.querySelector("#btnPurchaseData").classList.remove('active');
        
    }

    setDataDeliveryDiv(){
        document.querySelector('#dataOrderDiv').style.display = 'none';
        document.querySelector('#dataDeliveryDiv').style.display = 'block';
        document.querySelector('#dataPurchaseDiv').style.display = 'none';

        document.querySelector("#btnOrderData").classList.remove('active');
        document.querySelector("#btnDeliveryData").classList.add('active');
        document.querySelector("#btnPurchaseData").classList.remove('active');
    }

    setDataPurchaseDiv(){
        document.querySelector('#dataOrderDiv').style.display = 'none';
        document.querySelector('#dataDeliveryDiv').style.display = 'none';
        document.querySelector('#dataPurchaseDiv').style.display = 'block';
        
        document.querySelector("#btnOrderData").classList.remove('active');
        document.querySelector("#btnDeliveryData").classList.remove('active');
        document.querySelector("#btnPurchaseData").classList.add('active');
    }


    SaveNoSale(e) {
        let wksData = JSON.parse(localStorage.getItem('DataWorkspace'));
        
        const data = {
            id: wksData.state.currentElement.id,
            country_id: parseInt(wksData.state.params.id_pais),
            id_agent: parseInt(wksData.state.params.id_agente),
            call_index: wksData.state.params.id_datos ?? "",
            call_id: wksData.state.params.id_llamada,
            did: wksData.state.params.id_campania ?? "",
            id_campania_occ : parseInt(wksData.state.params.id_campania_occ ?? "0"),
            pbx_crm: parseInt(wksData.state.params.id_crm ?? "0"),
            pbx_db: parseInt(wksData.state.params.id_db ?? "0"),
            active_step: parseInt(wksData.state.activeStep ?? "0"),
            closingreason_id: this.state.closingReasonId,
            contact_again: this.state.contactAgain,
            comments: this.state.comments,
            product_id: wksData.state.productId,
            treatment_id: wksData.state.treatmentId,
            client_phone: wksData.state.phone,
            client_name: wksData.state.name,
            city_id: wksData.state.stateId,
            credit_card: wksData.state.hasCreditCard
        };
        // console.log(data)
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': this.state.tokenProducts
        };

        for (let i = 0; i < wksData.state.answersTreatmentQuestions.length; i++){
            const auxiliarData = {
                pbxworkspace_id: wksData.state.currentElement?.id,
                question_id: wksData.state.answersTreatmentQuestions[i].id,
                question_name: wksData.state.answersTreatmentQuestions[i].question,
                answer: wksData.state.answersTreatmentQuestions[i].answer,
                enabled: true
            };
            // console.log(auxiliarData);
            axios.post(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/savequestionanswers', auxiliarData, {
                headers: headers
            })
            .then((response) => { 
                
            })
            .catch((error) => console.log(error));

            
        }
        
        
        helpers.showLoadingOverlay();
        axios.post(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/save', data, {
            headers: headers
        })
        .then((response) => { 
            setTimeout(() => window.location.href = "/no-sale-workspace", 3000)
            
        })
        .catch((error) => console.log(error));
    }

    render() {

        return (<>
            <ToastContainer></ToastContainer>
            <div className="container">
                <div className="row">
                    <div className="p-2 my-1">
                        <Box sx={{ width: '100%' }}>
                            <Stepper activeStep={this.state.activeStep} alternativeLabel>
                                {this.state.steps.map((label) => (
                                    <Step key={label}>
                                        <StepLabel>{label}</StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                        </Box>
                    </div>
                </div>
                {/* <div className="row"> */}
                    <div  className="dis-flex m-0" style={{justifyContent: 'space-between'}}>
                        <div className='ml-1'>
                            <button className='btn-primary p-2' style={{border: 'none', fontSize: '0.9rem', marginRight: '2px'}}  onClick={this.setDataOrderDiv} id="btnOrderData">{localStorage.getItem('WspLanguage') == 'language0' ? "Order Data" : "Datos del Pedido"}</button>
                            <button className='btn-primary p-2' style={{border: 'none', fontSize: '0.9rem', marginRight: '2px'}} onClick={this.setDataDeliveryDiv} id="btnDeliveryData">{localStorage.getItem('WspLanguage') == 'language0' ? "Delivery Data" : "Datos de la Entrega"}</button>
                            <button className='btn-primary p-2' style={{border: 'none', fontSize: '0.9rem', marginRight: '2px'}} onClick={this.setDataPurchaseDiv} id="btnPurchaseData">{localStorage.getItem('WspLanguage') == 'language0' ? "Purchase Data" : "Datos de la Compra"}</button>    
                        </div>
                            <div className="dis-flex pt-2" style={{height: '35px'}}>
                                <h6 className='m-0'>{localStorage.getItem('WspLanguage') == 'language0' ? 'Order Number: ' : 'Número de Pedido: '}</h6>
                                <p className='m-0' style={{fontSize: '0.9rem'}}> &nbsp; {this.state.orderNumber}</p>
                            </div>
                            <div className="dis-flex pt-2" style={{height: '35px'}}>
                                <h6 className='m-0'>{localStorage.getItem('WspLanguage') == 'language0' ? 'Operator: ' : 'Operador: '}</h6>                        
                                <p className='m-0' style={{fontSize: '0.9rem'}}> &nbsp; {this.state.agentName} </p>
                            </div>
                        {/* <div className="dis-flex pt-2" style={{justifyContent: 'space-between'}}>
                            <div className="dis-flex">
                                <h6>{localStorage.getItem('WspLanguage') == 'language0' ? 'Order Number: ' : 'Número de Pedido: '}</h6>
                                <p className='pt-1' style={{fontSize: '0.9rem'}}> &nbsp; {this.state.orderNumber}</p>
                            </div>
                            <div className="dis-flex">
                                <h6>{localStorage.getItem('WspLanguage') == 'language0' ? 'Order Status: ' : 'Estado de Pedido: '}</h6>
                                <p className='pt-1' style={{fontSize: '0.9rem'}}> &nbsp; {this.state.orderState}</p>
                            </div>
                            <div className="dis-flex" margin>
                                <h6>{localStorage.getItem('WspLanguage') == 'language0' ? 'Payment Status: ' : 'Estado de Pago: '}</h6>                        
                                <p className='pt-1' style={{fontSize: '0.9rem'}}> &nbsp; {this.state.paymentState}</p>
                            </div>
                        </div> */}
                    </div>
                    
                {/* </div> */}
                <div id="dataOrderDiv" style={{border: '2px solid rgb(51 99 205)',borderRadius: '5px', height: '420px'}}>
                    <div className="row my-1">
                        {/* <div className="dis-flex ">
                            <h6>{localStorage.getItem('WspLanguage') == 'language0' ? 'Operator: ' : 'Operador: '}</h6>                        
                            <p className='pt-0' style={{fontSize: '0.9rem'}}> &nbsp; {this.state.agentName} </p>
                        </div> */}
                        <div className="col-md-12">
                            <div className="row m-1 pt-3" style={{border: '1px solid #8080806e', borderRadius: '5px'}}>
                                <div className='col-md-6'>
                                    <div className="dis-flex">
                                        <h6>{localStorage.getItem('WspLanguage') == 'language0' ? 'Order Number: ' : 'Número de Pedido: '}</h6>
                                        <p style={{fontSize: '0.9rem'}}> &nbsp; {this.state.orderNumber}</p>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className="dis-flex">
                                        <h6>{localStorage.getItem('WspLanguage') == 'language0' ? 'Order Status: ' : 'Estado de Pedido: '}</h6>
                                        <p style={{fontSize: '0.9rem'}}> &nbsp; {this.state.orderState}</p>
                                    </div>
                                </div>
                                {/* <div className="col-md-6 ">
                                    <div className="card">
                                        <div className="card-header">
                                            <h5>{this.state.resources.CheckOutBuyDetail}</h5>
                                        </div>
                                        <div className="card-body">
                                            <table className='table datatable my-table' aria-labelledby="tabelLabel">
                                                <thead>
                                                    <tr>
                                                    <th>{this.state.resources.Label_Products}</th>
                                                    <th>{this.state.resources.Label_Quantity}</th>
                                                        <th>{this.state.resources.Label_Price}</th>
                                                        <th>{this.state.resources.Label_TotalPrice}</th>
                                                    </tr>
                                                </thead>
                                                <tbody> 
                                                    {this.state.productsSelected.map(prod => (
                                                        <tr>
                                                            <td>{prod.description}</td>
                                                            <td>{prod.quantity}</td>
                                                            <td>${Number((Number(prod.price) + ((Number(prod.price) * this.state.interesRate) / 100)).toFixed(0)).toLocaleString('en-US')}</td>
                                                            <td>${Number((Number(prod.price * prod.quantity) + ((Number(prod.price * prod.quantity) * this.state.interesRate) / 100)).toFixed(0)).toLocaleString('en-US')}</td>
                                                        </tr>
                                                    ))}
                                                    <tr>
                                                        <td>{localStorage.getItem('WspLanguage') == 'language0' ? 'Single Shipment: ' : 'Envío Único: '}</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td> ${Number((Number(this.state.shippingCostMax) + ((Number(this.state.shippingCostMax) * this.state.interesRate) / 100)).toFixed(0)).toLocaleString('en-US')}</td>  
                                                    </tr>
                                                    <tr>
                                                        <td></td>
                                                        <td></td> 
                                                        <td></td>
                                                        <td>
                                                            <div style={{fontSize:'1.2rem'}}>
                                                                <b>Total: ${this.state.total.toLocaleString('en-US')}</b>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div> */}
                                {/* <div className="col-md-6 ">
                                    <div className="card">
                                        <div className="card-header">
                                            <h5>{this.state.resources.Title_WayPay}</h5>
                                        </div>
                                        <div className="card-body">
                                            <div className="form-group mt-2 mb-3">
                                                    <div className="dis-flex">
                                                        <label className=" form-label col-md-3">{this.state.resources.Title_WayPay}</label>
                                                        <select className="form-control col-md-8" data-val="true" name="waypayid" disabled>
                                                            <option value="">{this.state.resources.Label_SelectOption}</option>
                                                            {this.state.waypays.map(waypay =>
                                                                <option key={waypay.id} value={waypay.id} selected={this.state.waypayId == waypay.id}>{waypay.waypay}</option>
                                                            )}
                                                        </select>
                                                    </div>
                                                </div>
                                            <form id="form1">
                                                <div className="form-group my-2">
                                                    <div className="dis-flex">
                                                        <label className="form-label col-md-3">{this.state.resources.Label_Bank}</label>
                                                        <select className="form-control col-md-8" data-val="true" name="bankId" value={this.state.bankId} disabled>
                                                            {this.state.banks.map(bank =>
                                                                <option key={bank.id} value={bank.id} selected={this.state.bankId == bank.id}>{bank.bank}</option>
                                                            )}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="form-group my-2">
                                                    <div className="dis-flex">
                                                        <label className="form-label col-md-3">{this.state.resources.Label_CardNumber}</label>
                                                        <input className="form-control col-md-8" type="number" data-val="true" name="cardNumber" defaultValue={this.state.cardNumber} disabled />
                                                    </div>
                                                </div>
                                                <div className="form-group my-2">
                                                    <div className="dis-flex">
                                                        <label className="form-label col-md-3">{this.state.resources.Label_SecurityCode}</label>
                                                        <input className="form-control col-md-8" type="number" data-val="true" name="securityCode" defaultValue={this.state.securityCode} disabled />
                                                    </div>
                                                </div>
                                                <div className="form-group my-2">
                                                    <div className="dis-flex">
                                                        <label className="form-label col-md-3">{this.state.resources.Label_Expiration}</label>
                                                        
                                                        <input className="mx-1 col-md-2" style={{borderRadius: '5px', border: '1px solid #ced4da'}} type="number" data-val="true" name="expirationMonth" defaultValue={this.state.expirationMonth} disabled />
                                                        /
                                                        <input className="mx-1 col-md-2" style={{borderRadius: '5px', border: '1px solid #ced4da'}} type="number" data-val="true" name="expirationYear" defaultValue={this.state.expirationYear} disabled/>
                                                    </div>
                                                </div>
                                                <div className="form-group my-2">
                                                    <div className="dis-flex">
                                                        <label className="form-label col-md-3">{this.state.resources.Label_Holder}</label>
                                                        <input className="form-control col-md-8"  data-val="true" name="holder" defaultValue={this.state.holder} disabled />
                                                    </div>
                                                </div>
                                            </form>
                                            <form id="form2">
                                                <div className="form-group my-2">
                                                    <div className="dis-flex">
                                                        <label className="form-label col-md-3">{this.state.resources.Label_Bank}</label>
                                                        <select className="form-control col-md-8" data-val="true" name="bankId" defaultValue={this.state.bankId} disabled>
                                                            <option value="">{this.state.resources.Label_SelectOption}</option>
                                                            {this.state.banks.map(bank =>
                                                                <option key={bank.id} value={bank.id} selected={this.state.bankId == bank.id}>{bank.bank}</option>
                                                            )}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="form-group my-2">
                                                    <div className="dis-flex">
                                                        <label className="form-label col-md-3">{this.state.resources.Label_AccountType}</label>
                                                        <select className="form-control col-md-8" data-val="true" name="accountType" disabled>
                                                            <option value="">{this.state.resources.Label_SelectOption}</option>
                                                            <option key={this.state.resources.Label_CurrentAccount} selected={this.state.accountType == this.state.resources.Label_CurrentAccount} value={this.state.resources.Label_CurrentAccount}>
                                                                {this.state.resources.Label_CurrentAccount}
                                                            </option>
                                                            <option key={this.state.resources.Label_RutAccount} selected={this.state.accountType == this.state.resources.Label_RutAccount} value={this.state.resources.Label_RutAccount}>
                                                                {this.state.resources.Label_RutAccount}
                                                            </option>
                                                            <option key={this.state.resources.Label_VistaAccount} selected={this.state.accountType == this.state.resources.Label_VistaAccount} value={this.state.resources.Label_VistaAccount}>
                                                                {this.state.resources.Label_VistaAccount}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="form-group my-2">
                                                    <div className="dis-flex">
                                                        <label className="form-label col-md-3">{this.state.resources.Label_AccountNumber}</label>
                                                        <input className="form-control col-md-8" type="number" data-val="true" name="accountNumber" defaultValue={this.state.accountNumber} disabled/>
                                                    </div>
                                                </div>
                                            </form>   
                                            
                                            <hr></hr>
                                            <div id="duesNumberDiv">
                                                <div className="dis-flex">
                                                    <label><b>{this.state.resources.Label_DuesNumber}: </b> </label>
                                                    <label>&nbsp; {this.state.duesLabel}</label>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="row m-1">
                        <div className="col-md-12">
                            <div className="row p-2" style={{border: '1px solid #8080806e', borderRadius: '5px'}}>
                                <div className='col-md-12'><h6>{localStorage.getItem('WspLanguage') == 'language0' ? 'Client ' : 'Cliente '}</h6></div>
                                <div className="col-md-6">
                                    <div className="form-group my-2">
                                        <label>{this.state.resources.Label_Name}</label>
                                        <input type="text" className="form-control" value={this.state.name} disabled/>
                                    </div> 
                                                
                                    {/* <div className="card">
                                        <div className="card-header">
                                            <h5>{this.state.resources.CheckOutShipmentInfo}</h5>
                                        </div>
                                        <div className="card-body">
                                            <h6>{this.state.resources.Title_Courier}</h6>
                                            <label>{this.state.courierName}</label>
                                            <h6>{this.state.resources.Label_DeliveryTime}</h6>
                                            <label>{this.state.deliveryTime}{localStorage.getItem('WspLanguage') == 'language0' ? ' hours ' : ' horas '}</label>
                                            <hr></hr>
                                            <h6>{this.state.resources.Label_DeliveryDate}</h6>
                                            <input className='form-control col-md-6' type="date" value={this.state.showDate} disabled/>
                                        </div>
                                    </div> */}
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group my-2">
                                        <label>{this.state.resources.Label_LastName}</label>
                                        <input type="text" className="form-control" value={this.state.lastName} disabled />
                                    </div> 
                                    {/* <div className="card">
                                        <div className="card-header">
                                            <h5>{this.state.resources.CheckOutClientInfo}</h5>
                                        </div>
                                        <div className="card-body">
                                            <form className='col-md-12'>
                                                <div className="form-group my-2">
                                                    <label>{this.state.resources.Label_Name}</label>
                                                    <input type="text" className="form-control" value={this.state.name} disabled/>
                                                </div> 
                                                <div className="form-group my-2">
                                                    <label>{this.state.resources.Label_LastName}</label>
                                                    <input type="text" className="form-control" value={this.state.lastName} disabled />
                                                </div> 
                                                <div className="form-group my-2">
                                                    <label>{this.state.resources.Label_Phone}</label>
                                                    <input type="text" className="form-control" value={this.state.phone} disabled/>
                                                </div> 
                                                <div className="form-group my-2">
                                                    <label>{this.state.resources.Label_StateOrProvince}</label>
                                                    <select className="form-control" data-val="true" name="cityName" defaultValue={this.state.stateId} disabled>
                                                        {this.state.states.map(state =>
                                                            <option key={state.id} value={state.id} selected={this.state.stateId == state.id}>{state.name}</option>
                                                        )}
                                                    </select>
                                                </div> 
                                                <div className="form-group my-2">
                                                    <label>{localStorage.getItem('WspLanguage') == 'language0' ? 'City' : 'Ciudad'}</label>
                                                    <input type="text" className="form-control" value={this.state.cityName} disabled />
                                                </div>
                                                <div className="form-group my-2">
                                                    <label>{this.state.resources.Label_Address}</label>
                                                    <input type="text" className="form-control" value={this.state.address + ' ' + this.state.number} disabled/>
                                                </div> 
                                                <div className="form-group my-2">
                                                    <label>{this.state.resources.Label_Floor}</label>
                                                    <input type="text" className="form-control" value={this.state.flat} disabled/>
                                                </div> 
                                                <div className="form-group my-2">
                                                    <label>{this.state.resources.Label_Department}</label>
                                                    <input type="text" className="form-control" value={this.state.depto} disabled/>
                                                </div> 
                                                <div className="form-group my-2">
                                                    <label>{this.state.resources.Label_BetweenStreets}</label>
                                                    <input type="text" className="form-control" value={this.state.betweenstreets} disabled/>
                                                </div> 
                                                <div className="form-group my-2">
                                                    <label>{this.state.resources.Label_Email}</label>
                                                    <input type="text" className="form-control" value={this.state.email} disabled/>
                                                </div> 
                                                <div className="form-group my-2">
                                                    <label>{this.state.resources.Label_InsideComentaries}</label>
                                                    <textarea className="form-control" rows="3"  maxLength="150" value={this.state.internalComments} disabled></textarea>
                                                </div> 
                                            </form>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 my-2 mb-0">
                            <div className="row">
                                <div className='col-md-6 pl-0'>
                                    <div className='p-3' style={{border: '1px solid #8080806e', borderRadius: '5px'}}>
                                        <div className="dis-flex">
                                            <h6>{localStorage.getItem('WspLanguage') == 'language0' ? 'Payment Status: ' : 'Estado de Pago: '}</h6>                        
                                            <p style={{fontSize: '0.9rem'}}> &nbsp; {this.state.paymentState}</p>
                                        </div>
                                        <div className="dis-flex">
                                            <label className=" form-label col-md-3">{this.state.resources.Title_WayPay}</label>
                                            <select className="form-control col-md-8" data-val="true" name="waypayid" disabled>
                                                <option value="">{this.state.resources.Label_SelectOption}</option>
                                                {this.state.waypays.map(waypay =>
                                                    <option key={waypay.id} value={waypay.id} selected={this.state.waypayId == waypay.waypay_id}>{waypay.waypay}</option>
                                                )}
                                            </select>
                                        </div>
                                        <hr></hr>
                                        <div id="duesNumberDiv">
                                            <div className="dis-flex">
                                                <label><b>{this.state.resources.Label_DuesNumber}: </b> </label>
                                                <label>&nbsp; {this.state.duesLabel}</label>
                                            </div>
                                        </div>
                                        <div className="dis-flex" id="authCodeDiv">
                                            <label><b>{localStorage.getItem('WspLanguage') == 'language0' ? "Authorization Code" : "Código de Autorización"}: </b> </label>
                                            <label>&nbsp; {this.state.authorizationCode}</label>
                                        </div>
                                        <div className="dis-flex" id="cardNumberDiv">
                                            <label><b>{localStorage.getItem('WspLanguage') == 'language0' ? "Card Number" : "Numero de Tarjeta"}: </b> </label>
                                            <label>&nbsp; {this.state.cardNumber}</label>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-6 pr-0'>
                                    <div className='p-3' style={{border: '1px solid #8080806e', borderRadius: '5px'}}>
                                        <div className='py-1'>
                                            <label><b>{this.state.resources.Title_Courier}: </b></label>
                                            <label>&nbsp;{this.state.courierName}</label>
                                        </div>
                                        <div className='py-1'>
                                            <label><b>{this.state.resources.Label_DeliveryTime}: </b></label>
                                            <label>&nbsp;{this.state.deliveryTime}{localStorage.getItem('WspLanguage') == 'language0' ? ' hours ' : ' horas '}</label>
                                        </div>
                                        <hr></hr>
                                        <label><b>{this.state.resources.Label_DeliveryDate}</b></label>
                                        <input className='form-control col-md-6' type="date" value={this.state.showDate} disabled/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="dataDeliveryDiv" style={{border: '2px solid rgb(51 99 205)',borderRadius: '5px', height: '420px'}}>
                    <div className='row col-md-12'>
                        <div className="form-group my-1 col-md-6">
                            <label>{this.state.resources.Label_Address}</label>
                            <input type="text" className="form-control" value={this.state.address + ' ' + this.state.number} disabled/>
                        </div> 
                        <div className="form-group my-1 col-md-6">
                            <label>{this.state.resources.Label_Floor}</label>
                            <input type="text" className="form-control" value={this.state.flat} disabled/>
                        </div> 
                        <div className="form-group my-1 col-md-6">
                            <label>{this.state.resources.Label_Department}</label>
                            <input type="text" className="form-control" value={this.state.depto} disabled/>
                        </div> 
                        <div className="form-group my-1 col-md-6">
                            <label>{this.state.resources.Label_BetweenStreets}</label>
                            <input type="text" className="form-control" value={this.state.betweenstreets} disabled/>
                        </div> 
                        <div className="form-group my-1 col-md-6">
                            <label>{localStorage.getItem('WspLanguage') == 'language0' ? 'City' : 'Ciudad'}</label>
                            <input type="text" className="form-control" value={this.state.cityName} disabled />
                        </div>
                        <div className="form-group my-1 col-md-6">
                            <label>{this.state.resources.Label_StateOrProvince}</label>
                            <select className="form-control" data-val="true" name="cityName" defaultValue={this.state.stateId} disabled>
                                {this.state.states.map(state =>
                                    <option key={state.id} value={state.id} selected={this.state.stateId == state.id}>{state.name}</option>
                                )}
                            </select>
                        </div> 
                        <div className="form-group my-1 col-md-6">
                            <label>{this.state.resources.Label_Phone}</label>
                            <input type="text" className="form-control" value={this.state.phone} disabled/>
                        </div> 
                        <div className="form-group my-1 col-md-6">
                            <label>{this.state.resources.Label_Email}</label>
                            <input type="text" className="form-control" value={this.state.email} disabled/>
                        </div> 
                        <div className="form-group my-1 col-md-6">
                            <label>{this.state.resources.Label_InsideComentaries}</label>
                            <textarea className="form-control" rows="3"  maxLength="150" value={this.state.internalComments} disabled></textarea>
                        </div>
                    </div>
                </div>
                <div id="dataPurchaseDiv" style={{border: '2px solid rgb(51 99 205)',borderRadius: '5px', height: '420px'}}>
                    <h6 className='pl-3'>{localStorage.getItem('WspLanguage') == 'language0' ? 'Purchase' : 'Compra'}</h6>
                    <div className='col-md-6'>
                        <table className='table datatable my-table' aria-labelledby="tabelLabel">
                            <thead>
                                <tr style={{padding: 20}}>
                                <th>{this.state.resources.Label_Products}</th>
                                <th style={{textAlign: 'right'}}>{this.state.resources.Label_Quantity}</th>
                                    <th style={{textAlign: 'right'}}>{this.state.resources.Label_Price}</th>
                                    <th style={{textAlign: 'right', paddingRight: 40}}>{this.state.resources.Label_TotalPrice}</th>
                                </tr>
                            </thead>
                            <tbody> 
                                {this.state.productsSelected.map(prod => (
                                    <tr>
                                        <td>{prod.description}</td>
                                        <td style={{textAlign: 'right'}}>{prod.quantity}</td>
                                        <td style={{textAlign: 'right'}}>${Number((Number(prod.price) + ((Number(prod.price) * this.state.interesRate) / 100)).toFixed(0)).toLocaleString('en-US')}</td>
                                        <td style={{textAlign: 'right', paddingRight: 40}}>${Number((Number(prod.price * prod.quantity) + ((Number(prod.price * prod.quantity) * this.state.interesRate) / 100)).toFixed(0)).toLocaleString('en-US')}</td>
                                    </tr>
                                ))}
                                <tr>
                                    <td>{localStorage.getItem('WspLanguage') == 'language0' ? 'Single Shipment: ' : 'Envío Único: '}</td>
                                    <td></td>
                                    <td></td>
                                    <td style={{textAlign: 'right', paddingRight: 40}}> ${Number((Number(this.state.shippingCostMax) + ((Number(this.state.shippingCostMax) * this.state.interesRate) / 100)).toFixed(0)).toLocaleString('en-US')}</td>  
                                </tr>
                                <tr>
                                    <td></td>
                                    <td></td> 
                                    <td></td>
                                    <td>
                                        <div style={{fontSize:'1.2rem'}}>
                                            <b>Total: ${this.state.total.toLocaleString('en-US')}</b>
                                        </div>
                                    </td>
                                </tr>
                                
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="row my-1">
                    <div className="mx-auto mb-5">
                        <div className="col-md-4 dis-flex mx-auto">
                            <NoSale data={this.state}/>
                            <div>
                                <button className="btn btn-secondary m-2 px-3" style={{width:100, padding:5}} id="btnGoBack" onClick={this.onCancel}>{this.state.resources.Label_GoBack}</button>
                            </div>
                            <div>
                                <button className="btn btn-success m-2 px-3" style={{width:100, padding:5}} id="btnConfirm" onClick={this.onConfirm}>{this.state.resources.ButtonLabel_Confirm}</button>
                            </div>
                            
                            <OpenPaymentWebPay values={this.state}/>
                            <WindowOpener
                        url={this.state.urlWebPay + `commerceId=1&buyOrder=${this.state.buyOrder}&sessionId=${this.state.sessionId}&commerceCode=${this.state.commerceCode}&amount=${(Number(this.state.total))}&successUrl=${this.state.successUrl}&failureUrl=${this.state.failureUrl}&nulledUrl=${this.state.nulledUrl}`}
                        bridge={this.sonResponse}
                            >
                        Open Browser
                        </WindowOpener>
                        </div>
                    </div>
                </div>
            </div>
            </>
        );
    }

    async getResources() {
        const resourceVars = [];
        resourceVars.push("Label_SelectOption");
        resourceVars.push("Label_Name");
        resourceVars.push("Label_LastName");
        resourceVars.push("Label_PostalCode");
        resourceVars.push("Title_City");
        resourceVars.push("Label_Address");
        resourceVars.push("Label_Country");
        resourceVars.push("Label_StateOrProvince");
        resourceVars.push("Label_DocNumber");
        resourceVars.push("Label_DateOfBirth");
        resourceVars.push("Label_Phone");
        resourceVars.push("Label_MobilePhone");
        resourceVars.push("Label_Email");
        resourceVars.push("Label_Description");
        resourceVars.push("Label_Enabled");
        resourceVars.push("Label_Success");
        resourceVars.push("ButtonLabel_Save");
        resourceVars.push("ButtonLabel_Cancel");
        resourceVars.push("Label_Error");
        resourceVars.push("ErrorMessage_RequiredField");
        resourceVars.push("ErrorMessage_DuplicatedName");
        resourceVars.push("ErrorMessage_UnknownError");
        resourceVars.push("ErrorMessage_AssociatedEntity_SubArea_User");
        resourceVars.push("SuccessMessage_DataSavedSuccessfully");
        resourceVars.push("Label_GoBack");
        resourceVars.push("Title_Clients");
        resourceVars.push("Label_Creation");
        resourceVars.push("Label_DocumentType");
        resourceVars.push("Workspace_Next");
        resourceVars.push("Label_NoSale");
        resourceVars.push("Label_Sex");
        resourceVars.push("Label_Male");
        resourceVars.push("Label_Female");
        resourceVars.push("Label_SearchClient");
        resourceVars.push("Label_HistoryClient");
        resourceVars.push("Label_Info");
        resourceVars.push("Label_NoMail");
        resourceVars.push("Label_Number");
        resourceVars.push("Label_Floor");
        resourceVars.push("Label_Department");
        resourceVars.push("Label_CourierComentaries");
        resourceVars.push("Label_InsideComentaries");
        resourceVars.push("Label_DocsRequired");
        resourceVars.push("ButtonLabel_Select");
        resourceVars.push("Label_NoRows");
        resourceVars.push("Title_Objetions");
        resourceVars.push("Title_ScriptSales");
        resourceVars.push("Label_Price");
        resourceVars.push("Label_AddProduct");
        resourceVars.push("Label_Quantity");
        resourceVars.push("Label_Products");
        resourceVars.push("Label_Presentation");
        resourceVars.push("Label_Actions");
        resourceVars.push("Label_TotalPrice");
        resourceVars.push("Label_Comments");
        resourceVars.push("Label_NoSaleReason");
        resourceVars.push("Label_ContactAgain");
        resourceVars.push("Title_WayPay");
        resourceVars.push("Label_DuesNumber");
        resourceVars.push("Label_InterestRate");
        resourceVars.push("Label_Bank");
        resourceVars.push("Label_CardNumber");
        resourceVars.push("Label_SecurityCode");
        resourceVars.push("Label_Expiration");
        resourceVars.push("Label_Holder");
        resourceVars.push("Label_AccountType");
        resourceVars.push("Label_CurrentAccount");
        resourceVars.push("Label_AccountNumber");
        resourceVars.push("Label_VistaAccount");
        resourceVars.push("Label_RutAccount");
        resourceVars.push("Label_Dues");
        resourceVars.push("Label_Due");
        resourceVars.push("Error_ClientWsp");
        resourceVars.push("CheckOutBuyDetail");
        resourceVars.push("CheckOutShipmentInfo");
        resourceVars.push("CheckOutClientInfo");
        resourceVars.push("ButtonLabel_Confirm");
        resourceVars.push("Label_DeliveryDate");
        resourceVars.push("Title_Courier");
        resourceVars.push("Label_DeliveryTime");
        resourceVars.push("Label_BetweenStreets");
        resourceVars.push("Label_Chararter");
        resourceVars.push("Label_Remain");
        
        const headersLang = {
            'Content-Type': 'application/json'
        };

        let wspLanguage = localStorage.getItem('WspLanguage');

        if (wspLanguage == 'language1') {
            this.state.steps[0] = 'Inicio'
            this.state.steps[1] = 'Cliente'
            this.state.steps[2] = 'Pedido'
            this.state.steps[3] = 'Pago'
        } else {
            this.state.steps[0] = 'Start'
            this.state.steps[1] = 'Client'
            this.state.steps[2] = 'Order'
            this.state.steps[3] = 'Payment'
        }
        helpers.showLoadingOverlay();
        axios.post(window.config.REACT_APP_SECURITY_API_URL + '/languageresource/getbyvars/' + wspLanguage, resourceVars, {
            headers: headersLang
        })
        .then((response) => {
            const data = response.data.data;
            this.setState({ resources: data});
            // helpers.hideLoadingOverlay();
        })
        .catch((error) => {
            console.log(error);
            // this.setState({ loading: false });
            // helpers.hideLoadingOverlay();
        });
        
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': this.state.tokenProducts
        };

        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getproducts',
            {
                'headers': headers
            })
            .then(response => {
                const data = response.data.data;
                this.setState({
                    products: data 
                });
                this.state.pruebaOccid = data.occ_id;
                
                let dataWsp = JSON.parse(localStorage.getItem("DataWorkspace"));
                let dataOrder = JSON.parse(localStorage.getItem("DataOrderWsp"));
                helpers.hideLoadingOverlay();
                this.state.products.map(product => {
                    if (product.id == dataWsp.state.productId) {
                        let supplierId = product.supplier_id;
                        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getsellercommerceidbysupplier/' + supplierId,
                        {
                            'headers': headers
                        })
                        .then(response => {
                            const data = response.data.data;
                            this.setState({
                                commerceCode: data.commerce_id
                            });
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                    }
                });
                //ppalArticleID
                let filterArr = [];
                dataOrder.productsSelected.map(product => {
                    let prod = {
                        description: product.description,
                        total: Number(product.quantity) * Number(product.price)
                    }
                    filterArr.push(prod);
                });
                let prodMayor = filterArr.sort((a, b) => (a.total < b.total) ? 1 : -1)[0];
                //console.log(this.state.products)
                // dataWsp.state.productCountriesKey.map(prodKey => {
                //     if (prodKey.description == prodMayor.description) {
                //         this.setState({
                //             ppalArticleId: prodKey.id
                //         });
                //         this.state.ppalArticleId = prodKey.id;
                        // data.state.productCountries.map(prodCountry => { 
                this.state.Evaluacion =
                            this.state.products.map(proc => { 
                            if (this.state.productbugRefresh.description.toLowerCase().includes(proc.name.toLowerCase())) {
                                //console.log(proc);
                                axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getproductpaymentbyid/' + proc.payment_id,
                                    {
                                        'headers': headers
                                    })
                                    .then(response => {
                                        this.setState({
                                            successUrl: response.data.data.success_url,
                                            failureUrl: response.data.data.fail_url,
                                            nulledUrl: response.data.data.nulled_url
                                        });
                                     })
                                    .catch(function (error) {
                                        console.log(error);
                                    });
                                
                                axios.get(window.config.REACT_APP_PRODUCTS_API_URL + `/PbxWorkspace/getproductpaymentwaypaybywaypay/${proc.payment_id}/${this.state.waypayId}`,
                                    {
                                        'headers': headers
                                    })
                                    .then(response => {
                                        const data = response.data.data;
                                        this.setState({
                                            paymentStateId: data.initial_payment_state_id,
                                            carStateId: data.initial_shoppingcar_state_id
                                        });
                                     })
                                    .catch(function (error) {
                                        console.log(error);
                                    });
                                axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/geturlWebPay/'+ proc.payment_id ,
                                    {
                                        'headers': headers
                                    })
                                    .then(response => {
                                        const data = response.data.data;
                                        this.setState({
                                            urlWebPay: data.payment_url,
                                        });
                                    })
                                    .catch(error => {
                                        console.log(error);
                                    });
                                }
                            });
                        
                        // });
                    //}
                //});

                this.state.products.map(prod => {
                    if(prodMayor.description.toLowerCase().includes(prod.name.toLowerCase())){
                        if(prod.treatment_id > 0){
                            this.state.productsSelected.map(selected => {
                                if(selected.description == prodMayor.description){
                                    let totalDaysTreatment = Number(prod.duration) * selected.quantity;
                                    this.state.totalDaysTreatment = totalDaysTreatment;
                                }
                             });
                        }
                    }
                });
            })
            .catch(function (error) {
                console.log(error);
            });

        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getproductscountry',
            {
                'headers': headers
            })
            .then(response => {
                const data = response.data.data;
                this.setState({
                    productCountries: data
                });
            })
            .catch(function (error) {
                console.log(error);
            });

        
    }
}

function NoSale(values) {
  const [open, setOpen] = React.useState(false);
  const [closingReasonId, setClosingReasonId] = React.useState(0);
  const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
    };
    let loading = true;
  
    const onNosale = () => {
        this.SaveNoSale();
        handleClose();
    }

   const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };
    
  return (
    <div>
        <div>
        <button onClick={handleOpen} className="m-2 px-3 btn btn-danger" style={{width:100, padding:5}} id="btn-no-sale">{values.data.resources.Label_NoSale}</button>
        </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2" className='text-center'>
            {values.data.resources.Label_NoSale}
          </Typography>
          <hr></hr>
          
            <div className="dis-flex">
                <div className="col-md-6">
                    <label>{values.data.resources.Label_NoSaleReason}</label>
                    <select className="form-control col-md-12" data-val="true" name="closingReasonId" defaultValue={values.data.closingReasonId} onChange={this.onChangeClosingReasonId} > {/** */}
                        <option value="">{values.data.resources.Label_SelectOption}</option>
                        {values.data.closingReasons.map(closing =>
                            closing.enabled &&
                            <option key={closing.id} value={closing.id}>
                                {closing.description}
                            </option>
                        )}
                    </select>
                </div>
                
                <div className="col-md-6">
                    <label>{values.data.resources.Label_ContactAgain}</label>
                    <input type="datetime-local"  className="form-control col-md-12" onChange={this.onContactAgain}/>
                </div>
            </div>
            <div className="m-2 p-1">
                <label>{values.data.resources.Label_Comments}</label>
                <textarea className="form-control col-md-12" maxLength={100} onChange={this.onChangeCommnets}></textarea>
                <div className="row m-0">
                        <div className="col-md-6">
                        </div>
                        <div className="col-md-6" style={{textAlign:'right' }}>
                            {
                                this.state.comments.charAt(0) && <label id="lblComentaries"> {this.state.resources.Label_Remain} {this.state.remainComentaries} {this.state.resources.Label_Chararter} </label>
                            }
                            
                        </div>
                </div>
            </div>
            <div className="text-center">
                <button className="btn btn-primary" disabled={this.state.closingReasonId < 1} onClick={onNosale}>
                    {values.data.resources.ButtonLabel_Save}
                </button>
            </div>
                  
        </Box>
      </Modal>
    </div>
  );
}


//PAYMENT WebPay
function OpenPaymentWebPay(values) {
    const [open, setOpen] = React.useState(false);
    let total = this.state.total;//(Number(this.state.total) + ((Number(this.state.total) * this.state.interesRate) / 100));
    const [urlIframe, setUrlIframe] = React.useState(this.state.urlWebPay + `commerceId=1&buyOrder=${this.state.buyOrder}&sessionId=${this.state.sessionId}&commerceCode=${this.state.commerceCode}&amount=${total.toFixed(0)}&successUrl=${this.state.successUrl}&failureUrl=${this.state.failureUrl}&nulledUrl=${this.state.nulledUrl}`);
    
    const handleOpen = () => {
        setOpen(true);
        setUrlIframe(this.state.urlWebPay + `commerceId=1&buyOrder=${this.state.buyOrder}&sessionId=${this.state.sessionId}&commerceCode=${this.state.commerceCode}&amount=${total.toFixed(0)}&successUrl=${this.state.successUrl}&failureUrl=${this.state.failureUrl}&nulledUrl=${this.state.nulledUrl}`);
        
        setTimeout(() => {
            setUrlIframe(this.state.urlWebPay + `commerceId=1&buyOrder=${this.state.buyOrder}&sessionId=${this.state.sessionId}&commerceCode=${this.state.commerceCode}&amount=${total.toFixed(0)}&successUrl=${this.state.successUrl}&failureUrl=${this.state.failureUrl}&nulledUrl=${this.state.nulledUrl}`);
        }, 1000);
        
        // this.setState({
        //     orderNumber: resp.data.data,
        //     paymentState: localStorage.getItem('WspLanguage') == 'language0' ? 'Payed' : 'Pagado',
        //     orderState: localStorage.getItem('WspLanguage') == 'language0' ? 'New' : 'Nuevo',
        // });
    };
    const handleClose = () => {
        this.updateShoppingCarData();
        setOpen(false);
       
    };
    let loading = true;
  
    // const onNosale = () => {
    //     // this.SaveNoSale();
    //     handleClose();
    // }

    const onPay = () => {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': this.state.tokenProducts
        };
        setUrlIframe(this.state.urlWebPay + `commerceId=1&buyOrder=${this.state.buyOrder}&sessionId=${this.state.sessionId}&commerceCode=${this.state.commerceCode}&amount=${total.toFixed(0)}&successUrl=${this.state.successUrl}&failureUrl=${this.state.failureUrl}&nulledUrl=${this.state.nulledUrl}`);
        let clientData = {
            name: this.state.name,
            lastname: this.state.lastName,
            document_typeid: this.state.clientDocumentTypeId,
            document_number: this.state.clientDocumentNumber,
            date_birth: this.state.clientDateBirth,
            sex: this.state.sex,
            personal_phone: Number(this.state.mobilePhone),
            work_phone: Number(this.state.phone),
            email: this.state.email ?? '',
            //email_no: this.state.haventEmail,
            direction: this.state.address,
            number: this.state.number,
            flat: this.state.flat,
            depto: this.state.depto,
            between_street: this.state.betweenstreets ?? "",
            postal_code: this.state.postalCode,
            countriesid: this.state.countryId,
            cityid: this.state.cityId,
            stateid: this.state.stateId,
            // creation_user_id:
            // last_update_user_id:
            enabled: true
        }
        this.state.clients.map((client) => { 
            //let localclientid = JSON.parse(localStorage.getItem('DataClientWsp'));
            if (client.document_typeid == this.state.clientDocumentTypeId && client.document_number == this.state.clientDocumentNumber) { 
                clientData.id = client.id;
                clientData.last_update_user_id = this.state.userId;
            } else {
                clientData.creation_user_id = this.state.userId;
            }
             
        });

        helpers.showLoadingOverlay();
        axios.post(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/saveclient', clientData, {
            headers: headers
        })
        .then((response) => {
            
            const shoppingCar = {
                // id: ,
                // creation_date: ,
                unix_creation_date: Date.parse(new Date(Date.now())),
                client_id: response.data.data,
                car_state_id: this.state.carStateId ?? 1,
                // update_car_state_date: ,
                payment_state_id: 1,
                courier_id: this.state.courierId,
                waypay_id: this.state.waypayId,
                courier_comments: this.state.courierComments,
                internal_comments: this.state.internalComments,
                user_operator_id: this.state.userId,
                campaing_id: Number(this.state.campaingId),
                total: (Number(this.state.total) + ((Number(this.state.total) * this.state.interesRate) / 100)),
                invoice_number: null,
                billing_date: null,
                lot_id: null,
                lot_state_id: null,
             //   delivery_time: this.state.deliveryTime,
                credit_note: false,
                credit_note_number: null,
                annulment_date: null,
                dolar_quote: this.state.changeRate,
                usd_total: (this.state.total / this.state.changeRate).toFixed(3),
                delivery_date: this.state.actualDate,
                delivered: null,
                delivery_cost: this.state.shippingCostMax,
                address: this.state.address,
                address_number: this.state.number,
                flat: this.state.flat,
                depto: this.state.depto,
                between_streets: this.state.betweenstreets,
                postal_code: this.state.postalCode ?? null, 
                state_id: this.state.stateId,
                city_id: this.state.cityId,
                phone: this.state.phone,
                email: this.state.email,
                alternative_phone: this.state.mobilePhone,
                country_business_id: this.state.countryId,
                card_number: this.state.cardNumber,
                card_expiration: this.state.cardExpiration ?? null,
                card_code: this.state.cardCode ?? null,
                card_holder: this.state.cardHolder ?? null,
                card_dues: this.state.cardDues ?? null,
                card_approval: this.state.cardApproval ?? null,
                ppal_article_id: this.state.productRefresh,
                status_tango: this.state.statusTango ?? null,
                approval_date_bo: this.state.approvalDateBo ?? null,
                time_zone: this.state.timeZone ?? null,
                sms_sent: false,
                epack: this.state.epack ?? null,
                subarea_id: this.state.subareaId,
                amount_usd: this.state.amountUsd,
                export_hermes: false,
                hermes: null,
                export_bi: false,
                mail_ap_bo: false,
                index_hermes: this.state.indexHermes ?? null,
                wp_notific: false,
                logistic_cost: this.state.logisticCost,
                repurchase_company: false,
                finish_treatment_date: this.state.finishTreatmentDate

            };
            //console.log(this.state.campaingId)
            axios.post(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/saveshoppingcar', shoppingCar, {
                headers: headers
            })
            .then((resp) => { 
                //console.log(resp)
                for (let i = 0; i < this.state.productsSelected.length; i++) { 
                    this.setState({
                        buyOrder: resp.data.data,
                        shoppingCarCreationDate: new Date()
                    });
                    let keyId = 0;
                    this.state.productCountriesKey.map(prodKey => {
                        if (prodKey.description == this.state.productsSelected[i].description) {
                            keyId = prodKey.id
                        }
                    });
                    localStorage.setItem('DataCheckOutWsp',JSON.stringify(this.state));
                    const carDetail = {
                        idcar: resp.data.data,
                        idkey: keyId,
                        quantity: this.state.productsSelected[i].quantity,
                        unitamount: (Number(this.state.productsSelected[i].price) + ((Number(this.state.productsSelected[i].price) * this.state.interesRate) / 100)),
                        total: ((Number(this.state.productsSelected[i].quantity) * Number(this.state.productsSelected[i].price)) + ((Number(this.state.productsSelected[i].quantity) * Number(this.state.productsSelected[i].price) * this.state.interesRate) / 100)),
                        enabled: true,
                        idshippingkey: this.state.productCountryKeyId
                    }
                    // console.log(carDetail);
                    axios.post(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/savecardetail', carDetail, {
                        headers: headers
                    })
                    .then((rp) => { 
                        if(this.state.waypayId == 7 || this.state.waypayId == 14)
                        {
                            // document.getElementById("btnWebPay").click();
                            // document.getElementById("openWayPay").click();
                            handleOpen();
                        }
                        helpers.hideLoadingOverlay();

                    })
                    .catch((error) => {
                        helpers.hideLoadingOverlay();
                        console.log(error);
                    });
                    if (i == (this.state.productsSelected.length - 1)) {
                        this.state.productCountriesKey.map(prodKey => {
                            if (prodKey.id == this.state.productCountryKeyId) {
                               const shipping  = {
                                    idcar: resp.data.data,
                                    idkey: prodKey.id,
                                    quantity: 1,
                                    unitamount: (Number(prodKey.price) + (Number(prodKey.price) * this.state.interesRate) / 100),
                                    total: (Number(prodKey.price) + (Number(prodKey.price) * this.state.interesRate) / 100),
                                    enabled: true,
                                    idshippingkey: this.state.productCountryKeyId
                                }
                                // console.log(carDetail);
                                axios.post(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/savecardetail', shipping, {
                                    headers: headers
                                })
                                .then((rp) => { 
                                    if(this.state.waypayId == 7 || this.state.waypayId == 14)
                                    {
                                        // document.getElementById("btnWebPay").click();
                                        // document.getElementById("openWayPay").click();
                                        handleOpen();
                                        // document.write(this.state.urlWebPay + `commerceId=1&buyOrder=${this.state.buyOrder}&sessionId=${this.state.sessionId}&commerceCode=${this.state.commerceCode}&amount=${(Number(this.state.total) + Number(this.state.shippingCostMax))}&successUrl=${this.state.successUrl}&failureUrl=${this.state.failureUrl}&nulledUrl=${this.state.nulledUrl}`);
                                        // window.location.href = this.state.urlWebPay + `commerceId=1&buyOrder=${this.state.buyOrder}&sessionId=${this.state.sessionId}&commerceCode=${this.state.commerceCode}&amount=${(Number(this.state.total) + Number(this.state.shippingCostMax))}&successUrl=${this.state.successUrl}&failureUrl=${this.state.failureUrl}&nulledUrl=${this.state.nulledUrl}`
                                    }
                                    helpers.hideLoadingOverlay();

                                })
                                .catch((error) => {
                                    helpers.hideLoadingOverlay();
                                    console.log(error);
                                });
                            }
                        });
                        
                    }
                }
            })
            .catch((error) => {
                console.log(error);
                helpers.hideLoadingOverlay();
            });
        })
        .catch((error) => {
            console.log(error);
            helpers.hideLoadingOverlay();
        });
    }

   const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        height: '90%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };
    
  return (
    <div>
          {/* onPay*/}
        <button id="btnPaymentWebPay" className="btn btn-success px-3 m-1 mx-2" onClick={handleOpen}>{localStorage.getItem('WspLanguage') == 'language0' ? 'Pay' : 'Pagar'}</button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
            {/* <p>{urlIframe}</p> */}
            <iframe style={{ width: '100%', height: '90%' }} src={urlIframe}></iframe>
        </Box>
        
      </Modal>
    </div>
    );
}
      