import React, { useRef } from 'react';
 import { Editor } from '@tinymce/tinymce-react';

 export default function EditorHtml(dataVal) {
   const editorRef = useRef(null);
   let disabled = true;
   if (dataVal.disabled || dataVal.viewMode) {
     disabled = true;
   } else {
     disabled = false;
   }
  //  console.log(dataVal);
  
   const log = () => {
     if (editorRef.current) {
       //console.log(editorRef.current.getContent());
       if (localStorage.getItem('data-detail')) {
         localStorage.removeItem('data-detail');
       }
       localStorage.setItem('data-detail', editorRef.current.getContent());
      //  console.log(localStorage.getItem('data-detail'));
     }
   };
   return (
     <>
       <Editor
         disabled={disabled}
         onInit={(evt, editor) => editorRef.current = editor}
         initialValue={dataVal.value ? dataVal.value : "<html></html>"}
         apiKey="d8ub05a7oejoqe012boml27d7g5gh5xrmq9vmoqbof3og41a"
         init={{
           height: 400,
           menubar: true,
           plugins: [
             'advlist autolink lists link image charmap print preview anchor',
             'searchreplace visualblocks code fullscreen',
             'insertdatetime media table paste code help wordcount'
           ],
           toolbar: 'undo redo | formatselect | ' +
           'bold italic backcolor | alignleft aligncenter ' +
           'alignright alignjustify | bullist numlist outdent indent | ' +
           'removeformat | help',
           content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
         }}
       />
       <button className="btn btn-outline-danger" disabled={disabled} onClick={log}>Save Content</button>
     </>
   );
 }