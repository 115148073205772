import React, { Component, useCallback } from 'react';
import axios from 'axios';
import helpers from '../../Helpers';
import $ from 'jquery';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { styled } from '@mui/material/styles';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Button from '@mui/material/Button';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { RemoveShoppingCart } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { maxWidth } from '@mui/material/node_modules/@mui/system';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart';
import { render } from 'react-dom';
import debounce from 'lodash.debounce';
import Badge from '@material-ui/core/Badge';
import { withStyles } from '@material-ui/core/styles';
import { isConstructorDeclaration } from 'typescript';

const Swal = require('sweetalert2')

export class OrderWsp extends Component {
    static displayName = OrderWsp.name;

    constructor(props) {
        super(props);
        this.onChangeProduct = this.onChangeProduct.bind(this);
        this.onChangeState = this.onChangeState.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.removeCarItem = this.removeCarItem.bind(this);
        this.addCarItem=this.addCarItem.bind(this);
        this.subtractCarItem=this.subtractCarItem.bind(this);
        this.onChangeClosingReasonId = this.onChangeClosingReasonId.bind(this);
        this.onContactAgain = this.onContactAgain.bind(this);
        this.onChangeCommnets = this.onChangeCommnets.bind(this);
        this.SaveNoSale = this.SaveNoSale.bind(this);
        this.onChangeCreditCard = this.onChangeCreditCard.bind(this);
        this.goToPayment = this.goToPayment.bind(this);
        this.onShowKits  = this.onShowKits.bind(this);
        this.onShowCrossSell = this.onShowCrossSell.bind(this);
        ProductListModal = ProductListModal.bind(this);
        NoSale = NoSale.bind(this);

        this.setInfoProduct = this.setInfoProduct.bind(this);
        this.setInfoObjScript = this.setInfoObjScript.bind(this);

        this.state = {
            steps: ['Inicio', 'Cliente', 'Pedido', 'Pago', 'Checkout'],
            activeStep: 2,
            resources: [],
            products: [],
            countryId: JSON.parse(localStorage.getItem('DataWorkspace')).state.countryId,
            countryName: '',
            documentType: [],
            documentTypeId: 0,
            states: [],
            stateId: 0,
            cities: [],
            cityId: 0,
            clients: [],
            clientId: null,
            productId: 0,
            name: '',
            lastName: '',
            phone: '',
            mobilePhone: '',
            documentNumber: '',
            sex: '',
            dateOfBirth: '',
            email: '',
            haventMail: '',
            adress: '',
            number: 0,
            floor: '',
            department: '',
            postalCode: '',
            courierComentaries: '',
            insideComentaries: '',
            objetions: [],
            scriptSales: [],
            productCountries: [],
            productCountryId: null,
            productCountriesKey: [],
            productCountryKeyId: null,
            productCountryKeyName: '',
            productsSelected: [],
            productInfo: '',
            total: 0,
            closingReasons: [],
            closingReasonId: 0,
            treatmentQuestions: [],
            mapCourierCities: [],
            phone: 0,
            name: '',
            answersTreatmentQuestions: [],
            language: '',
            comments: '',
            contactAgain: null,
            hasCreditCard: false,
            shippingCostMax: 0,
            kitProducts: [],
            kitProductDetails: [],
            kitsArrayFiltered: [],
            showKits: false,
            showCrossSell: false,
            filterActive: false,
            filteringProds: "",
            productsCount: 0,
            tokenProducts: "",
            scriptFounded: false,
            userId: 0,
            courierProductCountryId: 0,
        };
    }

    componentDidMount() {
        document.querySelector('#btnInfoProduct').classList.add('active');
        document.querySelector("#productError").style.display = 'none';
        document.querySelector("#errorBtnPreSale").style.display = 'none';
        let data = JSON.parse(localStorage.getItem('DataWorkspace'));
        // console.log('datask');
        // console.log(data);
        this.setState({
            countryId: data.state.countryId,
            productId: data.state.productId,
            userId: data.state.userId,
            tokenProducts: data.state.tokenProducts
        });
        this.state.tokenProducts = data.state.tokenProducts;
        let productsCountries = JSON.parse(localStorage.getItem('WspProductCountries'));
        //console.log(this.state.courierProductCountryId)
        productsCountries.sort((a, b) => a.product_name < b.product_name ? -1 : +(a.product_name > b.product_name));
        this.setState({
            productCountries: productsCountries
        });

        let infoProduct = document.querySelector('#infoProductDiv');
        let infoObjScript = document.querySelector('#infoObjScriptDiv');

        infoProduct.style.display = 'flex';
        infoObjScript.style.display = 'none';

        productsCountries.map(productCountry => {
            if (productCountry.country_id ==  data.state.countryId && (productCountry.product_name.toLowerCase() == "envio unico" || productCountry.product_name.toLowerCase() == "envío único")) {
                data.state.productCountriesKey.map(prodKey => {
                    if (prodKey.productcountry_id == productCountry.id) {
                        this.setState({
                            productCountryKeyId: prodKey.id,
                            productCountryKeyName: prodKey.product_key
                        })
                    }
                });
            }
        });

        this.getResources();
        if (localStorage.getItem('DataOrderWsp')) {
            let clientData = JSON.parse(localStorage.getItem('DataOrderWsp'));
            this.setState({
                documentTypeId: clientData.documentTypeId,
                stateId: clientData.stateId,
                cityId: clientData.cityId,
                name: clientData.name,
                lastName: clientData.lastName,
                phone: clientData.phone,
                mobilePhone: clientData.mobilePhone,
                documentNumber: clientData.documentNumber,
                sex: clientData.sex,
                dateOfBirth: clientData.dateOfBirth,
                email: clientData.email,
                haventMail: clientData.haventMail,
                adress: clientData.adress,
                number: clientData.number,
                floor: clientData.floor,
                department: clientData.department,
                postalCode: clientData.postalCode,
                courierComentaries: clientData.courierComentaries,
                insideComentaries: clientData.insideComentaries,
                productsSelected: clientData.productsSelected,
                total: clientData.total,
                productId: clientData.productId,
                shippingCostMax: clientData.shippingCostMax,
            });
            this.state.total = clientData.total;
            this.state.number = clientData.number;

            let count = 0;
            clientData.productsSelected.map(proc => {
                count += proc.quantity
                
            });
            this.state.productsCount = count;
        }
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': this.state.tokenProducts
        };
        if(data.productId && data.countryId){
            axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getinfoproductbyid/' + data.productId + '/' + data.countryId, {
                headers: headers
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ productInfo: data, loading: false });
            })
            .catch(function (error) {
                console.log(error);
            });
        }
    }

    onCancel(e) {
        e.preventDefault();
        // console.log(this.state);
        this.state.productCountries = [];
        this.state.products = [];
        localStorage.setItem('DataOrderWsp',JSON.stringify(this.state));
        this.props.history.goBack();
    }

    goToPayment(e) {
        e.preventDefault();
        helpers.showLoadingOverlay();
        if (this.state.productsSelected.length > 0) {
            this.state.productCountries = [];
            this.state.products = [];
            localStorage.setItem('DataOrderWsp', JSON.stringify(this.state));
            window.location.href = "/payment-workspace";
            helpers.hideLoadingOverlay();
        } else {
            document.querySelector("#productError").style.display = 'block';
            helpers.hideLoadingOverlay();
            document.querySelector('#btnInfoProduct').classList.remove('btn-primary');
            document.querySelector('#btnInfoProduct').classList.add('btn-danger');
            document.querySelector("#errorBtnPreSale").style.display = 'inline';
        }
        
    }

    onChangeState(e) {
        e.preventDefault();
        this.setState({
            stateId: e.target.value
        });
    }

    onChangeProduct(e) {
        e.preventDefault();
        this.setState({
            productId: e.target.value
        });
        document.querySelector('#btnInfoProduct').classList.add('btn-primary');
        document.querySelector('#btnInfoProduct').classList.remove('btn-danger');
        document.querySelector("#errorBtnPreSale").style.display = 'none';
    }

    onChangeClosingReasonId(e) {
        this.setState({
            closingReasonId: e.target.value
        });
    }

    onContactAgain(e) {
        this.setState({
            contactAgain: e.target.value
        });
    }

    onChangeCommnets(e) {
        this.setState({
            comments: e.target.value
        });
        let countChararter= e.target.value.length;
        //console.log(countChararter)
        let resta = 100 - countChararter;
        //console.log(resta)
        this.state.remainComentaries = resta;
    }

    onChangeCreditCard(e) {
        this.setState({
            hasCreditCard: e.target.value
        });
    }

    onShowKits(e) {
        this.setState({
            showKits: !this.state.showKits,
            showCrossSell: false,
        });

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': this.state.tokenProducts
        };
        if(this.state.kitProducts.length < 1){
            axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getkitproducts/' + this.state.countryId, {
                headers: headers
            })
            .then(response => {
                const data = response.data.data;
                //console.log(data)
                this.setState({ kitProducts: data, loading: false });
            })
            .catch(function (error) {
                console.log(error);
            });
            
            axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getkitproductdetails', {
                headers: headers
            })
            .then(response => {
                const data = response.data.data;
                //console.log(data)
                this.setState({ kitProductDetails: data, loading: false });
            })
            .catch(function (error) {
                console.log(error);
            });
        }
    }

    onShowCrossSell(e){
        
        this.setState({
            showCrossSell: !this.state.showCrossSell,
            showKits: false,
        });
        // console.log(this.state);
    }

    setInfoProduct(){
        document.querySelector('#btnInfoProduct').classList.add('active');
        document.querySelector('#btnInfoObjScript').classList.remove('active');
        let infoProduct = document.querySelector('#infoProductDiv');
        let infoObjScript = document.querySelector('#infoObjScriptDiv');

        infoProduct.style.display = 'flex';
        infoObjScript.style.display = 'none';

        if(this.state.productId < 1){
            document.querySelector("#productError").style.display = 'block';
            document.querySelector('#btnPrevSale').classList.remove('btn-primary');
            document.querySelector('#btnPrevSale').classList.add('btn-danger');
            document.querySelector("#errorBtnPreSale").style.display = 'inline';
        } 
    }
    setInfoObjScript(){
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': this.state.tokenProducts
        };
        if(!this.state.scriptFounded){ 
            axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getobjetions',
            {
                'headers': headers
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ objetions: data, loading: false });
                // order alphabetically
                this.state.objetions.sort((a,b) => a.title < b.title ? -1 : +(a.title > b.title))
            })
            .catch(function (error) {
                console.log(error);
            });
            axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getscriptsales',
            {
                'headers': headers
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ scriptSales: data, loading: false, scriptFounded: true });
                // order alphabetically
                this.state.scriptSales.sort((a,b) => a.title < b.title ? -1 : +(a.title > b.title))
            })
            .catch(function (error) {
                console.log(error);
            });
        }

        document.querySelector('#btnInfoProduct').classList.remove('active');
        document.querySelector('#btnInfoObjScript').classList.add('active');
        let infoProduct = document.querySelector('#infoProductDiv');
        let infoObjScript = document.querySelector('#infoObjScriptDiv');

        infoProduct.style.display = 'none';
        infoObjScript.style.display = 'flex';
    }

    SaveNoSale(e) {
        let wksData = JSON.parse(localStorage.getItem('DataWorkspace'));
        
        const data = {
            id: wksData.state.currentElement.id,
            country_id: parseInt(wksData.state.params.id_pais),
            id_agent: parseInt(wksData.state.params.id_agente),
            call_index: wksData.state.params.id_datos ?? "",
            call_id: wksData.state.params.id_llamada,
            did: wksData.state.params.id_campania ?? "",
            id_campania_occ : parseInt(wksData.state.params.id_campania_occ ?? "0"),
            pbx_crm: parseInt(wksData.state.params.id_crm ?? "0"),
            pbx_db: parseInt(wksData.state.params.id_db ?? "0"),
            active_step: parseInt(wksData.state.activeStep ?? "0"),
            closingreason_id: this.state.closingReasonId,
            contact_again: this.state.contactAgain,
            comments: this.state.comments,
            product_id: wksData.state.productId,
            treatment_id: wksData.state.treatmentId,
            client_phone: wksData.state.phone,
            client_name: wksData.state.name,
            city_id: wksData.state.stateId,
            credit_card: wksData.state.hasCreditCard
        };
        // console.log(data)
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': this.state.tokenProducts
        };

        for (let i = 0; i < wksData.state.answersTreatmentQuestions.length; i++){
            const auxiliarData = {
                pbxworkspace_id: wksData.state.currentElement?.id,
                question_id: wksData.state.answersTreatmentQuestions[i].id,
                question_name: wksData.state.answersTreatmentQuestions[i].question,
                answer: wksData.state.answersTreatmentQuestions[i].answer,
                enabled: true
            };
            // console.log(auxiliarData);
            axios.post(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/savequestionanswers', auxiliarData, {
                headers: headers
            })
            .then((response) => { 
                
            })
            .catch((error) => console.log(error));
        }
        
        
        helpers.showLoadingOverlay();
        axios.post(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/save', data, {
            headers: headers
        })
        .then((response) => { 
            setTimeout(() => window.location.href = "/no-sale-workspace", 3000)
            
        })
        .catch((error) => console.log(error));
    }

    /*removeCarItem(e) {
        if (e.target.value != undefined) {
            for (let i = 0; i < this.state.productsSelected.length; i++){
                if (this.state.productsSelected[i].description == e.target.value) {
                    
                    if (!this.state.productsSelected[i].kitKey) {
                        let index = this.state.productsSelected.findIndex(prod => prod.description == e.target.value);
                        // console.log(this.state.productsSelected[i]);
                        let currentShippingCost = this.state.shippingCostMax;
                        this.setState({
                            total: this.state.total - (Number(this.state.productsSelected[i].price) * Number(this.state.productsSelected[i].quantity)) // - currentShippingCost
                        });
                        this.state.productsSelected.splice(index, 1);
                        
                        let shippingCostArr = [];
                        let shippingCostMax = 0;
                        for(let i = 0; i < this.state.productsSelected.length; i++) {
                            shippingCostArr.push(this.state.productsSelected[i].shippingCost);
                        }
                        
                        for (let i = 0; i < shippingCostArr.length; i++){
                                if (shippingCostArr[i] > shippingCostMax)
                                {
                                    shippingCostMax = shippingCostArr[i];
                                };
                        }
                        // console.log(shippingCostMax);
                        this.state.shippingCostMax = shippingCostMax;
                    } else {
                        // console.log(this.state.productsSelected.filter(prod => prod.kitKey != this.state.productsSelected[i].kitKey));
                        this.state.productsSelected = this.state.productsSelected.filter(prod => prod.kitKey != this.state.productsSelected[i].kitKey);

                        let shippingCostArr = [];
                        let shippingCostMax = 0;
                        let total = 0;
                        for(let i = 0; i < this.state.productsSelected.length; i++) {
                            shippingCostArr.push(this.state.productsSelected[i].shippingCost);
                            total += (Number(this.state.productsSelected[i].price) * Number(this.state.productsSelected[i].quantity));
                        }
                        this.state.total = total;
                        for (let i = 0; i < shippingCostArr.length; i++){
                            if (shippingCostArr[i] > shippingCostMax)
                            {
                                shippingCostMax = shippingCostArr[i];
                            };
                        }
                        this.state.shippingCostMax = shippingCostMax;
                    }
                    
                }
            }
            this.setState({
                productsSelected: this.state.productsSelected
            })
            let count = 0;
            this.state.productsSelected.map(proc => {
                count += proc.quantity
            });
            this.state.productsCount = count;
        }
    }*/
    


    removeCarItem(e) {
        const descriptProd=JSON.parse(e.target.value).description;
        if (descriptProd != undefined) {
            for (let i = 0; i < this.state.productsSelected.length; i++){
                if (this.state.productsSelected[i].description == descriptProd) {
                    
                    if (!this.state.productsSelected[i].kitKey) {
                        let index = this.state.productsSelected.findIndex(prod => prod.description == descriptProd);
                        // console.log(this.state.productsSelected[i]);
                        let currentShippingCost = this.state.shippingCostMax;
                        this.setState({
                            total: this.state.total - (Number(this.state.productsSelected[i].price) * Number(this.state.productsSelected[i].quantity)) // - currentShippingCost
                        });
                        this.state.productsSelected.splice(index, 1);
                        
                        let shippingCostArr = [];
                        let shippingCostMax = 0;
                        for(let i = 0; i < this.state.productsSelected.length; i++) {
                            shippingCostArr.push(this.state.productsSelected[i].shippingCost);
                        }
                        
                        for (let i = 0; i < shippingCostArr.length; i++){
                                if (shippingCostArr[i] > shippingCostMax)
                                {
                                    shippingCostMax = shippingCostArr[i];
                                };
                        }
                        // console.log(shippingCostMax);
                        this.state.shippingCostMax = shippingCostMax;
                    } else {
                        // console.log(this.state.productsSelected.filter(prod => prod.kitKey != this.state.productsSelected[i].kitKey));
                        this.state.productsSelected = this.state.productsSelected.filter(prod => prod.kitKey != this.state.productsSelected[i].kitKey);

                        let shippingCostArr = [];
                        let shippingCostMax = 0;
                        let total = 0;
                        for(let i = 0; i < this.state.productsSelected.length; i++) {
                            shippingCostArr.push(this.state.productsSelected[i].shippingCost);
                            total += (Number(this.state.productsSelected[i].price) * Number(this.state.productsSelected[i].quantity));
                        }
                        this.state.total = total;
                        for (let i = 0; i < shippingCostArr.length; i++){
                            if (shippingCostArr[i] > shippingCostMax)
                            {
                                shippingCostMax = shippingCostArr[i];
                            };
                        }
                        this.state.shippingCostMax = shippingCostMax;
                    }
                    
                }
            }
            this.setState({
                productsSelected: this.state.productsSelected
            })
            let count = 0;
            this.state.productsSelected.map(proc => {
                count += proc.quantity
            });
            this.state.productsCount = count;
        }
    }
    
    //leocode
    addCarItem(e){
        const inputProd=JSON.parse(e.target.value);
        let listProducts=[];
        let acumPrices=0;
        if (inputProd != undefined) {
            
            //Si el item tiene kitId => Buscar todos los productos bajo este kitId, aumentar en uno su cantidad y actualizar total
            if(inputProd.hasOwnProperty("kitId")){
                listProducts=this.state.productsSelected.map((prod)=>
                {if(inputProd.kitKey===prod.kitKey){
                    acumPrices +=Number(prod.price);
                    return {...prod,quantity:inputProd.quantity+1};                   
                }else{
                    return {...prod}
                }});

                //Si el item NO tiene kitId => aumentar en uno la cantidad de este item únicamente y actualizar total.
            }else if(!inputProd.hasOwnProperty("kitId")){
               listProducts=this.state.productsSelected.map((prod)=>{
                if(inputProd.description===prod.description){
                    acumPrices +=Number(prod.price);
                    return {...prod,quantity:inputProd.quantity+1};
               }else{
                    return {...prod}
               }});
            }
            this.setState(()=>{
                return{
                    productsSelected:listProducts,
                    total:acumPrices+this.state.total

                }
            });
        }
    }
    
    


    subtractCarItem(e){
        const inputProd=JSON.parse(e.target.value);
        let listProducts=[];
        let tempTotal=null;
        if (inputProd != undefined && inputProd.quantity>1) {
            
            //Si el item tiene kitId => Buscar todos los productos bajo este kitId, disminuir en uno su cantidad y actualizar total
            if(inputProd.hasOwnProperty("kitId")){
                listProducts=this.state.productsSelected.map((prod)=>
                {if(inputProd.kitKey===prod.kitKey){
                    tempTotal=this.state.total-Number(prod.price);
                    return {...prod,quantity:inputProd.quantity-1};                   
                }else{
                    return {...prod}
                }});

                //Si el item NO tiene kitId => aumentar en uno la cantidad de este item únicamente y actualizar total.
            }else if(!inputProd.hasOwnProperty("kitId")){
               listProducts=this.state.productsSelected.map((prod)=>{
                if(inputProd.description===prod.description){
                    tempTotal=this.state.total-Number(prod.price);
                    return {...prod,quantity:inputProd.quantity-1};
               }else{
                    return {...prod}
               }});
            }
            this.setState(()=>{
                return{
                    productsSelected:listProducts,
                    total:tempTotal

                }
            });
        }else{
           // console.log(inputProd.description);
            this.removeCarItem(e)
        }
    }

    /*subtractCarItem(e){
       // console.log(e.target.value);
        //console.log(this.state.productsSelected);
        if (e.target.value != undefined) {
            const listProducts=this.state.productsSelected.map((prod)=>(prod.description===e.target.value)?{...prod,quantity:prod.quantity-1}:{...prod});
            console.log(listProducts);
            this.setState(()=>{
                return{
                    productsSelected:listProducts
                }
            });
        }
        
    }*/

    render() {

        return (
            <div className="container">
                 <div className="row">
                    <div className="p-2 my-2">
                        <Box sx={{ width: '100%' }}>
                            <Stepper activeStep={this.state.activeStep} alternativeLabel>
                            {this.state.steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                            ))}
                        </Stepper>
                        </Box>
                    </div>
                </div>
                <div className="row">
                    <div className='dis-flex'>
                            <button className="btn-primary p-2" style={{border: 'none', fontSize: '0.9rem', marginRight: '2px'}} id="btnInfoProduct" onClick={this.setInfoProduct}>
                                {localStorage.getItem('WspLanguage') == 'language0' ? 'Shopping Car' : 'Carro de Compras'}<span id="errorBtnPreSale">(!)</span>
                            </button>

                            <button className="btn-primary p-2" style={{border: 'none', fontSize: '0.9rem', marginRight: '2px'}} id="btnInfoObjScript" onClick={this.setInfoObjScript}>
                                {localStorage.getItem('WspLanguage') == 'language0' ? 'Objetions / Script Sales' : 'Objeciones / Script Ventas'}
                            </button>
                    </div>
                </div>
                <div className="row" style={{border: '2px solid rgb(51 99 205)',borderRadius: '5px', height: '450px'}} id="infoProductDiv">
                    <div style={{height:'360px'}}>
                        <div className="row mt-1 p-2 pl-4 table-responsive" style={{height: '100%'}}> 
                            <table className='table datatable my-table' style={{maxHeight:'350px', overflowY: 'auto'}} aria-labelledby="tabelLabel">
                                <thead>
                                    <tr>
                                    <th>{this.state.resources.Label_Products}</th>
                                    <th><div className="mx-1 px-1" style={{textAlign: 'end'}}>{this.state.resources.Label_Quantity}</div></th>
                                        <th><div className="mx-2 px-2" style={{textAlign: 'end'}}>{this.state.resources.Label_Price}</div></th>
                                        <th style={{width: '20%'}}><div className="mx-5 px-2" style={{textAlign: 'end'}}>{this.state.resources.Label_TotalPrice} </div></th>
                                        <th style={{width: '15%'}}>KIT</th>
                                        <th style={{width: '20%',textAlign: 'center'}}>{this.state.resources.Label_Actions}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.productsSelected.map(prod => (
                                        <tr>
                                            <td>{prod.description}</td>
                                            <td><div className="mx-1 px-1" style={{textAlign: 'end'}}>{prod.quantity}</div></td>
                                            <td><div className="mx-1 px-0" style={{textAlign: 'end'}}>${Number(prod.price).toLocaleString('en-US')}</div></td>
                                            <td><div className="mx-5 px-2" style={{textAlign: 'end'}}>${(prod.price * prod.quantity).toLocaleString('en-US')}</div></td>
                                            <td>{prod.kitKey}</td>
                                            <td>
                                                <div className="mx-1 px-1" style={{margin: '0 auto'}}>
                                                    <button type="button" className="btn btn-success  mx-1" value={JSON.stringify(prod)} onClick={this.addCarItem/*leocode */}><i className="fa fa-plus" style={{ pointerEvents: 'none' }}></i></button>
                                                    <button type="button" className="btn btn-danger  mx-1" value={JSON.stringify(prod)} onClick={this.subtractCarItem}><i className="fa fa-minus" style={{ pointerEvents: 'none' }}></i></button>
                                                    <button type="button" className="btn btn-danger  mx-1" value={JSON.stringify(prod)} onClick={this.removeCarItem}><i className="fa fa-trash" style={{ pointerEvents: 'none' }}></i></button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                    <tr>
                                        <td>{localStorage.getItem('WspLanguage') == 'language0' ? 'Single Shipment ' : 'Envío Único ' .toLocaleString('en-US')} {this.state.productCountryKeyName}:</td>
                                        <td></td>
                                        <td></td>
                                        <td><div className="mx-5 px-2" style={{textAlign: 'end'}}>${Number(this.state.shippingCostMax).toLocaleString('en-US')}</div> </td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td>
                                            <div style={{fontSize:'1.2rem'}}>
                                                <b>Total: $ {(Number(this.state.total) + Number(this.state.shippingCostMax)).toLocaleString('en-US')}</b>
                                            </div>
                                        </td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    
                                </tbody>
                            </table>
                        </div>
                        <div style={{color: 'red'}} className="p-1 pl-2" id="productError">{localStorage.getItem('WspLanguage') == 'language0' ? 'You Must Select a Product' : 'Tiene que seleccionar un producto para continuar'}</div>
                        <div className="row my-4 dis-flex" style={{justifyContent: 'space-around'}}>
                            <ProductListModal />
                        </div>
                    </div>
                </div>
                
                <div className="row" style={{border: '2px solid rgb(51 99 205)',borderRadius: '5px', height: '450px'}} id="infoObjScriptDiv">
                 <div className="row mt-3 mb-5">
                        <div className="col-md-6">
                            <div className="card">
                                <div className="card-header">
                                    {this.state.resources.Title_Objetions}
                                </div>
                                <div className="card-body">
                                    <ObjetionScriptsAccordion data={this.state.objetions} resources={this.state.resources}
                                        country_id={this.state.countryId} product_id={this.state.productId} />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="card">
                                <div className="card-header">
                                    {this.state.resources.Title_ScriptSales}
                                </div>
                                <div className="card-body">
                                    <ObjetionScriptsAccordion data={this.state.scriptSales} resources={this.state.resources}
                                        country_id={this.state.countryId} product_id={this.state.productId} />
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div className="row my-1">
                    <div className="mx-auto mb-1">
                        <div className="col-md-4 dis-flex mx-auto">
                            <NoSale data={this.state}/>
                            <div>
                            <button className="btn btn-secondary m-2 px-3" style={{width:100}} onClick={this.onCancel}>{this.state.resources.Label_GoBack}</button>
                            </div>
                            <div>
                            <button className="btn btn-success m-2 px-3" style={{width:100}} onClick={this.goToPayment}>{this.state.resources.Workspace_Next}</button>
                            </div>
                            
                        </div>
                    </div>
                </div>
                    <div className="row"></div>
            </div>
        );
    }

    async getResources() {
        const resourceVars = [];
        resourceVars.push("Label_SelectOption");
        resourceVars.push("Label_Name");
        resourceVars.push("Label_LastName");
        resourceVars.push("Label_PostalCode");
        resourceVars.push("Title_City");
        resourceVars.push("Label_Address");
        resourceVars.push("Label_Country");
        resourceVars.push("Label_StateOrProvince");
        resourceVars.push("Label_DocNumber");
        resourceVars.push("Label_DateOfBirth");
        resourceVars.push("Label_Phone");
        resourceVars.push("Label_MobilePhone");
        resourceVars.push("Label_Email");
        resourceVars.push("Label_Description");
        resourceVars.push("Label_Enabled");
        resourceVars.push("Label_Success");
        resourceVars.push("ButtonLabel_Save");
        resourceVars.push("ButtonLabel_Cancel");
        resourceVars.push("Label_Error");
        resourceVars.push("ErrorMessage_RequiredField");
        resourceVars.push("ErrorMessage_DuplicatedName");
        resourceVars.push("ErrorMessage_UnknownError");
        resourceVars.push("ErrorMessage_AssociatedEntity_SubArea_User");
        resourceVars.push("SuccessMessage_DataSavedSuccessfully");
        resourceVars.push("Label_GoBack");
        resourceVars.push("Title_Clients");
        resourceVars.push("Label_Creation");
        resourceVars.push("Label_DocumentType");
        resourceVars.push("Workspace_Next");
        resourceVars.push("Label_NoSale");
        resourceVars.push("Label_Sex");
        resourceVars.push("Label_Male");
        resourceVars.push("Label_Female");
        resourceVars.push("Label_SearchClient");
        resourceVars.push("Label_HistoryClient");
        resourceVars.push("Label_Info");
        resourceVars.push("Label_NoMail");
        resourceVars.push("Label_Number");
        resourceVars.push("Label_Floor");
        resourceVars.push("Label_Department");
        resourceVars.push("Label_CourierComentaries");
        resourceVars.push("Label_InsideComentaries");
        resourceVars.push("Label_DocsRequired");
        resourceVars.push("ButtonLabel_Select");
        resourceVars.push("Label_NoRows");
        resourceVars.push("Title_Objetions");
        resourceVars.push("Title_ScriptSales");
        resourceVars.push("Label_Price");
        resourceVars.push("Label_AddProduct");
        resourceVars.push("Label_Quantity");
        resourceVars.push("Label_Products");
        resourceVars.push("Label_Presentation");
        resourceVars.push("Label_Actions");
        resourceVars.push("Label_TotalPrice");
        resourceVars.push("Label_Comments");
        resourceVars.push("Label_NoSaleReason");
        resourceVars.push("Label_ContactAgain");
        resourceVars.push("WspError_CarEmpty");
        resourceVars.push("Label_Chararter");
        resourceVars.push("Label_Remain");

        const headersLang = {
            'Content-Type': 'application/json'
        };

        let wspLanguage = localStorage.getItem('WspLanguage');

        if (wspLanguage == 'language1') {
            this.state.steps[0] = 'Inicio'
            this.state.steps[1] = 'Cliente'
            this.state.steps[2] = 'Pedido'
            this.state.steps[3] = 'Pago'
        } else {
            this.state.steps[0] = 'Start'
            this.state.steps[1] = 'Client'
            this.state.steps[2] = 'Order'
            this.state.steps[3] = 'Payment'
        }

        axios.post(window.config.REACT_APP_SECURITY_API_URL + '/languageresource/getbyvars/' + wspLanguage, resourceVars, {
            headers: headersLang
        })
        .then((response) => {
            const data = response.data.data;
            this.setState({ resources: data, loading: false });
        })
        .catch((error) => {
            console.log(error);
            this.setState({ loading: false });
        });
    }
}

function ObjetionScriptsAccordion(values) {
    // console.log(values);
    let loading = true;
    let myDataArray = [];
    if (values.data.length > 0) {
        myDataArray = values.data.sort((a, b) => a.title.toLowerCase() < b.title.toLowerCase() ? -1 : +(a.title.toLowerCase() > b.title.toLowerCase()))
        loading = false;
    }

  return (
    <div style={{height:'320px',overflowY:"auto"}}>
        {loading == true ? <div className="text-center"><CircularProgress /></div>: 
        myDataArray.map((objScrip) => (
            objScrip.country_id == values.country_id && objScrip.enabled && (objScrip.product_id == values.product_id || objScrip.product_id === null)? 
            <Accordion>
            <AccordionSummary
                expandIcon={<i class="fas fa-chevron-down"></i>}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography>{objScrip.title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography
                id={'obj' + objScrip.id}
                dangerouslySetInnerHTML={{ __html: objScrip.detail }}
                ></Typography>
            </AccordionDetails>
            </Accordion>
            : <></>
        ))}
    </div>
  );
}

function ProductListModal() {
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const handleOpen = () => {
        setOpen(true);
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': this.state.tokenProducts
        };        
        if(this.state.products.length < 1){
            axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getligthproductsbyAgent/' + this.state.userId, {
                headers: headers
            })
            .then(response => {
                const dataProc = response.data.data;
                this.setState({ products: dataProc, loading: false });
                // order alphabetically
                this.state.products.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
            })
            .catch((error) => {
                console.log(error);
            });
        }

        //console.log('prod-id');
        //console.log(this.state.productId);

        if(this.state.productId){
            axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getproductcountrykeybyproduct/' + this.state.productId,
            {
                'headers': headers
            })
            .then(response => {
                const data = response.data.data;
                //console.log(data);
                this.setState({ productCountriesKey: data, loading: false });
                // order alphabetically
                this.state.productCountriesKey.sort((a,b) => a.description < b.description ? -1 : +(a.description > b.description))
            })
            .catch(function (error) {
                console.log(error);
            });
            axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getproductbyid/' + this.state.productId,
            {
                'headers': headers
            })
            .then(response => {
                const data = response.data.data;
                data.map(dat =>{
                    this.state.courierProductCountryId = dat.courier_id;
                })
                //console.log(data)
                //this.state.courierProductCountryId = data.courier_id;
                //console.log(this.state.courierProductCountryId)
                //console.log(data.courier_id)

                this.setState({ productCountries: data, loading: false });
                // order alphabetically
                this.state.productCountries.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                this.setState({
                    productCountries: this.state.productCountries.concat({ id: 0, country_id: 0 })
                });
            })
            .catch(function (error) {
                console.log(error);
            });
            if(this.state.countryId){
                axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getinfoproductbyid/' + this.state.productId + '/' + this.state.countryId, {
                    headers: headers
                })
                .then(response => {
                    const data = response.data.data;
                    this.setState({ productInfo: data });
                    setLoading(false);
                })
                .catch(function (error) {
                    console.log(error);
                });
            }
        }
    };

    const handleClose = () => {
        setOpen(false);
        myProdCountriesId = [];
    };
  
    const onChangeProduct = (e) => {
        e.preventDefault();
        setLoading(true);
        this.setState({
            productId: e.target.value
        });
        document.querySelector("#productError").style.display = 'none';
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': this.state.tokenProducts
        };
        if(e.target.value && this.state.countryId){
            axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getinfoproductbyid/' + e.target.value + '/' + this.state.countryId, {
                headers: headers
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ productInfo: data });
                setLoading(false);
            })
            .catch(function (error) {
                console.log(error);
            });
        }
        if(e.target.value){
            axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getproductcountrykeybyproduct/' + e.target.value,
            {
                'headers': headers
            })
            .then(response => {
                const data = response.data.data;
                //console.log(data)
                this.setState({ productCountriesKey: data, loading: false });
                // order alphabetically
                this.state.productCountriesKey.sort((a,b) => a.description < b.description ? -1 : +(a.description > b.description))
            })
            .catch(function (error) {
                console.log(error);
            });
            axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getproductbyid/' + e.target.value,
            {
                'headers': headers
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ productCountries: data, loading: false });
                // order alphabetically
                this.state.productCountries.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                this.setState({
                    productCountries: this.state.productCountries.concat({ id: 0, country_id: 0 })
                });
            })
            .catch(function (error) {
                console.log(error);
            });
        }
    }

    const addProductCar = (e) => {
        e.preventDefault(e);
        document.querySelector("#productError").style.display = 'none';
        document.querySelector('#btnInfoProduct').classList.add('btn-primary');
        document.querySelector('#btnInfoProduct').classList.remove('btn-danger');
        document.querySelector("#errorBtnPreSale").style.display = 'none';
        if (e.target.value != undefined) {
            let shoppingCar = e.target.value.split(';');
            let brandId = null;
            this.state.products.map(x => {
                if(x.id == this.state.productId){
                    brandId = x.brand_id;
                }
            });
            // let presentation = shoppingCar[0].split(' ');
            let product = {
                description: shoppingCar[0],
                price: shoppingCar[1],
                shippingCost: shoppingCar[2],
                brandId: brandId,
                quantity: 1
            }

            let errorDiferentBrand = 0;
            for (let i = 0; i < this.state.productsSelected.length; i++){
                if(this.state.productsSelected[i]?.brandId != product.brandId){
                    errorDiferentBrand++;
                }
                if (this.state.productsSelected[i].description == product.description && !this.state.productsSelected[i].kitId) {
                    // console.log(this.state.productsSelected.findIndex(prod => prod.description == product.description));
                    let index = this.state.productsSelected.findIndex(prod => prod.description == product.description);
                    product.quantity = this.state.productsSelected[i].quantity + 1;
                    this.setState({
                        productsSelected: this.state.productsSelected.splice(index, 1)
                    });
                }
            }
            if(errorDiferentBrand < 1){
                this.setState({
                    productsSelected: this.state.productsSelected.concat(product),
                    total: this.state.total + Number(shoppingCar[1])
                });
                let count = 0;
                this.state.productsSelected.concat(product).map(proc => {
                    count += proc.quantity
                });
                this.state.productsCount = count;
                let shippingCostArr = [];
                let shippingCostMax = 0;
                for(let i = 0; i < this.state.productsSelected.concat(product).length; i++) {
                    shippingCostArr.push(this.state.productsSelected.concat(product)[i].shippingCost);
                }
                
                for (let i = 0; i < shippingCostArr.length; i++){
                    if (shippingCostArr[i] > shippingCostMax)
                    {
                        shippingCostMax = shippingCostArr[i];
                    };
                }
                this.setState({
                    total: this.state.total+ Number(shoppingCar[1])// - this.state.shippingCostMax   + Number(shippingCostMax) 
                })
                this.state.shippingCostMax = shippingCostMax;    
            }else{
                let errormsg = localStorage.getItem('WspLanguage') == 'language0' ? 'The product cannot be added because it is from a different brand than the products already selected' : 'No se puede agregar el producto ya que este es de una marca diferente a la de los productos ya seleccionados'
                toast.error(errormsg, {
                    position: "top-right",
                    autoClose: 6000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }
    }

    const addKitCar = (e) => {
        let currentKit = "";
        this.state.kitProducts.map(kit => {
            if (kit.id == e.target.value) {
                currentKit = kit.kitkey;
            }
        });

        this.state.kitProductDetails.map(kitDetail => {
            if (kitDetail.kit_id == e.target.value) { 
                this.state.productCountriesKey.map(prodKey => { 
                    if (prodKey.id == kitDetail.productcountrykey_id) {
                        this.state.productCountries.map(prodCt => {
                            if (prodCt.id == prodKey.productcountry_id) { 
                                let product = {
                                    description: prodKey.description,
                                    price: Number(prodKey.price),
                                    shippingCost: prodCt.shippingcost,
                                    quantity: kitDetail.quantity,
                                    kitId: kitDetail.kit_id,
                                    kitKey: currentKit
                                }
                               // console.log(product)
                                for (let i = 0; i < this.state.productsSelected.length; i++) {
                                    
                                    if (this.state.productsSelected[i].description == product.description && this.state.productsSelected[i].kitId == product.kitId) {
                                        let index = this.state.productsSelected.findIndex(prod => prod.description == product.description && prod.kitId == product.kitId);
                                        product.quantity = this.state.productsSelected[i].quantity + 1;
                                        this.setState({
                                            productsSelected: this.state.productsSelected.splice(index, 1)
                                        });
                                    }    
                                }

                                this.state.productsSelected.push(product);
                                this.setState({
                                    productsSelected: this.state.productsSelected,
                                    total: this.state.total + Number(prodKey.price)
                                });
                                let count = 0;
                                this.state.productsSelected.map(proc => {
                                    count += proc.quantity
                                });
                                this.state.productsCount = count;
                                let shippingCostArr = [];
                                let shippingCostMax = 0;
                                let total = 0;
                                for (let i = 0; i < this.state.productsSelected.length; i++) {
                                    shippingCostArr.push(this.state.productsSelected[i].shippingCost);
                                    total = total + (this.state.productsSelected[i].price * this.state.productsSelected[i].quantity);
                                }
                                
                                for (let i = 0; i < shippingCostArr.length; i++) {
                                    if (shippingCostArr[i] > shippingCostMax) {
                                        shippingCostMax = shippingCostArr[i];
                                    };
                                }
                                this.setState({
                                    total: total//this.state.total + Number(prodKey.price)
                                })
                                this.state.shippingCostMax = shippingCostMax;
                            }
                         });
                    }
                });
            }
        });
    }

    const onFilterProducts = (value) => {
        this.setState({
            filterActive: true,
            filteringProds: (value).toString()
        });
    }

    const handleProductFiltering = useCallback(
        debounce(onFilterProducts, 400), []
    );

    const resetFilter = () => {
        document.querySelector('#btnFilterProcs').value = "";
        this.setState({
            filterActive: false,
            filteringProds: ""
        });
        this.state.filteringProds = "";
    }

   const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '70%',
        maxHeight: '90%',
        overflowY: 'auto',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    let myProdCountriesId = [];
    let myProducsIds = [];

    if (this.state.productCountries.length > 0) {
        // loading = false;
        for (let i = 0; i < this.state.productCountries.length; i++){
            if (this.state.productCountries[i].country_id == this.state.countryId) {
                myProdCountriesId.push(this.state.productCountries[i].id);
                myProducsIds.push(this.state.productCountries[i].product_id);
            }
        }
    }

    let kitsArray = [];
    let kitAuxiliarPrice= [];
    //console.log(this.state.productCountriesKey)
    this.state.productCountriesKey.map(item =>
        myProdCountriesId.includes(item.productcountry_id) &&
        this.state.productCountries.map(
            prod => {
                if (prod.id == item.productcountry_id && prod.product_id == this.state.productId) {
                    
                    this.state.kitProducts.map(kit =>
                        this.state.kitProductDetails.map(kitDetail => {
                            if (kitDetail.kit_id == kit.id && kitDetail.productcountrykey_id == item.id) {
                                // console.log(kit)
                                // console.log(item);
                                // console.log(kitDetail)
                                kitsArray.push(kit);
                                const auxiliaPriceItem = {kitId: kitDetail.kit_id, price: item.price * kitDetail.quantity, description: kit.description};
                                kitAuxiliarPrice.push(auxiliaPriceItem);
                            }
                        })
                    )}
            })
    )
    let hash = {};
    kitsArray = kitsArray.filter(o => hash[o.id] ? false : hash[o.id] = true);
    if (kitsArray.length > 0) {
        // this.setState({
        //     kitsArrayFiltered: kitsArray
        // });
        this.state.kitsArrayFiltered = kitsArray;
    }
    let totalAuxiliar= [];

    this.state.kitsArrayFiltered.map(item =>{
        kitAuxiliarPrice.map(auxiliar =>{
            if(item.id == auxiliar.kitId){
               // console.log(auxiliar)
                if(totalAuxiliar.length > 0){
                    if(totalAuxiliar.find(x => x.kitId == auxiliar.kitId)){
                        totalAuxiliar.map(x =>{

                            if(x.kitId == auxiliar.kitId){
                                x.price+=auxiliar.price;
    
                            }
                        })
                    }else{
                        totalAuxiliar.push(auxiliar);
                    }
                    
                }else{
                    totalAuxiliar.push(auxiliar);
                }
            }
        })
    })
    //console.log(totalAuxiliar);
    //console.log(this.state.filteringProds)
    this.state.kitsArrayFiltered = totalAuxiliar;

    return (
        <><ToastContainer></ToastContainer>
            <button onClick={handleOpen} className="btn btn-block btn-primary" style={{ maxWidth: '50%' }}>{this.state.resources.Label_AddProduct}</button>  
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2" className='text-center'>
                    {this.state.resources.Label_Products}
                </Typography>
                <Badge badgeContent={this.state.productsCount.toString()} color="primary" style={{position: 'absolute', right: '20px', top: '20px'}} onClick={handleClose}>
                    <button className='btn btn-warning' >
                        <i class="fas fa-cart-arrow-down" style={{ pointerEvents: 'none', color: 'white', fontSize: '1.2rem' }}></i>
                    </button>
                </Badge>
                    <hr></hr>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="dis-flex">
                                <select className="form-control col-md-6 mr-1" data-val="true" name="productId" defaultValue={this.state.productId} onChange={onChangeProduct}>
                                    <option value="">{this.state.resources.Label_SelectOption}</option>
                                    {this.state.products?.map(prod =>
                                        // myProducsIds.includes(prod.id) &&
                                        <option key={prod.id} selected={this.state.productId == prod.id} value={prod.id}>
                                            {prod.name}
                                        </option>
                                    )}
                                </select>
                                <div className='m-2 ml-3 pl-3 pt-1 form-check'>
                                    <input type="checkbox" className="form-check-input" id="checkCrossSell" onChange={this.onShowCrossSell} defaultChecked={this.state.showCrossSell} checked={this.state.showCrossSell ? true : false}/>
                                        <label class="form-check-label" for="checkCrossSell">Cross Sell</label>
                                </div>
                                <div className='m-2 ml-3 pl-3 pt-1 form-check'>
                                    <input type="checkbox" className="form-check-input" id="checkKit" onChange={this.onShowKits} defaultChecked={this.state.showKits} checked={this.state.showKits ? true : false}/>
                                        <label class="form-check-label" for="checkKit">KIT</label>
                                </div>
                            </div>
                            <br></br>  
                            <div className='row ml-1'>
                                <input className="form-control col-md-10" data-val="true" id="btnFilterProcs" placeholder={localStorage.getItem('WspLanguage') == 'language0' ? 'Search' : 'Buscar'}
                                 onChange={(e) => handleProductFiltering(e.target.value)} defaultValue={this.state.filteringProds}/>
                                <button className='btn btn-danger col-md-1 mx-2' onClick={resetFilter}>X</button>
                            </div>
                            <br></br>
                            <table className='table datatable my-table' aria-labelledby="tabelLabel">
                                <thead>
                                    <tr>
                                    <th>{this.state.resources.Label_Presentation}</th>
                                    <th>{this.state.resources.Label_Price}</th>
                                    <th>{this.state.resources.Label_AddProduct}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.kitsArrayFiltered.map(kit => 
                                        kit.kitId > 0 && this.state.showKits ?
                                            <>
                                                {this.state.filterActive && kit.description.toLowerCase().includes(this.state.filteringProds.toLowerCase()) ?
                                                <tr>
                                                    <td>{kit.description}</td>
                                                    <td style={{textAlign: 'right', paddingRight:'5%'}}>${kit.price.toLocaleString('en-US')}</td>
                                                    <td>
                                                        <button className="btn btn-success" onClick={addKitCar} value={kit.kitId}>
                                                            <i class="fas fa-cart-arrow-down" style={{ pointerEvents: 'none' }}></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                                :<>
                                                    {this.state.filterActive ? 
                                                        <></>
                                                    :  <tr>
                                                            <td>{kit.description}</td>
                                                            <td style={{textAlign: 'right', paddingRight:'5%'}}>${kit.price.toLocaleString('en-US')}</td>
                                                            <td>
                                                                <button className="btn btn-success" onClick={addKitCar} value={kit.kitId}>
                                                                    <i class="fas fa-cart-arrow-down" style={{ pointerEvents: 'none' }}></i>
                                                                </button>
                                                            </td>
                                                        </tr>}
                                                 </>}
                                            </>
                                        : <></>
                                    )}
                                    {this.state.productCountriesKey.map(item =>
                                        myProdCountriesId.includes(item.productcountry_id) && 
                                        this.state.productCountries.map(
                                            prod => prod.id == item.productcountry_id && prod.product_id == this.state.productId && !this.state.showKits && !this.state.showCrossSell && item.single_sale && !item.cross_sell ?
                                                <>
                                                    {this.state.filterActive && item.description.toLowerCase().includes(this.state.filteringProds.toLowerCase()) ?
                                                    <tr>
                                                        <td>{item.description}</td>
                                                        <td style={{textAlign: 'right'}}>${item.price.toLocaleString('en-US')}</td>
                                                        <td>
                                                            <button className="btn btn-success" onClick={addProductCar} value={item.description + ';' + item.price + ';' + prod.shippingcost}>
                                                                <i class="fas fa-cart-arrow-down" style={{pointerEvents: 'none'}}></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                    :<>
                                                    {this.state.filterActive ? 
                                                        <></>
                                                    : <tr>
                                                            <td>{item.description}</td>
                                                            <td style={{textAlign: 'right'}}>${item.price.toLocaleString('en-US')}</td>
                                                            <td>
                                                                <button className="btn btn-success" onClick={addProductCar} value={item.description + ';' + item.price + ';' + prod.shippingcost}>
                                                                    <i class="fas fa-cart-arrow-down" style={{pointerEvents: 'none'}}></i>
                                                                </button>
                                                            </td>
                                                        </tr>}
                                                    </>}
                                                </>
                                            :<></>
                                        )
                                    )}
                                    {this.state.productCountriesKey.map(item =>
                                        myProdCountriesId.includes(item.productcountry_id) && 
                                        this.state.productCountries.map(//prod.id == item.productcountry_id && 
                                            prod =>prod.id == item.productcountry_id && prod.product_id == this.state.productId && !this.state.showKits && this.state.showCrossSell && item.single_sale && item.cross_sell ? // && item.product_cs == prod.id ?
                                                <>
                                                    {this.state.filterActive && item.description.toLowerCase().includes(this.state.filteringProds.toLowerCase()) ?
                                                        <tr>
                                                            <td>{item.description}</td>
                                                            <td style={{textAlign: 'right'}}>${item.price.toLocaleString('en-US')}</td>
                                                            <td>
                                                                <button className="btn btn-success" onClick={addProductCar} value={item.description + ';' + item.price + ';' + prod.shippingcost}>
                                                                    <i class="fas fa-cart-arrow-down" style={{pointerEvents: 'none'}}></i>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    :<>
                                                        {this.state.filterActive ? 
                                                        <></>
                                                        : <tr>
                                                            <td>{item.description}</td>
                                                            <td style={{textAlign: 'right'}}>${item.price.toLocaleString('en-US')}</td>
                                                            <td>
                                                            <button className="btn btn-success" onClick={addProductCar} value={item.description + ';' + item.price + ';' + prod.shippingcost}>
                                                                    <i class="fas fa-cart-arrow-down" style={{pointerEvents: 'none'}}></i>
                                                                </button>
                                                            </td>
                                                            </tr>}
                                                    </>}
                                                </>
                                            :<></>
                                        )
                                    )}
                                </tbody>
                            </table>
                            </div>
                            <div className="col-md-6 p-3 pt-2" style={{border: '2px solid #E8E4E4', borderRadius: '5px', backgroundColor: '#F8F9F9', maxHeight: '400px', overflowY: 'auto' }}>
                                {loading === true ? <div className="text-center"><CircularProgress /></div> :
                                <div dangerouslySetInnerHTML={{ __html: this.state.productInfo?.detail }}></div>}
                            </div>
                    </div>
                </Box>
                
            </Modal>
        </>
    );
}

function NoSale(values) {
  const [open, setOpen] = React.useState(false);
  const [closingReasonId, setClosingReasonId] = React.useState(0);
  const handleOpen = () => {
      setOpen(true);
      if(this.state.closingReasons.length < 1){
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': this.state.tokenProducts
        };
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getclosingreasons',
        {
            'headers': headers
        })
        .then(response => {
            const data = response.data.data;
            this.setState({ closingReasons: data, loading: false });
            // order alphabetically
            this.state.closingReasons.sort((a,b) => a.description < b.description ? -1 : +(a.description > b.description))
        })
        .catch(function (error) {
            console.log(error);
        });
    }
    };
    const handleClose = () => {
        setOpen(false);
    };
    let loading = true;
  
    const onNosale = () => {
        this.SaveNoSale();
        handleClose();
    }

   const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };
    
  return (
    <div>
      <button onClick={handleOpen} style={{width:100}} className="m-2 px-3 btn btn-danger">{values.data.resources.Label_NoSale}</button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2" className='text-center'>
            {values.data.resources.Label_NoSale}
          </Typography>
          <hr></hr>
          
            <div className="dis-flex">
                <div className="col-md-6">
                    <label>{values.data.resources.Label_NoSaleReason}</label>
                    <select className="form-control col-md-12" data-val="true" name="closingReasonId" defaultValue={this.state.closingReasonId} onChange={this.onChangeClosingReasonId} > {/** */}
                        <option value="">{values.data.resources.Label_SelectOption}</option>
                        {this.state.closingReasons.map(closing =>
                            // closing.enabled &&
                            <option key={closing.id} value={closing.id}>
                                {closing.description}
                            </option>
                        )}
                    </select>
                </div>
                
                <div className="col-md-6">
                    <label>{values.data.resources.Label_ContactAgain}</label>
                    <input type="datetime-local"  className="form-control col-md-12" onChange={this.onContactAgain}/>
                </div>
            </div>
            <div className="m-2 p-1">
                <label>{values.data.resources.Label_Comments}</label>
                <textarea className="form-control col-md-12" maxLength={100} onChange={this.onChangeCommnets}></textarea>
                <div className="row m-0">
                        <div className="col-md-6">
                        </div>
                        <div className="col-md-6" style={{textAlign:'right' }}>
                            {
                                this.state.comments.charAt(0) && <label id="lblComentaries"> {this.state.resources.Label_Remain} {this.state.remainComentaries} {this.state.resources.Label_Chararter} </label>
                            }
                            
                        </div>
                </div>
            </div>
            <div className="text-center">
                <button className="btn btn-primary" disabled={this.state.closingReasonId < 1} onClick={onNosale}>
                    {values.data.resources.ButtonLabel_Save}
                </button>
            </div>
                  
        </Box>
      </Modal>
    </div>
  );
}
