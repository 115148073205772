import React, { Component } from 'react';
import axios from 'axios';
import helpers from '../../Helpers';
import '../Companies/Company.css';
import $ from 'jquery';
import Button from '@material-ui/core/Button';

export class ProductCountryCreate extends Component {
    static displayName = ProductCountryCreate.name;

    constructor(props) {
        super(props);

        this.onChangeName = this.onChangeName.bind(this);
        this.onChangeStock = this.onChangeStock.bind(this);
        this.onChangeCourierCost = this.onChangeCourierCost.bind(this);
        this.onChangeCourierId = this.onChangeCourierId.bind(this);
        this.onChangeCountryId = this.onChangeCountryId.bind(this);
        this.onChangeProductId = this.onChangeProductId.bind(this);
        this.onChangeShippingCost = this.onChangeShippingCost.bind(this);
        this.onChangeProductionCost = this.onChangeProductionCost.bind(this);
        this.onChangeEnabled = this.onChangeEnabled.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onSubmit = this.onSubmit.bind(this);  
        this.onChangeRoyalties = this.onChangeRoyalties.bind(this);
        this.onChangeSalesAreaId = this.onChangeSalesAreaId.bind(this);
        this.removeSalesAreaItem = this.removeSalesAreaItem.bind(this);

        this.state = {
            id: 0,
            name: ' ',
            resources: [],
            products: [],
            productId: 0,
            countryId: 0,
            countries: [],
            shippingCost: 0,
            couriers: [],
            courierId: null,
            courierCost: 0,
            stock: 0,
            productionCost: 0,
            enabled: true,
            loading: true,
            userId: 0,
            royalties: 0,
            salesAreas: [],
            subareas: [],
            salesAreasIds: [],
        };
    }

    componentDidMount() {
        this.getResources();
        this.populateData();
        helpers.setNavActive("#nav-product-country");
        helpers.setClientValidation();

        let currentUser = helpers.getUserData();
        this.setState({
            userId: currentUser.id,
            productId: localStorage.getItem('currentProductId') ? JSON.parse(localStorage.getItem('currentProductId')) : 0,
        });

        document.querySelector('#salesAreaError').style.display = 'none';

        // // Jquery Dependency

        $("input[data-type='currency']").on({
            keyup: function() {
            formatCurrency($(this));
            },
            blur: function() { 
            formatCurrency($(this), "blur");
            }
        });


        function formatNumber(n) {
        // format number 1000000 to 1,234,567
        return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }


        function formatCurrency(input, blur) {
        // appends $ to value, validates decimal side
        // and puts cursor back in right position.
        
        // get input value
        var input_val = input.val();
        
        // don't validate empty input
        if (input_val === "") { return; }
        
        // original length
        var original_len = input_val.length;

        // initial caret position 
        var caret_pos = input.prop("selectionStart");
            
        // check for decimal
        if (input_val.indexOf(".") >= 0) {

            // get position of first decimal
            // this prevents multiple decimals from
            // being entered
            var decimal_pos = input_val.indexOf(".");

            // split number by decimal point
            var left_side = input_val.substring(0, decimal_pos);
            var right_side = input_val.substring(decimal_pos);

            // add commas to left side of number
            left_side = formatNumber(left_side);

            // validate right side
            right_side = formatNumber(right_side);
            
            // On blur make sure 2 numbers after decimal
            if (blur === "blur") {
            right_side += "00";
            }
            
            // Limit decimal to only 2 digits
            right_side = right_side.substring(0, 2);

            // join number by .
            input_val = left_side + "." + right_side; //"$" + 

        } else {
            // no decimal entered
            // add commas to number
            // remove all non-digits
            input_val = formatNumber(input_val);
            // input_val = "$" + input_val;
            
            // final formatting
            if (blur === "blur") {
            input_val += ".00";
            }
        }
        
        // send updated string to input
        input.val(input_val);

        // put caret back in the right position
        var updated_len = input_val.length;
        caret_pos = updated_len - original_len + caret_pos;
        input[0].setSelectionRange(caret_pos, caret_pos);
        }
    }

    async onSubmit(e) {
        e.preventDefault();

        if (this.state.salesAreas.length == 0) {
            document.querySelector('#salesAreaError').style.display = 'block';
            return;
        }

        var form = document.querySelectorAll('.needs-validation')[0];

        if (!form.checkValidity())
            return;

        this.setState({ loading: true });

        let array = [];
        this.state.salesAreas.map(item => {
            array.push(item.id);
        });
        
        let authTokenProducts = await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME);
        const data = {

            name: this.state.name,
            country_id: this.state.countryId,
            product_id: this.state.productId,
            shippingcost: this.state.shippingCost,
            manufacturingcost: this.state.productionCost,
            courier_id: this.state.courierId,
            couriercost: this.state.courierCost,
            stock: this.state.stock,
            enabled: this.state.enabled,
            creation_user_id: this.state.userId,
            royalties: this.state.royalties,
            SubAreasSalesId: array.toString()
        };
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME)
        };

        axios.post(window.config.REACT_APP_PRODUCTS_API_URL + '/productcountry/save', data, {
            headers: headers
        })
            .then((response) => {
                helpers.notifySuccess(this.state.resources.Label_Success, this.state.resources.SuccessMessage_DataSavedSuccessfully);
                //this.props.history.push('/product-country');
                axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/productcountry/getproductbyid/' + this.state.productId,
                {
                    'headers': { 'Authorization': authTokenProducts }
                })
                .then(response => {
                //   console.log(response.data.data)
                  let data = response.data.data;
                  let countriesArr = [];
                        for (let i = 0; i < data.length; i++) {
                            if (data[i].country_name) {
                                countriesArr.push(
                                    {
                                        'country': data[i].country_name,
                                        'enabled': data[i].enabled 
                                    }
                                );
                            }
                        }
                    localStorage.setItem('dataCountriesProductInfo', JSON.stringify(countriesArr));

                    setTimeout(() => {
                        // window.location.href = "/products-info/" + this.state.productId;
                        this.props.history.go('-1')
                    }, 700);
                })
                .catch(function (error) {
                    console.log(error);
                });
            })
            .catch((error) => {
                console.log(error);

                let msg = this.state.resources.ErrorMessage_UnknownError;
                if (error.response.data.error_code == window.errorCodes.DuplicatedEntityName)
                    msg = this.state.resources.ErrorMessage_DuplicatedName;
                else if (error.response.data.error_code == window.errorCodes.AssociatedEntity)
                    msg = this.state.resources.ErrorMessage_AssociatedEntity_Company_User;

                helpers.notifyError(this.state.resources.Label_Error, msg);
                this.setState({ loading: false });
            });
    }  

    onCancel(e) {
        e.preventDefault();
        // this.props.history.push("/product-country");
        this.props.history.go('-1')
    }

    onChangeName(e) {
        this.setState({
            name: e.target.value
        });
    }  
    onChangeStock(e) {
         this.setState({
            stock: e.target.value
        });
    } 

    onChangeShippingCost(e) {
        this.setState({
            shippingCost: e.target.value
        });
    }

    onChangeProductionCost(e) {
        this.setState({
            productionCost: e.target.value
        });
    }
    
    onChangeCountryId(e) {
        this.setState({
            countryId: e.target.value
        });
    }

    onChangeProductId(e) {
        this.setState({
            productId: e.target.value
        });
    }

    onChangeCourierCost(e) {
        this.setState({
            courierCost: e.target.value
        });
    }

    onChangeCourierId(e) {
        this.setState({
            courierId: e.target.value
        });
    }


    onChangeEnabled(e) {
        this.setState({
            enabled: e.target.checked
        });
    } 

    onChangeRoyalties(e) {
        this.setState({
            royalties: e.target.value
        });
    }

    onChangeSalesAreaId(e) { 
        // console.log(JSON.parse(e.target.value));
        document.querySelector('#salesAreaError').style.display = 'none';
        let item = JSON.parse(e.target.value);
        if (item !== -1) {
            if (this.state.salesAreas.length > 0) {
                let newArray = this.state.salesAreas;
                let count = 0;
                for (let i = 0; i < newArray.length; i++) {
                    if (newArray[i].id == item.id) {
                        count++;
                    }
                }
                if (count == 0) {
                    newArray.push(item);
                }
                // console.log(newArray);
                this.state.salesAreas = newArray;
                // console.log(this.state.salesAreas);
                this.setState({
                    salesAreas: newArray
                });
            } else {
                this.setState({
                    salesAreas: this.state.salesAreas.concat(item)
                });
                this.state.salesAreas = this.state.salesAreas.concat(item);
            }
        }   
    }

    removeSalesAreaItem(e) {
        // e.preventDefault();
        if (e.target?.value) {
            let item = JSON.parse(e.target.value);
            // console.log(item);    
            let newArray = this.state.salesAreas;
            for (let i = 0; i < newArray.length; i++) {
                if (newArray[i].id == item.id) {
                    newArray.splice(i, 1);
                }
            }
            // console.log(newArray);
            this.setState({
                salesAreas: newArray
            });
        }
        
    }

    render() {
        let contents =
            <form className="form row g-3 needs-validation" onSubmit={this.onSubmit} noValidate>
                {/*ENABLED*/}
                <div className="form-group col-md-12">
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" id="enabled" checked={this.state.enabled} onChange={this.onChangeEnabled} />
                        <label className="form-check-label" htmlFor="enabled">{this.state.resources.Label_Enabled}</label>
                        <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                    </div>
                </div>

                <div className="form-group col-md-4" >

                    <label className=" form-label">{this.state.resources.Label_Product}</label>
                     <select className="form-control"  disabled="true" data-val="true" name="productid" defaultValue={this.state.productId} onChange={this.onChangeProductId} required>
                        <option value="">{this.state.resources.Label_SelectOption}</option>
                        {this.state.products.map(product =>
                            <option key={product.id} selected={product.id === this.state.productId} value={product.id}>{product.name}</option>
                        )}
                    </select>
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>

                    <label className=" form-label">{this.state.resources.Title_Countries}</label>
                    <select className="form-control" data-val="true" name="countryid" defaultValue={this.state.countryId} onChange={this.onChangeCountryId} required>
                        <option value="">{this.state.resources.Label_SelectOption}</option>
                        {this.state.countries.map(country =>
                            <option key={country.id} value={country.id}>{country.name}</option>
                        )}
                    </select>
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                
                    

                    <label className=" form-label">{this.state.resources.Label_Name}</label>
                    <input className="form-control" type="text" name="name" onChange={this.onChangeName} value={this.state.name} />

                </div>
               
                <div className="form-group col-md-4" >

                    <label className=" form-label">{this.state.resources.Label_ShippingCost}</label>
                    <input className="form-control" type="text" name="shippingcost" onChange={this.onChangeShippingCost} value={this.state.shippingCost} required />
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>


                    <label className=" form-label">{this.state.resources.Title_Courier}</label>
                    <select className="form-control" data-val="true" name="courierid" defaultValue={this.state.courierId} onChange={this.onChangeCourierId}>
                        <option value="">{this.state.resources.Label_SelectOption}</option>
                        {this.state.couriers.map(courier =>
                            this.state.countryId == courier.country_id ?
                                <option key={courier.id} value={courier.id}>{courier.name}</option>
                                :
                                <></>
                        )}
                    </select>
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>

                    <label className=" form-label">{this.state.resources.Label_CourierCost}</label>
                    <input className="form-control" type="text" name="couriercost" onChange={this.onChangeCourierCost} value={this.state.courierCost} required />
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                    
                </div>
                <div className=" form-group col-md-4">
                    
                     <label className=" form-label">Stock</label>
                    <input className="form-control" type="number" name="stock" onChange={this.onChangeStock} value={this.state.stock} required />
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                    

                    <label className=" form-label">{this.state.resources.Label_ProductionCost}</label>
                    <input className="form-control" type="text" name="productioncost" onChange={this.onChangeProductionCost} value={this.state.productionCost} required />
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>

                    <label className=" form-label">{this.state.resources.Label_Royalties}</label>
                    <input className="form-control" type="number" data-type="currency" name="royalties" onChange={this.onChangeRoyalties} value={this.state.royalties} required />
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                </div>
                <div className="form-group col-md-4">
                    <label className=" form-label">{this.state.resources.Label_SalesArea}</label>
                    <select disabled={this.state.viewMode} className="form-control" name="salesareaid" onChange={this.onChangeSalesAreaId}> {/*defaultValue={this.state.salesAreaId}*/}
                        <option value={JSON.stringify(-1)}>{this.state.resources.Label_SelectOption}</option> 
                        {this.state.subareas.map(subarea => (
                            <option key={subarea.id} value={JSON.stringify(subarea)}>{subarea.name}</option>
                        ))}
                    </select>
                    <div id="salesAreaError" style={{color: 'red'}}>{helpers.getUiLanguage() == 'language1' ? 'Debe ingresar al menos un área de venta' : 'You must add at least one Sale Area'}</div>
                </div>
                <div className="form-group col-md-4 p-3 m-2" style={{borderRadius: '10px', border: '1px solid #80808054', display: 'flex', flexFlow: 'wrap'}} required>
                    {this.state.salesAreas.map(salesArea => (
                        <div className="p-2 m-2" style={{ backgroundColor: 'rgb(255 13 36 / 58%)', borderColor: '#dc3545', display: 'flex', maxWidth: '190px', maxHeight: '50px', justifyContent: 'space-between'}}>
                            <span className="pt-2">{salesArea.name}</span>
                            <button className='btn' type='button' value={JSON.stringify(salesArea)} onClick={this.removeSalesAreaItem}>X</button>
                        </div>
                    ))}
                </div>


                {/**Buttons Header */}
                 <div className="buttons-head row">
                    <div className=" buttons-childs">
                        <button type="submit" className="btn btn-primary">{this.state.resources.ButtonLabel_Save}</button>
                        <button className="btn btn-danger" onClick={this.onCancel}>{this.state.resources.ButtonLabel_Cancel}</button>
                    </div>
                 </div>
            </form>;

        return (

           <div>
                
           
            <div className="mt-2">

                <ol className="breadcrumb my-4">
                    <li className="breadcrumb-item active">{this.state.resources.Label_ProductCountry}</li>
                </ol>
                <div className="card">
                    <div className="card-body">
                        {this.state.loading ? helpers.showLoadingOverlay() : helpers.hideLoadingOverlay()}
                        {contents}
                    </div>
                </div>
                </div>
        </div> 
        );
    }

    async getResources() {
        const resourceVars = [];
        resourceVars.push("Label_Product");
        resourceVars.push("Label_Name");
        resourceVars.push("Label_Description");
        resourceVars.push("Label_BusinessArea");
        resourceVars.push("Label_Enabled");
        resourceVars.push("Label_Success");
        resourceVars.push("Label_SelectOption");
        resourceVars.push("ButtonLabel_Save");
        resourceVars.push("ButtonLabel_Cancel");
        resourceVars.push("Label_Error");
        resourceVars.push("ErrorMessage_RequiredField");
        resourceVars.push("ErrorMessage_DuplicatedName");
        resourceVars.push("ErrorMessage_UnknownError");
        resourceVars.push("ErrorMessage_AssociatedEntity_Company_User");
        resourceVars.push("ErrorMessage_ErrorWhileUploadingImage");
        resourceVars.push("ErrorMessage_InvalidEmail");
        resourceVars.push("SuccessMessage_DataSavedSuccessfully");
        resourceVars.push("Label_ProductCountry");
        resourceVars.push("Title_Courier");
        resourceVars.push("Title_Countries");
        resourceVars.push("Label_ShippingCost");
        resourceVars.push("Label_ProductionCost");
        resourceVars.push("Label_CourierCost");

        const headers = {
            'Content-Type': 'application/json'
        };

        axios.post(window.config.REACT_APP_SECURITY_API_URL + '/languageresource/getbyvars/' + helpers.getUiLanguage(), resourceVars, {
            headers: headers
        })
            .then((response) => {
                const data = response.data.data;
                this.setState({ resources: data, loading: false });
            })
            .catch((error) => {
                console.log(error);
                this.setState({ loading: false });
            });
    }

    async populateData() {
        this.setState({
            loading: true
        });

        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/product/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ products: data, loading: false });
                // order alphabetically
                this.state.products.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                // console.log(this.state.brands);
            })
            .catch(function (error) {
                console.log(error);
            });

        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/country/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                // console.log(data);
                this.setState({ countries: data, loading: false });
                
                // order alphabetically
                this.state.countries.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                // console.log(this.state.countries);
            })
            .catch(function (error) {
                console.log(error);
            });

        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/courier/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                // console.log(data);
                this.setState({ couriers: data, loading: false });
                // order alphabetically
                this.state.couriers.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                // console.log(this.state.couriers);
            })
            .catch(function (error) {
                console.log(error);
            });
         axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/subarea/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                let data = response.data.data;
                data = data.filter(item => item.generates_sale);
                this.setState({ subareas: data, loading: false });
                // order alphabetically
                this.state.subareas.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                // console.log(this.state.subareas);
            })
            .catch(function (error) {
                console.log(error);
            });
        
    }
}
