import React, { Component } from 'react';
import axios from 'axios';
import helpers from '../../Helpers';
import $ from 'jquery';
import queryString from 'query-string';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { styled } from '@mui/material/styles';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Button from '@mui/material/Button';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { RemoveShoppingCart } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import startsWith from 'lodash.startswith';
import RutInput from '../RutInput/RutInput';
import { padding } from '@material-ui/system';
import _assertThisInitialized from '@babel/runtime/helpers/assertThisInitialized';
const Swal = require('sweetalert2')

export class ClientWsp extends Component {
    static displayName = ClientWsp.name;

    constructor(props) {
        super(props);

        this.onChangeState = this.onChangeState.bind(this);
        this.onChangeCity = this.onChangeCity.bind(this);
        this.onChangeName = this.onChangeName.bind(this);
        this.onChangeLastName = this.onChangeLastName.bind(this);
        this.onChangeBetweenStreets = this.onChangeBetweenStreets.bind(this);
        this.onChangeDocType = this.onChangeDocType.bind(this);
        this.onChangeDocNumber = this.onChangeDocNumber.bind(this);
        this.onChangePhone = this.onChangePhone.bind(this);
        this.onChangeMobilePhone = this.onChangeMobilePhone.bind(this);
        this.onChangeSex = this.onChangeSex.bind(this);
        this.onChangeDateOfBirth = this.onChangeDateOfBirth.bind(this);
        this.onChangeEmail = this.onChangeEmail.bind(this);
        this.onChangeHaventEmail = this.onChangeHaventEmail.bind(this);
        this.onChangeAddress = this.onChangeAddress.bind(this);
        this.onChangeNumber = this.onChangeNumber.bind(this);
        this.onChangeFloor = this.onChangeFloor.bind(this);
        this.onChangeDepartment = this.onChangeDepartment.bind(this);
        this.onChangePostalCode = this.onChangePostalCode.bind(this);
        this.onChangeCourierComentaries = this.onChangeCourierComentaries.bind(this);
        this.onChangeInsideComentaries = this.onChangeInsideComentaries.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.goToOrder = this.goToOrder.bind(this);
        this.onChangeClosingReasonId = this.onChangeClosingReasonId.bind(this);
        this.onContactAgain = this.onContactAgain.bind(this);
        this.onChangeCommnets = this.onChangeCommnets.bind(this);
        this.SaveNoSale = this.SaveNoSale.bind(this);
        this.onChangeCreditCard = this.onChangeCreditCard.bind(this);
        this.onChangeClear= this.onChangeClear.bind(this);

        SearchClient = SearchClient.bind(this);
        NoSale = NoSale.bind(this);
        HistoryClient = HistoryClient.bind(this);

        this.setInfoClient = this.setInfoClient.bind(this);
        this.setInfoDestination = this.setInfoDestination.bind(this);

        this.state = {
            steps: ['Inicio', 'Cliente', 'Pedido', 'Pago', 'Checkout'],
            activeStep: 1,
            resources: [],
            countryId: JSON.parse(localStorage.getItem('DataWorkspace')).state.countryId,
            countryName: '',
            documentType: [],
            documentTypeId: 0,
            states: [],
            stateId: 0,
            cities: [],
            cityId: 0,
            clients: [],
            clientId: null,
            name: '',
            lastName: '',
            betweenstreets:'',
            phone: '',
            mobilePhone: '',
            documentNumber: '',
            sex: '',
            dateOfBirth: '',
            email: '',
            haventMail: '',
            adress: '',
            number: null,
            floor: '',
            department: '',
            postalCode: '',
            courierComentaries: '',
            insideComentaries: '',
            closingReasons: [],
            closingReasonId: 0,
            treatmentQuestions: [],
            mapCourierCities: [],
            phone: '',
            name: '',
            answersTreatmentQuestions: [],
            language: '',
            comments: '',
            contactAgain: null,
            hasCreditCard: false,
            invalidRutMsg: false,
            phoneFormat: '',
            clientSearched: false,
            historyClientData: [],
            disabledForm: false,
            tokenProducts: "",
            clientOccId:null,
        };
    }

    componentDidMount() {
        document.querySelector('#btnInfoClient').classList.add('active');
        document.querySelector("#errorBtnClient").style.display = 'none';
        document.querySelector("#errorBtnDestination").style.display = 'none';
        document.querySelector('#lblCourierComentaries').style.display = 'none';
        document.querySelector('#lblInsideComentaries').style.display = 'none';  

        let data = JSON.parse(localStorage.getItem('DataWorkspace'));
        let completeName = data.state.name?.split(' ') ?? "";
        this.setState({
            countryId: data.state.countryId,
            // closingReasons: data.state.closingReasons,
            name: completeName[0],
            lastName: completeName[1],
            phone: data.state.phone,
            tokenProducts: data.state.tokenProducts,
        });
        this.state.tokenProducts = data.state.tokenProducts;
        this.state.name = completeName[0];
        this.state.lastName = completeName[1];
        this.state.phone = data.state.phone;
        
        let infoClient = document.querySelector('#infoClientDiv');
        let infoDestination = document.querySelector('#infoDestinationDiv');

        infoClient.style.display = 'flex';
        infoDestination.style.display = 'none';

        document.querySelector("#inputRut").style.display = 'none';
        this.getResources();
        if (localStorage.getItem('DataClientWsp')) {
            let clientData = JSON.parse(localStorage.getItem('DataClientWsp'));
            this.setState({
                documentTypeId: clientData.documentTypeId,
                stateId: clientData.stateId,
                cityId: clientData.cityId,
                name: clientData.name,
                lastName: clientData.lastName,
                betweenstreets: clientData.betweenstreets,
                phone: clientData.phone,
                mobilePhone:clientData.mobilePhone, 
                documentNumber: clientData.documentNumber,
                sex: clientData.sex,
                dateOfBirth: clientData.dateOfBirth,
                email: clientData.email,
                haventMail: clientData.haventMail,
                adress: clientData.adress,
                number: clientData.number,
                floor: clientData.floor,
                department: clientData.department,
                postalCode: clientData.postalCode,
                courierComentaries: clientData.courierComentaries,
                insideComentaries: clientData.insideComentaries,
                disabledForm: true
            });
            if (clientData.documentTypeId == 1) {
                    document.querySelector("#inputRut").style.display = 'block';
                    document.querySelector("#inputDocNumber").style.display = 'none';
                }

            this.state.number = clientData.number;

            if(clientData.stateId){
                const headers = {
                    'Content-Type': 'application/json',
                    'Authorization': this.state.tokenProducts
                };
                axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getcitiesbystate/'+ clientData.stateId,
                {
                    'headers': headers
                })
                .then(response => {
                    const data = response.data.data;
                    data.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                    this.setState({ cities: data, loading: false });
                    // order alphabetically
                    this.state.cities.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                })
                .catch(function (error) {
                    console.log(error);
                });
            }
        }

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': data.state.tokenProducts
        };
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getcountrybyid/' + this.state.countryId,
        {
            headers: headers
        })
        .then(response => {
            const data = response.data.data;
            this.setState({
                phoneFormat: data.phone_format != null ? data.phone_format : ''
            });
        })
        .catch(function (error) {
            console.log(error);
            helpers.hideLoadingOverlay();
        }) 

        document.querySelector("#mailError").style.display = 'none';
        document.querySelector("#phoneError").style.display = 'none';
        document.querySelector("#mobilephoneError").style.display = 'none';
        document.querySelector("#nameError").style.display = 'none';
        document.querySelector("#lastNameError").style.display = 'none';
        document.querySelector("#documentTypeError").style.display = 'none';
        document.querySelector("#cityError").style.display = 'none';
        document.querySelector("#stateError").style.display = 'none';
        document.querySelector("#addressError").style.display = 'none';
        document.querySelector("#addressNumberError").style.display = 'none';
        document.querySelector("#sexError").style.display = 'none';
        document.querySelector('#dateBirthError').style.display = 'none';
       // document.querySelector('#lblComentaries').style.display = 'none';

    }

    onCancel(e) {
        e.preventDefault();
        localStorage.setItem('DataClientWsp',JSON.stringify(this.state));
        this.props.history.goBack();
    }

    goToOrder(e) {
        e.preventDefault();
        let emailRegex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
        let phoneOK = false;

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': this.state.tokenProducts
        };

        if(this.state.phoneFormat.charAt(0) != "")
        {
            let phoneRegex = new RegExp(this.state.phoneFormat);
            if (phoneRegex.test(this.state.phone) == true) 
            {
                phoneOK = true;
                document.querySelector("#mobilephoneError").style.display = 'none';
                document.querySelector("#phoneError").style.display = 'none';
            }
            else if(phoneRegex.test(this.state.mobilePhone) == true)
            {
                phoneOK = true;
                document.querySelector("#mobilephoneError").style.display = 'none';
                document.querySelector("#phoneError").style.display = 'none';
            }
            else
            {
                document.querySelector("#mobilephoneError").style.display = 'block';
                document.querySelector("#phoneError").style.display = 'block';
                
                document.querySelector("#errorBtnClient").style.display = 'inline';
                document.querySelector('#btnInfoClient').classList.remove('btn-primary');
                document.querySelector('#btnInfoClient').classList.add('btn-danger');
            }
        }
        
        console.log(this.state.postalCode.length);
        if(this.state.postalCode.length > 0){

            axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getcitiesbyid/' + this.state.postalCode, 
        {
            headers: headers 
        })
            .then((response) => {
                const data = response.data.data;
                //console.log(data)
               if(data.length > 0){
                        if (this.state.name && this.state.name?.charAt(0) && this.state.lastName && this.state.lastName?.charAt(0) && this.state.documentTypeId > 0 && this.state.documentNumber &&
                        this.state.documentNumber?.charAt(0) && this.state.phone > 0 && this.state.adress?.charAt(0) && this.state.number > 0 && this.state.countryId > 0 && this.state.stateId > 0 && this.state.cityId > 0
                        && phoneOK) { //&& this.state.dateOfBirth
                        if (!this.state.email?.charAt(0)) {
                            localStorage.setItem('DataClientWsp',JSON.stringify(this.state));
                            window.location.href = "/order-workspace";
                        }
                        else if (this.state.email?.charAt(0)) {
                            if(emailRegex.test(this.state.email)) {
                                localStorage.setItem('DataClientWsp',JSON.stringify(this.state));
                                window.location.href = "/order-workspace";
                            } else {
                                document.querySelector("#mailError").style.display = 'block';
                                
                                document.querySelector("#errorBtnDestination").style.display = 'inline';
                                document.querySelector('#btnInfoDestination').classList.remove('btn-primary');
                                document.querySelector('#btnInfoDestination').classList.add('btn-danger');
                            }
                        }
                        else {
                            if (!this.state.phone) {
                                document.querySelector("#phoneError").style.display = 'block'; 
                                
                                document.querySelector("#errorBtnClient").style.display = 'inline';
                                document.querySelector('#btnInfoClient').classList.remove('btn-primary');
                                document.querySelector('#btnInfoClient').classList.add('btn-danger');
                            }
                            if (!this.state.mobilePhone) {
                                document.querySelector("#mobilephoneError").style.display = 'block';
                                
                                document.querySelector("#errorBtnClient").style.display = 'inline';
                                document.querySelector('#btnInfoClient').classList.remove('btn-primary');
                                document.querySelector('#btnInfoClient').classList.add('btn-danger');
                            }
                            if (!this.state.name) {
                                document.querySelector("#nameError").style.display = 'block';
                                
                                document.querySelector("#errorBtnClient").style.display = 'inline';
                                document.querySelector('#btnInfoClient').classList.remove('btn-primary');
                                document.querySelector('#btnInfoClient').classList.add('btn-danger');
                            }
                            if (!this.state.lastName) {
                                document.querySelector("#lastNameError").style.display = 'block';
                                
                                document.querySelector("#errorBtnClient").style.display = 'inline';
                                document.querySelector('#btnInfoClient').classList.remove('btn-primary');
                                document.querySelector('#btnInfoClient').classList.add('btn-danger');
                            }
                            if (this.state.documentTypeId < 1) {
                                document.querySelector("#documentTypeError").style.display = 'block';
                                
                                document.querySelector("#errorBtnClient").style.display = 'inline';
                                document.querySelector('#btnInfoClient').classList.remove('btn-primary');
                                document.querySelector('#btnInfoClient').classList.add('btn-danger');
                            }
                            if(this.state.cityId < 1){
                                document.querySelector("#cityError").style.display = 'block';
                                
                                document.querySelector("#errorBtnDestination").style.display = 'inline';
                                document.querySelector('#btnInfoDestination').classList.remove('btn-primary');
                                document.querySelector('#btnInfoDestination').classList.add('btn-danger');
                            }
                            if (this.state.stateId < 1) {
                                document.querySelector("#stateError").style.display = 'block';
                                
                                document.querySelector("#errorBtnDestination").style.display = 'inline';
                                document.querySelector('#btnInfoDestination').classList.remove('btn-primary');
                                document.querySelector('#btnInfoDestination').classList.add('btn-danger');
                            }
                            if (!this.state.adress) {
                                document.querySelector("#addressError").style.display = 'block';
                                
                                document.querySelector("#errorBtnDestination").style.display = 'inline';
                                document.querySelector('#btnInfoDestination').classList.remove('btn-primary');
                                document.querySelector('#btnInfoDestination').classList.add('btn-danger');
                            }
                            if (this.state.number < 1) {
                                document.querySelector("#addressNumberError").style.display = 'block';
                                
                                document.querySelector("#errorBtnDestination").style.display = 'inline';
                                document.querySelector('#btnInfoDestination').classList.remove('btn-primary');
                                document.querySelector('#btnInfoDestination').classList.add('btn-danger');
                            }
                            if (!this.state.sex) {
                                document.querySelector("#sexError").style.display = 'block';
                                
                                document.querySelector("#errorBtnClient").style.display = 'inline';
                                document.querySelector('#btnInfoClient').classList.remove('btn-primary');
                                document.querySelector('#btnInfoClient').classList.add('btn-danger');
                            }
                        }  
                    }  else {
                        if (!this.state.mobilePhone) {
                            document.querySelector("#mobilephoneError").style.display = 'block';
                            document.querySelector("#errorBtnClient").style.display = 'inline';
                            document.querySelector('#btnInfoClient').classList.remove('btn-primary');
                            document.querySelector('#btnInfoClient').classList.add('btn-danger');
                        }
                        if (!this.state.phone) {
                            document.querySelector("#phoneError").style.display = 'block';
                            document.querySelector("#errorBtnClient").style.display = 'inline';
                            document.querySelector('#btnInfoClient').classList.remove('btn-primary');
                            document.querySelector('#btnInfoClient').classList.add('btn-danger');
                        }
                        if (!this.state.name) {
                            document.querySelector("#nameError").style.display = 'block';
                            document.querySelector("#errorBtnClient").style.display = 'inline';
                            document.querySelector('#btnInfoClient').classList.remove('btn-primary');
                            document.querySelector('#btnInfoClient').classList.add('btn-danger');
                        }
                        if (!this.state.lastName) {
                            document.querySelector("#lastNameError").style.display = 'block';
                            document.querySelector("#errorBtnClient").style.display = 'inline';
                            document.querySelector('#btnInfoClient').classList.remove('btn-primary');
                            document.querySelector('#btnInfoClient').classList.add('btn-danger');
                        }
                        if (this.state.documentTypeId < 1) {
                            document.querySelector("#documentTypeError").style.display = 'block';
                            document.querySelector("#errorBtnClient").style.display = 'inline';
                            document.querySelector('#btnInfoClient').classList.remove('btn-primary');
                            document.querySelector('#btnInfoClient').classList.add('btn-danger');
                        }
                        if(this.state.cityId < 1){
                            document.querySelector("#cityError").style.display = 'block';
                            document.querySelector("#errorBtnDestination").style.display = 'inline';
                            document.querySelector('#btnInfoDestination').classList.remove('btn-primary');
                            document.querySelector('#btnInfoDestination').classList.add('btn-danger');
                        }
                        if (this.state.stateId < 1) {
                            document.querySelector("#stateError").style.display = 'block';
                            document.querySelector("#errorBtnDestination").style.display = 'inline';
                            document.querySelector('#btnInfoDestination').classList.remove('btn-primary');
                            document.querySelector('#btnInfoDestination').classList.add('btn-danger');
                        }
                        if (!this.state.adress) {
                            document.querySelector("#addressError").style.display = 'block';
                            document.querySelector("#errorBtnDestination").style.display = 'inline';
                            document.querySelector('#btnInfoDestination').classList.remove('btn-primary');
                            document.querySelector('#btnInfoDestination').classList.add('btn-danger');
                        }
                        if (this.state.number < 1) {
                            document.querySelector("#addressNumberError").style.display = 'block';
                            document.querySelector("#errorBtnDestination").style.display = 'inline';
                            document.querySelector('#btnInfoDestination').classList.remove('btn-primary');
                            document.querySelector('#btnInfoDestination').classList.add('btn-danger');
                        }
                        if (!this.state.sex) {
                            document.querySelector("#sexError").style.display = 'block';
                            document.querySelector("#errorBtnClient").style.display = 'inline';
                            document.querySelector('#btnInfoClient').classList.remove('btn-primary');
                            document.querySelector('#btnInfoClient').classList.add('btn-danger');
                        }
                    } 
               } else{
                //console.log("localidad deshabilitada")
                let msg = localStorage.getItem('WspLanguage') == 'language0' ? `Location disabled, please select a valid location` : `Localidad deshabilitada, favor seleccionar una localidad válida `;
                toast.error(msg, {
                    position: "top-right",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
               }
            })
            .catch((error) => {
                console.log(error);
            });

        }else{
            let msg = localStorage.getItem('WspLanguage') == 'language0' ? `Postal Code  not entered, please select a state and city` : `Codigo Postal no ingresado, favor seleccionar un estado y ciudad`;
            toast.error(msg, {
                position: "top-right",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        
        
    }

    onChangeState(e) {
        e.preventDefault();
        this.setState({
            stateId: e.target.value
        });
        this.setState({
            postalCode: ""
        });
        let stateError = document.querySelector("#stateError");

        if (e.target.value < 1) {
            stateError.style.display = 'block';
        } else {
            stateError.style.display = 'none';
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': this.state.tokenProducts
            };
            axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getcitiesbystate/'+ e.target.value,
            {
                'headers': headers
            })
            .then(response => {
                const data = response.data.data;
                data.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                this.setState({ cities: data, loading: false });
                // order alphabetically
                this.state.cities.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
            })
            .catch(function (error) {
                console.log(error);
            });
        }
        document.querySelector("#errorBtnDestination").style.display = 'none';
        document.querySelector('#btnInfoDestination').classList.remove('btn-danger');
        document.querySelector('#btnInfoDestination').classList.add('btn-primary');

        
    }
    onChangeCity(e) {
        e.preventDefault();
        this.state.cities.map(city =>{
            if(this.state.stateId == city.state_id && city.id == e.target.value)
            {
                this.setState({ 
                    postalCode: city.cp
                });
            }
        })
        this.setState({
            cityId: e.target.value
        });
        let cityError = document.querySelector("#cityError");
        if (e.target.value < 1) {
            cityError.style.display = 'block';
        } else {
            cityError.style.display = 'none';
        }
        document.querySelector("#errorBtnDestination").style.display = 'none';
        document.querySelector('#btnInfoDestination').classList.remove('btn-danger');
        document.querySelector('#btnInfoDestination').classList.add('btn-primary');
    }

    onChangeName(e){
        e.preventDefault(e);
        this.setState({
            name: e.target.value
        });

        let nameError = document.querySelector("#nameError");
        if (e.target.value.charAt(0) == "") {
            nameError.style.display = 'block';
        } else {
            nameError.style.display = 'none';
        }
        document.querySelector("#errorBtnClient").style.display = 'none';
        document.querySelector('#btnInfoClient').classList.remove('btn-danger');
        document.querySelector('#btnInfoClient').classList.add('btn-primary');
    }

    onChangeLastName(e){
        e.preventDefault(e);
        this.setState({
            lastName: e.target.value
        });
        let lastNameError = document.querySelector("#lastNameError");
        if (e.target.value.charAt(0) == "") {
            lastNameError.style.display = 'block';
        } else {
            lastNameError.style.display = 'none';
        }
        document.querySelector("#errorBtnClient").style.display = 'none';
        document.querySelector('#btnInfoClient').classList.remove('btn-danger');
        document.querySelector('#btnInfoClient').classList.add('btn-primary');
    }

    onChangeBetweenStreets(e){
        e.preventDefault(e);
        this.setState({
            betweenstreets: e.target.value
        });
    }

    onChangeDocType(e){
        e.preventDefault(e);
        this.setState({
            documentTypeId: e.target.value
        });
        let documentTypeError = document.querySelector("#documentTypeError");
        if (e.target.value < 1) {
            documentTypeError.style.display = 'block';
        } else {
            documentTypeError.style.display = 'none';
            document.querySelector("#errorBtnClient").style.display = 'none';
            document.querySelector('#btnInfoClient').classList.remove('btn-danger');
            document.querySelector('#btnInfoClient').classList.add('btn-primary');
        }
        if (e.target.value != 1) {
            this.state.invalidRutMsg = false;
            document.querySelector("#inputRut").style.display = 'none';
            document.querySelector("#inputDocNumber").style.display = 'block';
        } else {
            document.querySelector("#inputRut").style.display = 'block';
            document.querySelector("#inputDocNumber").style.display = 'none';
            var Fn = {
                // Valida el rut con su cadena completa "XXXXXXXX-X"
                validaRut : function (rutCompleto) {
                    if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test( rutCompleto ))
                        return false;
                    var tmp 	= rutCompleto.split('-');
                    var digv	= tmp[1]; 
                    var rut 	= tmp[0];
                    if ( digv == 'K' ) digv = 'k' ;
                    return (Fn.dv(rut) == digv );
                },
                dv : function(T){
                    var M=0,S=1;
                    for(;T;T=Math.floor(T/10))
                        S=(S+T%10*(9-M++%6))%11;
                    return S?S-1:'k';
                }
            }
            if (Fn.validaRut(this.state.documentNumber)) {
                this.state.invalidRutMsg = false;
            } else {
                this.state.invalidRutMsg = true;
            }
        }
    }

    onChangeDocNumber(e){
        e.preventDefault(e);
        this.setState({
            documentNumber: e.target.value
        });

        if (this.state.documentTypeId == 1) {
            var Fn = {
                // Valida el rut con su cadena completa "XXXXXXXX-X"
                validaRut : function (rutCompleto) {
                    if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test( rutCompleto ))
                        return false;
                    var tmp 	= rutCompleto.split('-');
                    var digv	= tmp[1]; 
                    var rut 	= tmp[0];
                    if ( digv == 'K' ) digv = 'k' ;
                    return (Fn.dv(rut) == digv );
                },
                dv : function(T){
                    var M=0,S=1;
                    for(;T;T=Math.floor(T/10))
                        S=(S+T%10*(9-M++%6))%11;
                    return S?S-1:'k';
                }
            }
            if (Fn.validaRut(e.target.value)) {
                this.state.invalidRutMsg = false;
            } else {
                this.state.invalidRutMsg = true;
            }
        } else {
            this.state.invalidRutMsg = false;
            document.querySelector("#errorBtnClient").style.display = 'none';
            document.querySelector('#btnInfoClient').classList.remove('btn-danger');
            document.querySelector('#btnInfoClient').classList.add('btn-primary');  
        }
    }

    onChangePhone(e){
        // e.preventDefault(e);
        // console.log(e);
        // if (e.target.value.split("").length > 11) {
        //     return;
        // }
        this.setState({
           phone:e
        });
        
        document.querySelector("#errorBtnClient").style.display = 'none';
        document.querySelector('#btnInfoClient').classList.remove('btn-danger');
        document.querySelector('#btnInfoClient').classList.add('btn-primary');
    }

    onChangeMobilePhone(e){
        // e.preventDefault(e);
        // if (e.target.value.split("").length > 11) {
        //     //  if(phoneError.style.display == 'none') {
        //     //         phoneError.style.display = 'block';
        //     //     }
        //     return;
        // }
        this.setState({
            mobilePhone:e
        });
        document.querySelector("#errorBtnClient").style.display = 'none';
        document.querySelector('#btnInfoClient').classList.remove('btn-danger');
        document.querySelector('#btnInfoClient').classList.add('btn-primary');
    }

    onChangeSex(e){
        e.preventDefault(e);
        this.setState({
            sex: e.target.value
        });
        document.querySelector("#sexError").style.display = 'none';
        document.querySelector("#errorBtnClient").style.display = 'none';
        document.querySelector('#btnInfoClient').classList.remove('btn-danger');
        document.querySelector('#btnInfoClient').classList.add('btn-primary');
    }

    onChangeDateOfBirth(e){
        e.preventDefault(e);
        this.setState({
            dateOfBirth: e.target.value
        });
    }

    onChangeEmail(e){
        e.preventDefault(e);
        this.setState({
            email: e.target.value
        });
        let mailError = document.querySelector("#mailError");
        let emailRegex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
        let arrayemail = e.target.value.split("");
        let emailcount = arrayemail.length;
        if(emailcount == 0){
            mailError.style.display = 'none';
            document.querySelector("#errorBtnDestination").style.display = 'none';
            document.querySelector('#btnInfoDestination').classList.remove('btn-danger');
            document.querySelector('#btnInfoDestination').classList.add('btn-primary');
        }else{
            if (!e.target.value.includes('@') || !e.target.value.includes('.')  || emailRegex.test(e.target.value) == false) {    
                if(mailError.style.display == 'none') {
                    mailError.style.display = 'block';
                }
            } else {
                if(mailError.style.display == 'block') {
                    mailError.style.display = 'none';
                    document.querySelector("#errorBtnDestination").style.display = 'none';
                    document.querySelector('#btnInfoDestination').classList.remove('btn-danger');
                    document.querySelector('#btnInfoDestination').classList.add('btn-primary');
                }                                 
            }
                {
                if (!e.target.value.includes('@') || !e.target.value.includes('.')  || emailRegex.test(e.target.value) == false) {    
                    if(mailError.style.display == 'none') {
                        mailError.style.display = 'block';
                    }
                } else {
                    if(mailError.style.display == 'block') {
                        mailError.style.display = 'none';
                        document.querySelector("#errorBtnDestination").style.display = 'none';
                        document.querySelector('#btnInfoDestination').classList.remove('btn-danger');
                        document.querySelector('#btnInfoDestination').classList.add('btn-primary');
                        }                                 
                }
            }
    }
    }

    onChangeHaventEmail(e){
        this.setState({
            haventMail: e.target.checked
        });
        let mailError = document.querySelector("#mailError");
        if (e.target.checked) {
            if(mailError.style.display == 'block') {
                mailError.style.display = 'none';
                document.querySelector("#errorBtnDestination").style.display = 'none';
                document.querySelector('#btnInfoDestination').classList.remove('btn-danger');
                document.querySelector('#btnInfoDestination').classList.add('btn-primary');
            }
        }
    }

    onChangeAddress(e){
        e.preventDefault(e);
        this.setState({
            adress: e.target.value
        });
        document.querySelector("#addressError").style.display = 'none';
        document.querySelector("#errorBtnDestination").style.display = 'none';
        document.querySelector('#btnInfoDestination').classList.remove('btn-danger');
        document.querySelector('#btnInfoDestination').classList.add('btn-primary');
    }

    onChangeNumber(e){
        e.preventDefault(e);
        this.setState({
            number: e.target.value
        });
        document.querySelector("#addressNumberError").style.display = 'none';
        document.querySelector("#errorBtnDestination").style.display = 'none';
        document.querySelector('#btnInfoDestination').classList.remove('btn-danger');
        document.querySelector('#btnInfoDestination').classList.add('btn-primary');
    }

    onChangeFloor(e){
        e.preventDefault(e);
        this.setState({
            floor: e.target.value
        });
    }

    onChangeDepartment(e){
        e.preventDefault(e);
        this.setState({
            department: e.target.value
        });
    }

    onChangePostalCode(e){
        e.preventDefault(e);
        this.setState({
            postalCode: e.target.value
        });
    }


    onChangeClear(e){
        e.preventDefault(e);
        this.setState({
            disabledForm: false,
            name: '',
            lastName: '',
            documentTypeId: 0,
            documentNumber: '',
            sex: '',
            dateOfBirth: '',
            phone: '',
            mobilePhone: '',
            email: '',
            adress: '',
            number: '',
            floor: '',
            department: '',
            betweenstreets: '',
            stateId:0,
            cityId:0,
            courierComentaries:'',
            insideComentaries:'',
            postalCode:'',
        });
 

        this.state.name = '';
        this.state.lastName = '';
        this.state.documentTypeId = 0;
        this.state.documentNumber = '';
        this.state.sex = '';
        this.state.dateOfBirth = '';
        this.state.phone = '';
        this.state.mobilePhone = '';
        this.state.email = '';
        this.state.adress = '';
        this.state.number = '';
        this.state.floor = '';
        this.state.department = '';
        this.state.betweenstreets = '';
        this.state.stateId = 0;
        this.state.cityId = 0;
        this.state.courierComentaries ='';
        this.state.insideComentaries ='';
        this.state.postalCode ='';

        document.querySelector("#inputRut").style.display = 'none';
        document.querySelector("#inputDocNumber").style.display = 'block';
        this.state.invalidRutMsg = false;
    }

    onChangeCourierComentaries(e){
        e.preventDefault(e);
        this.setState({
            courierComentaries: e.target.value
        });
        let countChararter= e.target.value.length;
        let resta = 150 - countChararter;
        //console.log(resta)
        this.state.remainChararterCourier = resta;

        if(!countChararter== 0){
            document.querySelector('#lblCourierComentaries').style.display = 'block';
            }
            else{
                document.querySelector('#lblCourierComentaries').style.display = 'none';
            }
    }

    onChangeInsideComentaries(e){
        e.preventDefault(e);
        this.setState({
            insideComentaries: e.target.value
        });
        let countChararter= e.target.value.length;
        let resta = 150 - countChararter;
       // console.log(resta)
        this.state.remainChararterInside = resta;

        if(!countChararter== 0){
            document.querySelector('#lblInsideComentaries').style.display = 'block';
            }
            else{
                document.querySelector('#lblInsideComentaries').style.display = 'none';
            }
    }

    onChangeClosingReasonId(e) {
        this.setState({
            closingReasonId: e.target.value
        });
    }

    onContactAgain(e) {
        this.setState({
            contactAgain: e.target.value
        });
    }

    onChangeCommnets(e) {
        this.setState({
            comments: e.target.value
        });

        let countChararter= e.target.value.length;
        //console.log(countChararter)
        let resta = 100 - countChararter;
        //console.log(resta)
        this.state.remainComentaries = resta;
    }
    onChangeCreditCard(e) {
        this.setState({
            hasCreditCard: e.target.value
        });
    }

    setInfoClient(){
        let infoClient = document.querySelector('#infoClientDiv');
        let infoDestination = document.querySelector('#infoDestinationDiv');

        infoClient.style.display = 'flex';
        infoDestination.style.display = 'none';
        document.querySelector('#btnInfoClient').classList.add('active');
        document.querySelector('#btnInfoDestination').classList.remove('active');
    }
    setInfoDestination(){
        let infoClient = document.querySelector('#infoClientDiv');
        let infoDestination = document.querySelector('#infoDestinationDiv');

        infoClient.style.display = 'none';
        infoDestination.style.display = 'flex';

        document.querySelector('#btnInfoClient').classList.remove('active');
        document.querySelector('#btnInfoDestination').classList.add('active');
    }

    SaveNoSale(e) {
        
        if(!this.state.variable == true)
        document.querySelector('#lblComentaries').style.display = 'none';
        let wksData = JSON.parse(localStorage.getItem('DataWorkspace'));
        
        const data = {
            id: wksData.state.currentElement.id,
            country_id: parseInt(wksData.state.params.id_pais),
            id_agent: parseInt(wksData.state.params.id_agente),
            call_index: wksData.state.params.id_datos ?? "",
            call_id: wksData.state.params.id_llamada,
            did: wksData.state.params.id_campania ?? "",
            id_campania_occ : parseInt(wksData.state.params.id_campania_occ ?? "0"),
            pbx_crm: parseInt(wksData.state.params.id_crm ?? "0"),
            pbx_db: parseInt(wksData.state.params.id_db ?? "0"),
            active_step: parseInt(wksData.state.activeStep ?? "0"),
            closingreason_id: this.state.closingReasonId,
            contact_again: this.state.contactAgain,
            comments: this.state.comments,
            product_id: wksData.state.productId,
            treatment_id: wksData.state.treatmentId,
            client_phone: wksData.state.phone,
            client_name: wksData.state.name,
            city_id: wksData.state.stateId,
            credit_card: wksData.state.hasCreditCard
        };

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': this.state.tokenProducts
        };

        for (let i = 0; i < wksData.state.answersTreatmentQuestions.length; i++){
            const auxiliarData = {
                pbxworkspace_id: wksData.state.currentElement?.id,
                question_id: wksData.state.answersTreatmentQuestions[i].id,
                question_name: wksData.state.answersTreatmentQuestions[i].question,
                answer: wksData.state.answersTreatmentQuestions[i].answer,
                enabled: true
            };
            axios.post(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/savequestionanswers', auxiliarData, {
                headers: headers
            })
            .then((response) => { 
                
            })
            .catch((error) => console.log(error));
        }
        
        
        helpers.showLoadingOverlay();
        axios.post(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/save', data, {
            headers: headers
        })
        .then((response) => { 
            setTimeout(() => window.location.href = "/no-sale-workspace", 3000)
            
        })
        .catch((error) => console.log(error));
    }

    render() {

        return (<>
            <ToastContainer></ToastContainer>
            <div className="container">
                 <div className="row">
                    <div className="p-2 my-1">
                        <Box sx={{ width: '100%' }}>
                            <Stepper activeStep={this.state.activeStep} alternativeLabel>
                            {this.state.steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                            ))}
                        </Stepper>
                        </Box>
                    </div>
                </div>
                <div className="row m-0">
                    <div className='dis-flex'>
                            <button className="p-2 btn-primary" style={{border: 'none', fontSize: '0.9rem', marginRight: '2px'}} onClick={this.setInfoClient} id="btnInfoClient">
                                {localStorage.getItem('WspLanguage') == 'language0' ? 'Client Data' : 'Info Cliente'}  <span id="errorBtnClient">(!)</span>
                            </button>

                            <button className="p-2 btn-primary" style={{border: 'none', fontSize: '0.9rem'}} onClick={this.setInfoDestination} id="btnInfoDestination">
                                {localStorage.getItem('WspLanguage') == 'language0' ? 'Destination Data' : 'Info Destino'} <span id="errorBtnDestination">(!)</span>
                            </button>
                    </div>
                </div>
                <div className="row" id="infoClientDiv" style={{border: '2px solid rgb(51 99 205)',borderRadius: '5px', height: '450px'}}>
                    <div className="col-md-6 my-1">
                        <label>{this.state.resources.Label_Name}</label>
                        <input className="form-control mr-1" type="text" maxLength="50" defaultValue={this.state.name} value={this.state.name} name="name"
                            onChange={this.onChangeName} disabled={this.state.disabledForm} />
                        <div style={{color: 'red'}} className="p-1 pl-2" id="nameError">{localStorage.getItem('WspLanguage') == 'language0' ? ' Client Name Required' : ' El Nombre del Cliente es Obligatorio'}</div>
                    </div>
                    <div className="col-md-6 my-1">
                        <label>{this.state.resources.Label_LastName}</label>
                        <input className="form-control mr-1" type="text" maxLength="50" defaultValue={this.state.lastName} value={this.state.lastName} name="lastname"
                            onChange={this.onChangeLastName} disabled={this.state.disabledForm} />
                        <div style={{color: 'red'}} className="pl-2" id="lastNameError">{localStorage.getItem('WspLanguage') == 'language0' ? 'Client Last Name Required' : ' El Apellido del Cliente es Obligatorio'}</div>
                    </div>
                    <div className="col-md-6 my-1">
                        <label>{this.state.resources.Label_DocumentType}</label>
                        <select className="form-control mr-1" data-val="true" name="documentTypeId" onChange={this.onChangeDocType} disabled={this.state.disabledForm}>
                            <option value="">{this.state.resources.Label_SelectOption}</option>
                             {this.state.documentType.map( document =>
                             document.country_id == this.state.countryId && 
                                <option key={document.id} value={document.id} selected={document.id == this.state.documentTypeId}>
                                    {document.type}
                                </option>
                            )} 
                        </select>
                        <div style={{color: 'red'}} className="pl-2" id="documentTypeError">{localStorage.getItem('WspLanguage') == 'language0' ? ' Document Type Required' : ' El Tipo de Documento es Obligatorio'}</div>
                    </div>
                    <div className="col-md-6 my-1">
                        <label>{this.state.resources.Label_DocNumber}</label>
                        <input id="inputDocNumber" className="form-control mx-1" type="text" name="documentNumber" defaultValue={this.state.documentNumber} value={this.state.documentNumber}
                            onChange={this.onChangeDocNumber} disabled={this.state.disabledForm} onKeyDown={(evt) => ["e", "E", "+", "."].includes(evt.key) && evt.preventDefault()}/>
                        
                        <div id="inputRut"><RutInput  documentNumber={this.state.documentNumber} changeDocNumber={this.onChangeDocNumber} /> </div>
                        {this.state.invalidRutMsg ? <p style={{color: 'red'}}>
                            {localStorage.getItem('WspLanguage') == 'language0' ? 'invalid RUT number' : 'RUT no valido, por favor ingresar nuevamente'}</p> 
                        : <></>} 
                    </div>
                    
                    <div className="col-md-6 my-1">
                        <label>{this.state.resources.Label_Sex}</label>
                        <select className="form-control mr-1" data-val="true" name="sex" defaultValue={this.state.sex} onChange={this.onChangeSex} disabled={this.state.disabledForm}>
                            <option value="">{this.state.resources.Label_SelectOption}</option>
                            <option value="M" selected={this.state.sex == 'M'}>{this.state.resources.Label_Male}</option>
                            <option value="F" selected={this.state.sex == 'F'}>{this.state.resources.Label_Female}</option>
                        </select>
                        <div style={{color: 'red'}} class="pl-2" id="sexError">{localStorage.getItem('WspLanguage') == 'language0' ? 'Select a Sex' : 'Seleccione un Sexo'}</div>
                    </div>
                    <div className="col-md-6 my-1">
                        <label>{this.state.resources.Label_DateOfBirth}</label>
                        <input type="date"  className="form-control col-md-12" name="dateOfBirth" defaultValue={this.state.dateOfBirth} value={this.state.dateOfBirth} onChange={this.onChangeDateOfBirth}/>
                        <div style={{color: 'red'}} class="pl-2" id="dateBirthError">{localStorage.getItem('WspLanguage') == 'language0' ? 'Select a Date' : 'Seleccione una Fecha'}</div>
                    </div>
                    <div className="col-md-6 my-1">
                        <label>{this.state.resources.Label_Phone}</label>
                        {/* <input className="form-control mx-1" type="number" name="tel" defaultValue={this.state.phone} value={this.state.phone}
                            onKeyDown={(evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()} onChange={this.onChangePhone}
                            onKeyPress={(evt) => (evt.target.value.split("").length > 10 && evt.preventDefault())} onBlurCapture={this.onChangePhone}/> */}
                        <PhoneInput
                            country={'cl'}
                            value={this.state.phone?.toString()}
                            placeholder="+56"
                            countryCodeEditable={true}
                            onChange={this.onChangePhone}
                            masks={{ cl: '(.) ....-....' }}
                            inputStyle={{ width: '100%' }}
                            onlyCountries={['cl', 'ar', 'mx', 'uk']}
                            isValid={(value, country) => { 
                                if (startsWith(value, country.dialCode) || startsWith(country.dialCode, value)) {
                                    if (document.querySelector('#phoneError')) {
                                        document.querySelector('#phoneError').style.display = 'none';
                                    }
                                    return true;
                                } else {
                                    if (document.querySelector('#phoneError')) {
                                        document.querySelector('#phoneError').style.display = 'block';
                                    }
                                    return false;
                                }
                            }}
                        />       
                            <div style={{color: 'red'}} class="p-1 pl-2" id="phoneError">{this.state.resources.ErrorMessage_InvalidPhoneFormat}</div>
                    </div>
                    <div className="col-md-6 my-1">
                        <label>{this.state.resources.Label_MobilePhone}</label>
                        {/* <input className="form-control mx-1" type="number" name="mobilePhone" defaultValue={this.state.mobilePhone} value={this.state.mobilePhone}
                            onKeyPress={(evt) => (evt.target.value.split("").length > 10 && evt.preventDefault())}
                            onKeyDown={(evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()} onChange={this.onChangeMobilePhone}/> */}
                        <PhoneInput
                            country={'cl'}
                            value={this.state.mobilePhone?.toString()}
                            placeholder="+56"
                            countryCodeEditable={true}
                            onChange={this.onChangeMobilePhone}
                            masks={{ cl: '(.) ....-....' }}
                            inputStyle={{ width: '100%' }}
                            onlyCountries={['cl', 'ar', 'mx', 'uk']}
                            isValid={(value, country) => { 
                                if (startsWith(value, country.dialCode) || startsWith(country.dialCode, value)) {
                                    if (document.querySelector('#mobilephoneError')) {
                                        document.querySelector('#mobilephoneError').style.display = 'none';
                                    }
                                    return true;
                                } else {
                                    if (document.querySelector('#mobilephoneError')) {
                                        document.querySelector('#mobilephoneError').style.display = 'block';
                                    }
                                    return false;
                                }
                            }}
                        />       
                            <div style={{color: 'red'}} class="p-1 pl-2" id="mobilephoneError">{this.state.resources.ErrorMessage_InvalidPhoneFormat}</div>
                    </div>
                    <div className="row m-1">
                        <div>
                            <div className="col-md-7 dis-flex mx-auto">
                                {/* <button className="btn btn-primary p-2 m-1 mr-3" >{this.state.resources.Label_SearchClient}</button> */}
                                <SearchClient data={this.state}/>
                                <HistoryClient data={this.state}/>
                                <div>
                                <button  className="btn btn-primary p-2 m-1 ml-4" style={{width:175}} onClick={this.onChangeClear} >
                                {this.state.resources.Label_ClearClients}
                                </button>
                                </div>
                                {/**<button className="btn btn-primary p-2 m-1 ml-3" disabled={this.state.documentNumber.charAt(0) && this.state.documentTypeId > 0}>
                                    {this.state.resources.Label_HistoryClient}
                                </button> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row" id="infoDestinationDiv" style={{border: '2px solid rgb(51 99 205)',borderRadius: '5px', height: '450px'}}>
                    {/* <h5>{this.state.resources.Label_Info}</h5> */}
                    <div className="col-md-6 mt-1">
                        <label>{this.state.resources.Label_Email}</label>
                        <input className="form-control mr-1" type="email" maxLength="50" name="mail" defaultValue={this.state.email} value={this.state.email} onChange={this.onChangeEmail}/>
                         <div style={{color: 'red'}} class="p-1 pl-2" id="mailError">{this.state.resources.ErrorMessage_InvalidEmail}</div>       
                    </div>
                    <div className="col-md-6 mt-1">
                        <label>{this.state.resources.Label_Address}</label>
                        <input className="form-control" type="text" maxLength="100" name="address" defaultValue={this.state.adress} value={this.state.adress} onChange={this.onChangeAddress}/>
                        <div style={{color: 'red'}} class="p-1 pl-2" id="addressError">{localStorage.getItem('WspLanguage') == 'language0' ? 'Address is Required' : 'La Dirección es Requerida'}</div>
                    </div>
                    <div className="col-md-2 mt-1">
                        <label>{this.state.resources.Label_Number}</label>
                        <input className="form-control mx-1" type="number" name="number" defaultValue={this.state.number} value={this.state.number}
                            onKeyDown={(evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()} onChange={this.onChangeNumber} />
                        <div style={{color: 'red'}} class="p-1 pl-2" id="addressNumberError">{localStorage.getItem('WspLanguage') == 'language0' ? 'Address Number is Required' : 'El Número de Dirección es Requerida'}</div>
                    </div>
                    <div className="col-md-2 mt-1">
                        <label>{this.state.resources.Label_Floor}</label>
                        <input className="form-control mx-1" type="number" name="floorNumber" defaultValue={this.state.floor} value={this.state.floor}
                            onKeyDown={(evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()} onChange={this.onChangeFloor}/>
                    </div>
                    <div className="col-md-2 mt-1">
                        <label>{this.state.resources.Label_Department}</label>
                        <input className="form-control mx-1" type="text" name="departmentNumber" defaultValue={this.state.department} value={this.state.department}
                            onKeyDown={(evt) => ["+", "-", "."].includes(evt.key) && evt.preventDefault()} onChange={this.onChangeDepartment}/>
                    </div>

                    <div className="col-md-6 mt-1">
                        <label>{this.state.resources.Label_BetweenStreets}</label>
                        <input className="form-control mr-1" type="text" maxLength="50" defaultValue={this.state.betweenstreets} value={this.state.betweenstreets} name="betweenstreets"
                        onChange={this.onChangeBetweenStreets}/>
                    </div>

                    <div className="col-md-3 mt-1">
                        <label>{this.state.resources.Label_Country}</label>
                        <select className="form-control" data-val="true" name="countryId" disabled='true'>
                            {/* <option value="">{this.state.resources.Label_SelectOption}</option> */}
                            <option value={this.state.countryId} >{this.state.countryName}</option>
                            
                        </select>
                    </div>
                    <div className="col-md-3 mt-1">
                        <label>{this.state.resources.Label_StateOrProvince}</label>
                        <select className="form-control" data-val="true" name="stateId" onChange={this.onChangeState} defaultValue={this.state.stateId}>
                            <option value="">{this.state.resources.Label_SelectOption}</option>
                            {this.state.states.map(state =>
                                // state.country_id == this.state.countryId &&
                                <option key={state.id} value={state.id} selected={this.state.stateId == state.id}>
                                    {state.name}
                                </option>
                            )}
                        </select>
                        <div style={{color: 'red'}} className="p-1 pl-2" id="stateError">{localStorage.getItem('WspLanguage') == 'language0' ? 'State Required' : ' El Estado es Obligatorio'}</div>
                    </div>
                    <div className="col-md-3 mt-1">
                        <label>{this.state.resources.Label_City}</label>
                        <select className="form-control" data-val="true" name="cityId" onChange={this.onChangeCity} defaultValue={this.state.cityId}>
                            <option value="">{this.state.resources.Label_SelectOption}</option>
                            {this.state.cities.map(city =>
                                city.state_id == this.state.stateId &&
                                <option key={city.id} value={city.id} selected={this.state.cityId == city.id}>
                                    {city.name}
                                </option>
                            )}
                        </select>
                        <div style={{color: 'red'}} className="p-1 pl-2" id="cityError">{localStorage.getItem('WspLanguage') == 'language0' ? 'City Required' : ' La Ciudad es Obligatoria'}</div>
                    </div>
                    
                    <div className="col-md-3 mt-1">
                        <label>{this.state.resources.Label_PostalCode}</label>
                        <input className="form-control" type="text" maxLength="15" disabled="true" name="postalCode" defaultValue={this.state.postalCode} onChange={this.onChangePostalCode}/>
                    </div>
                    <div className="col-md-6">
                        <label>{this.state.resources.Label_CourierComentaries}</label>
                        <textarea className="form-control" rows="2" name="comentaryCourier" maxLength="150" defaultValue={this.state.courierComentaries} value={this.state.courierComentaries}
                        onChange={this.onChangeCourierComentaries}></textarea>
                        <div className="row m-0">
                        <div className="col-md-6">
                                </div>
                        <div className="col-md-6" style={{textAlign:'right' }}>
                            <label id="lblCourierComentaries"> {this.state.resources.Label_Remain} {this.state.remainChararterCourier} {this.state.resources.Label_Chararter} </label>
                        </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <label>{this.state.resources.Label_InsideComentaries}</label>
                        <textarea className="form-control" rows="2" name="comentaryInside" maxLength="150" defaultValue={this.state.insideComentaries} value={this.state.insideComentaries}
                            onChange={this.onChangeInsideComentaries}></textarea>
                    <div className="row m-0">
                        <div className="col-md-6">
                                </div>
                        <div className="col-md-6" style={{textAlign:'right' }}>
                            <label id="lblInsideComentaries"> {this.state.resources.Label_Remain} {this.state.remainChararterInside} {this.state.resources.Label_Chararter} </label>
                        </div>
                        </div>
                    </div>
                </div>

                <div className="row m-1">
                        <div className="mx-auto mb-5">
                        <div className="col-md-4 dis-flex mx-auto">
                            {/*px-3 m-1 mx-2*/}
                            <NoSale id="lblComentaries" data={this.state}/>
                            <div>
                            <button className="btn btn-secondary m-2 px-3" style={{width:100,padding:5}} onClick={this.onCancel}>{this.state.resources.Label_GoBack}</button>
                            </div>
                            <div>
                            <button className="btn btn-success m-2 px-3" style={{width:100,padding:5}} onClick={this.goToOrder}>{this.state.resources.Workspace_Next}</button>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
            </>
        );
    }

    async getResources() {
        const resourceVars = [];
        resourceVars.push("Label_SelectOption");
        resourceVars.push("Label_Name");
        resourceVars.push("Label_LastName");
        resourceVars.push("Label_PostalCode");
        resourceVars.push("Title_City");
        resourceVars.push("Label_City");
        resourceVars.push("Label_Address");
        resourceVars.push("Label_Country");
        resourceVars.push("Label_StateOrProvince");
        resourceVars.push("Label_DocNumber");
        resourceVars.push("Label_DateOfBirth");
        resourceVars.push("Label_Phone");
        resourceVars.push("Label_MobilePhone");
        resourceVars.push("Label_Email");
        resourceVars.push("Label_Description");
        resourceVars.push("Label_Enabled");
        resourceVars.push("Label_Success");
        resourceVars.push("ButtonLabel_Save");
        resourceVars.push("ButtonLabel_Cancel");
        resourceVars.push("Label_Error");
        resourceVars.push("ErrorMessage_RequiredField");
        resourceVars.push("ErrorMessage_DuplicatedName");
        resourceVars.push("ErrorMessage_UnknownError");
        resourceVars.push("ErrorMessage_AssociatedEntity_SubArea_User");
        resourceVars.push("SuccessMessage_DataSavedSuccessfully");
        resourceVars.push("Label_GoBack");
        resourceVars.push("Title_Clients");
        resourceVars.push("Label_Creation");
        resourceVars.push("Label_DocumentType");
        resourceVars.push("Workspace_Next");
        resourceVars.push("Label_NoSale");
        resourceVars.push("Label_Sex");
        resourceVars.push("Label_Male");
        resourceVars.push("Label_Female");
        resourceVars.push("Label_SearchClient");
        resourceVars.push("Label_HistoryClient");
        resourceVars.push("Label_Date");
        resourceVars.push("Label_Product");
        resourceVars.push("Title_Status");
        resourceVars.push("Label_Info");
        resourceVars.push("Label_NoMail");
        resourceVars.push("Label_Number");
        resourceVars.push("Label_Floor");
        resourceVars.push("Label_Department");
        resourceVars.push("Label_CourierComentaries");
        resourceVars.push("Label_InsideComentaries");
        resourceVars.push("Label_DocsRequired");
        resourceVars.push("ButtonLabel_Select");
        resourceVars.push("Label_NoRows");
        resourceVars.push("Error_ClientWsp");
        resourceVars.push("ErrorMessage_InvalidEmail");
        resourceVars.push("ErrorMessage_InvalidPhoneFormat");
        resourceVars.push("Label_BetweenStreets");
        resourceVars.push("Label_ClearClients");
        resourceVars.push("Label_Chararter");
        resourceVars.push("Label_Remain");
        resourceVars.push("Datatables_language_search");
        resourceVars.push("Title_WayPay");

        const headersLang = {
            'Content-Type': 'application/json'
        };

        let wspLanguage = localStorage.getItem('WspLanguage');

        if (wspLanguage == 'language1') {
            this.state.steps[0] = 'Inicio'
            this.state.steps[1] = 'Cliente'
            this.state.steps[2] = 'Pedido'
            this.state.steps[3] = 'Pago'
        } else {
            this.state.steps[0] = 'Start'
            this.state.steps[1] = 'Client'
            this.state.steps[2] = 'Order'
            this.state.steps[3] = 'Payment'
        }

        axios.post(window.config.REACT_APP_SECURITY_API_URL + '/languageresource/getbyvars/' + wspLanguage, resourceVars, {
            headers: headersLang
        })
        .then((response) => {
            const data = response.data.data;
            this.setState({ resources: data, loading: false });
        })
        .catch((error) => {
            console.log(error);
            this.setState({ loading: false });
        });

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': this.state.tokenProducts
        };
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getcountrybyid/' + this.state.countryId,
            {
                'headers': headers
            })
            .then(response => {
                const data = response.data.data;
                this.setState({
                    countryName: data.name
                })
            })
            .catch(function (error) {
                console.log(error);
            });

            axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getdocumenttype/', 
            {
                'headers': headers
            })
            .then(response => {
                const data = response.data.data;
                this.setState({
                    documentType: data
                })
                this.state.documentType.sort((a,b) => a.type< b.type? -1 : +(a.type > b.type))
            })
            .catch(function (error) {
                console.log(error);
            });

            let dataWsp = JSON.parse(localStorage.getItem('DataWorkspace'));
        
            axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getstates/' + dataWsp.state.countryId,
            {
                'headers': headers
            })
            .then(response => {
                const data = response.data.data;
                // data.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                this.setState({ states: data, loading: false });
                this.state.states = data;
                // order alphabetically
                this.state.states.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                // console.log(this.state.states);
            })
            .catch(function (error) {
                console.log(error);
            });
        
        // axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getcities',
        //     {
        //         'headers': headers
        //     })
        //     .then(response => {
        //         const data = response.data.data;
        //         data.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
        //         this.setState({ cities: data, loading: false });
        //         // order alphabetically
        //         this.state.cities.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
        //     })
        //     .catch(function (error) {
        //         console.log(error);
        //     });
    }
}

function SearchClient(values) {
    
    const [open, setOpen] = React.useState(false);
    const [filter, setFilter] = React.useState('');
    const [clients, setClient] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [searchError, setSearchError] = React.useState(false);
    const [noMatches, setNoMatches] = React.useState(false);
//   const [mapCourierCities, setmapCourierCities] = React.useState([]);

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setClient([]);
        setOpen(false);
    };
  
   const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '50%',
        height: '90%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };
    const headers = {
            'Content-Type': 'application/json',
            'Authorization': this.state.tokenProducts
        };

    const setClientValues = (client) => {
        //console.log(client);
        
            
        this.setState({
            clientId: client.id,
            name: client.name,
            lastName: client.lastname,
            phone: client.work_phone,
            mobilePhone: client.personal_phone,
            documentNumber: client.document_number,
            documentTypeId: client.document_typeid,
            sex: client.sex,
            dateOfBirth: client.date_birth ? client.date_birth.toString().replace('T00:00:00', '') : client.date_birth,
            email: client.email,
            //haventMail: client.email_no,
            adress: client.direction,
            number: client.number,
            floor: client.flat,
            department: client.depto,
            betweenstreets: client.between_street,
            postalCode: client.postal_code,
            countryId: client.countriesid,
            stateId: client.stateid,
            cityId: client.cityid,
            disabledForm : true,
            clientOccId: client.occ_id,
        });
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': this.state.tokenProducts
        };
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getcitiesbystate/'+ client.stateid,
        {
            'headers': headers
        })
        .then(response => {
            const data = response.data.data;
           // console.log(data)
            data.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
            this.setState({ cities: data, loading: false });
            // order alphabetically
            this.state.cities.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name));
            
            data.map(city =>{
                if(client.stateid == city.state_id && city.id == client.cityid)
                {
                    this.setState({ 
                        postalCode: city.cp
                    });
                }
            })
        })
        .catch(function (error) {
            console.log(error);
        });

        document.querySelector("#mailError").style.display = 'none';
        document.querySelector("#phoneError").style.display = 'none';
        document.querySelector("#mobilephoneError").style.display = 'none';
        document.querySelector("#nameError").style.display = 'none';
        document.querySelector("#lastNameError").style.display = 'none';
        document.querySelector("#documentTypeError").style.display = 'none';
        document.querySelector("#cityError").style.display = 'none';
        document.querySelector("#stateError").style.display = 'none';
        document.querySelector("#sexError").style.display = 'none';
        document.querySelector("#addressError").style.display = 'none';
        document.querySelector("#addressNumberError").style.display = 'none';
        document.querySelector("#errorBtnDestination").style.display = 'none';
        document.querySelector('#btnInfoDestination').classList.remove('btn-danger');
        document.querySelector('#btnInfoDestination').classList.add('btn-primary');
        document.querySelector("#errorBtnClient").style.display = 'none';
        document.querySelector('#btnInfoClient').classList.remove('btn-danger');
        document.querySelector('#btnInfoClient').classList.add('btn-primary');
        handleClose();
    }

    const filterClients = (e) => {
        if(filter !== ''){
            setLoading(true);
            axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getclientsfull/' + filter,
            {
                'headers': headers
            })
            .then(response => {
                const data = response.data.data;
                setClient(data);
                setLoading(false);
                if(data.length < 1){
                    setNoMatches(true);
                }else{
                    setNoMatches(false);
                }
            })
            .catch(function (error) {
                console.log(error);
                setLoading(false);
            });
        }else{
            setNoMatches(false);
            setSearchError(true);
        }
    }

    const onChangeFilter = (e) => {
        if(e.target.value !== ''){
            setSearchError(false);
            setNoMatches(false);
            setFilter(e.target.value);
        }else{
            setFilter('');
            setNoMatches(false);
        }
    }
    
    const handleEnter = (e) => {
        if(e.target.value !== ''){
            setNoMatches(false);
            setFilter(e.target.value);
            filterClients();
        }else{
            setSearchError(true);
            setNoMatches(false);
        }
    }
    
  return (
      <div> 
          <button  className="btn btn-primary p-2 m-1 mr-3 " style={{width:175}} onClick={handleOpen}>
              {this.state.resources.Label_SearchClient}
          </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <Typography id="modal-modal-title" variant="h6" component="h2" className='text-center'>
                <th>{values.data.resources.Title_Clients}</th>
            </Typography>
            {searchError ? <p style={{color: 'red', fontSize: '0.95rem'}}>{localStorage.getItem('WspLanguage') == 'language0' ? 'Enter a valid value for the search' : 'Ingrese un valor válido para la búsqueda'}</p>: <></>}
            {noMatches && <p style={{color: 'red', fontSize: '0.95rem'}}>{localStorage.getItem('WspLanguage') == 'language0' ? 'No matches found' : 'No se encontraron coincidencias'}</p>}
          </div>
          <hr></hr>
          <div className="dis-flex col-md-12 my-3">
              <div className='pt-2'>{values.data.resources.Datatables_language_search}</div>
              <div className='col-md-9'>
                  <input className='form-control' onChange={onChangeFilter} onKeyPress={(e) => e.key == 'Enter' && handleEnter(e)}/>
              </div>
              <div>
                  <button className='btn btn-primary' onClick={filterClients}><i class="fas fa-search"></i></button>
              </div>
          </div>

            <div style={{height: "80%", overflowY: 'auto' }}>
                <>
                    <table className='table datatable my-table' aria-labelledby="tabelLabel">
                        <thead>
                            <tr>
                            <th>{this.state.resources.Label_DocNumber}</th>
                            <th>{this.state.resources.Label_Name}</th>
                            <th>{this.state.resources.Label_LastName}</th>
                            <th>{this.state.resources.Label_Phone}</th>
                            <th>{this.state.resources.ButtonLabel_Select}</th>
                            </tr>
                        </thead>
                        <tbody>{/**values.data.clients */}
                            {loading ? <div className="text-center" style={{position: 'absolute', top: '50%', left: '50%'}}><CircularProgress size={'3.6rem'}/></div> : 
                            <>
                            {clients.map(client =>
                                // values.data.documentTypeId == client.document_typeid && values.data.documentNumber == client.document_number ?
                                <tr>
                                    <td>{client.document_number}</td>
                                    <td>{client.name}</td>
                                    <td>{client.lastname}</td>
                                    <td>{client.personal_phone}</td>
                                    <td><button className="btn btn-primary" onClick={() => setClientValues(client)}>{this.state.resources.ButtonLabel_Select}</button></td>
                                </tr>
                                    // : <></>
                            )}</>}
                        </tbody>
                    </table>
                </>

            </div>
                  
        </Box>
      </Modal>
    </div>
  );
}

function NoSale(values) {
  const [open, setOpen] = React.useState(false);
  const [closingReasonId, setClosingReasonId] = React.useState(0);
  const handleOpen = () => {
      setOpen(true);
      if(this.state.closingReasons.length < 1){
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': this.state.tokenProducts//await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_URL)
        };
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getclosingreasons',
        {
            'headers': headers
        })
        .then(response => {
            const data = response.data.data;
            this.setState({ closingReasons: data, loading: false });
            // order alphabetically
            this.state.closingReasons.sort((a,b) => a.description < b.description ? -1 : +(a.description > b.description))
        })
        .catch(function (error) {
            console.log(error);
        });
    }
    };
    const handleClose = () => {
        setOpen(false);
    };
    let loading = true;
    const onNosale = () => {
        this.SaveNoSale();
        handleClose();
    }
//document.querySelector('#lblComentaries').style.display = 'none';

   const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };
    
  return (
    <div>
      <button onClick={handleOpen} style={{width:100, padding:5} } className="m-2 px-3 btn btn-danger">{values.data.resources.Label_NoSale}</button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2" className='text-center'>
            {values.data.resources.Label_NoSale}
          </Typography>
          <hr></hr>
          
            <div className="dis-flex">
                <div className="col-md-6">
                    <label>{values.data.resources.Label_NoSaleReason}</label>
                    <select className="form-control col-md-12" data-val="true" name="closingReasonId" defaultValue={this.state.closingReasonId} onChange={this.onChangeClosingReasonId} > {/** */}
                        <option value="">{this.state.resources.Label_SelectOption}</option>
                        {this.state.closingReasons.map(closing =>
                            // closing.enabled &&
                            <option key={closing.id} value={closing.id}>
                                {closing.description}
                            </option>
                        )}
                    </select>
                </div>
                
                <div className="col-md-6">
                    <label>{values.data.resources.Label_ContactAgain}</label>
                    <input type="datetime-local"  className="form-control col-md-12" onChange={this.onContactAgain}/>
                </div>
            </div>
            <div className="m-2 p-1">
                <label>{values.data.resources.Label_Comments}</label>
                <textarea className="form-control col-md-12" maxLength={"100"} onChange={this.onChangeCommnets }></textarea>
                <div className="row m-0">
                        <div className="col-md-6">
                        </div>
                        <div className="col-md-6" style={{textAlign:'right' }}>
                            {
                                this.state.comments.charAt(0) && <label id="lblComentaries"> {this.state.resources.Label_Remain} {this.state.remainComentaries} {this.state.resources.Label_Chararter} </label>
                            }
                            
                        </div>
                </div>
            </div>
            <div className="text-center">
                <button className="btn btn-primary" disabled={this.state.closingReasonId < 1} onClick={onNosale}>
                    {values.data.resources.ButtonLabel_Save}
                </button>
            </div>
                  
        </Box>
      </Modal>
    </div>
  );
}

function HistoryClient(values) {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const handleOpen = () => {
      setOpen(true);
    //   if(this.state.historyClientData?.length < 1){
          const headers = {
              'Content-Type': 'application/json',
              'Authorization': this.state.tokenProducts
          };
          setLoading(true);
          axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getshoppingcarbydocument/' + this.state.documentTypeId + '/' + this.state.documentNumber,
          {
              'headers': headers
          })
          .then(response => {
            setLoading(false);
            const data = response.data.data;
            data.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
            this.setState({ historyClientData: data.reverse(), loading: false });
           // console.log(this.state.historyClientData)
          })
          .catch(function (error) {
            setLoading(false);
            console.log(error);
          });
    //   }
    };
    const handleClose = () => {
        setOpen(false);
    };

   const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '75%',
        height: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };
    
    // if (!this.state.clientSearched && this.state.documentTypeId > 0) {
    //     const headers = {
    //         'Content-Type': 'application/json',
    //         'Authorization': this.state.tokenProducts
    //     };
    //      axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getclientbydocument/' + this.state.documentTypeId + '/' + this.state.documentNumber, {
    //         headers: headers
    //     })
    //     .then(response => {
    //         const data = response.data.data;
    //         this.setState({
    //             clientSearched: true
    //         });
    //         this.state.clientSearched = true;
    //     })
    //     .catch(function (error) {
    //         console.log(error);
    //     }); 
    // }

  return (
    <div>
      <button onClick={handleOpen} className="btn btn-primary p-2 m-1 ml-3" style={{width:175}} disabled={(this.state.documentNumber.charAt(0) && this.state.documentTypeId > 0) ? false : true}>
            {this.state.resources.Label_HistoryClient}
        </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2" className='text-center'>
            {values.data.resources.Label_HistoryClient}
          </Typography>
          <hr></hr>

          <div  style={{height: "75%", overflowY: 'auto' }}>
            {loading ? <div className="text-center"><CircularProgress /></div>: 
            values.data.documentTypeId > 0 && values.data.documentNumber.charAt(0) ?
                <>
                    <table className='table datatable my-table' aria-labelledby="tabelLabel">
                        <thead>
                            <tr>
                            <th>{this.state.resources.Label_Date}</th>
                            <th>{this.state.resources.Label_Product}</th>
                            <th style={{textAlign: 'right'}}>Total</th>
                            <th>{this.state.resources.Title_WayPay}</th>
                            <th>{this.state.resources.Title_Status}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {values.data.historyClientData.map(client =>
                                // values.data.clientId == client.client_id ?
                                <tr>
                                    <td>{helpers.getDisplayDateTime(client.creation_date)}</td>
                                    <td>{client.ppal_article_name}</td>
                                    <td style={{textAlign: 'right'}}>${Number(client.total).toLocaleString('es-ES')}</td>
                                    <td>{client.waypay}</td>
                                    <td>{client.payment_state_name}</td>
                                </tr>
                                    // : <></>
                            )}
                        </tbody>
                    </table>
                </>
                : 
                <> 
                    <table className='table datatable my-table' aria-labelledby="tabelLabel">
                    <thead>
                            <tr>
                            <th>{this.state.resources.Label_Date}</th>
                            <th>{this.state.resources.Label_Product}</th>
                            <th>{this.state.resources.Title_Status}</th>
                            </tr>
                        </thead>
                        <tbody></tbody>
                    </table>
                </>
            }

            </div>                  
        </Box>
      </Modal>
    </div>
  );
}