import $ from 'jquery';
import { store } from 'react-notifications-component';
import axios from 'axios';
import Cookies from 'universal-cookie';
import { User } from 'oidc-client';
import Moment from 'moment';

const helpers = {
    notifySuccess: function (title, message) {
        store.addNotification({
            title: title,
            message: message,
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 5000,
                onScreen: true
            }
        });
    },

    notifyError: function (title, message) {
        store.addNotification({
            title: title,
            message: message,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 5000,
                onScreen: true
            }
        });
    },

    notifyWarning: function (title, message) {
        store.addNotification({
            title: title,
            message: message,
            type: "warning",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 5000,
                onScreen: true
            }
        });
    },

    notifyInfo: function (title, message) {
        store.addNotification({
            title: title,
            message: message,
            type: "info",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 5000,
                onScreen: true
            }
        });
    },

    getAuthorizationToken: async function (apiName) {
        let userData = this.getUserData();
        let _token = '-';

        for (var i in userData.api_tokens) {
            if (userData.api_tokens[i].api_name === apiName)
                return userData.api_tokens[i].key + ':' + userData.api_tokens[i].token;
        }

        return _token;
    },

    refreshAuthorizationToken: function (apiName, apiToken) {
        let userData = this.getUserData();

        if (apiName !== "") {
            for (var i in userData.api_tokens) {
                if (userData.api_tokens[i].api_name === apiName)
                    userData.api_tokens[i].token = apiToken.token;
            }
        }

        this.saveUserData(userData);

        return true;
    },

    getApiKey: function (apiName) {
        let key = '';
        let userData = this.getUserData();

        if (apiName !== "") {
            for (var i in userData.api_tokens) {
                if (userData.api_tokens[i].api_name === apiName)
                    return userData.api_tokens[i].key;
            }
        }

        return key;
    },

    getApiName: function (url) {
        let apiName = '';

        if (url !== "") {
            if (url.indexOf(window.config.REACT_APP_SECURITY_API_URL) !== -1)
                return "security";
            else if (url.indexOf(window.config.REACT_APP_PRODUCTS_API_URL) !== -1)
                return "products";
        }

        return apiName;
    },

    getUiLanguage: function () {
        let language = localStorage.getItem('uilanguage');

        if (language && language !== "")
            return language;

        return 'language0';
    },

    setUiLanguage: function (key) {
        this.showLoadingOverlay();
        localStorage.setItem('uilanguage', key);

        let userData = this.getUserData();

        const data = { };
        const headers = {
            'Content-Type': 'application/json',
        };
        axios.post(window.config.REACT_APP_SECURITY_API_URL + '/user/changelanguage/' + userData.id + '/' + key, data, {
            headers: headers
        })
            .then((response) => {
                this.hideLoadingOverlay();
                this.loadLanguageResources();
                window.location.reload(false);
            })
            .catch((error) => {
                this.hideLoadingOverlay();
                console.log(error);
            });
    },

    loadLanguageResources: function () {
        // cross app language resources
        const resourceVars = [];
        resourceVars.push("Label_Unauthorized");
        resourceVars.push("WarningMessage_Unauthorized");
        resourceVars.push("Label_AccessDenied");
        resourceVars.push("ErrorMessage_InvalidAuthorizatonToken");

        const headers = {
            'Content-Type': 'application/json'
        };

        axios.post(window.config.REACT_APP_SECURITY_API_URL + '/languageresource/getbyvars/' + this.getUiLanguage(), resourceVars, {
            headers: headers
        })
            .then((response) => {
                const data = response.data.data;
                localStorage.setItem('langresources', JSON.stringify(data));
            })
            .catch((error) => {
                console.log(error);
            });
    },

    getLanguageResources: function () {
        let langResources = [];

        if (localStorage.getItem("langresources")) {
            langResources = JSON.parse(localStorage.getItem("langresources"));
        }

        return langResources;
    },

    showLoadingOverlay: function () {
        $("#loadingOverlay").show();
    },

    hideLoadingOverlay: function () {
        $("#loadingOverlay").fadeOut();
    },

    getDisplayDateTime: function (dateUtc)
    {
        let date = new Date(dateUtc);
        return Moment(date).format("DD/MM/YYYY hh:mm");
    },

    getDisplayDateF: function (date)
    {
        return Moment(date).format("YYYYMMDD");
    },

    getDisplayDateTimeFromUtc: function (dateUtc) {
        let date = new Date(dateUtc);

        var now = new Date();
        let offfset = now.getTimezoneOffset() * -1;

        date.setMinutes(date.getMinutes() + offfset);

        return date.toLocaleString();
    },

    getDisplayDate: function (date) {
        let _date = new Date(date);

        return _date.toLocaleDateString();
    },

    formatDate: function (inputString) {
        let date = new Date(inputString);

        return date.toISOString().split('T')[0];
    },

    setNavActive: function (id) {
        $(".nav-link").removeClass("active");
        $(id).addClass("active");
    },

    toggleSidebar: function () {
        if ($(window).width() <= 990) // mobile
            $("body").toggleClass("sb-sidenav-toggled");
    },

    setClientValidation() {
        // Fetch all the forms we want to apply custom Bootstrap validation styles to
        var forms = document.querySelectorAll('.needs-validation');

        // Loop over them and prevent submission
        Array.prototype.slice.call(forms)
            .forEach(function (form) {
                form.addEventListener('submit', function (event) {
                    if (!form.checkValidity()) {
                        event.preventDefault()
                        event.stopPropagation()
                    }

                    form.classList.add('was-validated')
                }, false)
            });
    },

    setCookie: function (name, value, options) {
        const cookies = new Cookies();

        cookies.set(name, value, options);
    },

    getCookieValue: function (name) {
        const cookies = new Cookies();

        return cookies.get(name);
    },

    removeCookie: function (name) {
        const cookies = new Cookies();

        cookies.remove(name);
    },

    saveUserData: function (userData) {
        sessionStorage.setItem("userdata", JSON.stringify(userData));
    },

    clearUserData: function () {
        sessionStorage.removeItem("userdata");
    },

    getUserData: function () {
        let userData = null;

        if (sessionStorage.getItem("userdata")) {
            userData = JSON.parse(sessionStorage.getItem("userdata"));
        }

        return userData;
    },
}

export default helpers;