import React, { Component } from 'react';
import axios from 'axios';
import $ from 'jquery';
import helpers from '../../Helpers';
import DataCreation from '../DataCreation/DataCreation';

export class DidDetail extends Component {
    static displayName = DidDetail.name;

    constructor(props) {
        super(props);

        this.onChangeDid = this.onChangeDid.bind(this);
        this.onChangeCountry = this.onChangeCountry.bind(this);
        this.onChangeCarrier = this.onChangeCarrier.bind(this);
        this.onChangeEnabled = this.onChangeEnabled.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onSubmit = this.onSubmit.bind(this);  

        this.state = {
            id: 0,
            did: '',
            countries: [],
            countryId: null,
            carriers: [],
            carrierId: null,
            enabled: true,
            loading: true,
            resources: [],
            viewMode: false,
            currentUserId: 0,
        };
    }

    componentDidMount() {
        this.getResources();
        this.populateData();
        helpers.setNavActive("#nav-did");
        helpers.setClientValidation();

        let url = window.location.href;
        if (url.includes('view')) {
            this.setState({
                viewMode: true
            });
        }
        let currentUser = helpers.getUserData();
        this.setState({
            currentUserId: currentUser.id
        });

        let sort = JSON.parse(localStorage.getItem('DidListSorting'))
        localStorage.setItem('CopyDidListSorting', JSON.stringify(sort));

        let paging = JSON.parse(localStorage.getItem('DidListPagination'))
        localStorage.setItem('CopyDidListPaging', JSON.stringify(paging));

        let filtering = JSON.parse(localStorage.getItem('DidListFiltering'))
        localStorage.setItem('CopyDidListFiltering', JSON.stringify(filtering));
    }

    async onSubmit(e) {
        e.preventDefault();

        var form = document.querySelectorAll('.needs-validation')[0];

        if (!form.checkValidity())
            return;

        this.setState({ loading: true });

        const data = {
            id: parseInt(this.props.match.params.id),
            did: this.state.did,
            country_id: this.state.countryId,
            carrier_id: this.state.carrierId,
            enabled: this.state.enabled,
            last_update_user_id: this.state.currentUserId
        };
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME)
        };

        axios.post(window.config.REACT_APP_PRODUCTS_API_URL + '/did/save', data, {
            headers: headers
        })
            .then((response) => {
                helpers.notifySuccess(this.state.resources.Label_Success, this.state.resources.SuccessMessage_DataSavedSuccessfully);
                this.props.history.push('/did');
            })
            .catch((error) => {
                console.log(error);

                let msg = this.state.resources.ErrorMessage_UnknownError;

                helpers.notifyError(this.state.resources.Label_Error, msg);
                this.setState({ loading: false });
            });
    }  

    onCancel(e) {
        e.preventDefault();
        this.props.history.push("/did");
    }

    onChangeDid(e) {
        this.setState({
            did: e.target.value
        });
    }
    
    onChangeCountry(e) {
        this.setState({
            countryId: e.target.value
        });
    }
    
    onChangeCarrier(e) {
        this.setState({
            carrierId: e.target.value
        });
    }

    onChangeEnabled(e) {
        this.setState({
            enabled: e.target.checked
        });
    } 

    render() {
        let contents =
            <form className="form row g-3 needs-validation" onSubmit={this.onSubmit} noValidate>
                <div className="form-group col-md-12">
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" id="enabled" checked={this.state.enabled} onChange={this.onChangeEnabled} />
                        <label disabled={this.state.viewMode} className="form-check-label" htmlFor="enabled">{this.state.resources.Label_Enabled}</label>
                        <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                    </div>
                </div>

                <div className="form-group col-md-6" >
                    <label className=" form-label">Did</label>
                    <input disabled={this.state.viewMode} className="form-control" type="text" name="did" onChange={this.onChangeDid} value={this.state.did} required />
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                </div>

                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_Country}</label>
                    <select disabled={this.state.viewMode} className="form-control" data-val="true" name="countryid" defaultValue={this.state.countryId} onChange={this.onChangeCountry} required>
                        <option value="">{this.state.resources.Label_SelectOption}</option>
                        {this.state.countries.map(country =>
                            <option key={country.id} value={country.id} selected={this.state.countryId == country.id}>{country.name}</option>
                        )}
                    </select>
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                </div>

                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Title_Carrier}</label>
                    <select disabled={this.state.viewMode} className="form-control" data-val="true" name="carrierId" defaultValue={this.state.carrierId} onChange={this.onChangeCarrier} required>
                        <option value="">{this.state.resources.Label_SelectOption}</option>
                        {this.state.carriers.map(carrier =>
                            <option key={carrier.id} value={carrier.id} selected={this.state.carrierId == carrier.id}>{carrier.name}</option>
                        )}
                    </select>
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                </div>

                <div className=" form-group col-md-6">
                     <DataCreation resources={this.state}></DataCreation>
                </div>

                {/**Buttons Header */}
                <div className="buttons-head row">
                    {this.state.viewMode == false ?
                    <div className=" buttons-childs">
                        <button type="submit" className="btn btn-primary">{this.state.resources.ButtonLabel_Save}</button>
                        <button className="btn btn-danger" onClick={this.onCancel}>{this.state.resources.ButtonLabel_Cancel}</button>
                    </div>
                    :
                    <button className="btn btn-danger btn-block" onClick={this.onCancel}>{this.state.resources.Label_GoBack}</button>
                    }
                 </div>

            </form>;

        return (
            <div className="mt-2">
                <ol className="breadcrumb my-4">
                    <li className="breadcrumb-item active">DID</li>
                </ol>
                <div className="card">
                    <div className="card-body">
                        {this.state.loading ? helpers.showLoadingOverlay() : helpers.hideLoadingOverlay()}
                        {contents}
                    </div>
                </div>
            </div>
        );
    }

    async getResources() {
        const resourceVars = [];
        resourceVars.push("Title_CampaingType");
        resourceVars.push("Label_Name");
        resourceVars.push("Label_Country");
        resourceVars.push("Title_Carrier");
        resourceVars.push("Label_SelectOption");
        resourceVars.push("Label_Enabled");
        resourceVars.push("Label_Success");
        resourceVars.push("ButtonLabel_Save");
        resourceVars.push("ButtonLabel_Cancel");
        resourceVars.push("Label_Error");
        resourceVars.push("ErrorMessage_RequiredField");
        resourceVars.push("ErrorMessage_DuplicatedName");
        resourceVars.push("ErrorMessage_UnknownError");
        resourceVars.push("SuccessMessage_DataSavedSuccessfully");
        resourceVars.push("Label_GoBack");
        resourceVars.push("Label_Creation");
        resourceVars.push("Label_CreationUser");
        resourceVars.push("Label_LastUpdate");
        resourceVars.push("Label_LastUpdateUser");
        
        const headers = {
            'Content-Type': 'application/json'
        };

        axios.post(window.config.REACT_APP_SECURITY_API_URL + '/languageresource/getbyvars/' + helpers.getUiLanguage(), resourceVars, {
            headers: headers
        })
            .then((response) => {
                const data = response.data.data;
                this.setState({ resources: data, loading: false });
            })
            .catch((error) => {
                console.log(error);
                this.setState({ loading: false });
            });
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/country/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ countries: data, loading: false });
                // order alphabetically
                this.state.countries.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                // console.log(this.state.countries);
            })
            .catch(function (error) {
                console.log(error);
            });
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/carrier/getall',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
        .then(response => {
                const data = response.data.data;
                this.setState({ carriers: data, loading: false });
                // order alphabetically
                this.state.carriers.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                // console.log(this.state.carriers);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    async populateData() {
        this.setState({
            loading: true
        });

        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/did/getbyid/' + this.props.match.params.id,
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                // console.log(data);
                this.setState({
                    id: data.id,
                    did: data.did,
                    countryId: data.country_id,
                    carrierId: data.carrier_id,
                    enabled: data.enabled,
                    creation: helpers.getDisplayDateTimeFromUtc(data.creation),
                    creationUser: data.creation_user_fullname,
                    lastUpdate: data.last_update == null ? '' : helpers.getDisplayDateTimeFromUtc(data.last_update),
                    lastUpdateUser: data.last_update_user_fullname == null ? '' : data.last_update_user_fullname,
                    loading: false
                });
            })
            .catch(function (error) {
                console.log(error);
                this.setState({ loading: false });
            }) 
     }
}