import React, { Component } from 'react';
import axios from 'axios';
import helpers from '../../Helpers'

import '../../custom.css';

export class CountryCreate extends Component {
    static displayName = CountryCreate.name;

    constructor(props) {
        super(props);

        this.onChangeName = this.onChangeName.bind(this);
        this.onChangeIsoCode = this.onChangeIsoCode.bind(this);
        this.onChangePhoneCode = this.onChangePhoneCode.bind(this);
        this.onChangeIdCampaingDefault = this.onChangeIdCampaingDefault.bind(this);
        this.onChangeCurrencySymbol = this.onChangeCurrencySymbol.bind(this);
        this.onChangeTheme = this.onChangeTheme.bind(this);
        this.onChangeNotes = this.onChangeNotes.bind(this);
        this.onChangePhoneFormat = this.onChangePhoneFormat.bind(this);
        this.onChangeEnabled = this.onChangeEnabled.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onSubmit = this.onSubmit.bind(this);  

        this.state = {
            id: 0,
            name: '',
            isoCode: '',
            phoneCode: 0,
            currencySymbol: '',
            theme: '',
            notes: '',
            phoneFormat: '',
            enabled: true,
            loading: true,
            resources: [],
            userId: 0,
            idCampaingDefault: 0,
            campaings: [],
        };
    }

    componentDidMount() {
        this.getResources();
        this.populateData();
        helpers.setNavActive("#nav-countries");

        let currentUser = helpers.getUserData();
        this.setState({
            userId: currentUser.id
        });
    }

    async onSubmit(e) {
        e.preventDefault();
        this.setState({ loading: true });

        let counter = 0;
        this.state.campaings.map(c => {
            if (c.id == this.state.idCampaingDefault) {
                counter++;
            }
        });

        if(counter == 0) {
            let msg = helpers.getUiLanguage() == 'language0' ? "Id Campaing Default is not valid" : "Id de Campaña por defecto no es válido";
            helpers.notifyError(this.state.resources.Label_Error, msg);
            this.setState({
                loading: false
            });
        } else {
            const data = {
                name: this.state.name,
                iso_code: this.state.isoCode,
                phone_code: this.state.phoneCode,
                idcampaing_default: this.state.idCampaingDefault,
                currency_symbol: this.state.currencySymbol,
                theme: this.state.theme,
                notes: this.state.notes,
                enabled: this.state.enabled,
                creation_user_id: this.state.userId,
                phone_format: this.state.phoneFormat,
            };
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME)
            };

            axios.post(window.config.REACT_APP_PRODUCTS_API_URL + '/country/save', data, {
                headers: headers
            })
                .then((response) => {
                    helpers.notifySuccess(this.state.resources.Label_Success, this.state.resources.SuccessMessage_DataSavedSuccessfully);
                    this.props.history.push('/countries');
                })
                .catch((error) => {
                    console.log(error);

                    let msg = this.state.resources.ErrorMessage_UnknownError;
                    if (error.response.data.error_code == window.errorCodes.DuplicatedEntityName)
                        msg = this.state.resources.ErrorMessage_DuplicatedName;
                    else if (error.response.data.error_code == window.errorCodes.DuplicatedCountryIsoCode)
                        msg = this.state.resources.ErrorMessage_DuplicatedCountryIsoCode;

                    helpers.notifyError(this.state.resources.Label_Error, msg);
                    this.setState({ loading: false });
                });
        }
    }  

    onCancel(e) {
        e.preventDefault();
        this.props.history.push("/countries");
    }

    onChangeName(e) {
        this.setState({
            name: e.target.value
        });
    }  
    onChangeIsoCode(e) {
        this.setState({
            isoCode: e.target.value
        });
    }  
    onChangePhoneCode(e) {
        this.setState({
            phoneCode: e.target.value
        });
    }

    onChangeIdCampaingDefault(e) {
        this.setState({
            idCampaingDefault: e.target.value
        });
    }

    onChangePhoneFormat(e) {
        this.setState({
            phoneFormat: e.target.value
        });
    } 
    onChangeCurrencySymbol(e) {
        this.setState({
            currencySymbol: e.target.value
        });
    }  
    onChangeTheme(e) {
        this.setState({
            theme: e.target.value
        });
    }  
    onChangeNotes(e) {
        this.setState({
            notes: e.target.value
        });
    }  
    onChangeEnabled(e) {
        this.setState({
            enabled: e.target.checked
        });
    } 

    render() {
        let contents = this.state.loading
            ? helpers.showLoadingOverlay()
            :
            <form className="form row g-3 needs-validation" onSubmit={this.onSubmit}>
                <div className="form-group col-md-12">
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" id="enabled" defaultChecked={this.state.enabled} onChange={this.onChangeEnabled} />
                        <label className="form-check-label" htmlFor="enabled">{this.state.resources.Label_Enabled}</label>
                        <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                    </div>
                </div>
                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_Name}</label>
                    <input className="form-control" type="text" name="name" onChange={this.onChangeName} value={this.state.name} required />
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                </div>
                <div className=" form-group col-md-6">
                    <label className="form-label">{this.state.resources.Label_IsoCode}</label>
                    <input className="form-control" type="text" name="isocode" maxlength="3" onChange={this.onChangeIsoCode} value={this.state.isoCode} required />
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                </div>
                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_PhoneCode}</label>
                    <input className="form-control" type="number" name="phonecode" onChange={this.onChangePhoneCode} value={this.state.phoneCode} required />
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                </div>
                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_PhoneFormat}</label>
                    <input className="form-control" type="text" name="phoneformat" onChange={this.onChangePhoneFormat} value={this.state.phoneFormat} />
                </div>
                <div className=" form-group col-md-6">
                    <label className="form-label">{this.state.resources.Label_CurrencySymbol}</label>
                    <input className="form-control" type="text" name="currencysymbol" maxlength="5" onChange={this.onChangeCurrencySymbol} value={this.state.currencySymbol} required />
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                </div>
                <div className=" form-group col-md-6">
                    <label className="form-label">{this.state.resources.Label_Theme}</label>
                    <input className="form-control" type="text" name="theme" maxlength="20" onChange={this.onChangeTheme} value={this.state.theme} required />
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                </div>

                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_IdCampaingDefault}</label>
                    <input className="form-control" type="number" name="campaingdefault" onChange={this.onChangeIdCampaingDefault} value={this.state.idCampaingDefault} required />
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                </div>

                <div className="form-group col-md-12">
                    <label className="form-label">{this.state.resources.Label_Notes}</label>
                    <textarea className="form-control" rows="3" name="notes" maxlength="150" onChange={this.onChangeNotes} defaultValue={this.state.notes}></textarea>
                </div>

                {/*SAVE / CANCEL*/}
                <div className="buttons-head row">
                    <div className=" buttons-childs">
                        <button type="submit" className="btn btn-primary">{this.state.resources.ButtonLabel_Save}</button>
                        <button className="btn btn-danger" onClick={this.onCancel}>{this.state.resources.ButtonLabel_Cancel}</button>
                    </div>
                 </div>
                {/* <div className="form-group col-12 text-end">
                    <button type="submit" className="btn btn-primary">{this.state.resources.ButtonLabel_Save}</button>
                    <button className="btn btn-negative" onClick={this.onCancel}>{this.state.resources.ButtonLabel_Cancel}</button>
                </div> */}
            </form>
            helpers.hideLoadingOverlay();

        return (
            <div className="mt-2">
                {/* <h1 className="mt-4">{this.state.resources.Title_Countries}</h1> */}
                <ol className="breadcrumb my-4">
                    <li className="breadcrumb-item active">{this.state.resources.Title_Countries}</li>
                </ol>
                <div className="card">
                    <div className="card-body">
                        {contents}
                    </div>
                </div>
            </div>
        );
    }

    async getResources() {
        const resourceVars = [];
        resourceVars.push("Title_Countries");
        resourceVars.push("Label_Name");
        resourceVars.push("Label_IsoCode");
        resourceVars.push("Label_PhoneCode");
        resourceVars.push("Label_PhoneFormat");
        resourceVars.push("Label_IdCampaingDefault");
        resourceVars.push("Label_CurrencySymbol");
        resourceVars.push("Label_Theme");
        resourceVars.push("Label_Notes");
        resourceVars.push("Label_Enabled");
        resourceVars.push("Label_Success");
        resourceVars.push("ButtonLabel_Save");
        resourceVars.push("ButtonLabel_Cancel");
        resourceVars.push("Label_Error");
        resourceVars.push("ErrorMessage_RequiredField");
        resourceVars.push("ErrorMessage_DuplicatedName");
        resourceVars.push("ErrorMessage_UnknownError");
        resourceVars.push("ErrorMessage_DuplicatedCountryIsoCode");
        resourceVars.push("ErrorMessage_AssociatedEntity_Country_Company");
        resourceVars.push("SuccessMessage_DataSavedSuccessfully");

        const headers = {
            'Content-Type': 'application/json'
        };

        axios.post(window.config.REACT_APP_SECURITY_API_URL + '/languageresource/getbyvars/' + helpers.getUiLanguage(), resourceVars, {
            headers: headers
        })
            .then((response) => {
                const data = response.data.data;
                this.setState({ resources: data, loading: false });
            })
            .catch((error) => {
                console.log(error);
                this.setState({ loading: false });
            });
    }

    async populateData() {
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/campaing/get',
        {
            'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
        })
        .then(response => {
            const data = response.data.data;
            this.setState({
                campaings: data,
                loading: false
            });
        })
        .catch(function (error) {
            console.log(error);
            this.setState({ loading: false });
        });
    }
}
