import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';  
import $ from 'jquery';
import DataTable from 'datatables.net';
import helpers from '../../Helpers';
import '../../custom.css';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { DataGridPro } from '@mui/x-data-grid-pro';

import { LicenseInfo } from '@mui/x-data-grid-pro';

LicenseInfo.setLicenseKey(
    '97124f3444ae3cf552c73ef2f3487b08T1JERVI6MzEzNDQsRVhQSVJZPTE2NjY1MzkzOTkwMDAsS0VZVkVSU0lPTj0x',
  );

  export class InfoProductList extends Component {
    static displayName = InfoProductList.name;

    constructor(props) {
        super(props);
        this.state = {
            items: [],
            resources: [],
            loading: true,
            permissionsIds: [],
            columns: [],
            paging: null,
            sorting: null,
            filtering: null
        };
    }
    

    componentDidMount() {
        let userData = helpers.getUserData();
        this.state.permissionsIds = userData.permission_ids;
        this.getResources();
        this.populateData();
        helpers.setNavActive("#nav-infoproduct");

        this.state.sorting = JSON.parse(localStorage.getItem('CopyInfoProductListSorting'));
        this.state.paging = JSON.parse(localStorage.getItem('CopyInfoProductListPaging'));
        this.state.filtering = JSON.parse(localStorage.getItem('CopyInfoProductListFiltering'));
    }

      

    render() {
        let contents = this.state.loading
            ? helpers.showLoadingOverlay()
            : <InfoProductDataGrid data={this.state}/>
          // : ObjetionsList.renderTable(this.state.items, this.state.resources, this.state.permissionsIds);

        return (
            <div className="mt-2">
                {/* <h1 className="mt-4">{this.state.resources.Title_Areas}</h1> */}
                <ol className="breadcrumb my-4">
                    <li className="breadcrumb-item active">{this.state.resources.Label_ProductInformation}</li>
                </ol>
                <div className="card">
                    <div className="card-header">
                        {this.state.permissionsIds.includes(2152) ?
                        <Link to={"/info-product-create"} className="btn btn-success"><i className="fas fa-plus" aria-hidden="true"></i> {this.state.resources.ButtonLabel_New}</Link>
                          : <></>}
                      </div>
                    <div className="card-body">
                        <div className="table-responsive">
                            {contents}
                        </div>
                    </div>
                </div>
            </div>
      );
    }

    async populateData() {
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/infoproduct/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                // console.log(data);
                this.setState({ items: data, loading: false });
                this.state.columns = [
                    {
                        field: 'actions',
                        headerAlign: "center",
                        headerClassName: "header-table-mui",
                        headerName: this.state.resources.Label_Actions ? this.state.resources.Label_Actions : 'Actions',
                        flex: 1,
                        minWidth: 130,
                        renderCell: (item) => {
                            return (
                                this.state.permissionsIds.includes(2153) ?
                                <div className="d-flex justify-content-between align-items-center mx-auto" style={{ cursor: "pointer" }}>
                                    <td className="text-center">
                                        <Link to={"/info-product-detail/" + item.id} className="btn btn-default"><i className="fas fa-edit"></i></Link>
                                    </td>
                                </div>
                                :
                                <div>
                                <td className="text-center">
                                    <Link to={"/info-product-view/" + item.id} className="btn btn-default"><i class="fas fa-eye"></i></Link>
                                </td>
                                </div>
                            );
                        }
                    },
                    {
                        field: 'id',
                        headerName: 'ID',
                        type: 'number',
                        width: 105,
                        headerAlign: "center",
                        align: "left",
                        headerClassName: "header-table-mui"
                    },
                    {
                        field: 'country_name',
                        headerName: this.state.resources.Label_Country ? this.state.resources.Label_Country : 'Country',
                        flex: 1,
                        minWidth: 160,
                        headerAling: "center",
                        headerClassName: "header-table-mui"
                    },
                    {
                        field: 'product_name',
                        headerName: this.state.resources.Label_Products ? this.state.resources.Label_Products : 'Product',
                        flex: 1,
                        minWidth: 160,
                        headerAling: "center",
                        headerClassName: "header-table-mui"
                    },
                    {
                        field: 'enabled',
                        headerName: this.state.resources.Label_Status ? this.state.resources.Label_Status : 'Enabled',
                        flex: 1,
                        minWidth: 130,
                        headerAling: "center",
                        headerClassName: "header-table-mui",
                        renderCell: (item) => {
                            return (
                                <div className="text-center">
                                    {item.row.enabled == true ? this.state.resources.Label_Enabled : this.state.resources.Label_Disabled}
                                </div>
                            );
                        }
                    }
                ];
                helpers.hideLoadingOverlay();
            })
            .catch(function (error) {
                console.log(error);
                helpers.hideLoadingOverlay();
            });
    }

    async getResources() {
        const resourceVars = [];
        resourceVars.push("Title_Objetions");
        resourceVars.push("Label_ID");
        resourceVars.push("Label_Title");
        resourceVars.push("Label_Detail");
        resourceVars.push("Label_Products");
        resourceVars.push("Label_Actions");
        resourceVars.push("Label_Status");
        resourceVars.push("Label_Enabled");
        resourceVars.push("Label_Disabled");
        resourceVars.push("ButtonLabel_ViewEdit");
        resourceVars.push("ButtonLabel_New");
        resourceVars.push("Datatables_language_lengthMenu");
        resourceVars.push("Datatables_language_zeroRecords");
        resourceVars.push("Datatables_language_info");
        resourceVars.push("Datatables_language_infoEmpty");
        resourceVars.push("Datatables_language_infoFiltered");
        resourceVars.push("Datatables_language_search");
        resourceVars.push("Datatables_language_paginate_previous");
        resourceVars.push("Datatables_language_paginate_next");
        resourceVars.push("Label_AllProducts");
        resourceVars.push("Label_AllAreas");
        resourceVars.push("Label_Country");
        resourceVars.push("Label_FilterOff");
        resourceVars.push("Label_Filter");
        resourceVars.push("Label_Sort");
        resourceVars.push("Label_Hide");
        resourceVars.push("Label_ShowColumns");
        resourceVars.push("Label_OrderAsc");
        resourceVars.push("Label_OrderDesc");
        resourceVars.push("Label_Unsorting");
        resourceVars.push("Label_Columns");
        resourceVars.push("Label_Delete");
        resourceVars.push("Label_GridValue");
        resourceVars.push("Label_ValueFilter");
        resourceVars.push("Label_And");
        resourceVars.push("Label_Or");
        resourceVars.push("Label_Operator");
        resourceVars.push("Label_NoRows");
        resourceVars.push("Label_SelectColumns");
        resourceVars.push("Label_OperatorAfter");
        resourceVars.push("Label_OperatorBefore");
        resourceVars.push("Label_OperatorContains");
        resourceVars.push("Label_OperatorEnds");
        resourceVars.push("Label_OperatorEquals");
        resourceVars.push("Label_OperatorIs");
        resourceVars.push("Label_OperatorIsEmpty");
        resourceVars.push("Label_OperatorIsNotEmpty");
        resourceVars.push("Label_OperatorIsNot");
        resourceVars.push("Label_OperatorIsOnOrAfter");
        resourceVars.push("Label_OperatorIsOnOrBefore");
        resourceVars.push("Label_OperatorStarts");
        resourceVars.push("Label_ReorderColumns");
        resourceVars.push("Label_HideAll");
        resourceVars.push("Label_ShowAll");
        resourceVars.push("Label_FindColumn");
        resourceVars.push("Label_TitleColumn");
        resourceVars.push("Label_addFilter");
        resourceVars.push("Label_ProductInformation");

        const headers = {
            'Content-Type': 'application/json'
        };

        axios.post(window.config.REACT_APP_SECURITY_API_URL + '/languageresource/getbyvars/' + helpers.getUiLanguage(), resourceVars, {
            headers: headers
        })
            .then((response) => {
                const data = response.data.data;
                this.setState({ resources: data, loading: false });
                // console.log(this.state);
            })
            .catch((error) => {
                console.log(error);
            });






    }
}
    export default function InfoProductDataGrid(values) {

        let items = values.data.items,
            resources = values.data.resources,
            permissionsIds = values.data.permissionsIds, columns = values.data.columns,
            sorting = localStorage.getItem('CopyInfoProductListSorting') ?
                JSON.parse(localStorage.getItem('CopyInfoProductListSorting')) : values.data.sorting,
            paging = values.data.paging ? values.data.paging : 0
            , filter = localStorage.getItem('CopyInfoProductListFiltering') ?
                JSON.parse(localStorage.getItem('CopyInfoProductListFiltering')) : values.data.filtering

        
        const [page, setPage] = React.useState(paging.page);
        const [pageSize, setPageSize] = React.useState(paging.pageSize);
        
        const [value, setValue] = React.useState([null, null]);
        const [myRows, setRowsValue] = React.useState(values.data.items);
        const [mySortModel, setSortModel] = React.useState([]);
        const [filtering, setFilter] = React.useState(filter);
        const [status, onChangeStatus] = React.useState(true);
        const [open, setOpen] = React.useState(false);
        const openModal = () => setOpen(true);
        const closeModal = () => setOpen(false);
    
        const style = {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
        };
    
        function setInitial() {
            setTimeout(() => {
                if (document.getElementsByClassName('MuiTablePagination-selectLabel')[0]){
                    document.getElementsByClassName('MuiTablePagination-selectLabel')[0].innerHTML = '';
                }
                // loading = true
                if (myRows.length < 1) {
                    if (localStorage.getItem('InfoProductFilterItems')) {
                        let myItems = JSON.parse(localStorage.getItem('InfoProductFilterItems'));
                        setRowsValue(myItems);
                        setTimeout(() => {
                            localStorage.removeItem('InfoProductFilterItems');
                        }, 1000);
                    } else {
                        setRowsValue(items)
                    }   
                }
                // if (sorting) {
                //     if (sorting.sortModel) {
                //         setSortModel(sorting.sortModel)
                //     }
                // }
            }, 1000)
           
        }
        setInitial()
    
        const handlePageChange = (newPage) => {
            setPage(newPage);
        };
    
        const handlePageSizeChange = (newPage) => {
            setPageSize(newPage);
        };
    
        const handleFilterChange = (newFilter) => {
            // console.log(newFilter)
            setFilter(newFilter);
        }
    
        const handleSortingChange = (sort) => {
            setSortModel(sort);
            localStorage.removeItem('InfoProductListSorting');
            localStorage.removeItem('CopyInfoProductListSorting');
            localStorage.setItem('CopyInfoProductListSorting', JSON.stringify(sort));
        }
    
        const handleStatus = (e) => {
            e.preventDefault();
            onChangeStatus(e.target.value);
        }
    
        // const onReloadData =
        async function onReloadData() {
            // console.log(status)
            
            if (status === true || status === 'true') {
                helpers.showLoadingOverlay();
                axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/InfoProduct/get',
                {
                    'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
                })
                .then(response => {
                    // console.log(response.data.data)
                    let newItems = response.data.data;
                    setRowsValue(newItems);
                    localStorage.setItem('InfoProductFilterItems', JSON.stringify(newItems));
                    closeModal();
                    helpers.hideLoadingOverlay();
                 })
                .catch(err => console.log(err));
            } else if (status != true){
                helpers.showLoadingOverlay();
                axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/InfoProduct/getdisabled',
                {
                    'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
                })
                .then(response => {
                    // console.log(response.data.data)
                    let newItems = response.data.data;
                    setRowsValue(newItems);
                    localStorage.setItem('InfoProductFilterItems', JSON.stringify(newItems));
                    closeModal();
                    helpers.hideLoadingOverlay();
                 })
                .catch(err => console.log(err));
            }
        }
    
        async function resetFilter() {
            helpers.showLoadingOverlay();
            axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/InfoProduct/getall',
                {
                    'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
                })
                .then(response => {
                    let newItems = response.data.data;
                    setRowsValue(newItems);
                    localStorage.removeItem('InfoProductFilterItems');
                    closeModal();
                    helpers.hideLoadingOverlay();
                 })
                .catch(error => console.log(error));
        }
    
        return (
            <>
            {/* <button className="btn btn-success mb-1" onClick={openModal}><i class="fas fa-filter"></i></button> */}
            <Modal
                open={open}
                onClose={closeModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className="dis-flex m-1" style={{justifyContent: 'space-beetween'}}>
                        <div>
                            <label className="label-text">{values.data.resources.Label_Status}</label>
                            <select className="form-control" data-val="true" name="productId" value={status} onChange={handleStatus}>
                                <option value="true">{values.data.resources.Label_Enabled}</option>
                                <option value="false">{values.data.resources.Label_Disabled}</option>
                            </select>
                            </div>
                            <div className="mt-4">
                                <button className="btn btn-primary mx-1 my-auto" onClick={onReloadData}>{values.data.resources.Label_Filter}</button>
                                <button className="btn btn-danger mx-1 my-auto" onClick={resetFilter}>{values.data.resources.Label_FilterOff}</button>
                            </div>
                    </div>
                </Box>
            </Modal>
                <div style={{ height: 450, width: '99%' }}>
                    <DataGridPro rows={myRows} columns={columns} rowsPerPageOptions={[5, 10, 25, 50, 100]} density='compact'
                    pagination
                    page={page}
                    localeText={{
                        'columnMenuFilter': values.data.resources.Label_Filter,
                        'columnHeaderSortIconLabel': values.data.resources.Label_Sort,
                        'columnMenuHideColumn': values.data.resources.Label_Hide,
                        'columnMenuShowColumns': values.data.resources.Label_ShowColumns,
                        'columnMenuSortAsc': values.data.resources.Label_OrderAsc,
                        'columnMenuSortDesc': values.data.resources.Label_OrderDesc,
                        'columnMenuUnsort': values.data.resources.Label_Unsorting,
                        'filterPanelColumns': values.data.resources.Label_Columns,
                        'filterPanelDeleteIconLabel': values.data.resources.Label_Delete,
                        'filterPanelInputLabel': values.data.resources.Label_GridValue,
                        'filterPanelInputPlaceholder': values.data.resources.Label_ValueFilter,
                        'filterPanelOperatorAnd': values.data.resources.Label_And,
                        'filterPanelOperatorOr': values.data.resources.Label_Or,
                        'filterPanelOperators': values.data.resources.Label_Operator,
                        'noResultsOverlayLabel': values.data.resources.Label_NoRows,
                        'noRowsLabel': values.data.resources.Label_NoRows,
                        'toolbarColumns': values.data.resources.Label_Columns,
                        'toolbarColumnsLabel': values.data.resources.Label_SelectColumns,
                        'errorOverlayDefaultLabel': "Error",
                        'filterOperatorAfter': values.data.resources.Label_OperatorAfter,
                        'filterOperatorBefore': values.data.resources.Label_OperatorBefore,
                        'filterOperatorContains': values.data.resources.Label_OperatorContains,
                        'filterOperatorEndsWith': values.data.resources.Label_OperatorEnds,
                        'filterOperatorEquals': values.data.resources.Label_OperatorEquals,
                        'filterOperatorIs': values.data.resources.Label_OperatorIs,
                        'filterOperatorIsEmpty': values.data.resources.Label_OperatorIsEmpty,
                        'filterOperatorIsNotEmpty': values.data.resources.Label_OperatorIsNotEmpty,
                        'filterOperatorNot': values.data.resources.Label_NoRows,
                        'filterOperatorOnOrAfter': values.data.resources.Label_OperatorIsOnOrAfter,
                        'filterOperatorOnOrBefore': values.data.resources.Label_OperatorIsOnOrBefore,
                         'filterOperatorStartsWith': values.data.resources.Label_OperatorStarts,
                        'columnsPanelDragIconLabel': values.data.resources.Label_ReorderColumns,
                        'columnsPanelHideAllButton': values.data.resources.Label_HideAll,
                        'columnsPanelShowAllButton': values.data.resources.Label_ShowAll,
                        'columnsPanelTextFieldLabel': values.data.resources.Label_FindColumn,
                        'columnsPanelTextFieldPlaceholder': values.data.resources.Label_TitleColumn,
                        'filterPanelAddFilter': values.data.resources.Label_addFilter
                    }}    
                    pageSize={pageSize}
                    // density='compact'
                    onStateChange={(state) => {
                        // console.log(state)
                        localStorage.setItem('InfoProductListPagination', JSON.stringify(state.pagination))
                        localStorage.setItem('InfoProductListSorting', JSON.stringify(state.sorting))
                        localStorage.setItem('InfoProductListFiltering', JSON.stringify(state.filter))
                    }}
                    onPageChange={handlePageChange}
                    onPageSizeChange={handlePageSizeChange}
                    sortModel={mySortModel}
                    onSortModelChange={handleSortingChange}
                    state={
                            filter != null ? {filter: filter} : {}
                            
                    }
                          
                />
            </div>
        </>
        );
    }

