import React from 'react'
import { Component } from 'react';
import helpers from '../../Helpers';
import axios from 'axios';

export class ProfilePasswordChange extends Component {
    constructor(props) {
        super(props);

        this.onSubmit = this.onSubmit.bind(this);  
        this.onChangeUsername = this.onChangeUsername.bind(this);
        this.onChangeCurrentPassword = this.onChangeCurrentPassword.bind(this);
        this.onChangePassword = this.onChangePassword.bind(this);
        this.onChangePasswordConfirm = this.onChangePasswordConfirm.bind(this);

        this.state = {
            userId: 0,
            username: '',
            currentPassword: '',
            password: '',
            passwordConfirm: '',
            loading: false,
            resources: []
        };
    }

    onChangeUsername(e) {
        this.setState({
            username: e.target.value
        });
    }  

    onChangeCurrentPassword(e) {
        this.setState({
            currentPassword: e.target.value
        });
    } 

    onChangePassword(e) {
        this.setState({
            password: e.target.value
        });
    }  

    onChangePasswordConfirm(e) {
        this.setState({
            passwordConfirm: e.target.value
        });
    }  

    componentDidMount() {
        this.getResources();
        this.populateData();
        helpers.setClientValidation();
    }

    async onSubmit(e) {
        e.preventDefault();

        var form = document.querySelectorAll('.needs-validation')[0];

        if (!form.checkValidity())
            return;

        if (this.state.password !== this.state.passwordConfirm) {
            helpers.notifyError(this.state.resources.Label_Error, this.state.resources.ErrorMessage_PasswordsDoNotMatch);
            return;
        }

        this.setState({ loading: true });

        const data = {
            current_password: this.state.currentPassword,
            password: this.state.password
        };
        const headers = {
            'Content-Type': 'application/json'
        };

        axios.post(window.config.REACT_APP_SECURITY_API_URL + '/user/profile/passwordchange/' + this.state.userId, data, {
            headers: headers
        })
            .then((response) => {
                helpers.notifySuccess(this.state.resources.Label_Success, this.state.resources.SuccessMessage_DataSavedSuccessfully);

                this.props.history.push("/profile");
            })
            .catch((error) => {
                console.log(error);

                let msg = this.state.resources.ErrorMessage_UnknownError;
                if (error.response.data.error_code == window.errorCodes.InvalidNewPassword)
                    msg = error.response.data.message;

                helpers.notifyError(this.state.resources.Label_Error, msg);
                this.setState({ loading: false });
            });
    }

    render() {
        let contents =
            <form className="form row g-3 needs-validation" onSubmit={this.onSubmit} noValidate>
                <div className="form-group col-md-12">
                    <input type="hidden" name="id" value={this.state.id} />
                </div>

                {/*USERNAME*/}
                <div className="form-group col-md-12" >
                    <label className=" form-label">{this.state.resources.Label_Username}</label>
                    <input className="form-control" type="text" name="username" value={this.state.username} disabled />
                </div>

                {/*CURRENT PASSWORD*/}
                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_CurrentPassword}</label>
                    <input className="form-control" type="password" name="password" onChange={this.onChangeCurrentPassword} value={this.state.currentPassword} pattern="^.{6,80}$" required />
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_InvalidPassword}</div>
                </div>
                <div className="form-group col-md-6" >
                </div>

                {/*PASSWORD / CONFIRM PASSWORD*/}
                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_Password}</label>
                    <input className="form-control" type="password" name="password" onChange={this.onChangePassword} value={this.state.password} pattern="^.{6,80}$" required />
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_InvalidPassword}</div>
                </div>
                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_ConfirmPassword}</label>
                    <input className="form-control" type="password" name="passwordConfirm" onChange={this.onChangePasswordConfirm} value={this.state.passwordConfirm} pattern="^.{6,80}$" required />
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_InvalidPassword}</div>
                </div>

                {/*SAVE / CANCEL*/}
                <div className="form-group col-12 text-end">
                    <button type="submit" className="btn btn-primary">{this.state.resources.ButtonLabel_Confirm}</button>
                </div>

            </form>;

        return (
            <div className="mt-2">
                <h1 className="mt-4">{this.state.resources.Title_PasswordChange}</h1>
                <ol className="breadcrumb mb-4">
                    <li className="breadcrumb-item active">{this.state.resources.Title_PasswordChange}</li>
                </ol>
                <div className="card">
                    <div className="card-body">
                        {this.state.loading ? helpers.showLoadingOverlay() : helpers.hideLoadingOverlay()}
                        {contents}
                    </div>
                </div>
            </div>
        );
    }

    async populateData() {
        let userData = helpers.getUserData();

        this.setState({
            userId: userData.id,
            username: userData.username,
            loading: false
        });
    }

    async getResources() {
        const resourceVars = [];
        resourceVars.push("ErrorMessage_UnknownError");
        resourceVars.push("ErrorMessage_InvalidPassword");
        resourceVars.push("ErrorMessage_PasswordsDoNotMatch");
        resourceVars.push("ErrorMessage_NewPasswordMustBeDifferent");
        resourceVars.push("SuccessMessage_DataSavedSuccessfully");
        resourceVars.push("Label_Error");
        resourceVars.push("Label_Username");
        resourceVars.push("Label_Password");
        resourceVars.push("Label_Success");
        resourceVars.push("Label_ConfirmPassword");
        resourceVars.push("Label_CurrentPassword");
        resourceVars.push("ButtonLabel_Confirm");
        resourceVars.push("Title_PasswordChange");
        
        const headers = {
            'Content-Type': 'application/json'
        };

        axios.post(window.config.REACT_APP_SECURITY_API_URL + '/languageresource/getbyvars/' + helpers.getUiLanguage(), resourceVars, {
            headers: headers
        })
            .then((response) => {
                const data = response.data.data;
                this.setState({ resources: data, loading: false });
            })
            .catch((error) => {
                console.log(error);
            });
    }
}