import React, { Component } from 'react';
import axios from 'axios';
import helpers from '../../Helpers';
import $ from 'jquery';
import queryString from 'query-string';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { styled } from '@mui/material/styles';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Button from '@mui/material/Button';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { RemoveShoppingCart } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { maxWidth } from '@mui/material/node_modules/@mui/system';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart';
const Swal = require('sweetalert2')

export class PaymentWsp extends Component {
    static displayName = PaymentWsp.name;

    constructor(props) {
        super(props);
        this.onChangeWayPay = this.onChangeWayPay.bind(this);
        this.onChangeBank = this.onChangeBank.bind(this);
        this.onChangeCardNumber = this.onChangeCardNumber.bind(this);
        this.onChangeSecurityCode = this.onChangeSecurityCode.bind(this);
        this.onChangeExpirationMonth = this.onChangeExpirationMonth.bind(this);
        this.onChangeExpirationYear = this.onChangeExpirationYear.bind(this);
        this.onChangeHolder = this.onChangeHolder.bind(this);
        this.onChangeAccountNumber = this.onChangeAccountNumber.bind(this);
        this.onChangeAccountType = this.onChangeAccountType.bind(this);
        this.onChangeCod = this.onChangeCod.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onChangeClosingReasonId = this.onChangeClosingReasonId.bind(this);
        this.onContactAgain = this.onContactAgain.bind(this);
        this.onChangeCommnets = this.onChangeCommnets.bind(this);
        this.SaveNoSale = this.SaveNoSale.bind(this);
        this.goToCheckOut = this.goToCheckOut.bind(this);
        this.onChangeDues = this.onChangeDues.bind(this);

        DuesNumber = DuesNumber.bind(this);
        NoSale = NoSale.bind(this);

        this.state = {
            steps: ['Inicio', 'Cliente', 'Pedido', 'Pago', 'Checkout'], //['Start', 'Client', 'Order', 'Payment', 'Checkout'],
            activeStep: 3,
            resources: [],
            products: [],
            countryId: JSON.parse(localStorage.getItem('DataWorkspace')).state.countryId,
            countryName: '',
            documentType: [],
            documentTypeId: 0,
            states: [],
            stateId: 0,
            cities: [],
            cityId: 0,
            clients: [],
            clientId: null,
            productId: null,
            objetions: [],
            scriptSales: [],
            total: 0,
            shippingCostMax: 0,
            mapCourierCities: [],
            language: '',
            waypays: [],
            waypayId: 0,
            banks: [],
            bankId: 0,
            cardNumber: null,
            securityCode: null,
            expiration: null,
            holder: '',
            accountNumber: null,
            accountType: '',
            cod: false,
            showCod: false,
            countryWaypays: [],
            countryWaypayId: 0,
            closingReasons: [],
            closingReasonId: 0,
            contactAgain: null,
            comments: '',
            expirationMonth: '',
            expirationYear: '',
            courierId: null,
            courierName: '',
            deliveryTime: '',
            dataAditional: null,
            interesRate: 0,
            duesLabel: '',
            duesSelected: null,
            validateDues: true,
            routeType: '',
            limit: 0,
            currentMapCourierWaypay: '',
            shoppingCarsAnnuleds: 0,
            currentCountryWayPays: [],
            currentWaypays: [],
            paymethodName: "",
            tokenProducts: "",
            courierIdCampaing: null,
            courierProductCountryId: null,
            orderCancel: []
        };
    }

    componentDidMount() {
        document.querySelector('#form1').style.display = 'none';
        document.querySelector('#form2').style.display = 'none';
        document.querySelector('#form3').style.display = 'none';
        document.querySelector('#formCardNumber').style.display = 'none';
        document.querySelector("#cardNumberError2").style.display = 'none';

        document.querySelector("#waypayError").style.display = 'none';
        document.querySelector("#bankError").style.display = 'none';
        document.querySelector("#holderError").style.display = 'none';
        document.querySelector("#cardNumberError").style.display = 'none';
        document.querySelector("#securityCodeError").style.display = 'none';
        document.querySelector("#expirationError").style.display = 'none';
        document.querySelector("#accountTypeError").style.display = 'none';
        document.querySelector("#accountNumberError").style.display = 'none';
        document.querySelector("#duesSelectedError").style.display = 'none';

        // aca buggg------------------------------------------------------------------------------------------------------------
        let data = JSON.parse(localStorage.getItem('DataWorkspace'));
        //console.log(data.state)
        this.state.courierIdCampaing = Number(data.state.courierIdCampaing);
        //console.log(this.state.courierIdCampaing)

        let dataOrder = JSON.parse(localStorage.getItem('DataOrderWsp'));
        //console.log(dataOrder)
       // console.log(this.state.courierProductCountryId)
        this.state.courierProductCountryId =JSON.parse(localStorage.getItem('DataOrderWsp')).courierProductCountryId;
        this.setState({
            countryId: data.state.countryId,
            productId: data.state.productId,
            // closingReasons: data.state.closingReasons,
            tokenProducts: data.state.tokenProducts
        });
       // console.log(this.state.courierProductCountryId)
        this.state.tokenProducts = data.state.tokenProducts;
        this.state.cityId = JSON.parse(localStorage.getItem('DataClientWsp')).cityId;
        this.setState({
            cityId: JSON.parse(localStorage.getItem('DataClientWsp')).cityId
        });
        let clientData = JSON.parse(localStorage.getItem('DataClientWsp'));
        //console.log(clientData)
        this.state.stateId = clientData.stateId;
        this.state.clientId = clientData.clientId;
        //console.log(this.state.clientId)
        //console.log(this.state.stateId)
        // clientData.clients.map((client) => { 
        //     if (client.document_typeid == clientData.documentTypeId && client.document_number == clientData.documentNumber && client.enabled) {
        //         // console.log(client);
        //         this.setState({
        //             clientId: client.id,
        //         });
        //         this.state.clientId = client.id;
        //     }
        // });
        
        this.getResources();

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': this.state.tokenProducts
        };
        /**region Calculate month diference */
        function monthDiff(d1, d2) {
            var months; months = (d2.getFullYear() - d1.getFullYear()) * 12; months -= d1.getMonth();
            months += d2.getMonth();
            return months <= 0 ? 0 : months;
        }
        function test(d1, d2) {
            var diff = monthDiff(d1, d2);
            // console.log(d1.toISOString().substring(0, 10), "to", d2.toISOString().substring(0, 10), ": ", diff);
            return diff;
        }
        /**endregion Calculate month diference */

        this.state.total = JSON.parse(localStorage.getItem('DataOrderWsp')).total + Number(JSON.parse(localStorage.getItem('DataOrderWsp')).shippingCostMax);
        this.setState({
            total: JSON.parse(localStorage.getItem('DataOrderWsp')).total + Number(JSON.parse(localStorage.getItem('DataOrderWsp')).shippingCostMax)
        });

        // console.log(this.state.total);

        if (localStorage.getItem('DataPaymentWsp')) {
            let WspPayment = JSON.parse(localStorage.getItem('DataPaymentWsp'));
            //console.log(WspPayment)
            this.setState({
                waypayId: WspPayment.waypayId,
                bankId: WspPayment.bankId,
                cardNumber: WspPayment.cardNumber,
                securityCode: WspPayment.securityCode,
                expirationMonth: WspPayment.expirationMonth,
                expirationYear: WspPayment.expirationYear,
                holder: WspPayment.holder,
                accountNumber: WspPayment.accountNumber,
                accountTyp: WspPayment.accountType,
                cod: WspPayment.cod,
                showCod: WspPayment.showCod,
                dataAditional: WspPayment.dataAditional,
                interesRate: WspPayment.interesRate,
                duesLabel: WspPayment.duesLabel,
               // duesSelected: WspPayment.duesSelected,
                validateDues: WspPayment.validateDues,
                currentCountryWayPays: WspPayment.currentCountryWayPays,
                paymethodName: WspPayment.paymethodName
            });
            this.state.waypayId = WspPayment.waypayId;
            this.state.bankId = WspPayment.bankId;
            this.state.cardNumber = WspPayment.cardNumber;
            this.state.securityCode = WspPayment.securityCode;
            this.state.expiration = WspPayment.expiration;
            this.state.holder = WspPayment.holder;
            this.state.accountNumber = WspPayment.accountNumber;
            this.state.accountType = WspPayment.accountType;
            this.state.cod = WspPayment.cod;
            this.state.showCod = WspPayment.showCod;
            this.state.dataAditional = WspPayment.dataAditional;
            this.state.validateDues = WspPayment.validateDues;

            if(WspPayment.waypayId == 7){
                document.querySelector('#formCardNumber').style.display = 'block';
                if(WspPayment.cardNumber.length < 4 || WspPayment.cardNumber.length > 4){
                    document.querySelector("#cardNumberError2").style.display = 'block';
                }
            }

            if (WspPayment.dataAditional == 1) {
                document.querySelector('#form1').style.display = 'block';
                document.querySelector('#form2').style.display = 'none';
                document.querySelector('#form3').style.display = 'none';
                //document.querySelector('#formCardNumber').style.display = 'none';
            } else if (WspPayment.dataAditional == 2) {
                document.querySelector('#form1').style.display = 'none';
                document.querySelector('#form2').style.display = 'block';
                document.querySelector('#form3').style.display = 'none';
            } else {  // if (this.state.waypayId == x)
                document.querySelector('#form1').style.display = 'none';
                document.querySelector('#form2').style.display = 'none';
                document.querySelector('#form3').style.display = 'block';
               // document.querySelector('#formCardNumber').style.display = 'none';
            }
            // this.state.number = WspPayment.number;
            if (!WspPayment.validateDues) {
                document.querySelector("#duesNumberDiv").style.display = 'none';
            }
        }

        //console.log(this.state.clientId)

        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getshoppingcardbyclientId/' + this.state.clientId ,
        {
            headers: headers
        })
        .then(response => {
            const data = response.data.data;
            //  console.log(data);
            //  console.log(data.length);
            //this.state.orderCancel == data;
            this.setState({
                orderCancel: data,
                
            });
            //console.log(this.state.orderCancel);
            //console.log(this.state.orderCancel.length);
            
            // this.setState({
            //     shoppingCar: data
            // });
        })
        .catch(function (error) {
            console.log(error);
           // this.setState({ loading: false });
        });
        
        let filterArr = [];
        dataOrder.productsSelected.map(product => {
            let prod = {
                description: product.description,
                total: Number(product.quantity) * Number(product.price)
            }
            filterArr.push(prod);
        });
        let prodMayor = filterArr.sort((a, b) => (a.total < b.total) ? 1 : -1)[0];
        // console.log(prodMayor);
        helpers.showLoadingOverlay();
        axios.post(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getproductpaymentbydescription', {description: prodMayor.description.toLowerCase()}, {
            headers: headers
        })
        .then(response => {
            const data = response.data.data;
            // this.setState({ products: data, loading: false });
            // this.state.products.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
            // this.state.products.map(proc => {
                // if (prodMayor.description.toLowerCase().includes(proc.name.toString().toLowerCase())) {
                    axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getproductpaymentwaypaybypaymentid/' + data,// proc.payment_id,
                        {
                            'headers': headers
                        })
                        .then(response => {
                            let waypays = response.data.data.sort((a,b) => a.waypay < b.waypay ? -1 : +(a.waypay > b.waypay))
                            this.setState({
                                currentWaypays: waypays,
                                // waypays: response.data.data,
                                // waypayId: null,
                            });
                            if (this.state.countryWaypays.length > 0) {
                                for (let i = 0; i < this.state.countryWaypays.length; i++) {
                                    if (this.state.countryWaypays[i].waypay_id == this.state.waypayId) {
                                        this.state.currentCountryWayPays.push(this.state.countryWaypays[i]);
                                        this.state.countryWaypayId = this.state.countryWaypays[i].id;                                        
                                    }
                                }
                                this.state.currentCountryWayPays.sort((a,b) => a.dues_number < b.dues_number ? -1 : +(a.dues_number > b.dues_number))
                            }
                            this.state.currentWaypays.sort((a,b) => a.waypay < b.waypay ? -1 : +(a.waypay > b.waypay));
                            helpers.hideLoadingOverlay();
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                // }
            // });
        })
        .catch(function (error) {
            console.log(error);
        });
        //console.log(this.state.mapCourierCities);
         //console.log(this.state.courierIdCampaing)
        // console.log(this.state.stateId)
    }

    goToCheckOut(e) {
        e.preventDefault();
        helpers.showLoadingOverlay();
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': this.state.tokenProducts
        };
        //debugger;
        this.state.countryWaypays.map(countryWaypay => {
            if (this.state.duesSelected == countryWaypay.id) {
                   let label = `${ countryWaypay.dues_number } ${ countryWaypay.dues_number > 1 ? this.state.resources.Label_Dues : this.state.resources.Label_Dues } (${ countryWaypay.dues_number } x ${ Math.round(((Number(this.state.total) + ((Number(this.state.total) * Number(countryWaypay.interest_rate)) / 100)) / countryWaypay.dues_number)).toLocaleString() } - Total: ${ Math.round(((Number(this.state.total) + ((Number(this.state.total) * Number(countryWaypay.interest_rate)) / 100)))).toLocaleString() })`;
                    this.setState({
                        duesLabel: label,
                    });
                    this.state.duesLabel = label;        
                }
        });

        function monthDiff(d1, d2) {
            var months; months = (d2.getFullYear() - d1.getFullYear()) * 12; months -= d1.getMonth();
            months += d2.getMonth();
            return months <= 0 ? 0 : months;
        }
        function test(d1, d2) {
            var diff = monthDiff(d1, d2);
            // console.log(d1.toISOString().substring(0, 10), "to", d2.toISOString().substring(0, 10), ": ", diff);
            return diff;
        }
        // let currentWaypay;  
        
        // this.state.waypays.map(waypay => {
        //     // console.log(this.state.waypayId);
        //     if (this.state.waypayId == waypay.id) { 
        //         currentWaypay = waypay;
        //     }
        // });
        //console.log(currentWaypay)

        //console.log(this.state.cardNumber.length)
        if(this.state.cardNumber?.length == 4){
            document.querySelector("#cardNumberError2").style.display = 'none';
            //return; 
        }else{
            if(this.state.cardNumber?.length < 4 || this.state.cardNumber?.length > 4){
                document.querySelector("#cardNumberError2").style.display = 'block';
                return  helpers.hideLoadingOverlay();
               
            }
        }
        
        this.state.products = [];
        this.state.countryWaypays = [];
        this.state.waypays = this.state.currentWaypays;
        //console.log(this.state.waypays)
        // console.log(this.state.currentMapCourierWaypay)
        // console.log(this.state.routeType)
        let currentWaypay;
        //console.log(this.state.waypays)
        this.state.waypays.map(waypay => {
            // console.log(this.state.waypayId);
            if (this.state.waypayId == waypay.id) { 
                currentWaypay = waypay;
            }
        });
        
       //console.log(this.state.mapCourierCities);
        //console.log(currentWaypay)
        //debugger;
        if(this.state.paymethodName == 'COD'){
            this.setState({
                duesSelected: 0
            });
            this.state.duesSelected = 0;
        }
        //console.log(this.state.duesSelected)
        //console.log(this.state.paymethodName)
       // console.log(this.state.currentMapCourierWaypay)
//debugger;
        
        //console.log(this.state.limit);
//debugger;
        localStorage.setItem('DataPaymentWsp', JSON.stringify(this.state));

        this.state.mapCourierCities.map(mapcourier =>{
    //debugger;
            if(mapcourier.waypay_method == this.state.paymethodName){
        //debugger;
                this.state.limit = mapcourier.limit;   
            }
        });
       // console.log(this.state.limit);
        

        // if(this.state.orderCancel.length == 3){
        //     let errorRouteType = localStorage.getItem('WspLanguage') == 'language0' ? 'Client has more than 2 recently canceled orders. Change payment method' : 'Cliente tiene más de 2 pedidos anulados recientemente. Cambiar medio de pago';
        //         toast.error(errorRouteType, {
        //             position: "top-right",
        //             autoClose: 10000,
        //             hideProgressBar: false,
        //             closeOnClick: true,
        //             pauseOnHover: true,
        //             draggable: true,
        //             progress: undefined,
        //          });
        //     }else{
//debugger;
        if (this.state.waypayId > 0 && this.state.dataAditional == 1) {
    //debugger;
            if (this.state.bankId > 0 && this.state.holder.charAt(0) && this.state.cardNumber > 0 && this.state.securityCode.charAt(0) &&
                this.state.expirationMonth.charAt(1) && this.state.expirationYear.charAt(0)) {
                if (this.state.currentMapCourierWaypay == 'PRE' || this.state.currentMapCourierWaypay == 'COD' || this.state.currentMapCourierWaypay == 'All') {
            //debugger;
                    if(this.state.courierIdCampaing > 0){
                //debugger;
                             this.state.courierId= this.state.courierIdCampaing;
                        //     window.location.href = "/checkout-workspace";
                        //     helpers.hideLoadingOverlay();

                                axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/courier/getbyid/' + this.state.courierId,
                                {
                                    'headers': headers
                                })
                                .then(response => {
                                    let data = response.data.data;
                                   // console.log(data)
                                    let courierName = data.name;
                                    this.state.courierName = courierName;
                                    axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getmapcouriercitybystatewsp/' + this.state.stateId ,
                                    {
                                        'headers': headers
                                    })
                                    .then(response => {
                                        let data = response.data.data;
                                        //console.log(data)     
                                        let deliveryTime = data.delivery_time;
                                    this.state.deliveryTime = deliveryTime; 
                                    })
                                        .catch(function (error) {
                                            console.log(error);
                                            helpers.hideLoadingOverlay();
                                        });
                        
                                        localStorage.setItem('DataPaymentWsp', JSON.stringify(this.state));

                                window.location.href = "/checkout-workspace";
                               helpers.hideLoadingOverlay();
                                })
                                    .catch(function (error) {
                                        console.log(error);
                                        helpers.hideLoadingOverlay();
                                    }); 
                    } else if(this.state.courierProductCountryId > 0){

                        this.state.courierId = this.state.courierProductCountryId;
                        
                        
                        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/courier/getbyid/' + this.state.courierId,
                        {
                            'headers': headers
                        })
                        .then(response => {
                            let data = response.data.data;
                           // console.log(data)
                            let courierName = data.name;
                            this.state.courierName = courierName;
                            axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getmapcouriercitybystatewsp/' + this.state.stateId ,
                            {
                                'headers': headers
                            })
                            .then(response => {
                                let data = response.data.data;
                                //console.log(data)     
                                let deliveryTime = data.delivery_time;
                            this.state.deliveryTime = deliveryTime; 
                            })
                                .catch(function (error) {
                                    console.log(error);
                                    helpers.hideLoadingOverlay();
                                });
                
                                localStorage.setItem('DataPaymentWsp', JSON.stringify(this.state));

                        window.location.href = "/checkout-workspace";
                       helpers.hideLoadingOverlay();
                        })
                            .catch(function (error) {
                                console.log(error);
                                helpers.hideLoadingOverlay();
                            }); 

                    } else{
                        if (this.state.routeType == 'blocking' && (this.state.currentMapCourierWaypay == 'COD' || this.state.currentMapCourierWaypay == 'PRE')) {
                            helpers.hideLoadingOverlay();
                            let errorRouteType = localStorage.getItem('WspLanguage') == 'language0' ? 'Destination of the sale blocked by the courier. can not continue' : 'Destino de la venta bloqueado por el courier. No puede continuar';
                            toast.error(errorRouteType, {
                                position: "top-right",
                                autoClose: 10000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                            return;
                    //debugger;
                        } else if (this.state.total > this.state.limit && (this.state.currentMapCourierWaypay == 'COD' || this.state.currentMapCourierWaypay == 'PRE')) {
                            helpers.hideLoadingOverlay();
                            let errorRouteType = localStorage.getItem('WspLanguage') == 'language0' ? 'Amount of the sale exceeds the limit accepted by the courier. can not continue' : 'Monto de la venta sobrepasa el límite aceptado por el courier. No puede continuar';
                            toast.error(errorRouteType, {
                                position: "top-right",
                                autoClose: 10000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                            return;
                        } else {
                            if ((this.state.currentMapCourierWaypay == 'COD' || this.state.currentMapCourierWaypay == 'All') && this.state.currentMapCourierWaypay == 'COD') {
                                //debugger;
                                let shoppingCarsAnnuleds = 0;
                                axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getshoppingcarsanulledbyclient/' + this.state.clientId,
                                    {
                                        'headers': headers
                                    })
                                    .then(response => {
                                        let data = response.data.data;
                                        //console.log(data)
                                        // data = data.filter(x => x.car_state_id == 11 || x.car_state_id == 30);
                                        data.map(x => {
                                            if (test(new Date(x.creation_date), new Date()) <= 6) {
                                                shoppingCarsAnnuleds++;
                                            }
                                        });
                                        this.state.shoppingCarsAnnuleds = shoppingCarsAnnuleds;
                                        if (this.state.shoppingCarsAnnuleds > 2) {
                                            helpers.hideLoadingOverlay();
                                            let errorRouteType = localStorage.getItem('WspLanguage') == 'language0' ? 'Client has more than 2 recently canceled orders. Change payment method' : 'Cliente tiene más de 2 pedidos anulados recientemente. Cambiar medio de pago';
                                            toast.error(errorRouteType, {
                                                position: "top-right",
                                                autoClose: 10000,
                                                hideProgressBar: false,
                                                closeOnClick: true,
                                                pauseOnHover: true,
                                                draggable: true,
                                                progress: undefined,
                                            });
                                            return;
                                        } else {
                                            window.location.href = "/checkout-workspace";
                                            helpers.hideLoadingOverlay();
                                        }
                                    })
                                    .catch(function (error) {
                                        console.log(error);
                                    });                            
                            } else {
                                
                                window.location.href = "/checkout-workspace";
                                helpers.hideLoadingOverlay();
                            }
                        }  
                    }
                    
                   
                    

                    
                }else {
                            
                    window.location.href = "/checkout-workspace";
                    helpers.hideLoadingOverlay();
                }
            } else {
                if (this.state.waypayId < 1) {
                    document.querySelector("#waypayError").style.display = 'block';    
                }
                if (this.state.bankId < 1) {
                    document.querySelector("#bankError").style.display = 'block';
                }
                if (!this.state.holder) {
                    document.querySelector("#holderError").style.display = 'block';
                }
                if (this.state.cardNumber < 1) {
                    document.querySelector("#cardNumberError").style.display = 'block';
                }
                if (!this.state.securityCode) {
                    document.querySelector("#securityCodeError").style.display = 'block';
                }
                if(!this.state.expirationMonth.charAt(1) && !this.state.expirationYear.charAt(0)){
                    document.querySelector("#expirationError").style.display = 'block';
                }
                helpers.hideLoadingOverlay();
            }
         } else if (this.state.waypayId > 0 && this.state.dataAditional == 2) {
            ;

            if (this.state.bankId > 0  && //this.state.waypayId == 10 && this.state.holder.charAt(0)
                this.state.accountType.charAt(0) && this.state.accountNumber > 0) {
                if (this.state.currentMapCourierWaypay == 'PRE' || this.state.currentMapCourierWaypay == 'COD' || this.state.currentMapCourierWaypay == 'All') {
            //debugger;
                    if(this.state.courierIdCampaing > 0){
                //debugger;
                        this.state.courierId= this.state.courierIdCampaing;
                        localStorage.setItem('DataPaymentWsp', JSON.stringify(this.state));
                        
                        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/courier/getbyid/' + this.state.courierId,
                        {
                            'headers': headers
                        })
                        .then(response => {
                            let data = response.data.data;
                            //console.log(data)
                            let courierName = data.name;
                            this.state.courierName = courierName;
                            axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getmapcouriercitybystatewsp/' + this.state.stateId ,
                            {
                                'headers': headers
                            })
                            .then(response => {
                                let data = response.data.data;
                                //console.log(data)     
                                let deliveryTime = data.delivery_time;
                            this.state.deliveryTime = deliveryTime; 
                            })
                                .catch(function (error) {
                                    console.log(error);
                                    helpers.hideLoadingOverlay();
                                });
                
                                localStorage.setItem('DataPaymentWsp', JSON.stringify(this.state));

                        window.location.href = "/checkout-workspace";
                       helpers.hideLoadingOverlay();
                        })
                            .catch(function (error) {
                                console.log(error);
                                helpers.hideLoadingOverlay();
                            }); 

                 } else if(this.state.courierProductCountryId > 0){

                    this.state.courierId = this.state.courierProductCountryId;   
                   
                    axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/courier/getbyid/' + this.state.courierId,
                    {
                        'headers': headers
                    })
                    .then(response => {
                        let data = response.data.data;
                        //console.log(data)
                        let courierName = data.name;
                        this.state.courierName = courierName;
                        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getmapcouriercitybystatewsp/' + this.state.stateId ,
                        {
                            'headers': headers
                        })
                        .then(response => {
                            let data = response.data.data;
                            //console.log(data)     
                            let deliveryTime = data.delivery_time;
                        this.state.deliveryTime = deliveryTime; 
                        })
                            .catch(function (error) {
                                console.log(error);
                                helpers.hideLoadingOverlay();
                            });
            
                            localStorage.setItem('DataPaymentWsp', JSON.stringify(this.state));

                    window.location.href = "/checkout-workspace";
                   helpers.hideLoadingOverlay();
                    })
                        .catch(function (error) {
                            console.log(error);
                            helpers.hideLoadingOverlay();
                        }); 
                    
                } else{
                        
                    if (this.state.routeType == 'blocking' && (this.state.currentMapCourierWaypay == 'COD' || this.state.currentMapCourierWaypay == 'PRE')) {
                        helpers.hideLoadingOverlay();
                        let errorRouteType = localStorage.getItem('WspLanguage') == 'language0' ? 'Destination of the sale blocked by the courier. can not continue' : 'Destino de la venta bloqueado por el courier. No puede continuar';
                        toast.error(errorRouteType, {
                            position: "top-right",
                            autoClose: 10000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        return;
                //debugger;
                    } else if (this.state.total > this.state.limit && (this.state.currentMapCourierWaypay == 'COD' || this.state.currentMapCourierWaypay == 'PRE')) {
                        helpers.hideLoadingOverlay();
                        let errorRouteType = localStorage.getItem('WspLanguage') == 'language0' ? 'Amount of the sale exceeds the limit accepted by the courier. can not continue' : 'Monto de la venta sobrepasa el límite aceptado por el courier. No puede continuar';
                        toast.error(errorRouteType, {
                            position: "top-right",
                            autoClose: 10000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        return;
                    } else {
                        if ((this.state.currentMapCourierWaypay == 'COD' || this.state.currentMapCourierWaypay == 'All') && this.state.currentMapCourierWaypay == 'COD') {
                            //debugger;
                            let shoppingCarsAnnuleds = 0;
                            axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getshoppingcarsanulledbyclient/' + this.state.clientId,
                                {
                                    'headers': headers
                                })
                                .then(response => {
                                    let data = response.data.data;
                                    //console.log(data)
                                    // data = data.filter(x => x.car_state_id == 11 || x.car_state_id == 30);
                                    data.map(x => {
                                        if (test(new Date(x.creation_date), new Date()) <= 6) {
                                            shoppingCarsAnnuleds++;
                                        }
                                    });
                                    this.state.shoppingCarsAnnuleds = shoppingCarsAnnuleds;
                                    if (this.state.shoppingCarsAnnuleds > 2) {
                                        helpers.hideLoadingOverlay();
                                        let errorRouteType = localStorage.getItem('WspLanguage') == 'language0' ? 'Client has more than 2 recently canceled orders. Change payment method' : 'Cliente tiene más de 2 pedidos anulados recientemente. Cambiar medio de pago';
                                        toast.error(errorRouteType, {
                                            position: "top-right",
                                            autoClose: 10000,
                                            hideProgressBar: false,
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: true,
                                            progress: undefined,
                                        });
                                        return;
                                    } else {
                                        window.location.href = "/checkout-workspace";
                                        helpers.hideLoadingOverlay();
                                    }
                                })
                                .catch(function (error) {
                                    console.log(error);
                                }); 
                            
                        } else {
                            
                            window.location.href = "/checkout-workspace";
                            helpers.hideLoadingOverlay();
                        }
                    }
                }
                }else {
                            
                    window.location.href = "/checkout-workspace";
                    helpers.hideLoadingOverlay();
                }
            } else {
                document.querySelector("#accountTypeError").style.display = 'block';
                document.querySelector("#accountNumberError").style.display = 'block';
                helpers.hideLoadingOverlay();
            }
         } else if (this.state.waypayId > 0 && this.state.dataAditional == 0 && this.state.duesSelected > 1) {
            ;

            if (this.state.currentMapCourierWaypay == 'PRE' || this.state.currentMapCourierWaypay == 'COD' || this.state.currentMapCourierWaypay == 'All') {
        //debugger;
                 if(this.state.courierIdCampaing > 0){
            //debugger;
                            this.state.courierId= this.state.courierIdCampaing;
                           
                            //debugger;
                            axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/courier/getbyid/' + this.state.courierId,
                            {
                                'headers': headers
                            })
                            .then(response => {
                                //debugger;
                                let data = response.data.data;
                                //console.log(data)
                                let courierName = data.name;
                                this.state.courierName = courierName;
                                axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getmapcouriercitybystatewsp/' + this.state.stateId ,
                                {
                                    'headers': headers
                                })
                                .then(response => {
                                    let data = response.data.data;
                                    //console.log(data)     
                                    let deliveryTime = data.delivery_time;
                                this.state.deliveryTime = deliveryTime; 
                                })
                                    .catch(function (error) {
                                        console.log(error);
                                        helpers.hideLoadingOverlay();
                                    });
                    
                                    localStorage.setItem('DataPaymentWsp', JSON.stringify(this.state));

                            window.location.href = "/checkout-workspace";
                           helpers.hideLoadingOverlay();
                            })
                                .catch(function (error) {
                                    console.log(error);
                                    helpers.hideLoadingOverlay();
                                }); 
                            

                    } else if(this.state.courierProductCountryId > 0){

                        this.state.courierId = this.state.courierProductCountryId;
                        
                        
                        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/courier/getbyid/' + this.state.courierId,
                        {
                            'headers': headers
                        })
                        .then(response => {
                            let data = response.data.data;
                            //console.log(data)
                            let courierName = data.name;
                            this.state.courierName = courierName;
                            axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getmapcouriercitybystatewsp/' + this.state.stateId ,
                            {
                                'headers': headers
                            })
                            .then(response => {
                                let data = response.data.data;
                                //console.log(data)     
                                let deliveryTime = data.delivery_time;
                            this.state.deliveryTime = deliveryTime; 
                            })
                                .catch(function (error) {
                                    console.log(error);
                                    helpers.hideLoadingOverlay();
                                });
                
                                localStorage.setItem('DataPaymentWsp', JSON.stringify(this.state));

                        window.location.href = "/checkout-workspace";
                       helpers.hideLoadingOverlay();
                        })
                            .catch(function (error) {
                                console.log(error);
                                helpers.hideLoadingOverlay();
                            }); 
                        
                    } else{

                    

                if (this.state.routeType == 'blocking' && (this.state.currentMapCourierWaypay == 'COD' || this.state.currentMapCourierWaypay == 'PRE')) {
                    helpers.hideLoadingOverlay();
                    let errorRouteType = localStorage.getItem('WspLanguage') == 'language0' ? 'Destination of the sale blocked by the courier. can not continue' : 'Destino de la venta bloqueado por el courier. No puede continuar';
                    toast.error(errorRouteType, {
                        position: "top-right",
                        autoClose: 10000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    return;
            //debugger;
                } else if (this.state.total > this.state.limit && (this.state.currentMapCourierWaypay == 'COD' || this.state.currentMapCourierWaypay == 'PRE')) {
                    helpers.hideLoadingOverlay();
                    let errorRouteType = localStorage.getItem('WspLanguage') == 'language0' ? 'Amount of the sale exceeds the limit accepted by the courier. can not continue' : 'Monto de la venta sobrepasa el límite aceptado por el courier. No puede continuar';
                    toast.error(errorRouteType, {
                        position: "top-right",
                        autoClose: 10000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    return;
                } else {
                   if ((this.state.currentMapCourierWaypay == 'COD' || this.state.currentMapCourierWaypay == 'All') && this.state.currentMapCourierWaypay == 'COD') {
                    //debugger;
                    let shoppingCarsAnnuleds = 0;
                    axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getshoppingcarsanulledbyclient/' + this.state.clientId,
                        {
                            'headers': headers
                        })
                        .then(response => {
                            let data = response.data.data;
                            //console.log(data)
                            // data = data.filter(x => x.car_state_id == 11 || x.car_state_id == 30);
                            data.map(x => {
                                if (test(new Date(x.creation_date), new Date()) <= 6) {
                                    shoppingCarsAnnuleds++;
                                }
                            });
                            this.state.shoppingCarsAnnuleds = shoppingCarsAnnuleds;
                            if (this.state.shoppingCarsAnnuleds > 2) {
                                helpers.hideLoadingOverlay();
                                let errorRouteType = localStorage.getItem('WspLanguage') == 'language0' ? 'Client has more than 2 recently canceled orders. Change payment method' : 'Cliente tiene más de 2 pedidos anulados recientemente. Cambiar medio de pago';
                                toast.error(errorRouteType, {
                                    position: "top-right",
                                    autoClose: 10000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                });
                                return;
                            } else {
                                window.location.href = "/checkout-workspace";
                                helpers.hideLoadingOverlay();
                            }
                        })
                        .catch(function (error) {
                            console.log(error);
                        }); 
                        
                    } else {
                        
                        window.location.href = "/checkout-workspace";
                        helpers.hideLoadingOverlay();
                    }
                }
            }
            }else {
                            
                window.location.href = "/checkout-workspace";
                helpers.hideLoadingOverlay();
            }
            } else if(this.state.waypayId < 1 || !this.state.waypayId){
                document.querySelector("#waypayError").style.display = 'block';
                    helpers.hideLoadingOverlay();
                } else if(this.state.duesSelected < 1 && this.state.validateDues){
                document.querySelector("#duesSelectedError").style.display = 'block';
                helpers.hideLoadingOverlay();
                } else {
            if (this.state.currentMapCourierWaypay == 'PRE' || this.state.currentMapCourierWaypay == 'COD' || this.state.currentMapCourierWaypay == 'All') {
                ;
        //debugger;
                if(this.state.courierIdCampaing > 0){
            //debugger;
                    this.state.courierId= this.state.courierIdCampaing;
                    localStorage.setItem('DataPaymentWsp', JSON.stringify(this.state));
        
                    axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/courier/getbyid/' + this.state.courierId,
                    {
                        'headers': headers
                    })
                    .then(response => {
                //debugger;
                        let data = response.data.data;
                        //console.log(data)
                        let courierName = data.name;
                        this.state.courierName = courierName;
                        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getmapcouriercitybystatewsp/' + this.state.stateId ,
                        {
                            'headers': headers
                        })
                        .then(response => {
                            let data = response.data.data;
                           // console.log(data)     
                            let deliveryTime = data.delivery_time;
                        this.state.deliveryTime = deliveryTime; 
                        })
                            .catch(function (error) {
                                console.log(error);
                                helpers.hideLoadingOverlay();
                            });
            
                            localStorage.setItem('DataPaymentWsp', JSON.stringify(this.state));
                    //debugger;
                            if (this.state.total > this.state.limit && (this.state.currentMapCourierWaypay == 'COD' || this.state.currentMapCourierWaypay == 'PRE')) {
                                helpers.hideLoadingOverlay();
                                let errorRouteType = localStorage.getItem('WspLanguage') == 'language0' ? 'Amount of the sale exceeds the limit accepted by the courier. can not continue' : 'Monto de la venta sobrepasa el límite aceptado por el courier. No puede continuar';
                                toast.error(errorRouteType, {
                                    position: "top-right",
                                    autoClose: 10000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                });
                                return;
                            } else {
                                //if ((this.state.currentMapCourierWaypay == 'COD' || this.state.currentMapCourierWaypay == 'All') && this.state.currentMapCourierWaypay == 'COD') {
                                    let shoppingCarsAnnuleds = 0;
                                    //debugger;
                                        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getshoppingcarsanulledbyclient/' + this.state.clientId,
                                            {
                                                'headers': headers
                                            })
                                            .then(response => {
                                    
                                                let data = response.data.data;
                                                //console.log(data)
                                                // data = data.filter(x => x.car_state_id == 11 || x.car_state_id == 30);
                                                data.map(x => {
                                        
                                                    if (test(new Date(x.creation_date), new Date()) <= 6) {
                                                        shoppingCarsAnnuleds++;
                                                    }
                                                });
                                                this.state.shoppingCarsAnnuleds = shoppingCarsAnnuleds;
                                    
                                                if (this.state.shoppingCarsAnnuleds > 2) {
                                                    helpers.hideLoadingOverlay();
                                                    let errorRouteType = localStorage.getItem('WspLanguage') == 'language0' ? 'Client has more than 2 recently canceled orders. Change payment method' : 'Cliente tiene más de 2 pedidos anulados recientemente. Cambiar medio de pago';
                                                    toast.error(errorRouteType, {
                                                        position: "top-right",
                                                        autoClose: 10000,
                                                        hideProgressBar: false,
                                                        closeOnClick: true,
                                                        pauseOnHover: true,
                                                        draggable: true,
                                                        progress: undefined,
                                                    });
                                                    return;
                                                } else {
                                                    window.location.href = "/checkout-workspace";
                                                    helpers.hideLoadingOverlay();
                                                }
                                            })
                                            .catch(function (error) {
                                                console.log(error);
                                            }); 
                                    
                                // } else {
                                    
                                //     window.location.href = "/checkout-workspace";
                                //     helpers.hideLoadingOverlay();
                                // }
                            }

                        //window.location.href = "/checkout-workspace";
                        helpers.hideLoadingOverlay();
                    })
                        .catch(function (error) {
                            console.log(error);
                            helpers.hideLoadingOverlay();
                        }); 

                } else if(this.state.courierProductCountryId > 0){
    
                this.state.courierId = this.state.courierProductCountryId;
                
                            
                                        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/courier/getbyid/' + this.state.courierId,
                                        {
                                            'headers': headers
                                        })
                                        .then(response => {
                                
                                            let data = response.data.data;
                                            //console.log(data)
                                            let courierName = data.name;
                                            this.state.courierName = courierName;
                                            axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getmapcouriercitybystatewsp/' + this.state.stateId ,
                                            {
                                                'headers': headers
                                            })
                                            .then(response => {
                                                let data = response.data.data;
                                                //console.log(data)     
                                                let deliveryTime = data.delivery_time;
                                            this.state.deliveryTime = deliveryTime; 
                                            })
                                                .catch(function (error) {
                                                    console.log(error);
                                                    helpers.hideLoadingOverlay();
                                                });
                                
                                            localStorage.setItem('DataPaymentWsp', JSON.stringify(this.state));
                                    //debugger;
                                            if (this.state.total > this.state.limit && (this.state.currentMapCourierWaypay == 'COD' || this.state.currentMapCourierWaypay == 'PRE')) {
                                                helpers.hideLoadingOverlay();
                                                let errorRouteType = localStorage.getItem('WspLanguage') == 'language0' ? 'Amount of the sale exceeds the limit accepted by the courier. can not continue' : 'Monto de la venta sobrepasa el límite aceptado por el courier. No puede continuar';
                                                toast.error(errorRouteType, {
                                                    position: "top-right",
                                                    autoClose: 10000,
                                                    hideProgressBar: false,
                                                    closeOnClick: true,
                                                    pauseOnHover: true,
                                                    draggable: true,
                                                    progress: undefined,
                                                });
                                                return;
                                            } else {
                                                if ((this.state.currentMapCourierWaypay == 'COD' || this.state.currentMapCourierWaypay == 'All') && this.state.currentMapCourierWaypay == 'COD') {
                                                    //debugger;
                                                    let shoppingCarsAnnuleds = 0;
                                                        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getshoppingcarsanulledbyclient/' + this.state.clientId,
                                                            {
                                                                'headers': headers
                                                            })
                                                            .then(response => {
                                                                let data = response.data.data;
                                                               // console.log(data)
                                                                // data = data.filter(x => x.car_state_id == 11 || x.car_state_id == 30);
                                                                data.map(x => {
                                                                    if (test(new Date(x.creation_date), new Date()) <= 6) {
                                                                        shoppingCarsAnnuleds++;
                                                                    }
                                                                });
                                                                this.state.shoppingCarsAnnuleds = shoppingCarsAnnuleds;
                                                                if (this.state.shoppingCarsAnnuleds > 2) {
                                                                    helpers.hideLoadingOverlay();
                                                                    let errorRouteType = localStorage.getItem('WspLanguage') == 'language0' ? 'Client has more than 2 recently canceled orders. Change payment method' : 'Cliente tiene más de 2 pedidos anulados recientemente. Cambiar medio de pago';
                                                                    toast.error(errorRouteType, {
                                                                        position: "top-right",
                                                                        autoClose: 10000,
                                                                        hideProgressBar: false,
                                                                        closeOnClick: true,
                                                                        pauseOnHover: true,
                                                                        draggable: true,
                                                                        progress: undefined,
                                                                    });
                                                                    return;
                                                                } else {
                                                                    window.location.href = "/checkout-workspace";
                                                                    helpers.hideLoadingOverlay();
                                                                }
                                                            })
                                                            .catch(function (error) {
                                                                console.log(error);
                                                            }); 
                                                    
                                                } else {
                                                    
                                                    window.location.href = "/checkout-workspace";
                                                    helpers.hideLoadingOverlay();
                                                }
                                            }

                                        //window.location.href = "/checkout-workspace"; ///////////////////////////////////////////////////////////////////////////
                                        helpers.hideLoadingOverlay();
                                        })
                                            .catch(function (error) {
                                                console.log(error);
                                                helpers.hideLoadingOverlay();
                                            }); 
                } else{
                                
                                        if (this.state.routeType == 'blocking' && (this.state.currentMapCourierWaypay == 'COD' || this.state.currentMapCourierWaypay == 'PRE')) {
                                            helpers.hideLoadingOverlay();
                                            let errorRouteType = localStorage.getItem('WspLanguage') == 'language0' ? 'Destination of the sale blocked by the courier. can not continue' : 'Destino de la venta bloqueado por el courier. No puede continuar';
                                            toast.error(errorRouteType, {
                                                position: "top-right",
                                                autoClose: 10000,
                                                hideProgressBar: false,
                                                closeOnClick: true,
                                                pauseOnHover: true,
                                                draggable: true,
                                                progress: undefined,
                                            });
                                            return;
                                    //debugger;
                                        } else if (this.state.total > this.state.limit && (this.state.currentMapCourierWaypay == 'COD' || this.state.currentMapCourierWaypay == 'PRE')) {
                                            helpers.hideLoadingOverlay();
                                            let errorRouteType = localStorage.getItem('WspLanguage') == 'language0' ? 'Amount of the sale exceeds the limit accepted by the courier. can not continue' : 'Monto de la venta sobrepasa el límite aceptado por el courier. No puede continuar';
                                            toast.error(errorRouteType, {
                                                position: "top-right",
                                                autoClose: 10000,
                                                hideProgressBar: false,
                                                closeOnClick: true,
                                                pauseOnHover: true,
                                                draggable: true,
                                                progress: undefined,
                                            });
                                            return;
                                        } else {
                                            if ((this.state.currentMapCourierWaypay == 'COD' || this.state.currentMapCourierWaypay == 'All') && this.state.currentMapCourierWaypay == 'COD') {
                                                //debugger;
                                                let shoppingCarsAnnuleds = 0;
                                                    axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getshoppingcarsanulledbyclient/' + this.state.clientId,
                                                        {
                                                            'headers': headers
                                                        })
                                                        .then(response => {
                                                            let data = response.data.data;
                                                           // console.log(data)
                                                            // data = data.filter(x => x.car_state_id == 11 || x.car_state_id == 30);
                                                            data.map(x => {
                                                                if (test(new Date(x.creation_date), new Date()) <= 6) {
                                                                    shoppingCarsAnnuleds++;
                                                                }
                                                            });
                                                            this.state.shoppingCarsAnnuleds = shoppingCarsAnnuleds;
                                                            if (this.state.shoppingCarsAnnuleds > 2) {
                                                                helpers.hideLoadingOverlay();
                                                                let errorRouteType = localStorage.getItem('WspLanguage') == 'language0' ? 'Client has more than 2 recently canceled orders. Change payment method' : 'Cliente tiene más de 2 pedidos anulados recientemente. Cambiar medio de pago';
                                                                toast.error(errorRouteType, {
                                                                    position: "top-right",
                                                                    autoClose: 10000,
                                                                    hideProgressBar: false,
                                                                    closeOnClick: true,
                                                                    pauseOnHover: true,
                                                                    draggable: true,
                                                                    progress: undefined,
                                                                });
                                                                return;
                                                            } else {
                                                                window.location.href = "/checkout-workspace";
                                                                helpers.hideLoadingOverlay();
                                                            }
                                                        })
                                                        .catch(function (error) {
                                                            console.log(error);
                                                        }); 
                                                
                                            } else if((this.state.currentMapCourierWaypay == 'COD' || this.state.currentMapCourierWaypay == 'PRE') && this.state.currentMapCourierWaypay == 'PRE'){

                                                axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/courier/getbyid/' + this.state.courierId,
                                                {
                                                    'headers': headers
                                                })
                                                .then(response => {
                                            //debugger;
                                                    let data = response.data.data;
                                                    //console.log(data)
                                                    let courierName = data.name;
                                                    this.state.courierName = courierName;
                                                    axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getmapcouriercitybystatewsp/' + this.state.stateId ,
                                                    {
                                                        'headers': headers
                                                    })
                                                    .then(response => {
                                                        let data = response.data.data;
                                                    // console.log(data)     
                                                        let deliveryTime = data.delivery_time;
                                                    this.state.deliveryTime = deliveryTime; 
                                                    })
                                                        .catch(function (error) {
                                                            console.log(error);
                                                            helpers.hideLoadingOverlay();
                                                        });
                                        
                                                        localStorage.setItem('DataPaymentWsp', JSON.stringify(this.state));
                                                //debugger;
                                                        if (this.state.total > this.state.limit && (this.state.currentMapCourierWaypay == 'COD' || this.state.currentMapCourierWaypay == 'PRE')) {
                                                            helpers.hideLoadingOverlay();
                                                            let errorRouteType = localStorage.getItem('WspLanguage') == 'language0' ? 'Amount of the sale exceeds the limit accepted by the courier. can not continue' : 'Monto de la venta sobrepasa el límite aceptado por el courier. No puede continuar';
                                                            toast.error(errorRouteType, {
                                                                position: "top-right",
                                                                autoClose: 10000,
                                                                hideProgressBar: false,
                                                                closeOnClick: true,
                                                                pauseOnHover: true,
                                                                draggable: true,
                                                                progress: undefined,
                                                            });
                                                            return;
                                                        } else {
                                                            //if ((this.state.currentMapCourierWaypay == 'COD' || this.state.currentMapCourierWaypay == 'All') && this.state.currentMapCourierWaypay == 'COD') {
                                                                let shoppingCarsAnnuleds = 0;
                                                                //debugger;
                                                                    axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getshoppingcarsanulledbyclient/' + this.state.clientId,
                                                                        {
                                                                            'headers': headers
                                                                        })
                                                                        .then(response => {
                                                                
                                                                            let data = response.data.data;
                                                                            //console.log(data)
                                                                            // data = data.filter(x => x.car_state_id == 11 || x.car_state_id == 30);
                                                                            data.map(x => {
                                                                    
                                                                                if (test(new Date(x.creation_date), new Date()) <= 6) {
                                                                                    shoppingCarsAnnuleds++;
                                                                                }
                                                                            });
                                                                            this.state.shoppingCarsAnnuleds = shoppingCarsAnnuleds;
                                                                
                                                                            if (this.state.shoppingCarsAnnuleds > 2) {
                                                                                helpers.hideLoadingOverlay();
                                                                                let errorRouteType = localStorage.getItem('WspLanguage') == 'language0' ? 'Client has more than 2 recently canceled orders. Change payment method' : 'Cliente tiene más de 2 pedidos anulados recientemente. Cambiar medio de pago';
                                                                                toast.error(errorRouteType, {
                                                                                    position: "top-right",
                                                                                    autoClose: 10000,
                                                                                    hideProgressBar: false,
                                                                                    closeOnClick: true,
                                                                                    pauseOnHover: true,
                                                                                    draggable: true,
                                                                                    progress: undefined,
                                                                                });
                                                                                return;
                                                                            } else {
                                                                                window.location.href = "/checkout-workspace";
                                                                                helpers.hideLoadingOverlay();
                                                                            }
                                                                        })
                                                                        .catch(function (error) {
                                                                            console.log(error);
                                                                        }); 
                                                                
                                                            // } else {
                                                                
                                                            //     window.location.href = "/checkout-workspace";
                                                            //     helpers.hideLoadingOverlay();
                                                            // }
                                                        }

                                                    //window.location.href = "/checkout-workspace";
                                                    helpers.hideLoadingOverlay();
                                                })
                                                    .catch(function (error) {
                                                        console.log(error);
                                                        helpers.hideLoadingOverlay();
                                                    }); 
                                                
                                                // window.location.href = "/checkout-workspace";
                                                // helpers.hideLoadingOverlay();
                                            }
                                        }
                      }
            }else {
                            
                window.location.href = "/checkout-workspace";
                helpers.hideLoadingOverlay();
             }
        }
    
        //}
    }

    onChangeWayPay(e) {
        document.querySelector("#waypayError").style.display = 'none';
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': this.state.tokenProducts
        };
        let idWaypay = e.target.value;
        //GET WAYAPAY BY ID
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getwaypaybyid/' + e.target.value,
        {
            'headers': headers
        })
        .then(response => {
            const wayPay = response.data.data;

            if (wayPay.id == idWaypay) {
                // console.log(waypay);
                this.setState({
                    waypayId: idWaypay,//data[0]
                    dataAditional: wayPay.data_additional,
                    paymethodName: wayPay.paymethod_name
                });
                this.state.paymethodName = wayPay.paymethod_name;
                if(wayPay.data_additional == 1){
                    axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getbanks',
                    {
                        'headers': headers
                    })
                    .then(response => {
                        const data = response.data.data;
                        this.setState({ banks: data});
                        // order alphabetically
                        this.state.banks.sort((a,b) => a.bank < b.bank ? -1 : +(a.bank > b.bank))
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
                }

                
                if (wayPay.paymethod_id == 2) {
                    document.querySelector("#duesNumberDiv").style.display = 'none';
                    document.querySelector('#formCardNumber').style.display = 'none';
                    this.setState({
                        validateDues: false,
                    });
                } else {
                    document.querySelector("#duesNumberDiv").style.display = 'flex';
                    this.setState({
                        validateDues: true,
                    });
                }

                for (let i = 0; i < this.state.mapCourierCities.length; i++) {
                
                    // let cities = this.state.mapCourierCities[i].cities.split(',');
                    // if(cities[0] == this.state.cityId.toString() || cities[1] == this.state.cityId.toString() || cities[2] == this.state.cityId.toString() || cities[3] == this.state.cityId.toString()){ //&& !founded
                        if(this.state.mapCourierCities[i].waypay_method == "COD" ||this.state.mapCourierCities[i].waypay_method == "All" || this.state.mapCourierCities[i].waypay_method == "PRE"){
                            // if(this.state.mapCourierCities[i].waypay_method == this.state.paymethodName){
                                this.setState({
                                    courierId: this.state.mapCourierCities[i].courier_id,
                                    courierName: this.state.mapCourierCities[i].courier_name,
                                    deliveryTime: this.state.mapCourierCities[i].delivery_time,
                                    limit: this.state.mapCourierCities[i].limit,
                                    routeType: this.state.mapCourierCities[i].route_type,
                                    showCod: true,
                                    currentMapCourierWaypay: this.state.mapCourierCities[i].waypay_method
                                });
                                this.state.deliveryTime = this.state.mapCourierCities[i].delivery_time;
                                // console.log(this.state.deliveryTime);
                            // }
                            helpers.hideLoadingOverlay();
                        } else {
                            this.setState({
                                courierId: this.state.mapCourierCities[i].courier_id,
                                courierName: this.state.mapCourierCities[i].courier_name,
                                showCod: false
                            });
                            helpers.hideLoadingOverlay();
                        }
                    // }
                }
                //console.log(this.state.limit);
            }
        })
        .catch((error) => {
            console.log(error);
        });
        // this.state.waypays.map(waypay => {
            
        // });
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getcountrywaypaysbyids/'+ this.state.countryId + '/' + e.target.value,
            {
                'headers': headers
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ currentCountryWayPays: data.sort((a,b) => a.dues_number < b.dues_number ? -1 : +(a.dues_number > b.dues_number))});
                // this.state.currentCountryWayPays.sort((a,b) => a.waypay < b.waypay ? -1 : +(a.waypay > b.waypay))
                    this.state.currentCountryWayPays = this.state.currentCountryWayPays.sort((a,b) => a.dues_number < b.dues_number ? -1 : +(a.dues_number > b.dues_number))
            })
            .catch(function (error) {
                console.log(error);
            });

        this.state.waypayId = e.target.value;
        //console.log(e.target.value)

        if(e.target.value == 14){
            document.querySelector('#formCardNumber').style.display = 'none';
        }
        if(e.target.value == 7){ 
            document.querySelector('#formCardNumber').style.display = 'block';
            document.querySelector("#cardNumberError2").style.display = 'none';
        }

        if (e.target.value < 1) {
            document.querySelector("#waypayError").style.display = 'block';
        }
        if (e.target.value == 1 && !this.state.showCod) {
            // document.querySelector('#form1').style.display = 'block';
            document.querySelector('#formCardNumber').style.display = 'none';
            document.querySelector('#form2').style.display = 'none';
            document.querySelector('#form3').style.display = 'none';
        } else if (e.target.value == 2 && !this.state.showCod && this.state.dataAditional == 1) {
            document.querySelector('#form1').style.display = 'none';
            document.querySelector('#formCardNumber').style.display = 'none';
            // document.querySelector('#form2').style.display = 'block';
            document.querySelector('#form3').style.display = 'none';
        } else {  // if (e.target.value == x)
            
            document.querySelector('#form1').style.display = 'none';
            document.querySelector('#form2').style.display = 'none';
            // document.querySelector('#form3').style.display = 'block';
        }

        if(this.state.paymethodName == 'COD'){
            // this.setState({
            //     duesSelected: 0
            // });
            this.state.duesSelected = 0;
        }
    }

    onChangeBank(e) {
        this.setState({
            bankId: e.target.value
        });
        document.querySelector("#bankError").style.display = 'none';
    }

    onChangeCardNumber(e) {
        if(e.target.value.length <= 4)
        {
            const limit = 4;
            let num = e.target.value.slice(0, limit);
            this.state.cardNumber = e.target.value.slice(0, limit);
            this.setState({
                cardNumber: e.target.value.slice(0, limit)
            });
        }
        document.querySelector("#cardNumberError").style.display = 'none';
        document.querySelector("#cardNumberError2").style.display = 'none';
      //  console.log(e.target.value.length)
        if(e.target.value.length == 4 || e.target.value.length == 5){
            document.querySelector("#cardNumberError2").style.display = 'none';
        }else{
            document.querySelector("#cardNumberError2").style.display = 'block';
        }
    }

    onChangeSecurityCode(e) {
        this.setState({
            securityCode: e.target.value
        });
        document.querySelector("#securityCodeError").style.display = 'none';
    }

    onChangeExpirationMonth(e) {
        this.setState({
            expirationMonth: e.target.value
        });
        document.querySelector("#expirationError").style.display = 'none';
    }

    onChangeExpirationYear(e) {
        this.setState({
            expirationYear: e.target.value
        });
        document.querySelector("#expirationError").style.display = 'none';
    }

    onChangeHolder(e) {
        this.setState({
            holder: e.target.value
        });
        document.querySelector("#holderError").style.display = 'none';
    }

    onChangeAccountNumber(e) {
        this.setState({
            accountNumber: e.target.value
        });
        document.querySelector("#accountNumberError").style.display = 'none';
    }

    onChangeAccountType(e) {
        this.setState({
            accountType: e.target.value
        });
        document.querySelector("#accountTypeError").style.display = 'none';
    }

    onChangeCod(e) {
        // console.log(e.target.checked);
        this.setState({
            cod: e.target.checked
        });
        this.state.cod = e.target.checked;
    }

    onChangeClosingReasonId(e) {
        this.setState({
            closingReasonId: e.target.value
        });
    }

    onContactAgain(e) {
        this.setState({
            contactAgain: e.target.value
        });
    }

    onChangeCommnets(e) {
        this.setState({
            comments: e.target.value
        });
        let countChararter= e.target.value.length;
        //console.log(countChararter)
        let resta = 100 - countChararter;
        //console.log(resta)
        this.state.remainComentaries = resta;
    }

    onChangeDues(e) {
        const data = JSON.parse(e.target.value);
        if (data !== 0) {
            this.setState({
                interesRate: data.interest_rate,
                duesLabel: `${data.dues_number} ${data.dues_number > 1 ? this.state.resources.Label_Dues : this.state.resources.Label_Dues} (${data.dues_number} x $${Math.round(((Number(this.state.total) + ((Number(this.state.total) * Number(data.interest_rate)) / 100)) / data.dues_number)).toLocaleString()} - Total: $${Math.round(((Number(this.state.total) + ((Number(this.state.total) * Number(data.interest_rate)) / 100)))).toLocaleString()})`,
                duesSelected: data.id
            });
            document.querySelector("#duesSelectedError").style.display = 'none';
        } else {
            this.setState({
                duesSelected: 0
            });
            document.querySelector("#duesSelectedError").style.display = 'block';
        }
    }

    onCancel(e) {
        e.preventDefault();
        this.state.products = [];
        localStorage.setItem('DataPaymentWsp',JSON.stringify(this.state));
        this.props.history.goBack();
    }

    SaveNoSale(e) {
        let wksData = JSON.parse(localStorage.getItem('DataWorkspace'));
        
        const data = {
            id: wksData.state.currentElement.id,
            country_id: parseInt(wksData.state.params.id_pais),
            id_agent: parseInt(wksData.state.params.id_agente),
            call_index: wksData.state.params.id_datos ?? "",
            call_id: wksData.state.params.id_llamada,
            did: wksData.state.params.id_campania ?? "",
            id_campania_occ : parseInt(wksData.state.params.id_campania_occ ?? "0"),
            pbx_crm: parseInt(wksData.state.params.id_crm ?? "0"),
            pbx_db: parseInt(wksData.state.params.id_db ?? "0"),
            active_step: parseInt(wksData.state.activeStep ?? "0"),
            closingreason_id: this.state.closingReasonId,
            contact_again: this.state.contactAgain,
            comments: this.state.comments,
            product_id: wksData.state.productId,
            treatment_id: wksData.state.treatmentId,
            client_phone: wksData.state.phone,
            client_name: wksData.state.name,
            city_id: wksData.state.stateId,
            credit_card: wksData.state.hasCreditCard
        };
        // console.log(data)
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': this.state.tokenProducts
        };

        for (let i = 0; i < wksData.state.answersTreatmentQuestions.length; i++){
            const auxiliarData = {
                pbxworkspace_id: wksData.state.currentElement?.id,
                question_id: wksData.state.answersTreatmentQuestions[i].id,
                question_name: wksData.state.answersTreatmentQuestions[i].question,
                answer: wksData.state.answersTreatmentQuestions[i].answer,
                enabled: true
            };
            // console.log(auxiliarData);
            axios.post(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/savequestionanswers', auxiliarData, {
                headers: headers
            })
            .then((response) => { 
                
            })
            .catch((error) => console.log(error));
        }
        
        
        helpers.showLoadingOverlay();
        axios.post(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/save', data, {
            headers: headers
        })
        .then((response) => { 
            setTimeout(() => window.location.href = "/no-sale-workspace", 3000)
            
        })
        .catch((error) => console.log(error));
    }

    render() {

        return (
            <div className="container">
                <ToastContainer></ToastContainer>
                <div className="row">
                    <div className="p-2 my-2">
                        <Box sx={{ width: '100%' }}>
                            <Stepper activeStep={this.state.activeStep} alternativeLabel>
                                {this.state.steps.map((label) => (
                                    <Step key={label}>
                                        <StepLabel>{label}</StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                        </Box>
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col-md-12 p-3" style={{border: '1px solid #1976d2', borderRadius: '5px'}}>
                        <div className="row mb-2">
                            <h4>{this.state.resources.ButtonLabel_Select} {this.state.resources.Title_WayPay}</h4>
                        </div>
                        <div className="row my-2">
                            <div className="col-md-8 p-3">
                                <div className="dis-flex">
                                    <label className=" form-label col-md-3">{this.state.resources.Title_WayPay}</label>
                                    <select className="form-control col-md-8" data-val="true" name="waypayid" onChange={this.onChangeWayPay}> {/** defaultValue={this.state.waypayId}  */}
                                        <option value="">{this.state.resources.Label_SelectOption}</option>
                                        {this.state.currentWaypays.map(waypay => //+ ';' + waypay.data_additional
                                            <option key={waypay.waypay_id} value={waypay.waypay_id} selected={this.state.waypayId == waypay.waypay_id}>{waypay.waypay}</option>
                                        )}
                                    </select>
                                </div>
                                <div className="my-2">
                                    <form id="form1">
                                        <div className="form-group my-2">
                                            <div className="dis-flex">
                                                <label className="form-label col-md-3">{this.state.resources.Label_Bank}</label>
                                                <select className="form-control col-md-8" data-val="true" name="bankId" onChange={this.onChangeBank}>
                                                    <option value="">{this.state.resources.Label_SelectOption}</option>
                                                    {this.state.banks.map(bank =>
                                                        <option key={bank.id} value={bank.id} selected={this.state.bankId == bank.id}>{bank.bank}</option>
                                                    )}
                                                </select>
                                            </div>
                                            <div style={{color: 'red'}} className="p-1 pl-2" id="bankError">{localStorage.getItem('WspLanguage') == 'language0' ? 'Bank is Required' : 'El Banco es Requerido'}</div>
                                        </div>
                                        <div className="form-group my-2">
                                            <div className="dis-flex">
                                                <label className="form-label col-md-3">{this.state.resources.Label_CardNumber}</label>
                                                <input className="form-control col-md-8" type="number" id="txtcardNumber"  data-val="true" name="cardNumber" value={this.state.cardNumber} defaultValue={this.state.cardNumber} onChange={this.onChangeCardNumber} max="9999" maxLength={4}/>
                                            </div>
                                            <div style={{color: 'red'}} className="p-1 pl-2" id="cardNumberError">{localStorage.getItem('WspLanguage') == 'language0' ? 'Card Number is Required' : 'El Número de tarjeta es Requerido'}</div>
                                        </div>
                                        <div className="form-group my-2">
                                            <div className="dis-flex">
                                                <label className="form-label col-md-3">{this.state.resources.Label_SecurityCode}</label>
                                                <input className="form-control col-md-8" type="number" data-val="true" name="securityCode" defaultValue={this.state.securityCode} onChange={this.onChangeSecurityCode} />
                                            </div>
                                            <div style={{color: 'red'}} className="p-1 pl-2" id="securityCodeError">{localStorage.getItem('WspLanguage') == 'language0' ? 'Security Code is Required' : 'El Código de Seguridad es Requerido'}</div>
                                        </div>
                                        <div className="form-group my-2">
                                            <div className="dis-flex">
                                                <label className="form-label col-md-3">{this.state.resources.Label_Expiration}</label>
                                                {/* <input className="form-control col-md-8" type="number" data-val="true" name="expiration" onChange={this.onChangeExpiration} /> */}
                                                <input className="mx-1 col-md-2" style={{borderRadius: '5px', border: '1px solid #ced4da'}} type="number" data-val="true" name="expirationMonth" defaultValue={this.state.expirationMonth} onChange={this.onChangeExpirationMonth} />
                                                /
                                                <input className="mx-1 col-md-2" style={{borderRadius: '5px', border: '1px solid #ced4da'}} type="number" data-val="true" name="expirationYear" defaultValue={this.state.expirationYear} onChange={this.onChangeExpirationYear} />
                                            </div>
                                            <div style={{color: 'red'}} className="p-1 pl-2" id="expirationError">{localStorage.getItem('WspLanguage') == 'language0' ? 'Expiration Date is Required' : 'La Fecha de Expiracion es Requerido'}</div>
                                        </div>
                                        <div className="form-group my-2">
                                            <div className="dis-flex">
                                                <label className="form-label col-md-3">{this.state.resources.Label_Holder}</label>
                                                <input className="form-control col-md-8"  data-val="true" name="holder" defaultValue={this.state.holder} onChange={this.onChangeHolder} />
                                            </div>
                                            <div style={{color: 'red'}} className="p-1 pl-2" id="holderError">{localStorage.getItem('WspLanguage') == 'language0' ? 'Holder is Required' : 'El Titular es Requerido'}</div>
                                        </div>
                                    </form>
                                    <form id="form2">
                                        <div className="form-group my-2">
                                            <div className="dis-flex">
                                                <label className="form-label col-md-3">{this.state.resources.Label_Bank}</label>
                                                <select className="form-control col-md-8" data-val="true" name="bankId" onChange={this.onChangeBank}>
                                                    <option value="">{this.state.resources.Label_SelectOption}</option>
                                                    {this.state.banks.map(bank =>
                                                        <option key={bank.id} value={bank.id} selected={this.state.bankId == bank.id}>{bank.bank}</option>
                                                    )}
                                                </select>
                                            </div>
                                            <div style={{color: 'red'}} className="p-1 pl-2" id="bankError">{localStorage.getItem('WspLanguage') == 'language0' ? 'Bank is Required' : 'El Banco es Requerido'}</div>
                                        </div>
                                        <div className="form-group my-2">
                                            <div className="dis-flex">
                                                <label className="form-label col-md-3">{this.state.resources.Label_AccountType}</label>
                                                <select className="form-control col-md-8" data-val="true" name="accountType" onChange={this.onChangeAccountType}>
                                                    <option value="">{this.state.resources.Label_SelectOption}</option>
                                                    <option key={this.state.resources.Label_CurrentAccount} selected={this.state.accountType == this.state.resources.Label_CurrentAccount} value={this.state.resources.Label_CurrentAccount}>
                                                        {this.state.resources.Label_CurrentAccount}
                                                    </option>
                                                    <option key={this.state.resources.Label_RutAccount} selected={this.state.accountType == this.state.resources.Label_RutAccount} value={this.state.resources.Label_RutAccount}>
                                                        {this.state.resources.Label_RutAccount}
                                                    </option>
                                                    <option key={this.state.resources.Label_VistaAccount} selected={this.state.accountType == this.state.resources.Label_VistaAccount} value={this.state.resources.Label_VistaAccount}>
                                                        {this.state.resources.Label_VistaAccount}
                                                    </option>
                                                </select>
                                            </div>
                                            <div style={{color: 'red'}} className="p-1 pl-2" id="accountTypeError">{localStorage.getItem('WspLanguage') == 'language0' ? 'Account Type is Required' : 'El Tipo de Cuenta es Requerido'}</div>
                                        </div>
                                        <div className="form-group my-2">
                                            <div className="dis-flex">
                                                <label className="form-label col-md-3">{this.state.resources.Label_AccountNumber}</label>
                                                <input className="form-control col-md-8" type="number" data-val="true" name="accountNumber" defaultValue={this.state.accountNumber} onChange={this.onChangeAccountNumber} />
                                            </div>
                                            <div style={{color: 'red'}} className="p-1 pl-2" id="accountNumberError">{localStorage.getItem('WspLanguage') == 'language0' ? 'Account Number is Required' : 'El Número de Cuenta es Requerido'}</div>
                                        </div>
                                    </form>
                                    <form id="form3">
                                        <div className="form-group">

                                        </div>
                                    </form>
                                    <form id="formCardNumber">
                                        <div className="form-group">
                                        <div className="form-group my-2">
                                            <div className="dis-flex">
                                                <label className="form-label col-md-3">{this.state.resources.Label_CardNumber}</label>
                                                <input className="form-control col-md-8" placeholder="Ingrese 4 últimos dígitos de la tarjeta" type="number" min='1' max='9999' data-val="true" name="cardNumber" value={this.state.cardNumber} defaultValue={this.state.cardNumber} onChange={this.onChangeCardNumber} maxLength={4} />
                                            </div>
                                            <div style={{color: 'red' , paddingLeft: "26%" }}  id="cardNumberError2">{localStorage.getItem('WspLanguage') == 'language0' ? 'Card Number is Required' : 'El Número de tarjeta es Requerido'}</div>
                                        </div> 
                                        </div>
                                    </form>
                                </div>
                                <div className="dis-flex"  id="duesNumberDiv">
                                    <div className="form-label col-md-3">
                                        <label>{this.state.resources.Label_DuesNumber}</label>
                                    </div>
                                    <div className=" col-md-8">
                                        <DuesNumber />
                                    </div>  
                                </div>
                            </div>
                        </div>
                         <div style={{color: 'red'}} className="p-1 pl-2" id="waypayError">{localStorage.getItem('WspLanguage') == 'language0' ? 'You Must Select a Way Pay' : 'Tiene que seleccionar una forma de pago para continuar'}</div>
                        <hr></hr>
                    </div>
                </div>
                <div className="row my-4">
                    <div className="mx-auto mb-5">
                        <div className="col-md-4 dis-flex mx-auto">
                            <NoSale data={this.state}/>
                            <div>
                                <button className="btn btn-secondary m-2 px-3" style={{width:100, padding:5}} onClick={this.onCancel}>{this.state.resources.Label_GoBack}</button>
                            </div>
                            <div>
                                <button className="btn btn-success m-2 px-3" style={{width:100, padding:5}} id="btn-checkout" onClick={this.goToCheckOut}>{this.state.resources.Workspace_Next}</button>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    async getResources() {
        const resourceVars = [];
        resourceVars.push("Label_SelectOption");
        resourceVars.push("Label_Name");
        resourceVars.push("Label_LastName");
        resourceVars.push("Label_PostalCode");
        resourceVars.push("Title_City");
        resourceVars.push("Label_Address");
        resourceVars.push("Label_Country");
        resourceVars.push("Label_StateOrProvince");
        resourceVars.push("Label_DocNumber");
        resourceVars.push("Label_DateOfBirth");
        resourceVars.push("Label_Phone");
        resourceVars.push("Label_MobilePhone");
        resourceVars.push("Label_Email");
        resourceVars.push("Label_Description");
        resourceVars.push("Label_Enabled");
        resourceVars.push("Label_Success");
        resourceVars.push("ButtonLabel_Save");
        resourceVars.push("ButtonLabel_Cancel");
        resourceVars.push("Label_Error");
        resourceVars.push("ErrorMessage_RequiredField");
        resourceVars.push("ErrorMessage_DuplicatedName");
        resourceVars.push("ErrorMessage_UnknownError");
        resourceVars.push("ErrorMessage_AssociatedEntity_SubArea_User");
        resourceVars.push("SuccessMessage_DataSavedSuccessfully");
        resourceVars.push("Label_GoBack");
        resourceVars.push("Title_Clients");
        resourceVars.push("Label_Creation");
        resourceVars.push("Label_DocumentType");
        resourceVars.push("Workspace_Next");
        resourceVars.push("Label_NoSale");
        resourceVars.push("Label_Sex");
        resourceVars.push("Label_Male");
        resourceVars.push("Label_Female");
        resourceVars.push("Label_SearchClient");
        resourceVars.push("Label_HistoryClient");
        resourceVars.push("Label_Info");
        resourceVars.push("Label_NoMail");
        resourceVars.push("Label_Number");
        resourceVars.push("Label_Floor");
        resourceVars.push("Label_Department");
        resourceVars.push("Label_CourierComentaries");
        resourceVars.push("Label_InsideComentaries");
        resourceVars.push("Label_DocsRequired");
        resourceVars.push("ButtonLabel_Select");
        resourceVars.push("Label_NoRows");
        resourceVars.push("Title_Objetions");
        resourceVars.push("Title_ScriptSales");
        resourceVars.push("Label_Price");
        resourceVars.push("Label_AddProduct");
        resourceVars.push("Label_Quantity");
        resourceVars.push("Label_Products");
        resourceVars.push("Label_Presentation");
        resourceVars.push("Label_Actions");
        resourceVars.push("Label_TotalPrice");
        resourceVars.push("Label_Comments");
        resourceVars.push("Label_NoSaleReason");
        resourceVars.push("Label_ContactAgain");
        resourceVars.push("Title_WayPay");
        resourceVars.push("Label_DuesNumber");
        resourceVars.push("Label_InterestRate");
        resourceVars.push("Label_Bank");
        resourceVars.push("Label_CardNumber");
        resourceVars.push("Label_SecurityCode");
        resourceVars.push("Label_Expiration");
        resourceVars.push("Label_Holder");
        resourceVars.push("Label_AccountType");
        resourceVars.push("Label_CurrentAccount");
        resourceVars.push("Label_AccountNumber");
        resourceVars.push("Label_VistaAccount");
        resourceVars.push("Label_RutAccount");
        resourceVars.push("Label_Dues");
        resourceVars.push("Label_Due");
        resourceVars.push("Error_ClientWsp");
        resourceVars.push("Label_Chararter");
        resourceVars.push("Label_Remain");
        
        const headersLang = {
            'Content-Type': 'application/json'
        };

        let wspLanguage = localStorage.getItem('WspLanguage');

        if (wspLanguage == 'language1') {
            this.state.steps[0] = 'Inicio'
            this.state.steps[1] = 'Cliente'
            this.state.steps[2] = 'Pedido'
            this.state.steps[3] = 'Pago'
        } else {
            this.state.steps[0] = 'Start'
            this.state.steps[1] = 'Client'
            this.state.steps[2] = 'Order'
            this.state.steps[3] = 'Payment'
        }
        helpers.showLoadingOverlay();
        axios.post(window.config.REACT_APP_SECURITY_API_URL + '/languageresource/getbyvars/' + wspLanguage, resourceVars, {
            headers: headersLang
        })
        .then((response) => {
            const data = response.data.data;
            this.setState({ resources: data});
            // helpers.hideLoadingOverlay();
        })
        .catch((error) => {
            console.log(error);
            this.setState({ loading: false });
            helpers.hideLoadingOverlay();
        });

        const dataClient = JSON.parse(localStorage.getItem('DataClientWsp'));

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': this.state.tokenProducts
        };

        // axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getligthwaypays',
        //     {
        //         'headers': headers
        //     })
        //     .then(response => {
        //         const data = response.data.data;
        //         this.setState({ waypays: data});
        //         // order alphabetically
        //         this.state.waypays.sort((a,b) => a.waypay < b.waypay ? -1 : +(a.waypay > b.waypay))
        //     })
        //     .catch(function (error) {
        //         console.log(error);
        //     });

        // axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getcountrywaypays',
        //     {
        //         'headers': headers
        //     })
        //     .then(response => {
        //         const data = response.data.data;
        //         this.setState({ countryWaypays: data});
        //         // order alphabetically
        //         this.state.countryWaypays.sort((a,b) => a.waypay < b.waypay ? -1 : +(a.waypay > b.waypay))
        //     })
        //     .catch(function (error) {
        //         console.log(error);
        //     });
        
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getmapcouriercitybystatecity/' + dataClient.stateId + '/' + dataClient.cityId,
            {
                'headers': headers
            })
            .then(response => {
                const data = response.data.data;
                //console.log(data)
                this.setState({ mapCourierCities: data});
                // order alphabetically
                this.state.mapCourierCities.sort((a,b) => a.state_name < b.state_name ? -1 : +(a.state_name > b.state_name))
                //console.log(this.state.mapCourierCities);
            
                for (let i = 0; i < this.state.mapCourierCities.length; i++) {
                    // let cities = this.state.mapCourierCities[i].cities.split(',');
                    // if(cities[0] == this.state.cityId.toString() || cities[1] == this.state.cityId.toString() || cities[2] == this.state.cityId.toString() || cities[3] == this.state.cityId.toString()){ //&& !founded
                        if(this.state.mapCourierCities[i].waypay_method == "COD" ||this.state.mapCourierCities[i].waypay_method == "All" || this.state.mapCourierCities[i].waypay_method == "PRE"){
                            //if(this.state.mapCourierCities[i].waypay_method == this.state.paymethodName){
                                this.setState({
                                    courierId: this.state.mapCourierCities[i].courier_id,
                                    courierName: this.state.mapCourierCities[i].courier_name,
                                    deliveryTime: this.state.mapCourierCities[i].delivery_time,
                                    limit: this.state.mapCourierCities[i].limit,
                                    routeType: this.state.mapCourierCities[i].route_type,
                                    showCod: true,
                                    currentMapCourierWaypay: this.state.mapCourierCities[i].waypay_method
                                });
                                this.state.deliveryTime = this.state.mapCourierCities[i].delivery_time;
                                //console.log(this.state.limit);
                            //}
                            helpers.hideLoadingOverlay();
                        } else {
                            this.setState({
                                courierId: this.state.mapCourierCities[i].courier_id,
                                courierName: this.state.mapCourierCities[i].courier_name,
                                showCod: false
                            });
                            helpers.hideLoadingOverlay();
                        }
                    // }
                }
                //console.log('aca lo setea');
                //console.log(this.state.courierId);
                //console.log(this.state.currentMapCourierWaypay);
            })
            .catch(function (error) {
                console.log(error);
                helpers.hideLoadingOverlay();
            });
            
    }
}

function DuesNumber() {
    return (
        <div className="row">
            <div className="col-md-12 px-0">
                <select className="form-control col-md-12" data-val="true" onChange={this.onChangeDues}> {/** name="productId" defaultValue={this.state.productId} onChange={onChangeProduct} */}
                    <option value="0">{this.state.resources.Label_SelectOption}</option>
                {this.state.currentCountryWayPays.map(countryWaypay => (
                    <option style={{ fontSize: '1rem' }} selected={this.state.duesSelected == countryWaypay.id} value={JSON.stringify(countryWaypay)}>{countryWaypay.dues_number} {countryWaypay.dues_number > 1 ? this.state.resources.Label_Dues : this.state.resources.Label_Dues} ({countryWaypay.dues_number} x ${Math.round(((Number(this.state.total) + ((Number(this.state.total) * Number(countryWaypay.interest_rate)) / 100)) / countryWaypay.dues_number)).toLocaleString()} - Total: ${Math.round(((Number(this.state.total) + ((Number(this.state.total) * Number(countryWaypay.interest_rate)) / 100)))).toLocaleString()})</option>        
                ))
                }
                </select>
            </div>
            <div style={{color: 'red'}} className="p-1 pl-2" id="duesSelectedError">{localStorage.getItem('WspLanguage') == 'language0' ? 'Dues Number is Required' : 'El Número de Cuotas es Requerido'}</div>
            
        </div>
    );
}

function NoSale(values) {
  const [open, setOpen] = React.useState(false);
  const [closingReasonId, setClosingReasonId] = React.useState(0);
  const handleOpen = () => {
      setOpen(true);
      if(this.state.closingReasons.length < 1){
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': this.state.tokenProducts
        };
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getclosingreasons',
        {
            'headers': headers
        })
        .then(response => {
            const data = response.data.data;
            this.setState({ closingReasons: data, loading: false });
            // order alphabetically
            this.state.closingReasons.sort((a,b) => a.description < b.description ? -1 : +(a.description > b.description))
        })
        .catch(function (error) {
            console.log(error);
        });
    }
    };
    const handleClose = () => {
        setOpen(false);
    };
    let loading = true;
  
    const onNosale = () => {
        this.SaveNoSale();
        handleClose();
    }

   const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };
    
  return (
    <div>
        <div>
        <button onClick={handleOpen} className="m-2 px-3 btn btn-danger" style={{width:100, padding:5}}>{values.data.resources.Label_NoSale}</button>
        </div>
      
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2" className='text-center'>
            {values.data.resources.Label_NoSale}
          </Typography>
          <hr></hr>
          
            <div className="dis-flex">
                <div className="col-md-6">
                    <label>{values.data.resources.Label_NoSaleReason}</label>
                    <select className="form-control col-md-12" data-val="true" name="closingReasonId" defaultValue={this.state.closingReasonId} onChange={this.onChangeClosingReasonId} > {/** */}
                        <option value="">{values.data.resources.Label_SelectOption}</option>
                        {this.state.closingReasons.map(closing =>
                            // closing.enabled &&
                            <option key={closing.id} value={closing.id}>
                                {closing.description}
                            </option>
                        )}
                    </select>
                </div>
                
                <div className="col-md-6">
                    <label>{values.data.resources.Label_ContactAgain}</label>
                    <input type="datetime-local"  className="form-control col-md-12" onChange={this.onContactAgain}/>
                </div>
            </div>
            <div className="m-2 p-1">
                <label>{values.data.resources.Label_Comments}</label>
                <textarea className="form-control col-md-12" maxLength={100} onChange={this.onChangeCommnets}></textarea>
                <div className="row m-0">
                        <div className="col-md-6">
                        </div>
                        <div className="col-md-6" style={{textAlign:'right' }}>
                            {
                                this.state.comments.charAt(0) && <label id="lblComentaries"> {this.state.resources.Label_Remain} {this.state.remainComentaries} {this.state.resources.Label_Chararter} </label>
                            }
                            
                        </div>
                </div>
            </div>
            <div className="text-center">
                <button className="btn btn-primary" disabled={this.state.closingReasonId < 1} onClick={onNosale}>
                    {values.data.resources.ButtonLabel_Save}
                </button>
            </div>
                  
        </Box>
      </Modal>
    </div>
  );
}