import React, { Component } from 'react';
import axios from 'axios';
import helpers from '../../Helpers';
import $ from 'jquery';
import queryString from 'query-string';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { styled } from '@mui/material/styles';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Button from '@mui/material/Button';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { RemoveShoppingCart } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { maxWidth } from '@mui/material/node_modules/@mui/system';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart';
const Swal = require('sweetalert2')

export class NoSaleComponent extends Component {
    static displayName = NoSaleComponent.name;

    constructor(props) {
        super(props);

        this.state = {
            steps: ['Inicio', 'Cliente', 'Pedido', 'Pago', 'Checkout'],
            activeStep: 2,
            resources: [],
        };
    }

    componentDidMount() {
        
        // let data = JSON.parse(localStorage.getItem('DataWorkspace'));
        // // console.log(data);
        // this.setState({
        //     countryId: data.countryId,
        //     productId: data.productId
        // });
        // let productsCountries = JSON.parse(localStorage.getItem('WspProductCountries'));
        // productsCountries.sort((a, b) => a.product_name < b.product_name ? -1 : +(a.product_name > b.product_name));
        // this.setState({
        //     productCountries: productsCountries
        // });

        this.getResources();

        let wspLanguage = localStorage.getItem('WspLanguage');

        Swal.fire({
            icon: 'success',
            title: '',
            text: wspLanguage == 'language0' ? "Process Has Finalized. Thanks" : "El Proceso ha terminado. Gracias",
            
        })

    }

    render() {

        return (
            <div className="container">
                 <div className="row text-center">
                    <h2 style={{marginTop: '240px'}}>
                        {localStorage.getItem('WspLanguage') == 'language0' ? "You Can Close this Window" : "Ya puede Cerrar esta Ventana"}
                    </h2>
                </div>
            </div>
        );
    }

    async getResources() {
        const resourceVars = [];
        resourceVars.push("Label_SelectOption");
        resourceVars.push("Label_Name");
        resourceVars.push("Label_LastName");
        resourceVars.push("Label_PostalCode");
        resourceVars.push("Title_City");
        resourceVars.push("Label_Address");
        resourceVars.push("Label_Country");
        resourceVars.push("Label_StateOrProvince");
        resourceVars.push("Label_DocNumber");
        resourceVars.push("Label_DateOfBirth");
        resourceVars.push("Label_Phone");
        resourceVars.push("Label_MobilePhone");
        resourceVars.push("Label_Email");
        resourceVars.push("Label_Description");
        resourceVars.push("Label_Enabled");
        resourceVars.push("Label_Success");
        resourceVars.push("ButtonLabel_Save");
        resourceVars.push("ButtonLabel_Cancel");
        resourceVars.push("Label_Error");
        resourceVars.push("ErrorMessage_RequiredField");
        resourceVars.push("ErrorMessage_DuplicatedName");
        resourceVars.push("ErrorMessage_UnknownError");
        resourceVars.push("ErrorMessage_AssociatedEntity_SubArea_User");
        resourceVars.push("SuccessMessage_DataSavedSuccessfully");
        resourceVars.push("Label_GoBack");
        resourceVars.push("Title_Clients");
        resourceVars.push("Label_Creation");
        resourceVars.push("Label_DocumentType");
        resourceVars.push("Workspace_Next");
        resourceVars.push("Label_NoSale");
        resourceVars.push("Label_Sex");
        resourceVars.push("Label_Male");
        resourceVars.push("Label_Female");
        resourceVars.push("Label_SearchClient");
        resourceVars.push("Label_HistoryClient");
        resourceVars.push("Label_Info");
        resourceVars.push("Label_NoMail");
        resourceVars.push("Label_Number");
        resourceVars.push("Label_Floor");
        resourceVars.push("Label_Department");
        resourceVars.push("Label_CourierComentaries");
        resourceVars.push("Label_InsideComentaries");
        resourceVars.push("Label_DocsRequired");
        resourceVars.push("ButtonLabel_Select");
        resourceVars.push("Label_NoRows");
        resourceVars.push("Title_Objetions");
        resourceVars.push("Title_ScriptSales");
        resourceVars.push("Label_Price");
        resourceVars.push("Label_AddProduct");
        resourceVars.push("Label_Quantity");
        resourceVars.push("Label_Products");
        resourceVars.push("Label_Presentation");
        resourceVars.push("Label_Actions");
        resourceVars.push("Label_TotalPrice");
        resourceVars.push("Label_Comments");
        resourceVars.push("Label_NoSaleReason");
        resourceVars.push("Label_ContactAgain");
        resourceVars.push("Label_ProcessHasFinalized");

        const headers = {
            'Content-Type': 'application/json'
        };

        let wspLanguage = localStorage.getItem('WspLanguage');

        if (wspLanguage == 'language1') {
            this.state.steps[0] = 'Inicio'
            this.state.steps[1] = 'Cliente'
            this.state.steps[2] = 'Pedido'
            this.state.steps[3] = 'Pago'
        } else {
            this.state.steps[0] = 'Start'
            this.state.steps[1] = 'Client'
            this.state.steps[2] = 'Order'
            this.state.steps[3] = 'Payment'
        }

        axios.post(window.config.REACT_APP_SECURITY_API_URL + '/languageresource/getbyvars/' + wspLanguage, resourceVars, {
            headers: headers
        })
        .then((response) => {
            const data = response.data.data;
            this.setState({ resources: data, loading: false });
        })
        .catch((error) => {
            console.log(error);
            this.setState({ loading: false });
        });
    }
}