import React, { Component } from 'react';
import axios from 'axios';
import helpers from '../../Helpers';
import '../Companies/Company.css';
import { Link } from 'react-router-dom';  

import Button from '@material-ui/core/Button';
import DataCreation from '../DataCreation/DataCreation';

export class ProductsDetail extends Component {
    static displayName = ProductsDetail.name;

    constructor(props) {
        super(props);

        this.onChangeName = this.onChangeName.bind(this);
        this.onChangeDescription = this.onChangeDescription.bind(this);
        this.onChangeTreatmentId = this.onChangeTreatmentId.bind(this);
        this.onChangeBrandId = this.onChangeBrandId.bind(this);
        this.onChangeSupplierId = this.onChangeSupplierId.bind(this);
        this.onChangeImageFile = this.onChangeImageFile.bind(this);
        this.onChangeSku = this.onChangeSku.bind(this);
        this.onChangeDuration = this.onChangeDuration.bind(this);
        this.onChangeEnabled = this.onChangeEnabled.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onSubmit = this.onSubmit.bind(this);  
        this.onChangePaymentId = this.onChangePaymentId.bind(this);
        this.onChangeClassificationId = this.onChangeClassificationId.bind(this);

        this.state = {
            id: 0,
            name: null,
            description: null,
            brandId: null,
            brandName: null,
            supplierId: null,
            supplierName: null,
            treatmentId: null,
            treatmentName: null,
            image: null,
            imageFile: null,
            sku: '',
            enabled: true,
            loading: true,
            dataCountryProduct: null,
            countries: null,
            resources: [],
            brands: [],
            suppliers: [],
            treatments: [],
            viewMode: false,
            currentUserId: 0,
            payments: [],
            paymentId: null,
            classifications: [],
            classificationId: null,
        };
    }

    componentDidMount() {
        this.getResources();
        this.populateData();
        helpers.setNavActive("#nav-products");
        helpers.setClientValidation();
        
        let url = window.location.href;
        if (url.includes('view')) {
            this.setState({
                viewMode: true
            });
        }
        let currentUser = helpers.getUserData();
        this.setState({
            currentUserId: currentUser.id
        });

        let sort = JSON.parse(localStorage.getItem('ProductsListSorting'))
        localStorage.setItem('CopyProductsListSorting', JSON.stringify(sort));

        let paging = JSON.parse(localStorage.getItem('ProductsListPagination'))
        localStorage.setItem('CopyProductsListPaging', JSON.stringify(paging));

        let filtering = JSON.parse(localStorage.getItem('ProductsListFiltering'))
        localStorage.setItem('CopyProductsListFiltering', JSON.stringify(filtering));
    }

    async onSubmit(e) {
        e.preventDefault();

        var form = document.querySelectorAll('.needs-validation')[0];

        if (!form.checkValidity())
            return;

        this.setState({ loading: true });

        // upload logo file
        const formData = new FormData();
        formData.append('file', this.state.imageFile);

        const uploadLogoHeaders = {
            'Content-Type': 'multipart/form-data',
            'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME)
        };

        const data = {
            id: this.state.id,
            name: this.state.name,
            description: this.state.description,
            image: this.state.imageFile,
            brand_id: this.state.brandId,
            supplier_id: this.state.supplierId,
            treatment_id: this.state.treatmentId,
            duration: this.state.duration,
            sku_id: this.state.sku,
            enabled: this.state.enabled,
            last_update_user_id: this.state.currentUserId,
            payment_id: this.state.paymentId,
            classification_id: this.state.classificationId
        };
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME)
        };

        axios.post(window.config.REACT_APP_PRODUCTS_API_URL + '/product/save', data, {
            headers: headers
        })
            .then((response) => {
                helpers.notifySuccess(this.state.resources.Label_Success, this.state.resources.SuccessMessage_DataSavedSuccessfully);
                this.props.history.push('/products');
            })
            .catch((error) => {
                console.log(error);

                let msg = this.state.resources.ErrorMessage_UnknownError;
                if (error.response.data.error_code == window.errorCodes.DuplicatedEntityName)
                    msg = this.state.resources.ErrorMessage_DuplicatedName;
                else if (error.response.data.error_code == window.errorCodes.AssociatedEntity)
                    msg = this.state.resources.ErrorMessage_AssociatedEntity_Company_User;

                helpers.notifyError(this.state.resources.Label_Error, msg);
                this.setState({ loading: false });
            });
    }  

    onCancel(e) {
        e.preventDefault();
        this.props.history.push("/products");
    }

    onChangeName(e) {
        this.setState({
            name: e.target.value
        });
    }  
    onChangeDescription(e) {
         this.setState({
            description: e.target.value
        });
    } 
    onChangeImageFile(e) {
        this.setState({
            imageFile: e.target.files[0],
            //show new file name in UI
            image: e.target.files[0].name
        });
    }  
    onChangeSku(e) {
        this.setState({
            sku: e.target.value
        });
    }
    
    onChangeBrandId(e) {
        this.setState({
            brandId: e.target.value
        });
    }

    onChangeSupplierId(e) {
        this.setState({
            supplierId: e.target.value
        });
    }

    onChangeTreatmentId(e) {
        this.setState({
            treatmentId: e.target.value
        });

        if(e.target.value < 1){
            this.state.duration = "";
        }
    }
    onChangeEnabled(e) {
        this.setState({
            enabled: e.target.checked
        });
    }
    
    onChangeDuration(e) {
        this.setState({
            duration: e.target.value
        });
    }

    onChangePaymentId(e) {
        this.setState({
            paymentId: e.target.value
        });
    }

    onChangeClassificationId(e){
        this.setState({
            classificationId: e.target.value
        });
    }

    convertBase64(files) {
        Array.from(files).forEach((file) => {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (e) => {
                let filePreview = document.querySelector('#file-preview');
                    //e.target.result contents the base64 data from the image uploaded
                    filePreview.src = e.target.result;
                    let previewZone = document.getElementById('file-preview-zone');
                    previewZone.appendChild(filePreview);
                let arrayAux = [];
                let base64 = reader.result;
                arrayAux = base64.split(',');
                this.setState({
                    imageFile: arrayAux[1],
                    //show new file name in UI
                    image: files[0].name
                });
            }
        })
    }

    render() {
        
        let contents =
            <form className="form row g-3 needs-validation" onSubmit={this.onSubmit} noValidate>
                {/*ENABLED*/}
                <div className="form-group col-md-12">
                    <div className="form-check form-switch">
                        <input disabled={this.state.viewMode} className="form-check-input" type="checkbox" id="enabled" checked={this.state.enabled} onChange={this.onChangeEnabled} />
                        <label className="form-check-label" htmlFor="enabled">{this.state.resources.Label_Enabled}</label>
                        <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                    </div>
                </div>

                {/*LOGO FILE / NAME*/}
                <div className=" form-group col-md-4">
                    
                    <div className="pl-3 pt-3">
                        <input
                            accept="image/*"
                            className="input-upload"
                            id="contained-button-file"
                            multiple
                            type="file"
                            onChange={(e)=>this.convertBase64(e.target.files)}
                            // onChange={this.onChangeImageFile}
                        />
                        {/* <label className="px-4">{this.state.image}</label> */}
                        <label htmlFor="contained-button-file">
                            <Button variant="outlined" color="secondary" component="span" disabled={this.state.viewMode}>
                                Image
                            </Button>
                        </label>
                        <div id="file-preview-zone" style={{ width: '100px' , height: '100px' }}>
                            <img id="file-preview" src={'data:image/png;base64,' + this.state.imageFile} style={{ height: '150px' }}/>
                         </div>
                    </div>
                    {/* <label className=" form-label">{this.state.resources.Label_Logo}</label>
                    <input className="form-control" type="file" name="logofile" id="logofile" defaultValue={this.state.logoFile} onChange={this.onChangeLogoFile} required /> */}
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                </div>
                <div className="form-group col-md-8" >
                    <div className="row col-md-12">
                        <div className="col-md-6">
                            <label className=" form-label">{this.state.resources.Label_Name}</label>
                            <input disabled={this.state.viewMode} className="form-control" type="text" name="name" onChange={this.onChangeName} value={this.state.name} required />
                            <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                        </div>
                        <div className="col-md-6">
                            <label className=" form-label">{this.state.resources.Label_Classification}</label>
                            <select className="form-control" data-val="true" name="classificationId" defaultValue={this.state.classificationId} onChange={this.onChangeClassificationId}>
                                <option value="">{this.state.resources.Label_SelectOption}</option>
                                {this.state.classifications.map(classifications =>
                                    <option key={classifications.id} value={classifications.id} selected={this.state.classificationId == classifications.id}>{classifications.name}</option>
                                )}
                            </select>
                            <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                        </div>
                    </div>

                    <div className="row col-md-12">
                        <div className="col-md-6">
                            <label className=" form-label">SKU</label>
                            <input disabled={this.state.viewMode} className="form-control" type="text" name="sku" onChange={this.onChangeSku} value={this.state.sku} required />
                            <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                        </div>
                        <div className="col-md-6">
                            <label className=" form-label">{this.state.resources.Label_Description}</label>
                            <input disabled={this.state.viewMode} className="form-control" type="text" maxlength="150" name="description" onChange={this.onChangeDescription} value={this.state.description} placeholder="Max (150)"/>
                            <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                        </div>
                    </div>

                    <div classname="row col-md-12" style={{display: 'flex'}}>
                        <div className="col-md-6 pr-4">
                            <label className=" form-label">{this.state.resources.Label_Brand}</label>
                            <select disabled={this.state.viewMode} className="form-control" data-val="true" name="brandid" defaultValue={this.state.brandId} onChange={this.onChangeBrandId} required>
                                <option value={this.state.brandId}>{this.state.brandName}</option>
                                {this.state.brands.map(brand =>
                                    <option key={brand.id} value={brand.id}>{brand.name}</option>
                                )}
                            </select>
                            <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                        </div>

                        <div className="col-md-6 pl-0" style={{paddingRight: '2rem'}}>
                            <label className=" form-label">{this.state.resources.Label_Supplier}</label>
                            <select disabled={this.state.viewMode} className="form-control" data-val="true" name="supplierid" defaultValue={this.state.supplierId} onChange={this.onChangeSupplierId} required>
                                <option value={this.state.supplierId}>{this.state.supplierName}</option>
                                {this.state.suppliers.map(supplier =>
                                    <option key={supplier.id} value={supplier.id}>{supplier.name}</option>
                                )}
                            </select>
                            <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                        </div>
                    </div>
                    <div className="row col-md-12">
                        <div className="col-md-6">
                            <label className=" form-label">{this.state.resources.Title_Treatments}</label>
                            <select disabled={this.state.viewMode} className="form-control" data-val="true" name="treatmentid" defaultValue={this.state.treatmentId} onChange={this.onChangeTreatmentId}>
                            <option value="">{this.state.resources.Label_SelectOption}</option>
                                {this.state.treatments.map(treatment =>
                                    <option key={treatment.id} value={treatment.id} selected={treatment.id == this.state.treatmentId}>{treatment.name}</option>
                                )}
                            </select>
                            {/* <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>      */}
                        </div>
                        
                        <div className="col-md-6">
                            <label className=" form-label">{this.state.resources.Label_DurationTreatment}</label>
                            <input disabled={this.state.viewMode} className="form-control" type="number" name="duration" onChange={this.onChangeDuration} value={this.state.duration} required={this.state.treatmentId > 0 ? true : false}/>
                        </div>
                    </div>
                    
                    <div className="col-md-6 pr-4" >

                        <label className=" form-label">{this.state.resources.Label_ProductPayment}</label>
                        <select className="form-control" data-val="true" name="paymentId" defaultValue={this.state.paymentId} onChange={this.onChangePaymentId} required>
                            <option value="">{this.state.resources.Label_SelectOption}</option>
                            {this.state.payments.map(payment =>
                                <option key={payment.id} value={payment.id} selected={this.state.paymentId == payment.id}>{payment.description}</option>
                            )}
                        </select>

                    </div>
                    
                </div>
               
                
                <div className=" form-group col-md-6">
                     <DataCreation resources={this.state}></DataCreation>
                </div>
                {this.state.viewMode == false ?
                <div className=" form-group col-md-6" style={{ textAlign: 'end' }}>

                    <Link to={"/products-info/" + this.state.id} values={this.state.dataCountryProduct} className="btn btn-outline-primary">{this.state.resources.Label_LookCountries}</Link>

                </div>
                :
                <></>
                }

                {/**Buttons Header */}
                <div className="buttons-head row">
                    {this.state.viewMode == false ?
                    <div className=" buttons-childs">
                        <button type="submit" className="btn btn-primary">{this.state.resources.ButtonLabel_Save}</button>
                        <button className="btn btn-danger" onClick={this.onCancel}>{this.state.resources.ButtonLabel_Cancel}</button>
                    </div>
                    :
                    <button className="btn btn-danger btn-block" onClick={this.onCancel}>{this.state.resources.Label_GoBack}</button>
                    }
                 </div>
            </form>;

        return (

           <div>
           
            <div className="mt-2">
                <ol className="breadcrumb my-4">
                    <li className="breadcrumb-item active">{this.state.resources.Label_Products}</li>
                </ol>
                <div className="card">
                    <div className="card-body">
                        {/* {this.state.loading ? helpers.showLoadingOverlay() : helpers.hideLoadingOverlay()} */}
                        {contents}
                    </div>
                </div>
            </div>
        </div> 
        );
    }

    async getResources() {
        const resourceVars = [];
        resourceVars.push("Label_Products");
        resourceVars.push("Label_Name");
        resourceVars.push("Label_Description");
        resourceVars.push("Label_Logo");
        resourceVars.push("Label_FiscalId");
        resourceVars.push("Label_Brand");
        resourceVars.push("Label_Supplier");
        resourceVars.push("Title_Treatments");
        resourceVars.push("Label_Logo");
        resourceVars.push("Label_BusinessArea");
        resourceVars.push("Label_Enabled");
        resourceVars.push("Label_Success");
        resourceVars.push("Label_SelectOption");
        resourceVars.push("ButtonLabel_Save");
        resourceVars.push("ButtonLabel_Cancel");
        resourceVars.push("Label_Error");
        resourceVars.push("ErrorMessage_RequiredField");
        resourceVars.push("ErrorMessage_DuplicatedName");
        resourceVars.push("ErrorMessage_UnknownError");
        resourceVars.push("ErrorMessage_AssociatedEntity_Company_User");
        resourceVars.push("ErrorMessage_ErrorWhileUploadingImage");
        resourceVars.push("ErrorMessage_InvalidEmail");
        resourceVars.push("SuccessMessage_DataSavedSuccessfully");
        resourceVars.push("Label_Creation");
        resourceVars.push("Label_CreationUser");
        resourceVars.push("Label_LastUpdate");
        resourceVars.push("Label_LastUpdateUser");
        resourceVars.push("Label_ProductinCountry");
        resourceVars.push("Label_DurationTreatment");
        resourceVars.push("Label_LookKeys");
        resourceVars.push("Label_GoBack");
        resourceVars.push("Label_LookCountries");
        resourceVars.push("Label_ProductPayment");
        resourceVars.push("Label_Classification");
        
        const headers = {
            'Content-Type': 'application/json'
        };

        axios.post(window.config.REACT_APP_SECURITY_API_URL + '/languageresource/getbyvars/' + helpers.getUiLanguage(), resourceVars, {
            headers: headers
        })
            .then((response) => {
                const data = response.data.data;
                this.setState({ resources: data, loading: false });
            })
            .catch((error) => {
                console.log(error);
                this.setState({ loading: false });
            });
    }

    async populateData() {
        this.setState({
            loading: true
        });

        let authTokenProducts = await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME);
        let authTokenSecurity = await helpers.getAuthorizationToken(process.env.REACT_APP_SECURITY_API_NAME);
        helpers.showLoadingOverlay();
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/product/getbyid/' + this.props.match.params.id,
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                // console.log(data);
                this.setState({
                    id: data.id,
                    name: data.name,
                    sku: data.sku_id,
                    brandId: data.brand_id,
                    brandName: data.brand_name,
                    description: data.description,
                    treatmentId: data.treatment_id,
                    treatmentName: data.treatment_name,
                    supplierId: data.supplier_id,
                    supplierName: data.supplier_name,
                    paymentId: data.payment_id,
                    imageFile: data.image,
                    enabled: data.enabled,
                    duration: data.duration,
                    classificationId: data.classification_id,
                    creation: helpers.getDisplayDateTimeFromUtc(data.creation),
                    creationUser: data.creation_user_fullname,
                    lastUpdate: data.last_update == null ? '' : helpers.getDisplayDateTimeFromUtc(data.last_update),
                    lastUpdateUser: data.last_update_user_fullname == null ? '' : data.last_update_user_fullname,
                    loading: false
                });

                axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/productcountry/getproductbyid/' + this.state.id,
                    {
                        'headers': { 'Authorization': authTokenProducts }
                    })
                    .then(response => {
                    // console.log(response.data.data)
                    let data = response.data.data;
                    let countriesArr = [];
                        for (let i = 0; i < data.length; i++) {
                            if (data[i].country_name) {
                                countriesArr.push(
                                    {
                                        'country': data[i].country_name,
                                        'enabled': data[i].enabled 
                                    }
                                );
                            }
                        }
                        localStorage.setItem('dataCountriesProductInfo', JSON.stringify(countriesArr));
                        helpers.hideLoadingOverlay();
                    })
                    .catch(function (error) {
                        console.log(error);
                        helpers.hideLoadingOverlay();
                    });

            //     axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/countryproductinfo/getbyproductid/' + this.state.id,
            //     {
            //         'headers': { 'Authorization': authTokenProducts}
            //     })
            //     .then(response => {
            //         const dataArr = response.data.data;
            //         let data = [];
            //         console.log(dataArr);
            //         for (let i = 0; i < dataArr.length; i++) {
            //             data.push(dataArr[i].country_name);
            //         }
            //         // console.log(data);
            //         this.setState({ dataCountryProduct: data, loading: false });
            //         localStorage.setItem('dataCountriesProductInfo', data);
                    
            //     })
            //     .catch(function (error) {
            //         console.log(error);
            //     });

            //     axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/countryproductinfo/getbyproductid/' + this.props.match.params.id,
            //     {
            //         'headers': { 'Authorization': authTokenProducts}
            //     })
            //     .then(response => {
            //         let dataCountryProduct = response.data.data;
            //         localStorage.setItem('dataCountryProductInfo', JSON.stringify(dataCountryProduct[0]));
            //     })
            //     .catch(function (error) {
            //         console.log(error);
            //     });
                
            })
            .catch(function (error) {
                console.log(error);
                // this.setState({ loading: false });
            })

        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/brand/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ brands: data, loading: false });
                // order alphabetically
                this.state.brands.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                // console.log(this.state.brands);
            })
            .catch(function (error) {
                console.log(error);
            });

        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/supplier/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ suppliers: data, loading: false });
                // order alphabetically
                this.state.suppliers.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                // console.log(this.state.suppliers);
            })
            .catch(function (error) {
                console.log(error);
            });

        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/treatment/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ treatments: data, loading: false });
                // order alphabetically
                this.state.treatments.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                // console.log(this.state.treatments);
            })
            .catch(function (error) {
                console.log(error);
            });
        
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/companybusinessarea/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ businessAreas: data, loading: false });
                // order alphabetically
                this.state.businessAreas.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                // console.log(this.state.businessAreas);
            })
            .catch(function (error) {
                console.log(error);
            });
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/productpayment/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ payments: data, loading: false });
                // order alphabetically
                this.state.payments.sort((a,b) => a.description < b.description ? -1 : +(a.description > b.description))
                // console.log(this.state.payments);
            })
            .catch(function (error) {
                console.log(error);
            });
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/productcategory/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ classifications: data, loading: false });
                // order alphabetically
                this.state.classifications.sort((a,b) => a.description < b.description ? -1 : +(a.description > b.description))
                // console.log(this.state.classifications);
            })
            .catch(function (error) {
                console.log(error);
            });
    }
}

