import React, { Component } from 'react';
import axios from 'axios';
import $ from 'jquery';
import helpers from '../../Helpers'

export class PublicityCreate extends Component {
    static displayName = PublicityCreate.name;

    constructor(props) {
        super(props);

        this.onChangePublicity = this.onChangePublicity.bind(this);
        this.onChangeCountry = this.onChangeCountry.bind(this);
        this.onChangeProductsCountry = this.onChangeProductsCountry.bind(this);
        this.onChangeEnabled = this.onChangeEnabled.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onSubmit = this.onSubmit.bind(this);  

        this.state = {
            id: 0,
            publicity: '',
            countries: [],
            countryId: null,
            productId: null,
            products: [],
            product_country_id: null,
            product_countries: [],
            country_name: '',
            enabled: true,
            loading: true,
            resources: [],
            userId: 0,
            repeatPublicity: [],
        };
    }

    async componentDidMount() {
        this.getResources();
        this.populateData();
        helpers.setNavActive("#nav-publicity");
        helpers.setClientValidation();

        let currentUser = helpers.getUserData();
        this.setState({
            userId: currentUser.id
        });

        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/publicity/getall',
        {
            'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
        })
        .then(response => {
            console.log(response.data.data)
            const data = response.data.data;
            this.state.repeatPublicity = data ;
            console.log(this.state.repeatPublicity)
            // this.setState({ countries: data});
            // // order alphabetically
            this.state.repeatPublicity.sort((a,b) => a.publicity < b.publicity ? -1 : +(a.publicity > b.publicity))
            // // console.log(this.state.countries);
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    async onSubmit(e) {
        e.preventDefault();

        var form = document.querySelectorAll('.needs-validation')[0];

        if (!form.checkValidity())
            return;

        this.setState({ loading: true });

        const data = {
            publicity: this.state.publicity,
            country_id: this.state.countryId,
            product_country_id: this.state.productId,
            enabled: this.state.enabled,
            creation_user_id: this.state.userId,
        };
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME)
        };
        // console.log(this.state.repeatPublicity)
        // for (let i = 0; i < this.state.repeatPublicity; i++) {
        //     if (this.state.publicity.toString().toLocaleLowerCase() == this.state.repeatPublicity[i].toString().toLocaleLowerCase()) {
        //  helpers.notifyWarning(this.state.resources.Label_Error, this.state.resources.ErrorMessage_UserLdapAlreadyExist);
        //          }
        //   }

        axios.post(window.config.REACT_APP_PRODUCTS_API_URL + '/publicity/save', data, {
            headers: headers
        })
            .then((response) => {
                helpers.notifySuccess(this.state.resources.Label_Success, this.state.resources.SuccessMessage_DataSavedSuccessfully);
                this.props.history.push('/publicity');
            })
            .catch((error) => {
                console.log(error);

                let msg = this.state.resources.ErrorMessage_PublicityError;
                //let msg = localStorage.getItem('ErrorLanguage') == 'language0' ? `Ya existe una publicidad con la misma combinación` : `Publicity is already exist`;
                helpers.notifyError(this.state.resources.Label_Error, msg);
                this.setState({ loading: false });
            });
    }  

    onCancel(e) {
        e.preventDefault();
        this.props.history.push("/publicity");
    }

    onChangePublicity(e) {
        this.setState({
            publicity: e.target.value
        });
    }
    
    async onChangeCountry(e) {
        this.setState({
            countryId: e.target.value
        });
        let prueba = e.target.value;

        console.log(prueba.length)
        if(prueba.length == 0){
            this.state.product_countries = [];
        }else{
         let authTokenProducts = await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME);
            axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/productcountry/getproductsbycountryid/' + this.state.countryId,
                {
                    'headers': { 'Authorization': authTokenProducts}
                })
                .then(response => {
                    const data = response.data.data;
                    this.state.product_countries = [];
                     console.log(data);
                    for (let i = 0; i < data.length; i++) {
                        this.state.product_countries.push(data[i].product_id);
                    }
                    // order alphabetically
                    this.state.product_countries.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                     console.log(this.state.product_countries);
                    setTimeout(() => {
                        this.setState({
                            product_countries: this.state.product_countries,
                            //country_name: data[0].country_name,

                         });
                    }, 1000);
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    }
    
    onChangeProductsCountry(e) {
        this.setState({
            productId: e.target.value
        });
    }

    onChangeEnabled(e) {
        this.setState({
            enabled: e.target.checked
        });
    } 

    render() {
        let contents =
            <form className="form row g-3 needs-validation" onSubmit={this.onSubmit} noValidate>
                <div className="form-group col-md-12">
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" id="enabled" defaultChecked={this.state.enabled} onChange={this.onChangeEnabled} />
                        <label className="form-check-label" htmlFor="enabled">{this.state.resources.Label_Enabled}</label>
                        <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                    </div>
                </div>

                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Title_Publicity}</label>
                    <input className="form-control" type="text" maxLength="150"  name="publicity" onChange={this.onChangePublicity} value={this.state.publicity} required />
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                </div>

                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_Country}</label>
                    <select className="form-control" data-val="true" name="countryid" defaultValue={this.state.countryId} onChange={this.onChangeCountry} required>
                        <option value="">{this.state.resources.Label_SelectOption}</option>
                        {this.state.countries.map(country =>
                            <option key={country.id} value={country.id}>{country.name}</option>
                        )}
                    </select>
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                </div>

                 <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_Products}</label>
                    <select className="form-control" data-val="true" name="productid" defaultValue={this.state.productId} onChange={this.onChangeProductsCountry} required>
                        <option value="">{this.state.resources.Label_SelectOption}</option>
                        {this.state.products.map(product =>
                            this.state.product_countries.includes(product.id) &&
                                <option key={product.id} value={product.id}>{product.name}</option>
                        )}
                    </select>
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                </div> 

                {/**Buttons Header */}
                 <div className="buttons-head row">
                    <div className=" buttons-childs">
                        <button type="submit" className="btn btn-primary">{this.state.resources.ButtonLabel_Save}</button>
                        <button className="btn btn-danger" onClick={this.onCancel}>{this.state.resources.ButtonLabel_Cancel}</button>
                    </div>
                 </div>

            </form>;

        return (
            <div className="mt-2">
                <ol className="breadcrumb my-4">
                    <li className="breadcrumb-item active">{this.state.resources.Title_Publicity}</li>
                </ol>
                <div className="card">
                    <div className="card-body">
                        {this.state.loading ? helpers.showLoadingOverlay() : helpers.hideLoadingOverlay()}
                        {contents}
                    </div>
                </div>
            </div>
        );
    }

    async getResources() {
        const resourceVars = [];
        resourceVars.push("Title_CampaingType");
        resourceVars.push("Label_Name");
        resourceVars.push("Title_Publicity");
        resourceVars.push("Label_Country");
        resourceVars.push("Title_Carrier");
        resourceVars.push("Label_SelectOption");
        resourceVars.push("Label_Enabled");
        resourceVars.push("Label_Success");
        resourceVars.push("ButtonLabel_Save");
        resourceVars.push("ButtonLabel_Cancel");
        resourceVars.push("Label_Error");
        resourceVars.push("ErrorMessage_RequiredField");
        resourceVars.push("ErrorMessage_DuplicatedName");
        resourceVars.push("ErrorMessage_UnknownError");
        resourceVars.push("SuccessMessage_DataSavedSuccessfully");
        resourceVars.push("Label_Products");
        resourceVars.push("ErrorMessage_PublicityError");
        const headers = {
            'Content-Type': 'application/json'
        };

        axios.post(window.config.REACT_APP_SECURITY_API_URL + '/languageresource/getbyvars/' + helpers.getUiLanguage(), resourceVars, {
            headers: headers
        })
            .then((response) => {
                const data = response.data.data;
                this.setState({ resources: data});
            })
            .catch((error) => {
                console.log(error);
                this.setState({ loading: false });
            });
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/country/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                console.log(response.data.data)
                const data = response.data.data;
                this.setState({ countries: data});
                // order alphabetically
                this.state.countries.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                // console.log(this.state.countries);
            })
            .catch(function (error) {
                console.log(error);
            });

            axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/product/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ products: data, loading: false});
                // order alphabetically
                this.state.products.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                // console.log(this.state.brands);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    async populateData() { }
}