import React, { Component } from 'react';
import axios from 'axios';
import helpers from '../../Helpers';
import $ from 'jquery';

export class ChangePassword extends Component {
    static displayName = ChangePassword.name;

    constructor(props) {
        super(props);

        this.onChangePasswordLdap = this.onChangePasswordLdap.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

        this.state = {
            id: 0,
            userLdap: null,
            passwordLdap: null,
            resources: [],
            loading: true
        };
    }

    componentDidMount() {
        this.getResources();
        this.populateData();
        helpers.setNavActive("#nav-users");
        helpers.setClientValidation();
    }

    async onSubmit(e) {
        e.preventDefault();

        var form = document.querySelectorAll('.needs-validation')[0];

        if (!form.checkValidity())
            return;

        this.setState({ loading: true });

        const data = {
            password: this.state.passwordLdap
        };
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_SECURITY_API_NAME)
        };

        axios.post(window.config.REACT_APP_SECURITY_API_URL + '/user/changepassword/' + this.props.match.params.id, data, {
            headers: headers
        })
            .then((response) => {
                helpers.notifySuccess(this.state.resources.Label_Success, this.state.resources.SuccessMessage_DataSavedSuccessfully);
                this.props.history.push('/users');
            })
            .catch((error) => {
                console.log(error);

                let msg = this.state.resources.ErrorMessage_UnknownError;

                helpers.notifyError(this.state.resources.Label_Error, msg);
                this.setState({ loading: false });
            });
    }

    onCancel(e) {
        e.preventDefault();
        this.props.history.push("/users");
    }

    onChangePasswordLdap(e) {
        this.setState({
            passwordLdap: e.target.value
        });
    }

    render() {
        let contents =
            <form className="form row g-3 needs-validation" onSubmit={this.onSubmit} noValidate>
                <div className="form-group col-md-12">
                    <input type="hidden" name="id" value={this.state.id} />
                </div>

                {/*USER LDAP / PASSWORD LDAP*/}
                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_UserLdap}</label>
                    <input className="form-control" type="text" name="userldap" defaultValue={this.state.userLdap} disabled />
                </div>
                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_PasswordLdap}</label>
                    <input className="form-control" type="password" name="passwordldap" onChange={this.onChangePasswordLdap} defaultValue={this.state.passwordLdap} required />
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                </div>

                {/*SAVE / CANCEL*/}
                <div className="form-group col-12 text-end">
                    <button type="submit" className="btn btn-primary">{this.state.resources.ButtonLabel_Save}</button>
                    <button className="btn btn-negative" onClick={this.onCancel}>{this.state.resources.ButtonLabel_Cancel}</button>
                </div>

            </form>;

        return (
            <div className="mt-2">
                <h1 className="mt-4">{this.state.resources.Title_Users}</h1>
                <ol className="breadcrumb mb-4">
                    <li className="breadcrumb-item active">{this.state.resources.Title_Users}</li>
                </ol>
                <div className="card">
                    <div className="card-body">
                        {this.state.loading ? helpers.showLoadingOverlay() : helpers.hideLoadingOverlay()}
                        {contents}
                    </div>
                </div>
            </div>
        );
    }

    async getResources() {
        const resourceVars = [];
        resourceVars.push("Title_Users");
        resourceVars.push("Label_UserLdap");
        resourceVars.push("Label_UserLdap");
        resourceVars.push("Label_PasswordLdap");
        resourceVars.push("Label_Success");
        resourceVars.push("Label_Error");
        resourceVars.push("ButtonLabel_Save");
        resourceVars.push("ButtonLabel_Cancel");
        resourceVars.push("ErrorMessage_RequiredField");
        resourceVars.push("ErrorMessage_UnknownError");
        resourceVars.push("SuccessMessage_DataSavedSuccessfully");

        const headers = {
            'Content-Type': 'application/json'
        };

        axios.post(window.config.REACT_APP_SECURITY_API_URL + '/languageresource/getbyvars/' + helpers.getUiLanguage(), resourceVars, {
            headers: headers
        })
            .then((response) => {
                const data = response.data.data;
                this.setState({ resources: data, loading: false });
            })
            .catch((error) => {
                console.log(error);
                this.setState({ loading: false });
            });
    }

    async populateData() {
        this.setState({
            loading: false
        });

        let authTokenSecurity = await helpers.getAuthorizationToken(process.env.REACT_APP_SECURITY_API_NAME);

        axios.get(window.config.REACT_APP_SECURITY_API_URL + '/user/getbyid/' + this.props.match.params.id,
            {
                'headers': { 'Authorization': authTokenSecurity }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({
                    id: data.id,
                    userLdap: data.user_ldap,
                    passwordLdap: data.password_ldap,
                    loading: false
                });
            })
            .catch(function (error) {
                console.log(error);
                this.setState({ loading: false });
            })  
    }
}
