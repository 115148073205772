import React, { Component, useMemo, useCallback} from 'react';
import axios from 'axios';
import helpers from '../../Helpers';
import $ from 'jquery';
import queryString from 'query-string';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { styled } from '@mui/material/styles';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Button from '@mui/material/Button';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { RemoveShoppingCart } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import startsWith from 'lodash.startswith';
import { textAlign } from '@material-ui/system';
// ES6 Modules or TypeScript

const Swal = require('sweetalert2')

export class PbxWorkspaceList extends Component {
    static displayName = PbxWorkspaceList.name;

    static message = '';

    constructor(props) {
        super(props);

        this.onChangeProduct = this.onChangeProduct.bind(this);
        this.onChangeState = this.onChangeState.bind(this);
        this.onChangePhone = this.onChangePhone.bind(this);
        this.onChangeName = this.onChangeName.bind(this);
        this.onChangeLanguageEsp = this.onChangeLanguageEsp.bind(this);
        this.onChangeLanguageEng = this.onChangeLanguageEng.bind(this);
        this.onChangeTreatmentQuestions = this.onChangeTreatmentQuestions.bind(this);
        this.goToClientWsp = this.goToClientWsp.bind(this);
        this.onChangeClosingReasonId = this.onChangeClosingReasonId.bind(this);
        this.onContactAgain = this.onContactAgain.bind(this);
        this.onChangeCommnets = this.onChangeCommnets.bind(this);
        this.SaveNoSale = this.SaveNoSale.bind(this);
        this.onChangeCreditCard = this.onChangeCreditCard.bind(this);
        this.onChangeGeneralComments = this.onChangeGeneralComments.bind(this);
        this.onClickBtnPrevSale = this.onClickBtnPrevSale.bind(this);
        this.onClickBtnTreatment = this.onClickBtnTreatment.bind(this);
        this.onClickBtnObjsScript = this.onClickBtnObjsScript.bind(this);
        this.getMapCourierCities = this.getMapCourierCities.bind(this);

        BasicModal= BasicModal.bind(this);
        WayPaysModal = WayPaysModal.bind(this);
        NoSale = NoSale.bind(this);

        this.getTokens = this.getTokens.bind(this);

        this.state = {
            id: 0,
            apiName: '',
            userId: 0,
            userFullName: '',
            enabled: true,
            loading: true,
            users: [],
            resources: [],
            viewMode: false,
            currentUserId: 0,
            params: queryString.parse(window.location.search),
            message: '',
            currentElement: null,
            steps: ['Inicio', 'Cliente', 'Pedido', 'Pago', 'Checkout'],
            activeStep: 0,
            products: [],
            productId: 0,
            productName: '',
            treatments: [],
            treatmentId: 0,
            treatmentName: '',
            productCountries: [],
            productCountriesKey: [],
            objetions: [],
            scriptSales: [],
            states: [],
            stateId: 0,
            stateName: '',
            tokenProducts: "9ycIkF9y84b9919zc7Mmeg==:vF1/yAwoeJP6gxxPKL3KqWWORql33HaFjd7sma2p4uimiNa3GI4TNMu7UrHp6/rD6KfRlUir64c=",
            tokenSecurity: "RwrTmBsHBrcjNRVG7UnGsQ==:X3w0NN1HJSBG+zbzLHcAX/KRDmGQtDkVitYM+2EDiMCVPHWOB8T86LprsHLuTUalXfdqjaM1RCw=",
            currentUser: null,
            closingReasons: [],
            closingReasonId: 0,
            treatmentQuestions: [],
            mapCourierCities: [],
            phone: 0,
            name: '',
            answersTreatmentQuestions: [],
            language: '',
            comments: '',
            contactAgain: null,
            hasCreditCard: null,
            campaingId: 0,
            phoneFormat: '',
            subareaId: null,
            generalComments: '',
            countryId: 0,
            currentWorkspaceId: null,
            scriptFounded: false,
            restaChararter: 0, 
            courierIdCampaing: null,
        };
    }

    componentDidMount() {
        document.querySelector('#divPrevSale').style.display = 'block';
        document.querySelector('#divTreatQuestion').style.display = 'none';
        document.querySelector('#divObjsScrip').style.display = 'none';
        document.querySelector('#lblGeneralComments').style.display = 'none';

        this.state.params = queryString.parse(window.location.search)
        this.getTokens();
        // this.populateData();
        this.state.campaingId = parseInt(this.state.params?.id_campania_occ) ?? 0;
        this.state.userId = parseInt(this.state.params?.id_agente) ?? 0;
        document.querySelector("#phoneError").style.display = 'none';
        document.querySelector("#productError").style.display = 'none';
        // document.querySelector("#mobilephoneError").style.display = 'none';
        document.querySelector("#nameError").style.display = 'none';
        document.querySelector("#stateError").style.display = 'none';
        document.querySelector("#treatmentError").style.display = 'none';
        document.querySelector("#creditRequired").style.display = 'none';

        document.querySelector("#errorBtnPreSale").style.display = 'none';
        document.querySelector("#errorBtnTreat").style.display = 'none';
        document.querySelector("#errorBtnObjScrip").style.display = 'none';

        this.setState({
            countryId: this.state.params.id_pais
        })
        
        document.querySelector('#btnPrevSale').classList.add('active');

        if(localStorage.getItem('DataWorkspace')){
            let wspData = JSON.parse(localStorage.getItem('DataWorkspace'));
            this.setState({
                name: wspData.state?.name,
                hasCreditCard: wspData.state?.hasCreditCard
            });
        }
        //console.log(this.state.courierIdCampaing)
    }

    async getTokens(){
        // let dataToken = {"api_tokens": [{
        //     "api_name": "products",
        //     "key": "9ycIkF9y84b9919zc7Mmeg==",
        //     "token": "vF1/yAwoeJP6gxxPKL3KqWWORql33HaFjd7sma2p4uimiNa3GI4TNB6rUGGmf9B0xtkBpFSZ4YM="
        // }]};
        // sessionStorage.setItem('userdata', JSON.stringify(dataToken))
        const headers = {
            'Content-Type': 'application/json',
        };
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/gettoken',
        {
            'headers': headers
        })
        .then(async response => {
            helpers.saveUserData(response.data.data);
            // console.log(response);
            let userData = helpers.getUserData();
            // console.log(userData);
            this.setState({
                tokenProducts: userData.key + ':' + userData.token//await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME)
            });

            const newHeaders = {
                'Content-Type': 'application/json',
                'Authorization': userData.key + ':' + userData.token
            };

            //Validate UserAgent
            axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/validateUserId/'+parseInt(this.state.params.id_agente),
            {
                'headers': newHeaders
            })
            .then(response => {
                if(response.data.data){
                    //Validate Did Campaign
                    axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/validateCampaignByDid/'+ this.state.params.id_campania,
                    {
                        'headers': newHeaders
                    })
                    .then(response => {
                        if(response.data.data){
                            //Validate Did
                            axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/validateDid/'+ this.state.params.id_campania,
                            {
                                'headers': newHeaders
                            })
                            .then(response => {
                                if(response.data.data){
                                    this.populateData();
                                }else{
                                    toast.error(this.state.language == 'language0' ? 'DID is not active' : 'DID inactivo', {
                                        position: "top-right",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                    });
                                }
                            })
                            .catch((err) => {
                                console.log(err);
                                toast.error(this.state.language == 'language0' ? 'An error ocurred' : 'Ocurrio un Error. Verifique los datos', {
                                    position: "top-right",
                                    autoClose: 3000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                });
                            });
                        }else{
                            toast.error(this.state.language == 'language0' ? 'Campaign is not active' : 'Campaña inactiva', {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        toast.error(this.state.language == 'language0' ? 'An error ocurred' : 'Ocurrio un Error. Verifique los datos', {
                            position: "top-right",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    });
                }else{
                    toast.error(this.state.language == 'language0' ? 'User is not active' : 'Usuario Inactivo', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
            .catch((err) => {
                console.log(err);
                toast.error(this.state.language == 'language0' ? 'An error ocurred' : 'Ocurrio un Error. Verifique los datos', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            })

        })
        .catch((error) => {
            console.log(error);
        })
        
    }

    goToClientWsp(e) {
        e.preventDefault(e);
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': this.state.tokenProducts//await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_URL)
        };
        let treatmentAnw = false;
        let phoneOK = false;
        if(this.state.phoneFormat.charAt(0) != "")
        {
            let phoneRegex = new RegExp(this.state.phoneFormat);
            if (phoneRegex.test(this.state.phone) == true) 
            {
                phoneOK = true;
                document.querySelector("#phoneError").style.display = 'none';
            }
            else
            {
                document.querySelector("#phoneError").style.display = 'block';
            }
        }

        let creditCardOK = true;
        if(this.state.hasCreditCard == 'true' || this.state.hasCreditCard == 'false'){
            creditCardOK = true
         }else{
           creditCardOK = false
         }
          let dataNew = [];
          let notData = [];
            for(let i = 0; i < this.state.answersTreatmentQuestions?.length; i++){
                this.state.treatmentQuestions.map(treat => {
                if(treat.treatment_id == this.state.treatmentId){
                    if(this.state.answersTreatmentQuestions[i].id != treat.id){
                        dataNew.push(treat);
                    }else{
                        notData.push(treat);
                    }
                }})
            }
        let hash = {};
        dataNew = dataNew.filter((current)=>{
            var exists = !hash[current.id];
            hash[current.id] = true;
            return exists;
          });
          if(notData.length > 0){
            notData.map(x => {
                dataNew.map(y => {
                    if(x.id == y.id){
                          dataNew = dataNew.filter(item => item.id != x.id);
                    }
                })
            });
          }
          this.state.answersTreatmentQuestions = this.state.answersTreatmentQuestions.concat(dataNew)

        if (this.state.name !== '' && this.state.phone > 0 && this.state.productId > 0 && this.state.stateId > 0 && phoneOK && creditCardOK) {

            const workspaceData = {
                id: this.state.currentElement?.id ?? this.state.currentWorkspaceId,
                country_id: parseInt(this.state.params.id_pais),
                id_agent: parseInt(this.state.params.id_agente),
                call_index: this.state.params.id_datos ?? "",
                call_id: this.state.params.id_llamada,
                did: this.state.params.id_campania ?? "",
                id_campania_occ : parseInt(this.state.params.id_campania_occ ?? "0"),
                pbx_crm: parseInt(this.state.params.id_crm ?? "0"),
                pbx_db: parseInt(this.state.params.id_db ?? "0"),
                active_step: parseInt(this.state.activeStep ?? "0"),
                general_comments: this.state.generalComments,
                client_phone: Number(this.state.phone),
                client_name: this.state.name,
                city_id: this.state.stateId,
                credit_card: this.state.hasCreditCard
            };

            this.state.answersTreatmentQuestions = this.state.answersTreatmentQuestions?.sort((a,b) => a.order < b.order ? -1 : +(a.order > b.order));
            this.state.products = [];
            this.state.productCountries = [];
            this.state.countryId = this.state.params.id_pais;
            this.state.states = [];
            this.state.objetions = [];
            this.state.scriptSales = [];
            let data = {
                state: this.state,
            }
            
            this.state.treatmentQuestions.map(item => {
                if (item.treatment_id == this.state.treatmentId) {
                    treatmentAnw = true;
                }
            });
            if (treatmentAnw) {
                if (this.state.answersTreatmentQuestions?.length > 0 || 
                    (JSON.parse(localStorage.getItem('DataWorkspace'))?.state.answersTreatmentQuestions?.length > 0 &&
                     JSON.parse(localStorage.getItem('DataWorkspace'))?.state.productId == this.state.productId)) {
                    if(data.state.answersTreatmentQuestions?.length < 1 || !data.state.answersTreatmentQuestions){
                        data.state.answersTreatmentQuestions = JSON.parse(localStorage.getItem('DataWorkspace'))?.state.answersTreatmentQuestions;
                    }
                    localStorage.removeItem('DataWorkspace');
                    // localStorage.clear();
                    localStorage.setItem('DataWorkspace', JSON.stringify(data));
                    if(this.state.answersTreatmentQuestions){
                        let noAswerQuestions = 0;
                        this.state.answersTreatmentQuestions.map(x => {
                            if(x.answer_type == "Obligatorio" && !x.answer){
                                noAswerQuestions++;
                            }
                        });
                        if(noAswerQuestions > 0){
                            document.querySelector("#treatmentError").style.display = 'block';
                            document.querySelector('#btnTreatment').classList.remove('btn-primary');
                            document.querySelector('#btnTreatment').classList.add('btn-danger');
                            document.querySelector("#errorBtnTreat").style.display = 'inline';
                        }else{
                            helpers.showLoadingOverlay();
                            axios.post(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/save', workspaceData, {
                                headers: headers
                            })
                            .then((response) => { 
                                helpers.hideLoadingOverlay();
                                window.location.href = "/client-workspace";
                            })
                            .catch((error) => {
                                helpers.hideLoadingOverlay();
                                console.log(error)
                            });
                        }
                    }else{
                        helpers.showLoadingOverlay();
                        axios.post(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/save', workspaceData, {
                            headers: headers
                        })
                        .then((response) => { 
                            helpers.hideLoadingOverlay();
                            window.location.href = "/client-workspace";
                        })
                        .catch((error) => {
                            helpers.hideLoadingOverlay();
                            console.log(error)
                        });
                    }
                } else {
                    document.querySelector("#treatmentError").style.display = 'block';
                    document.querySelector('#btnTreatment').classList.remove('btn-primary');
                    document.querySelector('#btnTreatment').classList.add('btn-danger');
                    document.querySelector("#errorBtnTreat").style.display = 'inline';
                }   
            } else {
                localStorage.removeItem('DataWorkspace');
                localStorage.setItem('DataWorkspace', JSON.stringify(data));
                helpers.showLoadingOverlay();
                    axios.post(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/save', workspaceData, {
                        headers: headers
                    })
                    .then((response) => { 
                        helpers.hideLoadingOverlay();
                        window.location.href = "/client-workspace";
                    })
                    .catch((error) => {
                        helpers.hideLoadingOverlay();
                        console.log(error)
                    });
            }
        } else {
            if (this.state.name == '') {
                document.querySelector("#nameError").style.display = 'block';
                document.querySelector('#btnPrevSale').classList.remove('btn-primary');
                document.querySelector('#btnPrevSale').classList.add('btn-danger');
                document.querySelector("#errorBtnPreSale").style.display = 'inline';
            } else if(this.state.phone < 1){
                document.querySelector("#phoneError").style.display = 'block';
                document.querySelector('#btnPrevSale').classList.remove('btn-primary');
                document.querySelector('#btnPrevSale').classList.add('btn-danger');
                document.querySelector("#errorBtnPreSale").style.display = 'inline';
            } else if(this.state.productId < 1){
                document.querySelector("#productError").style.display = 'block';
                document.querySelector('#btnPrevSale').classList.remove('btn-primary');
                document.querySelector('#btnPrevSale').classList.add('btn-danger');
                document.querySelector("#errorBtnPreSale").style.display = 'inline';
            } else if(this.state.stateId < 1){
                document.querySelector("#stateError").style.display = 'block';
                document.querySelector('#btnPrevSale').classList.remove('btn-primary');
                document.querySelector('#btnPrevSale').classList.add('btn-danger');
                document.querySelector("#errorBtnPreSale").style.display = 'inline';
            } else if (this.state.hasCreditCard == null) {
                document.querySelector("#creditRequired").style.display = 'block';
                document.querySelector('#btnPrevSale').classList.remove('btn-primary');
                document.querySelector('#btnPrevSale').classList.add('btn-danger');
                document.querySelector("#errorBtnPreSale").style.display = 'inline';
            } 
        }
        
        
    }

    onChangeState(e) {
        
        this.setState({
            stateId: e.target.value
        });
        this.state.stateId = e.target.value;
        // this.getMapCourierCities();
        let stateError = document.querySelector("#stateError");
        if (e.target.value < 1) {
            stateError.style.display = 'block';
        } else {
            stateError.style.display = 'none';
            document.querySelector('#btnPrevSale').classList.add('btn-primary');
            document.querySelector('#btnPrevSale').classList.remove('btn-danger');
            document.querySelector("#errorBtnPreSale").style.display = 'none';
        }
    }

    onChangePhone(e) {
        this.setState({
            phone: e
        });
        
        document.querySelector('#btnPrevSale').classList.add('btn-primary');
        document.querySelector('#btnPrevSale').classList.remove('btn-danger');
        document.querySelector("#errorBtnPreSale").style.display = 'none';
    }



    onChangeName(e) {
        
        this.setState({
            name: e.target.value
        });
        let nameError = document.querySelector("#nameError");

        if (e.target.value.charAt(0) == "") {
            nameError.style.display = 'block';
        } else {
            nameError.style.display = 'none';
            document.querySelector('#btnPrevSale').classList.add('btn-primary');
            document.querySelector('#btnPrevSale').classList.remove('btn-danger');
            document.querySelector("#errorBtnPreSale").style.display = 'none';
        }
    }

    onChangeLanguageEsp(e) {
        e.preventDefault();
        this.getResources('language1');
        this.state.steps[0] = 'Inicio'
        this.state.steps[1] = 'Cliente'
        this.state.steps[2] = 'Pedido'
        this.state.steps[3] = 'Pago'
    }
    onChangeLanguageEng(e) {
        e.preventDefault();
        this.getResources('language0');
        this.state.steps[0] = 'Start'
        this.state.steps[1] = 'Client'
        this.state.steps[2] = 'Order'
        this.state.steps[3] = 'Payment'
    }
    

    async onChangeProduct(e) {
        let values = e.target.value.split(';');
        let productError = document.querySelector("#productError");
        if (values[0] < 1) {
            productError.style.display = 'block';
        } else {
            productError.style.display = 'none';
            document.querySelector('#btnPrevSale').classList.add('btn-primary');
            document.querySelector('#btnPrevSale').classList.remove('btn-danger');
            document.querySelector("#errorBtnPreSale").style.display = 'none';
            
        }
        this.setState({
            productId: values[0],
            productName: values[1],
            treatmentId: values[2],
            treatmentName: values[3],
            productCountries: [],
            answersTreatmentQuestions: []
        });
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': this.state.tokenProducts
        };
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getproductbyid/' + values[0],
            {
                'headers': headers
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ productCountries: data, loading: false });
                // order alphabetically
                this.state.productCountries.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                localStorage.setItem('WspProductCountries', JSON.stringify(data));
                this.setState({
                    productCountries: this.state.productCountries.concat({ id: 0, country_id: 0 })
                });
            })
            .catch(function (error) {
                console.log(error);
            });
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/gettreatmentquestionsbytype/' + (this.state.countryId ?? 3 )+'/'+ values[2],
            {
                'headers': headers
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ 
                    treatmentQuestions: response.data.data,
                    loading: false 
                });
            })
            .catch(function (error) {
                console.log(error);
            });
            
            localStorage.removeItem('DataWorkspace');
    }

    onChangeGeneralComments(e) {
        this.setState({
            generalComments: e.target.value
        });
        let countChararter= e.target.value.length;
        let resta = 255 - countChararter;
        this.state.restaChararter = resta;

        if(!countChararter== 0){
        document.querySelector('#lblGeneralComments').style.display = 'block';
        }
        else{
            document.querySelector('#lblGeneralComments').style.display = 'none';
        }
    }

    onChangeTreatmentQuestions(e) {
        let item = {
            id: e.target.dataset.myid,
            question: e.target.name,
            answer: e.target.value,
            required: e.target.required,
            type: e.target.id,
            order: e.target.dataset.order
        }
        if (this.state.answersTreatmentQuestions.length < 1){
            this.state.answersTreatmentQuestions.push(item);
        }
        for (let i = 0; i < this.state.answersTreatmentQuestions.length; i++){
            if (this.state.answersTreatmentQuestions[i].question == item.question) {
                this.state.answersTreatmentQuestions.splice(i, 1)
                this.state.answersTreatmentQuestions.push(item);
            } else {
                if (i == (this.state.answersTreatmentQuestions.length - 1)) {
                    this.state.answersTreatmentQuestions.push(item);
                }
            }
        }
        document.querySelector("#treatmentError").style.display = 'none';
        document.querySelector('#btnTreatment').classList.add('btn-primary');
        document.querySelector('#btnTreatment').classList.remove('btn-danger');
        document.querySelector("#errorBtnTreat").style.display = 'none';
    }

    onChangeClosingReasonId(e) {
        this.setState({
            closingReasonId: e.target.value
        });
    }

    onContactAgain(e) {
        this.setState({
            contactAgain: e.target.value
        });
    }

    onChangeCommnets(e) {
        this.setState({
            comments: e.target.value
        });
        let countChararter= e.target.value.length;
        //console.log(countChararter)
        let resta = 100 - countChararter;
        //console.log(resta)
        this.state.remainComentaries = resta;
    }

    onChangeCreditCard(e) {
        this.setState({
            hasCreditCard: e.target.value
        });
        let creditRequired = document.querySelector("#creditRequired");
        // console.log(values)
        creditRequired.style.display = 'none';
        document.querySelector('#btnPrevSale').classList.add('btn-primary');
        document.querySelector('#btnPrevSale').classList.remove('btn-danger');
        document.querySelector("#errorBtnPreSale").style.display = 'none';
    }

    onClickBtnPrevSale(){
        document.querySelector('#btnPrevSale').classList.add('active');
        document.querySelector('#btnTreatment').classList.remove('active');
        document.querySelector('#btnObjsScript').classList.remove('active');

        document.querySelector('#divPrevSale').style.display = 'block';
        document.querySelector('#divTreatQuestion').style.display = 'none';
        document.querySelector('#divObjsScrip').style.display = 'none';
    }

    onClickBtnTreatment(){
        document.querySelector('#btnTreatment').classList.add('active');
        document.querySelector('#btnPrevSale').classList.remove('active');
        document.querySelector('#btnObjsScript').classList.remove('active');

        document.querySelector('#divPrevSale').style.display = 'none';
        document.querySelector('#divTreatQuestion').style.display = 'block';
        document.querySelector('#divObjsScrip').style.display = 'none';
    }

    onClickBtnObjsScript(){
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': this.state.tokenProducts//await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_URL)
        };
        if(!this.state.scriptFounded){ 
            axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getobjetions',
                {
                    'headers': headers
                })
                .then(response => {
                    const data = response.data.data;
                    this.setState({ objetions: data, loading: false });
                    // order alphabetically
                    this.state.objetions.sort((a,b) => a.title < b.title ? -1 : +(a.title > b.title))
                    // console.log(this.state.objetions);
                })
                .catch(function (error) {
                    console.log(error);
                });
            axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getscriptsales',
                {
                    'headers': headers
                })
                .then(response => {
                    const data = response.data.data;
                    this.setState({ scriptSales: data, loading: false, scriptFounded:true });
                    // order alphabetically
                    this.state.scriptSales.sort((a,b) => a.title < b.title ? -1 : +(a.title > b.title))
                })
                .catch(function (error) {
                    console.log(error);
                });
        }

        document.querySelector('#btnObjsScript').classList.add('active');
        document.querySelector('#btnTreatment').classList.remove('active');
        document.querySelector('#btnPrevSale').classList.remove('active');

        document.querySelector('#divPrevSale').style.display = 'none';
        document.querySelector('#divTreatQuestion').style.display = 'none';
        document.querySelector('#divObjsScrip').style.display = 'flex';
    }


    async SaveNoSale(e) {
        
        const data = {
            id: this.state.currentElement.id,
            country_id: parseInt(this.state.params.id_pais),
            id_agent: parseInt(this.state.params.id_agente),
            call_index: this.state.params.id_datos ?? "",
            call_id: this.state.params.id_llamada,
            did: this.state.params.id_campania ?? "",
            id_campania_occ : parseInt(this.state.params.id_campania_occ ?? "0"),
            pbx_crm: parseInt(this.state.params.id_crm ?? "0"),
            pbx_db: parseInt(this.state.params.id_db ?? "0"),
            active_step: parseInt(this.state.activeStep ?? "0"),
            closingreason_id: this.state.closingReasonId,
            contact_again: this.state.contactAgain,
            comments: this.state.comments,
            product_id: this.state.productId,
            treatment_id: this.state.treatmentId,
            client_phone: this.state.phone,
            client_name: this.state.name,
            city_id: this.state.stateId,
            credit_card: this.state.hasCreditCard
        };
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_URL)
        };

        for (let i = 0; i < this.state.answersTreatmentQuestions.length; i++){
            const auxiliarData = {
                pbxworkspace_id: this.state.currentElement?.id,
                question_id: this.state.answersTreatmentQuestions[i].id,
                question_name: this.state.answersTreatmentQuestions[i].question,
                answer: this.state.answersTreatmentQuestions[i].answer,
                enabled: true
            };
            axios.post(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/savequestionanswers', auxiliarData, {
                headers: headers
            })
            .then((response) => { 
                
            })
            .catch((error) => console.log(error));
        }
        
        
        helpers.showLoadingOverlay();
        axios.post(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/save', data, {
            headers: headers
        })
        .then((response) => { 
            setTimeout(() => window.location.href = "/no-sale-workspace", 3000)
            
        })
        .catch((error) => console.log(error));
    }
    
    async populateData()
    {
        helpers.showLoadingOverlay();
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': this.state.tokenProducts//await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_URL)
        };
        const dataFilter = {
            pbx_crm: this.state.params.id_crm, 
            pbx_db: this.state.params.id_db, 
            call_id: this.state.params.id_llamada, 
            call_index: this.state.params.id_datos, 
            country_id: this.state.params.id_pais, 
            did: this.state.params.id_campania, 
            id_agent: this.state.params.id_agente
        }
        axios.post(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getworkspacefilter', dataFilter, {
                headers: headers
            })
            .then((response) => {
                    const dataWsp = response.data.data;
                    this.setState({
                        currentElement: dataWsp,
                        currentWorkspaceId: dataWsp?.id
                    });
                    this.state.currentElement = dataWsp;
                    this.state.currentWorkspaceId = dataWsp?.id
                    axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getuserbypbxidid/' + this.state.params.id_agente,
                    {
                        'headers': headers
                    })
                    .then(response => {
                        const data = response.data.data;
                        //console.log(data)
                        this.setState({ language: data.language_resource, subareaId: data.sub_area_id,loading: false });
                        localStorage.setItem('WspLanguage', data.language_resource);
                        this.getResources(data.language_resource);
                        if (!localStorage.getItem('DataWorkspace')) {
                            if(dataWsp){
                                if (data.language_resource == 'language1') {
                                    this.state.steps[0] = 'Inicio'
                                    this.state.steps[1] = 'Cliente'
                                    this.state.steps[2] = 'Pedido'
                                    this.state.steps[3] = 'Pago'
                                    toast.success('Recuperando Datos', {
                                        position: "top-right",
                                        autoClose: 3000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                    });
                                    this.setState({
                                        message: 'Ya existe'
                                    });
                                } else {
                                    this.state.steps[0] = 'Start'
                                    this.state.steps[1] = 'Client'
                                    this.state.steps[2] = 'Order'
                                    this.state.steps[3] = 'Payment'
                                    toast.success('Loading Data', {
                                        position: "top-right",
                                        autoClose: 3000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                    });
                                    this.setState({
                                        message: 'Ya existe'
                                    });
                                }
                            }
                        }
                        //console.log('Llega aca?');
                        if (localStorage.getItem('DataWorkspace')) {
                            let data = JSON.parse(localStorage.getItem('DataWorkspace'));
                            this.setState({
                                name: data.state.name,
                                phone: data.state.phone,
                                productId: Number(data.state.productId),
                                productName: data.state.productName,
                                countryId: data.countryId,
                                stateId: data.state.stateId,
                                answersTreatmentQuestions: data.state.answersTreatmentQuestions,
                                treatmentId: data.state.treatmentId,
                                treatmentName: data.state.treatmentName,
                                generalComments: data.state.generalComments,
                                hasCreditCard: data.state.hasCreditCard
                            });
                            this.state.stateId = data.state.stateId;
                            axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/gettreatmentquestionsbytype/' + data.state.countryId+'/'+ data.state.treatmentId,
                            {
                                'headers': headers
                            })
                            .then(response => {
                                const data = response.data.data;
                                this.setState({ 
                                    treatmentQuestions: response.data.data,
                                    loading: false 
                                });
                            })
                            .catch(function (error) {
                                console.log(error);
                            });
                        }else{
                            if (this.state.params.id_pais > 1 && this.state.campaingId < 1) {
                                axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getcampaingbycountryid/' + this.state.params.id_pais,
                                {
                                    'headers': headers
                                })
                                .then(response => {
                                    //console.log(response.data.data)
                                    const data = response.data.data;
                                    // this.setState({
                                    //     campaingId: data.id,
                                    // });
                                    const headers = {
                                            'Content-Type': 'application/json',
                                            'Authorization': this.state.tokenProducts//await helpers.getAuthorizationToken(process.env.REACT_APP_SECURITY_API_NAME)
                                        };
                                    this.state.products.map(prod => {
                                        if (prod.id == data.product_id) {
                                            this.setState({
                                                productId: prod.id,
                                                productName: prod.name,
                                                treatmentId: prod.treatment_id,
                                                treatmentName: prod.treatment_name
                                            });
                                            axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getproductbyid/' + prod.id,
                                                {
                                                    'headers': headers
                                                })
                                                .then(response => {
                                                    const data = response.data.data;
                                                    this.setState({ productCountries: data, loading: false });
                                                    // order alphabetically
                                                    this.state.productCountries.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                                                    localStorage.setItem('WspProductCountries', JSON.stringify(data));
                                                    this.setState({
                                                        productCountries: this.state.productCountries.concat({ id: 0, country_id: 0 })
                                                    });
                                                })
                                                .catch(function (error) {
                                                    console.log(error);
                                                });
                                        }
                                    });
                                })
                                .catch(function (error) {
                                    console.log(error);
                                });
                                
                            }
                        }
                        
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
                            
                    if(this.state.params.id_pais > 0)
                        {
                            axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getcountrybyid/' + this.state.params.id_pais,
                            {
                                headers: headers
                            })
                            .then(response => {
                                const data = response.data.data;
                              //  console.log(data)
                                this.setState({
                                    phoneFormat: data.phone_format != null ? data.phone_format : '',
                                    //campaingId: data?.idcampaing_default
                                });
                            })
                            .catch(function (error) {
                                console.log(error);
                                this.setState({ loading: false });
                            })  

                        }
                    if(this.state.params.id_ani != "")
                    {
                        this.setState({
                            phone: this.state.params.id_ani?.replace(' ','')
                        });
                    }
                    if (!this.state.currentElement) {
                        if (this.state.params.id_agente > 0 && this.state.params.id_llamada > 0 && this.state.params.id_pais > 0) {
                            const data = {
                                country_id: parseInt(this.state.params.id_pais),
                                id_agent: parseInt(this.state.params.id_agente),
                                call_index: this.state.params.id_datos ?? "",
                                call_id: this.state.params.id_llamada,
                                did: this.state.params.id_campania ?? "",
                                id_campania_occ : parseInt(this.state.params.id_campania_occ ?? "0"),
                                pbx_crm: parseInt(this.state.params.id_crm ?? "0"),
                                pbx_db: parseInt(this.state.params.id_db ?? "0"),
                                active_step: parseInt(this.state.activeStep ?? "0"),
                                general_comments: this.state.generalComments ?? "",
                            };
                            
                            const headers = {
                                'Content-Type': 'application/json',
                               'Authorization': this.state.tokenProducts//await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_URL)
                            };
                    
                            axios.post(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/save', data, {
                                headers: headers
                            })
                                .then((response) => {
                                    this.setState({
                                        currentWorkspaceId: response.data.data,
                                        message: 'Creado',
                                    });
                                    
                                    localStorage.removeItem("DataWorkspace");
                                    this.state.productId = null;
                                    this.state.productName = "";
                                    this.state.name = "";
                                    this.state.stateId = "";
                                    this.state.stateName = "";
                                    this.state.hasCreditCard = null;
                                    this.state.generalComments = "";
                                    if(!this.state.params.id_ani)
                                    {
                                       this.state.phone = "";
                                    }

                                    axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getuserbypbxidid/' + this.state.params.id_agente,
                                    {
                                        'headers': headers
                                    })
                                    .then(response => {
                                        const data = response.data.data;
                                         //console.log(data);
                                        this.setState({ language: data.language_resource, subareaId: data.sub_area_id, loading: false });
                                        this.getResources(data.language_resource);
                                        if (this.state.language == 'language0') {

                                            this.state.steps[0] = 'Start';
                                            this.state.steps[1] = 'Client';
                                            this.state.steps[2] = 'Order';
                                            this.state.steps[3] = 'Payment';

                                            toast.success('Data Saved', {
                                                position: "top-right",
                                                autoClose: 3000,
                                                hideProgressBar: false,
                                                closeOnClick: true,
                                                pauseOnHover: true,
                                                draggable: true,
                                                progress: undefined,
                                            });
                                            
                                        } else {
                                            this.state.steps[0] = 'Inicio';
                                            this.state.steps[1] = 'Cliente';
                                            this.state.steps[2] = 'Pedido';
                                            this.state.steps[3] = 'Pago';

                                            toast.success('Información Guardada', {
                                                position: "top-right",
                                                autoClose: 3000,
                                                hideProgressBar: false,
                                                closeOnClick: true,
                                                pauseOnHover: true,
                                                draggable: true,
                                                progress: undefined,
                                            });
                                        }
                                        //console.log(this.state.params.id_campania);
                                        if (this.state.params.id_campania) { //&& this.state.campaingId < 1
                                            axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getcampaingbydid/' + this.state.params.id_campania.toString(),
                                            {
                                                'headers': headers
                                            })
                                            .then(response => {

                                                const data = response.data.data;
                                                //console.log(data);
                                                this.setState({
                                                    campaingId: data.id,
                                                    courierIdCampaing: data.courier_id,
                                                });
                                                //console.log(this.state.courierIdCampaing)
                                                const headers = {
                                                        'Content-Type': 'application/json',
                                                        'Authorization': this.state.tokenProducts// await helpers.getAuthorizationToken(process.env.REACT_APP_SECURITY_API_NAME)
                                                    };
                                                this.state.products.map(prod => {
                                                    if (prod.id == data.product_id) {
                                                        this.setState({
                                                            productId: prod.id,
                                                            productName: prod.name,
                                                            
                                                        });
                                                        // axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getproductcountrykeyfiltered/' + prod.id,
                                                        // {
                                                        //     'headers': headers
                                                        // })
                                                        // .then(response => {
                                                        //     const data = response.data.data;
                                                        //     this.setState({ productCountriesKey: data, loading: false });
                                                        //     // order alphabetically
                                                        //     this.state.productCountriesKey.sort((a,b) => a.description < b.description ? -1 : +(a.description > b.description))
                                                        //     // console.log(this.state.productCountriesKey);
                                                        // })
                                                        // .catch(function (error) {
                                                        //     console.log(error);
                                                        // });
                                                        // console.log(this.state.treatmentId);
                                                        if (this.state.treatmentId <= 1)  {
                                                            this.setState({
                                                                treatmentId: prod.treatment_id,
                                                                treatmentName: prod.treatment_name
                                                            });
                                                        }
                                                        // localStorage.removeItem('DataWorkspace');
                                                        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getproductbyid/' + prod.id,
                                                            {
                                                                'headers': headers
                                                            })
                                                            .then(response => {
                                                                const data = response.data.data;
                                                                this.setState({ productCountries: data, loading: false });
                                                                // order alphabetically
                                                                this.state.productCountries.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                                                                localStorage.setItem('WspProductCountries', JSON.stringify(data));
                                                                this.setState({
                                                                    productCountries: this.state.productCountries.concat({ id: 0, country_id: 0 })
                                                                });
                                                            })
                                                            .catch(function (error) {
                                                                console.log(error);
                                                            });
                                                    }
                                                });
                                            })
                                            .catch(function (error) {
                                                console.log(error);
                                            });
                                        } else if (this.state.params.id_campania_occ && this.state.campaingId < 1) {
                                            axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getcampaingbyid/' + this.state.params.id_campania_occ,
                                            {
                                                'headers': headers
                                            })
                                            .then(response => {
                                                const data = response.data.data;
                                                 //console.log(data);
                                                this.setState({
                                                    campaingId: data.id,
                                                });
                                                const headers = {
                                                        'Content-Type': 'application/json',
                                                        'Authorization': this.state.tokenProducts//await helpers.getAuthorizationToken(process.env.REACT_APP_SECURITY_API_NAME)
                                                    };
                                                this.state.products.map(prod => {
                                                    if (prod.id == data.product_id) {
                                                        this.setState({
                                                            productId: prod.id,
                                                            productName: prod.name,
                                                        });
                                                        if (this.state.treatmentId >= 1)  {
                                                            this.setState({
                                                                treatmentId: prod.treatment_id,
                                                                treatmentName: prod.treatment_name
                                                            });
                                                        }
                                                        // axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getproductcountrykeyfiltered/' + prod.id,
                                                        // {
                                                        //     'headers': headers
                                                        // })
                                                        // .then(response => {
                                                        //     const data = response.data.data;
                                                        //     this.setState({ productCountriesKey: data, loading: false });
                                                        //     // order alphabetically
                                                        //     this.state.productCountriesKey.sort((a,b) => a.description < b.description ? -1 : +(a.description > b.description))
                                                        //     // console.log(this.state.productCountriesKey);
                                                        // })
                                                        // .catch(function (error) {
                                                        //     console.log(error);
                                                        // });
                                                        // localStorage.removeItem('DataWorkspace');
                                                        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getproductbyid/' + prod.id,
                                                            {
                                                                'headers': headers
                                                            })
                                                            .then(response => {
                                                                const data = response.data.data;
                                                                this.setState({ productCountries: data, loading: false });
                                                                // order alphabetically
                                                                this.state.productCountries.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                                                                localStorage.setItem('WspProductCountries', JSON.stringify(data));
                                                                this.setState({
                                                                    productCountries: this.state.productCountries.concat({ id: 0, country_id: 0 })
                                                                });
                                                            })
                                                            .catch(function (error) {
                                                                console.log(error);
                                                            });
                                                    }
                                                });
                                            })
                                            .catch(function (error) {
                                                console.log(error);
                                            });
                                        } else {
                                            if (this.state.params.id_pais && this.state.campaingId < 1) {
                                                axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getcampaingbycountryid/' + this.state.params.id_pais,
                                                {
                                                    'headers': headers
                                                })
                                                .then(response => {
                                                    const data = response.data.data;
                                                     //console.log(data);
                                                    // this.setState({
                                                    //     campaingId: data.id,
                                                    // });
                                                    const headers = {
                                                        'Content-Type': 'application/json',
                                                        'Authorization': this.state.tokenProducts//await helpers.getAuthorizationToken(process.env.REACT_APP_SECURITY_API_NAME)
                                                    };
                                                    this.state.products.map(prod => {
                                                        if (prod.id == data.product_id) {
                                                            this.setState({
                                                                productId: prod.id,
                                                                productName: prod.name,
                                                                treatmentId: prod.treatment_id,
                                                                treatmentName: prod.treatment_name
                                                            });
                                                            // localStorage.removeItem('DataWorkspace');
                                                            // axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getproductcountrykeyfiltered/' + prod.id,
                                                            // {
                                                            //     'headers': headers
                                                            // })
                                                            // .then(response => {
                                                            //     const data = response.data.data;
                                                            //     this.setState({ productCountriesKey: data, loading: false });
                                                            //     // order alphabetically
                                                            //     this.state.productCountriesKey.sort((a,b) => a.description < b.description ? -1 : +(a.description > b.description))
                                                            // })
                                                            // .catch(function (error) {
                                                            //     console.log(error);
                                                            // });
                                                            axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getproductbyid/' + prod.id,
                                                                {
                                                                    'headers': headers
                                                                })
                                                                .then(response => {
                                                                    const data = response.data.data;
                                                                    this.setState({ productCountries: data, loading: false });
                                                                    // order alphabetically
                                                                    this.state.productCountries.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                                                                    localStorage.setItem('WspProductCountries', JSON.stringify(data));
                                                                    this.setState({
                                                                        productCountries: this.state.productCountries.concat({ id: 0, country_id: 0 })
                                                                    });
                                                                })
                                                                .catch(function (error) {
                                                                    console.log(error);
                                                                });
                                                        }
                                                    });
                                                })
                                                .catch(function (error) {
                                                    console.log(error);
                                                });
                                                
                                            }
                                        }
                                        
                                    })
                                    .catch(function (error) {
                                        console.log(error);
                                    });
                                    localStorage.removeItem("DataClientWsp");
                                    localStorage.removeItem("DataOrderWsp");
                                    localStorage.removeItem("DataPaymentWsp");
                                    localStorage.removeItem("DataCheckOutWsp");
                                    helpers.hideLoadingOverlay();
                                })
                                .catch((error) => {
                                    console.log(error);
                                    helpers.hideLoadingOverlay();
                                    this.message = this.state.resources.ErrorMessage_UnknownError;
                                    this.setState({ loading: false });
                                    Swal.fire({
                                        icon: 'error',
                                        title: 'Error',
                                        text: 'Faltan Parametros',
                                    })
                                });
                        } else {
                            helpers.hideLoadingOverlay();
                            this.message = this.state.resources.ErrorMessage_UnknownError;
                            this.setState({ loading: false });
                            Swal.fire({
                                icon: 'error',
                                title: 'Error',
                                text: 'Faltan Parametros',
                            })
                        }
                    } else {
                        helpers.hideLoadingOverlay();
                        this.message = this.state.resources.ErrorMessage_UnknownError;
                        this.setState({ loading: false });
                        
                    }
                    
            })
            .catch((error) => {
                console.log(error);
                helpers.hideLoadingOverlay();
                this.message = this.state.resources.ErrorMessage_UnknownError;
                this.setState({ loading: false });
            });
        
            axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getligthproductsbyAgent/' + this.state.userId, {

                headers: headers
            })
            .then(response => {
                const dataProc = response.data.data;
                //console.log(dataProc)
                this.setState({ products: dataProc, loading: false });
                // order alphabetically
                this.state.products.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                if (this.state.params.id_campania) {// && this.state.campaingId < 1
                    axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getcampaingbydid/' + this.state.params.id_campania.toString(),
                    {
                        'headers': headers
                    })
                    .then(response => {
                        const data = response.data.data;
                        // console.log(data);
                        this.setState({
                            campaingId: data?.id,
                            courierIdCampaing:  data.courier_id,
                        });
                        //console.log(this.state.courierIdCampaing);
                        this.state.products.map(prod => {
                            const headers = {
                                'Content-Type': 'application/json',
                                'Authorization': this.state.tokenProducts//await helpers.getAuthorizationToken(process.env.REACT_APP_SECURITY_API_NAME)
                            };
                            if (prod.id == data.product_id) {
                                this.setState({
                                    productId: prod.id,
                                    productName: prod.name,
                                    
                                });
                                if (this.state.treatmentId >= 1)  {
                                    this.setState({
                                        treatmentId: prod.treatment_id,
                                        treatmentName: prod.treatment_name
                                    });
                                }
                                
                                axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getproductbyid/' + prod.id,
                                    {
                                        'headers': headers
                                    })
                                    .then(response => {
                                        const data = response.data.data;
                                        this.setState({ productCountries: data, loading: false });
                                        // order alphabetically
                                        this.state.productCountries.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                                        localStorage.setItem('WspProductCountries', JSON.stringify(data));
                                        this.setState({
                                            productCountries: this.state.productCountries.concat({ id: 0, country_id: 0 })
                                        });
                                    })
                                    .catch(function (error) {
                                        console.log(error);
                                    });

                                axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/gettreatmentquestionsbytype/' + (this.state.countryId ?? 3 )+'/'+ prod.treatment_id,
                                    {
                                        'headers': headers
                                    })
                                    .then(response => {
                                        const data = response.data.data;
                                        this.setState({ 
                                            treatmentQuestions: response.data.data,
                                            loading: false 
                                        });
                                        this.state.treatmentQuestions = response.data.data;
                                    })
                                    .catch(function (error) {
                                        console.log(error);
                                    });
                            }
                        });
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
                }
            })
            .catch(function (error) {
                console.log(error);
            });
        
        if (this.state.params.id_pais) {
            axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getstates/'+ this.state.params.id_pais,
            {
                'headers': headers
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ states: data, loading: false });
                // order alphabetically
                this.state.states.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
            })
            .catch(function (error) {
                console.log(error);
            });
            // axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/gettreatmentquestionsbycountryid/' + this.state.params.id_pais,
            //     {
            //         'headers': headers
            //     })
            //     .then(response => { 
            //         this.setState({
            //             treatmentQuestions: this.state.treatmentQuestions.concat(response.data.data),
            //             // treatmentId: response.data.data.treatment_id
            //         });
            //     })
            //     .catch(function (error) {
            //         console.log(error);
            //     });
                
        } 
        if (this.state.params.id_campania && this.state.campaingId < 1) {
            axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getcampaingbydid/' + this.state.params.id_campania.toString(),
            {
                'headers': headers
            })
            .then(response => {
                const data = response.data.data;
                // console.log(data);
                this.setState({
                    campaingId: data.id,
                    courierIdCampaing:  data.courier_id,
                });
                //console.log(this.state.courierIdCampaing);
                if(!data){
                    if (this.state.params.id_pais && this.state.campaingId < 1) {
                        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getcampaingbycountryid/' + this.state.params.id_pais,
                        {
                            'headers': headers
                        })
                        .then(response => {
                            const data = response.data.data;
                            // console.log(data);
                            // this.setState({
                            //     campaingId: data.id,
                            // });
                            const headers = {
                                    'Content-Type': 'application/json',
                                    'Authorization': this.state.tokenProducts//await helpers.getAuthorizationToken(process.env.REACT_APP_SECURITY_API_NAME)
                                };
                            this.state.products.map(prod => {
                                if (prod.id == data.product_id) {
                                    this.setState({
                                        productId: prod.id,
                                        productName: prod.name,
                                        treatmentId: prod.treatment_id,
                                        treatmentName: prod.treatment_name
                                    });
                                    // axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getproductcountrykeyfiltered/' + prod.id,
                                    // {
                                    //     'headers': headers
                                    // })
                                    // .then(response => {
                                    //     const data = response.data.data;
                                    //     this.setState({ productCountriesKey: data, loading: false });
                                    //     // order alphabetically
                                    //     this.state.productCountriesKey.sort((a,b) => a.description < b.description ? -1 : +(a.description > b.description))
                                    //     // console.log(this.state.productCountriesKey);
                                    // })
                                    // .catch(function (error) {
                                    //     console.log(error);
                                    // });
                                    axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getproductbyid/' + prod.id,
                                        {
                                            'headers': headers
                                        })
                                        .then(response => {
                                            const data = response.data.data;
                                            this.setState({ productCountries: data, loading: false });
                                            // order alphabetically
                                            this.state.productCountries.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                                            localStorage.setItem('WspProductCountries', JSON.stringify(data));
                                            this.setState({
                                                productCountries: this.state.productCountries.concat({ id: 0, country_id: 0 })
                                            });
                                        })
                                        .catch(function (error) {
                                            console.log(error);
                                        });
                                }
                            });
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                        
                    }
                }
                this.state.products.map(prod => {
                    if (prod.id == data.product_id) {
                        this.setState({
                            productId: prod.id,
                            productName: prod.name,
                            
                        });
                        if (this.state.treatmentId >= 1)  {
                            this.setState({
                                treatmentId: prod.treatment_id,
                                treatmentName: prod.treatment_name
                            });
                        }
                        const headers = {
                                'Content-Type': 'application/json',
                                'Authorization': this.state.tokenProducts//await helpers.getAuthorizationToken(process.env.REACT_APP_SECURITY_API_NAME)
                            };
                        // axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getproductcountrykeyfiltered/' + prod.id,
                        // {
                        //     'headers': headers
                        // })
                        // .then(response => {
                        //     const data = response.data.data;
                        //     this.setState({ productCountriesKey: data, loading: false });
                        //     // order alphabetically
                        //     this.state.productCountriesKey.sort((a,b) => a.description < b.description ? -1 : +(a.description > b.description))
                        //     // console.log(this.state.productCountriesKey);
                        // })
                        // .catch(function (error) {
                        //     console.log(error);
                        // });
                        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getproductbyid/' + prod.id,
                            {
                                'headers': headers
                            })
                            .then(response => {
                                const data = response.data.data;
                                this.setState({ productCountries: data, loading: false });
                                // order alphabetically
                                this.state.productCountries.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                                localStorage.setItem('WspProductCountries', JSON.stringify(data));
                                this.setState({
                                    productCountries: this.state.productCountries.concat({ id: 0, country_id: 0 })
                                });
                            })
                            .catch(function (error) {
                                console.log(error);
                            });
                    }
                });
            })
            .catch(function (error) {
                console.log(error);
            });
        } else if (this.state.params.id_campania_occ) {
            axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getcampaingbyid/' + this.state.params.id_campania_occ,
            {
                'headers': headers
            })
            .then(response => {
                const data = response.data.data;
                // console.log(data);
                this.setState({
                    campaingId: data.id,
                });
                const headers = {
                        'Content-Type': 'application/json',
                        'Authorization': this.state.tokenProducts//await helpers.getAuthorizationToken(process.env.REACT_APP_SECURITY_API_NAME)
                    };
                this.state.products.map(prod => {
                    if (prod.id == data.product_id) {
                        this.setState({
                            productId: prod.id,
                            productName: prod.name,
                        });
                        // axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getproductcountrykeyfiltered/' + prod.id,
                        // {
                        //     'headers': headers
                        // })
                        // .then(response => {
                        //     const data = response.data.data;
                        //     this.setState({ productCountriesKey: data, loading: false });
                        //     // order alphabetically
                        //     this.state.productCountriesKey.sort((a,b) => a.description < b.description ? -1 : +(a.description > b.description))
                        //     // console.log(this.state.productCountriesKey);
                        // })
                        // .catch(function (error) {
                        //     console.log(error);
                        // });
                        if (this.state.treatmentId >= 1)  {
                            this.setState({
                                treatmentId: prod.treatment_id,
                                treatmentName: prod.treatment_name
                            });
                        }
                        // localStorage.removeItem('DataWorkspace');
                        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getproductbyid/' + prod.id,
                            {
                                'headers': headers
                            })
                            .then(response => {
                                const data = response.data.data;
                                this.setState({ productCountries: data, loading: false });
                                // order alphabetically
                                this.state.productCountries.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                                localStorage.setItem('WspProductCountries', JSON.stringify(data));
                                this.setState({
                                    productCountries: this.state.productCountries.concat({ id: 0, country_id: 0 })
                                });
                            })
                            .catch(function (error) {
                                console.log(error);
                            });
                    }
                });
            })
            .catch(function (error) {
                console.log(error);
            });
        } else {
            if (this.state.params.id_pais) {
                axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getcampaingbycountryid/' + this.state.params.id_pais,
                {
                    'headers': headers
                })
                .then(response => {
                    const data = response.data.data;
                     //console.log(data);
                    // this.setState({
                    //     campaingId: data.id,
                    // });
                    this.state.products.map(prod => {
                        if (prod.id == data.product_id) {
                            this.setState({
                                productId: prod.id,
                                productName: prod.name,
                                treatmentId: prod.treatment_id,
                                treatmentName: prod.treatment_name
                            });
                            // localStorage.removeItem('DataWorkspace');
                            // axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getproductcountrykeyfiltered/' + prod.id,
                            // {
                            //     'headers': headers
                            // })
                            // .then(response => {
                            //     const data = response.data.data;
                            //     this.setState({ productCountriesKey: data, loading: false });
                            //     // order alphabetically
                            //     this.state.productCountriesKey.sort((a,b) => a.description < b.description ? -1 : +(a.description > b.description))
                            //     // console.log(this.state.productCountriesKey);
                            // })
                            // .catch(function (error) {
                            //     console.log(error);
                            // });
                            const headers = {
                                    'Content-Type': 'application/json',
                                    'Authorization': this.state.tokenProducts//await helpers.getAuthorizationToken(process.env.REACT_APP_SECURITY_API_NAME)
                                };
                            axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getproductbyid/' + prod.id,
                                {
                                    'headers': headers
                                })
                                .then(response => {
                                    const data = response.data.data;
                                    this.setState({ productCountries: data, loading: false });
                                    // order alphabetically
                                    this.state.productCountries.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                                    localStorage.setItem('WspProductCountries', JSON.stringify(data));
                                    this.setState({
                                        productCountries: this.state.productCountries.concat({ id: 0, country_id: 0 })
                                    });
                                })
                                .catch(function (error) {
                                    console.log(error);
                                });
                        }
                    });
                })
                .catch(function (error) {
                    console.log(error);
                });
                
            }
        }
        
    }

    
    async getMapCourierCities() {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': this.state.tokenProducts//await helpers.getAuthorizationToken(process.env.REACT_APP_SECURITY_API_NAME)
        };
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + `/PbxWorkspace/getmapcouriercitybystatecom/${this.state.stateId}`,
        {
            'headers': headers
        })
        .then(response => {
            const data = response.data.data;
            //console.log(data)
            this.setState({ mapCourierCities: data, loading: false });
            // order alphabetically
            this.state.mapCourierCities.sort((a,b) => a.state_name < b.state_name ? -1 : +(a.state_name > b.state_name))
            // console.log(this.state.mapCourierCities);
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    getResources(lang) {
        const resourceVars = [];
        resourceVars.push("Label_ID");
        resourceVars.push("Label_Name");
        resourceVars.push("Label_Description");
        resourceVars.push("Label_Actions");
        resourceVars.push("Label_Status");
        resourceVars.push("Label_Enabled");
        resourceVars.push("Label_Disabled");
        resourceVars.push("Label_Country");
        resourceVars.push("Title_WayPay");
        resourceVars.push("Label_PayMethod");
        resourceVars.push("NavItem_Countries");
        resourceVars.push("NavItem_SubAreas");
        resourceVars.push("Label_Products");
        resourceVars.push("Label_SelectOption");
        resourceVars.push("WorkspaceDataError");
        resourceVars.push("WorkspaceDataSaved");
        resourceVars.push("WorkspaceLoadingData");
        resourceVars.push("Workspace_Start");
        resourceVars.push("Workspace_Client");
        resourceVars.push("Workspace_Order");
        resourceVars.push("Workspace_Payment");
        resourceVars.push("Label_Phone");
        resourceVars.push("Label_Name");
        resourceVars.push("Title_Treatments");
        resourceVars.push("Label_Presentation");
        resourceVars.push("Label_Price");
        resourceVars.push("Title_Objetions");
        resourceVars.push("Title_ScriptSales");
        resourceVars.push("Label_TreatmentQuestions");
        resourceVars.push("Label_GeneralQuestions");
        resourceVars.push("Label_HasCreditCard");
        resourceVars.push("Title_WayPay");
        resourceVars.push("Label_SelectOption");
        resourceVars.push("Label_Yes");
        resourceVars.push("Label_LookPrices");
        resourceVars.push("Label_NoSale");
        resourceVars.push("Label_NoSaleReason");
        resourceVars.push("Label_ContactAgain");
        resourceVars.push("Label_Comments");
        resourceVars.push("ButtonLabel_Save");
        resourceVars.push("Workspace_Next");
        resourceVars.push("Label_PayMethod");
        resourceVars.push("Label_DeliveryTime");
        resourceVars.push("Label_Limit");
        resourceVars.push("Label_RouteType");
        resourceVars.push("Title_Courier");
        resourceVars.push("Label_Country");
        resourceVars.push("Title_City");
        resourceVars.push("Title_State");
        resourceVars.push("Label_RoutesDestination");
        resourceVars.push("Label_All");
        resourceVars.push("Label_Refresh");
        resourceVars.push("Label_ProcessHasFinalized");
        resourceVars.push("ErrorMessage_InvalidPhoneFormat");
        resourceVars.push("Label_TimesDelivery");
        resourceVars.push("Label_Chararter");
        resourceVars.push("Label_Remain");
        resourceVars.push("Label_Location");
        resourceVars.push("Label_CityCom");
        resourceVars.push("Title_Limit");
        let myLang = lang ? lang : 'language0';

        const headers = {
            'Content-Group': 'application/json'
        };

        axios.post(window.config.REACT_APP_SECURITY_API_URL + '/languageresource/getbyvars/' + myLang, resourceVars, {
            headers: headers
        })
            .then((response) => {
                const data = response.data.data;
                this.setState({ resources: data });
            })
            .catch((error) => {
                console.log(error);
            });
    }

    render()
    {
        return (
            <>
                <div className="container">
                    <ToastContainer></ToastContainer>
                    <div className="row" >
                        <div className="p-2 my-2">
                            <Box sx={{ width: '100%' }}>
                                <Stepper activeStep={this.state.activeStep} alternativeLabel>
                                {this.state.steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                                ))}
                            </Stepper>
                            </Box>
                        </div>                        
                    </div>
                    <div className='row m-0'>
                        <div style={{display: 'flex'}}>
                            <button className="p-2 btn-primary" style={{border: 'none', fontSize: '0.9rem', marginRight: '2px'}} id="btnPrevSale" onClick={this.onClickBtnPrevSale}>
                                {this.state.language == 'language0' ? 'Pre-Sale' : 'Pre-Venta'} <span id="errorBtnPreSale">(!)</span>
                            </button>
                            <button className="p-2 btn-primary" style={{border: 'none', fontSize: '0.9rem', marginRight: '2px'}} id="btnTreatment" onClick={this.onClickBtnTreatment}>
                                {this.state.language == 'language0' ? 'Treatment Questions' : 'Preguntas de Tratamiento'} <span id="errorBtnTreat">(!)</span>
                            </button>
                            <button className="p-2 btn-primary" style={{border: 'none', fontSize: '0.9rem', marginRight: '2px'}} id="btnObjsScript" onClick={this.onClickBtnObjsScript}>
                                {this.state.language == 'language0' ? 'Objetions/Script Sales' : 'Objeciones/Script Ventas'} <span id="errorBtnObjScrip">(!)</span>
                            </button>
                        </div>
                    </div>
                    <div className="row m-0 p-1" id="divPrevSale" style={{border: '2px solid rgb(51 99 205)',borderRadius: '5px', height: '450px'}}>
                        <div className="row m-0">
                            <div className="col-md-6 ">
                                <div className="dis-flex"><label className="mt-2 form-label">{this.state.resources.Label_Products}</label> <p className="mt-2 ml-2" style={{color: 'red'}} id="productError">{this.state.language == 'language0' ? ' Product Required' : ' El Producto es Obligatorio'}</p></div>
                                <div className="dis-flex">
                                    <select className="form-control col-md-6 mr-1" data-val="true" name="productId" defaultValue={this.state.productId} onChange={this.onChangeProduct}>
                                    <option value="">{this.state.resources.Label_SelectOption}</option>
                                        {this.state.products.map(prod =>
                                            <option key={prod.id} selected={this.state.productId == prod.id} value={prod.id + ';' + prod.name + ';' + prod.treatment_id + ';' + prod.treatment_name}>
                                                {prod.name}
                                            </option>
                                        )}
                                    </select>
                                    
                                    <div className="dis-flex">
                                        <input className="form-control mx-1" disabled='true' type="text" name="treatment" value={this.state.treatmentName} placeholder={this.state.resources.Title_Treatments}/>
                                    </div>
                                    <BasicModal data={this.state}/>
                                </div>
                                <div className="row text-center">
                                
                                </div>
                            </div>
                            <div className="col-md-6">
                                <label className="mt-2 form-label">{this.state.resources.Workspace_Client}</label>
                                <div className="dis-flex">
                                    <div className="mx-1">
                                        <PhoneInput
                                            country={'cl'}
                                            value={this.state.phone?.toString()}
                                            onChange={this.onChangePhone}
                                            masks={{ cl: '(.) ....-....' }}
                                            inputStyle={{ width: '100%' }}
                                            onlyCountries={['cl', 'ar', 'mx', 'uk']}
                                            isValid={(value, country) => { 
                                                if (startsWith(value, country.dialCode) || startsWith(country.dialCode, value)) {
                                                    if (document.querySelector('#phoneError')) {
                                                        document.querySelector('#phoneError').style.display = 'none';
                                                    }
                                                    return true;
                                                } else {
                                                    if (document.querySelector('#phoneError')) {
                                                        document.querySelector('#phoneError').style.display = 'block';
                                                    }
                                                    return false;
                                                }
                                            }}
                                        />                                    
                                        <div style={{ color: 'red' }} className="p-1 pl-2" id="phoneError">{this.state.resources.ErrorMessage_InvalidPhoneFormat}</div>
                                    </div>
                                    <div className="mx-1">
                                        <input className="form-control mx-1" type="text" maxLength="100" name="name" placeholder={this.state.resources.Label_Name} onChange={this.onChangeName} defaultValue={this.state.name}/>
                                        <div style={{color: 'red'}} className="p-1 pl-2" id="nameError">{this.state.language == 'language0' ? ' Client Name Required' : ' El Nombre de Cliente es Obligatorio'}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="col-md-12 mx-3">
                                <label className="m-1 form-label">{this.state.resources.Label_Comments}</label>
                                <textarea className="form-control" name="generalComments" maxLength="255" onChange={this.onChangeGeneralComments} value={this.state.generalComments} ></textarea>
                            <div className="row m-0">
                                <div className="col-md-6">
                                </div>
                                <div className="col-md-6" style={{textAlign:'right' }} >
                                    <label id="lblGeneralComments" >{this.state.resources.Label_Remain} {this.state.restaChararter} {this.state.resources.Label_Chararter} </label> 
                                </div>
                            </div>
                            </div>
                        </div>

                        <div className="card col-md-6 mx-4 p-0 ml-2">
                                <div className="card-header">
                                    {this.state.resources.Label_GeneralQuestions}
                                </div>
                                <div className="card-body dis-flex" style={{height: '150px'}}>
                                    <div className="col-6 pt-0">
                                        <label className="form-label">{this.state.resources.Label_Location}</label>
                                        <select className="form-control" data-val="true" name="stateId" value={this.state.stateId} onChange={this.onChangeState}> {/**  required */}
                                                <option value="">{this.state.resources.Label_SelectOption}</option>
                                                {this.state.states.map(state =>
                                                    // state.country_id == this.state.params.id_pais &&
                                                    <option key={state.id} value={state.id}>{state.name}</option>
                                                )} 
                                        </select>
                                        <div style={{color: 'red'}} className="p-1 pl-2" id="stateError">{this.state.language == 'language0' ? ' State Required' : ' El Estado es Obligatorio'}</div> 
                                                                                     
                                        </div>
                                        <div className="col-6 pt-4 dis-flex">
                                            <WayPaysModal data={this.state}/>
                                            <div>
                                                <label>{this.state.resources.Label_HasCreditCard}</label>
                                                <div className="form-check col-md-6 "  style={{display: 'flex' , justifyContent:'space-between'}}>
                                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="Yes" value="true" onChange={this.onChangeCreditCard} checked={this.state.hasCreditCard}/>
                                                    <label className="form-check-label pr-3" for="Yes">{this.state.resources.Label_Yes}</label> 
                                                    <div className="form-check pl-3">
                                                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="No" value="false" onChange={this.onChangeCreditCard} checked={this.state.hasCreditCard == 'false'}/>
                                                        <label className="form-check-label" for="No">No</label>
                                                    </div>                                                                     
                                                </div>     
                                                <div style={{color: 'red'}} class="p-1 pl-2" id="creditRequired">{this.state.resources.Label_HasCreditCard}</div> 
                                            </div>
                                        </div>
                                       </div>                                                                             
                             </div>
                    </div>
                    
                    <div className="row m-0 p-2" id="divTreatQuestion"  style={{border: '2px solid rgb(51 99 205)',borderRadius: '5px', height: '450px'}}>
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header dis-flex">
                                    {this.state.resources.Label_TreatmentQuestions}
                                    <div style={{color: 'red'}} className="p-1 pl-2"  id="treatmentError">{this.state.language == 'language0' ? ' Answer the Treatment Questions' : ' Responda la Preguntas de Tratamiento'}</div>
                                    {/* <span className='ml-3' id="treatmentQuestionRequired" style={{color: 'red'}}>{this.state.language == 'language0' ? 'Required Field' : 'Campo Obligatorio'}</span> */}
                                </div>
                                <div className="card-body" style={{ height: '200px', overflowY: 'auto' }}>
                                     {JSON.parse(localStorage.getItem('DataWorkspace'))?.state?.productId == this.state.productId &&
                                        <div className="row">
                                           
                                            {JSON.parse(localStorage.getItem('DataWorkspace'))?.state?.answersTreatmentQuestions?.map(question => (
                                                <div className="col-md-6 mb-2">
                                                    <div className="row">
                                                        <div className="col-md-5">
                                                            <p style={{fontSize: '1rem'}}>{question.question}</p>
                                                        </div>
                                                        <div className="col-md-7">
                                                            {question.type == 'N' || question.question_type == 'N' ? 
                                                                <input type="number" className={"form-control" + ' ' + question.question} id={question.type ?? question.question_type} required={question.required} name={question.question}
                                                                    defaultValue={question.answer} onChange={this.onChangeTreatmentQuestions} data-order={question.order} data-myid={question.id}/>
                                                            : <></>}
                                                            {question.type == 'A' || question.question_type == 'A' ?
                                                                <input type="text" className={"form-control" + ' ' + question.question} id={question.type ?? question.question_type} required={question.required} name={question.question}
                                                                 defaultValue={question.answer} onChange={this.onChangeTreatmentQuestions} data-order={question.order} data-myid={question.id}/>    
                                                            : <></>}
                                                            {question.type == 'F' || question.question_type == 'F' ? 
                                                                    <input type="date" className={"form-control" + ' ' + question.question}  id={question.type ?? question.question_type} required={question.required}
                                                                    name={question.question}  onChange={this.onChangeTreatmentQuestions} defaultValue={question.answer} data-order={question.order} data-myid={question.id}/>
                                                            : <></>}
                                                            {question.type == 'B' || question.question_type == 'B' ? 
                                                                <>
                                                                    <div className="form-check">
                                                                        <input className={"form-check-input" + " " + question.question} type="radio" data-order={question.order} data-myid={question.id} defaultChecked={question.answer == 'Yes'} name={question.question}  id={question.type ?? question.question_type} onChange={this.onChangeTreatmentQuestions} value="Yes"/>
                                                                        <label className="form-check-label" for="flexRadio1">{this.state.resources.Label_Yes}</label>
                                                                    </div>
                                                                    <div className="form-check">
                                                                        <input className={"form-check-input" + " " + question.question} type="radio" data-order={question.order} data-myid={question.id} defaultChecked={question.answer == 'No'} name={question.question}  id={question.type ?? question.question_type} onChange={this.onChangeTreatmentQuestions} value="No"/>
                                                                        <label className="form-check-label" for="flexRadio2">No</label>
                                                                    </div>
                                                                </>
                                                            : <></>}
                                                            {question.type == 'L' || question.question_type == 'L' ? 
                                                                    <select className={"form-control" + ' ' + question.question} data-val="true" data-order={question.order} data-myid={question.id} defaultValue={question.answer} id={question.type ?? question.question_type} name={question.question} onChange={this.onChangeTreatmentQuestions}>
                                                                        <option value="">{this.state.resources.Label_SelectOption}</option>
                                                                        {this.state.treatmentQuestions.map(tQuestion => (
                                                                            tQuestion.treatment_id == this.state.treatmentId &&
                                                                            <>
                                                                                {tQuestion.question_value.split(',').map(item => (
                                                                                tQuestion.question_type == 'L' &&
                                                                                <option value={item} key={item} name={tQuestion.question} selected={question.answer == item}>{item}</option>
                                                                            ))}
                                                                            </>
                                                                        ))}
                                                                    </select>
                                                            : <></>}
                                                        </div>
                                                    </div>
                                                </div>
                                                ))
                                            } 
                                        </div>
                                     }
                                    {(JSON.parse(localStorage.getItem('DataWorkspace'))?.state?.productId != this.state.productId) &&
                                        <div className="row">
                                            {this.state.treatmentQuestions.map(question => (
                                            // (question.treatment_id == this.state.treatmentId && question.country_id == this.state.params.id_pais) &&
                                         //codeleo   
                                        <div className="col-md-6 mb-2">
                                            <div className="row">
                                                <div className="col-md-5">
                                                    <p style={{fontSize: '1rem'}}>{question.question}</p>
                                                </div>
                                                <div className="col-md-7">
                                                    {question.question_type == 'N' ? 
                                                            <><input type="number" className={"form-control" + ' ' + question.question} id={question.question_type} required={question.answer_type == 'Obligatorio'} name={question.question}
                                                            max={question.question_value != null ? Number(question.question_value) : 100000} data-order={question.output_order} data-myid={question.id} onChange={this.onChangeTreatmentQuestions} /><div style={{position:'absolute',top:'0',right:'5px',color:'red'}}>{(question.answer_type === 'Obligatorio')?'*':''}</div ></>
                                                    : <></>}
                                                    {question.question_type == 'A' ?
                                                        <><input type="text" className={"form-control" + ' ' + question.question} id={question.question_type} required={question.answer_type == 'Obligatorio'} name={question.question}
                                                         maxLength={question.question_value != null ? question.question_value : 100000} data-order={question.output_order} data-myid={question.id} onChange={this.onChangeTreatmentQuestions}/><div style={{position:'absolute',top:'0',right:'5px',color:'red'}}>{(question.answer_type === 'Obligatorio')?'*':''}</div ></>   
                                                    : <></>}
                                                    {question.question_type == 'F' ? 
                                                              <><input type="date" className={"form-control" + ' ' + question.question} style={{minWidth: '145px'}} id={question.question_type} required={question.answer_type == 'Obligatorio'}
                                                              name={question.question}  onChange={this.onChangeTreatmentQuestions} data-order={question.output_order} data-myid={question.id}/><div style={{position:'absolute',top:'0',right:'5px',color:'red'}}>{(question.answer_type === 'Obligatorio')?'*':''}</div ></>

                                                    : <></>}
                                                    {question.question_type == 'B' ? 
                                                        <><>
                                                            <div className="form-check">
                                                                <input className={"form-check-input" + " " + question.question} type="radio" name={question.question} data-order={question.output_order} data-myid={question.id} id={question.question_type} onChange={this.onChangeTreatmentQuestions} value="Yes"/>
                                                                <label className="form-check-label" for="flexRadio1">{this.state.resources.Label_Yes}</label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className={"form-check-input" + " " + question.question} type="radio" name={question.question} data-order={question.output_order} data-myid={question.id} id={question.question_type} onChange={this.onChangeTreatmentQuestions} value="No"/>
                                                                <label className="form-check-label" for="flexRadio2">No</label>
                                                            </div>
                                                        </><div style={{position:'absolute',top:'0',right:'5px',color:'red'}}>{(question.answer_type === 'Obligatorio')?'*':''}</div ></>
                                                    : <></>}
                                                    {question.question_type == 'L' ? 
                                                            <><select className={"form-control" + ' ' + question.question} data-val="true" id={question.question_type} data-order={question.output_order} data-myid={question.id} name={question.question} onChange={this.onChangeTreatmentQuestions}>
                                                                <option value="">{this.state.resources.Label_SelectOption}</option>
                                                                {question.question_value.split(',').map(item => (
                                                                    <option value={item} key={item} name={question.question}>{item}</option>
                                                                ))}
                                                            </select><div style={{position:'absolute',top:'0',right:'5px',color:'red'}}>{(question.answer_type === 'Obligatorio')?'*':''}</div ></>
                                                    : <></>}
                                                </div>
                                            </div>
                                        </div>
                                        ))}
                                    </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                              <div className="row m-0 p-2" id="divObjsScrip" style={{border: '2px solid rgb(51 99 205)',borderRadius: '5px', height: '450px'}}>
                                <div className="col-md-6"> 
                                  <div className="card">
                                    <div className="card-header">
                                    {this.state.resources.Title_Objetions}
                                     </div>
                                       <div className="card-body">
                                    <ObjetionScriptsAccordion data={this.state.objetions} resources={this.state.resources}
                                        country_id={this.state.params.id_pais} product_id={this.state.productId} />
                                     </div>
                                  </div>
                                 </div>
                                     < div className="col-md-6">
                                  <div className="card">
                                    <div className="card-header">
                                    {this.state.resources.Title_ScriptSales}
                                      </div>
                                       <div className="card-body">
                                    <ObjetionScriptsAccordion data={this.state.scriptSales} resources={this.state.resources}
                                        country_id={this.state.params.id_pais} product_id={this.state.productId} />
                                          </div>
                                          </div>
                                   </div>
                          </div>
                               <div className="row"></div>
                     </div>
                     

                    <div className="row col-md-12 text-center">
                        <div className="col-md-6">
                            <NoSale data={this.state}/>
                        </div>
                        <div className="col-md-6">
                            <button className="btn btn-success my-2" style={{width:100,padding:5}} onClick={this.goToClientWsp}>{this.state.resources.Workspace_Next}</button>
                        </div>
                    </div>

            </>
        );
    }

}

function BasicModal(values) {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const handleOpen = () => {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': this.state.tokenProducts
    };
    let count = 0
    setTimeout(() => {
      if(count < 1){
        count++;
            axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getproductcountrykeyfiltered/' + this.state.productId,
            {
                'headers': headers
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ productCountriesKey: data, loading: false });
                setLoading(false);
                this.state.productCountriesKey = data;
                // order alphabetically
                this.state.productCountriesKey.sort((a,b) => a.description < b.description ? -1 : +(a.description > b.description))
            })
            .catch(function (error) {
                console.log(error);
                setLoading(false);
            });
      }
    }, 100);
      
      setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        myProdCountriesId = [];
    };
  
   const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        maxHeight:500,
        overflowY:'scroll'
    };

    let myProdCountriesId = [];

    if (this.state.productCountries.length > 0) {
        for (let i = 0; i < this.state.productCountries.length; i++){
            if (this.state.productCountries[i].country_id == this.state.params.id_pais) {
                myProdCountriesId.push(this.state.productCountries[i].id)
            }
        }
    }
    
  return (
    <div>
        <button className='btn btn-primary' onClick={handleOpen}>
            <i className="px-6 m-5" style={{padding:30, cursor: 'pointer', color: 'white', fontSize:'1.1rem'}} class="fas fa-dollar-sign fa-3x fa-lg mr-2 mx-2 p-1 " data-tougle="tooltip" data-placement="right" title={values.data.resources.Label_Price} onClick={handleOpen}></i>
        </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"    
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2" className='text-center'>
            {values.data.products.map(prod => 
                prod.id == values.data.productId &&
                <>{prod.name}</>
            )}
          </Typography>
          <hr></hr>
          
            <div>
            {loading == true ? <div className="text-center"><CircularProgress /></div>: 
                <table className='table datatable my-table' aria-labelledby="tabelLabel">
                    <thead>
                        <tr>
                          <th>{values.data.resources.Label_Presentation}</th>
                          <th style={{textAlign: 'right'}}>{values.data.resources.Label_Price}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.productCountriesKey.map(item =>
                            // myProdCountriesId.includes(item.productcountry_id) &&
                            <tr>
                                <td>{item.description}</td>
                                <td style={{textAlign: 'right'}}>${item.price.toLocaleString('es-ES')}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            }

            </div>
                  
        </Box>
      </Modal>
    </div>
  );
}
export default React.memo(BasicModal);


function ObjetionScriptsAccordion(values) {
    // console.log(values);
    let loading = true;
    let myDataArray = [];
    if (values.data.length > 0) {
        myDataArray = values.data.sort((a, b) => a.title.toLowerCase() < b.title.toLowerCase() ? -1 : +(a.title.toLowerCase() > b.title.toLowerCase()))
        loading = false;
    }

  return (
    <div  style={{height:'320px', overflowY:'auto'}}>
        {loading == true ? <div className="text-center"><CircularProgress /></div>: 
        myDataArray.map((objScrip) => (
            objScrip.country_id == values.country_id && objScrip.enabled && (objScrip.product_id == values.product_id || objScrip.product_id === null)? 
            <Accordion>
            <AccordionSummary
                expandIcon={<i class="fas fa-chevron-down"></i>}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography>{objScrip.title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography
                id={'obj' + objScrip.id}
                dangerouslySetInnerHTML={{ __html: objScrip.detail }}
                ></Typography>
            </AccordionDetails>
            </Accordion>
            : <></>
        ))}
    </div>
  );
}

function NoSale(values) {
  const [open, setOpen] = React.useState(false);
  const [closingReasonId, setClosingReasonId] = React.useState(0);
  const handleOpen = () => {
      setOpen(true);
        if(this.state.closingReasons.length < 1){
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': this.state.tokenProducts//await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_URL)
            };
            axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getclosingreasons',
            {
                'headers': headers
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ closingReasons: data, loading: false });
                // order alphabetically
                this.state.closingReasons.sort((a,b) => a.description < b.description ? -1 : +(a.description > b.description))
            })
            .catch(function (error) {
                console.log(error);
            });
        }
    };
    const handleClose = () => {
        setOpen(false);
    };
    let loading = true;
  
    const onNosale = () => {
        this.SaveNoSale();
        handleClose();
    }

   const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };
    
    let myProdCountriesId = [];

    if (values.data.productCountries.length > 0) {
        loading = false;
        for (let i = 0; i < values.data.productCountries.length; i++){
            myProdCountriesId.push(values.data.productCountries[i].id)
        }
    }    
    
  return (
    <div>
      <button onClick={handleOpen} className="m-2 px-3 btn btn-danger" style={{width:100,padding:5}}>{values.data.resources.Label_NoSale}</button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2" className='text-center'>
            {values.data.resources.Label_NoSale}
          </Typography>
          <hr></hr>
          
            <div className="dis-flex">
                <div className="col-md-6">
                    <label>{values.data.resources.Label_NoSaleReason}</label>
                    <select className="form-control col-md-12" data-val="true" name="closingReasonId" defaultValue={values.data.closingReasonId} onChange={this.onChangeClosingReasonId} > {/** */}
                        <option value="">{values.data.resources.Label_SelectOption}</option>
                        {values.data.closingReasons.map(closing =>
                            // closing.enabled &&
                            <option key={closing.id} value={closing.id}>
                                {closing.description}
                            </option>
                        )}
                    </select>
                </div>
                
                <div className="col-md-6">
                    <label>{values.data.resources.Label_ContactAgain}</label>
                    <input type="datetime-local"  className="form-control col-md-12" onChange={this.onContactAgain}/>
                </div>
            </div>
            <div className="m-2 p-1">
                <label>{values.data.resources.Label_Comments}</label>
                <textarea className="form-control col-md-12" maxLength={100} onChange={this.onChangeCommnets}></textarea>
                <div className="row m-0">
                        <div className="col-md-6">
                        </div>
                        <div className="col-md-6" style={{textAlign:'right' }}>
                            {
                                this.state.comments.charAt(0) && <label id="lblComentaries"> {this.state.resources.Label_Remain} {this.state.remainComentaries} {this.state.resources.Label_Chararter} </label>
                            }
                            
                        </div>
                </div>
            </div>
            <div className="text-center">
                <button className="btn btn-primary" disabled={this.state.closingReasonId < 1} onClick={onNosale}>
                    {values.data.resources.ButtonLabel_Save}
                </button>
            </div>
                  
        </Box>
      </Modal>
    </div>
  );
}

function WayPaysModal(values) {
  const [open, setOpen] = React.useState(false);
  const [Loading, setLoading] = React.useState(false);
  
  let flag = false;
    const handleOpen = () => {
        flag = !flag;
        if(flag){
            this.getMapCourierCities();
        }
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    let loading = true;
  
   const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        height: '80%',
        overflowY: 'auto',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    if (values.data.mapCourierCities.length > 0) {
        loading = false;
    }

    const setNewState = (e) => {
        values.data.stateId = e.target.value;
    }
    let flag1 = false;
    const getNewState = (e) => {
        e.preventDefault();
        let data = {
            target: {
                value: values.data.stateId
            }
        }
        this.state.stateId = values.data.stateId;
        this.setState({
            stateId: values.data.stateId
        })
        this.onChangeState(data);
        setLoading(true);
        
        flag1 = !flag1;
        if(flag1){
            this.getMapCourierCities();
        }

        setTimeout(() => {
            setLoading(false);
        }, 1000)   
    }
    
    
  return (
      <div className=" col-md-6 ">
          <button className='btn btn-primary mr-2' onClick={handleOpen}>
            <i className="px-6 m-5" style={{padding:30, cursor: 'pointer', color:'white', fontSize:'1.3rem'}} class="fas fa-shipping-fast fa-3x fa-lg mr-2 mx-2 p-1 " data-tougle="tooltip" data-placement="right" title={values.data.resources.Label_TimesDelivery} onClick={handleOpen}></i>
          </button>                          
        
                                            
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2" className='text-center'>
            <th>{values.data.resources.Label_RoutesDestination}</th>
          </Typography>
          <hr></hr>
          
            <div>
            {loading == true || Loading ? <div className="text-center"><CircularProgress /></div>: 
                <div>
                    <div className="row p-2 mb-1 dis-flex">
                    <label className="form-label">{this.state.resources.Label_Location}</label>
                        <select className="form-control col-md-6 mx-2" data-val="true" name="stateId" defaultValue={this.state.stateId} onChange={setNewState}> {/**  onChange={this.onChangeState}  required */}
                                <option value="">{values.data.resources.Label_SelectOption}</option>
                                {this.state.states.map(state =>
                                    <option key={state.id} value={state.id}>{state.name}</option>
                                )} 
                        </select>
                        <button className="btn btn-primary col-md-1" style={{padding:5, backgroundColor:'#e1e107', borderColor:'yellow'}} onClick={getNewState}>
                            {/*{values.data.resources.Label_Refresh}*/}
                            <i className="px-6 m-5" style={{padding:6, cursor: 'pointer', color:'black', fontSize:'1.5rem', paddingLeft:1}} class="fas fa-redo fa-3x fa-lg ml-1 mr-2" data-tougle="tooltip" data-placement="right" title={values.data.resources.Label_Refresh} onClick={getNewState}></i>
                        </button>
                    </div>
                    <table className='table datatable my-table' aria-labelledby="tabelLabel">
                        <thead>
                            <tr>
                            <th>{values.data.resources.Label_CityCom}</th>
                            <th>{values.data.resources.Title_WayPay}</th>
                            <th>{values.data.resources.Title_Limit}</th>
                            <th>{values.data.resources.Title_Courier}</th>
                            <th>{values.data.resources.Label_DeliveryTime}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.mapCourierCities.map(item =>
                                this.state.stateId == item.state_id &&
                                <tr>
                                    
                                    <td>{item.city_name}</td>
                                    <td>{item.waypay_method == 'All' ? values.data.resources.Label_All : item.waypay_method }</td>
                                    <td>{item.limit != 0 ? '$' + item.limit.toLocaleString('en-US'): '---' }</td>
                                    <td>{item.courier_name}</td>
                                    <td>{item.delivery_time}</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            }

            </div>
                  
        </Box>
      </Modal>
    </div>
  );
}



