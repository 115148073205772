import React, { Component } from 'react';
import axios from 'axios';
import helpers from '../../Helpers';

import '../../custom.css';


export class LanguageResourceDetail extends Component {
    static displayName = LanguageResourceDetail.name;

    constructor(props) {
        super(props);

        this.onChangeLanguage0 = this.onChangeLanguage0.bind(this);
        this.onChangeLanguage1 = this.onChangeLanguage1.bind(this);
        this.onChangeLanguage2 = this.onChangeLanguage2.bind(this);
        this.onChangeLanguage3 = this.onChangeLanguage3.bind(this);
        this.onChangeLanguage4 = this.onChangeLanguage4.bind(this);
        this.onChangeLanguage5 = this.onChangeLanguage5.bind(this);
        this.onChangeLanguage6 = this.onChangeLanguage6.bind(this);
        this.onChangeLanguage7 = this.onChangeLanguage7.bind(this);
        this.onChangeLanguage8 = this.onChangeLanguage8.bind(this);
        this.onChangeLanguage9 = this.onChangeLanguage9.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onSubmit = this.onSubmit.bind(this);  

        this.state = {
            id: 0,
            var: '',
            description: '',
            language0: '',
            language1: null,
            language2: null,
            language3: null,
            language4: null,
            language5: null,
            language6: null,
            language7: null,
            language8: null,
            language9: null,
            lastUpdate: '',
            lastUpdateUser: '',
            loading: true,
            resources: [],
            viewMode: false,
        };
    }

    componentDidMount() {
        this.getResources();
        this.populateData();
        helpers.setNavActive("#nav-langresources");

        let url = window.location.href;
        if (url.includes('view')) {
            this.setState({
                viewMode: true
            });
        }

        let sort = JSON.parse(localStorage.getItem('LanguageRescListSorting'))
        localStorage.setItem('CopyLanguageRescListSorting', JSON.stringify(sort));

        let paging = JSON.parse(localStorage.getItem('LanguageRescListPagination'))
        localStorage.setItem('CopyLanguageRescListPaging', JSON.stringify(paging));

        let filtering = JSON.parse(localStorage.getItem('LanguageRescListFiltering'))
        localStorage.setItem('CopyLanguageRescListFiltering', JSON.stringify(filtering));
    }

    async onSubmit(e) {
        e.preventDefault();
        this.setState({
            loading: true
        });

        const data = {
            id: parseInt(this.props.match.params.id),
            description: this.state.description,
            var: this.state.var,
            language_0: this.state.language0,
            language_1: this.state.language1,
            language_2: this.state.language2,
            language_3: this.state.language3,
            language_4: this.state.language4,
            language_5: this.state.language5,
            language_6: this.state.language6,
            language_7: this.state.language7,
            language_8: this.state.language8,
            language_9: this.state.language9,
        };
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_SECURITY_API_NAME)
        };

        axios.post(window.config.REACT_APP_SECURITY_API_URL + '/languageresource/save', data, {
            headers: headers
        })
            .then((response) => {
                helpers.notifySuccess(this.state.resources.Label_Success, this.state.resources.SuccessMessage_DataSavedSuccessfully);
                this.props.history.push('/langresources');
            })
            .catch((error) => {
                console.log(error);

                let msg = this.state.resources.ErrorMessage_UnknownError;

                helpers.notifyError(this.state.resources.Label_Error, msg);
                this.setState({ loading: false });
            });
    }  

    onCancel(e) {
        e.preventDefault();
        this.props.history.push("/langresources");
    }

    onChangeLanguage0(e) {
        this.setState({
            language0: e.target.value
        });
    }  
    onChangeLanguage1(e) {
        this.setState({
            language1: e.target.value
        });
    }  
    onChangeLanguage2(e) {
        this.setState({
            language2: e.target.value
        });
    }  
    onChangeLanguage3(e) {
        this.setState({
            language3: e.target.value
        });
    }  
    onChangeLanguage4(e) {
        this.setState({
            language4: e.target.value
        });
    }  
    onChangeLanguage5(e) {
        this.setState({
            language5: e.target.value
        });
    }  
    onChangeLanguage6(e) {
        this.setState({
            language6: e.target.value
        });
    }  
    onChangeLanguage7(e) {
        this.setState({
            language7: e.target.value
        });
    }  
    onChangeLanguage8(e) {
        this.setState({
            language8: e.target.value
        });
    }  
    onChangeLanguage9(e) {
        this.setState({
            language9: e.target.value
        });
    }  

    render() {
        let contents = this.state.loading
            ? helpers.showLoadingOverlay()
            :
            <form className="form row g-3 needs-validation" onSubmit={this.onSubmit}>
                <div className="form-group col-md-12">
                    <input type="hidden" name="areaId" value={this.state.id} />
                </div>
                <div className="form-group col-md-12" >
                    <label className=" form-label">{this.state.resources.Label_Var}</label>
                    <input disabled={this.state.viewMode} className="form-control" type="text" name="var" value={this.state.var} readOnly />
                </div>
                <div className="form-group col-md-12" >
                    <label className=" form-label">{this.state.resources.Label_Description}</label>
                    <input disabled={this.state.viewMode} className="form-control" type="text" name="description" value={this.state.description} readOnly />
                </div>

                {/*LANGUAGE 0*/}
                <div className="form-group col-md-12" >
                    <label className=" form-label">{this.state.resources.Label_Language0}</label>
                    <input disabled={this.state.viewMode} className="form-control" type="text" name="language0" onChange={this.onChangeLanguage0} value={this.state.language0} required />
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                </div>

                {/*LANGUAGE 1*/}
                <div className="form-group col-md-12" >
                    <label className=" form-label">{this.state.resources.Label_Language1}</label>
                    <input disabled={this.state.viewMode} className="form-control" type="text" name="language1" onChange={this.onChangeLanguage1} value={this.state.language1} />
                </div>

                {/*LANGUAGE 2*/}
                <div className="form-group col-md-12" >
                    <label className=" form-label">{this.state.resources.Label_Language2}</label>
                    <input disabled={this.state.viewMode} className="form-control" type="text" name="language2" onChange={this.onChangeLanguage2} value={this.state.language2} />
                </div>

                {/*LANGUAGE 3*/}
                <div className="form-group col-md-12" >
                    <label className=" form-label">{this.state.resources.Label_Language3}</label>
                    <input disabled={this.state.viewMode} className="form-control" type="text" name="language3" onChange={this.onChangeLanguage3} value={this.state.language3} />
                </div>

                {/*LANGUAGE 4*/}
                <div className="form-group col-md-12" >
                    <label className=" form-label">{this.state.resources.Label_Language4}</label>
                    <input disabled={this.state.viewMode} className="form-control" type="text" name="language4" onChange={this.onChangeLanguage4} value={this.state.language4} />
                </div>

                {/*LANGUAGE 5*/}
                <div className="form-group col-md-12" >
                    <label className=" form-label">{this.state.resources.Label_Language5}</label>
                    <input disabled={this.state.viewMode} className="form-control" type="text" name="language5" onChange={this.onChangeLanguage5} value={this.state.language5} />
                </div>

                {/*LANGUAGE 6*/}
                <div className="form-group col-md-12" >
                    <label className=" form-label">{this.state.resources.Label_Language6}</label>
                    <input disabled={this.state.viewMode} className="form-control" type="text" name="language6" onChange={this.onChangeLanguage6} value={this.state.language6} />
                </div>

                {/*LANGUAGE 7*/}
                <div className="form-group col-md-12" >
                    <label className=" form-label">{this.state.resources.Label_Language7}</label>
                    <input disabled={this.state.viewMode} className="form-control" type="text" name="language7" onChange={this.onChangeLanguage7} value={this.state.language7} />
                </div>

                {/*LANGUAGE 8*/}
                <div className="form-group col-md-12" >
                    <label className=" form-label">{this.state.resources.Label_Language8}</label>
                    <input disabled={this.state.viewMode} className="form-control" type="text" name="language8" onChange={this.onChangeLanguage8} value={this.state.language8} />
                </div>

                {/*LANGUAGE 9*/}
                <div className="form-group col-md-12" >
                    <label className=" form-label">{this.state.resources.Label_Language9}</label>
                    <input disabled={this.state.viewMode} className="form-control" type="text" name="language9" onChange={this.onChangeLanguage9} value={this.state.language9} />
                </div>
               
                <div className="form-group col-md-6" >
                    <DataModification resources={this.state}></DataModification>
                </div>

                {/**Buttons Header */}
                <div className="buttons-head row">
                    {this.state.viewMode == false ?
                    <div className=" buttons-childs">
                        <button type="submit" className="btn btn-primary">{this.state.resources.ButtonLabel_Save}</button>
                        <button className="btn btn-danger" onClick={this.onCancel}>{this.state.resources.ButtonLabel_Cancel}</button>
                    </div>
                    : 
                      <button className="btn btn-danger btn-block" onClick={this.onCancel}>{this.state.resources.Label_GoBack}</button>
                    }
                 </div>
            </form>
            helpers.hideLoadingOverlay();

        return (
            <div className="mt-2">
                <ol className="breadcrumb my-4">
                    <li className="breadcrumb-item active">{this.state.resources.Title_LanguageResources}</li>
                </ol>
                <div className="card">
                    <div className="card-body">
                        {contents}
                    </div>
                </div>
            </div>
        );
    }

    async getResources() {
        const resourceVars = [];
        resourceVars.push("Title_LanguageResources");
        resourceVars.push("Label_Var");
        resourceVars.push("Label_Description");
        resourceVars.push("Label_Language0");
        resourceVars.push("Label_Language1");
        resourceVars.push("Label_Language2");
        resourceVars.push("Label_Language3");
        resourceVars.push("Label_Language4");
        resourceVars.push("Label_Language5");
        resourceVars.push("Label_Language6");
        resourceVars.push("Label_Language7");
        resourceVars.push("Label_Language8");
        resourceVars.push("Label_Language9");
        resourceVars.push("Label_LastUpdate");
        resourceVars.push("Label_LastUpdateUser");
        resourceVars.push("Label_Enabled");
        resourceVars.push("Label_Success");
        resourceVars.push("ButtonLabel_Save");
        resourceVars.push("ButtonLabel_Cancel");
        resourceVars.push("Label_Error");
        resourceVars.push("ErrorMessage_RequiredField");
        resourceVars.push("ErrorMessage_UnknownError");
        resourceVars.push("SuccessMessage_DataSavedSuccessfully");
        resourceVars.push("Label_GoBack");

        const headers = {
            'Content-Type': 'application/json'
        };

        axios.post(window.config.REACT_APP_SECURITY_API_URL + '/languageresource/getbyvars/' + helpers.getUiLanguage(), resourceVars, {
            headers: headers
        })
            .then((response) => {
                const data = response.data.data;
                this.setState({ resources: data, loading: false });
            })
            .catch((error) => {
                console.log(error);
                this.setState({ loading: false });
            });
    }

    async populateData() {
        axios.get(window.config.REACT_APP_SECURITY_API_URL + '/languageresource/getbyid/' + this.props.match.params.id,
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_SECURITY_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({
                    id: data.id,
                    var: data.var,
                    description: data.description,
                    language0: data.language_0,
                    language1: data.language_1,
                    language2: data.language_2,
                    language3: data.language_3,
                    language4: data.language_4,
                    language5: data.language_5,
                    language6: data.language_6,
                    language7: data.language_7,
                    language8: data.language_8,
                    language9: data.language_9,
                    lastUpdate: data.last_update == null ? '' : helpers.getDisplayDateTimeFromUtc(data.last_update),
                    lastUpdateUser: data.last_update_user_fullname == null ? '' : data.last_update_user_fullname,
                    loading: false
                });
            })
            .catch(function (error) {
                console.log(error);
                this.setState({ loading: false });
            })  
    }
}

export default function DataModification(values) {

    return (
        <div>
            <p><b>{values.resources.resources.Label_LastUpdate}</b>:
            {values.resources.lastUpdateUser !== '' ? values.resources.lastUpdateUser : values.resources.creationUser}
            {values.resources.lastUpdate !== '' ? values.resources.lastUpdate : values.resources.creation}
            </p>
        </div>
    )
}
