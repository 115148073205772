import React, { Component } from 'react';
import axios from 'axios';
import helpers from '../../Helpers'
import $ from 'jquery';

export class AuthorizationTokenCreate extends Component {
    static displayName = AuthorizationTokenCreate.name;

    constructor(props) {
        super(props);

        this.onChangeApiName = this.onChangeApiName.bind(this);
        this.onChangeEnabled = this.onChangeEnabled.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onSubmit = this.onSubmit.bind(this);  

        this.state = {
            id: 0,
            apiName: '',
            userId: 0,
            userFullName: '',
            enabled: true,
            loading: true,
            users: [],
            resources: [],
            currentUserId: 0,
        };
    }

    componentDidMount() {
        this.getResources();
        this.populateData();
        helpers.setNavActive("#nav-authtokens");
        let currentUser = helpers.getUserData();
        this.setState({
            currentUserId: currentUser.id
        });
    }

    async onSubmit(e) {
        e.preventDefault();

        if (this.state.userId == 0) {
            helpers.notifyError(this.state.resources.Label_Error, this.state.resources.ErrorMessage_AuthorizationToken_SelectApiClient);
            return;
        }

        this.setState({ loading: true });

        const data = {
            api_name: this.state.apiName,
            user_id: this.state.userId,
            enabled: this.state.enabled,
            last_update_user_id: this.state.currentUserId
        };
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_SECURITY_API_NAME)
        };

        axios.post(window.config.REACT_APP_SECURITY_API_URL + '/auth/save', data, {
            headers: headers
        })
            .then((response) => {
                helpers.notifySuccess(this.state.resources.Label_Success, this.state.resources.SuccessMessage_DataSavedSuccessfully);
                this.props.history.push('/authtokens');
            })
            .catch((error) => {
                console.log(error);

                let msg = this.state.resources.ErrorMessage_UnknownError;
                if (error.response.data.error_code == window.errorCodes.DuplicatedEntity)
                    msg = this.state.resources.ErrorMessage_DuplicatedEntity_AuthorizationToken;

                helpers.notifyError(this.state.resources.Label_Error, msg);
                this.setState({ loading: false });
            });
    }  

    onCancel(e) {
        e.preventDefault();
        this.props.history.push("/authtokens");
    }

    onChangeApiName(e) {
        this.setState({
            apiName: e.target.value
        });
    }  
    onChangeEnabled(e) {
        this.setState({
            enabled: e.target.checked
        });
    } 

    setUserId = (id, fullName) => () => {
        this.setState({
            userId: id,
            userFullName: fullName
        });
    }

    render() {
        let contents = this.state.loading
            ? helpers.showLoadingOverlay()
            :
            <form className="form row g-3 needs-validation" onSubmit={this.onSubmit}>
                <div className="form-group col-md-12">
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" id="enabled" defaultChecked={this.state.enabled} onChange={this.onChangeEnabled} />
                        <label className="form-check-label" htmlFor="enabled">{this.state.resources.Label_Enabled}</label>
                        <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                    </div>
                </div>
                <div className="form-group col-md-12">
                    <label className=" form-label">{this.state.resources.Label_ApiClient}</label>
                    <div className="input-group">
                        <button data-bs-toggle="modal" data-bs-target="#selectUserModal" className="btn btn-outline-secondary" type="button" id="button-selectuser">{this.state.resources.ButtonLabel_Select}</button>
                        <input type="text" className="form-control" value={this.state.userFullName} placeholder={this.state.resources.Label_SelectApiClient} aria-label={this.state.resources.Label_SelectApiClient} aria-describedby="button-selectuser" readOnly />
                    </div>
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                </div>
                <div className="form-group col-md-12">
                    <label className=" form-label">{this.state.resources.Label_Api}</label>
                    <select className="form-control" data-val="true" name="area" defaultValue={this.state.apiName} onChange={this.onChangeApiName} required>
                        <option value="">{this.state.resources.Label_SelectOption}</option>
                        <option value="bi">{this.state.resources.Label_Api_Bi}</option>
                        <option value="customers">{this.state.resources.Label_Api_Customers}</option>
                        <option value="logistics">{this.state.resources.Label_Api_Logistics}</option>
                        <option value="marketing">{this.state.resources.Label_Api_Marketing}</option>
                        <option value="products">{this.state.resources.Label_Api_Products}</option>
                        <option value="sales">{this.state.resources.Label_Api_Sales}</option>
                        <option value="security">{this.state.resources.Label_Api_Security}</option>
                    </select>
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                </div>

                {/**Buttons Header */}
                 <div className="buttons-head row">
                    <div className=" buttons-childs">
                        <button type="submit" className="btn btn-primary">{this.state.resources.ButtonLabel_Save}</button>
                        <button className="btn btn-danger" onClick={this.onCancel}>{this.state.resources.ButtonLabel_Cancel}</button>
                    </div>
                 </div>
                {/* <div className="form-group col-12 text-end">
                    <button type="submit" className="btn btn-primary">{this.state.resources.ButtonLabel_Save}</button>
                    <button className="btn btn-negative" onClick={this.onCancel}>{this.state.resources.ButtonLabel_Cancel}</button>
                </div> */}

                <div id="selectUserModal" className="modal" tabIndex="-1">
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{this.state.resources.Title_SelectUser}</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label={this.state.resources.ButtonLabel_Close}></button>
                            </div>
                            <div className="modal-body">
                                <table className='table table-striped datatable' aria-labelledby="tabelLabel">
                                    <thead>
                                        <tr>
                                            <th>{this.state.resources.Label_ID}</th>
                                            <th>{this.state.resources.Label_apiName}</th>
                                            <th>{this.state.resources.Label_workEmail}</th>
                                            <th className="text-center">{this.state.resources.Label_Actions}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.users.map(user =>
                                                <tr key={user.id}>
                                                    <td>{user.id}</td>
                                                    <td>{user.name}</td>
                                                    <td>{user.apiname}</td>
                                                    <td className="text-center">
                                                        <button type="button" className="btn btn-default" data-bs-dismiss="modal" onClick={this.setUserId(user.id, user.name + ', ' + user.apiname)}><i className="fa fa-plus-circle"></i></button>
                                                    </td>
                                                </tr>
                                            )}
                                    </tbody>
                                </table>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">{this.state.resources.ButtonLabel_Close}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            helpers.hideLoadingOverlay();

        return (
            <div className="mt-2">
                {/* <h1 className="mt-4">{this.state.resources.Title_AuthorizationTokens}</h1> */}
                <ol className="breadcrumb my-4">
                    <li className="breadcrumb-item active">{this.state.resources.Title_AuthorizationTokens}</li>
                </ol>
                <div className="card">
                    <div className="card-body">
                        {contents}
                    </div>
                </div>
            </div>
        );
    }

    async getResources() {
        const resourceVars = [];
        resourceVars.push("Title_AuthorizationTokens");
        resourceVars.push("Title_SelectApiClient");
        resourceVars.push("Label_SelectOption");
        resourceVars.push("Label_ApiClient");
        resourceVars.push("Label_Api");
        resourceVars.push("Label_ID");
        resourceVars.push("Label_apiName");
        resourceVars.push("Label_workEmail");
        resourceVars.push("Label_Actions");
        resourceVars.push("Label_Enabled");
        resourceVars.push("Label_Success");
        resourceVars.push("Label_Api_Bi");
        resourceVars.push("Label_Api_Customers");
        resourceVars.push("Label_Api_Logistics");
        resourceVars.push("Label_Api_Marketing");
        resourceVars.push("Label_Api_Products");
        resourceVars.push("Label_Api_Sales");
        resourceVars.push("Label_Api_Security");
        resourceVars.push("Label_SelectApiClient");
        resourceVars.push("ButtonLabel_Save");
        resourceVars.push("ButtonLabel_Cancel");
        resourceVars.push("ButtonLabel_Select");
        resourceVars.push("ButtonLabel_Close");
        resourceVars.push("Label_Error");
        resourceVars.push("ErrorMessage_RequiredField");
        resourceVars.push("ErrorMessage_DuplicatedEntity_AuthorizationToken");
        resourceVars.push("ErrorMessage_UnknownError");
        resourceVars.push("ErrorMessage_AuthorizationToken_SelectApiClient");
        resourceVars.push("SuccessMessage_DataSavedSuccessfully");

        const headers = {
            'Content-Type': 'application/json'
        };

        axios.post(window.config.REACT_APP_SECURITY_API_URL + '/languageresource/getbyvars/' + helpers.getUiLanguage(), resourceVars, {
            headers: headers
        })
            .then((response) => {
                const data = response.data.data;
                this.setState({ resources: data, loading: false });
            })
            .catch((error) => {
                console.log(error);
                this.setState({ loading: false });
            });
    }

    async populateData() {
        this.setState({
            loading: false
        });

        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/apiclients/getall',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ users: data, loading: false });
                $('.datatable').DataTable({
                    "language": {
                        "lengthMenu": this.state.resources.Datatables_language_lengthMenu,
                        "zeroRecords": this.state.resources.Datatables_language_zeroRecords,
                        "info": this.state.resources.Datatables_language_info,
                        "infoEmpty": this.state.resources.Datatables_language_infoEmpty,
                        "infoFiltered": this.state.resources.Datatables_language_infoFiltered,
                        "search": this.state.resources.Datatables_language_search,
                        "paginate": {
                            "previous": this.state.resources.Datatables_language_paginate_previous,
                            "next": this.state.resources.Datatables_language_paginate_next
                        },
                        "autoWidth": false
                    }
                });
            })
            .catch(function (error) {
                console.log(error);
            });
    }
}
