import React, { Component } from 'react';
import $ from 'jquery';
import helpers from '../Helpers'
import axios from 'axios';

export class Home extends Component {
  static displayName = Home.name;

    constructor(props) {
        super(props);
        this.state = { resources: [], loading: true };
    }

    componentDidMount() {
        this.getResources();
        helpers.setNavActive("#nav-home");
    }

  render () {
    return (
        <div className="mt-2">
            {/* <h1 className="mt-4">{this.state.resources.NavItem_Home}</h1> */}
            <ol className="breadcrumb my-4">
                <li className="breadcrumb-item active">{this.state.resources.NavItem_Home}</li>
            </ol>
        </div>
    );
    }

    async getResources() {
        const resourceVars = [];
        resourceVars.push("NavItem_Home");

        const headers = {
            'Content-Type': 'application/json'
        };

        axios.post(window.config.REACT_APP_SECURITY_API_URL + '/languageresource/getbyvars/' + helpers.getUiLanguage(), resourceVars, {
            headers: headers
        })
            .then((response) => {
                const data = response.data.data;
                this.setState({ resources: data, loading: false });
                helpers.hideLoadingOverlay();
            })
            .catch((error) => {
                console.log(error);
            });
    }
}

export default Home;