import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';  
import $ from 'jquery';
import DataTable from 'datatables.net';
import helpers from '../../Helpers';
import '../../custom.css';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { DataGridPro } from '@mui/x-data-grid-pro';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Moment from 'moment';

import { LicenseInfo } from '@mui/x-data-grid-pro';
import { format } from 'date-fns';

LicenseInfo.setLicenseKey(
  '97124f3444ae3cf552c73ef2f3487b08T1JERVI6MzEzNDQsRVhQSVJZPTE2NjY1MzkzOTkwMDAsS0VZVkVSU0lPTj0x',
);

export class SalesHistoryList extends Component {
    static displayName = SalesHistoryList.name;

    constructor(props) {
        super(props);

        AddFilter = AddFilter.bind(this);
        PublicityDataGrid = PublicityDataGrid.bind(this);

        this.state = {
            items: [],
            itemsFilter:[],
            resources: [],
            loading: true,
            permissionsIds: [],
            columns: [],
            paging: null,
            sorting: null,
            filtering: null,
            countries: [],
            waypaies: [],
            carStates: [],
        };
    }

    componentDidMount() {
        let userData = helpers.getUserData();
        this.state.permissionsIds = userData.permission_ids;
        this.getResources();
        this.populateData();
        helpers.setNavActive("#nav-saleshistory");
        // this.state.sorting = JSON.parse(localStorage.getItem('CopyPublicityListSorting'));
        // this.state.paging = JSON.parse(localStorage.getItem('CopyPublicityListPaging'));
        // this.state.filtering = JSON.parse(localStorage.getItem('CopyPublicityListFiltering'));
    }

    //   static renderTable(items, resources, permissionsIds) {
    //       return (
    //             <table className='table datatable my-table' aria-labelledby="tabelLabel"> {/*table class:* table-striped datatable */}
    //                 <thead>
    //                     <tr>
    //                       <th className="text-center">{resources.Label_Actions}</th>
    //                       <th>{resources.Label_Order}</th>
    //                       <th>{resources.Label_Client}</th>
    //                       <th>{resources.Label_WayPay}</th>
    //                       <th>{resources.Label_Courier}</th>
    //                       <th>{resources.Label_Invoice}</th>
    //                       <th>{resources.Label_PaymentState}</th>
    //                       <th>{resources.Label_OrderState}</th>
    //                       <th>{resources.Label_Mount}</th> 
    //                       <th>{resources.Label_Operator}</th>
    //                       <th>{resources.Label_Product}</th>
    //                       <th>{resources.Label_CreationDate}</th>
    //                       <th>{resources.Label_OrderOcc1}</th>
    //                     </tr>
    //                 </thead>
    //                 <tbody>
    //                     {
    //                         items.map(item =>
    //                             <tr key={item.pedido}>
    //                                 {permissionsIds.includes(2197) ?
    //                                 <td className="text-center">
    //                                     <Link to={"/salesview/" + item.pedido} className="btn btn-default"><i className="fas fa-edit"></i></Link>
    //                                 </td>
    //                                 :
    //                                 <td className="text-center">
    //                                     <Link to={"/salesview/" + item.pedido} className="btn btn-default"><i class="fas fa-edit"></i></Link>
    //                                 </td>
    //                                 }

    //                                 <td>{item.pedido}</td>
    //                                 <td>{item.client_name}</td>
    //                                 <td>{item.waypay_name}</td>
    //                                 <td>{item.courier}</td>
    //                                 <td>{item.invoice_number}</td>
    //                                 <td>{item.payment_state_name}</td>
    //                                 <td>{item.car_state_name}</td>
    //                                 <td>{item.total}</td>
    //                                 <td>{item.user_operator_name}</td>
    //                                 <td>{item.product_name}</td>
    //                                 <td>{item.creation_date}</td>
    //                                 <td>{item.pedido_occ}</td>
    //                             </tr>
    //                         )}
    //                 </tbody>
    //             </table>
    //     );
    //   }

      render() {
          let contents = this.state.loading
              ? helpers.showLoadingOverlay()
              : <PublicityDataGrid data={this.state}/>
            // : DidList.renderTable(this.state.items, this.state.resources, this.state.permissionsIds);

          return (
              <div className="mt-2">
                  <ol className="breadcrumb my-4">
                      <li className="breadcrumb-item active">{this.state.resources.Title_SalesHistory}</li>
                  </ol>
                  <div className="card">
                  <AddFilter />
                      <div className="card-body">
                          <div className="table-responsive">
                              {contents}
                          </div>
                      </div>
                  </div>
              </div>
        );
      }

    async populateData() {
         
        // let hoy = new Date();
        // let dia = hoy.getDate();
        // let mes = hoy.getMonth() + 1;
        // let ano = hoy.getFullYear();

        // dia = ('0' + dia).slice(-2);
        // mes = ('0' + mes).slice(-2);
        
        // let formateDayActual = `${dia}/${mes}/${ano}`;
        // this.state.dateOfSince = formateDayActual;
        // this.state.dateOfUntil = formateDayActual;
        // document.getElementById("fechaActual")?.value = formateDayActual;
        // console.log(formateDayActual);
        // console.log(this.state.dateOfSince );
        // console.log(this.state.dateOfUntil );

        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/country/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ countries: data});
                // order alphabetically
                this.state.countries.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                // console.log(this.state.countries);
            })
            .catch(function (error) {
                console.log(error);
            });
        
            axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/waypay/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ waypaies: data});
                // order alphabetically
                this.state.waypaies.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                // console.log(this.state.countries);
            })
            .catch(function (error) {
                console.log(error);
            });

            axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/PbxWorkspace/getcarstates',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ carStates: data});
                // order alphabetically
                this.state.carStates.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                // console.log(this.state.countries);
            })
            .catch(function (error) {
                console.log(error);
            });

        helpers.showLoadingOverlay();
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/shoppingcar/getventashistoricas',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                console.log(data);
                this.setState({ items: data, loading: false });
                helpers.hideLoadingOverlay();
            })
            .catch(function (error) {
                console.log(error);
                helpers.hideLoadingOverlay();
            });
    }
    async getResources() {
        const resourceVars = [];
        resourceVars.push("Label_Order")
        resourceVars.push("Label_Client")
        resourceVars.push("Label_WayPay")
        resourceVars.push("Label_Invoice")
        resourceVars.push("Label_PaymentState")
        resourceVars.push("Label_OrderState")
        resourceVars.push("Label_Mount")
        resourceVars.push("Label_Operator")
        resourceVars.push("Label_Product")
        resourceVars.push("Label_CreationDate")
        resourceVars.push("Label_OrderOcc1")
        resourceVars.push("Label_Courier")
        resourceVars.push("Title_CampaingType");
        resourceVars.push("Title_Pbx");
        resourceVars.push("Label_ID");
        resourceVars.push("Label_Name");
        resourceVars.push("Label_Description");
        resourceVars.push("Label_Actions");
        resourceVars.push("Label_Status");
        resourceVars.push("Label_Enabled");
        resourceVars.push("Label_Disabled");
        resourceVars.push("ButtonLabel_ViewEdit");
        resourceVars.push("ButtonLabel_New");
        resourceVars.push("Datatables_language_lengthMenu");
        resourceVars.push("Datatables_language_zeroRecords");
        resourceVars.push("Datatables_language_info");
        resourceVars.push("Datatables_language_infoEmpty");
        resourceVars.push("Datatables_language_infoFiltered");
        resourceVars.push("Datatables_language_search");
        resourceVars.push("Datatables_language_paginate_previous");
        resourceVars.push("Datatables_language_paginate_next");
        resourceVars.push("Label_Country");
        resourceVars.push("Label_Comments");
        resourceVars.push("Title_SalesHistory");
        resourceVars.push("Label_Product");
        resourceVars.push("Label_Products");
        resourceVars.push("Title_Carrier");
        resourceVars.push("Label_FilterOff");
        resourceVars.push("Label_Filter");
        resourceVars.push("Label_Sort");
        resourceVars.push("Label_Hide");
        resourceVars.push("Label_ShowColumns");
        resourceVars.push("Label_OrderAsc");
        resourceVars.push("Label_OrderDesc");
        resourceVars.push("Label_Unsorting");
        resourceVars.push("Label_Columns");
        resourceVars.push("Label_Delete");
        resourceVars.push("Label_GridValue");
        resourceVars.push("Label_ValueFilter");
        resourceVars.push("Label_And");
        resourceVars.push("Label_Or");
        resourceVars.push("Label_NoRows");
        resourceVars.push("Label_SelectColumns");
        resourceVars.push("Label_OperatorAfter");
        resourceVars.push("Label_OperatorBefore");
        resourceVars.push("Label_OperatorContains");
        resourceVars.push("Label_OperatorEnds");
        resourceVars.push("Label_OperatorEquals");
        resourceVars.push("Label_OperatorIs");
        resourceVars.push("Label_OperatorIsEmpty");
        resourceVars.push("Label_OperatorIsNotEmpty");
        resourceVars.push("Label_OperatorIsNot");
        resourceVars.push("Label_OperatorIsOnOrAfter");
        resourceVars.push("Label_OperatorIsOnOrBefore");
        resourceVars.push("Label_OperatorStarts");
        resourceVars.push("Label_ReorderColumns");
        resourceVars.push("Label_HideAll");
        resourceVars.push("Label_ShowAll");
        resourceVars.push("Label_FindColumn");
        resourceVars.push("Label_TitleColumn");
        resourceVars.push("Label_addFilter");
        resourceVars.push("Label_DocNumber");
        resourceVars.push("Label_Status");
        resourceVars.push("Label_SearchClient");
        resourceVars.push("Label_SelectOption");
        
        const headers = {
            'Content-Group': 'application/json'
        };

        axios.post(window.config.REACT_APP_SECURITY_API_URL + '/languageresource/getbyvars/' + helpers.getUiLanguage(), resourceVars, {
            headers: headers
        })
            .then((response) => {
                const data = response.data.data;
                this.setState({ resources: data, loading: false });
                this.state.columns = [
                    {
                        field: 'actions',
                        headerAlign: "center",
                        headerClassName: "header-table-mui",
                        headerName: this.state.resources.Label_Actions ? this.state.resources.Label_Actions : 'Actions',
                        flex: 1,
                        minWidth: 120,
                        renderCell: (item) => {
                            return (
                                this.state.permissionsIds.includes(2197) ?
                                <div className="d-flex justify-content-between align-items-center mx-auto" style={{ cursor: "pointer" }}>
                                    <td className="text-center">
                                        <Link to={"/salesview/" + item.id} className="btn btn-default"><i className="fas fa-edit"></i></Link>
                                    </td>
                                </div>
                                :
                                <div>
                                <td className="text-center">
                                    <Link to={"/salesview/" + item.id} className="btn btn-default"><i class="fas fa-eye"></i></Link>
                                </td>
                                </div>
                            );
                        }
                    },
                    {
                        field: 'id',
                        headerName: this.state.resources.Label_Order ? this.state.resources.Label_Order :'Order',
                        flex: 1,
                        minWidth: 60,
                        headerAling: "center",
                        headerClassName: "header-table-mui"
                    },
                    {
                        field: 'client_name',
                        headerName: this.state.resources.Label_Client ? this.state.resources.Label_Client :'Client',
                        flex: 1,
                        minWidth: 160,
                        headerAling: "center",
                        headerClassName: "header-table-mui"
                    },
                    {
                        field: 'waypay_name',
                        headerName: this.state.resources.Label_WayPay ? this.state.resources.Label_WayPay : 'Way Payment',
                        flex: 1,
                        minWidth: 160,
                        headerAling: "center",
                        headerClassName: "header-table-mui"
                    },
                    {
                        field: 'courier',
                        headerName: this.state.resources.Label_Courier ? this.state.resources.Label_Courier : 'Courier',
                        flex: 1,
                        minWidth: 160,
                        headerAling: "center",
                        headerClassName: "header-table-mui"
                    },
                    {
                        field: 'invoice_number',
                        headerName: this.state.resources.Label_Invoice ? this.state.resources.Label_Invoice : 'Invoice',
                        flex: 1,
                        minWidth: 160,
                        headerAling: "center",
                        headerClassName: "header-table-mui"
                    },
                    {
                        field: 'payment_state_name',
                        headerName: this.state.resources.Label_PaymentState ? this.state.resources.Label_PaymentState : 'Payment State',
                        flex: 1,
                        minWidth: 160,
                        headerAling: "center",
                        headerClassName: "header-table-mui"
                    },
                    {
                        field: 'car_state_name',
                        headerName: this.state.resources.Label_OrderState ? this.state.resources.Label_OrderState : 'Order State',
                        flex: 1,
                        minWidth: 160,
                        headerAling: "center",
                        headerClassName: "header-table-mui"
                    },
                    {
                        field: 'total',
                        headerName: this.state.resources.Label_Mount ? this.state.resources.Label_Mount : 'Mount',
                        flex: 1,
                        minWidth: 160,
                        headerAlign: "right",
                        align: 'right',
                        headerClassName: "header-table-mui",
                        type: 'date',
                        renderCell: (item) => {
                            return (
                                <div style={{textAlign: 'right'}}>
                                    ${Number(item.row.total).toLocaleString('es-ES')}
                                </div>
                            );
                        },
                        valueGetter: (item)=>item.row.total
                    },
                    {
                        field: 'user_operator_name',
                        headerName: this.state.resources.Label_Operator ? this.state.resources.Label_Operator : 'Operator',
                        flex: 1,
                        minWidth: 160,
                        headerAling: "center",
                        headerClassName: "header-table-mui"
                    },
                    {
                        field: 'product_name',
                        headerName: this.state.resources.Label_Product ? this.state.resources.Label_Product : 'Product',
                        flex: 1,
                        minWidth: 160,
                        headerAling: "center",
                        headerClassName: "header-table-mui"
                    },
                    {
                        field: 'creation_date',
                        headerName: this.state.resources.Label_CreationDate ? this.state.resources.Label_CreationDate : 'Creation Date',
                        flex: 1,
                        minWidth: 200,
                        headerAling: "center",
                        headerClassName: "header-table-mui",
                        type: 'date',
                        renderCell: (item) => {
                            return (
                                <div className="text-center">
                                    {helpers.getDisplayDateTime(item.row.creation_date)}
                                </div>
                            );
                        },
                        valueGetter: (item)=>item.row.creation_date
                    },
                    {
                        field: 'pedido_occ',
                        headerName: this.state.resources.Label_OrderOcc1 ? this.state.resources.Label_OrderOcc1 : 'Order Occ1',
                        flex: 1,
                        minWidth: 160,
                        headerAling: "center",
                        headerClassName: "header-table-mui"
                    }
                    
                ];
            })
            .catch((error) => {
                console.log(error);
            });
    }
}

export default function PublicityDataGrid(values) {

    let items = values.data.items,
        resources = values.data.resources,
        permissionsIds = values.data.permissionsIds, columns = values.data.columns,
        sorting = localStorage.getItem('CopyPublicityListSorting') ?
            JSON.parse(localStorage.getItem('CopyPublicityListSorting')) : values.data.sorting,
        paging = values.data.paging ? values.data.paging : 0
        , filter = localStorage.getItem('CopyPublicityListFiltering') ?
            JSON.parse(localStorage.getItem('CopyPublicityListFiltering')) : values.data.filtering
    
    const [page, setPage] = React.useState(paging.page);
    const [pageSize, setPageSize] = React.useState(paging.pageSize);
    
    const [value, setValue] = React.useState([null, null]);
    const [myRows, setRowsValue] = React.useState(values.data.items);
    const [mySortModel, setSortModel] = React.useState([]);
    const [filtering, setFilter] = React.useState(filter);
    const [status, onChangeStatus] = React.useState(true);
    const [open, setOpen] = React.useState(false);
    const openModal = () => setOpen(true);
    const closeModal = () => setOpen(false);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    function setInitial() {
        setTimeout(() => {
            if (document.getElementsByClassName('MuiTablePagination-selectLabel')[0]){
                document.getElementsByClassName('MuiTablePagination-selectLabel')[0].innerHTML = '';
            }
            // loading = true
            if (myRows.length < 1) {
                if (localStorage.getItem('PublicityFilterItems')) {
                    let myItems = JSON.parse(localStorage.getItem('PublicityFilterItems'));
                    setRowsValue(myItems);
                    setTimeout(() => {
                        localStorage.removeItem('PublicityFilterItems');
                    }, 1000);
                } else {
                    setRowsValue(items)
                }   
            }
            // if (sorting) {
            //     if (sorting.sortModel) {
            //         setSortModel(sorting.sortModel)
            //     }
            // }
        }, 1000)
       
    }
    setInitial()

    const handlePageChange = (newPage) => {
        setPage(newPage);
    };

    const handlePageSizeChange = (newPage) => {
        setPageSize(newPage);
    };

    const handleFilterChange = (newFilter) => {
        // console.log(newFilter)
        setFilter(newFilter);
    }

    const handleSortingChange = (sort) => {
        setSortModel(sort);
        localStorage.removeItem('PublicityListSorting');
        localStorage.removeItem('CopyPublicityListSorting');
        localStorage.setItem('CopyPublicityListSorting', JSON.stringify(sort));
    }

    const handleStatus = (e) => {
        e.preventDefault();
        onChangeStatus(e.target.value);
    }

    const onReloadData =
    async function onReloadData() {
        // console.log(status)
        
        if (status === true || status === 'true') {
            helpers.showLoadingOverlay();
            axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/publicity/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                // console.log(response.data.data)
                let newItems = response.data.data;
                setRowsValue(newItems);
                localStorage.setItem('PublicityFilterItems', JSON.stringify(newItems));
                closeModal();
                helpers.hideLoadingOverlay();
             })
            .catch(err => console.log(err));
        } else if (status != true){
            helpers.showLoadingOverlay();
            axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/publicity/getdisabled',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                // console.log(response.data.data)
                let newItems = response.data.data;
                setRowsValue(newItems);
                localStorage.setItem('PublicityFilterItems', JSON.stringify(newItems));
                closeModal();
                helpers.hideLoadingOverlay();
             })
            .catch(err => console.log(err));
        }
    }

    async function resetFilter() {
        helpers.showLoadingOverlay();
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/shoppingcar/getventashistoricas',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                let newItems = response.data.data;
                setRowsValue(newItems);
                localStorage.removeItem('PublicityFilterItems');
                closeModal();
                helpers.hideLoadingOverlay();
             })
            .catch(error => console.log(error));
    }

    return (
        <>
        {/* <button className="btn btn-success mb-1" onClick={openModal}><i class="fas fa-filter"></i></button> */}
        <Modal
            open={open}
            onClose={closeModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <div className="dis-flex m-1" style={{justifyContent: 'space-beetween'}}>
                    <div>
                        <label className="label-text">{values.data.resources.Label_Status}</label>
                        <select className="form-control" data-val="true" name="productId" value={status} onChange={handleStatus}>
                            <option value="true">{values.data.resources.Label_Enabled}</option>
                            <option value="false">{values.data.resources.Label_Disabled}</option>
                        </select>
                        </div>
                        <div className="mt-4">
                            <button className="btn btn-primary mx-1 my-auto" onClick={onReloadData}>{values.data.resources.Label_Filter}</button>
                            <button className="btn btn-danger mx-1 my-auto" onClick={resetFilter}>{values.data.resources.Label_FilterOff}</button>
                        </div>
                </div>
            </Box>
        </Modal>
            <div style={{ height: 450, width: '99%' }}>
                <DataGridPro rows={this.state.items.length > 0 ? this.state.items : myRows} columns={columns} rowsPerPageOptions={[5, 10, 25, 50, 100]} density='compact'
                pagination
                page={page}
                localeText={{
                    'columnMenuFilter': values.data.resources.Label_Filter,
                    'columnHeaderSortIconLabel': values.data.resources.Label_Sort,
                    'columnMenuHideColumn': values.data.resources.Label_Hide,
                    'columnMenuShowColumns': values.data.resources.Label_ShowColumns,
                    'columnMenuSortAsc': values.data.resources.Label_OrderAsc,
                    'columnMenuSortDesc': values.data.resources.Label_OrderDesc,
                    'columnMenuUnsort': values.data.resources.Label_Unsorting,
                    'filterPanelColumns': values.data.resources.Label_Columns,
                    'filterPanelDeleteIconLabel': values.data.resources.Label_Delete,
                    'filterPanelInputLabel': values.data.resources.Label_GridValue,
                    'filterPanelInputPlaceholder': values.data.resources.Label_ValueFilter,
                    'filterPanelOperatorAnd': values.data.resources.Label_And,
                    'filterPanelOperatorOr': values.data.resources.Label_Or,
                    'filterPanelOperators': values.data.resources.Label_Operator,
                    'noResultsOverlayLabel': values.data.resources.Label_NoRows,
                    'noRowsLabel': values.data.resources.Label_NoRows,
                    'toolbarColumns': values.data.resources.Label_Columns,
                    'toolbarColumnsLabel': values.data.resources.Label_SelectColumns,
                    'errorOverlayDefaultLabel': "Error",
                    'filterOperatorAfter': values.data.resources.Label_OperatorAfter,
                    'filterOperatorBefore': values.data.resources.Label_OperatorBefore,
                    'filterOperatorContains': values.data.resources.Label_OperatorContains,
                    'filterOperatorEndsWith': values.data.resources.Label_OperatorEnds,
                    'filterOperatorEquals': values.data.resources.Label_OperatorEquals,
                    'filterOperatorIs': values.data.resources.Label_OperatorIs,
                    'filterOperatorIsEmpty': values.data.resources.Label_OperatorIsEmpty,
                    'filterOperatorIsNotEmpty': values.data.resources.Label_OperatorIsNotEmpty,
                    'filterOperatorNot': values.data.resources.Label_NoRows,
                    'filterOperatorOnOrAfter': values.data.resources.Label_OperatorIsOnOrAfter,
                    'filterOperatorOnOrBefore': values.data.resources.Label_OperatorIsOnOrBefore,
                     'filterOperatorStartsWith': values.data.resources.Label_OperatorStarts,
                    'columnsPanelDragIconLabel': values.data.resources.Label_ReorderColumns,
                    'columnsPanelHideAllButton': values.data.resources.Label_HideAll,
                    'columnsPanelShowAllButton': values.data.resources.Label_ShowAll,
                    'columnsPanelTextFieldLabel': values.data.resources.Label_FindColumn,
                    'columnsPanelTextFieldPlaceholder': values.data.resources.Label_TitleColumn,
                    'filterPanelAddFilter': values.data.resources.Label_addFilter
                }}    
                pageSize={pageSize}
                // density='compact'
                onStateChange={(state) => {
                    // console.log(state)
                    localStorage.setItem('PublicityListPagination', JSON.stringify(state.pagination))
                    localStorage.setItem('PublicityListSorting', JSON.stringify(state.sorting))
                    localStorage.setItem('PublicityListFiltering', JSON.stringify(state.filter))
                }}
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
                sortModel={mySortModel}
                onSortModelChange={handleSortingChange}
                state={
                        filter != null ? {filter: filter} : {}
                        
                }
                      
            />
        </div>
    </>
    );
}
function AddFilter() {
    const [open, setOpen] = React.useState(false);
    const closeModal = () => setOpen(false);
    const [closingReasonId, setClosingReasonId] = React.useState(0);

    let countriess = [];

    const handleOpen = () => {
        setOpen(true);
      };
      const handleClose = () => {
        setOpen(false);
        this.setState({
          countryId: null,
          order: null,
          documentNumber: null,
          documentNumberSearched:null,
          dateOfSince:'',
          dateOfUntil:'',
          invoice: null,
          stateOrderId: null,
          waypayId: null,         
      });

    };
      let loading = true;
  
      const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '1200px',
        maxWidth:'1200px',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    
    const onChangeOrderModal = (e)  =>{
            this.setState({
                order: e.target?.value
            });
        }
    
    const onChangeRutDocumentModal = (e)  =>{
            this.setState({
                documentNumber: e.target?.value
            });

        }

    const onChangeInvoiceModal = (e)  =>{
            this.setState({
                invoice: e.target?.value
            });
        }
    
    const onChangeDateOfSinceModal = async (e)  =>{
            this.setState({
                dateOfSince: e.target?.value
            });
        }
    
    const onChangeDateOfUntilModal = async (e)  =>{
            this.setState({
                dateOfUntil: e.target?.value
            });
        }

        const onChangeCountryModal = async (e) => {
            this.setState({
                countryId: e.target?.value
            });
            console.log(e.target?.value)
        }

        const onChangeStateModal = async (e) => {
            this.setState({
                stateOrderId: e.target?.value
            });
            console.log(e.target?.value)
        }

        const onChangeWaypayModal = async (e) => {
            this.setState({
                waypayId: e.target?.value
            });
            console.log(e.target?.value)
        }

        
        const onClearModal = async (e) => {
        this.setState({
                order: '',
                invoice: null,
                documentNumber:'',
                countryId: 0,
                stateOrderId: 0,
                waypayId: 0,
                
            });

        }


    const onSearchOrderModal = async (e) => {
            e.preventDefault();
            
                const headers = {
                    'Content-Type': 'application/json',
                    'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME)
                };

                if(this.state.dateOfSince?.length > 0 ){

                    if(this.state.dateOfUntil?.length > 0){
                        if(this.state.invoice){
                        
                        }else{
                            this.state.invoice = null;
                        }
                        if(!this.state.order){
                            this.state.order = 0;
                        }
                        if(!this.state.countryId){
                            this.state.countryId = 0;
                        }
                        if(!this.state.stateOrderId){
                            this.state.stateOrderId = 0;
                        }
        
                        if(!this.state.documentNumber){
                            this.state.documentNumber = 0;
                        }
                        if(!this.state.waypayId){
                            this.state.waypayId = 0;
                        }
                        if(this.state.documentNumber?.length >0){
                            axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/shoppingcar/getclientbydocument/' + this.state.documentNumber, {
                                headers: headers
                            })
                            .then(response => {
                                const data = response.data.data;
                                console.log(data)

                                if(data?.length >0){

                                this.setState({
                                   documentNumberSearched: data.id
                                });
                                this.state.documentNumberSearched = data.id;
                                console.log(data.id)
                                console.log(this.state.documentNumberSearched)
                                axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/shoppingcar/filtrosventas/'+this.state.order+'/' +this.state.countryId+'/' +this.state.documentNumberSearched +'/'+this.state.dateOfSince+'/' +this.state.dateOfUntil+'/' +this.state.stateOrderId+'/' +this.state.waypayId+ '/'+this.state.invoice ,
                                {
                                    headers: headers
                                })
                                .then(response => {
                                    const data = response.data.data;
                                    console.log(data);

                                    if(data?.length > 0){

                                    this.setState({
                                        items: data
                                    });
                                    this.state.items = data;
                                    if(this.state.order == 0){
                                         this.state.order = "";
                                    }
                                    if(this.state.documentNumber == 0){
                                        this.state.documentNumber = "";
                                   }
                                    closeModal();
                                    this.state.loading = false;
                                    helpers.hideLoadingOverlay();
                                    
                                }else{
                                    this.setState({
                                        items: data
                                    });
                                    this.state.items = data;
                                    helpers.notifyWarning('Error','No se encontraron pedidos para mostrar'); 
                                    if(this.state.order == 0){
                                        this.state.order = "";
                                   }
                                   if(this.state.documentNumber == 0){
                                       this.state.documentNumber = "";
                                  }
                                }
                                })
                                .catch(function (error) {
                                    console.log(error);
                                    helpers.hideLoadingOverlay();
                                });
                                }else{
                                helpers.notifyError('Error','No se encontro pedido asociado al Documento Ingresado');
                                 }
                            })
                            .catch(function (error) {
                                console.log(error);
                            }); 
                            
                        }
                        else{
                            axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/shoppingcar/filtrosventas/'+this.state.order+'/' +this.state.countryId+'/' +this.state.documentNumber +'/'+this.state.dateOfSince+'/' +this.state.dateOfUntil+'/' +this.state.stateOrderId+'/' +this.state.waypayId+ '/'+this.state.invoice ,
                                {
                                    headers: headers
                                })
                                .then(response => {
                                    const data = response.data.data;
                                    console.log(data);
                                    
                                    if(data?.length > 0){

                                    this.setState({
                                        items: data
                                    });
                                    this.state.items = data;
                                    if(this.state.order == 0){
                                        this.state.order = "";
                                   }
                                   if(this.state.documentNumber == 0){
                                       this.state.documentNumber = "";
                                  }
                                    closeModal();
                                    this.state.loading = false;
                                    helpers.hideLoadingOverlay();
                                }else{
                                    this.setState({
                                        items: data
                                    });
                                    this.state.items = data;
                                    console.log(this.state.items)
                                    if(this.state.order == 0){
                                        this.state.order = "";
                                   }
                                   if(this.state.documentNumber == 0){
                                       this.state.documentNumber = "";
                                   }
                                    //helpers.notifyWarning('Error','No se encontraron pedidos para mostrar'); 
                                }
                                })
                                .catch(function (error) {
                                    console.log(error);
                                    helpers.hideLoadingOverlay();
                                });
                            
                        }
                    }else{
                        //helpers.notifyWarning('Error','Seleccione la fecha Hasta');
                    }

                }else{
                    helpers.notifyWarning('Error','Seleccione la fecha Desde');
                }

                if(this.state.dateOfUntil?.length > 0){

                    if(this.state.dateOfSince?.length > 0){
                        if(this.state.invoice){
                        
                        }else{
                            this.state.invoice = null;
                        }
                        if(!this.state.order){
                            this.state.order = 0;
                        }
                        if(!this.state.countryId){
                            this.state.countryId = 0;
                        }
                        if(!this.state.stateOrderId){
                            this.state.stateOrderId = 0;
                        }
        
                        if(!this.state.documentNumber){
                            this.state.documentNumber = 0;
                        }
                        if(!this.state.waypayId){
                            this.state.waypayId = 0;
                        }
                        if(this.state.documentNumber?.length >0){
                            axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/shoppingcar/getclientbydocument/' + this.state.documentNumber, {
                                headers: headers
                            })
                            .then(response => {
                                const data = response.data.data;
                                console.log(data)

                                if(data.length > 0){
            
                                this.setState({
                                   documentNumberSearched: data.id
                                });
                                this.state.documentNumberSearched = data.id;
                                console.log(data.id)
                                console.log(this.state.documentNumberSearched)
                                axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/shoppingcar/filtrosventas/'+this.state.order+'/' +this.state.countryId+'/' +this.state.documentNumberSearched +'/'+this.state.dateOfSince+'/' +this.state.dateOfUntil+'/' +this.state.stateOrderId+'/' +this.state.waypayId+ '/'+this.state.invoice ,
                                {
                                    headers: headers
                                })
                                .then(response => {
                                    const data = response.data.data;
                                    console.log(data);

                                    if(data?.length > 0){

                                    this.setState({
                                        items: data
                                    });
                                    this.state.items = data;
                                    if(this.state.order == 0){
                                         this.state.order = "";
                                    }
                                    if(this.state.documentNumber == 0){
                                        this.state.documentNumber = "";
                                   }
                                    closeModal();
                                    this.state.loading = false;
                                    helpers.hideLoadingOverlay();
                                }else{
                                    this.setState({
                                        items: data
                                    });
                                    this.state.items = data;
                                    if(this.state.order == 0){
                                        this.state.order = "";
                                   }
                                   if(this.state.documentNumber == 0){
                                       this.state.documentNumber = "";
                                  }
                                   // helpers.notifyWarning('Error','No se encontraron pedidos para mostrar'); 
                                }
                                })
                                .catch(function (error) {
                                    console.log(error);
                                    helpers.hideLoadingOverlay();
                                });
                            }else{
                                helpers.notifyError('Error','No se encontro pedido asociado al Documento Ingresado');
                                 }
                            })
                            .catch(function (error) {
                                console.log(error);
                            }); 
                        }
                        else{
                            axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/shoppingcar/filtrosventas/'+this.state.order+'/' +this.state.countryId+'/' +this.state.documentNumber +'/'+this.state.dateOfSince+'/' +this.state.dateOfUntil+'/' +this.state.stateOrderId+'/' +this.state.waypayId+ '/'+this.state.invoice ,
                                {
                                    headers: headers
                                })
                                .then(response => {
                                    const data = response.data.data;
                                    console.log(data);
                                    
                                    if(data?.length > 0){
                                    this.setState({
                                        items: data
                                    });
                                    this.state.items = data;
                                    if(this.state.order == 0){
                                        this.state.order = "";
                                   }
                                   if(this.state.documentNumber == 0){
                                       this.state.documentNumber = "";
                                  }
                                    closeModal();
                                    this.state.loading = false;
                                    helpers.hideLoadingOverlay();
                                }else{
                                    
                                    helpers.notifyWarning('Error','No se encontraron pedidos para mostrar'); 
                                    this.setState({
                                        items: data
                                    });
                                    this.state.items = data;
                                    console.log(this.state.items)
                                    if(this.state.order == 0){
                                        this.state.order = "";
                                   }
                                   if(this.state.documentNumber == 0){
                                       this.state.documentNumber = "";
                                    }
                                }
                                })
                                .catch(function (error) {
                                    console.log(error);
                                    helpers.hideLoadingOverlay();
                                });
                            
                        }
                    }else{
                        //helpers.notifyWarning('Error','Seleccione la fecha Hasta');
                    }
                    
                }else{
                    helpers.notifyWarning('Error','Seleccione la fecha Hasta');
                }

                
        
                helpers.showLoadingOverlay();           
        }
        



    return (
    <div className="form-group col-md-1">
        <button className='btn btn-success' onClick={handleOpen}>
            <i className="btn btn-success mb-1" class="fas fa-filter" data-tougle="tooltip" data-placement="right"  onClick={handleOpen}></i>
        </button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
          <div className="mt-2">
                <ol className="breadcrumb my-4">
                    <li className="breadcrumb-item active">{this.state.resources.Label_addFilter}</li>
                </ol>
            </div>
                {/* <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <Typography id="modal-modal-title" variant="h6" component="h2" className='text-center'>
                    <th>{this.state.resources.Label_addFilter}</th>
                </Typography>
                </div> */}
                {/* <div className="text-center" style={{position: 'absolute', top: '50%', left: '50%'}}></div><div> */}
                <div className="row m-0">
                <div className="col-md-2 ">
                    <label className=" form-label">{this.state.resources.Label_Country} :</label>
                    <select className="form-control" data-val="true" name="countryid" value={this.state.countryId} onChange={onChangeCountryModal} >
                        <option value="">{this.state.resources.Label_SelectOption}</option>
                        {this.state.countries.map(country =>
                            <option key={country.id} value={country.id}>{country.name}</option>
                        )}
                    </select> 
                </div>
                <div className="col-md-2 ">
                <label className=" form-label">{this.state.resources.Label_Order} :</label>
                    <input className="form-control" type="text" maxLength="150" onChange={onChangeOrderModal} value={this.state.order}  name="order"   />
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                </div>
                <div className="col-md-2 ">
                    <label className=" form-label">{this.state.resources.Label_DocNumber} :</label>
                    <input className="form-control" type="text" maxLength="150" onChange={onChangeRutDocumentModal} value={this.state.documentNumber}  name="documentNumber"   />
                    <div id="errorDocument" style={{color: 'red',display:'none'}}>{helpers.getUiLanguage() == 'language1' ? 'RUT no valido' : 'invalid RUT number'}</div> 
                    </div>
                <div className="col-md-3 ">
                <label className=" form-label">{helpers.getUiLanguage() == 'language1' ? 'Desde :' : 'Since :'}</label>
                     <input type="date"  className="form-control col-md-12" name="dateofSince" id="fechaActual"  value={this.state.dateOfSince} defaultValue={this.state.dateOfSince} onChange={onChangeDateOfSinceModal} required/><div style={{position:'absolute',top:'0',right:'5px',color:'red'}}>*</div >
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                </div>
                <div className="col-md-3 ">
                <label className=" form-label">{helpers.getUiLanguage() == 'language1' ? 'Hasta :' : 'Until :'}</label>
                     <input type="date"  className="form-control col-md-12" name="dateOfUntil" id="fechaActual" value={this.state.dateOfUntil} defaultValue={this.state.dateOfUntil} onChange={onChangeDateOfUntilModal} required/><div style={{position:'absolute',top:'0',right:'5px',color:'red'}}>*</div >
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                </div>
                <div className="col-md-2 ">
                    <label className=" form-label">{this.state.resources.Label_Invoice} :</label>
                    <input className="form-control" type="text" maxLength="150" onChange={onChangeInvoiceModal} value={this.state.invoice}  name="invoice"   />
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div></div>
                <div className="col-md-2 ">
                    <label className=" form-label">{this.state.resources.Label_Status} :</label>
                    <select className="form-control" data-val="true" name="stateOrderId" value={this.state.stateOrderId} onChange={onChangeStateModal} >
                        <option value="">{this.state.resources.Label_SelectOption}</option>
                        {this.state.carStates.map(state =>
                            <option key={state.id} value={state.id}>{state.carstate}</option>
                        )}
                    </select> 
                </div>
                <div className="col-md-2 ">
                    <label className=" form-label">{this.state.resources.Label_WayPay} :</label>
                    <select className="form-control" data-val="true" name="wayPayId" value={this.state.waypayId} onChange={onChangeWaypayModal} >
                        <option value="">{this.state.resources.Label_SelectOption}</option>
                        {this.state.waypaies.map(waypay =>
                            <option key={waypay.id} value={waypay.id}>{waypay.waypay}</option>
                        )}
                    </select> 
                </div>
                <div className="col-md-3 "></div>
                <div className="col-md-3 " style={{marginTop:'3%', justifyContent: 'space-between' }}>
                <div className=" buttons-childs">
                        <button className="btn btn-primary mx-2"onClick={onSearchOrderModal}>{helpers.getUiLanguage() == 'language1' ? 'Enviar' : 'Send'}</button>
                        <div></div>
                        <button className="btn btn-danger" onClick={onClearModal}>{helpers.getUiLanguage() == 'language1' ? 'Limpiar' : 'Clear'}</button>
                    </div>
                    
                 </div>
                 </div>
                    
          </Box>
        </Modal>
      </div>
    );
  }