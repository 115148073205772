import React, { Component, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';  
import $ from 'jquery';
import DataTable from 'datatables.net';
import helpers from '../../Helpers';
import '../../custom.css';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/styles';
import { DataGrid, useGridApiRef, esES } from '@mui/x-data-grid';
import { DataGridPro } from '@mui/x-data-grid-pro';
import CircularProgress from '@mui/material/CircularProgress';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import {CSVLink} from 'react-csv'
import {CSVReader } from 'react-papaparse'
import Papa from "papaparse";

LicenseInfo.setLicenseKey(
  '97124f3444ae3cf552c73ef2f3487b08T1JERVI6MzEzNDQsRVhQSVJZPTE2NjY1MzkzOTkwMDAsS0VZVkVSU0lPTj0x',
);

export class ProductKeyList extends Component {
    static displayName = ProductKeyList.name;

    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);  
        this.state = {
            items: [],
            csvItems: [],
            resources: [],
            loading: true,
            permissionsIds: [],
            citiesList: [],
            columns: [],
            paging: null,
            sorting: null,
            filtering: null,
            importItems: [],
            token:"",
            userId: 0,
            importOK: 0,
            resultImport: [],
            mensajeOK: ""

        };

    }

    componentDidMount() {
        let userData = helpers.getUserData();
        this.state.permissionsIds = userData.permission_ids;
        this.state.userId = userData.id;
        this.getResources();
        this.populateData();
        helpers.setNavActive("#nav-map-courier-export");
    }

    showFile = async (e) => {
        e.preventDefault()
        const reader = new FileReader()
        reader.onload = async (e) => { 
          const text = (e.target.result)
          this.setState({ csvItems: JSON.parse(text) });
          console.log(text)
        };
        reader.readAsText(e.target.files[0])
      }

      onSubmit = async(e) => {
        if(this.state.csvItems.length == 0)
        {
            this.setState({ resultImport: [],  loading: false });
            helpers.notifyError(this.state.resources.Label_Error, "No se ingreso ningun archivo o el mismo no contiene datos");
        }
        else
        {
            this.setState({ loading: true });
            let data = this.state.csvItems;
            this.setState({ resultImport: data,  loading: false });
        }
    } 

      render() {
           let contents = this.state.loading
               ? helpers.showLoadingOverlay()
               : <></> //<MapCourierCityDataGrid data={this.state} />
              //MapCourierCityList.renderTable(this.state.items, this.state.resources, this.state.permissionsIds, this.state.citiesList);

          return (
              <div className="mt-2">
                  <ol className="breadcrumb my-4">
                      <li className="breadcrumb-item active">{this.state.resources.NavItem_ProductKeyList}</li>
                  </ol>
                  <div className="card">
                      <div className="card-header">
                      <input type="file" className="form-control"  accept={".json"} onChange={(e) => this.showFile(e)} />
                      {/* &nbsp; &nbsp; 
                      {this.state.permissionsIds.includes(136) ?
                      <CSVLink data={this.state.csvItems} filename="MAPA_DESTINOS.csv"><button className="btn btn-success">{this.state.resources.Label_Export}</button></CSVLink>
                      : <></>} */}
                     <button className="btn btn-success"
             onClick={(e) => this.onSubmit(e)}
           >
             Cargar
           </button>
                        </div>
                      
                      <div className="card-body">
                          <div className="table-responsive">
                            <div className='row'>
                              {this.state.resultImport.length > 0
                              ?  this.state.resultImport.map(item =>
                                <div className="col-md-3">
                                   <ul>
                                   <li style={{listStyle:'none'}}>
                                   <div id="file-preview-zone" style={{ width: '100px' , height: '100px' }}>
                                        {item.Imagen_Producto !== "" ? 
                                        <img id="file-preview" src={'data:image/png;base64,' + item.Imagen_Producto} style={{ height: '150px' }}/>
                                         : <></>}
                                    </div>
                                   </li>
                                   <br/><br/>
                                   <li style={{listStyle:'none'}}><h6>{item.SKU_Clave}</h6></li>
                                   <li style={{listStyle:'none'}}><h6>{item.Presentacion_Clave}</h6></li>
                                   <li style={{listStyle:'none'}}><h6>${item.Precio_Clave.toLocaleString('en-US')}</h6></li>
                                    </ul>
                                </div>

                              ) : <></>}                                
                              </div>

                          </div>
                      </div>
                  </div>
              </div>
        );
      }

    async populateData() {
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/productcountrykey/gettest',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {

                const data = response.data.data;
                this.setState({ items: data, loading: false });
                console.log(data);
                /*this.state.columns = [
                    {
                        field: 'actions',
                        headerAlign: "center",
                        headerClassName: "header-table-mui",
                        headerName: this.state.resources.Label_Actions ? this.state.resources.Label_Actions : 'Actions',
                        flex: 1,
                        minWidth: 140,
                        renderCell: (item) => {
                        }
                    },
                ];*/
                helpers.hideLoadingOverlay();
            })
            .catch(function (error) {
                console.log(error);
                helpers.hideLoadingOverlay();
            });
            /*axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/mapcouriercity/getallcsv',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {

                const data = response.data.data;
                console.log(data);
                this.setState({ csvItems: data, loading: false });
                helpers.hideLoadingOverlay();
            })
            .catch(function (error) {
                console.log(error);
                helpers.hideLoadingOverlay();
            });
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/city/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ citiesList: data, loading: false });
            })
            .catch(function (error) {
                console.log(error);
            });*/
    }

    async getResources() {
        const resourceVars = [];
        resourceVars.push("Title_MapCourierCity");
        resourceVars.push("Title_Courier");
        resourceVars.push("Title_City");
        resourceVars.push("Title_State");
        resourceVars.push("Label_ID");
        resourceVars.push("Label_Name");
        resourceVars.push("Label_Description");
        resourceVars.push("Label_Actions");
        resourceVars.push("Label_Status");
        resourceVars.push("Label_Enabled");
        resourceVars.push("Label_Disabled");
        resourceVars.push("ButtonLabel_ViewEdit");
        resourceVars.push("ButtonLabel_New");
        resourceVars.push("Datatables_language_lengthMenu");
        resourceVars.push("Datatables_language_zeroRecords");
        resourceVars.push("Datatables_language_info");
        resourceVars.push("Datatables_language_infoEmpty");
        resourceVars.push("Datatables_language_infoFiltered");
        resourceVars.push("Datatables_language_search");
        resourceVars.push("Datatables_language_paginate_previous");
        resourceVars.push("Datatables_language_paginate_next");
        resourceVars.push("Label_DeliveryTime");
        resourceVars.push("Label_PayMethod");
        resourceVars.push("Label_Limit");
        resourceVars.push("Label_RouteType");
        resourceVars.push("Title_Courier");
        resourceVars.push("Label_Country");
        resourceVars.push("Title_City");
        resourceVars.push("Title_State");
        resourceVars.push("Label_SelectOption");
        resourceVars.push("Label_Options");
        resourceVars.push("Label_Cities");
        resourceVars.push("Label_All");
        resourceVars.push("Label_LookCities");
        resourceVars.push("Label_DeleteRoute");
        resourceVars.push("ButtonLabel_Cancel");
        resourceVars.push("Label_Yes");
        resourceVars.push("SuccessMessage_DataSavedSuccessfully");
        resourceVars.push("Label_Success");
        resourceVars.push("Label_FilterOff");
        resourceVars.push("Label_Filter");
        resourceVars.push("Label_Sort");
        resourceVars.push("Label_Hide");
        resourceVars.push("Label_ShowColumns");
        resourceVars.push("Label_OrderAsc");
        resourceVars.push("Label_OrderDesc");
        resourceVars.push("Label_Unsorting");
        resourceVars.push("Label_Columns");
        resourceVars.push("Label_Delete");
        resourceVars.push("Label_GridValue");
        resourceVars.push("Label_ValueFilter");
        resourceVars.push("Label_And");
        resourceVars.push("Label_Or");
        resourceVars.push("Label_Operator");
        resourceVars.push("Label_NoRows");
        resourceVars.push("Label_SelectColumns");
        resourceVars.push("Label_OperatorAfter");
        resourceVars.push("Label_OperatorBefore");
        resourceVars.push("Label_OperatorContains");
        resourceVars.push("Label_OperatorEnds");
        resourceVars.push("Label_OperatorEquals");
        resourceVars.push("Label_OperatorIs");
        resourceVars.push("Label_OperatorIsEmpty");
        resourceVars.push("Label_OperatorIsNotEmpty");
        resourceVars.push("Label_OperatorIsNot");
        resourceVars.push("Label_OperatorIsOnOrAfter");
        resourceVars.push("Label_OperatorIsOnOrBefore");
        resourceVars.push("Label_OperatorStarts");
        resourceVars.push("Label_ReorderColumns");
        resourceVars.push("Label_HideAll");
        resourceVars.push("Label_ShowAll");
        resourceVars.push("Label_FindColumn");
        resourceVars.push("Label_TitleColumn");
        resourceVars.push("Label_addFilter");
        resourceVars.push("Label_Export");
        resourceVars.push("Title_ExportRoutes");
        resourceVars.push("NavItem_ProductKeyList");
        resourceVars.push("Label_Error");
        const headers = {
            'Content-Type': 'application/json'
        };

        axios.post(window.config.REACT_APP_SECURITY_API_URL + '/languageresource/getbyvars/' + helpers.getUiLanguage(), resourceVars, {
            headers: headers
        })
            .then((response) => {
                const data = response.data.data;
                this.setState({ resources: data});
            })
            .catch((error) => {
                console.log(error);
            });
    }
}