import React, { Component } from 'react';
import axios from 'axios';
import helpers from '../../Helpers';
import $ from 'jquery';

export class Profile extends Component {
    static displayName = Profile.name;

    constructor(props) {
        super(props);

        this.onChangePhone = this.onChangePhone.bind(this);
        this.onChangeMobilePhone = this.onChangeMobilePhone.bind(this);
        this.onChangeWorkEmail = this.onChangeWorkEmail.bind(this);
        this.onChangePersonalEmail = this.onChangePersonalEmail.bind(this);
        this.onChangeAddress = this.onChangeAddress.bind(this);
        this.onChangeCountry = this.onChangeCountry.bind(this);
        this.onChangeStateOrProvince = this.onChangeStateOrProvince.bind(this);
        this.onChangeTown = this.onChangeTown.bind(this);
        this.onChangePostalCode = this.onChangePostalCode.bind(this);
        this.onChangeLanguageResource = this.onChangeLanguageResource.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

        this.state = {
            id: 0,
            lastName: null,
            firstName: null,
            docNumber: null,
            dateOfBirth: null,
            phone: null,
            mobilePhone: null,
            workEmail: null,
            personalEmail: null,
            address: null,
            country: null,
            stateOrProvince: null,
            town: null,
            postalCode: null,
            userLdap: null,
            passwordLdap: null,
            permissionGroupId: null,
            permissionGroupName: null,
            workScheduleId: null,
            workScheduleName: null,
            workShiftId: null,
            workShiftName: null,
            areaId: null,
            areaName: null,
            subAreaId: null,
            subAreaName: null,
            pbxId: null,
            languageResource: null,
            companyIds: [],
            employerCompanyId: null,
            employerCompanyName: null,
            admissionDate: null,
            loading: true,
            companies: [],
            resources: []
        };
    }

    componentDidMount() {
        this.getResources();
        this.populateData();
        helpers.setClientValidation();
    }

    async onSubmit(e) {
        e.preventDefault();

        var form = document.querySelectorAll('.needs-validation')[0];

        if (!form.checkValidity())
            return;

        this.setState({ loading: true });

        const data = {
            id: parseInt(this.state.id),
            phone: this.state.phone,
            mobile_phone: this.state.mobilePhone,
            work_email: this.state.workEmail,
            personal_email: this.state.personalEmail,
            address: this.state.address,
            country: this.state.country,
            state_or_province: this.state.stateOrProvince,
            town: this.state.town,
            postal_code: this.state.postalCode,
            language_resource: this.state.languageResource
        };
        const headers = {
            'Content-Type': 'application/json'
        };

        axios.post(window.config.REACT_APP_SECURITY_API_URL + '/user/profile/save', data, {
            headers: headers
        })
            .then((response) => {
                helpers.notifySuccess(this.state.resources.Label_Success, this.state.resources.SuccessMessage_DataSavedSuccessfully);
                this.setState({ loading: false });
            })
            .catch((error) => {
                console.log(error);

                let msg = this.state.resources.ErrorMessage_UnknownError;

                helpers.notifyError(this.state.resources.Label_Error, msg);
                this.setState({ loading: false });
            });
    }

    onChangePhone(e) {
        this.setState({
            phone: e.target.value
        });
    }
    onChangeMobilePhone(e) {
        this.setState({
            mobilePhone: e.target.value
        });
    }
    onChangeWorkEmail(e) {
        this.setState({
            workEmail: e.target.value
        });
    }
    onChangePersonalEmail(e) {
        this.setState({
            personalEmail: e.target.value
        });
    }
    onChangeAddress(e) {
        this.setState({
            address: e.target.value
        });
    }
    onChangeCountry(e) {
        this.setState({
            country: e.target.value
        });
    }
    onChangeStateOrProvince(e) {
        this.setState({
            stateOrProvince: e.target.value
        });
    }
    onChangeTown(e) {
        this.setState({
            town: e.target.value
        });
    }
    onChangePostalCode(e) {
        this.setState({
            postalCode: e.target.value
        });
    }
    onChangeLanguageResource(e) {
        this.setState({
            languageResource: e.target.value
        });
    }

    changePassword = () => () => {
        this.props.history.push('/profile-passwordchange');
    }

    render() {
        let contents =
            <form className="form row g-3 needs-validation" onSubmit={this.onSubmit} noValidate>
                <div className="form-group col-md-12">
                    <input type="hidden" name="id" value={this.state.id} />
                </div>

                {/*LAST NAME / FIRST NAME*/}
                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_LastName}</label>
                    <input className="form-control" type="text" name="lastname" value={this.state.lastName} disabled />
                </div>
                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_FirstName}</label>
                    <input className="form-control" type="text" name="firstname" value={this.state.firstName} disabled />
                </div>

                {/*DOC NUMBER / DATE OF BIRTH*/}
                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_DocNumber}</label>
                    <input className="form-control" type="text" name="docnumber" value={this.state.docNumber} disabled />
                </div>
                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_DateOfBirth}</label>
                    <input className="form-control" type="text" name="dateofbirth" value={this.state.dateOfBirth} disabled />
                </div>

                {/*PHONE / MOBILE PHONE*/}
                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_Phone}</label>
                    <input className="form-control" type="text" name="phone" onChange={this.onChangePhone} value={this.state.mobilePhone} />
                </div>
                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_MobilePhone}</label>
                    <input className="form-control" type="text" name="mobilephone" onChange={this.onChangeMobilePhone} value={this.state.mobilePhone} />
                </div>

                {/*WORK EMAIL / PERSONAL EMAIL*/}
                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_WorkEmail}</label>
                    <input className="form-control" type="email" name="workemail" onChange={this.onChangeWorkEmail} value={this.state.workEmail} />
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_InvalidEmail}</div>
                </div>
                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_PersonalEmail}</label>
                    <input className="form-control" type="email" name="personalemail" onChange={this.onChangePersonalEmail} value={this.state.personalEmail} />
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_InvalidEmail}</div>
                </div>

                {/*ADDRESS / COUNTRY*/}
                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_Address}</label>
                    <input className="form-control" type="text" name="address" onChange={this.onChangeAddress} value={this.state.address} />
                </div>
                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_Country}</label>
                    <input className="form-control" type="text" name="country" onChange={this.onChangeCountry} value={this.state.country} />
                </div>

                {/*STATE OR PROVINCE / TOWN*/}
                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_StateOrProvince}</label>
                    <input className="form-control" type="text" name="stateorprovince" onChange={this.onChangeStateOrProvince} value={this.state.stateOrProvince} />
                </div>
                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_Town}</label>
                    <input className="form-control" type="text" name="town" onChange={this.onChangeTown} value={this.state.town} />
                </div>

                {/*ZIP CODE / */}
                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_PostalCode}</label>
                    <input className="form-control" type="text" name="postalcode" onChange={this.onChangePostalCode} value={this.state.postalCode} />
                </div>
                <div className="form-group col-md-6" >
                </div>

                {/*USER LDAP / PASSWORD LDAP*/}
                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_UserLdap}</label>
                    <input className="form-control" type="text" name="userldap" onChange={this.onChangeUserLdap} value={this.state.userLdap} disabled />
                </div>
                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_PasswordLdap}</label>
                    <div className="input-group">
                        <button onClick={this.changePassword()} className="btn btn-outline-secondary" type="button" id="button-changepassword">{this.state.resources.ButtonLabel_Change}</button>
                        <input type="password" className="form-control" value={this.state.passwordLdap} aria-label={this.state.resources.ButtonLabel_Change} aria-describedby="button-changepassword" readOnly />
                    </div>
                </div>

                {/*PERMISSION GROUP / WORK SCHEDULE*/}
                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_PermissionGroup}</label>
                    <input className="form-control" type="text" name="permissiongroup" value={this.state.permissionGroupName} disabled />
                </div>
                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_WorkSchedule}</label>
                    <input className="form-control" type="text" name="workschedule" value={this.state.workScheduleName} disabled />
                </div>

                {/*WORK SHIFT / AREA*/}
                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_WorkShift}</label>
                    <input className="form-control" type="text" name="workshift" value={this.state.workShiftName} disabled />
                </div>
                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_Area}</label>
                    <input className="form-control" type="text" name="area" value={this.state.areaName} disabled />
                </div>

                {/*SUB AREA / PBX ID*/}
                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_SubArea}</label>
                    <input className="form-control" type="text" name="subarea" value={this.state.subAreaName} disabled />
                </div>
                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_PbxId}</label>
                    <input className="form-control" type="number" name="pbxid" value={this.state.pbxId} disabled />
                </div>

                {/*LANGUAGE / */}
                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_Language}</label>
                    <select className="form-control" data-val="true" name="language" defaultValue={this.state.language} onChange={this.onChangeLanguageResource} required>
                        <option value="">{this.state.resources.Label_SelectOption}</option>
                        <option value="language0" selected={this.state.languageResource == 'language0'}>{this.state.resources.Label_Language0}</option>
                        <option value="language1" selected={this.state.languageResource == 'language1'}>{this.state.resources.Label_Language1}</option>
                        <option value="language2" selected={this.state.languageResource == 'language2'}>{this.state.resources.Label_Language2}</option>
                        <option value="language3" selected={this.state.languageResource == 'language3'}>{this.state.resources.Label_Language3}</option>
                        <option value="language4" selected={this.state.languageResource == 'language4'}>{this.state.resources.Label_Language4}</option>
                        <option value="language5" selected={this.state.languageResource == 'language5'}>{this.state.resources.Label_Language5}</option>
                        <option value="language6" selected={this.state.languageResource == 'language6'}>{this.state.resources.Label_Language6}</option>
                        <option value="language7" selected={this.state.languageResource == 'language7'}>{this.state.resources.Label_Language7}</option>
                        <option value="language8" selected={this.state.languageResource == 'language8'}>{this.state.resources.Label_Language8}</option>
                        <option value="language9" selected={this.state.languageResource == 'language9'}>{this.state.resources.Label_Language9}</option>
                    </select>
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                </div>
                <div className="form-group col-md-6" >
                </div>

                {/*COMPANIES*/}
                <div className="form-group col-md-12" >
                    <label className=" form-label">{this.state.resources.Label_Companies}</label>
                    <div className="col-md-12">
                        {this.state.companies.map(company =>
                            <div key={company.id} className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" checked={this.state.companyIds.indexOf(company.id) > -1} id={'company' + company.id} defaultValue={company.id} disabled />
                                <label className="form-check-label" htmlFor={'company' + company.id}>{company.name}</label>
                            </div>
                        )}
                    </div>
                </div>

                {/*EMPLOYER COMPANY / ADMISSION DATE*/}
                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_EmployerCompany}</label>
                    <input className="form-control" type="text" name="employercompany" value={this.state.employerCompanyName} disabled />
                </div>
                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_AdmissionDate}</label>
                    <input className="form-control" type="text" name="admissiondate" value={this.state.admissionDate} disabled />
                </div>

                {/*TEAM LEADER USER / SUPERVISOR USER*/}
                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_TeamLeader}</label>
                    <input className="form-control" type="text" name="teamleader" value={this.state.teamLeaderUserFullName} disabled />
                </div>
                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_Supervisor}</label>
                    <input className="form-control" type="text" name="supervisor" value={this.state.supervisorUserFullName} disabled />
                </div>

                {/*SAVE*/}
                <div className="form-group col-12 text-end">
                    <button type="submit" className="btn btn-primary">{this.state.resources.ButtonLabel_Save}</button>
                </div>

            </form>;

        return (
            <div className="mt-2">
                <h1 className="mt-4">{this.state.resources.Title_Profile}</h1>
                <ol className="breadcrumb mb-4">
                    <li className="breadcrumb-item active">{this.state.resources.Title_Profile}</li>
                </ol>
                <div className="card">
                    <div className="card-body">
                        {this.state.loading ? helpers.showLoadingOverlay() : helpers.hideLoadingOverlay()}
                        {contents}
                    </div>
                </div>
            </div>
        );
    }

    async getResources() {
        const resourceVars = [];
        resourceVars.push("Title_Profile");
        resourceVars.push("Label_ID");
        resourceVars.push("Label_FirstName");
        resourceVars.push("Label_LastName");
        resourceVars.push("Label_DocNumber");
        resourceVars.push("Label_DateOfBirth");
        resourceVars.push("Label_Phone");
        resourceVars.push("Label_MobilePhone");
        resourceVars.push("Label_Area");
        resourceVars.push("Label_SubArea");
        resourceVars.push("Label_PbxId");
        resourceVars.push("Label_WorkEmail");
        resourceVars.push("Label_PersonalEmail");
        resourceVars.push("Label_Address");
        resourceVars.push("Label_Country");
        resourceVars.push("Label_StateOrProvince");
        resourceVars.push("Label_Town");
        resourceVars.push("Label_PostalCode");
        resourceVars.push("Label_UserLdap");
        resourceVars.push("Label_PasswordLdap");
        resourceVars.push("Label_PermissionGroup");
        resourceVars.push("Label_WorkSchedule");
        resourceVars.push("Label_WorkShift");
        resourceVars.push("Label_Language");
        resourceVars.push("Label_Companies");
        resourceVars.push("Label_EmployerCompany");
        resourceVars.push("Label_AdmissionDate");
        resourceVars.push("Label_TeamLeader");
        resourceVars.push("Label_Supervisor");
        resourceVars.push("Label_Success");
        resourceVars.push("Label_Error");
        resourceVars.push("Label_SelectOption");
        resourceVars.push("Label_Language0");
        resourceVars.push("Label_Language1");
        resourceVars.push("Label_Language2");
        resourceVars.push("Label_Language3");
        resourceVars.push("Label_Language4");
        resourceVars.push("Label_Language5");
        resourceVars.push("Label_Language6");
        resourceVars.push("Label_Language7");
        resourceVars.push("Label_Language8");
        resourceVars.push("Label_Language9");
        resourceVars.push("ButtonLabel_Save");
        resourceVars.push("ButtonLabel_Change");
        resourceVars.push("ErrorMessage_RequiredField");
        resourceVars.push("ErrorMessage_UnknownError");
        resourceVars.push("ErrorMessage_InvalidEmail");
        resourceVars.push("SuccessMessage_DataSavedSuccessfully");

        const headers = {
            'Content-Type': 'application/json'
        };

        axios.post(window.config.REACT_APP_SECURITY_API_URL + '/languageresource/getbyvars/' + helpers.getUiLanguage(), resourceVars, {
            headers: headers
        })
            .then((response) => {
                const data = response.data.data;
                this.setState({ resources: data, loading: false });
            })
            .catch((error) => {
                console.log(error);
                this.setState({ loading: false });
            });
    }

    async populateData() {
        this.setState({
            loading: false
        });

        let userData = helpers.getUserData();

        // get companies
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/company/get', { })
            .then(response => {
                const data = response.data.data;
                this.setState({ companies: data, loading: false });
            })
            .catch(function (error) {
                console.log(error);
            });

        axios.get(window.config.REACT_APP_SECURITY_API_URL + '/user/profile/get/' + userData.id, { })
            .then(response => {
                const data = response.data.data;
                this.setState({
                    id: data.id,
                    lastName: data.last_name,
                    firstName: data.first_name,
                    docNumber: data.doc_number,
                    dateOfBirth: data.date_of_birth === null ? null : helpers.getDisplayDate(data.date_of_birth),
                    phone: data.phone,
                    mobilePhone: data.mobile_phone,
                    workEmail: data.work_email,
                    personalEmail: data.personal_email,
                    address: data.address,
                    country: data.country,
                    stateOrProvince: data.state_or_province,
                    town: data.town,
                    postalCode: data.postal_code,
                    userLdap: data.user_ldap,
                    passwordLdap: data.password_ldap,
                    permissionGroupId: data.permission_group_id,
                    permissionGroupName: data.permission_group_name,
                    workScheduleId: data.work_schedule_id,
                    workScheduleName: data.work_schedule_name,
                    workShiftId: data.work_shift_id,
                    workShiftName: data.work_shift_name,
                    areaId: data.area_id,
                    areaName: data.area_name,
                    subAreaId: data.sub_area_id,
                    subAreaName: data.sub_area_name,
                    pbxId: data.pbx_id,
                    languageResource: data.language_resource,
                    companyIds: data.company_ids,
                    employerCompanyId: data.employer_company_id,
                    employerCompanyName: data.employer_company_name,
                    enabled: data.enabled,
                    teamLeaderUserId: data.team_leader_user_id,
                    teamLeaderUserFullName: data.team_leader_user_fullname == null ? '' : data.team_leader_user_fullname,
                    supervisorUserId: data.supervisor_user_id,
                    supervisorUserFullName: data.supervisor_user_fullname == null ? '' : data.supervisor_user_fullname,
                    admissionDate: helpers.getDisplayDate(data.admission_date),
                    loading: false
                });
            })
            .catch(function (error) {
                console.log(error);
                this.setState({ loading: false });
            })  
    }
}
