import React, { Component } from 'react';
import axios from 'axios';
import $ from 'jquery';
import helpers from '../../Helpers';
import DataCreation from '../DataCreation/DataCreation';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

export class CampaingDetail extends Component {
    static displayName = CampaingDetail.name;

    constructor(props) {
        super(props);

        this.onChangeName = this.onChangeName.bind(this);
        this.onChangeCountry = this.onChangeCountry.bind(this);
        this.onChangeCampaingType = this.onChangeCampaingType.bind(this);
        this.onChangeAgency = this.onChangeAgency.bind(this);
        this.onChangeBussiness = this.onChangeBussiness.bind(this);
        this.onChangeMedia = this.onChangeMedia.bind(this);
        this.onChangeFormat = this.onChangeFormat.bind(this);
        this.onChangeCourier = this.onChangeCourier.bind(this);
        this.onChangeSupplier = this.onChangeSupplier.bind(this);
        this.onChangeProduct = this.onChangeProduct.bind(this);
        this.onChangeDid = this.onChangeDid.bind(this);
        this.onChangePublicity = this.onChangePublicity.bind(this);
        this.onChangePublicityModal = this.onChangePublicityModal.bind(this);
        this.onChangePbxDatabase = this.onChangePbxDatabase.bind(this);
        this.onChangeUrlLanding = this.onChangeUrlLanding.bind(this);
        this.onChangeManualLoading = this.onChangeManualLoading.bind(this);
        this.onChangeCampaingRecalculation = this.onChangeCampaingRecalculation.bind(this);
        this.onChangeEnabled = this.onChangeEnabled.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.setCampaingType = this.setCampaingType.bind(this);
        this.onChangeDomain = this.onChangeDomain.bind(this);
        AddPublicity = AddPublicity.bind(this);

        this.state = {
            id: null,
            name: '',
            country_id: null,
            countries: [],
            campaings: [],
            campaings_id: null,
            campaingtype_id: null,
            campaingtypes: [],
            agency_id: null,
            agencies: [],
            actualCampaings: [],
            bussiness_id: null,
            bussiness: [],
            media_id: null,
            media_name: '',
            medias: [],
            format_id: null,
            formats: [],
            courier_id: null,
            couriers: [],
            supplier_id: null,
            suppliers: [],
            product_id: null,
            products: [],
            did_id: null,
            dids: [],
            publicity_id: null,
            publicitys:[],
            usedPublicities:[],
            pbxdatabase_id: null,
            pbxdatabases: [],
            url_landing: '',
            manual_loading: '',
            campaing_recalculation: null,
            enabled: true,
            loading: true,
            creation: '',
            creationUser: '',
            lastUpdate: '',
            lastUpdateUser: '',
            resources: [],
            viewMode: false,
            currentUserId: 0,
            domains: [],
            domain_id: 0,
            domain_name: '',
            usedDids: [],
            product_countries: [],
            publicity: '',
            countryId: null,
            productCountryId: null,
            loadingModal: false
        };
        
    }

    componentDidMount() {
        this.getResources();
        this.populateData();
        helpers.setNavActive("#nav-campaing");
        helpers.setClientValidation();

        let url = window.location.href;
        if (url.includes('view')) {
            this.setState({
                viewMode: true
            });
        }

        let currentUser = helpers.getUserData();
        this.setState({
            currentUserId: currentUser.id
        });

        document.querySelector('#form1').style.display = 'none';
        document.querySelector('#form2').style.display = 'none';
        document.querySelector('#form3').style.display = 'none';
        if (this.state.campaingtype_id == 2 || this.state.campaingtype_id == 3 || this.state.campaingtype_id == 5) {
            document.querySelector('#form1').style.display = 'block';
            document.querySelector('#form2').style.display = 'none';
            document.querySelector('#form3').style.display = 'none';
        } else if (this.state.campaingtype_id == 4) {
            document.querySelector('#form2').style.display = 'block';
            document.querySelector('#form1').style.display = 'none';
            document.querySelector('#form3').style.display = 'none';
        } else if (this.state.campaingtype_id == 6) {
            document.querySelector('#form3').style.display = 'block';
            document.querySelector('#form1').style.display = 'none';
            document.querySelector('#form2').style.display = 'none';
        } else {
            document.querySelector('#form1').style.display = 'none';
            document.querySelector('#form2').style.display = 'none';
            document.querySelector('#form3').style.display = 'none';
        }

        let sort = JSON.parse(localStorage.getItem('CampaingListSorting'))
        localStorage.setItem('CopyCampaingListSorting', JSON.stringify(sort));

        let paging = JSON.parse(localStorage.getItem('CampaingListPagination'))
        localStorage.setItem('CopyCampaingListPaging', JSON.stringify(paging));

        let filtering = JSON.parse(localStorage.getItem('CampaingListFiltering'))
        localStorage.setItem('CopyCampaingListFiltering', JSON.stringify(filtering));
    }

    async onSubmit(e) {
        e.preventDefault();
        // var form = document.querySelectorAll('.needs-validation')[0];

        // if (!form.checkValidity())
        //     return;
        if (this.state.name == '' || this.state.campaingtype_id == 0) {
            helpers.notifyWarning('Error','Todos los campos son requeridos');
            return;
        } else if ((this.state.campaingtype_id == 4 || this.state.campaingtype_id == 6) && this.state.domain_id == 0 ) {
            helpers.notifyWarning('Error','Seleccione un tipo de Dominio');
            return;
        } else if (this.state.campaingtype_id == 6 && this.state.domain_id == 0) {
                helpers.notifyWarning('Error','Seleccione un tipo de Dominio');
                return;
        } else if (this.state.campaingtype_id == 4 && this.state.did_id == null ) {
            helpers.notifyWarning('Error','El Campo DID es Obligatorio');
                return;
        } else if (this.state.campaingtype_id == 2 && this.state.did_id == null) {
            helpers.notifyWarning('Error','El Campo DID es Obligatorio');
                return;
        }else if (this.state.campaingtype_id == 3 && this.state.pbxdatabase_id == null) {
            helpers.notifyWarning('Error','El Campo PBX es Obligatorio');
                return;
        }else if (this.state.campaingtype_id == 6 && this.state.pbxdatabase_id == null) {
            helpers.notifyWarning('Error','El Campo PBX es Obligatorio');
                return;
        }else {
        
            this.setState({ loading: true });

            const data = {
                id: parseInt(this.props.match.params.id),
                name: this.state.name,
                country_id: this.state.country_id,
                campaingtype_id: this.state.campaingtype_id,
                agency_id: this.state.agency_id,
                bussiness_id: this.state.bussiness_id,
                media_id: this.state.media_id,
                format_id: this.state.format_id,
                courier_id: this.state.courier_id,
                supplier_id: this.state.supplier_id,
                product_id: this.state.product_id,
                did_id: this.state.did_id,
                pbxdatabase_id: this.state.pbxdatabase_id,
                url_landing: this.state.url_landing,
                manual_loading: this.state.manual_loading,
                campaing_recalculation: this.state.campaing_recalculation,
                enabled: this.state.enabled,
                last_update_user_id: this.state.currentUserId,
                domain_id: this.state.domain_id,
                media_name: this.state.media_name,
                domain_name: this.state.domain_name,
                publish_id:this.state.publicity_id,
            };

            console.log(data);

            if(data.campaingtype_id == 2){
                data.domain_id = null;
                data.domain_name = "";
                data.url_landing = "";
                data.pbxdatabase_id= null;
            }else if(data.campaingtype_id == 3){
                data.did_id= null;
                data.domain_id= "";
                data.domain_name= "";
                data.url_landing = "";
            }else if(data.campaingtype_id == 4){
                data.pbxdatabase_id= null;
            }else if(data.campaingtype_id == 5){
                data.domain_id = null;
                data.domain_name = "";
                data.url_landing = "";
                data.pbxdatabase_id= null; 
                data.did_id= null;
            }else if(data.campaingtype_id == 6){
                data.did_id= null;
            }
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME)
            };

            axios.post(window.config.REACT_APP_PRODUCTS_API_URL + '/campaing/save', data, {
                headers: headers
            })
                .then((response) => {
                    helpers.notifySuccess(this.state.resources.Label_Success, this.state.resources.SuccessMessage_DataSavedSuccessfully);
                    this.props.history.push('/campaing');
                })
                .catch((error) => {
                    console.log(error);

                    let msg = this.state.resources.ErrorMessage_UnknownError;

                    helpers.notifyError(this.state.resources.Label_Error, msg);
                    this.setState({ loading: false });
                });
        }

    }  

    onCancel(e) {
        e.preventDefault();
        this.props.history.push("/campaing");
    }

    onChangeName(e) {
        this.setState({
            name: e.target.value
        });
    }
    
    onChangeCountry(e) {
        this.setState({
            country_id: e.target.value,
        });
    }

    onChangePublicity(e) {
        this.setState({
            publicity_id: e.target.value
        });
        console.log(e.target.value)
    }

    onChangePublicityModal(e) {
        this.setState({
            publicity: e.target.value
        });
    }

    onChangeCampaingType(e) {
        this.setState({
            campaingtype_id: e.target.value
        });
        
        
        if (e.target.value == 2 || e.target.value == 3 || e.target.value == 5) {
            document.querySelector('#form1').style.display = 'block';
            document.querySelector('#form2').style.display = 'none';
            document.querySelector('#form3').style.display = 'none';
        } else if (e.target.value == 4) {
            document.querySelector('#form1').style.display = 'none';
            document.querySelector('#form2').style.display = 'block';
            document.querySelector('#form3').style.display = 'none';       
        } else if (e.target.value == 6) {
            document.querySelector('#form1').style.display = 'none';
            document.querySelector('#form2').style.display = 'none';
            document.querySelector('#form3').style.display = 'block';
        }
    }

    onChangeAgency(e) {
        this.setState({
            agency_id: e.target.value,
        });
    }

    onChangeBussiness(e) {
        this.setState({
            bussiness_id: e.target.value
        });
    }

    onChangeMedia(e) {
        if (e.target.value.includes(';')) {
            let data = e.target.value.split(';');
            // console.log(data);
            this.setState({
                media_id: data[0],
                media_name: data[1],
            });
        } else {
            this.setState({
                media_id: e.target.value,
            });
        }
    }

    onChangeFormat(e) {
        this.setState({
            format_id: e.target.value
        });
    }

    onChangeCourier(e) {
        this.setState({
            courier_id: e.target.value
        });
    }

    onChangeSupplier(e) {
        this.setState({
            supplier_id: e.target.value
        });
    }

    onChangeProduct(e) {
        this.setState({
            product_id: e.target.value
        });
        console.log(e.target.value)
    }

    onChangeDid(e) {
        this.setState({
            did_id: e.target.value
        });
    }

    onChangePbxDatabase(e) {
        this.setState({
            pbxdatabase_id: e.target.value
        });
    }

    onChangeUrlLanding(e) {
        this.setState({
            url_landing: e.target.value
        });
    }

    onChangeManualLoading(e) {
        this.setState({
            manual_loading: e.target.value
        });
    }

    onChangeCampaingRecalculation(e) {
        this.setState({
            campaing_recalculation: e.target.value
        });
    }

    onChangeEnabled(e) {
        this.setState({
            enabled: e.target.checked
        });
    }
    
    onChangeDomain(e) {
        if (e.target.value.includes(';')) {
            let data = e.target.value.split(';');
            this.setState({
                domain_id: data[0],
                domain_name: data[1],
            });
        } else {
            this.setState({
                domain_id: e.target.value,
            });
        }
    }

    setCampaingType(id) {
        this.setState({
            campaingtype_id: id
        });
        
        
        if (id == 2 || id == 3 || id == 5) {
            document.querySelector('#form1').style.display = 'block';
            document.querySelector('#form2').style.display = 'none';
            document.querySelector('#form3').style.display = 'none';
        } else if (id == 4) {
            document.querySelector('#form1').style.display = 'none';
            document.querySelector('#form2').style.display = 'block';
            document.querySelector('#form3').style.display = 'none';
        } else if (id == 6) {
            document.querySelector('#form1').style.display = 'none';
            document.querySelector('#form2').style.display = 'none';
            document.querySelector('#form3').style.display = 'block';
        }
    }

    render() {
        let contents =
            <div className="form row g-3 needs-validation">
                <div className="form-group col-md-8">
                    <div className="form-check form-switch">
                        <input disabled={this.state.viewMode} className="form-check-input" type="checkbox" id="enabled" checked={this.state.enabled} onChange={this.onChangeEnabled} />
                        <label className="form-check-label" htmlFor="enabled">{this.state.resources.Label_Enabled}</label>
                        {/* <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div> */}
                    </div>

                    
                </div>
                <div className="form-group col-md-4">
                    <label className=" form-label">{this.state.resources.Title_CampaingType}</label>
                    <select disabled={this.state.viewMode} className="form-control" data-val="true" name="campaingtypeId" defaultValue={this.state.campaingtype_id} onChange={this.onChangeCampaingType}>
                        <option value="">{this.state.resources.Label_SelectOption}</option>
                        {this.state.campaingtypes.map(campaingtype =>
                            <option key={campaingtype.id} selected={campaingtype.id == this.state.campaingtype_id} value={campaingtype.id}>
                                {campaingtype.name}
                            </option>
                        )}
                    </select>
                </div>

                {/*FORM 1*/}
                <div id="form1">
                    <div style={{display: 'flex'}}>
                        <div className="form-group col-md-6" >
                            <label className=" form-label">{this.state.resources.Label_Name}</label>
                            <input disabled={this.state.viewMode} className="form-control" type="text" name="name" onChange={this.onChangeName} value={this.state.name} required />
                            {/* <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div> */}

                            <label className=" form-label">{this.state.resources.Label_Country}</label>
                            <select disabled={this.state.viewMode} className="form-control" data-val="true" name="countryId" defaultValue={this.state.country_id} onChange={this.onChangeCountry}>
                                {/* <option value="">{this.state.resources.Label_SelectOption}</option> */}
                                {this.state.countries.map(country =>
                                    <option key={country.id} value={country.id} selected={country.id == this.state.country_id}>
                                        {country.name}
                                    </option>
                                )}
                            </select>

                            <label className=" form-label">{this.state.resources.Title_Agency}</label>
                            <select disabled={this.state.viewMode} className="form-control" data-val="true" name="agencyId" defaultValue={this.state.agency_id} onChange={this.onChangeAgency}>
                                <option value="">{this.state.resources.Label_SelectOption}</option>
                                {this.state.agencies.map(agency =>
                                    <option key={agency.id} value={agency.id} /*hidden={this.state.country_id == agency.country_id ? false : true}*/
                                            selected={agency.id == this.state.agency_id}>
                                        {agency.name}
                                    </option>
                                )}
                            </select>

                            <label className=" form-label">{this.state.resources.Title_Business}</label>
                            <select disabled={this.state.viewMode} className="form-control" data-val="true" name="bussinessId" defaultValue={this.state.bussiness_id} onChange={this.onChangeBussiness}>
                                <option value="">{this.state.resources.Label_SelectOption}</option>
                                {this.state.bussiness.map(bussiness =>
                                    <option key={bussiness.id} value={bussiness.id} selected={bussiness.id == this.state.bussiness_id}>
                                        {bussiness.name}
                                    </option>
                                )}
                            </select>

                            <label className=" form-label">{this.state.resources.Title_Media}</label>
                            <select disabled={this.state.viewMode} className="form-control" data-val="true" name="mediaId" defaultValue={this.state.media_id} onChange={this.onChangeMedia}>
                                <option value="">{this.state.resources.Label_SelectOption}</option>
                                {this.state.medias.map(media =>
                                    <option key={media.id} value={media.id} hidden={this.state.country_id == media.country_id ? false : true}
                                        selected={media.id == this.state.media_id}>
                                        {media.name}
                                    </option>
                                )}
                            </select>

                            <label className=" form-label">{this.state.resources.Title_Formats}</label>
                            <select disabled={this.state.viewMode} className="form-control" data-val="true" name="formatId" defaultValue={this.state.format_id} onChange={this.onChangeFormat}>
                                <option value="">{this.state.resources.Label_SelectOption}</option>
                                {this.state.formats.map(format =>
                                    <option key={format.id} value={format.id} selected={format.id == this.state.format_id}>
                                        {format.name}
                                    </option>
                                )}
                            </select>
                        </div>
                        
                        <div className="form-group col-md-6" >
                            <label className=" form-label">{this.state.resources.NavItem_Courier}</label>
                            <select disabled={this.state.viewMode} className="form-control" data-val="true" name="courierId" defaultValue={this.state.courier_id} onChange={this.onChangeCourier}>
                                <option value="">{this.state.resources.Label_SelectOption}</option>
                                {this.state.couriers.map(courier =>
                                    <option key={courier.id} value={courier.id} hidden={this.state.country_id == courier.country_id ? false : true}
                                        selected={courier.id == this.state.courier_id}>
                                        {courier.name}
                                    </option>
                                )}
                            </select>

                            <label className=" form-label">{this.state.resources.Label_Supplier}</label>
                            <select disabled={this.state.viewMode} className="form-control" data-val="true" name="supplierId" defaultValue={this.state.supplier_id} onChange={this.onChangeSupplier}>
                                <option value="">{this.state.resources.Label_SelectOption}</option>
                                {this.state.suppliers.map(supplier =>
                                    <option key={supplier.id} value={supplier.id} hidden={this.state.country_id == supplier.country_id ? false : true}
                                        selected={supplier.id == this.state.supplier_id}>
                                        {supplier.name}
                                    </option>
                                )}
                            </select>

                            <label className=" form-label">{this.state.resources.Label_Products}</label>
                            <select disabled={this.state.viewMode} className="form-control" data-val="true" name="productId" defaultValue={this.state.product_id} onChange={this.onChangeProduct}>
                                <option value="">{this.state.resources.Label_SelectOption}</option>
                                {this.state.products.map(product =>
                                    <option key={product.id} value={product.id} hidden={this.state.supplier_id == product.supplier_id ? false : true}
                                        selected={product.id == this.state.product_id}>
                                        {product.name}
                                    </option>
                                )}
                            </select> 

                            <label className=" form-label" style={this.state.campaingtype_id == 2 ? { display: 'block' } : { display: 'none' }}>DID</label>
                            <select className="form-control" style={this.state.campaingtype_id == 2 ? { display: 'block' } : { display: 'none' }}
                                data-val="true" name="didId" defaultValue={this.state.did_id} onChange={this.onChangeDid}>
                                <option value="">{this.state.resources.Label_SelectOption}</option>
                                {this.state.dids.map(did =>
                                     this.state.usedDids.includes(did.id) ?
                                    <> </>:
                                    <option key={did.id} value={did.id}
                                    hidden={this.state.country_id == did.country_id ? false : true}
                                    selected={did.id == this.state.did_id}>
                                        {did.did} 
                                    </option>
                                )}
                            </select>

                             <label className=" form-label" style={this.state.campaingtype_id == 3 ? { display: 'block' } : { display: 'none' }}>{this.state.resources.Title_PbxDatabase}</label>
                            <select className="form-control" style={this.state.campaingtype_id == 3 ? { display: 'block' } : { display: 'none' }}
                                data-val="true" name="pbxdatabaseId" defaultValue={this.state.pbxdatabase_id} onChange={this.onChangePbxDatabase}>
                                <option value="">{this.state.resources.Label_SelectOption}</option>
                                {this.state.pbxdatabases.map(pbxdatabase =>
                                    <option key={pbxdatabase.id} value={pbxdatabase.id} hidden={this.state.country_id == pbxdatabase.country_id ? false : true}
                                    selected={pbxdatabase.id == this.state.pbxdatabase_id}>
                                        {pbxdatabase.name}
                                    </option>
                                )}
                            </select>

                            <label className=" form-label">{this.state.resources.Label_ManualLoading}</label>
                            <select disabled={this.state.viewMode} className="form-control" data-val="true" name="manualLoading" defaultValue={this.state.manual_loading} onChange={this.onChangeManualLoading}>
                                {/* <option value="">{this.state.resources.Label_SelectOption}</option> */}
                                <option value={this.state.manual_loading} selected>{this.state.manual_loading ? this.state.resources.Label_Yes : 'No'}</option>
                                <option value="true">
                                    {this.state.resources.Label_Yes}
                                </option>
                                <option value="false">No</option>
                            </select>

                            <label className=" form-label">{this.state.resources.Label_CampaingRecalculation}</label>
                            <select disabled={this.state.viewMode} className="form-control" data-val="true" name="campaingRecalculation" defaultValue={this.state.campaing_recalculation} onChange={this.onChangeCampaingRecalculation}>
                                {/* <option value="">{this.state.resources.Label_SelectOption}</option> */}
                                <option value={this.state.campaing_recalculation} selected>{this.state.campaing_recalculation  ? this.state.resources.Label_Yes : 'No'}</option>
                                <option value="true">{this.state.resources.Label_Yes}</option>
                                <option value="false" >No</option>
                            </select>

                            <label className=" form-label">{this.state.resources.Title_Publicity}</label>
                            <div className="form-group col-md-12" style={{display:'flex', justifyContent: 'space-between', paddingLeft: '0%' }}>
                                <select className="form-control mr-1" data-val="true" name="publicity" style={{width:"100%"}} defaultValue={this.state.publicity_id} onChange={this.onChangePublicity}>
                                <option value="">{this.state.resources.Label_SelectOption}</option>
                                {this.state.publicitys.map(publicity =>
                                    <option key={publicity.id} value={publicity.id} 
                                    hidden={this.state.country_id == publicity.country_id && this.state.product_id == publicity.product_country_id ? false : true} 
                                    selected={publicity.id == this.state.publicity_id}>
                                        {publicity.publicity}
                                    </option>
                                )} 
                                </select>
                                <AddPublicity />
                            </div>
                        
                            
                        </div>
                    </div>

                </div>

                {/*FORM 2*/}
                <div id="form2">
                    <div style={{display: 'flex'}}>
                        <div className="form-group col-md-6" >
                            <label className=" form-label">{this.state.resources.Label_Name}</label>
                            <input disabled={this.state.viewMode} className="form-control" type="text" name="name" onChange={this.onChangeName} value={this.state.name} required />
                            {/* <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div> */}

                            <label className=" form-label">{this.state.resources.Label_Country}</label>
                            <select disabled={this.state.viewMode} className="form-control" data-val="true" name="countryId" defaultValue={this.state.country_id} onChange={this.onChangeCountry}>
                                {/* <option value="">{this.state.resources.Label_SelectOption}</option> */}
                                {this.state.countries.map(country =>
                                    <option key={country.id} value={country.id} selected={country.id == this.state.country_id}>
                                        {country.name}
                                    </option>
                                )}
                            </select>

                            <label className=" form-label">{this.state.resources.Title_Agency}</label>
                            <select disabled={this.state.viewMode} className="form-control" data-val="true" name="agencyId" defaultValue={this.state.agency_id} onChange={this.onChangeAgency}>
                                <option value="">{this.state.resources.Label_SelectOption}</option>
                                {this.state.agencies.map(agency =>
                                    <option key={agency.id} value={agency.id} /*hidden={this.state.country_id == agency.country_id ? false : true}*/
                                        selected={agency.id == this.state.agency_id}>
                                        {agency.name}
                                    </option>

                                )}
                            </select>

                            <label className=" form-label">{this.state.resources.Title_Business}</label>
                            <select disabled={this.state.viewMode} className="form-control" data-val="true" name="bussinessId" defaultValue={this.state.bussiness_id} onChange={this.onChangeBussiness}>
                                <option value="">{this.state.resources.Label_SelectOption}</option>
                                {this.state.bussiness.map(bussiness =>
                                    <option key={bussiness.id} value={bussiness.id} selected={bussiness.id == this.state.bussiness_id}>
                                        {bussiness.name}
                                    </option>
                                )}
                            </select>

                            <label className=" form-label">{this.state.resources.Title_Media}</label>
                            <select disabled={this.state.viewMode} className="form-control" data-val="true" name="mediaId" defaultValue={this.state.media_id} onChange={this.onChangeMedia}>
                                <option value="">{this.state.resources.Label_SelectOption}</option>
                                {this.state.medias.map(media =>
                                    <option key={media.id} value={media.id + ';'+media.name} hidden={this.state.country_id == media.country_id ? false : true}
                                        selected={media.id == this.state.media_id}>
                                        {media.name}
                                    </option>
                                )}
                            </select>

                            <label className=" form-label">{this.state.resources.Title_Formats}</label>
                            <select disabled={this.state.viewMode} className="form-control" data-val="true" name="formatId" defaultValue={this.state.format_id} onChange={this.onChangeFormat}>
                                <option value="">{this.state.resources.Label_SelectOption}</option>
                                {this.state.formats.map(format =>
                                    <option key={format.id} value={format.id} selected={format.id == this.state.format_id}>
                                        {format.name}
                                    </option>
                                )}
                            </select>

                            <label className=" form-label">{this.state.resources.Label_Domains}</label>
                            <select disabled={this.state.viewMode} className="form-control" data-val="true" name="domainId" defaultValue={this.state.domain_id} onChange={this.onChangeDomain}>
                                <option value="">{this.state.resources.Label_SelectOption}</option>
                                {this.state.domains.map(domain =>
                                    <option key={domain.id} value={domain.id+';'+domain.url} selected={domain.id == this.state.domain_id}>{domain.url}</option>
                                )}
                            </select>

                        </div>
                        
                        <div className="form-group col-md-6" >
                            <label className=" form-label">{this.state.resources.NavItem_Courier}</label>
                            <select disabled={this.state.viewMode} className="form-control" data-val="true" name="courierId" defaultValue={this.state.courier_id} onChange={this.onChangeCourier}>
                                <option value="">{this.state.resources.Label_SelectOption}</option>
                                {this.state.couriers.map(courier =>
                                    <option key={courier.id} value={courier.id} hidden={this.state.country_id == courier.country_id ? false : true}
                                        selected={courier.id == this.state.courier_id}>
                                        {courier.name}
                                    </option>
                                )}
                            </select>

                            <label className=" form-label">{this.state.resources.Label_Supplier}</label>
                            <select disabled={this.state.viewMode} className="form-control" data-val="true" name="supplierId" defaultValue={this.state.supplier_id} onChange={this.onChangeSupplier}>
                                <option value="">{this.state.resources.Label_SelectOption}</option>
                                {this.state.suppliers.map(supplier =>
                                    <option key={supplier.id} value={supplier.id} hidden={this.state.country_id == supplier.country_id ? false : true}
                                        selected={supplier.id == this.state.supplier_id}>
                                        {supplier.name}
                                    </option>
                                )}
                            </select>

                            <label className=" form-label">{this.state.resources.Label_Products}</label>
                            <select disabled={this.state.viewMode} className="form-control" data-val="true" name="productId" defaultValue={this.state.product_id} onChange={this.onChangeProduct}>
                                <option value="">{this.state.resources.Label_SelectOption}</option>
                                {this.state.products.map(product =>
                                    <option key={product.id} value={product.id} hidden={this.state.supplier_id == product.supplier_id ? false : true}
                                        selected={product.id == this.state.product_id}>
                                        {product.name}
                                    </option>
                                )}
                            </select>

                            <label className=" form-label">DID</label>
                            <select disabled={this.state.viewMode} className="form-control" data-val="true" name="didId" defaultValue={this.state.did_id} onChange={this.onChangeDid}>
                                <option value="">{this.state.resources.Label_SelectOption}</option>
                                {this.state.dids.map(did =>
                                    this.state.usedDids.includes(did.id) ?
                                    <> </>:
                                    <option key={did.id} value={did.id} hidden={this.state.country_id == did.country_id ? false : true}
                                        selected={did.id == this.state.did_id}>
                                        {did.did}
                                    </option>
                                )}
                            </select>

                            <label className=" form-label">{this.state.resources.Label_ManualLoading}</label>
                            <select disabled={this.state.viewMode} className="form-control" data-val="true" name="manualLoading" defaultValue={this.state.manual_loading} onChange={this.onChangeManualLoading}>
                                {/* <option value="">{this.state.resources.Label_SelectOption}</option> */}
                                <option value={this.state.manual_loading} selected>{this.state.manual_loading ? this.state.resources.Label_Yes : 'No'}</option>
                                <option value="true">
                                    {this.state.resources.Label_Yes}
                                </option>
                                <option value="false">No</option>
                            </select>

                            <label className=" form-label">{this.state.resources.Label_CampaingRecalculation}</label>
                            <select disabled={this.state.viewMode} className="form-control" data-val="true" name="campaingRecalculation" defaultValue={this.state.campaing_recalculation} onChange={this.onChangeCampaingRecalculation}>
                                {/* <option value="">{this.state.resources.Label_SelectOption}</option> */}
                                <option value={this.state.campaing_recalculation} selected>{this.state.campaing_recalculation  ? this.state.resources.Label_Yes : 'No'}</option>
                                <option value="true">{this.state.resources.Label_Yes}</option>
                                <option value="false" >No</option>
                            </select>

                            <label className=" form-label">URL</label>
                            <input className="form-control" type="text" name="urlLanding" onChange={this.onChangeUrlLanding} value={this.state.url_landing} />

                            <label className=" form-label">{this.state.resources.Title_Publicity}</label>
                            <div className="form-group col-md-12" style={{display:'flex', justifyContent: 'space-between', paddingLeft: '0%' }}>
                                <select className="form-control mr-1" data-val="true" name="publicity" style={{width:"100%"}} defaultValue={this.state.publicity_id} onChange={this.onChangePublicity}>
                                <option value="">{this.state.resources.Label_SelectOption}</option>
                                {this.state.publicitys.map(publicity =>
                                    <option key={publicity.id} value={publicity.id} 
                                    hidden={this.state.country_id == publicity.country_id  && this.state.product_id == publicity.product_country_id ? false : true} 
                                    selected={publicity.id == this.state.publicity_id}>
                                        {publicity.publicity}
                                    </option>
                                )} 
                                </select>
                                <AddPublicity />
                            </div>
                        </div>
                    </div>

                </div>

                {/*FORM 3*/}
                <div id="form3">
                    <div style={{display: 'flex'}}>
                        <div className="form-group col-md-6" >
                            <label className=" form-label">{this.state.resources.Label_Name}</label>
                            <input disabled={this.state.viewMode} className="form-control" type="text" name="name" onChange={this.onChangeName} value={this.state.name} required />
                            {/* <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div> */}

                            <label className=" form-label">{this.state.resources.Label_Country}</label>
                            <select disabled={this.state.viewMode} className="form-control" data-val="true" name="countryId" defaultValue={this.state.country_id} onChange={this.onChangeCountry}>
                                {/* <option value="">{this.state.resources.Label_SelectOption}</option> */}
                                {this.state.countries.map(country =>
                                    <option key={country.id} value={country.id} selected={this.state.country_id == country.id}>
                                        {country.name}
                                    </option>
                                )}
                            </select>

                            <label className=" form-label">{this.state.resources.Title_Agency}</label>
                            <select disabled={this.state.viewMode} className="form-control" data-val="true" name="agencyId" defaultValue={this.state.agency_id} onChange={this.onChangeAgency}>
                                <option value="">{this.state.resources.Label_SelectOption}</option>
                                {this.state.agencies.map(agency =>
                                    <option key={agency.id} value={agency.id} /*hidden={this.state.country_id == agency.country_id ? false : true}*/
                                            selected={agency.id == this.state.agency_id}>
                                        {agency.name}
                                    </option>
                                )}
                            </select>

                            <label className=" form-label">{this.state.resources.Title_Business}</label>
                            <select disabled={this.state.viewMode} className="form-control" data-val="true" name="bussinessId" defaultValue={this.state.bussiness_id} onChange={this.onChangeBussiness}>
                                <option value="">{this.state.resources.Label_SelectOption}</option>
                                {this.state.bussiness.map(bussiness =>
                                    <option key={bussiness.id} value={bussiness.id} selected={bussiness.id == this.state.bussiness_id}>
                                        {bussiness.name}
                                    </option>
                                )}
                            </select>

                            <label className=" form-label">{this.state.resources.Title_Media}</label>
                            <select disabled={this.state.viewMode} className="form-control" data-val="true" name="mediaId" defaultValue={this.state.media_id} onChange={this.onChangeMedia}>
                                <option value="">{this.state.resources.Label_SelectOption}</option>
                                {this.state.medias.map(media =>
                                    <option key={media.id} value={media.id + ';'+media.name} hidden={this.state.country_id == media.country_id ? false : true}
                                        selected={media.id == this.state.media_id}>
                                        {media.name}
                                    </option>
                                )}
                            </select>

                            <label className=" form-label">{this.state.resources.Title_Formats}</label>
                            <select disabled={this.state.viewMode} className="form-control" data-val="true" name="formatId" defaultValue={this.state.format_id} onChange={this.onChangeFormat}>
                                <option value="">{this.state.resources.Label_SelectOption}</option>
                                {this.state.formats.map(format =>
                                    <option key={format.id} value={format.id} selected={format.id == this.state.format_id}>
                                        {format.name}
                                    </option>
                                )}
                            </select>

                            <label className=" form-label">{this.state.resources.Label_Domains}</label>
                            <select disabled={this.state.viewMode} className="form-control" data-val="true" name="domainId" defaultValue={this.state.domain_id} onChange={this.onChangeDomain}>
                                <option value="">{this.state.resources.Label_SelectOption}</option>
                                {this.state.domains.map(domain =>
                                    <option key={domain.id} value={domain.id + ';'+domain.url} selected={domain.id == this.state.domain_id}>{domain.url}</option>
                                )}
                            </select>
                        </div>
                        
                        <div className="form-group col-md-6" >
                            <label className=" form-label">{this.state.resources.NavItem_Courier}</label>
                            <select disabled={this.state.viewMode} className="form-control" data-val="true" name="courierId" defaultValue={this.state.courier_id} onChange={this.onChangeCourier}>
                                <option value="">{this.state.resources.Label_SelectOption}</option>
                                {this.state.couriers.map(courier =>
                                    <option key={courier.id} value={courier.id} hidden={this.state.country_id == courier.country_id ? false : true}
                                        selected={courier.id == this.state.courier_id}>
                                        {courier.name}
                                    </option>
                                )}
                            </select>

                            <label className=" form-label">{this.state.resources.Label_Supplier}</label>
                            <select disabled={this.state.viewMode} className="form-control" data-val="true" name="supplierId" defaultValue={this.state.supplier_id} onChange={this.onChangeSupplier}>
                                <option value="">{this.state.resources.Label_SelectOption}</option>
                                {this.state.suppliers.map(supplier =>
                                    <option key={supplier.id} value={supplier.id} hidden={this.state.country_id == supplier.country_id ? false : true}
                                        selected={supplier.id == this.state.supplier_id}>
                                        {supplier.name}
                                    </option>
                                )}
                            </select>

                            <label className=" form-label">{this.state.resources.Label_Products}</label>
                            <select disabled={this.state.viewMode} className="form-control" data-val="true" name="productId" defaultValue={this.state.product_id} onChange={this.onChangeProduct}>
                                <option value="">{this.state.resources.Label_SelectOption}</option>
                                {this.state.products.map(product =>
                                    <option key={product.id} value={product.id} hidden={this.state.supplier_id == product.supplier_id ? false : true}
                                        selected={product.id == this.state.product_id}>
                                        {product.name}
                                    </option>
                                )}
                            </select>

                            <label className=" form-label">{this.state.resources.Title_PbxDatabase}</label>
                            <select disabled={this.state.viewMode} className="form-control" data-val="true" name="pbxdatabaseId" defaultValue={this.state.pbxdatabase_id} onChange={this.onChangePbxDatabase}>
                                <option value="">{this.state.resources.Label_SelectOption}</option>
                                {this.state.pbxdatabases.map(pbxdatabase =>
                                    <option key={pbxdatabase.id} value={pbxdatabase.id} hidden={this.state.country_id == pbxdatabase.country_id ? false : true}
                                        selected={pbxdatabase.id == this.state.pbxdatabase_id}>
                                        {pbxdatabase.name}
                                    </option>
                                )}
                            </select>

                            <label className=" form-label">{this.state.resources.Label_ManualLoading}</label>
                            <select disabled={this.state.viewMode} className="form-control" data-val="true" name="manualLoading" defaultValue={this.state.manual_loading} onChange={this.onChangeManualLoading}>
                                {/* <option value="">{this.state.resources.Label_SelectOption}</option> */}
                                <option value={this.state.manual_loading} selected>{this.state.manual_loading ? this.state.resources.Label_Yes : 'No'}</option>
                                <option value="true">
                                    {this.state.resources.Label_Yes}
                                </option>
                                <option value="false">No</option>
                            </select>

                            <label className=" form-label">{this.state.resources.Label_CampaingRecalculation}</label>
                            <select disabled={this.state.viewMode} className="form-control" data-val="true" name="campaingRecalculation" defaultValue={this.state.campaing_recalculation} onChange={this.onChangeCampaingRecalculation}>
                                {/* <option value="">{this.state.resources.Label_SelectOption}</option> */}
                                <option value={this.state.campaing_recalculation} selected>{this.state.campaing_recalculation ? this.state.resources.Label_Yes : 'No'}</option>
                                <option value="true">{this.state.resources.Label_Yes}</option>
                                <option value="false">No</option>
                            </select>

                            <label className=" form-label">URL</label>
                            <input disabled={this.state.viewMode} className="form-control" type="text" name="urlLanding" onChange={this.onChangeUrlLanding} value={this.state.url_landing} />
                            
                            <label className=" form-label">{this.state.resources.Title_Publicity}</label>
                            <div className="form-group col-md-12" style={{display:'flex', justifyContent: 'space-between', paddingLeft: '0%' }}>
                                <select className="form-control mr-1" data-val="true" name="publicity" style={{width:"100%"}} defaultValue={this.state.publicity_id} onChange={this.onChangePublicity}>
                                <option value="">{this.state.resources.Label_SelectOption}</option>
                                {this.state.publicitys.map(publicity =>
                                    <option key={publicity.id} value={publicity.id} 
                                    hidden={this.state.country_id == publicity.country_id && this.state.product_id == publicity.product_country_id ? false : true } 
                                    selected={publicity.id == this.state.publicity_id}>
                                        {publicity.publicity}
                                    </option>
                                )} 
                                </select>
                                <AddPublicity />
                            </div>
                        </div>
                    </div>

                </div>

                {/*LAST UPDATE / LAST UPDATE USER*/}
                <div className="form-group col-md-6" >
                    <DataCreation resources={this.state}></DataCreation>
                </div>

                {/**Buttons Header */}
                <div className="buttons-head row">
                    {this.state.viewMode == false ?
                    <div className=" buttons-childs">
                        <button type="submit"  onClick={this.onSubmit}  className="btn btn-primary">{this.state.resources.ButtonLabel_Save}</button> {/*onClick={this.onSubmit}*/}
                        <button className="btn btn-danger" onClick={this.onCancel}>{this.state.resources.ButtonLabel_Cancel}</button>
                    </div>
                    :
                     <button className="btn btn-danger btn-block" onClick={this.onCancel}>{this.state.resources.Label_GoBack}</button>
                    }
                 </div>

            </div>;

        return (
            <div className="mt-2">
                <ol className="breadcrumb my-4">
                    <li className="breadcrumb-item active">{this.state.resources.Title_Campaing}</li>
                </ol>
                <div className="card">
                    <div className="card-body">
                        {/* {this.state.loading ? helpers.showLoadingOverlay() : helpers.hideLoadingOverlay()} */}
                        {contents}
                    </div>
                </div>
            </div>
        );
    }

    async getResources() {
        const resourceVars = [];
        resourceVars.push("Label_Products");
        resourceVars.push("Label_ID");
        resourceVars.push("Label_Name");
        resourceVars.push("Title_Campaing");
        resourceVars.push("Title_CampaingType");
        resourceVars.push("Label_FiscalId");
        resourceVars.push("Label_Brand");
        resourceVars.push("Label_Supplier");
        resourceVars.push("Title_Treatments");
        resourceVars.push("Label_Actions");
        resourceVars.push("Label_Status");
        resourceVars.push("Label_Enabled");
        resourceVars.push("Label_Disabled");
        resourceVars.push("ButtonLabel_ViewEdit");
        resourceVars.push("ButtonLabel_New");
        resourceVars.push("Label_AddCountry");
        resourceVars.push("Label_DetailinCountry");
        resourceVars.push("Label_Country");
        resourceVars.push("Title_Agency");
        resourceVars.push("Title_Business");
        resourceVars.push("Title_Media");
        resourceVars.push("Label_Name");
        resourceVars.push("Label_Country");
        resourceVars.push("Label_SelectOption");
        resourceVars.push("Label_Enabled");
        resourceVars.push("Label_Success");
        resourceVars.push("ButtonLabel_Save");
        resourceVars.push("ButtonLabel_Cancel");
        resourceVars.push("Label_Error");
        resourceVars.push("Title_Formats");
        resourceVars.push("NavItem_Courier");
        resourceVars.push("ErrorMessage_RequiredField");
        resourceVars.push("ErrorMessage_DuplicatedName");
        resourceVars.push("ErrorMessage_UnknownError");
        resourceVars.push("SuccessMessage_DataSavedSuccessfully");
        resourceVars.push("Label_ManualLoading");
        resourceVars.push("Label_Yes");
        resourceVars.push("Label_CampaingRecalculation");
        resourceVars.push("Label_Domain");
        resourceVars.push("Title_PbxDatabase");
        resourceVars.push("Label_Creation");
        resourceVars.push("Label_LastUpdate");
        resourceVars.push("Label_GoBack");
        resourceVars.push("Label_Domains");
        resourceVars.push("Title_Publicity");
        resourceVars.push("ErrorMessage_PublicityError");

        const headers = {
            'Content-Type': 'application/json'
        };

        axios.post(window.config.REACT_APP_SECURITY_API_URL + '/languageresource/getbyvars/' + helpers.getUiLanguage(), resourceVars, {
            headers: headers
        })
            .then((response) => {
                const data = response.data.data;
                this.setState({ resources: data});
            })
            .catch((error) => {
                console.log(error);
                this.setState({ loading: false });
            });
    }

    async populateData() {

        this.setState({
            loading: true
        });
        helpers.showLoadingOverlay();
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/campaing/getbyid/' + this.props.match.params.id,
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({
                    id: data.id,
                    name: data.name,
                    country_id: data.country_id,
                    campaingtype_id: data.campaingtype_id,
                    agency_id: data.agency_id,
                    bussiness_id: data.bussiness_id,
                    media_id: data.media_id,
                    format_id: data.format_id,
                    courier_id: data.courier_id,
                    supplier_id: data.supplier_id,
                    product_id: data.product_id,
                    did_id: data.did_id,
                    pbxdatabase_id: data.pbxdatabase_id,
                    url_landing: data.url_landing,
                    manual_loading: data.manual_loading,
                    campaing_recalculation: data.campaing_recalculation,
                    enabled: data.enabled,
                    creation: helpers.getDisplayDateTimeFromUtc(data.creation),
                    creationUser: data.creation_user_name,
                    lastUpdate: data.last_update == null ? '' : helpers.getDisplayDateTimeFromUtc(data.last_update),
                    lastUpdateUser: data.last_update_user_name == null ? data.creation_user_name : data.last_update_user_name,
                    // loading: false,
                    domain_id: data.domain_id,
                    domain_name: data.domain_name,
                    media_name: data.media_name,
                });
                // console.log(data);
                // console.log(this.state);
                this.setCampaingType(data.campaingtype_id);
                // helpers.hideLoadingOverlay();
            })
            .catch(function (error) {
                console.log(error);
                this.setState({ loading: false });
            }) 

        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/campaingtype/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ campaingtypes: data});
                // order alphabetically
                this.state.campaingtypes.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                // console.log(this.state.campaingtypes);
            })
            .catch(function (error) {
                console.log(error);
            });
        
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/country/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ countries: data});
                // order alphabetically
                this.state.countries.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                // console.log(this.state.countries);
            })
            .catch(function (error) {
                console.log(error);
            });
        
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/agency/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ agencies: data});
                // order alphabetically
                this.state.agencies.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                // console.log(this.state.agencies);
            })
            .catch(function (error) {
                console.log(error);
            });
        
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/business/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ bussiness: data});
                // order alphabetically
                this.state.bussiness.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                // console.log(this.state.bussiness);
            })
            .catch(function (error) {
                console.log(error);
            });
        
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/media/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ medias: data});
                // order alphabetically
                this.state.medias.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                // console.log(this.state.medias);
            })
            .catch(function (error) {
                console.log(error);
            });
        
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/format/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ formats: data});
                // order alphabetically
                this.state.formats.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                // console.log(this.state.formats);
            })
            .catch(function (error) {
                console.log(error);
            });
        
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/courier/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ couriers: data});
                // order alphabetically
                this.state.couriers.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                // console.log(this.state.couriers);
            })
            .catch(function (error) {
                console.log(error);
            });
        
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/supplier/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ suppliers: data});
                // order alphabetically
                this.state.suppliers.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                // console.log(this.state.supplier);
            })
            .catch(function (error) {
                console.log(error);
            });
        
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/product/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ products: data});
                // order alphabetically
                this.state.products.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                // console.log(this.state.product);
            })
            .catch(function (error) {
                console.log(error);
            });
        
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/did/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ dids: data});
                // order alphabetically
                this.state.dids.sort((a,b) => a.did < b.did ? -1 : +(a.did > b.did))
                // console.log(this.state.dids);
            })
            .catch(function (error) {
                console.log(error);
            });

            axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/publicity/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;

                this.setState({ publicitys: data});
                // order alphabetically
                //this.state.publicitys.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                this.state.publicitys.sort((a,b) => {
                    const publicidadA = a.publicity.toLowerCase();
                    const publicidadB = b.publicity.toLowerCase();
                    if(publicidadA < publicidadB){
                        return -1;
                    }

                    if(publicidadA > publicidadB){
                        return 1;
                    }
                    return 0;
                })
                 console.log(this.state.publicitys);
            })
            .catch(function (error) {
                console.log(error);
            });
        
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/pbxdatabase/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ pbxdatabases: data});
                // order alphabetically
                this.state.pbxdatabases.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                // console.log(this.state.pbxdatabases);
            })
            .catch(function (error) {
                console.log(error);
            });
        
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/domain/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ domains: data});
                // order alphabetically
                this.state.domains.sort((a,b) => a.url < b.url ? -1 : +(a.url > b.url))
                // console.log(this.state.domains);
            })
            .catch(function (error) {
                console.log(error);
            });

            this.setState({ loading: true });
            axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/campaing/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ actualCampaings:  data, loading: false})

                for (let i = 0; i < data.length; i++){
                    for(let c = 0; c < this.state.dids.length; c++){

                        if (data[i].did_id == this.state.dids[c].id) {
                            
                            if(!this.state.usedDids.includes(this.state.dids[c].id)){
                                if (this.state.did_id != this.state.dids[c].id) {
                                    this.state.usedDids.push(this.state.dids[c].id);
                                }
                            }
                        }
                            
                    }
                }
                this.setState({
                    dids: this.state.dids
                })

                this.state.campaings.sort((a,b) => a.url < b.url ? -1 : +(a.url > b.url))
                helpers.hideLoadingOverlay();
            })
            
            .catch(function (error) {
                console.log(error);
                helpers.hideLoadingOverlay();
            });

    }

}


function AddPublicity() {
    const [open, setOpen] = React.useState(false);
    const [closingReasonId, setClosingReasonId] = React.useState(0);

    let countriess = [];

    const handleOpen = () => {
        setOpen(true);
      };
      const handleClose = () => {
        setOpen(false);
        this.setState({
          publicity: '',
          product_countries: [],
          countryId : null,
          productCountryId : null,
          publicity_id: null
      });

    };
      let loading = true;
  
      const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '50%',
        height: '50%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const onChangePublicityModal = (e)  =>{
            this.setState({
                publicity: e.target.value
            });
        }

    const onChangeProductsCountry = (e)  =>{
            this.setState({
                productCountryId: e.target?.value
            });
        }

    const onSubmitModal = async (e) => {
            e.preventDefault();
    
            /*var form = document.querySelectorAll('.needs-validation')[0];
    
            if (!form.checkValidity())
                return;*/

            if (this.state.publicity == '' || this.state.countryId == null || this.state.productCountryId == null) {
                helpers.notifyWarning('Error','Todos los campos son requeridos');
                return;
            } else if (this.state.publicity == '') {
                    helpers.notifyWarning('Error','Ingrese un nombre para Publicidad');
                    return;
            }
            else if (this.state.countryId == null || this.state.countryId == 0) {
                helpers.notifyWarning('Error','Seleccione un Pais');
                return;
        }
        else if (this.state.productCountryId == null || this.state.productCountryId == 0) {
            helpers.notifyWarning('Error','Seleccione un Producto');
            return;
    }
            else 
            {
                this.setState({ loadingModal: true, country_id: this.state.countryId });
    
            const data = {
                publicity: this.state.publicity,
                country_id: this.state.countryId,
                product_country_id: this.state.productCountryId,
                enabled: this.state.enabled,
                creation_user_id: this.state.userId,
            };
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME)
            };
    
            axios.post(window.config.REACT_APP_PRODUCTS_API_URL + '/publicity/save', data, {
                headers: headers
            })
                .then((response) => {
                    helpers.notifySuccess(this.state.resources.Label_Success, this.state.resources.SuccessMessage_DataSavedSuccessfully);
                    this.setState({
                        loadingModal: false,
                        publicity: '',
                        countryId: null,
                        productCountryId: null,
                        publicity_id: response.data.data
                    });
                    handleClose();
                    axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/publicity/get',
                    {
                        headers: headers
                    })
                    .then(response => {
                        const data = response.data.data;
                        this.setState({ publicitys: data});
                        // order alphabetically
                      //this.state.publicitys.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                       this.state.publicitys.sort((a,b) => {
                        const publicidadA = a.publicity.toLowerCase();
                        const publicidadB = b.publicity.toLowerCase();
                        if(publicidadA < publicidadB){
                            return -1;
                        }
    
                        if(publicidadA > publicidadB){
                            return 1;
                        }
                        return 0;
                    })
                        //console.log(this.state.publicitys);
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
                })
                .catch((error) => {
                    console.log(error);
    
                    let msg = this.state.resources.ErrorMessage_PublicityError;
                    helpers.notifyError(this.state.resources.Label_Error, msg);
                    this.setState({ loadingModal: false });
                });
            }
    
            
        }
        

      const onChangeCountryModal = async (e) => {
        console.log('a ver si entra aca');
        /*this.setState({
            countryId: e.target?.value
        });*/
        let prueba = e.target?.value;
        console.log(this.state?.countryId)
        console.log(prueba?.length)
        if(prueba?.length == 0)
        {
            this.state.product_countries = [];
            this.state.countryId = null;
            this.state.productCountryId  = null;
            this.setState({
                product_countries: [],
                countryId : null,
                productCountryId : null
            });
        }
        else
        {
            this.setState({
                loadingModal: true
            });
         let authTokenProducts = await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME);
            axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/productcountry/getproductsbycountryid/' + prueba,
                {
                    'headers': { 'Authorization': authTokenProducts}
                })
                .then(response => {
                    const data = response.data.data;
                    this.state.product_countries = [];
                     console.log(data);
                    /*for (let i = 0; i < data.length; i++) 
                    {
                        this.state.product_countries.push(data[i].product_id);
                    }*/
                    // order alphabetically
                    countriess = data.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                    console.log(countriess);
                    setTimeout(() => {
                        this.setState({
                            countryId: prueba,
                            product_countries: countriess,
                            loadingModal: false
                         });
                    }, 1000);
                })
                .catch(function (error) {
                    console.log(error);
                    countriess = [];
                    this.setState({ loadingModal: false,product_countries: [] });
                });
        }
    }



    return (
    <div className="form-group col-md-1">
        <button className='btn btn-success' onClick={handleOpen}>
            <i className="px-6 m-5" style={{padding:30, cursor: 'pointer', color: 'white', fontSize:'1.1rem'}} class="fas fa-plus fa-3x fa-lg mr-2 mx-2 p-1 " data-tougle="tooltip" data-placement="right"  onClick={handleOpen}></i>
        </button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
          {/* <div className="mt-2">
                <ol className="breadcrumb my-4">
                    <li className="breadcrumb-item active">{this.state.resources.Title_Publicity}</li>
                </ol>
            </div> */}
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <Typography id="modal-modal-title" variant="h6" component="h2" className='text-center'>
                    <th>{this.state.resources.Title_Publicity}</th>
                </Typography>
                </div>
                {this.state.loadingModal ? <div className="text-center" style={{position: 'absolute', top: '50%', left: '50%'}}><CircularProgress size={'3.6rem'}/></div> : <div>
                  <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Title_Publicity}</label>
                    <input className="form-control" type="text" maxLength="150" onChange={onChangePublicityModal} value={this.state.publicity}  name="publicity"   />
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                
                    <label className=" form-label">{this.state.resources.Label_Country}</label>
                    <select className="form-control" data-val="true" name="countryId" defaultValue={this.state.countryId} onChange={onChangeCountryModal}>
                        <option value="">{this.state.resources.Label_SelectOption}</option>
                        {this.state.countries.map(country =>
                            <option key={country.id} value={country.id}>{country.name}</option>
                        )}
                    </select>
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                
                    <label className=" form-label">{this.state.resources.Label_Products}</label>
                    <select className="form-control" data-val="true" name="productid" defaultValue={this.state.productCountryId} onChange={onChangeProductsCountry}>
                        <option value="">{this.state.resources.Label_SelectOption}</option>
                        {this.state.product_countries.map(product =>
                            //countriess.includes(product.id) &&
                                <option key={product.id} value={product.id}>{product.name}</option>
                        )}
                    </select>
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                </div> 

                <div className="buttons-head row">
                    <div className=" buttons-childs">
                        <button className="btn btn-primary"onClick={onSubmitModal}>{this.state.resources.ButtonLabel_Save}</button>
                        <button className="btn btn-danger" onClick={handleClose}>{this.state.resources.ButtonLabel_Cancel}</button>
                    </div>
                 </div></div>}

          </Box>
        </Modal>
      </div>
    );
  }