import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';  
import $ from 'jquery';
import DataTable from 'datatables.net';
import helpers from '../../Helpers';
import { render } from 'react-dom';

import '../../custom.css';

export default function DataCreation(values) {

    return (
        <div className="data-creation-component">
            

            <p><b>{values.resources.resources.Label_Creation}</b>: {values.resources.creationUser} ({values.resources.creation})</p>
            <p><b>{values.resources.resources.Label_LastUpdate}</b>: 
             &nbsp;{values.resources.lastUpdateUser !== '' ? values.resources.lastUpdateUser : values.resources.creationUser}
             &nbsp;({values.resources.lastUpdate !== '' ? values.resources.lastUpdate : values.resources.creation})
            </p>
        </div>
    )
}