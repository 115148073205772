import React, { Component } from 'react';
import axios from 'axios';
import helpers from '../../Helpers';
import '../Companies/Company.css';

import Button from '@material-ui/core/Button';

export class ProductPaymentCreate extends Component {
    static displayName = ProductPaymentCreate.name;

    constructor(props) {
        super(props);

        this.onChangeDescription = this.onChangeDescription.bind(this);
        this.onChangeEnabled = this.onChangeEnabled.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        // this.onChangePayment = this.onChangePayment.bind(this);
        this.onChangeUrlPayment = this.onChangeUrlPayment.bind(this);
        this.onChangeUrlSuccess = this.onChangeUrlSuccess.bind(this);
        this.onChangeUrlFailure = this.onChangeUrlFailure.bind(this);
        this.onChangeUrlAnnulment = this.onChangeUrlAnnulment.bind(this);

        this.state = {
            id: 0,
            description: null,
            sku: '',
            duration: null,
            enabled: true,
            loading: true,
            resources: [],
            userId: 0,
            productPayment: '',
            paymentUrl: '',
            successUrl: '',
            failureUrl: '',
            annulmentUrl: '',
        };
    }

    componentDidMount() {
        this.getResources();
        this.populateData();
        helpers.setNavActive("#nav-product-payment");
        helpers.setClientValidation();

        let currentUser = helpers.getUserData();
        this.setState({
            userId: currentUser.id
        });
    }

    async onSubmit(e) {
        e.preventDefault();

        var form = document.querySelectorAll('.needs-validation')[0];

        if (!form.checkValidity())
            return;

        this.setState({ loading: true });

        const data = {
            description: this.state.description,
            success_url: this.state.successUrl,
            fail_url: this.state.failureUrl,
            nulled_url: this.state.annulmentUrl,
            enabled: this.state.enabled,
            payment_url: this.state.paymentUrl,
            creation_user_id: this.state.userId,
        };
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME)
        };

        axios.post(window.config.REACT_APP_PRODUCTS_API_URL + '/productpayment/save', data, {
            headers: headers
        })
            .then((response) => {
                helpers.notifySuccess(this.state.resources.Label_Success, this.state.resources.SuccessMessage_DataSavedSuccessfully);
                this.props.history.push('/product-payment');
            })
            .catch((error) => {
                console.log(error);

                let msg = this.state.resources.ErrorMessage_UnknownError;
                if (error.response.data.error_code == window.errorCodes.DuplicatedEntityName)
                    msg = this.state.resources.ErrorMessage_DuplicatedName;
                else if (error.response.data.error_code == window.errorCodes.AssociatedEntity)
                    msg = this.state.resources.ErrorMessage_AssociatedEntity_Company_User;

                helpers.notifyError(this.state.resources.Label_Error, msg);
                this.setState({ loading: false });
            });
    }  

    onCancel(e) {
        e.preventDefault();
        this.props.history.push("/product-payment");
    }

    onChangeDescription(e) {
         this.setState({
            description: e.target.value
        });
    }

    onChangeEnabled(e) {
        this.setState({
            enabled: e.target.checked
        });
    }

    // onChangePayment(e) {
    //     this.setState({
    //         productPayment: e.target.value
    //     });
    // }

    onChangeUrlPayment(e){
        this.setState({
            paymentUrl: e.target.value
        })
    }
    onChangeUrlSuccess(e){
        this.setState({
            successUrl: e.target.value
        })
    }
    onChangeUrlFailure(e){
        this.setState({
            failureUrl: e.target.value
        })
    }
    onChangeUrlAnnulment(e){
        this.setState({
            annulmentUrl: e.target.value
        })
    }

    render() {
        let contents =
            <form className="form row g-3 needs-validation" onSubmit={this.onSubmit} noValidate>
                {/*ENABLED*/}
                <div className="form-group col-md-12">
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" id="enabled" checked={this.state.enabled} onChange={this.onChangeEnabled} />
                        <label className="form-check-label" htmlFor="enabled">{this.state.resources.Label_Enabled}</label>
                        <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                    </div>
                </div>
                <div className="form-group col-md-6" >

                    <label className=" form-label">{this.state.resources.Label_ProductPayment}</label>
                    <input className="form-control" type="text" name="productPayment" maxlength="50" onChange={this.onChangeDescription} value={this.state.description} required placeholder='MAX(50)'/>
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                
                </div>

                <div className="form-group col-md-6" >

                    <label className=" form-label">{this.state.resources.Label_PaymentURL}</label>
                    <input className="form-control" type="text" maxlength="150" name="paymentUrl" onChange={this.onChangeUrlPayment} value={this.state.paymentUrl} placeholder='MAX(150)' />
                </div>  

                 <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_SuccessURL}</label>
                    <input className="form-control" type="text" maxlength="150" name="successUrl" onChange={this.onChangeUrlSuccess} value={this.state.successUrl} placeholder='MAX(150)'/>
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                </div>
                 <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_NulledURL}</label>
                    <input className="form-control" type="text" maxlength="150" name="annulmentUrl" onChange={this.onChangeUrlAnnulment} value={this.state.annulmentUrl} placeholder='MAX(150)' />
                </div>

                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_FailureURL}</label>
                    <input className="form-control" type="text" maxlength="150" name="failureUrl" onChange={this.onChangeUrlFailure} value={this.state.failureUrl} placeholder='MAX(150)' />
                </div>

                {/**Buttons Header */}
                 <div className="buttons-head row">
                    <div className=" buttons-childs">
                        <button type="submit" className="btn btn-primary">{this.state.resources.ButtonLabel_Save}</button>
                        <button className="btn btn-danger" onClick={this.onCancel}>{this.state.resources.ButtonLabel_Cancel}</button>
                    </div>
                 </div>
            </form>;

        return (

           <div>
                
           
            <div className="mt-2">
                
                <ol className="breadcrumb my-4">
                    <li className="breadcrumb-item active">{this.state.resources.Label_ProductPayment}</li>
                </ol>
                <div className="card">
                    <div className="card-body">
                        {this.state.loading ? helpers.showLoadingOverlay() : helpers.hideLoadingOverlay()}
                        {contents}
                    </div>
                </div>
                </div>
        </div> 
        );
    }

    async getResources() {
        const resourceVars = [];
        resourceVars.push("Label_Products");
        resourceVars.push("Label_Name");
        resourceVars.push("Label_Description");
        resourceVars.push("Label_Logo");
        resourceVars.push("Label_FiscalId");
        resourceVars.push("Label_Brand");
        resourceVars.push("Label_Supplier");
        resourceVars.push("Title_Treatments");
        resourceVars.push("Label_Logo");
        resourceVars.push("Label_BusinessArea");
        resourceVars.push("Label_Enabled");
        resourceVars.push("Label_Success");
        resourceVars.push("Label_SelectOption");
        resourceVars.push("ButtonLabel_Save");
        resourceVars.push("ButtonLabel_Cancel");
        resourceVars.push("Label_Error");
        resourceVars.push("ErrorMessage_RequiredField");
        resourceVars.push("ErrorMessage_DuplicatedName");
        resourceVars.push("ErrorMessage_UnknownError");
        resourceVars.push("ErrorMessage_AssociatedEntity_Company_User");
        resourceVars.push("ErrorMessage_ErrorWhileUploadingImage");
        resourceVars.push("ErrorMessage_InvalidEmail");
        resourceVars.push("SuccessMessage_DataSavedSuccessfully");
        resourceVars.push("Label_DurationTreatment");
        resourceVars.push("Workspace_Payment");
        resourceVars.push("Label_PaymentURL");
        resourceVars.push("Label_SuccessURL");
        resourceVars.push("Label_FailureURL");
        resourceVars.push("Label_NulledURL");
        resourceVars.push("Label_Description");
        resourceVars.push("Label_ProductPayment");

        const headers = {
            'Content-Type': 'application/json'
        };

        axios.post(window.config.REACT_APP_SECURITY_API_URL + '/languageresource/getbyvars/' + helpers.getUiLanguage(), resourceVars, {
            headers: headers
        })
            .then((response) => {
                const data = response.data.data;
                this.setState({ resources: data, loading: false });
            })
            .catch((error) => {
                console.log(error);
                this.setState({ loading: false });
            });
    }

    async populateData() {
        this.setState({
            loading: true
        });

            axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/productpayment/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ payments: data, loading: false });
                // order alphabetically
                this.state.payments.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                // console.log(this.state.payments);
            })
            .catch(function (error) {
                console.log(error);
            });
    }
}
