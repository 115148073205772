import React, { Component } from 'react';
import axios from 'axios';
import $ from 'jquery';
import helpers from '../../Helpers'
import DataCreation from '../DataCreation/DataCreation';

export class ClosingReasonDetail extends Component {
    static displayName = ClosingReasonDetail.name;

    constructor(props) {
        super(props);

        this.onChangeEnabled = this.onChangeEnabled.bind(this);
        this.onChangeCountry = this.onChangeCountry.bind(this);
        this.onChangeArea = this.onChangeArea.bind(this);
        this.onChangeSubArea = this.onChangeSubArea.bind(this);
        this.onChangeDescription = this.onChangeDescription.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onSubmit = this.onSubmit.bind(this);  

        this.state = {
            id: 0,
            name: '',
            countries: [],
            countryId: null,
            description: '',
            subareas: [],
            subareaId: null,
            areas: [],
            areaId: null,
            enabled: true,
            loading: true,
            resources: [],
            userId: 0,
            creation: null,
            creationUser: '',
            lastUpdate: null,
            lastUpdateUser: '',
            viewMode: false,
        };
    }

    componentDidMount() {
        this.getResources();
        this.populateData();
        helpers.setNavActive("#nav-closing-reasons");

        let url = window.location.href;
        if (url.includes('view')) {
            this.setState({
                viewMode: true
            });   
        }
        let currentUser = helpers.getUserData();
        this.setState({
            currentUserId: currentUser.id
        });

        let sort = JSON.parse(localStorage.getItem('ClosingReasonListSorting'))
        localStorage.setItem('CopyClosingReasonListSorting', JSON.stringify(sort));
        
        let paging = JSON.parse(localStorage.getItem('ClosingReasonListPagination'))
        localStorage.setItem('CopyClosingReasonListPaging', JSON.stringify(paging));

        let filtering = JSON.parse(localStorage.getItem('ClosingReasonListFiltering'))
        localStorage.setItem('CopyClosingReasonListFiltering', JSON.stringify(filtering));
    }

    async onSubmit(e) {
        e.preventDefault();
        this.setState({ loading: true });

        const data = {
            id: parseInt(this.props.match.params.id),
            country_id: this.state.countryId,
            area_id: this.state.areaId,
            subarea_id: this.state.subareaId,
            description: this.state.description,
            enabled: this.state.enabled,
            creation_user_id: this.state.userId,
        };
        // console.log(data);
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME)
        };

        axios.post(window.config.REACT_APP_PRODUCTS_API_URL + '/closingreason/save', data, {
            headers: headers
        })
            .then((response) => {
                helpers.notifySuccess(this.state.resources.Label_Success, this.state.resources.SuccessMessage_DataSavedSuccessfully);
                this.props.history.push('/closing-reasons');
            })
            .catch((error) => {
                console.log(error);

                let msg = this.state.resources.ErrorMessage_UnknownError;
                if (error.response.data.error_code == window.errorCodes.DuplicatedEntityName)
                    msg = this.state.resources.ErrorMessage_DuplicatedName;

                helpers.notifyError(this.state.resources.Label_Error, msg);
                this.setState({ loading: false });
            });
    }  

    onCancel(e) {
        e.preventDefault();
        this.props.history.push("/closing-reasons");
    }

    onChangeDescription(e) {
        this.setState({
            description: e.target.value
        });
    }
    
    onChangeCountry(e) {
        this.setState({
            countryId: e.target.value
        });
    }

    onChangeEnabled(e) {
        this.setState({
            enabled: e.target.checked
        });
    }
    
    onChangeArea(e) {
        this.setState({
            areaId: e.target.value
        });
    }

    onChangeSubArea(e) {
        this.setState({
            subareaId: e.target.value
        });
    }

    render() {
        let contents = this.state.loading
            ? helpers.showLoadingOverlay()
            :
            <form className="form row g-3 needs-validation" onSubmit={this.onSubmit}>
                <div className="form-group col-md-12">
                    <div className="form-check form-switch">
                        <input disabled={this.state.viewMode} className="form-check-input" type="checkbox" id="enabled" defaultChecked={this.state.enabled} onChange={this.onChangeEnabled} />
                        <label className="form-check-label" htmlFor="enabled">{this.state.resources.Label_Enabled}</label>
                        <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                    </div>
                </div>
                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_Description}</label>
                    <input disabled={this.state.viewMode} className="form-control" type="text" name="description" onChange={this.onChangeDescription} value={this.state.description} required />
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                </div>
                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_Country}</label>
                    <select disabled={this.state.viewMode} className="form-control" data-val="true" name="countryid" value={this.state.countryId} onChange={this.onChangeCountry} required>
                        <option value="">{this.state.resources.Label_SelectOption}</option>
                        {this.state.countries.map(country =>
                            <option key={country.id} value={country.id}
                                selected={this.state.countryId == country.id}>{country.name}</option>
                        )}
                    </select>                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                </div>
                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.NavItem_Areas}</label>
                    <select disabled={this.state.viewMode} className="form-control" data-val="true" name="areaId" defaultValue={this.state.areaId} onChange={this.onChangeArea} required>
                        <option value="">{this.state.resources.Label_SelectOption}</option>
                        {this.state.areas.map(area =>
                            <option key={area.id} value={area.id} selected={this.state.areaId == area.id}>{area.name}</option>
                        )}
                    </select>                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                </div>
                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.NavItem_SubAreas}</label>
                    <select disabled={this.state.viewMode} className="form-control" data-val="true" name="subareaId" defaultValue={this.state.subareaId} onChange={this.onChangeSubArea} required>
                        <option value="">{this.state.resources.Label_SelectOption}</option>
                        {this.state.subareas.map(subarea =>
                            subarea.area_id == this.state.areaId &&
                            <option key={subarea.id} value={subarea.id}
                                selected={this.state.subareaId == subarea.id}>{subarea.name}</option>
                        )}
                    </select>                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                </div>

                 {/*LAST UPDATE / LAST UPDATE USER*/}
                <div className="form-group col-md-6" >
                    <DataCreation resources={this.state}></DataCreation>
                </div>

                {/**Buttons Header */}
                 <div className="buttons-head row">
                    {this.state.viewMode == false ?
                    <div className=" buttons-childs">
                        <button type="submit" className="btn btn-primary">{this.state.resources.ButtonLabel_Save}</button>
                        <button className="btn btn-danger" onClick={this.onCancel}>{this.state.resources.ButtonLabel_Cancel}</button>
                    </div>
                    :
                    <button className="btn btn-danger btn-block" onClick={this.onCancel}>{this.state.resources.Label_GoBack}</button>
                    }
                 </div>
            </form>
            helpers.hideLoadingOverlay();

        return (
            <div className="mt-2">
                <ol className="breadcrumb my-4">
                    <li className="breadcrumb-item active">{this.state.resources.Titile_ClosingReasons}</li>
                </ol>
                <div className="card">
                    <div className="card-body">
                        {contents}
                    </div>
                </div>
            </div>
        );
    }

    async getResources() {
        const resourceVars = [];
        resourceVars.push("Title_Courier");
        resourceVars.push("Label_Name");
        resourceVars.push("Label_Country");
        resourceVars.push("Title_Documentation");
        resourceVars.push("Label_Description");
        resourceVars.push("Label_Enabled");
        resourceVars.push("Label_Success");
        resourceVars.push("ButtonLabel_Save");
        resourceVars.push("ButtonLabel_Cancel");
        resourceVars.push("Label_Error");
        resourceVars.push("ErrorMessage_RequiredField");
        resourceVars.push("ErrorMessage_DuplicatedName");
        resourceVars.push("ErrorMessage_UnknownError");
        resourceVars.push("SuccessMessage_DataSavedSuccessfully");
        resourceVars.push("Label_SelectOption");
        resourceVars.push("Title_Ticket");
        resourceVars.push("Title_Invoice");
        resourceVars.push("Titile_ClosingReasons");
        resourceVars.push("NavItem_SubAreas");
        resourceVars.push("NavItem_Areas");
        resourceVars.push("Label_GoBack");
        resourceVars.push("Label_Creation");
        resourceVars.push("Label_CreationUser");
        resourceVars.push("Label_LastUpdate");
        resourceVars.push("Label_LastUpdateUser");

        const headers = {
            'Content-Type': 'application/json'
        };

        axios.post(window.config.REACT_APP_SECURITY_API_URL + '/languageresource/getbyvars/' + helpers.getUiLanguage(), resourceVars, {
            headers: headers
        })
            .then((response) => {
                const data = response.data.data;
                this.setState({ resources: data, loading: false });
            })
            .catch((error) => {
                console.log(error);
                this.setState({ loading: false });
            });

            axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/country/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ countries: data, loading: false });
                // order alphabetically
                this.state.countries.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                // console.log(this.state.countries);
            })
            .catch(function (error) {
                console.log(error);
            });
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/area/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ areas: data, loading: false });
                // order alphabetically
                this.state.areas.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                // console.log(this.state.areas);
            })
            .catch(function (error) {
                console.log(error);
            });
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/subarea/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
               
                this.setState({
                    subareas: data.sort((a, b) => a.name < b.name ? -1 : +(a.name > b.name)),
                    loading: false
                });
                // order alphabetically
                this.state.subareas.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                // console.log(this.state.subareas);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    async populateData() {
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/closingreason/getbyid/' + this.props.match.params.id,
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({
                    id: data.id,
                    subareaId: data.subarea_id,
                    areaId: data.area_id,
                    countryId: data.country_id,
                    description: data.description,
                    enabled: data.enabled,
                    loading: false,
                    creation: helpers.getDisplayDateTimeFromUtc(data.creation),
                    creationUser: data.creation_user_fullname,
                    lastUpdate: data.last_update == null ? '' : helpers.getDisplayDateTimeFromUtc(data.last_update),
                    lastUpdateUser: data.last_update_user_fullname == null ? '' : data.last_update_user_fullname,
                });
                helpers.hideLoadingOverlay();
            })
            .catch(function (error) {
                console.log(error);
                this.setState({ loading: false });
            })
    }
}