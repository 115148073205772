import React, { Component } from 'react';
import axios from 'axios';
import helpers from '../../Helpers'

export class PermissionGroupCreate extends Component {
    static displayName = PermissionGroupCreate.name;

    constructor(props) {
        super(props);

        this.onChangeName = this.onChangeName.bind(this);
        this.onChangeDescription = this.onChangeDescription.bind(this);
        this.onChangePermissionIds = this.onChangePermissionIds.bind(this);
        this.onChangeEnabled = this.onChangeEnabled.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onSubmit = this.onSubmit.bind(this);  

        this.state = {
            id: 0,
            name: '',
            description: '',
            permissionIds: [],
            enabled: true,
            loading: true,
            resources: [],
            permissions: [],
            menuPermissions: [],
            abmPermissions: [],
            userId: 0,
        };
    }

    componentDidMount() {
        this.getResources();
        this.populateData();
        helpers.setNavActive("#nav-permissiongroups");

        let currentUser = helpers.getUserData();
        this.setState({
            userId: currentUser.id
        });
        
    }

    async onSubmit(e) {
        e.preventDefault();

        if (this.state.permissionIds.length == 0) {
            helpers.notifyError(this.state.resources.Label_Error, this.state.resources.ErrorMessage_PermissionGroupMustHaveOnePermission);
            return;
        }

        this.setState({ loading: true });

        const data = {
            name: this.state.name,
            description: this.state.description,
            permission_ids: this.state.permissionIds,
            enabled: this.state.enabled,
            creation_user_id: this.state.userId,
        };
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_SECURITY_API_NAME)
        };

        axios.post(window.config.REACT_APP_SECURITY_API_URL + '/permissiongroup/save', data, {
            headers: headers
        })
            .then((response) => {
                helpers.notifySuccess(this.state.resources.Label_Success, this.state.resources.SuccessMessage_DataSavedSuccessfully);
                this.props.history.push('/permissiongroups');
            })
            .catch((error) => {
                console.log(error);

                let msg = this.state.resources.ErrorMessage_UnknownError;
                if (error.response.data.error_code == window.errorCodes.DuplicatedEntityName)
                    msg = this.state.resources.ErrorMessage_DuplicatedName;

                helpers.notifyError(this.state.resources.Label_Error, msg);
                this.setState({ loading: false });
            });
    }  

    onCancel(e) {
        e.preventDefault();
        this.props.history.push("/permissiongroups");
    }

    onChangeName(e) {
        this.setState({
            name: e.target.value
        });
    } 
    onChangeDescription(e) {
        this.setState({
            description: e.target.value
        });
    }  
    onChangePermissionIds(e) {
        
    //  console.log(e.target.value);

        let id = parseInt(e.target.value);
        let _permissionIds = this.state.permissionIds;
        const index = _permissionIds.indexOf(id);

        if (e.target.checked == true) {
            _permissionIds.push(id);
        } else {
            if (index > -1)
                _permissionIds.splice(index, 1);
        }
        
        this.setState({
            permissionIds: _permissionIds
        });

    } 
    onChangeEnabled(e) {
        this.setState({
            enabled: e.target.checked
        });
    } 

    render() {
        let contents = this.state.loading
            ? helpers.showLoadingOverlay()
            :
            <form className="form row g-3 needs-validation" onSubmit={this.onSubmit}>
                <div className="form-group col-md-12">
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" id="enabled" defaultChecked={this.state.enabled} onChange={this.onChangeEnabled} />
                        <label className="form-check-label" htmlFor="enabled">{this.state.resources.Label_Enabled}</label>
                        <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                    </div>
                </div>
                <div className="form-group row" >
                    <div className="form-group col-md-6" >
                        <label className=" form-label">{this.state.resources.Label_Name}</label>
                        <input className="form-control" type="text" name="name" onChange={this.onChangeName} value={this.state.name} required />
                        <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                    </div>
                    <div className="form-group col-md-6" >
                        <label className=" form-label">{this.state.resources.Label_Description}</label>
                        <input className="form-control" type="text" name="description" onChange={this.onChangeDescription} value={this.state.description} />
                        <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                    </div>
                </div>

                <div className="form-group col-md-12" >
                    <label className=" form-label">Menu</label>
                    <div className="row">
                        {this.state.menuPermissions.includes(23) &&
                            
                            <div key={23} className="form-check form-check-inline col-md-1 mx-3">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 23} defaultValue={23} />
                                <label className="form-check-label" htmlFor={'permission' + 23}>Menu RH</label>
                            </div>
                        }
                        {this.state.menuPermissions.includes(24) &&
                            
                            <div key={24} className="form-check form-check-inline col-md-1 mx-3">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 24} defaultValue={24} />
                                <label className="form-check-label" htmlFor={'permission' + 24}>Menu {this.state.resources.Title_Media}</label>
                            </div>
                        }
                        {this.state.menuPermissions.includes(25) &&
                            
                            <div key={25} className="form-check form-check-inline col-md-1 ml-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 25} defaultValue={25} />
                                <label className="form-check-label" htmlFor={'permission' + 25}>Menu {this.state.resources.Title_Logistic}</label>
                            </div>
                        }
                        {this.state.menuPermissions.includes(26) &&
                            
                            <div key={26} className="form-check form-check-inline col-md-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 26} defaultValue={26} />
                                <label className="form-check-label" htmlFor={'permission' + 26}>Menu Stock</label>
                            </div>
                        }

                        {this.state.menuPermissions.includes(1164) &&
                            
                            <div key={1164} className="form-check form-check-inline col-md-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 1164} defaultValue={1164} />
                                <label className="form-check-label" htmlFor={'permission' + 1164}>Menu {this.state.resources.Label_Sales}</label>
                            </div>
                        }

                        {this.state.menuPermissions.includes(27) &&
                            
                            <div key={27} className="form-check form-check-inline col-md-2">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 27} defaultValue={27} />
                                <label className="form-check-label" htmlFor={'permission' + 27}>Menu {this.state.resources.NavItem_Security}</label>
                            </div>
                        }
                        {this.state.menuPermissions.includes(138) &&
                            
                            <div key={138} className="form-check form-check-inline col-md-2">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 138} defaultValue={138} />
                                <label className="form-check-label" htmlFor={'permission' + 138}>Menu {this.state.resources.NavItem_Configurations}</label>
                            </div>
                        }
                    </div>
                </div>
                
                <div className="form-group col-md-12" >
                    <label className=" form-label">{this.state.resources.Label_Permissions}</label>
                    <div className="row">
                        {this.state.abmPermissions.includes(8) &&
                         <div style={{borderLeft: '3px solid red',marginBottom:"30px",}} class="col-md-2">
                            <p><b>ABM {this.state.resources.Title_Areas}</b></p>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 37} defaultValue={37} />
                                <label className="form-check-label" htmlFor={'permission' + 37}>{this.state.resources.Label_Creation}</label>
                            </div>

                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 38} defaultValue={38} />
                                <label className="form-check-label" htmlFor={'permission' + 38}>{this.state.resources.Label_Edition}</label>
                            </div>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 8} defaultValue={8} />
                                <label className="form-check-label" htmlFor={'permission' + 8}>{this.state.resources.Label_Reading}</label>
                            </div>
                         </div>
                        }
                           
                           {this.state.abmPermissions.includes(104) &&
                         <div style={{borderLeft: '3px solid red',marginBottom:"30px",}} class="col-md-2">
                            <p><b>ABM {this.state.resources.Title_Business}</b></p>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 105} defaultValue={105} />
                                <label className="form-check-label" htmlFor={'permission' + 105}>{this.state.resources.Label_Creation}</label>
                            </div>

                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 106} defaultValue={106} />
                                <label className="form-check-label" htmlFor={'permission' + 106}>{this.state.resources.Label_Edition}</label>
                            </div>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 104} defaultValue={104} />
                                <label className="form-check-label" htmlFor={'permission' + 104}>{this.state.resources.Label_Reading}</label>
                            </div>
                         </div>
                    }
                    
                    {this.state.abmPermissions.includes(6) &&
                         <div style={{borderLeft: '3px solid red',marginBottom:"30px",}} class="col-md-2">
                            <p><b>ABM {this.state.resources.NavItem_Suppliers}</b></p>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 33} defaultValue={33} />
                                <label className="form-check-label" htmlFor={'permission' + 33}>{this.state.resources.Label_Creation}</label>
                            </div>

                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 34} defaultValue={34} />
                                <label className="form-check-label" htmlFor={'permission' + 34}>{this.state.resources.Label_Edition}</label>
                            </div>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 6} defaultValue={6} />
                                <label className="form-check-label" htmlFor={'permission' + 6}>{this.state.resources.Label_Reading}</label>
                            </div>
                         </div>
                    }

                    {this.state.abmPermissions.includes(7) &&
                         <div style={{borderLeft: '3px solid red',marginBottom:"30px",}} class="col-md-2">
                            <p><b>ABM {this.state.resources.NavItem_BusinessAreas}</b></p>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 35} defaultValue={35} />
                                <label className="form-check-label" htmlFor={'permission' + 35}>{this.state.resources.Label_Creation}</label>
                            </div>

                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 36} defaultValue={36} />
                                <label className="form-check-label" htmlFor={'permission' + 36}>{this.state.resources.Label_Edition}</label>
                            </div>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 7} defaultValue={7} />
                                <label className="form-check-label" htmlFor={'permission' + 7}>{this.state.resources.Label_Reading}</label>
                            </div>
                         </div>
                    }
                
                    {this.state.abmPermissions.includes(9) &&
                         <div style={{borderLeft: '3px solid red',marginBottom:"30px",}} class="col-md-2">
                            <p><b>ABM {this.state.resources.NavItem_SubAreas}</b></p>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 39} defaultValue={39} />
                                <label className="form-check-label" htmlFor={'permission' + 39}>{this.state.resources.Label_Creation}</label>
                            </div>

                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 40} defaultValue={40} />
                                <label className="form-check-label" htmlFor={'permission' + 40}>{this.state.resources.Label_Edition}</label>
                            </div>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 9} defaultValue={9} />
                                <label className="form-check-label" htmlFor={'permission' + 9}>{this.state.resources.Label_Reading}</label>
                            </div>
                         </div>
                    }


                    {this.state.abmPermissions.includes(10) &&
                         <div style={{borderLeft: '3px solid red',marginBottom:"30px",}} class="col-md-2">
                            <p><b>ABM {this.state.resources.NavItem_Brands}</b></p>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 41} defaultValue={41} />
                                <label className="form-check-label" htmlFor={'permission' + 41}>{this.state.resources.Label_Creation}</label>
                            </div>

                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 42} defaultValue={42} />
                                <label className="form-check-label" htmlFor={'permission' + 42}>{this.state.resources.Label_Edition}</label>
                            </div>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 10} defaultValue={10} />
                                <label className="form-check-label" htmlFor={'permission' + 10}>{this.state.resources.Label_Reading}</label>
                            </div>
                         </div>
                    }

                    {this.state.abmPermissions.includes(11) &&
                         <div style={{borderLeft: '3px solid red',marginBottom:"30px",}} class="col-md-2">
                            <p><b>ABM {this.state.resources.NavItem_Countries}</b></p>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 43} defaultValue={43} />
                                <label className="form-check-label" htmlFor={'permission' + 43}>{this.state.resources.Label_Creation}</label>
                            </div>

                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 44} defaultValue={44} />
                                <label className="form-check-label" htmlFor={'permission' + 44}>{this.state.resources.Label_Edition}</label>
                            </div>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 11} defaultValue={11} />
                                <label className="form-check-label" htmlFor={'permission' + 11}>{this.state.resources.Label_Reading}</label>
                            </div>
                         </div>
                    } 

                    {this.state.abmPermissions.includes(12) &&
                         <div style={{borderLeft: '3px solid red',marginBottom:"30px",}} class="col-md-2">
                            <p><b>ABM {this.state.resources.NavItem_Permissions}</b></p>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 45} defaultValue={45} />
                                <label className="form-check-label" htmlFor={'permission' + 45}>{this.state.resources.Label_Creation}</label>
                            </div>

                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 46} defaultValue={46} />
                                <label className="form-check-label" htmlFor={'permission' + 46}>{this.state.resources.Label_Edition}</label>
                            </div>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 12} defaultValue={12} />
                                <label className="form-check-label" htmlFor={'permission' + 12}>{this.state.resources.Label_Reading}</label>
                            </div>
                         </div>
                    }

                    {this.state.abmPermissions.includes(13) &&
                         <div style={{borderLeft: '3px solid red',marginBottom:"30px",}} class="col-md-2">
                            <p><b>ABM {this.state.resources.NavItem_PermissionGroups}</b></p>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 47} defaultValue={47} />
                                <label className="form-check-label" htmlFor={'permission' + 47}>{this.state.resources.Label_Creation}</label>
                            </div>

                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 48} defaultValue={48} />
                                <label className="form-check-label" htmlFor={'permission' + 48}>{this.state.resources.Label_Edition}</label>
                            </div>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 13} defaultValue={13} />
                                <label className="form-check-label" htmlFor={'permission' + 13}>{this.state.resources.Label_Reading}</label>
                            </div>
                         </div>
                    }

                    {this.state.abmPermissions.includes(14) &&
                         <div style={{borderLeft: '3px solid red',marginBottom:"30px",}} class="col-md-2">
                            <p><b>ABM {this.state.resources.NavItem_Users}</b></p>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 49} defaultValue={49} />
                                <label className="form-check-label" htmlFor={'permission' + 49}>{this.state.resources.Label_Creation}</label>
                            </div>

                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 51} defaultValue={51} />
                                <label className="form-check-label" htmlFor={'permission' + 51}>{this.state.resources.Label_Edition}</label>
                            </div>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 14} defaultValue={14} />
                                <label className="form-check-label" htmlFor={'permission' + 14}>{this.state.resources.Label_Reading}</label>
                            </div>
                         </div>
                    }

                    {this.state.abmPermissions.includes(15) &&
                         <div style={{borderLeft: '3px solid red',marginBottom:"30px",}} class="col-md-2">
                            <p><b>ABM {this.state.resources.NavItem_AuthorizationTokens}</b></p>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 52} defaultValue={52} />
                                <label className="form-check-label" htmlFor={'permission' + 52}>{this.state.resources.Label_Creation}</label>
                            </div>

                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 53} defaultValue={53} />
                                <label className="form-check-label" htmlFor={'permission' + 53}>{this.state.resources.Label_Edition}</label>
                            </div>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 15} defaultValue={15} />
                                <label className="form-check-label" htmlFor={'permission' + 15}>{this.state.resources.Label_Reading}</label>
                            </div>
                         </div>
                    }

                    {this.state.abmPermissions.includes(16) &&
                         <div style={{borderLeft: '3px solid red',marginBottom:"30px",}} class="col-md-2">
                            <p><b>ABM {this.state.resources.NavItem_LanguageResources}</b></p>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 54} defaultValue={54} />
                                <label className="form-check-label" htmlFor={'permission' + 54}>{this.state.resources.Label_Creation}</label>
                            </div>

                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 55} defaultValue={55} />
                                <label className="form-check-label" htmlFor={'permission' + 55}>{this.state.resources.Label_Edition}</label>
                            </div>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 16} defaultValue={16} />
                                <label className="form-check-label" htmlFor={'permission' + 16}>{this.state.resources.Label_Reading}</label>
                            </div>
                         </div>
                    }

                    {this.state.abmPermissions.includes(17) &&
                         <div style={{borderLeft: '3px solid red',marginBottom:"30px",}} class="col-md-2">
                            <p><b>ABM {this.state.resources.NavItem_Configurations}</b></p>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 56} defaultValue={56} />
                                <label className="form-check-label" htmlFor={'permission' + 56}>{this.state.resources.Label_Creation}</label>
                            </div>

                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 57} defaultValue={57} />
                                <label className="form-check-label" htmlFor={'permission' + 57}>{this.state.resources.Label_Edition}</label>
                            </div>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 17} defaultValue={17} />
                                <label className="form-check-label" htmlFor={'permission' + 17}>{this.state.resources.Label_Reading}</label>
                            </div>
                         </div>
                    }

                    {this.state.abmPermissions.includes(18) &&
                         <div style={{borderLeft: '3px solid red',marginBottom:"30px",}} class="col-md-2">
                            <p><b>ABM {this.state.resources.NavItem_ApiClients}</b></p>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 58} defaultValue={58} />
                                <label className="form-check-label" htmlFor={'permission' + 58}>{this.state.resources.Label_Creation}</label>
                            </div>

                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 59} defaultValue={59} />
                                <label className="form-check-label" htmlFor={'permission' + 59}>{this.state.resources.Label_Edition}</label>
                            </div>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 18} defaultValue={18} />
                                <label className="form-check-label" htmlFor={'permission' + 18}>{this.state.resources.Label_Reading}</label>
                            </div>
                         </div>
                    }

                    {this.state.abmPermissions.includes(19) &&
                         <div style={{borderLeft: '3px solid red',marginBottom:"30px",}} class="col-md-2">
                            <p><b>ABM {this.state.resources.NavItem_Treatments}</b></p>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 60} defaultValue={60} />
                                <label className="form-check-label" htmlFor={'permission' + 60}>{this.state.resources.Label_Creation}</label>
                            </div>

                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 61} defaultValue={61} />
                                <label className="form-check-label" htmlFor={'permission' + 61}>{this.state.resources.Label_Edition}</label>
                            </div>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 19} defaultValue={19} />
                                <label className="form-check-label" htmlFor={'permission' + 19}>{this.state.resources.Label_Reading}</label>
                            </div>
                         </div>
                    }

                    {this.state.abmPermissions.includes(20) &&
                         <div style={{borderLeft: '3px solid red',marginBottom:"30px",}} class="col-md-2">
                            <p><b>ABM {this.state.resources.Title_Formats}</b></p>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 62} defaultValue={62} />
                                <label className="form-check-label" htmlFor={'permission' + 62}>{this.state.resources.Label_Creation}</label>
                            </div>

                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 63} defaultValue={63} />
                                <label className="form-check-label" htmlFor={'permission' + 63}>{this.state.resources.Label_Edition}</label>
                            </div>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 20} defaultValue={20} />
                                <label className="form-check-label" htmlFor={'permission' + 20}>{this.state.resources.Label_Reading}</label>
                            </div>
                         </div>
                    }

                    {this.state.abmPermissions.includes(66) &&
                        <div style={{borderLeft: '3px solid red',marginBottom:"30px",}} class="col-md-2">
                            <p><b>ABM {this.state.resources.Title_Logs}</b></p>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 66} defaultValue={66} />
                                <label className="form-check-label" htmlFor={'permission' + 66}>{this.state.resources.Label_Reading}</label>
                            </div>
                            
                        </div>
                    }

                        {this.state.abmPermissions.includes(67) &&
                         <div style={{borderLeft: '3px solid red',marginBottom:"30px",}} class="col-md-2">
                            <p><b>ABM {this.state.resources.NavItem_Courier}</b></p>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 68} defaultValue={68} />
                                <label className="form-check-label" htmlFor={'permission' + 68}>{this.state.resources.Label_Creation}</label>
                            </div>

                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 69} defaultValue={69} />
                                <label className="form-check-label" htmlFor={'permission' + 69}>{this.state.resources.Label_Edition}</label>
                            </div>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 67} defaultValue={67} />
                                <label className="form-check-label" htmlFor={'permission' + 67}>{this.state.resources.Label_Reading}</label>
                            </div>
                         </div>
                        }

                        {this.state.abmPermissions.includes(70) &&
                         <div style={{borderLeft: '3px solid red',marginBottom:"30px",}} class="col-md-2">
                            <p><b>ABM {this.state.resources.NavItem_Sellers}</b></p>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 71} defaultValue={71} />
                                <label className="form-check-label" htmlFor={'permission' + 71}>{this.state.resources.Label_Creation}</label>
                            </div>

                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 72} defaultValue={72} />
                                <label className="form-check-label" htmlFor={'permission' + 72}>{this.state.resources.Label_Edition}</label>
                            </div>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 70} defaultValue={70} />
                                <label className="form-check-label" htmlFor={'permission' + 70}>{this.state.resources.Label_Reading}</label>
                            </div>
                         </div>
                        }

                        {this.state.abmPermissions.includes(73) &&
                         <div style={{borderLeft: '3px solid red',marginBottom:"30px",}} class="col-md-2">
                            <p><b>ABM {this.state.resources.Title_Objetions}</b></p>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 74} defaultValue={74} />
                                <label className="form-check-label" htmlFor={'permission' + 74}>{this.state.resources.Label_Creation}</label>
                            </div>

                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 75} defaultValue={75} />
                                <label className="form-check-label" htmlFor={'permission' + 75}>{this.state.resources.Label_Edition}</label>
                            </div>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 73} defaultValue={73} />
                                <label className="form-check-label" htmlFor={'permission' + 73}>{this.state.resources.Label_Reading}</label>
                            </div>
                         </div>
                        }

                        {this.state.abmPermissions.includes(76) &&
                         <div style={{borderLeft: '3px solid red',marginBottom:"30px",}} class="col-md-2">
                            <p><b>ABM {this.state.resources.Title_ScriptSales}</b></p>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 77} defaultValue={77} />
                                <label className="form-check-label" htmlFor={'permission' + 77}>{this.state.resources.Label_Creation}</label>
                            </div>

                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 78} defaultValue={78} />
                                <label className="form-check-label" htmlFor={'permission' + 78}>{this.state.resources.Label_Edition}</label>
                            </div>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 76} defaultValue={76} />
                                <label className="form-check-label" htmlFor={'permission' + 76}>{this.state.resources.Label_Reading}</label>
                            </div>
                         </div>
                        }

                        {this.state.abmPermissions.includes(79) &&
                         <div style={{borderLeft: '3px solid red',marginBottom:"30px",}} class="col-md-2">
                            <p><b>ABM {this.state.resources.Label_Products}</b></p>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 80} defaultValue={80} />
                                <label className="form-check-label" htmlFor={'permission' + 80}>{this.state.resources.Label_Creation}</label>
                            </div>

                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 81} defaultValue={81} />
                                <label className="form-check-label" htmlFor={'permission' + 81}>{this.state.resources.Label_Edition}</label>
                            </div>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 79} defaultValue={79} />
                                <label className="form-check-label" htmlFor={'permission' + 79}>{this.state.resources.Label_Reading}</label>
                            </div>
                         </div>
                        }

                        {this.state.abmPermissions.includes(82) &&
                         <div style={{borderLeft: '3px solid red',marginBottom:"30px",}} class="col-md-2">
                            <p><b>ABM {this.state.resources.Title_MediaType}</b></p>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 83} defaultValue={83} />
                                <label className="form-check-label" htmlFor={'permission' + 83}>{this.state.resources.Label_Creation}</label>
                            </div>

                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 84} defaultValue={84} />
                                <label className="form-check-label" htmlFor={'permission' + 84}>{this.state.resources.Label_Edition}</label>
                            </div>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 82} defaultValue={82} />
                                <label className="form-check-label" htmlFor={'permission' + 82}>{this.state.resources.Label_Reading}</label>
                            </div>
                         </div>
                        }

                        {this.state.abmPermissions.includes(85) &&
                         <div style={{borderLeft: '3px solid red',marginBottom:"30px",}} class="col-md-2">
                            <p><b>ABM {this.state.resources.Title_MediaGroup}</b></p>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 86} defaultValue={86} />
                                <label className="form-check-label" htmlFor={'permission' + 86}>{this.state.resources.Label_Creation}</label>
                            </div>

                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 87} defaultValue={87} />
                                <label className="form-check-label" htmlFor={'permission' + 87}>{this.state.resources.Label_Edition}</label>
                            </div>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 85} defaultValue={85} />
                                <label className="form-check-label" htmlFor={'permission' + 85}>{this.state.resources.Label_Reading}</label>
                            </div>
                         </div>
                        }
                        {this.state.abmPermissions.includes(88) &&
                         <div style={{borderLeft: '3px solid red',marginBottom:"30px",}} class="col-md-2">
                            <p><b>ABM {this.state.resources.Label_ProductCountry}</b></p>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 89} defaultValue={89} />
                                <label className="form-check-label" htmlFor={'permission' + 89}>{this.state.resources.Label_Creation}</label>
                            </div>

                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 90} defaultValue={90} />
                                <label className="form-check-label" htmlFor={'permission' + 90}>{this.state.resources.Label_Edition}</label>
                            </div>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 88} defaultValue={88} />
                                <label className="form-check-label" htmlFor={'permission' + 88}>{this.state.resources.Label_Reading}</label>
                            </div>
                         </div>
                        }
                        {this.state.abmPermissions.includes(91) &&
                         <div style={{borderLeft: '3px solid red',marginBottom:"30px",}} class="col-md-2">
                            <p><b>ABM {this.state.resources.Title_ProductCountryKey}</b></p>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 92} defaultValue={92} />
                                <label className="form-check-label" htmlFor={'permission' + 92}>{this.state.resources.Label_Creation}</label>
                            </div>

                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 93} defaultValue={93} />
                                <label className="form-check-label" htmlFor={'permission' + 93}>{this.state.resources.Label_Edition}</label>
                            </div>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 91} defaultValue={91} />
                                <label className="form-check-label" htmlFor={'permission' + 91}>{this.state.resources.Label_Reading}</label>
                            </div>
                         </div>
                        }
                        {this.state.abmPermissions.includes(94) &&
                         <div style={{borderLeft: '3px solid red',marginBottom:"30px",}} class="col-md-2">
                            <p><b>ABM {this.state.resources.Title_Agency}</b></p>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 95} defaultValue={95} />
                                <label className="form-check-label" htmlFor={'permission' + 95}>{this.state.resources.Label_Creation}</label>
                            </div>

                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 96} defaultValue={96} />
                                <label className="form-check-label" htmlFor={'permission' + 96}>{this.state.resources.Label_Edition}</label>
                            </div>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 94} defaultValue={94} />
                                <label className="form-check-label" htmlFor={'permission' + 94}>{this.state.resources.Label_Reading}</label>
                            </div>
                         </div>
                        }
                        {this.state.abmPermissions.includes(97) &&
                         <div style={{borderLeft: '3px solid red',marginBottom:"30px",}} class="col-md-2">
                            <p><b>ABM {this.state.resources.Title_Media}</b></p>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 98} defaultValue={98} />
                                <label className="form-check-label" htmlFor={'permission' + 98}>{this.state.resources.Label_Creation}</label>
                            </div>

                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 99} defaultValue={99} />
                                <label className="form-check-label" htmlFor={'permission' + 99}>{this.state.resources.Label_Edition}</label>
                            </div>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 97} defaultValue={97} />
                                <label className="form-check-label" htmlFor={'permission' + 97}>{this.state.resources.Label_Reading}</label>
                            </div>
                         </div>
                        }
                        {this.state.abmPermissions.includes(100) &&
                         <div style={{borderLeft: '3px solid red',marginBottom:"30px",}} class="col-md-2">
                            <p><b>ABM {this.state.resources.Title_Pbx}</b></p>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 101} defaultValue={101} />
                                <label className="form-check-label" htmlFor={'permission' + 101}>{this.state.resources.Label_Creation}</label>
                            </div>

                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 103} defaultValue={103} />
                                <label className="form-check-label" htmlFor={'permission' + 103}>{this.state.resources.Label_Edition}</label>
                            </div>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 100} defaultValue={100} />
                                <label className="form-check-label" htmlFor={'permission' + 100}>{this.state.resources.Label_Reading}</label>
                            </div>
                         </div>
                        }
                        {this.state.abmPermissions.includes(107) &&
                         <div style={{borderLeft: '3px solid red',marginBottom:"30px",}} class="col-md-2">
                            <p><b>ABM {this.state.resources.Title_PbxDatabase}</b></p>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 108} defaultValue={108} />
                                <label className="form-check-label" htmlFor={'permission' + 108}>{this.state.resources.Label_Creation}</label>
                            </div>

                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 109} defaultValue={109} />
                                <label className="form-check-label" htmlFor={'permission' + 109}>{this.state.resources.Label_Edition}</label>
                            </div>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 107} defaultValue={107} />
                                <label className="form-check-label" htmlFor={'permission' + 107}>{this.state.resources.Label_Reading}</label>
                            </div>
                         </div>
                        }
                        {this.state.abmPermissions.includes(110) &&
                         <div style={{borderLeft: '3px solid red',marginBottom:"30px",}} class="col-md-2">
                            <p><b>ABM {this.state.resources.Title_CampaingType}</b></p>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 111} defaultValue={111} />
                                <label className="form-check-label" htmlFor={'permission' + 111}>{this.state.resources.Label_Creation}</label>
                            </div>

                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 112} defaultValue={112} />
                                <label className="form-check-label" htmlFor={'permission' + 112}>{this.state.resources.Label_Edition}</label>
                            </div>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 110} defaultValue={110} />
                                <label className="form-check-label" htmlFor={'permission' + 110}>{this.state.resources.Label_Reading}</label>
                            </div>
                         </div>
                        }
                        {this.state.abmPermissions.includes(115) &&
                         <div style={{borderLeft: '3px solid red',marginBottom:"30px",}} class="col-md-2">
                            <p><b>ABM {this.state.resources.Title_Carrier}</b></p>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 116} defaultValue={116} />
                                <label className="form-check-label" htmlFor={'permission' + 116}>{this.state.resources.Label_Creation}</label>
                            </div>

                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 117} defaultValue={117} />
                                <label className="form-check-label" htmlFor={'permission' + 117}>{this.state.resources.Label_Edition}</label>
                            </div>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 115} defaultValue={115} />
                                <label className="form-check-label" htmlFor={'permission' + 115}>{this.state.resources.Label_Reading}</label>
                            </div>
                         </div>
                        }
                        {this.state.abmPermissions.includes(119) &&
                         <div style={{borderLeft: '3px solid red',marginBottom:"30px",}} class="col-md-2">
                            <p><b>ABM DID</b></p>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 120} defaultValue={120} />
                                <label className="form-check-label" htmlFor={'permission' + 120}>{this.state.resources.Label_Creation}</label>
                            </div>

                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 121} defaultValue={121} />
                                <label className="form-check-label" htmlFor={'permission' + 121}>{this.state.resources.Label_Edition}</label>
                            </div>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 119} defaultValue={119} />
                                <label className="form-check-label" htmlFor={'permission' + 119}>{this.state.resources.Label_Reading}</label>
                            </div>
                         </div>
                        }
                        {this.state.abmPermissions.includes(122) &&
                         <div style={{borderLeft: '3px solid red',marginBottom:"30px",}} class="col-md-2">
                            <p><b>ABM {this.state.resources.Title_State}</b></p>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 123} defaultValue={123} />
                                <label className="form-check-label" htmlFor={'permission' + 123}>{this.state.resources.Label_Creation}</label>
                            </div>

                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 124} defaultValue={124} />
                                <label className="form-check-label" htmlFor={'permission' + 124}>{this.state.resources.Label_Edition}</label>
                            </div>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 122} defaultValue={122} />
                                <label className="form-check-label" htmlFor={'permission' + 122}>{this.state.resources.Label_Reading}</label>
                            </div>
                         </div>
                        }
                        {this.state.abmPermissions.includes(125) &&
                         <div style={{borderLeft: '3px solid red',marginBottom:"30px",}} class="col-md-2">
                            <p><b>ABM {this.state.resources.Title_City}</b></p>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 126} defaultValue={126} />
                                <label className="form-check-label" htmlFor={'permission' + 126}>{this.state.resources.Label_Creation}</label>
                            </div>

                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 127} defaultValue={127} />
                                <label className="form-check-label" htmlFor={'permission' + 127}>{this.state.resources.Label_Edition}</label>
                            </div>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 125} defaultValue={125} />
                                <label className="form-check-label" htmlFor={'permission' + 125}>{this.state.resources.Label_Reading}</label>
                            </div>
                         </div>
                        }
                        {this.state.abmPermissions.includes(129) &&
                         <div style={{borderLeft: '3px solid red',marginBottom:"30px",}} class="col-md-2">
                            <p><b>ABM {this.state.resources.Title_Campaing}</b></p>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 130} defaultValue={130} />
                                <label className="form-check-label" htmlFor={'permission' + 130}>{this.state.resources.Label_Creation}</label>
                            </div>

                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 131} defaultValue={131} />
                                <label className="form-check-label" htmlFor={'permission' + 131}>{this.state.resources.Label_Edition}</label>
                            </div>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 129} defaultValue={129} />
                                <label className="form-check-label" htmlFor={'permission' + 129}>{this.state.resources.Label_Reading}</label>
                            </div>
                         </div>
                        }
                        {this.state.abmPermissions.includes(132) &&
                         <div style={{borderLeft: '3px solid red',marginBottom:"30px",}} class="col-md-2">
                            <p><b>ABM {this.state.resources.Title_ProductCategory}</b></p>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 133} defaultValue={133} />
                                <label className="form-check-label" htmlFor={'permission' + 133}>{this.state.resources.Label_Creation}</label>
                            </div>

                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 134} defaultValue={134} />
                                <label className="form-check-label" htmlFor={'permission' + 134}>{this.state.resources.Label_Edition}</label>
                            </div>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 132} defaultValue={132} />
                                <label className="form-check-label" htmlFor={'permission' + 132}>{this.state.resources.Label_Reading}</label>
                            </div>
                         </div>
                        }
                        {this.state.abmPermissions.includes(135) &&
                         <div style={{borderLeft: '3px solid red',marginBottom:"30px",}} class="col-md-2">
                            <p><b>ABM {this.state.resources.Title_MapCourierCity}</b></p>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 136} defaultValue={136} />
                                <label className="form-check-label" htmlFor={'permission' + 136}>{this.state.resources.Label_Creation}</label>
                            </div>

                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 137} defaultValue={137} />
                                <label className="form-check-label" htmlFor={'permission' + 137}>{this.state.resources.Label_Edition}</label>
                            </div>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 135} defaultValue={135} />
                                <label className="form-check-label" htmlFor={'permission' + 135}>{this.state.resources.Label_Reading}</label>
                            </div>
                         </div>
                        }
                        {this.state.abmPermissions.includes(139) &&
                         <div style={{borderLeft: '3px solid red',marginBottom:"30px",}} class="col-md-2">
                            <p><b>ABM {this.state.resources.Label_Domains}</b></p>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 140} defaultValue={140} />
                                <label className="form-check-label" htmlFor={'permission' + 140}>{this.state.resources.Label_Creation}</label>
                            </div>

                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 141} defaultValue={141} />
                                <label className="form-check-label" htmlFor={'permission' + 141}>{this.state.resources.Label_Edition}</label>
                            </div>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 139} defaultValue={139} />
                                <label className="form-check-label" htmlFor={'permission' + 139}>{this.state.resources.Label_Reading}</label>
                            </div>
                         </div>
                        }

                        {this.state.abmPermissions.includes(5) &&
                         <div style={{borderLeft: '3px solid red',marginBottom:"30px",}} class="col-md-2">
                            <p><b>ABM {this.state.resources.Title_Companies}</b></p>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 29} defaultValue={29} />
                                <label className="form-check-label" htmlFor={'permission' + 29}>{this.state.resources.Label_Creation}</label>
                            </div>

                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 32} defaultValue={32} />
                                <label className="form-check-label" htmlFor={'permission' + 32}>{this.state.resources.Label_Edition}</label>
                            </div>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 5} defaultValue={5} />
                                <label className="form-check-label" htmlFor={'permission' + 5}>{this.state.resources.Label_Reading}</label>
                            </div>
                         </div>
                        }

                        {this.state.abmPermissions.includes(144) &&
                         <div style={{borderLeft: '3px solid red',marginBottom:"30px",}} class="col-md-2">
                            <p><b>ABM {this.state.resources.Title_WayPay}</b></p>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 145} defaultValue={145} />
                                <label className="form-check-label" htmlFor={'permission' + 145}>{this.state.resources.Label_Creation}</label>
                            </div>

                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 146} defaultValue={146} />
                                <label className="form-check-label" htmlFor={'permission' + 146}>{this.state.resources.Label_Edition}</label>
                            </div>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 144} defaultValue={144} />
                                <label className="form-check-label" htmlFor={'permission' + 144}>{this.state.resources.Label_Reading}</label>
                            </div>
                         </div>
                        }
                        {this.state.abmPermissions.includes(147) &&
                         <div style={{borderLeft: '3px solid red',marginBottom:"30px",}} class="col-md-2">
                            <p><b>ABM {this.state.resources.Titile_ClosingReasons}</b></p>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 148} defaultValue={148} />
                                <label className="form-check-label" htmlFor={'permission' + 148}>{this.state.resources.Label_Creation}</label>
                            </div>

                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 149} defaultValue={149} />
                                <label className="form-check-label" htmlFor={'permission' + 149}>{this.state.resources.Label_Edition}</label>
                            </div>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 147} defaultValue={147} />
                                <label className="form-check-label" htmlFor={'permission' + 147}>{this.state.resources.Label_Reading}</label>
                            </div>
                         </div>
                        }
                        {this.state.abmPermissions.includes(152) &&
                         <div style={{borderLeft: '3px solid red',marginBottom:"30px",}} class="col-md-2">
                            <p><b>ABM {this.state.resources.NavItem_TreatmentQuestion}</b></p>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 153} defaultValue={153} />
                                <label className="form-check-label" htmlFor={'permission' + 153}>{this.state.resources.Label_Creation}</label>
                            </div>

                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 154} defaultValue={154} />
                                <label className="form-check-label" htmlFor={'permission' + 154}>{this.state.resources.Label_Edition}</label>
                            </div>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 152} defaultValue={152} />
                                <label className="form-check-label" htmlFor={'permission' + 152}>{this.state.resources.Label_Reading}</label>
                            </div>
                         </div>
                        }
                        {this.state.abmPermissions.includes(1150) &&
                         <div style={{borderLeft: '3px solid red',marginBottom:"30px",}} class="col-md-2">
                            <p><b>ABM {this.state.resources.Title_Clients}</b></p>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 1151} defaultValue={1151} />
                                <label className="form-check-label" htmlFor={'permission' + 1151}>{this.state.resources.Label_Creation}</label>
                            </div>

                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 1152} defaultValue={1152} />
                                <label className="form-check-label" htmlFor={'permission' + 1152}>{this.state.resources.Label_Edition}</label>
                            </div>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 1150} defaultValue={1150} />
                                <label className="form-check-label" htmlFor={'permission' + 1150}>{this.state.resources.Label_Reading}</label>
                            </div>
                         </div>
                        }
                        {this.state.abmPermissions.includes(2151) &&
                         <div style={{borderLeft: '3px solid red',marginBottom:"30px",}} class="col-md-2">
                            <p><b>ABM {this.state.resources.Label_ProductInformation}</b></p>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 2152} defaultValue={2152} />
                                <label className="form-check-label" htmlFor={'permission' + 2152}>{this.state.resources.Label_Creation}</label>
                            </div>

                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 2153} defaultValue={2153} />
                                <label className="form-check-label" htmlFor={'permission' + 2153}>{this.state.resources.Label_Edition}</label>
                            </div>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 2151} defaultValue={2151} />
                                <label className="form-check-label" htmlFor={'permission' + 2151}>{this.state.resources.Label_Reading}</label>
                            </div>
                         </div>
                        }
                        {this.state.abmPermissions.includes(2154) &&
                         <div style={{borderLeft: '3px solid red',marginBottom:"30px",}} class="col-md-2">
                            <p><b>ABM {this.state.resources.Title_CountryWayPay}</b></p>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 2155} defaultValue={2155} />
                                <label className="form-check-label" htmlFor={'permission' + 2155}>{this.state.resources.Label_Creation}</label>
                            </div>

                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 2156} defaultValue={2156} />
                                <label className="form-check-label" htmlFor={'permission' + 2156}>{this.state.resources.Label_Edition}</label>
                            </div>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 2154} defaultValue={2154} />
                                <label className="form-check-label" htmlFor={'permission' + 2154}>{this.state.resources.Label_Reading}</label>
                            </div>
                         </div>
                        }

                        {this.state.abmPermissions.includes(2159) &&
                         <div style={{borderLeft: '3px solid red',marginBottom:"30px",}} class="col-md-2">
                            <p><b>ABM {this.state.resources.Label_Bank}</b></p>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 2160} defaultValue={2160} />
                                <label className="form-check-label" htmlFor={'permission' + 2160}>{this.state.resources.Label_Creation}</label>
                            </div>

                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 2162} defaultValue={2162} />
                                <label className="form-check-label" htmlFor={'permission' + 2162}>{this.state.resources.Label_Edition}</label>
                            </div>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 2159} defaultValue={2159} />
                                <label className="form-check-label" htmlFor={'permission' + 2159}>{this.state.resources.Label_Reading}</label>
                            </div>
                         </div>
                        }

                        {this.state.abmPermissions.includes(2166) &&
                         <div style={{borderLeft: '3px solid red',marginBottom:"30px",}} class="col-md-2">
                            <p><b>ABM {this.state.resources.Label_ProductPayment}</b></p>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 2167} defaultValue={2167} />
                                <label className="form-check-label" htmlFor={'permission' + 2167}>{this.state.resources.Label_Creation}</label>
                            </div>

                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 2168} defaultValue={2168} />
                                <label className="form-check-label" htmlFor={'permission' + 2168}>{this.state.resources.Label_Edition}</label>
                            </div>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 2166} defaultValue={2166} />
                                <label className="form-check-label" htmlFor={'permission' + 2166}>{this.state.resources.Label_Reading}</label>
                            </div>
                         </div>
                        }

                        {this.state.abmPermissions.includes(2169) &&
                         <div style={{borderLeft: '3px solid red',marginBottom:"30px",}} class="col-md-2">
                            <p><b>ABM {this.state.resources.Label_ProductPaymentWayPay}</b></p>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 2170} defaultValue={2170} />
                                <label className="form-check-label" htmlFor={'permission' + 2170}>{this.state.resources.Label_Creation}</label>
                            </div>

                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 2171} defaultValue={2171} />
                                <label className="form-check-label" htmlFor={'permission' + 2171}>{this.state.resources.Label_Edition}</label>
                            </div>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 2169} defaultValue={2169} />
                                <label className="form-check-label" htmlFor={'permission' + 2169}>{this.state.resources.Label_Reading}</label>
                            </div>
                         </div>
                        }

                        {this.state.abmPermissions.includes(2172) &&
                         <div style={{borderLeft: '3px solid red',marginBottom:"30px",}} class="col-md-2">
                            <p><b>ABM {this.state.resources.Label_KitProducts}</b></p>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 2173} defaultValue={2173} />
                                <label className="form-check-label" htmlFor={'permission' + 2173}>{this.state.resources.Label_Creation}</label>
                            </div>

                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 2174} defaultValue={2174} />
                                <label className="form-check-label" htmlFor={'permission' + 2174}>{this.state.resources.Label_Edition}</label>
                            </div>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 2172} defaultValue={2172} />
                                <label className="form-check-label" htmlFor={'permission' + 2172}>{this.state.resources.Label_Reading}</label>
                            </div>
                         </div>
                        }
                        {this.state.abmPermissions.includes(2178) &&
                         <div style={{borderLeft: '3px solid red',marginBottom:"30px",}} class="col-md-2">
                            <p><b>ABM {this.state.resources.Title_Publicity}</b></p>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 2181} defaultValue={2181} />
                                <label className="form-check-label" htmlFor={'permission' + 2181}>{this.state.resources.Label_Creation}</label>
                            </div>

                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 2182} defaultValue={2182} />
                                <label className="form-check-label" htmlFor={'permission' + 2182}>{this.state.resources.Label_Edition}</label>
                            </div>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 2178} defaultValue={2178} />
                                <label className="form-check-label" htmlFor={'permission' + 2178}>{this.state.resources.Label_Reading}</label>
                            </div>
                         </div>
                        }
                        {this.state.abmPermissions.includes(2184) &&
                         <div style={{borderLeft: '3px solid red',marginBottom:"30px",}} class="col-md-2">
                            <p><b>{this.state.resources.Label_Export}</b></p>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 2184} defaultValue={2184} />
                                <label className="form-check-label" htmlFor={'permission' + 2184}>{this.state.resources.Label_Reading}</label>
                            </div>
                         </div>
                        }
                        {this.state.abmPermissions.includes(2185) &&
                         <div style={{borderLeft: '3px solid red',marginBottom:"30px",}} class="col-md-2">
                            <p><b>{this.state.resources.Label_ImportRoute}</b></p>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 2185} defaultValue={2185} />
                                <label className="form-check-label" htmlFor={'permission' + 2185}>{this.state.resources.Label_Edition}</label>
                            </div>
                         </div>
                        }
                        {this.state.abmPermissions.includes(2187) &&
                         <div style={{borderLeft: '3px solid red',marginBottom:"30px",}} class="col-md-2">
                            <p><b>{this.state.resources.Title_SalesHistory}</b></p>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 2187} defaultValue={2187} />
                                <label className="form-check-label" htmlFor={'permission' + 2187}>{this.state.resources.Label_Edition}</label>
                            </div>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 2197} defaultValue={2197} />
                                <label className="form-check-label" htmlFor={'permission' + 2197}>{this.state.resources.ButtonLabel_ViewEdit}</label>
                            </div>
                         </div>
                        }
                        {this.state.abmPermissions.includes(2189) &&
                         <div style={{borderLeft: '3px solid red',marginBottom:"30px",}} class="col-md-2">
                            <p><b>{this.state.resources.Title_MySales}</b></p>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 2189} defaultValue={2189} />
                                <label className="form-check-label" htmlFor={'permission' + 2189}>{this.state.resources.Label_Edition}</label>
                            </div>
                         </div>
                        }
                        {this.state.abmPermissions.includes(2191) &&
                         <div style={{borderLeft: '3px solid red',marginBottom:"30px",}} class="col-md-2">
                            <p><b>{this.state.resources.Title_MySalesPendingPayment}</b></p>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 2191} defaultValue={2191} />
                                <label className="form-check-label" htmlFor={'permission' + 2191}>{this.state.resources.Label_Edition}</label>
                            </div>
                         </div>
                        }
                        {this.state.abmPermissions.includes(2200) &&
                         <div style={{borderLeft: '3px solid red',marginBottom:"30px",}} class="col-md-2">
                            <p><b>{this.state.resources.Tittle_LogMapCourierCity}</b></p>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 2200} defaultValue={2200} />
                                <label className="form-check-label" htmlFor={'permission' + 2200}>{this.state.resources.Label_Reading}</label>
                            </div>
                         </div>
                        }
                        {this.state.abmPermissions.includes(2201) &&
                         <div style={{borderLeft: '3px solid red',marginBottom:"30px",}} class="col-md-2">
                            <p><b>{this.state.resources.Title_KeysSKU}</b></p>
                            <div className="p-1 pl-4 m-1">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + 2201} defaultValue={2201} />
                                <label className="form-check-label" htmlFor={'permission' + 2201}>{this.state.resources.Label_Edition}</label>
                            </div>
                         </div>
                        }
                    </div>
                </div>
                
                {/* <div className="form-group col-md-12" >
                    <label className=" form-label">{this.state.resources.Label_Permissions}</label>
                    <div className="col-md-12">
                        {this.state.permissions.map(permission =>
                            <div key={permission.id} className="form-check form-check-inline col-md-3">
                                <input className="form-check-input" type="checkbox" onChange={this.onChangePermissionIds} id={'permission' + permission.id} defaultValue={permission.id} />
                                <label className="form-check-label" htmlFor={'permission' + permission.id}>{permission.description}</label>
                            </div>
                        )}
                    </div>
                </div> */}
                
                {/**Buttons Header */}
                 <div className="buttons-head row">
                    <div className=" buttons-childs">
                        <button type="submit" className="btn btn-primary">{this.state.resources.ButtonLabel_Save}</button>
                        <button className="btn btn-danger" onClick={this.onCancel}>{this.state.resources.ButtonLabel_Cancel}</button>
                    </div>
                 </div>
            </form>
            // helpers.hideLoadingOverlay();

        return (
            <div className="mt-2">
                {/* <h1 className="mt-4">{this.state.resources.Title_PermissionGroups}</h1> */}
                <ol className="breadcrumb my-4">
                    <li className="breadcrumb-item active">{this.state.resources.Title_PermissionGroups}</li>
                </ol>
                <div className="card">
                    <div className="card-body">
                        {contents}
                    </div>
                </div>
            </div>
        );
    }

    async getResources() {
        const resourceVars = [];
        resourceVars.push("Title_PermissionGroups");
        resourceVars.push("Label_Name");
        resourceVars.push("Label_Description");
        resourceVars.push("Label_Permissions");
        resourceVars.push("Label_Enabled");
        resourceVars.push("Label_Success");
        resourceVars.push("ButtonLabel_Save");
        resourceVars.push("ButtonLabel_Cancel");
        resourceVars.push("Label_Error");
        resourceVars.push("ErrorMessage_RequiredField");
        resourceVars.push("ErrorMessage_DuplicatedName");
        resourceVars.push("ErrorMessage_UnknownError");
        resourceVars.push("ErrorMessage_PermissionGroupMustHaveOnePermission");
        resourceVars.push("SuccessMessage_DataSavedSuccessfully");
        resourceVars.push("Label_Edition");
        resourceVars.push("Label_Creation");
        resourceVars.push("Label_Reading");
        resourceVars.push("Title_Areas");
        resourceVars.push("Title_Companies");
        resourceVars.push("Title_Logistic");
        resourceVars.push("Title_Business");
        resourceVars.push("Title_Menu");
        resourceVars.push("Title_Media");
        resourceVars.push("NavItem_Configurations");
        resourceVars.push("NavItem_Security");
        resourceVars.push("NavItem_Suppliers");
        resourceVars.push("NavItem_BusinessAreas");
        resourceVars.push("NavItem_SubAreas");
        resourceVars.push("NavItem_Brands");
        resourceVars.push("NavItem_Countries");
        resourceVars.push("NavItem_Permissions");
        resourceVars.push("NavItem_PermissionGroups");
        resourceVars.push("NavItem_Users");
        resourceVars.push("NavItem_AuthorizationTokens");
        resourceVars.push("NavItem_LanguageResources");
        resourceVars.push("NavItem_ApiClients");
        resourceVars.push("NavItem_Treatments");
        resourceVars.push("Title_Formats");
        resourceVars.push("Title_Logs");
        resourceVars.push("NavItem_Courier");
        resourceVars.push("NavItem_Sellers");
        resourceVars.push("Title_Objetions");
        resourceVars.push("Title_ScriptSales");
        resourceVars.push("Label_Products");
        resourceVars.push("Title_MediaType");
        resourceVars.push("Title_MediaGroup"); 
        resourceVars.push("Label_ProductCountry");
        resourceVars.push("Title_ProductCountryKey");
        resourceVars.push("Title_Agency");
        resourceVars.push("Title_Media");
        resourceVars.push("Title_Pbx");
        resourceVars.push("Title_PbxDatabase");
        resourceVars.push("Title_Campaing");
        resourceVars.push("Title_Carrier");
        resourceVars.push("Title_CampaingType");
        resourceVars.push("Title_State");
        resourceVars.push("Title_City");
        resourceVars.push("Title_ProductCategory");
        resourceVars.push("Title_MapCourierCity");
        resourceVars.push("Label_Domains");
        resourceVars.push("Title_WayPay");
        resourceVars.push("Titile_ClosingReasons");
        resourceVars.push("NavItem_TreatmentQuestion");
        resourceVars.push("Label_Sales");
        resourceVars.push("Title_Clients");
        resourceVars.push("Label_ProductInformation");
        resourceVars.push("Title_CountryWayPay");
        resourceVars.push("Label_Bank");
        resourceVars.push("Label_ProductPayment");
        resourceVars.push("Label_ProductPaymentWayPay");
        resourceVars.push("Label_KitProducts");
        resourceVars.push("Title_Publicity");
        resourceVars.push("Label_Export");
        resourceVars.push("Label_ImportRoute");
        resourceVars.push("Title_SalesHistory");
        resourceVars.push("Title_MySales");
        resourceVars.push("Title_MySalesPendingPayment");
        resourceVars.push("ButtonLabel_ViewEdit");
        resourceVars.push("Tittle_LogMapCourierCity");
        resourceVars.push("Title_KeysSKU");

        const headers = {
            'Content-Type': 'application/json'
        };

        axios.post(window.config.REACT_APP_SECURITY_API_URL + '/languageresource/getbyvars/' + helpers.getUiLanguage(), resourceVars, {
            headers: headers
        })
            .then((response) => {
                const data = response.data.data;
                this.setState({ resources: data, loading: false });
            })
            .catch((error) => {
                console.log(error);
                this.setState({ loading: false });
            });
    }

    async populateData() {
        this.setState({
            loading: false
        });

        axios.get(window.config.REACT_APP_SECURITY_API_URL + '/permission/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_SECURITY_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ permissions: data, loading: false });

                for (let i = 0; i < this.state.permissions.length; i++){
                    if (this.state.permissions[i].description.includes('Menu')) {
                        this.setState({
                            menuPermissions: this.state.menuPermissions.concat(this.state.permissions[i].id)
                        })
                    }
                }

                for (let i = 0; i < this.state.permissions.length; i++){
                    if (this.state.permissions[i].description.includes('Admin')) {
                        this.setState({
                            abmPermissions: this.state.abmPermissions.concat(this.state.permissions[i].id)
                        })
                    }
                }
                helpers.hideLoadingOverlay();
            })
            .catch(function (error) {
                console.log(error);
            });
    }
}
