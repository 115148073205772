import React, { useState, Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';  
import $ from 'jquery';
import DataTable from 'datatables.net';
import helpers from '../../Helpers';
import '../../custom.css';
import { DataGrid, useGridApiRef, esES } from '@mui/x-data-grid';
import TextField from '@mui/material/TextField';
import DateRangePicker from '@mui/lab/DateRangePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { DataGridPro } from '@mui/x-data-grid-pro';


export class ProductCountryKeyAll extends Component {
    static displayName = ProductCountryKeyAll.name;



    constructor(props) 
    {
        super(props);
        this.onSubmitEnabled = this.onSubmitEnabled.bind(this);
        this.onSubmitDisabled = this.onSubmitDisabled.bind(this);
        this.state = { 
            items: [],
            resources: [],
            loading: true,
            permissionsIds: [],
            columns: [],
            paging: null,
            sorting: null,
            filtering: null,
            itemsToUpdate: [],
            userId: 0
        };
    }


    componentDidMount() {
        let userData = helpers.getUserData();
        this.state.permissionsIds = userData.permission_ids;
        this.state.userId = userData.id;
        this.getResources();
        this.populateData();
        helpers.setNavActive("#nav-areas");
        localStorage.setItem('ProductCountryKeyItemsCheck', JSON.stringify([]));
        this.state.sorting = JSON.parse(localStorage.getItem('CopyAreaListSorting'));
        this.state.paging = JSON.parse(localStorage.getItem('CopyAreaListPaging'));
        this.state.filtering = JSON.parse(localStorage.getItem('CopyAreaListFiltering'));
    }

    
    
      handleCheckChieldElement = event => {
        let fruites = this.state.items;
        fruites.forEach(fruite => {
          if (fruite.value === event.target.value)
            fruite.isChecked = event.target.checked;
        });
        this.setState({ items: fruites });
        console.log(fruites);
      };

    async onSubmitEnabled(e) {

        console.log('ENLA');

        //e.preventDefault();
        
        let itemss = [];

        if (localStorage.getItem("ProductCountryKeyItemsCheck")) 
        {
            itemss = JSON.parse(localStorage.getItem("ProductCountryKeyItemsCheck"));
        }
        
        if(itemss.length == 0)
        {
            helpers.notifyWarning('Error','Debe seleccionar al menos un item para continuar');
        }
        else
        {
            this.setState({ loading: true });
            let dataIds = [];
            for (let i = 0; i < itemss.length; i++)
            {
                dataIds.push(itemss[i].id);
            }
            const data = {
                user_id: this.state.userId,
                items: dataIds
            };
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME)
            };
    
            axios.post(window.config.REACT_APP_PRODUCTS_API_URL + '/productcountrykey/enableall', data, {
                headers: headers
            })
                .then((response) => 
                {
                    helpers.notifySuccess(this.state.resources.Label_Success, this.state.resources.SuccessMessage_DataSavedSuccessfully);
                    this.setState({ loading: false });
                    setTimeout(() => {
                        window.location.reload();
                    }, 3000);
                    
                })
                .catch((error) => {
                    console.log(error);
    
                    let msg = this.state.resources.ErrorMessage_UnknownError;
                    if (error.response.data.error_code == window.errorCodes.DuplicatedEntityName)
                        msg = this.state.resources.ErrorMessage_DuplicatedName;
                    else if (error.response.data.error_code == window.errorCodes.AssociatedEntity)
                        msg = this.state.resources.ErrorMessage_AssociatedEntity_Company_User;
    
                    helpers.notifyError(this.state.resources.Label_Error, msg);
                    this.setState({ loading: false });
                });
        }
        
    }  

    async onSubmitDisabled(e) {
        //e.preventDefault();

        console.log('DISALA');

        
        

        let itemss = [];

        if (localStorage.getItem("ProductCountryKeyItemsCheck")) 
        {
            itemss = JSON.parse(localStorage.getItem("ProductCountryKeyItemsCheck"));
        }
        
        if(itemss.length == 0)
        {
            helpers.notifyWarning('Error','Debe seleccionar al menos un item para continuar');
        }
        else
        {
            this.setState({ loading: true });
            let dataIds = [];
            for (let i = 0; i < itemss.length; i++)
            {
                dataIds.push(itemss[i].id);
            }
            const data = {
                user_id: this.state.userId,
                items: dataIds
            };   
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME)
            };
    
            axios.post(window.config.REACT_APP_PRODUCTS_API_URL + '/productcountrykey/disableall', data, {
                headers: headers
            })
                .then((response) => 
                {
                    helpers.notifySuccess(this.state.resources.Label_Success, this.state.resources.SuccessMessage_DataSavedSuccessfully);
                    this.setState({ loading: false });
                    setTimeout(() => {
                        window.location.reload();
                    }, 3000);
                    
                })
                .catch((error) => {
                    console.log(error);
    
                    let msg = this.state.resources.ErrorMessage_UnknownError;
                    if (error.response.data.error_code == window.errorCodes.DuplicatedEntityName)
                        msg = this.state.resources.ErrorMessage_DuplicatedName;
                    else if (error.response.data.error_code == window.errorCodes.AssociatedEntity)
                        msg = this.state.resources.ErrorMessage_AssociatedEntity_Company_User;
    
                    helpers.notifyError(this.state.resources.Label_Error, msg);
                    this.setState({ loading: false });
                });
        }
        
    }  

    static renderTable(items, resources, permissionsIds, columns, sortOptions, pagingOptions) { //
        // let page = pagingOptions.page;
        // let pageSize = pagingOptions.pageSize;
          return (
                // <table className='table datatable my-table' aria-labelledby="tabelLabel"> {/*table class:* table-striped datatable */}
                //     <thead>
                //         <tr>
                //           <th>{resources.Label_ID}</th>
                //           <th>{resources.Label_Name}</th>
                //           <th>{resources.Label_Description}</th>
                //           <th>{resources.Label_Status}</th>
                           
                //               <th className="text-center">{resources.Label_Actions}</th>
                            
                //         </tr>
                //     </thead>
                //     <tbody>
                //         {
                //             items.map(item =>
                //                 <tr key={item.id}>
                //                     <td>{item.id}</td>
                //                     <td>{item.name}</td>
                //                     <td>{item.description}</td>
                //                     <td>{item.enabled == true ? resources.Label_Enabled : resources.Label_Disabled}</td>
                //                     {permissionsIds.includes(38) ?
                //                     <td className="text-center">
                //                         <Link to={"/area-detail/" + item.id} className="btn btn-default"><i className="fas fa-edit"></i></Link>
                //                     </td>
                //                     : 
                //                      <td className="text-center">
                //                         <Link to={"/area-view/" + item.id} className="btn btn-default"><i class="fas fa-eye"></i></Link>
                //                       </td>}
                //                 </tr>
                //             )}
                //     </tbody>
                // </table>
              
              <div style={{ height: 500, width: '99%' }}>
                  <DataGrid rows={items} columns={columns} rowsPerPageOptions={[5, 10, 25, 50, 100]}
                      
                      onStateChange={(state) => {
                        //   console.log(state)
                          //   console.log(state.pagination);
                          localStorage.setItem('AreaListPagination', JSON.stringify(state.pagination))
                          //   console.log(JSON.parse(localStorage.getItem('AreaListPagination')))
                          //   console.log(state.sorting.sortedRows)
                          localStorage.setItem('AreaListSorting', JSON.stringify(state.sorting))
                          //   console.log(JSON.parse(localStorage.getItem('AreaListSorting')))
                      }}
                      state={
                          
                          sortOptions !== null ? {
                              sorting: sortOptions
                          } : {}
                          
                        //   pagingOptions !== null ? 
                        //     {
                        //       pagination: pagingOptions
                        //     } : {}
                          
                      }
                      
                    />
              </div>
        );
        
      }

    render() {
        // let contents = 
          let contents = this.state.loading
              ? helpers.showLoadingOverlay() : <AreaDataGrid data={this.state}/>
            // : AreasList.renderTable(this.state.items, this.state.resources, this.state.permissionsIds, this.state.columns, this.state.sorting, this.state.paging); //

          return (
              <div className="mt-2">
                  <ol className="breadcrumb my-4">
                      <li className="breadcrumb-item active">{this.state.resources.NavItem_EDKeys}</li>
                  </ol>
                  <div className="card">
                      <div className="card-header">
                      
                        <button id="btnEnabled" onClick={this.onSubmitEnabled} style={{ display: 'none' }} className="btn btn-success">{this.state.resources.Label_Enable}</button>
                        &nbsp;&nbsp;
                        <button id="btnDisabled" onClick={this.onSubmitDisabled}  className="btn btn-danger">{this.state.resources.Label_Disable}</button>
                        </div>
                      <div className="card-body">
                          <div className="table-responsive">
                              {contents}
                          </div>
                      </div>
                  </div>
              </div>
        );
      }

    async populateData() {
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/productcountrykey/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                localStorage.setItem('AreaFilterItems', JSON.stringify(data));
                this.setState({ items: data, loading: false });
                $('.datatable').DataTable({
                    "language": {
                        "lengthMenu": this.state.resources.Datatables_language_lengthMenu,
                        "zeroRecords": this.state.resources.Datatables_language_zeroRecords,
                        "info": this.state.resources.Datatables_language_info,
                        "infoEmpty": this.state.resources.Datatables_language_infoEmpty,
                        "infoFiltered": this.state.resources.Datatables_language_infoFiltered,
                        "search": this.state.resources.Datatables_language_search,
                        "paginate": {
                            "previous": this.state.resources.Datatables_language_paginate_previous,
                            "next": this.state.resources.Datatables_language_paginate_next
                        },
                        "autoWidth": false
                    },
                    stateSave: true,
                    stateSaveCallback: function (settings, data) {
                        localStorage.setItem('DataTables_b', JSON.stringify(data))
                        },
                    stateLoadCallback: function(settings) {
                        return JSON.parse(localStorage.getItem( 'DataTables_b'))
                        }
                });

                helpers.hideLoadingOverlay();
            })
            .catch(function (error) {
                console.log(error);
                helpers.hideLoadingOverlay();
            });
    }

    async getResources() {
        const resourceVars = [];
        resourceVars.push("Title_Areas");
        resourceVars.push("Label_ID");
        resourceVars.push("Label_Name");
        resourceVars.push("Label_Description");
        resourceVars.push("Label_Actions");
        resourceVars.push("Label_Status");
        resourceVars.push("Label_Enabled");
        resourceVars.push("Label_Disabled");
        resourceVars.push("ButtonLabel_ViewEdit");
        resourceVars.push("ButtonLabel_New");
        resourceVars.push("Datatables_language_lengthMenu");
        resourceVars.push("Datatables_language_zeroRecords");
        resourceVars.push("Datatables_language_info");
        resourceVars.push("Datatables_language_infoEmpty");
        resourceVars.push("Datatables_language_infoFiltered");
        resourceVars.push("Datatables_language_search");
        resourceVars.push("Datatables_language_paginate_previous");
        resourceVars.push("Datatables_language_paginate_next");
        resourceVars.push("Label_Creation");
        resourceVars.push("Label_FilterOff");
        resourceVars.push("Label_Filter");
        resourceVars.push("Label_Sort");
        resourceVars.push("Label_Hide");
        resourceVars.push("Label_ShowColumns");
        resourceVars.push("Label_OrderAsc");
        resourceVars.push("Label_OrderDesc");
        resourceVars.push("Label_Unsorting");
        resourceVars.push("Label_Columns");
        resourceVars.push("Label_Delete");
        resourceVars.push("Label_GridValue");
        resourceVars.push("Label_ValueFilter");
        resourceVars.push("Label_And");
        resourceVars.push("Label_Or");
        resourceVars.push("Label_Operator");
        resourceVars.push("Label_NoRows");
        resourceVars.push("Label_SelectColumns");
        resourceVars.push("Label_OperatorAfter");
        resourceVars.push("Label_OperatorBefore");
        resourceVars.push("Label_OperatorContains");
        resourceVars.push("Label_OperatorEnds");
        resourceVars.push("Label_OperatorEquals");
        resourceVars.push("Label_OperatorIs");
        resourceVars.push("Label_OperatorIsEmpty");
        resourceVars.push("Label_OperatorIsNotEmpty");
        resourceVars.push("Label_OperatorIsNot");
        resourceVars.push("Label_OperatorIsOnOrAfter");
        resourceVars.push("Label_OperatorIsOnOrBefore");
        resourceVars.push("Label_OperatorStarts");
        resourceVars.push("Label_ReorderColumns");
        resourceVars.push("Label_HideAll");
        resourceVars.push("Label_ShowAll");
        resourceVars.push("Label_FindColumn");
        resourceVars.push("Label_TitleColumn");
        resourceVars.push("Label_addFilter");
        resourceVars.push("NavItem_EDKeys");
        resourceVars.push("Label_SelectAll");
        resourceVars.push("Label_DeselectAll");
        resourceVars.push("Label_Enable");
        resourceVars.push("Label_Disable");
        resourceVars.push("Label_Description");
        resourceVars.push("Label_Enabled");
        resourceVars.push("Label_Success");
        resourceVars.push("ButtonLabel_Save");
        resourceVars.push("ButtonLabel_Cancel");
        resourceVars.push("Label_Error");
        resourceVars.push("ErrorMessage_RequiredField");
        resourceVars.push("ErrorMessage_DuplicatedName");
        resourceVars.push("ErrorMessage_UnknownError");
        resourceVars.push("SuccessMessage_DataSavedSuccessfully");

        const headers = {
            'Content-Type': 'application/json'
        };

        axios.post(window.config.REACT_APP_SECURITY_API_URL + '/languageresource/getbyvars/' + helpers.getUiLanguage(), resourceVars, {
            headers: headers
        })
            .then((response) => {
                const data = response.data.data;
                this.setState({ resources: data, loading: false });

                this.state.columns = [
                    /*{
                        field: 'actions',
                        headerAlign: "center",
                        headerClassName: "header-table-mui",
                        headerName: this.state.resources.Label_Actions ? this.state.resources.Label_Actions : 'Actions',
                        //flex: 1,
                        align: 'center',
                        width: 80,
                        renderCell: (item) => {
                            return (
                                <div>
                                <td className="text-center">
              <input
    key={item.id}
    className="form-check-input"
    onClick={this.handleCheckChieldElement}
    type="checkbox"
    checked={item.isChecked}
    value={item.id}
  />
              <br/>
                                </td>
                                </div>
                            );
                        }
                    },*/
                    {
                        field: 'productcountry_name',
                        headerName: 'Producto',
                        type: 'number',
                        width: 190,
                        headerAlign: "center",
                        align: 'left',
                        headerClassName: "header-table-mui"
                    },
                    {
                        field: 'country_name',
                        headerName: 'Pais',
                        flex: 1,
                        minWidth: 190,
                        headerAling: "center",
                        headerClassName: "header-table-mui"
                    },
                    {
                        field: 'product_key',
                        headerName: 'Clave',
                        flex: 1,
                        minWidth: 200,
                        headerAling: "center",
                        headerClassName: "header-table-mui"
                    },
                    {
                        field: 'description',
                        headerName: this.state.resources.Label_Description ? this.state.resources.Label_Description : 'Description',
                        flex: 1,
                        minWidth: 200,
                        headerAling: "center",
                        headerClassName: "header-table-mui"
                    },
                    {
                        field: 'enabled',
                        headerName: this.state.resources.Label_Status ? this.state.resources.Label_Status : 'Enabled',
                        flex: 1,
                        minWidth: 200,
                        headerAling: "center",
                        headerClassName: "header-table-mui",
                        renderCell: (item) => {
                            // console.log(item)
                            return (
                                <div className="text-center">
                                    {item.row.enabled == true ? this.state.resources.Label_Enabled : this.state.resources.Label_Disabled}
                                </div>
                            );
                        }
                    }
                ];
            })
            .catch((error) => {
                console.log(error);
            });
    }
}


export default function AreaDataGrid(values) {

    let loading = false;
    // if (loading == true) {
    //     setRowsValue(items)
    // }
    
    // items =  localStorage.getItem('AreaItems') ? JSON.parse(localStorage.getItem('AreaItems')) :  values.data.items,
    let items = values.data.items,
        resources = values.data.resources,
        permissionsIds = values.data.permissionsIds, columns = values.data.columns,
        sorting = localStorage.getItem('CopyAreaListSorting') ?
            JSON.parse(localStorage.getItem('CopyAreaListSorting')) : values.data.sorting,
        paging = values.data.paging ? values.data.paging : 0,
        filter = localStorage.getItem('CopyAreaListFiltering') ?
            JSON.parse(localStorage.getItem('CopyAreaListFiltering')) : values.data.filtering
    
    const [page, setPage] = React.useState(paging.page);
    const [pageSize, setPageSize] = React.useState(paging.pageSize);
    const [selectedRows, setSelectedRows] = React.useState([]);
    const [value, setValue] = React.useState([null, null]);
    const [myRows, setRowsValue] = React.useState(values.data.items);
    const [mySortModel, setSortModel] = React.useState([]);
    const [filtering, setFilter] = React.useState(filter);
    const [status, onChangeStatus] = React.useState(true);
    const [open, setOpen] = React.useState(false);
    const openModal = () => setOpen(true);
    const closeModal = () => setOpen(false);

    

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    function setInitial() {
        setTimeout(() => {
            if (document.getElementsByClassName('MuiTablePagination-selectLabel')[0]){
                document.getElementsByClassName('MuiTablePagination-selectLabel')[0].innerHTML = '';
            }
            // loading = true
            if (myRows.length < 1) {
                if (localStorage.getItem('AreaFilterItems')) {
                    let myItems = JSON.parse(localStorage.getItem('AreaFilterItems'));
                    setRowsValue(myItems);
                    setTimeout(() => {
                        localStorage.removeItem('AreaFilterItems');
                    }, 1000);
                } else {
                    setRowsValue(items)
                }
                
            }
            // if (sorting) {
            //     if (sorting.sortModel) {
            //         setSortModel(sorting.sortModel)
            //     }
            // }
        }, 1000)
       
    }
    setInitial()

    const handlePageChange = (newPage) => {
        setPage(newPage);
    };

    const handleAllChecked = (event) => {
        console.log(values.data.items);
        //console.log(this.state.items);
        let fruites = values.data.items;
        fruites.forEach(fruite => (fruite.isChecked = event.target.checked));
        localStorage.setItem('AreaFilterItems', JSON.stringify(fruites));
        console.log(fruites);
        setRowsValue(fruites);
      };

    const handlePageSizeChange = (newPage) => {
        setPageSize(newPage);
    };

    const handleFilterChange = (newFilter) => {
        // console.log(newFilter)
        setFilter(newFilter);
    }

    const handleSortingChange = (sort) => {
        setSortModel(sort);
        localStorage.removeItem('AreaListSorting');
        localStorage.removeItem('CopyAreaListSorting');
        localStorage.setItem('CopyAreaListSorting', JSON.stringify(sort));
    }

    const handleStatus = (e) => {
        e.preventDefault();
        onChangeStatus(e.target.value);
    }

    // const onReloadData =
    async function onReloadData() {
        // console.log(status)
        
        if (status === true || status === 'true') {
            helpers.showLoadingOverlay();
            axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/productcountrykey/get',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                // console.log(response.data.data)
                let newItems = response.data.data;
                setRowsValue(newItems);
                document.getElementById("btnDisabled").style.display = '';
                document.getElementById("btnEnabled").style.display = 'none';
                localStorage.setItem('AreaFilterItems', JSON.stringify(newItems));
                closeModal();
                helpers.hideLoadingOverlay();
             })
            .catch(err => console.log(err));
        } else if (status != true){
            helpers.showLoadingOverlay();
            axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/productcountrykey/getdisabled',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                // console.log(response.data.data)
                let newItems = response.data.data;
                setRowsValue(newItems);
                document.getElementById("btnDisabled").style.display = 'none';
                document.getElementById("btnEnabled").style.display = '';
                localStorage.setItem('AreaFilterItems', JSON.stringify(newItems));
                closeModal();
                helpers.hideLoadingOverlay();
             })
            .catch(err => console.log(err));
        }
    }

    async function resetFilter() {
        helpers.showLoadingOverlay();
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/productcountrykey/getall',
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                let newItems = response.data.data;
                document.getElementById("btnDisabled").style.display = 'none';
                document.getElementById("btnEnabled").style.display = 'none';
                setRowsValue(newItems);
                localStorage.setItem('AreaFilterItems', JSON.stringify(newItems));
                closeModal();
                helpers.hideLoadingOverlay();
             })
            .catch(error => console.log(error));
    }

    return (
        <>
            
        {/* <div style={{marginTop: '7px'}}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateRangePicker
            startText="Desde"
            endText="Hasta"
            value={value}
            style={{ marginBottom: '10px' },{marginTop: '16px'}}
            onChange={newValue => {
              setValue(newValue);
              let datesArr = [];
              for (let i = 0; i < values.data.items.length; i++) {
                if (
                  Date.parse(values.data.items[i].creation) >=
                    Date.parse(newValue[0]) &&
                  Date.parse(values.data.items[i].creation) <= Date.parse(newValue[1])
                ) {
                //   console.log('match: ' + values.data.items[i].creation);
                  datesArr.push(values.data.items[i].creation);
                }
              }
            //   console.log(datesArr);
            //   console.log(values.data.items.filter(x => x.creation == datesArr[0]));
              let newDates = values.data.items.filter(
                x => x.creation >= datesArr[0] && x.creation <= datesArr[1]
                );
                // console.log(newDates);
                // setRowsValue(newDates);
                localStorage.setItem('AreaItems', JSON.stringify(newDates))
                // if (newDates.length > 0) {
                //     // console.log(newDates)
                //     localStorage.setItem('AreaItems', JSON.stringify(newDates))
                // } else {
                //     // setRowsValue([]);
                //     // localStorage.removeItem('AreaItems')
                // }
                
                // console.log(newValue);
              if (newValue[0] === null || newValue == [null,null] || newValue[1] === null) {
                //   setRowsValue(values.data.items);
                  localStorage.setItem('AreaItems', JSON.stringify(values.data.items))
                //   localStorage.removeItem('AreaItems')
              }
            }}
            renderInput={(startProps, endProps) => (
              <React.Fragment>
                <TextField {...startProps} />
                <Box sx={{ mx: 2 }}> to </Box>
                <TextField {...endProps} />
              </React.Fragment>
            )}
          />
        </LocalizationProvider>
      </div>
      <br /> */}
        <button className="btn btn-success mb-1" onClick={openModal}><i class="fas fa-filter"></i></button> 
        <Modal
            open={open}
            onClose={closeModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <div className="dis-flex m-1" style={{justifyContent: 'space-beetween'}}>
                    <div>
                        <label className="label-text">{values.data.resources.Label_Status}</label>
                        <select className="form-control" data-val="true" name="productId" value={status} onChange={handleStatus}>
                            <option value="true">{values.data.resources.Label_Enabled}</option>
                            <option value="false">{values.data.resources.Label_Disabled}</option>
                        </select>
                    </div>
                    <div className="mt-4">
                        <button className="btn btn-primary mx-1 my-auto" onClick={onReloadData}>{values.data.resources.Label_Filter}</button>
                    </div>
                    
                </div>
            </Box>
        </Modal>
            <div style={{ height: 450, width: '99%' }}>
                <DataGridPro checkboxSelection
        onSelectionModelChange={(ids) => {
          const selectedIDs = new Set(ids);
          const selectedRows = myRows.filter((row) =>
            selectedIDs.has(row.id),
          );
          localStorage.setItem('ProductCountryKeyItemsCheck', JSON.stringify(selectedRows));
          setSelectedRows(selectedRows);
        }} rows={myRows} columns={columns} rowsPerPageOptions={[5, 10, 25, 50, 100]} density='compact'
                pagination
                page={page}
                localeText={{
                    'columnMenuFilter': values.data.resources.Label_Filter,
                    'columnHeaderSortIconLabel': values.data.resources.Label_Sort,
                    'columnMenuHideColumn': values.data.resources.Label_Hide,
                    'columnMenuShowColumns': values.data.resources.Label_ShowColumns,
                    'columnMenuSortAsc': values.data.resources.Label_OrderAsc,
                    'columnMenuSortDesc': values.data.resources.Label_OrderDesc,
                    'columnMenuUnsort': values.data.resources.Label_Unsorting,
                    'filterPanelColumns': values.data.resources.Label_Columns,
                    'filterPanelDeleteIconLabel': values.data.resources.Label_Delete,
                    'filterPanelInputLabel': values.data.resources.Label_GridValue,
                    'filterPanelInputPlaceholder': values.data.resources.Label_ValueFilter,
                    'filterPanelOperatorAnd': values.data.resources.Label_And,
                    'filterPanelOperatorOr': values.data.resources.Label_Or,
                    'filterPanelOperators': values.data.resources.Label_Operator,
                    'noResultsOverlayLabel': values.data.resources.Label_NoRows,
                    'noRowsLabel': values.data.resources.Label_NoRows,
                    'toolbarColumns': values.data.resources.Label_Columns,
                    'toolbarColumnsLabel': values.data.resources.Label_SelectColumns,
                    'errorOverlayDefaultLabel': "Error",
                    'filterOperatorAfter': values.data.resources.Label_OperatorAfter,
                    'filterOperatorBefore': values.data.resources.Label_OperatorBefore,
                    'filterOperatorContains': values.data.resources.Label_OperatorContains,
                    'filterOperatorEndsWith': values.data.resources.Label_OperatorEnds,
                    'filterOperatorEquals': values.data.resources.Label_OperatorEquals,
                    'filterOperatorIs': values.data.resources.Label_OperatorIs,
                    'filterOperatorIsEmpty': values.data.resources.Label_OperatorIsEmpty,
                    'filterOperatorIsNotEmpty': values.data.resources.Label_OperatorIsNotEmpty,
                    'filterOperatorNot': values.data.resources.Label_NoRows,
                    'filterOperatorOnOrAfter': values.data.resources.Label_OperatorIsOnOrAfter,
                    'filterOperatorOnOrBefore': values.data.resources.Label_OperatorIsOnOrBefore,
                     'filterOperatorStartsWith': values.data.resources.Label_OperatorStarts,
                    'columnsPanelDragIconLabel': values.data.resources.Label_ReorderColumns,
                    'columnsPanelHideAllButton': values.data.resources.Label_HideAll,
                    'columnsPanelShowAllButton': values.data.resources.Label_ShowAll,
                    'columnsPanelTextFieldLabel': values.data.resources.Label_FindColumn,
                    'columnsPanelTextFieldPlaceholder': values.data.resources.Label_TitleColumn,
                    'filterPanelAddFilter': values.data.resources.Label_addFilter
                }}    
                pageSize={pageSize}
                onStateChange={(state) => {
                    //   console.log(state)
                    localStorage.setItem('AreaListPagination', JSON.stringify(state.pagination))
                    localStorage.setItem('AreaListSorting', JSON.stringify(state.sorting))
                    localStorage.setItem('AreaListFiltering', JSON.stringify(state.filter))
                }}
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
                sortModel={mySortModel}
                onSortModelChange={handleSortingChange}
                state={
                        filter != null ? {filter: filter} : {}
                        
                }
                      
            />
        </div>
    </>
    );
}