import React, { Component } from 'react';
import axios from 'axios';
import $ from 'jquery';
import helpers from '../../Helpers'
import EditorHtml from '../Editor/Editor';

export class ScriptSalesCreate extends Component {
    static displayName = ScriptSalesCreate.name;

    constructor(props) {
        super(props);

        this.onChangeTitle = this.onChangeTitle.bind(this);
        this.onChangeDetail = this.onChangeDetail.bind(this);
        this.onChangeEnabled = this.onChangeEnabled.bind(this);
        this.onChangeProduct = this.onChangeProduct.bind(this);
        this.onChangeArea = this.onChangeArea.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onSubmit = this.onSubmit.bind(this);  
        this.onChangeCountry= this.onChangeCountry.bind(this);

        this.state = {
            id: 0,
            title: '',
            products: [],
            productId: null,
            areas: [],
            detail: '',
            areaId: null,
            enabled: true,
            loading: true,
            resources: [],
            userId: 0,
            countries: [],
            countryId: 0,
        };
    }

    componentDidMount() {
        this.getResources();
        this.populateData();
        helpers.setNavActive("#nav-scriptsales");

        let currentUser = helpers.getUserData();
        this.setState({
            userId: currentUser.id
        });
    }

    async onSubmit(e) {
        e.preventDefault();
        this.setState({ loading: true });

        const data = {
            title: this.state.title,
            detail: localStorage.getItem('data-detail') ? localStorage.getItem('data-detail') : '',
            product_id: this.state.product,
            area_id: this.state.areaId,
            enabled: this.state.enabled,
            creation_user_id: this.state.userId,
            country_id: this.state.countryId,
        };
        // console.log(data);
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME)
        };

        axios.post(window.config.REACT_APP_PRODUCTS_API_URL + '/scriptsale/save', data, {
            headers: headers
        })
            .then((response) => {
                helpers.notifySuccess(this.state.resources.Label_Success, this.state.resources.SuccessMessage_DataSavedSuccessfully);
                this.props.history.push('/scriptsales');
            })
            .catch((error) => {
                console.log(error);

                let msg = this.state.resources.ErrorMessage_UnknownError;
                if (error.response.data.error_code == window.errorCodes.DuplicatedEntityName)
                    msg = this.state.resources.ErrorMessage_DuplicatedName;

                helpers.notifyError(this.state.resources.Label_Error, msg);
                this.setState({ loading: false });
            });
    }  

    onCancel(e) {
        e.preventDefault();
        this.props.history.push("/scriptsales");
    }

    onChangeTitle(e) {
        this.setState({
            title: e.target.value
        });
    }

    onChangeDetail(e) {
         this.setState({
            detail: e.target.value
         });
        console.log(this.state.detail);
    }
    
    onChangeProduct(e) {
        this.setState({
            product: e.target.value
        });
    }

    onChangeArea(e) {
        let areaId = e.target.value === "" ? null : parseInt(e.target.value);

        this.setState({
            areaId: areaId,
        });
        
    }

    onChangeEnabled(e) {
        this.setState({
            enabled: e.target.checked
        });
    } 

    onChangeCountry(e){
        console.log(e.target.value)
     this.setState({
       countryId : e.target.value
     });
      console.log(this.state.countryId)
    }

    render() {
        let contents = this.state.loading
            ? helpers.showLoadingOverlay()
            :
            <form className="form row g-3 needs-validation" onSubmit={this.onSubmit}>
                <div className="form-group col-md-12">
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" id="enabled" defaultChecked={this.state.enabled} onChange={this.onChangeEnabled} />
                        <label className="form-check-label" htmlFor="enabled">{this.state.resources.Label_Enabled}</label>
                        <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                    </div>
                </div>
                <div className="form-group col-md-6" >

                  <label className="mt-2 form-label">{this.state.resources.Label_Country}</label>
                     <select className="form-control" data-val="true" name="Country" defaultValue={this.state.countryId} onChange={this.onChangeCountry} required>
                         <option value="">{this.state.resources.Label_SelectOption}</option>
                         { this.state.countries.map(country =>
                             <option value={country.id} key={country.id}> {country.name} </option>
                         )
                         }
                         </select>  
                         <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div> 

                    <label className=" form-label">{this.state.resources.Label_Title}</label>
                    <input className="form-control" disabled={this.state.countryId == 0} type="text" name="title" onChange={this.onChangeTitle} value={this.state.title} required />
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>

                    <label className="mt-2 form-label">{this.state.resources.Label_Products}</label>
                    <select className="form-control" disabled={this.state.countryId == 0} data-val="true" name="product" defaultValue={this.state.product} onChange={this.onChangeProduct}>
                        <option value="">{this.state.resources.Label_SelectOption}</option>
                        <option value="-1">{this.state.resources.Label_AllProducts}</option>
                            {this.state.products.map(prod =>
                                <option key={prod.id} value={prod.id}>{prod.name}</option>
                            )}
                        </select>
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>

                    <label className="mt-2 form-label">{this.state.resources.Label_Area}</label>
                    {/* <input className="form-control" type="text" name="area" onChange={this.onChangeArea} value={this.state.area} required />
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div> */}
                        <select className="form-control" disabled={this.state.countryId == 0} data-val="true" name="area" defaultValue={this.state.areaId} onChange={this.onChangeArea} required>
                        <option value="">{this.state.resources.Label_SelectOption}</option>
                        <option value="-1">{this.state.resources.Label_AllAreas}</option>
                            {this.state.areas.map(area =>
                                <option key={area.id} value={area.id}>{area.name}</option>
                            )}
                        </select>
                </div>
                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_Detail}</label>
                    <br></br>
                    {/* <textarea className="form-control rounded-0" rows="7" name="detail" onChange={this.onChangeDetail} value={this.state.detail}></textarea> */}
                    <EditorHtml dataVal={false} disabled={this.state.countryId == 0} name="detail" onChange={this.onChangeDetail} value={this.state.detail}></EditorHtml>
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                </div>
                <div className="form-group col-md-6" >
                    
                </div>
                <div className="form-group col-md-6" >
                    
                </div>


                {/**Buttons Header */}
                 <div className="buttons-head row">
                    <div className=" buttons-childs">
                        <button type="submit" className="btn btn-primary">{this.state.resources.ButtonLabel_Save}</button>
                        <button className="btn btn-danger" onClick={this.onCancel}>{this.state.resources.ButtonLabel_Cancel}</button>
                    </div>
                 </div>
                {/* <div className="form-group col-12 text-end">
                    <button type="submit" className="btn btn-primary">{this.state.resources.ButtonLabel_Save}</button>
                    <button className="btn btn-negative" onClick={this.onCancel}>{this.state.resources.ButtonLabel_Cancel}</button>
                </div > */}
            </form>
            helpers.hideLoadingOverlay();

        return (
            <div className="mt-2">
                <ol className="breadcrumb my-4">
                    <li className="breadcrumb-item active">{this.state.resources.Title_ScriptSales}</li>
                </ol>
                <div className="card">
                    <div className="card-body">
                        {contents}
                    </div>
                </div>
            </div>
        );
    }

    async getResources() {
        const resourceVars = [];
        resourceVars.push("Title_ScriptSales");
        resourceVars.push("Label_Title");
        resourceVars.push("Label_Detail");
        resourceVars.push("Label_Area");
        resourceVars.push("Label_Products");
        resourceVars.push("Label_Enabled");
        resourceVars.push("Label_Success");
        resourceVars.push("ButtonLabel_Save");
        resourceVars.push("ButtonLabel_Cancel");
        resourceVars.push("Label_Error");
        resourceVars.push("ErrorMessage_RequiredField");
        resourceVars.push("ErrorMessage_DuplicatedName");
        resourceVars.push("ErrorMessage_UnknownError");
        resourceVars.push("SuccessMessage_DataSavedSuccessfully");
        resourceVars.push("Label_SelectOption");
        resourceVars.push("Label_AllProducts");
        resourceVars.push("Label_AllAreas");
        resourceVars.push("Label_Country");

        const headers = {
            'Content-Type': 'application/json'
        };

        axios.post(window.config.REACT_APP_SECURITY_API_URL + '/languageresource/getbyvars/' + helpers.getUiLanguage(), resourceVars, {
            headers: headers
        })
            .then((response) => {
                const data = response.data.data;
                this.setState({ resources: data, loading: false });
            })
            .catch((error) => {
                console.log(error);
                this.setState({ loading: false });
            });
        
        let authTokenProducts = await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME);
        // get areas
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/area/get',
            {
                'headers': { 'Authorization': authTokenProducts }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ areas: data, loading: false });
                this.state.areas.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                // console.log(this.state.areas);
            })
            .catch(function (error) {
                console.log(error);
            });

        // get products
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/product/getall',
            {
                'headers': { 'Authorization': authTokenProducts }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ products: data, loading: false });
                this.state.products.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                // console.log(this.state.areas);
            })
            .catch(function (error) {
                console.log(error);
            });
            axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/country/get',
            {
                'headers': { 'Authorization': authTokenProducts }
            })
            .then(response => {
                const data = response.data.data;
                this.setState({ countries: data, loading: false });
                this.state.countries.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                //console.log(response);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    async populateData() {
        this.setState({
            loading: false
        });
    }
}