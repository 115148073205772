import React, { Component } from 'react';
import axios from 'axios';
import $ from 'jquery';
import helpers from '../../Helpers';
import DataCreation from '../DataCreation/DataCreation';
import EditorHtml from '../Editor/Editor';

export class ObjetionsDetail extends Component {
    static displayName = ObjetionsDetail.name;

    constructor(props) {
        super(props);

        this.onChangeTitle = this.onChangeTitle.bind(this);
        this.onChangeDetail = this.onChangeDetail.bind(this);
        this.onChangeEnabled = this.onChangeEnabled.bind(this);
        this.onChangeProduct = this.onChangeProduct.bind(this);
        this.onChangeArea = this.onChangeArea.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onSubmit = this.onSubmit.bind(this);  
        this.onChangeCountry = this.onChangeCountry.bind(this);

        this.state = {
            id: 0,
            title: '',
            products: [],
            areas: [],
            detail: '',
            areaId: null,
            productId: null,
            enabled: false,
            loading: true,
            creation: '',
            creationUser: '',
            lastUpdate: '',
            lastUpdateUser: '',
            resources: [],
            viewMode: false,
            currentUserId: 0,
            countries: [],
            countryId: 0,
        };
    }

    componentDidMount() {
        this.getResources();
        this.populateData();
        helpers.setNavActive("#nav-objetions");

        let url = window.location.href;
        if (url.includes('view')) {
            this.setState({
                viewMode: true
            });
        }
        let currentUser = helpers.getUserData();
        this.setState({
            currentUserId: currentUser.id
        });

        let sort = JSON.parse(localStorage.getItem('ObjetionsListSorting'))
        localStorage.setItem('CopyObjetionsListSorting', JSON.stringify(sort));

        let paging = JSON.parse(localStorage.getItem('ObjetionsListPagination'))
        localStorage.setItem('CopyObjetionsListPaging', JSON.stringify(paging));

        let filtering = JSON.parse(localStorage.getItem('ObjetionsListFiltering'))
        localStorage.setItem('CopyObjetionsListFiltering', JSON.stringify(filtering));
    }

    async onSubmit(e) {
        e.preventDefault();
        this.setState({
            loading: true
        });

        const data = {
            id: parseInt(this.props.match.params.id),
            title: this.state.title,
            detail: localStorage.getItem('data-detail') ? localStorage.getItem('data-detail') : this.state.detail,
            product_id: this.state.productId,
            area_id: this.state.areaId,
            enabled: this.state.enabled,
            last_update_user_id: this.state.currentUserId,
            country_id: this.state.countryId,
        };
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME)
        };

        axios.post(window.config.REACT_APP_PRODUCTS_API_URL + '/objetions/save', data, {
            headers: headers
        })
            .then((response) => {
                helpers.notifySuccess(this.state.resources.Label_Success, this.state.resources.SuccessMessage_DataSavedSuccessfully);
                this.props.history.push('/objetions');
            })
            .catch((error) => {
                console.log(error);

                let msg = this.state.resources.ErrorMessage_UnknownError;
                if (error.response.data.error_code == window.errorCodes.DuplicatedEntityName)
                    msg = this.state.resources.ErrorMessage_DuplicatedName;
                else if (error.response.data.error_code == window.errorCodes.AssociatedEntity)
                    msg = this.state.resources.ErrorMessage_AssociatedEntity_Area_User;

                helpers.notifyError(this.state.resources.Label_Error, msg);
                this.setState({ loading: false });
            });
    }  

    onCancel(e) {
        e.preventDefault();
        this.props.history.push("/objetions");
    }

    onChangeTitle(e) {
        this.setState({
            title: e.target.value
        });
    }

    onChangeDetail(e) {
         this.setState({
            detail: e.target.value
         });
        console.log(this.state.detail);
    }
    
    onChangeProduct(e) {
        this.setState({
            productId: e.target.value
        });
    }

    onChangeArea(e) {
        let areaId = e.target.value === "" ? null : parseInt(e.target.value);

        this.setState({
            areaId: areaId,
        });
    }


    onChangeEnabled(e) {
        this.setState({
            enabled: e.target.checked
        });
    } 
    onChangeCountry(e){
        console.log(e.target.value)
     this.setState({
       countryId : e.target.value
     });
      console.log(this.state.countryId)
    }

    render() {
        let contents = this.state.loading
            ? helpers.showLoadingOverlay()
            :
            <form className="form row g-3 needs-validation" onSubmit={this.onSubmit}>
                <div className="form-group col-md-12">
                    <input type="hidden" name="areaId" value={this.state.id} />
                </div>
                <div className="form-group col-md-12">
                    <div className="form-check form-switch">
                        <input disabled={this.state.viewMode} className="form-check-input" type="checkbox" id="enabled" checked={this.state.enabled} defaultChecked={this.state.enabled} onChange={this.onChangeEnabled} />
                        <label className="form-check-label" htmlFor="enabled">{this.state.resources.Label_Enabled}</label>
                        <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                    </div>
                </div>
                <div className="form-group col-md-6" >
                <label className="mt-2 form-label">{this.state.resources.Label_Country}</label>
                     {/*<input disabled={this.state.countryId == 0} />*/}
                     <select className="form-control" data-val="true" name="Country" defaultValue={this.state.countryId} onChange={this.onChangeCountry} required>
                         <option value="">{this.state.resources.Label_SelectOption}</option>
                         { this.state.countries.map(country =>
                             <option value={country.id} key={country.id} selected={ this.state.countryId == country.id} > {country.name} </option>
                         )
                         }
                         </select>
                     <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div> 

                    <label className=" form-label">{this.state.resources.Label_Title}</label>
                    <input disabled={this.state.viewMode} className="form-control" disabled={this.state.countryId == 0} type="text" name="title" onChange={this.onChangeTitle} value={this.state.title} required />
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>

                    <label className="mt-2 form-label">{this.state.resources.Label_Products}</label>
                    <select disabled={this.state.viewMode} className="form-control" disabled={this.state.countryId == 0} data-val="true" name="product" defaultValue={this.state.product} onChange={this.onChangeProduct}>
                        <option value="">{this.state.resources.Label_SelectOption}</option>
                        <option value="-1" selected={ this.state.productId == null}>{this.state.resources.Label_AllProducts}</option>
                            {this.state.products.map(prod =>
                                <option key={prod.id} value={prod.id} selected={this.state.productId == prod.id} >{prod.name}</option>
                            )}
                        </select>
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>

                    <label className="mt-2 form-label">{this.state.resources.Label_Area}</label>
                        <select disabled={this.state.viewMode} className="form-control" disabled={this.state.countryId == 0} data-val="true" name="area" defaultValue={this.state.areaId} onChange={this.onChangeArea} required>
                        <option value="">{this.state.resources.Label_SelectOption}</option>
                        <option value="-1" selected={ this.state.areaId == null}>{this.state.resources.Label_AllAreas}</option>
                            {this.state.areas.map(area =>
                                <option key={area.id} value={area.id} selected={this.state.areaId == area.id} >{area.name}</option>
                            )}
                        </select>
                </div>
                <div className="form-group col-md-6" >
                    <label className=" form-label">{this.state.resources.Label_Detail}</label>
                    <br></br>
                    <EditorHtml disabled={this.state.countryId == 0} viewMode={this.state.viewMode} dataVal={this.state.detail} name="detail" onChange={this.onChangeDetail} value={this.state.detail}></EditorHtml>
                    <div className="invalid-feedback">{this.state.resources.ErrorMessage_RequiredField}</div>
                </div>
                <div className="form-group col-md-6" >
                    
                </div>
                <div className="form-group col-md-6" >
                   
                </div>
                {/*LAST UPDATE / LAST UPDATE USER*/}
                <div className="form-group col-md-6" >
                    <DataCreation resources={this.state}></DataCreation>
                </div>

                {/**Buttons Header */}
                <div className="buttons-head row">
                    {this.state.viewMode == false ?
                    <div className=" buttons-childs">
                        <button type="submit" className="btn btn-primary">{this.state.resources.ButtonLabel_Save}</button>
                        <button className="btn btn-danger" onClick={this.onCancel}>{this.state.resources.ButtonLabel_Cancel}</button>
                    </div>
                    : 
                      <button className="btn btn-danger btn-block" onClick={this.onCancel}>{this.state.resources.Label_GoBack}</button>
                    }
                 </div>
            </form>
            // helpers.hideLoadingOverlay();

        return (
            <div className="mt-2">
                <ol className="breadcrumb my-4">
                    <li className="breadcrumb-item active">{this.state.resources.Title_Objetions}</li>
                </ol>
                <div className="card">
                    <div className="card-body">
                        {contents}
                    </div>
                </div>
            </div>
        );
    }

    async getResources() {
        const resourceVars = [];
        resourceVars.push("Title_Objetions");
        resourceVars.push("Label_Title");
        resourceVars.push("Label_Detail");
        resourceVars.push("Label_Area");
        resourceVars.push("Label_Products");
        resourceVars.push("Label_Enabled");
        resourceVars.push("Label_Success");
        resourceVars.push("ButtonLabel_Save");
        resourceVars.push("ButtonLabel_Cancel");
        resourceVars.push("Label_Error");
        resourceVars.push("ErrorMessage_RequiredField");
        resourceVars.push("ErrorMessage_DuplicatedName");
        resourceVars.push("ErrorMessage_UnknownError");
        resourceVars.push("ErrorMessage_AssociatedEntity_Area_User");
        resourceVars.push("SuccessMessage_DataSavedSuccessfully");
        resourceVars.push("Label_Creation");
        resourceVars.push("Label_CreationUser");
        resourceVars.push("Label_LastUpdate");
        resourceVars.push("Label_LastUpdateUser");
        resourceVars.push("Label_SelectOption");
        resourceVars.push("Label_AllProducts");
        resourceVars.push("Label_AllAreas");
        resourceVars.push("Label_GoBack");
        resourceVars.push("Label_Country");

        const headers = {
            'Content-Type': 'application/json'
        };

        axios.post(window.config.REACT_APP_SECURITY_API_URL + '/languageresource/getbyvars/' + helpers.getUiLanguage(), resourceVars, {
            headers: headers
        })
            .then((response) => {
                const data = response.data.data;
                this.setState({ resources: data, loading: false });
            })
            .catch((error) => {
                console.log(error);
                this.setState({ loading: false });
            });

            let authTokenProducts = await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME);
            // get areas
            axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/area/get',
                {
                    'headers': { 'Authorization': authTokenProducts }
                })
                .then(response => {
                    const data = response.data.data;
                    this.setState({ areas: data, loading: false });
                    this.state.areas.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                    // console.log(this.state.areas);
                })
                .catch(function (error) {
                    console.log(error);
                });
    
            // get products
            axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/product/getall',
                {
                    'headers': { 'Authorization': authTokenProducts }
                })
                .then(response => {
                    const data = response.data.data;
                    this.setState({ products: data, loading: false });
                    this.state.products.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                    // console.log(this.state.areas);
                })
                .catch(function (error) {
                    console.log(error);
                });

                axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/country/get',
                {
                    'headers': { 'Authorization': authTokenProducts }
                })
                .then(response => {
                    const data = response.data.data;
                    this.setState({ countries: data, loading: false });
                    this.state.countries.sort((a,b) => a.name < b.name ? -1 : +(a.name > b.name))
                    //console.log(response);
                })
                .catch(function (error) {
                    console.log(error);
                });

    }

    async populateData() {
        helpers.showLoadingOverlay();
        axios.get(window.config.REACT_APP_PRODUCTS_API_URL + '/objetions/getbyid/' + this.props.match.params.id,
            {
                'headers': { 'Authorization': await helpers.getAuthorizationToken(process.env.REACT_APP_PRODUCTS_API_NAME) }
            })
            .then(response => {
                const data = response.data.data;
                // console.log(data);
                this.setState({
                    id: data.id,
                    title: data.title,
                    productId: data.product_id,
                    countryId: data.country_id,
                    detail: data.detail,
                    area: data.area_name,
                    areaId: data.area_id,
                    enabled: data.enabled,
                    creation: helpers.getDisplayDateTimeFromUtc(data.creation),
                    creationUser: data.creation_user_fullname,
                    lastUpdate: data.last_update == null ? '' : helpers.getDisplayDateTimeFromUtc(data.last_update),
                    lastUpdateUser: data.last_update_user_fullname == null ? '' : data.last_update_user_fullname,
                    loading: false
                });
                helpers.hideLoadingOverlay();
            })
            .catch(function (error) {
                console.log(error);
                this.setState({ loading: false });
            })  
    }
}